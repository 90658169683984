import $ from 'jquery';
import _ from 'underscore';
import UsageService from './usage/UsageService';
import Managers from '@axisnow/data/Managers';
import Helpers from './EpubReaderHelpers';
import PackageInfo from './PackageInfo';
import utils from '@axisnow/util/utils';

var appid;
var usageService;
var EngageManager = function() {
  usageService = new UsageService();
  this._userManager = Managers.user;
  getVersion();
};

EngageManager.prototype.trackActivityToRemote = function (isbn, currentPage, totalPages, bookType, totalwords) {
	var self = this;
	return this._userManager.getCurrentUser().then(function(user) {
		console.log('page end time', Helpers.getCurrentDate());
		// var userId = user.id;
		var uuid = user.bookvaultId; // user uuid
    var library = user.libraryPrefix; // customer number
    var startTime = window.sessionStorage.getItem('startTimeStamp');
    var timeZone = Helpers.getTimezone();
    var eof = (currentPage == totalPages) ? true : false;
    var requestPayload;
    
    var baseURL = decodeURIComponent(Helpers.getUrlParam("trackAPI") === null ? utils.getRemoteServerOrigin().toString() : Helpers.getUrlParam("trackAPI"));
    // Sample data for reference: 
    /* enableEngageModule=False&engageUserGuid=4a30c403-c0ce-449b-b53b-4ece57064119&engageBookId=0012281665&engageLibraryId=57C86A8B-E3B9-E011-9A12-6431504A306A&
     */
    var bookId = Helpers.getUrlParam("engageBookId") == null ? isbn : Helpers.getUrlParam("engageBookId");
    var engageAppId = Helpers.getUrlParam("engageLibraryId");
    var siteId = Helpers.getUrlParam("engageLibraryId");
    var userGuId = Helpers.getUrlParam("engageUserGuid");
    var engagePermission = Helpers.getUrlParam("enableEngageModule") === null ? false : (Helpers.getUrlParam("enableEngageModule")).toLowerCase() == 'true';
    var profileId = Helpers.getUrlParam("profileId");
    var profileType = Helpers.getUrlParam("profileType");
    var isPrimary = Helpers.getUrlParam("isPrimary");
    var isReadingV2 = Helpers.getUrlParam("isReadingV2"); // to find out reading version url

      requestPayload = {
        "clientAppId": appid,
        "bookSource": "Favorite printers",
        "totalSize": totalPages,
        "totalWords": (typeof totalwords == 'undefined') ? 0 : totalwords,
        "currentLocation": currentPage,
        "totalInCurrentSession": 1,
        "bookType": bookType,
        "startTime":startTime,
        "endTime": Helpers.getCurrentDateFormat(),
        "date": Helpers.getCurrentDateFormat(),
        "isbn": isbn,
        "timeZone": timeZone,
        "eof": eof
      };

      /**
       *  Kidszone reading progress
       */
    
       if (profileId != "" && profileId != undefined) {

         requestPayload.profileId = profileId;
         requestPayload.profileType = profileType;
         requestPayload.isPrimary = isPrimary;

       }

    if (engagePermission === true) {
         
        var readingPath;

       //finding a reading version url path
        if (isReadingV2 == 'True') {

          readingPath = 'read/activity/v2/apps/';

        } else {

          readingPath = 'read/activity/apps/';

        }
      var jqXhr = $.ajax({
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(requestPayload),
        url: baseURL + readingPath + engageAppId + '/sites/' + siteId + '/users/' + userGuId + '/bookclub/books/' + bookId + '/read',
        //utils.getRemoteServerOrigin().path('activity')  
        //url: 'https://devuse2-destinydiscoverapi-dev-apim.azure-api.net/api/v1/apps/' + appId + '/sites/' + siteId + '/users/' + userGuId + '/bookclub/books/' + bookId  + '/read' //utils.
      });
      return utils.deferredToPromise(jqXhr).then(function (response) {
        return response;
      }).catch(function (error) {
        console.error("activity error:" + JSON.stringify(error));
      });
    }
	  });  
};

//Engage Event CloseBook
EngageManager.prototype.eventcloseBook = function () {
  return this._userManager.getCurrentUser().then(function(user) {
     
      var engageAppId = Helpers.getUrlParam("engageAppId");
      var siteId = Helpers.getUrlParam("engageSiteId");
      var userGuId = Helpers.getUrlParam("engageUserGuid");
      var bookId = Helpers.getUrlParam("engageBookId") == null ? isbn : Helpers.getUrlParam("engageBookId");
      var engagePermission = Managers.appPermission.permission("EnableEngageModule");

      var requestPayload = { 
        "eventType": "bookclose", 
        "eventData": bookId, 
        "clientAppId": appid, 
        "eventDate": Helpers.getCurrentDateFormat() 
      };

      // This piece of code used for Axis360 to prevent user not to call ajax request
      if(engagePermission.disableByDefault || engageAppId == null || siteId == null || userGuId == null) {
        return null;
      }
      var jqXhr = $.ajax({
          type: 'POST',
          data: JSON.stringify(requestPayload),
          contentType: 'application/json',
          url: utils.getRemoteServerOrigin().path('closebook/apps/' + engageAppId + '/sites/' + siteId + '/users/' + userGuId) 
      });
      console.log(jqXhr);
      return utils.deferredToPromise(jqXhr);
  });
};

//Engage Event Checkout
EngageManager.prototype.eventcheckoutBook = function (isbn, bookType) {
  return this._userManager.getCurrentUser().then(function(user) {
    
    var engageAppId = Helpers.getUrlParam("engageAppId");
    var siteId = Helpers.getUrlParam("engageSiteId");
    var userGuId = Helpers.getUrlParam("engageUserGuid");
    var bookId = Helpers.getUrlParam("engageBookId") == null ? isbn : Helpers.getUrlParam("engageBookId");
    var engagePermission = Managers.appPermission.permission("EnableEngageModule");
   
    var requestPayload = { 
      "type": bookType,
      "data": bookId, 
      "clientAppId": appid, 
      "date": Helpers.getCurrentDateFormat() 
    };
    
    // This piece of code used for Axis360 to prevent user not to call ajax request
    if(engagePermission.disableByDefault || engageAppId == null || siteId == null || userGuId == null) {
      return null;
    }

    var jqXhr = $.ajax({
      type: 'POST',
      data: JSON.stringify(requestPayload),
      contentType: 'application/json',
      url: utils.getRemoteServerOrigin().path('checkout/apps/' + engageAppId + '/sites/' + siteId + '/users/' + userGuId)      
    });
    return utils.deferredToPromise(jqXhr);
  });
};

EngageManager.prototype.getVideoSignedlUrl = function(itemId, videoId) {
   var baseURL = decodeURIComponent(Helpers.getUrlParam("trackAPI") === null ? utils.getRemoteServerOrigin().toString() : Helpers.getUrlParam("trackAPI"));
  let requestPayload = {
    itemId:itemId,
    videoId:videoId
  }

    var jqXhr = $.ajax({
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(requestPayload),
        url: baseURL + 'video/signedUrl',
      });
      return utils.deferredToPromise(jqXhr).then(function (response) {
        return response;
      }).catch(function (error) {
        console.error("activity error:" + JSON.stringify(error));
        return false;
      });
}
function getVersion(){
  var string = PackageInfo.version;
    string = "Axis360-"+string;
    appid =  string;
}

export default EngageManager;