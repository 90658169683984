import $ from 'jquery';
import _ from 'underscore';

var Helpers = {};

Helpers.createStyleSheet = function(document, id) {
  var style = document.createElement('style');
  style.id = 'rd-stylesheet-' + id;
  style.appendChild(document.createTextNode(''));
  document.head.appendChild(style);
  return style.sheet;
};

Helpers.removeStyleSheet = function(document, id) {
  return !!$('style#rd-stylesheet-' + id, document).remove().length;
};

Helpers.supportsFocusScrollingProperly = function() {
  var scrollTestDiv = document.createElement('div');
  var paragraph1 = document.createElement('p');
  var paragraph2 = document.createElement('p');
  $(scrollTestDiv).css({
    position: 'absolute',
    opacity: '0',
    'font-size': '16px',
    'line-height': '1',
    'font-family': 'Helvetica, sans-serif',
    overflow: 'scroll',
    width: '250px',
    height: '250px',
    'column-width': '250px',
    '-moz-column-width': '250px',
    'vertical-align': 'baseline',
  });
  paragraph1.textContent =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent quis feugiat leo, a pharetra ' +
    'magna. Curabitur ' +
    'volutpat nunc quam, eget commodo est placerat sed. Vivamus tellus metus, ultricies et auctor iaculis, viverra at ' +
    'elit. Donec eu facilisis est. Nam justo massa, malesuada quis neque id, tempus hendrerit est. Donec ac ultrices ' +
    'quam. Mauris consequat nisi vitae.lisis est.';
  scrollTestDiv.appendChild(paragraph1);
  paragraph2.textContent =
    'Vivamus tempor, felis quis tincidunt semper, leo arcu suscipit arcu, at molestie sem augue quis ' +
    'mauris. Curabitur ' +
    'ante dolor, malesuada semper est eu, facilisis efficitur ligula. Quisque sit amet nulla id augue lacinia ' +
    'molestie. Aliquam quis sem ut turpis laoreet tincidunt. Donec sit amet laoreet augue, eu accumsan nulla. Aliquam ' +
    'fringilla pellentesque turpis, ut fringilla lorem auctor in. Aenean tempus, orci id commodo fermentum, justo ' +
    'massa efficitur sapien, et egestas tortor elit id ante. Sed sit amet tristique mi. Curabitur vel lacinia leo. ' +
    'Etiam mattis, velit quis commodo elementum, dui risus ultrices mi, efficitur sodales mi nibh et orci. Donec ' +
    'tempor rhoncus tellus sit amet elementum. Curabitur dapibus at sapien non tempus.';
  scrollTestDiv.appendChild(paragraph2);
  document.documentElement.appendChild(scrollTestDiv);
  scrollTestDiv.scrollLeft = 250;
  paragraph2.setAttribute('tabindex', '-1');
  paragraph2.focus();
  paragraph2.removeAttribute('tabindex', '');
  var result = scrollTestDiv.scrollLeft;
  document.documentElement.removeChild(scrollTestDiv);
  return result === 250;
};

Helpers.focusOnElement = function($el) {
  if (!$el.attr('tabindex')) {
    $el[0].setAttribute('tabindex', '-1');
    $el[0].setAttribute('data-rd-accessibility-focus-temp', '');
    _.defer(function() {
      $el.focus();
      $el.one('blur', function() {
        $el[0].removeAttribute('data-rd-accessibility-focus-temp');
      });
      _.defer(function() {
        $el[0].removeAttribute('tabindex');
      });
    });
  } else {
    $el.focus();
  }
};

export default Helpers;
