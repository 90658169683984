var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./toc-item.hbs"),depth0,{"name":"toc-item","data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"container-layout\">\n        <div class=\"pane fixed-pane header-pane inverted border-top-1\">\n            <h3 class=\"pane-title text-uppercase\">Table of Contents</h3>\n        </div>\n        <div class=\"pane\">\n            <div class=\"pane-body\">\n                <ol id=\"readium-toc-body\" aria-label=\"Table of Contents\"\n                role=\"list\" tabindex=\"0\" class=\"list-group book-toc book-toc-ol\">\n"
    + ((stack1 = container.hooks.blockHelperMissing.call(depth0,container.lambda((depth0 != null ? lookupProperty(depth0,"tocData") : depth0), depth0),{"name":"tocData","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":20},"end":{"line":11,"column":32}}})) != null ? stack1 : "")
    + "                </ol>\n            </div>\n        </div>\n    </div>\n";
},"usePartial":true,"useData":true});