import $ from 'jquery';
import IndexDBStorageManager from '@axisnow/storage/IndexedDBStorage';
import Managers from '@axisnow/data/Managers';
import Promise from 'bluebird';
import URI from 'urijs';
import Errors from '@axisnow/util/Errors';
import Feature from '@axisnow/data/app-permission/Feature';
import UsageService from './UsageService';
import EventEmitter from 'eventemitter3';
import PackageInfo from '../PackageInfo';
import utils from '@axisnow/util/utils';
import PopupFactory from '../popup/PopupFactory';
import errorMonitor from '../ErrorMonitor';
import SplashMessage from '../SplashMessage';
import PageUsage from './PageUsage';
import AnnotationsUsage from './AnnotationsUsage';
import EbookAnnotationsUsage from './EbookAnnotationsUsage';
import AudioAnnotationsUsage from './AudioAnnotationsUsage';
import ComicBookAnnotationsUsage from './ComicBookAnnotationsUsage';
import ComicBookPageUsage from './ComicBookPageUsage';
import PositionsUsage from './PositionsUsage';
import MemoryStorageManager from '@axisnow/storage/MemoryStorage';
import Helpers from '../EpubReaderHelpers';
import EngageManager from '../EngageManager';
import momentCore from 'moment';
import * as R from 'ramda'

var _storageManager = new IndexDBStorageManager();

//var authToken = "EDE24724-76A4-4AAC-BEBD-0B0D32D17D93";
var appid;
var featureManager = Managers.feature;
var usageService;
var engageManager;
var UsageManager = function() {
  usageService = new UsageService();
  engageManager = new EngageManager();
  this._userManager = Managers.user;
  this._usages = {};
  createAsyncFeatures();
};

//Internal Functions
function createAsyncFeatures() {
  featureManager.feature([
    new Feature({
      name: 'Notes/Highlights-Sync',
      enable: usageService.fetchAnnotationsFromRemote,
      disable: disableFeatures,
      isAsync: true,
    }),
    new Feature({
      name: 'Notes/Highlights-Save',
      enable: usageService.saveNotesHighlights,
      disable: disableFeatures,
      isAsync: true,
    }),
    new Feature({
      name: 'Notes/Highlights-Delete',
      enable: usageService.deleteNotesHighlights,
      disable: disableFeatures,
      isAsync: true,
    }),
    new Feature({
      name: 'CurrentPosition-Fetch',
      enable: usageService.fetchCurrentPositionFromRemote,
      disable: disableFeatures,
      isAsync: true,
    }),
    new Feature({
      name: 'CurrentPosition-Save',
      enable: usageService.saveCurrentPositionToRemote,
      disable: disableCurrentPositionSave,
      isAsync: true,
    }),
    new Feature({
      name: 'AudioPosition-Fetch',
      enable: usageService.fetchAudioPositionsFromRemote,
      disable: disableFeatures,
      isAsync: true,
    }),
    new Feature({
      name: 'AudioPosition-Save',
      enable: usageService.saveAudioPositionsToRemote,
      disable: disableFeatures,
      isAsync: true,
    }),
    new Feature({
      name: 'AudioPosition-Delete',
      enable: usageService.deleteAudioPositionsFromRemote,
      disable: disableFeatures,
      isAsync: true,
    }),
    new Feature({
      name: 'ListeningPosition-Fetch',
      enable: usageService.fetchListeningPositionFromRemote,
      disable: disableFeatures,
      isAsync: true,
    }),
    new Feature({
      name: 'ListeningPosition-Save',
      enable: usageService.saveListeningPositionsToRemote,
      disable: disableListeningPositionsSaveFeature,
      isAsync: true,
    }),
  ]);
}
function getEventEmitter(success){
  var eventEmitter = new EventEmitter();
  eventEmitter.on('new:updated', success);
  return eventEmitter;
}

function getVersion(){
  var string = PackageInfo.version;
    string = "Axis360-"+string;
    appid =  string;
}

/*********** GET USAGES FROM LOCAL (memory / indexedDB) *************/
let createNewUsageObject = usageType => isbn => uuid => profile => {
  let newObject = new usageType(isbn, uuid, profile.profileid, profile.profileType, profile.isPrimary,
    getEventEmitter(function(data) {
      if (usageType.usageName == AnnotationsUsage.usageName) {
        usageType = EbookAnnotationsUsage;
      }
      return saveUsage(usageType)(data)
        .then(function() {
          return getUsage(usageType)(profile)(isbn);
        })
        .catch(function(err) {
          console.error(err);
          throw err;
        });
    }),
    )
  return newObject;
}

let convertToUsageObject = (getDataFromStorage) => usageType => uuid_isbn => {
  return getDataFromStorage(usageType)(uuid_isbn)
    .then(function (usage) {
      let newPageObj;
      if (usage) {
        let bookId = usage.isbn || usage.id;        
        newPageObj = createNewUsageObject(usageType)(bookId)(usage.uuid)(usage);
        newPageObj = Object.assign(newPageObj, usage);
      }
    return newPageObj;
  })
}

var getDataObjectFromStorage = storageManager => usageType => key =>  {
  /**
   * In ebooks, key is uuid_isbn; In audiobooks, key is uuid_id.
   **/
  const usage_uuidIsbn = [PageUsage.usageName, AnnotationsUsage.usageName, EbookAnnotationsUsage.usageName, ComicBookPageUsage.usageName, ComicBookAnnotationsUsage.usageName];
  let storeName = storageManager.STORE_NAMES[usageType.usageName];
  let keyString = 'uuid_profile_isbn';
  if (usage_uuidIsbn.indexOf(usageType.usageName) == -1) {
    keyString = 'uuid_profile_id';
  }
  return storageManager.get(storeName, keyString, key)
}

let _memoryManager  = new MemoryStorageManager();
let getUsageFromMemory = getDataObjectFromStorage(_memoryManager);
let getUsageFromIndexDB = convertToUsageObject(getDataObjectFromStorage(_storageManager));

/**
 * Get page/ position/ annotations/ audioAnnotations usages
 **/
let getUsage = usageType => profile => isbn => {
  let  uuid_profile_isbn = profile.uuid + '|' + profile.profileid + '|' + isbn;
  return getUsageFromMemory(usageType)(uuid_profile_isbn)
  .then(function (usgMemory) {
    if (usgMemory == undefined) {
      return getUsageFromIndexDB(usageType)(uuid_profile_isbn).then(function (usgIndex){
        usgIndex = usgIndex || createNewUsageObject(usageType)(isbn)(profile.uuid)(profile);
        //update memory
        return saveUsageToMemory(usageType)(usgIndex).then(function (){
          return usgIndex;
        })
      })
    }
    else {
      return usgMemory;
    }
  });
}

UsageManager.prototype.getPageUsage = isbn => R.pipeP(() => Managers.user.getCurrentUser(), user => Managers.user.getCurrentProfile(user), profile => getUsage(PageUsage)(profile)(isbn))();
UsageManager.prototype.getAnnotationsUsage = isbn => R.pipeP(() => Managers.user.getCurrentUser(), user => Managers.user.getCurrentProfile(user), profile => getUsage(EbookAnnotationsUsage)(profile)(isbn))();
UsageManager.prototype.getPositionsUsage = id => R.pipeP(() => Managers.user.getCurrentUser(), user => Managers.user.getCurrentProfile(user), profile => getUsage(PositionsUsage)(profile)(id))();
UsageManager.prototype.getAudioAnnotationsUsage = id => R.pipeP(() => Managers.user.getCurrentUser(), user => Managers.user.getCurrentProfile(user), profile => getUsage(AudioAnnotationsUsage)(profile)(id))();
/*********** Comic Annotations *************/
UsageManager.prototype.getComicBookPageUsage = isbn => R.pipeP(() => Managers.user.getCurrentUser(), user => Managers.user.getCurrentProfile(user), profile => getUsage(ComicBookPageUsage)(profile)(isbn))();
UsageManager.prototype.getComicBookAnnotationsUsage = id => R.pipeP(() => Managers.user.getCurrentUser(), user => Managers.user.getCurrentProfile(user), profile => getUsage(ComicBookAnnotationsUsage)(profile)(id))();
/*********** SAVE USAGES TO LOCAL (memory / indexedDB) *************/
let saveDataToStorage = storageManager => usageType => data => {
  //TODO: name error check
  let storeName = storageManager.STORE_NAMES[usageType.usageName];
  return storageManager.put(storeName, data);
}
let saveUsageToIndexedDB = saveDataToStorage(_storageManager);
let saveUsageToMemory = saveDataToStorage(_memoryManager);

/****** SAVE TO MEMORY & INDEXEDDB ********/
let saveUsage = usageType => data => {
  let jsonData = JSON.parse(JSON.stringify(data));
  return saveUsageToIndexedDB(usageType)(jsonData)
  .then(function() {
    return saveUsageToMemory(usageType)(data);
  })
}

UsageManager.prototype.savePageUsage = saveUsage(PageUsage);
UsageManager.prototype.saveAnnotationsUsage = saveUsage(EbookAnnotationsUsage);
UsageManager.prototype.savePositionsUsage = saveUsage(PositionsUsage);
UsageManager.prototype.saveAudioAnnotationsUsage = saveUsage(AudioAnnotationsUsage);
UsageManager.prototype.saveComicBookPageUsage = saveUsage(ComicBookPageUsage);
UsageManager.prototype.saveComicBookAnnotationsUsage = saveUsage(ComicBookAnnotationsUsage);

UsageManager.prototype.syncAllBookUsages = function(isOnline, ebookURL, isComic) {
  Managers.GetAllBookInfosForCurrentUser()
    .then(function(bookInfos) {
      //when the book is epub
      if(bookInfos.length === 0 && ebookURL){
        let currentIsbn = new URI(ebookURL).filename();
        if(isComic){
          UsageManager.prototype.fetchComicBookAnnotationsFromRemote(currentIsbn)
          .then(function(){
            $(window).triggerHandler('usageSynchronized')
          });
        } else {
          UsageManager.prototype.fetchAnnotationsFromRemote(currentIsbn)
          .then(function(){
            $(window).triggerHandler('usageSynchronized')
          });
        }
      }

         for (var book of bookInfos) {
          /* jshint ignore:start */
          // Ignore JSHint error: Functions declared within loops referencing an outer scoped variable may lead to confusing semantics.
          /**
           * In the callback function, the book is an audiobook (book key is id) or an ebook (key is isbn).
           */
          Promise.join(UsageManager.prototype.getAudioAnnotationsUsage(book.isbn),
            UsageManager.prototype.getComicBookPageUsage(book.isbn),
            UsageManager.prototype.getPageUsage(book.isbn), function (audioAnnotUsage, comicPageUsage,eBookPageUsage) {
              if (!isOnline) {
                return;
              }
              if (
                !$.isEmptyObject(audioAnnotUsage.audiopositions)
              ) {
                var id = audioAnnotUsage.id;
                return UsageManager.prototype.saveListeningPositionsToRemote(id)
                  .then(function() {
                    return UsageManager.prototype.saveAudioPositionsToRemote(id, true);
                  })
                  .then(function() {
                    return UsageManager.prototype.deleteAudioPositionsFromRemote(id, false);
                  })
                  .catch(function(err) {
                    console.error(err);
                    throw err;
                  })
              }
              else if (!$.isEmptyObject(eBookPageUsage.location)) {
                var isbn = eBookPageUsage.isbn;
                return Promise.all([
                  UsageManager.prototype.saveCurrentPositionToRemote(isbn),
                  UsageManager.prototype.saveAnnotationsToRemote(isbn, true),
                  UsageManager.prototype.deleteAnnotationsFromRemote(isbn, false)
                ])
                .then(function(){
                  UsageManager.prototype.fetchAnnotationsFromRemote(isbn)
                  .then(function(){
                    $(window).triggerHandler('usageSynchronized')
                  });
                })
                .catch(function(err) {
                  console.error(err);
                  throw err;
                })
              }
              else if(comicPageUsage.location) {
                var isbn = comicPageUsage.isbn;
                return Promise.all([
                  UsageManager.prototype.saveCurrentComicPositionToRemote(isbn),
                  UsageManager.prototype.saveComicBookAnnotationsToRemote(isbn, true),
                  UsageManager.prototype.deleteComicBookAnnotationsFromRemote(isbn, false)
                ])
                .then(function(){
                  UsageManager.prototype.fetchAnnotationsFromRemote(isbn)
                  .then(function(){
                    $(window).triggerHandler('usageSynchronized')
                  });
                })
                .catch(function(err) {
                  console.error(err);
                  throw err;
                })
              }
            }
          )
          .catch(function(err) {
            console.error(err);
            throw err;
          })
        }
        
    })
    .catch(function(err) {
      console.error(err);
      throw err;
    });
};
/*********** FUNCTIONS TO REMOTE (fetch, save and delete) *************/
/********* ANNOTATIONS *********/
UsageManager.prototype.fetchAnnotationsFromRemote = isbn => {
  return UsageManager.prototype.getAnnotationsUsage(isbn)
    .then(function(usage) {
      return featureManager.feature('Notes/Highlights-Sync').promise(usage);
    })
    .catch(Errors.AnnotationsServiceError, function(e) {
      errorHandleForAnnotationsServiceError(e, false);
    })
    .catch(function(e) {
      //Currently we don't do any error specific handling, but we may add some later, such as retrying the call.
      throw e;
    });
};

UsageManager.prototype.saveAnnotationsToRemote = (isbn, skipLocalUpdate) => {
  return UsageManager.prototype.getAnnotationsUsage(isbn)
    .then(function(usage) {
      return featureManager.feature('Notes/Highlights-Save').promise(usage, skipLocalUpdate);
    });
};

UsageManager.prototype.deleteAnnotationsFromRemote = (isbn, skipLocalUpdate) => {
  return UsageManager.prototype.getAnnotationsUsage(isbn)
    .then(function(usage) {
      return featureManager.feature('Notes/Highlights-Delete').promise(usage, skipLocalUpdate);
    });
};

/********* COMICBOOK ANNOTATIONS *********/

UsageManager.prototype.fetchComicBookAnnotationsFromRemote = isbn => {
  return UsageManager.prototype.getComicBookAnnotationsUsage(isbn)
    .then(function(usage) {
      return featureManager.feature('Notes/Highlights-Sync').promise(usage);
    })
    .catch(Errors.AnnotationsServiceError, function(e) {
      errorHandleForAnnotationsServiceError(e, false, true);
    })
    .catch(function(e) {
      //Currently we don't do any error specific handling, but we may add some later, such as retrying the call.
      throw e;
    });
};

UsageManager.prototype.exportComicBookAnnotationsToGoogle = function(isbn, title) {
  return UsageManager.prototype.getComicBookAnnotationsUsage(isbn)
  .then(function(usage) {
    var annotationsData = $.extend(true, {}, usage);
    if (Object.keys(annotationsData.spines).length > 0) {
      var contentType = 'application/rtf';
      var rtfString = createRTFAnnotationsReport(annotationsData.spines, title);  

      return google.createFile(title, contentType, rtfString)
      .then(function(response2) {
        console.log(response2);
        return usage;
      })
      .catch(function(err) {
        console.error("Unable to export to Google Drive");
        throw err;
      });
    } else {
      return usage;
    }
  })
};

UsageManager.prototype.exportComicBookAnnotationsToOneDrive = function(isbn, title) {
  return UsageManager.prototype.getComicBookAnnotationsUsage(isbn)
  .then(function(usage) {
    var annotationsData = $.extend(true, {}, usage);
    if (Object.keys(annotationsData.spines).length > 0) {
      var contentType = 'application/rtf';
      var rtfString = createRTFAnnotationsReport(annotationsData.spines, title);  

      return onedrive.createFile(title, contentType, rtfString)
      .then(function(response2) {
        console.log(response2);
        return usage;
      })
      .catch(function(err) {
        console.error("Unable to export to One Drive");
        throw err;
      });
    } else {
      return usage;
    }
  })
};

UsageManager.prototype.saveComicBookAnnotationsToRemote = (isbn, skipLocalUpdate) => {
  return UsageManager.prototype.getComicBookAnnotationsUsage(isbn)
    .then(function(usage) {
      return featureManager.feature('Notes/Highlights-Save').promise(usage, skipLocalUpdate);
    });
};

UsageManager.prototype.deleteComicBookAnnotationsFromRemote = (isbn, skipLocalUpdate) => {
  return UsageManager.prototype.getComicBookAnnotationsUsage(isbn)
    .then(function(usage) {
      return featureManager.feature('Notes/Highlights-Delete').promise(usage, skipLocalUpdate);
    });
};

/********* AUDIO BOOKMARKS *********/
UsageManager.prototype.fetchAudioPositionsFromRemote = function(id) {
  return UsageManager.prototype.getAudioAnnotationsUsage(id).then(function(usage) {
    return featureManager.feature('AudioPosition-Fetch').promise(usage);
  });
};

UsageManager.prototype.saveAudioPositionsToRemote = function(id, skipLocalUpdate) {
  return UsageManager.prototype.getAudioAnnotationsUsage(id).then(function(usage) {
    return featureManager.feature('AudioPosition-Save').promise(usage, skipLocalUpdate);
  });
};

UsageManager.prototype.deleteAudioPositionsFromRemote = function(id, skipLocalUpdate) {
  return UsageManager.prototype.getAudioAnnotationsUsage(id).then(function(usage) {
    return featureManager.feature('AudioPosition-Delete').promise(usage, skipLocalUpdate);
  });
};

//Used by FSS
UsageManager.prototype.updateActivityToRemote = function (isbn) {
  return this._userManager.getCurrentUser().then(function(user) {
      // var userId = user.id;
      var uuid = user.bookvaultId; // user uuid
      var library = user.libraryPrefix; // customer number
      var jqXhr = $.ajax({
          type: 'POST',
          data: JSON.stringify({ "appid": appid, "uuid": uuid, "library": library, "isbn": isbn }),
          contentType: 'application/json',
          url: utils.getRemoteServerOrigin().path('activity')
      });
      console.log('Activity Path', utils.getRemoteServerOrigin().path('activity'));
      return utils.deferredToPromise(jqXhr).then(function (response) {
        return response;
      }).catch(function(error) {
          console.error("activity error:"+JSON.stringify(error));
      });
  });
};

//Used by FSS
UsageManager.prototype.updateListeningActivityToRemote = function (id) {
  return this._userManager.getCurrentUser().then(function(user) {
      // var userId = user.id;
      var uuid = user.bookvaultId; // user uuid
      var library = user.libraryPrefix; // customer number
      var jqXhr = $.ajax({
          type: 'POST',
          data: JSON.stringify({ "appid": appid, "uuid": uuid, "library": library, "audiobookId": id }),
          contentType: 'application/json',
          url: utils.getRemoteServerOrigin().path('activity')
      });
      return utils.deferredToPromise(jqXhr).then(function (response) {
          return response;
      }).catch(function(error) {
          console.error("activity error:"+JSON.stringify(error));
      });
  });
};

/***** Close Comic Book **************/
UsageManager.prototype.closeComicBook = function (isbn) {
  return this._userManager.getCurrentUser().then(function(user) {
      // var userId = user.id;
      var uuid = user.bookvaultId; // user uuid
      var library = user.libraryPrefix; // customer number
      var jqXhr = $.ajax({
          type: 'POST',
          data: JSON.stringify({ "appid": appid, "uuid": uuid, "library": library, "isbn": isbn }),
          contentType: 'application/json',
          url: utils.getRemoteServerOrigin().path('close-book')
      });
      return utils.deferredToPromise(jqXhr).then(function (response) {
          return response;
      }).catch(function(error) {
          console.error("closeBook error:"+JSON.stringify(error));
          return Promise.reject(error);
      });
  });
};

/********* PAGE USAGE / CURRENT POSITIONS *********/
UsageManager.prototype.fetchCurrentPositionFromRemote = isbn => {
  return UsageManager.prototype.getPageUsage(isbn)
    .then(function(usage) {
      return featureManager.feature('CurrentPosition-Fetch').promise(usage);
    })
    .catch(Errors.AnnotationsServiceError, function(e) {
      errorHandleForAnnotationsServiceError(e, false);
    })
    .catch(function(e) {
      //Currently we don't do any error specific handling, but we may add some later, such as retrying the call.
      throw e;
    });
}
UsageManager.prototype.saveCurrentPositionToRemote = isbn => {
  return UsageManager.prototype.getPageUsage(isbn)
    .then(function(usage) {
      return featureManager.feature('CurrentPosition-Save').promise(usage);
    });
}

/********* COMIC BOOK PAGE USAGE / CURRENT POSITIONS *********/
UsageManager.prototype.fetchCurrentComicPositionFromRemote = isbn => {
  return UsageManager.prototype.getComicBookPageUsage(isbn)
    .then(function(usage) {
      return featureManager.feature('CurrentPosition-Fetch').promise(usage);
    })
    .catch(Errors.AnnotationsServiceError, function(e) {
      errorHandleForAnnotationsServiceError(e, false, false);
    })
    .catch(function(e) {
      //Currently we don't do any error specific handling, but we may add some later, such as retrying the call.
      throw e;
    });
}
UsageManager.prototype.saveCurrentComicPositionToRemote = isbn => {
  return UsageManager.prototype.getComicBookPageUsage(isbn)
    .then(function(usage) {
      return featureManager.feature('CurrentPosition-Save').promise(usage);
    });
}
/********* AUDIO LISTENING POSITION *********/
UsageManager.prototype.fetchListeningPositionFromRemote = function(id) {
  return UsageManager.prototype.getPositionsUsage(id).then(function(usage) {
    return featureManager.feature('ListeningPosition-Fetch').promise(usage);
  });
};

UsageManager.prototype.saveListeningPositionsToRemote = function(id) {
  return UsageManager.prototype.getPositionsUsage(id).then(function(usage) {
    return featureManager.feature('ListeningPosition-Save').promise(usage);
  });
};
// TODO: disable functions
function disableCurrentPositionSave(pageUsage) {
  return pageUsage.location;
}
function disableListeningPositionsSaveFeature(positionUsage) {
  return positionUsage.audiopositions;
}
function disableFeatures(usage) {
  return usage;
}

let errorHandleForAnnotationsServiceError = function (e, isAudio) {
  var popupModal = PopupFactory.create(PopupFactory.TYPE.MODAL, { id: 'warningMessage' });
  popupModal.$body.css('min-height', '230px');
  popupModal.$body.css('height', '230px');
  popupModal.$body.addClass('annotations'); // this makes the popup persist pagination change
  popupModal.setTitle('Notice');
  popupModal.addButton(
    'Ok',
    function(e) {
      popupModal.hide();
    },
    true,
  );
  var bugsnagText = e.responseText || 'Unspecified error';
  if (e.origin == 'annotations') {
    popupModal.setMessage(
      'We were unable to retrieve all of your notes and highlights.  Refreshing the page may resolve this issue.  You can continue reading if you would like.  We apologize for the inconvenience.',
    );
    bugsnagText += ' when fetching annotations for epub ' + isbn;
  } else if ((e.origin = 'position')) {
    popupModal.setMessage(
      'Your last reading position could not be retrieved. We apologize for the inconvenience.',
    );
    bugsnagText += 'when fetching reading position for epub ' + isbn;
  }
  popupModal.show();
  errorMonitor.bugsnag(new Error(bugsnagText));
  var messageInfo = {
    errorName: bugsnagText,
    errorReturnCode: e.ReturnCode,
    errorOrigin: e.origin,
    isbn: isbn,
  };
  SplashMessage.sendInfo(messageInfo);

  if (!isAudio) {
    return Promise.join(
      UsageManager.prototype.getPageUsage(isbn),
      UsageManager.prototype.getAnnotationsUsage(isbn),
      function (pageUsage, annotationsUsage) {
        return {pageUsage: pageUsage, annotationsUsage: annotationsUsage};
      })
  } else {
    return Promise.join(
      UsageManager.prototype.getPositionsUsage(isbn),
      UsageManager.prototype.getAudioAnnotationsUsage(isbn),
      function (positionsUsage, audioAnnotationsUsage) {
        return {positionsUsage: positionsUsage, audioAnnotationsUsage: audioAnnotationsUsage};
      })
  }
}
// fetch from remote and update local storages
UsageManager.prototype.fetchEbookUsageFromRemote = function(isbn) {
  return Promise.all([
    UsageManager.prototype.fetchCurrentPositionFromRemote(isbn),
    UsageManager.prototype.fetchAnnotationsFromRemote(isbn),
  ])
  .spread(function(pageUsage, annotationsUsage) {
    return { pageUsage: pageUsage, annotationsUsage: annotationsUsage };
  })
  .catch(Errors.AnnotationsServiceError, function(e) {
    errorHandleForAnnotationsServiceError(e, false);
  })
  .catch(Errors.PageServiceError, function(e) {
    //Currently we don't do any error specific handling, but we may add some later, such as retrying the call.
    throw e;
  });
};

UsageManager.prototype.fetchAudiobookUsageFromRemote = function(isbn) {
  return Promise.all([
    UsageManager.prototype.fetchListeningPositionFromRemote(isbn),
    UsageManager.prototype.fetchAudioPositionsFromRemote(isbn)
  ])
  .spread(function(positionsUsage, audioAnnotationsUsage) {
    return { positionsUsage: positionsUsage, audioAnnotationsUsage: audioAnnotationsUsage };
  })
  .catch(Errors.AnnotationsServiceError, function(e) {
    errorHandleForAnnotationsServiceError(e, true);
  })
  .catch(function(e) {
    throw e;
  }); 
};

export default UsageManager;
