import $ from 'jquery';
import _ from 'underscore';
import PopupFactory from '../../popup/PopupFactory';
import Keyboard from '../../Keyboard';
import Managers from '@axisnow/data/Managers';
import IndexedDBStorage from '@axisnow/storage/IndexedDBStorage';
import MainMenuTemplate from '../../templates/main-menu.hbs';
import Toc from '../../templates/toc-container.hbs';
import splashMessage from '../../SplashMessage';
import TocUtils from '../../menus/TocUtils';
import Menu from '../../menus/Menu';
import Helpers from '../../EpubReaderHelpers';
import spinnerProgress from '../../SpinnerProgress';
import Feature from '@axisnow/data/app-permission/Feature';
import FeatureGroup from '@axisnow/data/app-permission/FeatureGroup';
import AccessibilityManager from '../../accessibility/AcessibilityManager';
import utils from '@axisnow/util/utils';
import AXISAnalyticshelper from '../../AXISAnalyticshelper';

var $spinner = spinnerProgress;
var mainMenuPopup = PopupFactory.create(PopupFactory.TYPE.MAIN_MENU, 'Main Menu', {
  id: 'mainMenu2',
});
var popupModal = PopupFactory.create(PopupFactory.TYPE.MODAL, { id: 'goToPagePopup' });
var isAudiobook = false;
var firstLogOut = true;
var storageManager = new IndexedDBStorage();
var user = undefined;
var comicInOfflineContentLibrary;

var $mainMenuContent = $(MainMenuTemplate({}));
mainMenuPopup.clearContainerLayouts();
var $mainMenuLayout = mainMenuPopup.addContainerLayout($mainMenuContent);

var isTOCEnabled = true;
var lastAccessiblePage;

var logoutUser = function() {
  Managers.user.logoutCurrentUser();

  splashMessage.init({
    closeBtn: false,
    cssClass: 'splash-system',
  });
  splashMessage.message({
    Title: 'User logged out  successfully!',
    Icon: splashMessage.icons.CHECKALT,
  });
  splashMessage.$el.find('.splash-content h3').attr('role', 'alert');
  splashMessage.$el.show();
  setTimeout(function() {
    window.location = window.location;
    location.reload();
  }, 3000);
};

$('#app-navbar').on('click', '#btnToggleMainMenu', function() {
  $('.navbar-btn').removeClass('active');

  $('.popup').hide();
  mainMenuPopup.show();
  //$(this).tooltip('hide');

  // AccessibilityManager.setTabGroup("main_menu_popup");
  // AccessibilityManager.updateTabOrder("main_menu_popup");
});
Keyboard.on(Keyboard.Menu, 'reader', function() {
  $(opener).triggerHandler('click');
});

var $toc = mainMenuPopup.$el.find('.toc-container');
mainMenuPopup.toc = {
  $el: $toc,
  update: function(tocData) {
    $toc.html(
      Toc({
        tocData: tocData,
      }),
    );
  },
  clear: function() {
    $toc.empty();
  },
};

$toc.on('touchmove', function(event) {
  event.stopImmediatePropagation();
});

var setupButtons = function(menu) {
  var self = this;

  mainMenuPopup.$el.off('click', '.offline-lib-btn');
  mainMenuPopup.$el.on('click', '.offline-lib-btn', function() {
    if(menu.isAudiobook()){
      menu.findaway.pause();
    }   
    $('.popup').hide();
    loadlibrary();
    return false;
  });

  $('.download-btn').hide();
  mainMenuPopup.$el.off('click', '.download-btn');
  mainMenuPopup.$el.one('click', '.download-btn', function() {
    //ga('send', 'event', 'Cloud Download', menu.isbn, user.libraryPrefix);
    AXISAnalyticshelper.startTiming('Download', menu.isAudiobook() ? 'Audiobook ' + menu.findaway.currentTitle.id : 'Epub ' + menu.isbn);
    var downloadPromise = null;
    if (menu.isComicBook()) { 
      downloadPromise = downloadComicBook(menu.comic);
    } else {
      downloadPromise = downloadEpub(menu.readium, menu.ebookURL);
    }    
    return false;
  });

  mainMenuPopup.$el.on('click', '.back-btn', function() {
    Managers.navigation.getButton('back').then(function(data) {
      if (data.url) {
        window.location.href = data.url;
      }
    });
    return false;
  });

  mainMenuPopup.$el.on('click', '.logout-btn', function() {
    if (isAudiobook && firstLogOut) {
      menu.findaway.unload();
      firstLogOut = false;
    }
    logoutUser();
    return false;
  });
  mainMenuPopup.$el.on('click', '.close-book-btn', function() {
    // TODO Add logic for close-book-btn to talk to service
    //window.close();
    window.parent.close();
    return false;
  });

  mainMenuPopup.$el.on('click', '.go-to-btn', function() {
    //trigger main menu close button as it hides mobile device keyboard when clicked on Goto Location menu option
    mainMenuPopup.$el.find('.close').click();
    popupModal.clear();

    var firstPage = -1;
    var lastPage = -1;
    var currentPage = -1;
    var visibleText = '';

    if(((menu.isComicBook()) || (comicInOfflineContentLibrary != undefined)) && (menu.comic != undefined)){
      if(comicInOfflineContentLibrary != undefined){
        menu = comicInOfflineContentLibrary;
      }     
      firstPage = 1;
      lastPage = menu.comic.comicMetaData.pages.length;
      currentPage = window.sessionStorage.getItem('COMIC_CURRENT_PAGE');
      
      if(currentPage && currentPage >= 0){
        currentPage = parseInt(currentPage);
        visibleText = ' You are currently reading at page ' + currentPage +  '.';
      }
    } else {
      var pagination = menu.readium.reader.plugins.pagination;
      var mappedPageList = undefined;
      if (pagination) mappedPageList = pagination.getMappedPageList();

      if (!pagination) {
        return;
      }
      
      if (mappedPageList.length > 0) {
        var firstPageEntry = mappedPageList[0];
        firstPage = firstPageEntry.label.label
          ? firstPageEntry.label.label
          : firstPageEntry.label;
        lastPage = lastAccessiblePage || pagination.getLastPageNumber();
        
        var pages = pagination.getVisibleLabelItems();
        
        var hasVisiblePages = pages.length > 0;
        if (hasVisiblePages) {
          var visibleEntry = pages[0];
          currentPage = visibleEntry.label.label ? visibleEntry.label.label : visibleEntry.label;
          visibleText = ' You are currently reading at page ' + currentPage + '.';
        }
      }
    }

    popupModal.setTitle('Go to Page');

    if (firstPage >= 0 && lastPage >= 0) {
      var message = 'Enter a page between ' + firstPage + ' and ' + lastPage + '.' + visibleText;
      popupModal.setMessage(message);

      popupModal.addInputBox('Go to...');
      popupModal.addButton(
        'Go',
        function() {
          buttonGo();
        },
        true,
      );

      $(document).unbind('keypress.goToPage'); // prevent multiple handlings
      $(document).bind('keypress.goToPage', function (ev) {
        if (ev.target.id === 'inputBox') {
          if (ev.key === 'Enter') {
            buttonGo();
          } else if (ev.key === 'Escape') {
            $('#goToPagePopup .close').trigger('click');
          }
        }
      });

      // setTimeout(function() {
      //     AccessibilityManager.setTabGroup("go_to_page_popup");
      //     AccessibilityManager.updateTabOrder("go_to_page_popup", function() {
      //         AccessibilityManager.setFocus('#inputBox');
      //     });
      // }, 200);

      var buttonGo = function () {
        var val = popupModal.getInputValue();
        if(menu.isComicBook()){
          var isValidNumber = /^\d+$/.test(val);
          if ((parseInt(val) > parseInt(lastPage)) || (isValidNumber != true)){
            val = -1;
          }
        }
        else{
          // If we try to access a page not apart of the preview, 
          // set val to -1 so jumpToLabel will catch and display invalid location message
          if (parseInt(val) > parseInt(lastAccessiblePage)){
            val = -1;
          }  
        }

        if(menu.isComicBook()){
          if(val == -1){
            popupModal.hide();
            mainMenuPopup.$el.hide();
            popupModal.setMessage('Error: Invalid location entered! ' + message);
            popupModal.addInputBox('Go to...');
            popupModal.show();
          }
          else{
            $(window).triggerHandler('gotoPage', val);
            popupModal.hide();
          } 
        } else {
          popupModal.hide(); // FSS needs this to happen BEFORE we do the page jump, for TK-44540, else it messes with ADA NVDA focus
          mainMenuPopup.$el.hide();
          pagination.jumpToLabel(val).catch(function() {
            popupModal.hide();
            popupModal.setMessage('Error: Invalid location entered! ' + message);
            popupModal.addInputBox('Go to...');
            popupModal.show();

            // setTimeout(function() {
            //     AccessibilityManager.updateTabOrder("go_to_page_popup", function() {
            //         AccessibilityManager.setFocus('#inputBox');
            //         AccessibilityManager.announceText("Error: Invalid location entered! " + message);
            //     });
            // }, 200);
          });
        }
      }
    } else {
      popupModal.setMessage('This book does not support jumping to pages.');
    }

    popupModal.addButton(
      'Cancel',
      function() {
        popupModal.hide();
        mainMenuPopup.$el.hide();
      },
      false,
    );

    popupModal.show();
  });
};

var toggleTOC = function(menu) {
  if (isTOCEnabled) {
    mainMenuPopup.toc.$el.find('li').removeClass('enabled');
  } else {
    mainMenuPopup.toc.$el.find('li').addClass('disabled');
  }
};

var MainMenu = function() {
  Menu.apply(this, arguments);
  this.popup(mainMenuPopup);

  setupButtons(this);
};

MainMenu.prototype = Object.create(Menu.prototype);

//Manage Feature of Search menu through Feature API
var featureManager = Managers.feature;
var featureArr = [];
var menuItems = mainMenuPopup.$el.find('.pane.main-menu-items');
featureArr.push(
  new Feature(
    'OfflineMenu',
    function() {
      mainMenuPopup.$body.find('.download-btn,.offline-lib-btn').show();
      menuItems.removeClass('disable-offline');
      console.log('Offline menu feature enabled');
    },
    function() {
      mainMenuPopup.$body.find('.download-btn,.offline-lib-btn').hide();
      menuItems.addClass('disable-offline');
      console.log('Offline menu feature disabled');
    },
  ),
  new Feature(
    'BackButton',
    function() {
      mainMenuPopup.$body.find('.back-btn').hide();
      Managers.navigation.getButton('back').then(function(data) {
        if (data && data.btnType == 'back') {
          mainMenuPopup.$body.find('.back-btn').show();
        }
      });
      menuItems.removeClass('disable-MainMenu');
      console.log('Back button feature enabled');
    },
    function() {
      mainMenuPopup.$body.find('.back-btn').hide();
      console.log('Back button feature disabled');
    },
  ),
  new Feature(
    'LogoutButton',
    function() {
      mainMenuPopup.$body.find('.logout-btn').show();
      menuItems.removeClass('disable-MainMenu');
      menuItems.removeClass('disable-logout');
      console.log('Logout button feature enabled');
    },
    function() {
      mainMenuPopup.$body.find('.logout-btn').hide();
      if (menuItems.hasClass('disable-offline')) {
        menuItems.addClass('disable-MainMenu');
      } else {
        menuItems.addClass('disable-logout');
      }
      console.log('Logout button feature disabled');
    },
  ),
  new Feature(
    'TOCMenu',
    function() {
      if (isTOCEnabled == false) {
        isTOCEnabled = true;
        window.READIUM.reader.updateSettings({ enableInternalLinks: isTOCEnabled });
        toggleTOC();
      }
    },
    function() {
      if (isTOCEnabled == true) {
        isTOCEnabled = false;
        window.READIUM.reader.updateSettings({ enableInternalLinks: isTOCEnabled });
        toggleTOC();
      }
    },
  ),
  new Feature(
    'GoToButton',
    function() {
      mainMenuPopup.$body.find('.go-to-btn').show();
      menuItems.removeClass('disable-goTo');
      console.log('GoTo menu feature enabled');
    },
    function() {
      mainMenuPopup.$body.find('.go-to-btn').hide();
      menuItems.addClass('disable-goTo');
      console.log('GoTo menu feature disabled');
    },
  ),
  new Feature(
    'CloseBookButton',
    function() {
      mainMenuPopup.$body.find('.close-book-btn').show();
    },
    function() {
      mainMenuPopup.$body.find('.close-book-btn').hide();
    },
  ),
);
featureManager.featureGroup(new FeatureGroup('MainMenu', featureArr));
featureArr.push(
  new Feature(
    'DisplayOptions',
    function() {
      $('#btnToggleFontOptions').show();
      console.log('Display Options feature enabled');
    },
    function() {
      $('#btnToggleFontOptions').hide();
      console.log('Display Options feature disabled');
    },
  ),
);
featureManager.feature(featureArr);

function tocDataHasChildren(tocArr) {
  var out = [];
  if (!tocArr) {
    return out;
  }
  for (var i = 0; i < tocArr.length; i++) {
    tocArr[i].hasChildren = false;
    if (tocArr[i].children.length > 0) {
      tocArr[i].hasChildren = true;
      tocDataHasChildren(tocArr[i].children);
    }
    out.push(tocArr[i]);
  }
  return out;
}

// ----toc accessability---
function tocAccessibility(mainMenuPopup) {
  mainMenuPopup.toc.$el.find('ol').attr({
    role: 'list',
  });
  mainMenuPopup.toc.$el.find('ul').attr({
    role: 'list',
  });

  mainMenuPopup.toc.$el.find('.book-toc-ol').on('focus', function() {
    // If no selected element, select the first by default
    if (!$(this).find('[aria-selected=true]').length) {
      $(this)
        .find('li:first')
        .attr('aria-selected', 'true')
        .focus();
    } else {
      $(this)
        .find('[aria-selected=true]')
        .focus();
    }
  });
  mainMenuPopup.toc.$el.find('.book-toc-ol').on('keydown', function(e) {
    var currentItem = $(this).find('[aria-selected=true]');
    if (currentItem.children('ul').length > 0) {
      var eleNext = currentItem.clone().next();
      var elePre = currentItem.clone().prev();
      currentItem.attr('aria-selected', 'false');
      $(currentItem)
        .find('li:first')
        .attr('aria-selected', 'true')
        .focus();
      currentItem = $(currentItem).find('li:first');
    }
    var keyCode = e.keyCode + (e.shiftKey ? 1000 : 0);
    switch (keyCode) {
      case 1009: // FSS shift tab fix for TK-42253, go back up into Go To page button
          var currentFocus = $(document.activeElement);
          if (!currentFocus.prev().length) {
              // we're on first TOC element, shift tab moves us out of TOC into Go To page (or OCL if GoTo not available)
              // note, add this to up arrow below if we want same behavior
              e.preventDefault();
              var gotopage_btn = $('.go-to-btn');
              gotopage_btn.length > 0 ? gotopage_btn.focus() : $('.offline-lib-btn').focus();
          }else {
            currentItem.attr("aria-selected", "false");                    
            currentItem.prev().attr("aria-selected", "true");
          }
          break;
      case 38: // Up arrow
        if (currentItem.prev().length) {
          currentItem.attr('aria-selected', 'false');
          currentItem
            .prev()
            .attr('aria-selected', 'true')
            .focus();
        } else if (elePre) {
          currentItem.attr('aria-selected', 'false');
          $(elePre)
            .attr('aria-selected', 'true')
            .focus();
        }
        e.preventDefault();
        break;
      case 40: // Down arrow
        if (currentItem.next().length) {
          currentItem.attr('aria-selected', 'false');
          currentItem
            .next()
            .attr('aria-selected', 'true')
            .focus();
        } else if (eleNext) {
          currentItem.attr('aria-selected', 'false');
          $(eleNext)
            .attr('aria-selected', 'true')
            .focus();
        }
        e.preventDefault();
        break;
      case 9: 
        if (currentItem.next().length ) {
          currentItem.attr("aria-selected", "false");
          currentItem.next().attr("aria-selected", "true").focus();
        } else if(eleNext ){
          currentItem.attr("aria-selected", "false");                    
          $(eleNext).attr("aria-selected", "true").focus();
        }
        e.preventDefault();
        break;
      case 13: // Enter
        currentItem.find('a').trigger('click');
        break;
    }
  });
}

MainMenu.prototype.show = function() {
  var self = this;
  isAudiobook = this.isAudiobook();

  storageManager.isIndexDBSupported().then(function(result) {
    if (!result || self.isAudiobook()) {
      mainMenuPopup.$el.find('.download-btn').hide();
      mainMenuPopup.$el.find('.offline-lib-btn').hide();
      mainMenuPopup.$el.find('.pane.main-menu-items').addClass('disable-offline');
    } else if (featureManager.feature('OfflineMenu').isEnabled()) {
      /*else if(this.isAudiobook()){
                    mainMenuPopup.$el.find(".download-btn").hide();
                    mainMenuPopup.$el.find(".offline-lib-btn").hide();
                    mainMenuPopup.$el.find(".pane.main-menu-items").addClass("disable-offline");
                }*/
        mainMenuPopup.$el.find('.download-btn').show();
        mainMenuPopup.$el.find('.offline-lib-btn').show();
    }
    /**
     * If book is already downloaded we disabled the download button
     */
    if (self.isbn && self.isbn.trim()) {
      Managers.library.getBookInfo(self.isbn).then(function(book) {
        if (book && book.downloaded) {
          mainMenuPopup.$body.find('.download-btn').prop('disabled', true);
          mainMenuPopup.$body.find('.icon-download').addClass('hidden');
          mainMenuPopup.$body.find('.icon-offline').removeClass('hidden');
        } else {
          mainMenuPopup.$body.find('.download-btn').prop('disabled', false);
          mainMenuPopup.$body.find('.icon-download').removeClass('hidden');
          mainMenuPopup.$body.find('.icon-offline').addClass('hidden');
        }
      });
    }
  });

  if (
    self.readium &&
    self.readium.reader.plugins.pagination &&
    featureManager.feature('GoToButton').isEnabled()
  ) {
    var mappedPageList = self.readium.reader.plugins.pagination.getMappedPageList();
    if (mappedPageList.length < 1) {
      mainMenuPopup.$el.find('.pane.main-menu-items').addClass('disable-goTo');
      mainMenuPopup.$body.find('.go-to-btn').hide();
    } else {
      mainMenuPopup.$el.find('.pane.main-menu-items').removeClass('disable-goTo');
      mainMenuPopup.$body.find('.go-to-btn').show();
    }
  }
  if (this.isAudiobook()) {
    var tocInfo = this.findaway.currentTitle.chapters;
    var intailPartNumber = tocInfo[0].part_number;
    mainMenuPopup.$body.find('.go-to-btn').hide();
    var tocData = _.map(tocInfo, function(item, index) {
      return {
        title: item.chapter_number === 0 ? 'Intro' : 'Chapter ' + item.chapter_number,
        track: index,
        part_number: item.part_number,
        children: [],
      };
    });
    var tocDataFinal = _.reduce(
      tocData,
      function(tocDataFinal, obj) {
        if (intailPartNumber != obj.part_number) {
          intailPartNumber = obj.part_number;
          var childrens = $.grep(tocData, function(itemToc) {
            return itemToc.part_number == intailPartNumber;
          });
          tocDataFinal.push({
            title: 'Part ' + obj.part_number,
            //track: childrens[0].track,
            children: childrens,
          });
        } else {
          if (obj.title == 'Intro') {
            obj.hasChildren = false;
            if (obj.children.length > 0) {
              obj.hasChildren = true;
            }
            tocDataFinal.push(obj);
          }
        }
        return tocDataFinal;
      },
      [],
    );
    tocData = tocDataHasChildren(tocData);
    tocDataFinal = tocDataHasChildren(tocDataFinal);
    mainMenuPopup.toc.update(intailPartNumber == tocInfo[0].part_number ? tocData : tocDataFinal);
    tocAccessibility(mainMenuPopup);
    
    $('#svg-spinner').css('margin-top', '-145px');
    mainMenuPopup.toc.$el.find('a').on('click', function(e) {
      e.preventDefault();
      var track = $(this).data('audiobook-track');

      if (track >= 0) {
        self.findaway.changeTrack(track).then(function() {
        }); /*.catch(function (error) {
                            $spinnerAudioBook.spinner("stop");
                            return utils.isOnline().then(function () {
                                error.code = "Audiobook Error";
                                error.message = "Unable to access audiobook. Please try again later.";

                                displayError(error);
                            }).catch(function (error) {
                                error.code = "Device Is Offline";
                                error.message = "Please return online for full audiobook functionality.";
                                error.feedbackBtn = false;

                                displayError(error);
                            });
                        });*/
      }
      $('#btnPause span:first').attr('class', 'icon icon-audio-pause');
      setTimeout(function() {
        $('#btnPause').attr('title', 'pause');
      }, 200);
    });
    return;
  }

  if (this.isComicBook()) {
    var tocDataFinal = [];
    console.log('this.comic', this.comic);
    var tocInfo = this.comic.tocData;

    //mainMenuPopup.$body.find('.go-to-btn').hide();
    console.log('tocInfo', tocInfo);
    var tocData = _.map(tocInfo, function (item, index) {
      return {
        title: item.title,
        track: item.index,
        children: item.children
      };
    });
    console.log('tocData', tocData);
    tocData = tocDataHasChildren(tocData);
    mainMenuPopup.toc.update(tocData);
    tocAccessibility(mainMenuPopup);
    $('#svg-spinner').css('margin-top', '-145px');
    mainMenuPopup.toc.$el.find('a').on('click', function (e) {
      e.preventDefault();
      var href = $(this).data('epub-href');
      mainMenuPopup.hide(false);
      $('#epubContentIframe').focus();
      var index = $(this).data('audiobook-track');
      var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
      var formatStatus = false;
      if(!format.test(index)){
        $("#viewerSelect").val("page").attr("selected","selected");
        formatStatus = true;
      }else {
        /**
         * it used filtered the user selected frame item in the menu bar
         */
        var outPut;
        tocData.forEach(itemFind => {
         if(itemFind.children.length > 0){ 
          itemFind.children.filter(data => {
           if(data.index == index){
            console.log(itemFind)
           return outPut = itemFind;            
           }
          })
         }
        })
      }      
     /**
      * it used to update the drop down view which based on menu list click
      */
      window.sessionStorage.setItem('TOC-CLICKED-ITEM-KEY',JSON.stringify({index:parseInt(index),data:formatStatus ? tocData[parseInt(index)] :outPut}))
      $('#app-container').trigger('navigatePage',index);


    });
    mainMenuPopup.$el.find('.download-btn').hide();
    mainMenuPopup.$el.find('.offline-lib-btn').hide();
    mainMenuPopup.$el.find('.pane.main-menu-items').addClass('disable-offline');
    if (featureManager.feature('OfflineMenu').isEnabled()) {
      mainMenuPopup.$body.find('.download-btn,.offline-lib-btn,.go-to-btn').show();
      menuItems.removeClass("disable-offline");
      comicInOfflineContentLibrary = this;
    };
    calculateHeightOftMainMenuItems();
    return;
  }

  var currentPackageDocument = self.readium.packageDocument;
  currentPackageDocument.generateTocListJSON(function(tocData) {
    var tocUrl = currentPackageDocument.getToc();

    var tocUtils = new TocUtils(self.readium);

    tocUtils.setTocData(tocData);
    tocData = tocDataHasChildren(tocData);
    mainMenuPopup.toc.update(tocData);
    toggleTOC();

    mainMenuPopup.toc.$el.find('a').on('click', function(e) {
      e.preventDefault();
      var href = $(this).data('epub-href');

      if (isTOCEnabled) {
        AXISAnalyticshelper.startTiming('Paging', 'TOC'); // this will be ended by pagination event in EpubReader
        self.readium.reader.openContentUrl(href, tocUrl, undefined);
        mainMenuPopup.hide(false);
        $('#epubContentIframe').focus();
      }
    });

    tocAccessibility(mainMenuPopup);
    mainMenuPopup.$el.on('downloadEpub', function() {
      var throttledDownload = _.throttle(downloadEpub, 6000);
      throttledDownload(self.readium, self.ebookURL);
      return false;
    });

    // Disabling Offline Access button if book is already downloaded
    if (self.isbn && self.isbn.trim()) {
      Managers.library.getBookInfo(self.isbn).then(function(book) {
        if (book && book.downloaded) {
          mainMenuPopup.$body.find('.download-btn').prop('disabled', true);
          mainMenuPopup.$body.find('.icon-download').addClass('hidden');
          mainMenuPopup.$body.find('.icon-offline').removeClass('hidden');
        } else {
          mainMenuPopup.$body.find('.download-btn').prop('disabled', false);
          mainMenuPopup.$body.find('.icon-download').removeClass('hidden');
          mainMenuPopup.$body.find('.icon-offline').addClass('hidden');
        }
      });
    }

    // utils.isOnline().then(function() {
    //     mainMenuPopup.$body.find('.go-to-btn').show();
    //     menuItems.removeClass("disable-goTo");
    // }).catch(function() {
    //     mainMenuPopup.$el.find(".pane.main-menu-items").addClass("disable-goTo");
    //     mainMenuPopup.$body.find('.go-to-btn').hide();
    // });

    // AccessibilityManager.updateTabOrder("main_menu_popup");
    calculateHeightOftMainMenuItems();
  });
};
//calculates height of main menu items
function calculateHeightOftMainMenuItems() {
  var menuItems = mainMenuPopup.$body.find("button:not([style*='display: none'])");
  var minHeightOfMenuItem = menuItems.css('min-height');
  var heightOfMenuItems = menuItems.length * minHeightOfMenuItem.split('px')[0];
  mainMenuPopup.$body
    .find('.main-menu-items')
    .attr('style', 'height: ' + heightOfMenuItems + 'px!important');
  var cssOfTOC = mainMenuPopup.toc.$el.css('border-top').split('px')[1];
  mainMenuPopup.toc.$el.attr(
    'style',
    'border-top: ' + heightOfMenuItems + 'px' + cssOfTOC + '!important',
  );
}
MainMenu.prototype.toc = {
  $el: $toc,
  update: function(tocData) {
    $toc.html(
      Toc({
        tocData: tocData,
      }),
    );
  },
  clear: function() {
    $toc.empty();
  },
};

MainMenu.prototype.getButton = function(name) {
  var btnIconSelector = {
    download: '.download-btn',
    offlineLibrary: '.offline-lib-btn',
    goToPage: '.go-to-btn',
    closeBook: '.close-book-btn',
  };
  return mainMenuPopup.$el.find(btnIconSelector[name]);
};

MainMenu.prototype.setLastAccessiblePage = function(lastPage) {
  lastAccessiblePage = lastPage;
};

var loadlibrary = function() {
  $('html').attr('data-theme', 'library');

  var urlParams = Helpers.getURLQueryParams();
  var libraryURL;
  if (urlParams.epub) {
    libraryURL = new URL('/epub_library.json', urlParams.epub);
    libraryURL.protocol = 'offline:';
  } else if (urlParams.comic) {
    libraryURL = new URI('/epub_library.json', urlParams.comic);
    libraryURL.protocol('offline:');
  }
  else {
    libraryURL = urlParams.epubs;
  }

  $(window).triggerHandler('loadlibrary', libraryURL);
  //$(window).trigger('loadlibrary');
};

var downloadComicBook = function(comicManager){
  $($spinner.$el)
      .find('.splash-icon')
      .focus();
  $('nav, #app-container')
    .find('*')
    .attr({ disabled: 'disabled' });
  $('#statusDiv').text('Downloading to your browser for offline access.');
  $('.popup').hide();

  $spinner.start();
  var ms = (Math.random() * 3 + 1) * 1000;
  var currentPackageDocument = comicManager.comicMetaData;

  var bookName = '';
  if (!!currentPackageDocument) {
    bookName = currentPackageDocument.title;
  }

  console.log('ComicURL:' + currentPackageDocument.url);

  var progressBar = startSpinner();
  return comicManager
    .cacheComicBook(currentPackageDocument.url, progressBar)
    .then(function() {
      removeSpinner(bookName);
      $('#app-navbar, #app-container, #popup-container').attr('aria-hidden', 'true');
      $('#statusDiv').text('Downloading Complete.');
      $('.bkMrkOCL').focus();

      //Disable download offline access if user already download
      mainMenuPopup.$body.find('.download-btn').prop('disabled', true);
      mainMenuPopup.$body.find('.icon-download').addClass('hidden');
      mainMenuPopup.$body.find('.icon-offline').removeClass('hidden');

      splashMessage.$el.find('.firstElement').focusin(function() {
        $('.bkMrkOCL').focus();
      });
      splashMessage.$el.find('.lastElement').focusin(function(e) {
        $('.bkMrkOCL').focus();
      });
      $('nav, #app-container')
        .find('*')
        .removeAttr('disabled');
      // AccessibilityManager.announceText("Downloading Complete.")
      // setTimeout(function() {
      //     AccessibilityManager.setFocus(".readPrevBook")
      // }, 1500)
      setTimeout(function() {
        $('.bkMrkOCL').focus();
      }, 50)
    })
    .then(function () {
      progressBar.reset('','#fff');
    })
    .catch(function(err) {
      console.error(err);
    });
}

var startSpinner = function() {

  var progressBar = new function() {
    var _spinner = $spinner;

    _spinner.setValue.bind(_spinner);

    this.progress = function(val) {
      _spinner.setValue(val);
    };

    this.reset = function(msg, color) {
      if (!color) { 
        var color = '#004976'; 
      }
      $(_spinner.$el)
          .find('svg .bar')
          .css({ stroke: color });
      _spinner.reset();
      $(_spinner.$el)
          .find('.spin-prgs-msg.line2')
          .text(msg);
    };
  }();

  return progressBar;
};

var displayMemoryWarning = function(bookName) {
  let label = 'Return to your book';
  if(bookName){
    label += ': ' + bookName;
  } else {
    label += '.';
  }

  splashMessage.init({
    closeBtn: false,
    cssClass: 'splash-busy app-splash-book-downloaded',
  });
  splashMessage.message({
    Title: 'Error!',
    Messages: [
      'There is not enough space to download your book to your browser for offline access.',
      'Please delete other downloaded books and try again.',
    ],
    Buttons: [
      {
        label: label,
        cssClass: 'readPrevBook btn-primary',
      },
    ],
    Icon: splashMessage.icons.ERROR,
  });

  splashMessage.$el.find('.readPrevBook').one('click', function(e) {
    $('#popup-container').attr('aria-hidden', 'false');
    splashMessage.$el.hide();
  });
  splashMessage.$el.show();
};

var removeSpinner = function(bookName) {
  let label = 'Return to your book';
  if(bookName){
    label += ': ' + bookName;
  } else {
    label += '.';
  }
  $spinner.stop();
  splashMessage.init({
    closeBtn: false,
    cssClass: 'splash-busy app-splash-book-downloaded',
  });
  splashMessage.message({
    Title: 'Success!',
    Messages: [
      'Your book is downloaded to your browser for offline access.',
      'For easy access later, go to the Offline Content Library and create a bookmark in your browser.',
    ],
    Buttons: [
      {
        label: label,
        cssClass: 'readPrevBook btn-primary',
      },
    ],
    Icon: splashMessage.icons.CHECK,
  });

  splashMessage.$el.find('.bkMrkOCL').one('click', function(e) {
    //ga('send', 'event', 'Download Confirmation Page', 'Click', 'Bookmark OCL');
    $('#popup-container').attr('aria-hidden', 'false');
    splashMessage.$el.hide();
    bookmarkOcl();
  });

  splashMessage.$el.find('.readPrevBook').one('click', function(e) {
    $('#popup-container').attr('aria-hidden', 'false');
    splashMessage.$el.hide();
  });

  splashMessage.$el.find('.goToOcl').one('click', function(e) {
    $('#popup-container').attr('aria-hidden', 'false');
    splashMessage.$el.hide();    
    loadlibrary();
  });
  splashMessage.$el.show();
};

var downloadEpub = function(readium, ebookURL) {
  // AccessibilityManager.panelCloseHandler(function() {
  //     AccessibilityManager.disableElements(["#reflowable-content-frame"])
  //     AccessibilityManager.announceText("Downloading to your browser for offline access.")
  // })
  $($spinner.$el)
      .find('.splash-icon')
      .focus();
  $('nav, #app-container')
    .find('*')
    .attr({ disabled: 'disabled' });
  $('#statusDiv').text('Downloading to your browser for offline access.');
  $('.popup').hide();
  $spinner.start();
  var ms = (Math.random() * 3 + 1) * 1000;
  var label = 'Return to your book';
  var currentPackageDocument = readium.packageDocument;

  if (!!currentPackageDocument) {
    label += ': ' + currentPackageDocument.getMetadata().title;
  } else {
    label += '.';
  }

  console.log('ebookURL:' + ebookURL);

  var progressBar = new function() {
    var _spinner = $spinner;

    _spinner.setValue.bind(_spinner);

    this.progress = function(val) {
      _spinner.setValue(val);
    };

    this.reset = function(msg, color) {
      if (!color) {
        var color = '#004976';
      }
      $(_spinner.$el)
          .find('svg .bar')
          .css({ stroke: color });
      _spinner.reset();
      $(_spinner.$el)
          .find('.spin-prgs-msg.line2')
          .text(msg);
    };
  }();
  
  readium
    .cacheEpub(ebookURL, progressBar)
    .then(function() {
      $spinner.stop();
      splashMessage.init({
        closeBtn: false,
        cssClass: 'splash-busy app-splash-book-downloaded',
      });
      splashMessage.message({
        Title: 'Success!',
        Messages: [
          'Your eBook is downloaded to your browser for offline access.',
          'For easy access later, go to the Offline Content Library and create a bookmark in your browser.',
        ],
        Buttons: [
          {
            label: label,
            cssClass: 'readPrevBook btn-primary',
          },
        ],
        Icon: splashMessage.icons.CHECK,
      });

      splashMessage.$el.find('.readPrevBook').one('click', function(e) {
        splashMessage.$el.hide();
        $('#app-navbar, #app-container, #opup-container').attr('aria-hidden', 'false');
        // setTimeout(function() {
        //     AccessibilityManager.enableElements(["#reflowable-content-frame"])
        //     AccessibilityManager.dispatchAction('focusContentElement');
        // }, 100)
      });
      splashMessage.$el.show();
      $('#app-navbar, #app-container, #popup-container').attr('aria-hidden', 'true');
      $('#statusDiv').text('Downloading Complete.');
      $('.readPrevBook').focus();

      splashMessage.$el.find('.firstElement').focusin(function() {
        $('.readPrevBook').focus();
      });
      splashMessage.$el.find('.lastElement').focusin(function(e) {
        $('.readPrevBook').focus();
      });
      $('nav, #app-container')
        .find('*')
        .removeAttr('disabled');
      // AccessibilityManager.announceText("Downloading Complete.")
      // setTimeout(function() {
      //     AccessibilityManager.setFocus(".readPrevBook")
      // }, 1500)
    })
    .then(function () {
      progressBar.reset('','#fff');
    })
    .catch(function(err) {
      console.error(err);
    });
};

var displayError = function(error) {
  error.Messages = error.message;
  error.Type = 'Warning';

  splashMessage.init({
    closeBtn: false,
    cssClass: 'splash-system',
    urlParams: urlParams,
  });

  if (typeof error == 'string') {
    error = {
      Type: 'Error',
      Messages: ['An unexpected error has occurred.', error],
    };
  }

  error.Title = error.code ? error.code.replace('_', ' ') : Strings.err_dlg_title;

  splashMessage.message(error);
  splashMessage.$el.show();
};

MainMenu.prototype.unbindListeners = function() {
  if (mainMenuPopup.$el) {
    mainMenuPopup.$el.off('click', '.offline-lib-btn');
    mainMenuPopup.$el.off('click', '.download-btn');
    mainMenuPopup.$el.off('click', '.back-btn');
    mainMenuPopup.$el.off('click', '.logout-btn');
    mainMenuPopup.$el.off('click', '.close-book-btn');
    mainMenuPopup.$el.off('click', '.go-to-btn');
  }
};

export default MainMenu;
