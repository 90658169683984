var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h2 aria-hidden=\"true\" class=\"sr-only\">\n    "
    + alias4(((helper = (helper = lookupProperty(helpers,"titleSR") || (depth0 != null ? lookupProperty(depth0,"titleSR") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"titleSR","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":15}}}) : helper)))
    + "\n</h2>\n<button class=\"btn btn-bg-none btn-add-bookmark\" aria-label=\"Add Bookmark\">\n    <span class=\"icon "
    + alias4(((helper = (helper = lookupProperty(helpers,"icon") || (depth0 != null ? lookupProperty(depth0,"icon") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"icon","hash":{},"data":data,"loc":{"start":{"line":5,"column":22},"end":{"line":5,"column":30}}}) : helper)))
    + "\" aria-hidden=\"true\"></span>\n    <h2 class=\"popup-title\" aria-hidden=\"true\">\n        "
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":7,"column":17}}}) : helper)))
    + "\n    </h2>\n</button>\n<button type=\"button\" class=\"close js-close-popup\" aria-label=\"Close\">\n    <span aria-hidden=\"true\" class=\"icon icon-close\"></span>\n</button>\n";
},"useData":true});