import $ from 'jquery';
import PopupFactory from '../../popup/PopupFactory';
import Helpers from '../../EpubReaderHelpers';
import InlineAnnotationsMenu from '../../menus/inline/InlineAnnotationsMenu';
import InlineHighlightOptions from '../../templates/inline-menu/in-place-highlight-options.hbs';
import AccessibilityManager from '../../accessibility/AcessibilityManager';
import moment from 'moment-timezone';

//Highlights
var inlineHighlightOptions = PopupFactory.create(PopupFactory.TYPE.IN_PLACE, {
  id: 'appHighlightOptions',
  cssClass: 'app-highlight-options',
});
inlineHighlightOptions.$body.html(InlineHighlightOptions({}));
var oldHide = inlineHighlightOptions.hide;

inlineHighlightOptions.hide = function() {
  this.$el.trigger('hideInlineHighlights');
  oldHide.call(this);
};

var InlineHighlightsMenu = function() {
  InlineAnnotationsMenu.apply(this, arguments);
  this.popup(inlineHighlightOptions);
};

InlineHighlightsMenu.prototype = Object.create(InlineAnnotationsMenu.prototype);

InlineHighlightsMenu.prototype.update = function() {
  this.centerMenuToSelection();
};

InlineHighlightsMenu.prototype.show = Promise.method(function(triggerElement, isNewHighlight) {
  let self = this;
  inlineHighlightOptions.hideAll();
  this.bindEvents();
  //this.annotationUtils.clearSelection();
  this.isNewHighlight = isNewHighlight;
  this.centerMenuToSelection();
  var color = this.selectedHighlightType() || 'yellow';

  inlineHighlightOptions.show(triggerElement);

  inlineHighlightOptions.$el.data(this.textSelectionEvent());
  var name = $("input[id^='highlightColor']")[0] ? $("input[id^='highlightColor']")[0].name : 'selected-highlight-color';
  inlineHighlightOptions.$el.find("input[name='" + name + "']").each(function() {
    $(this).attr('aria-checked', 'false');
    $(this).attr('aria-label', $(this).val());
  });

  inlineHighlightOptions.$el
    .find("input[name='" + name + "']")
    .off('click')
    .on(
      'click',
      $.proxy(function(e) {
        let textSelectionEvent = inlineHighlightOptions.$el.data();
        if (!textSelectionEvent) {
          return;
        }

        //clear the selection using a better method:
        textSelectionEvent.clear();

        let cfiObj = textSelectionEvent.cfi;

        // Don't filter this CFI since it's used as the annotation identifier in this case
        let fullCFI = Helpers.locObjToFullCFI(this.readium.packageDocument, cfiObj, false);

        let filteredContentCFI = Helpers.applyFilteringToCFI(cfiObj.contentCFI);

        let color = $(e.currentTarget).val();

        if(isNewHighlight) {
          this.readium.reader.plugins.highlights.addHighlight(
            cfiObj.idref,
            filteredContentCFI,
            fullCFI,
            color,
            null,
          );
          isNewHighlight = false;
        } else {
          this.readium.reader.plugins.highlights.updateAnnotation(fullCFI, color, null);
        }

        if(color === 'Delete'){
          this.readium.reader.plugins.highlights.removeHighlight(fullCFI);
          inlineHighlightOptions.$el.hide();
          this.annotationUtils.deleteAnnotation(cfiObj.idref, fullCFI, this.isbn).then(function() {
            $('#statusDiv').text('Highlight Removed');
            self.usageManager.fetchAnnotationsFromRemote(self.isbn).then(function(response){
              console.log('Fetched Annotations from Remote');
            });
          });
          // AccessibilityManager.panelCloseHandler(function() {
          //     AccessibilityManager.dispatchAction("focusContentElement")
          // })
        }

        inlineHighlightOptions.$el.find("input[name='" + name + "']").each(function() {
          $(this).attr('aria-checked', 'false');
        });
        $(e.currentTarget).attr('aria-checked', 'true');

        document.addEventListener('keyup', function(event) {
          if (event.keyCode == 13 && inlineHighlightOptions.$el.is(':visible')) {
            inlineHighlightOptions.$el.hide();
            // AccessibilityManager.panelCloseHandler(function() {
            //     AccessibilityManager.dispatchAction("focusContentElement")
            // })
          }
        });
      }, this),
    );

  var $colorInput = inlineHighlightOptions.$el.find('.' + color + ' input');

  if (!this.annotation) {
    inlineHighlightOptions.$el.find('.' + color + ' input').click();
  } else {
    inlineHighlightOptions.$el.find('.' + this.annotation.color + ' input')[0].checked = true;
  }
 // AccessibilityManager.panelCloseHandler(function() {
  //     AccessibilityManager.setTabGroup("highlight_color")
  //     AccessibilityManager.updateTabOrder("highlight_color")
  // })
  return;
});

InlineHighlightsMenu.prototype.hide = function() {
  let cfiObj  = null;
  let self = this;
  let textSelectionEvent = inlineHighlightOptions.$el.data();
  if(inlineHighlightOptions.$el.data() != undefined){
    cfiObj = inlineHighlightOptions.$el.data().cfi;
  }
  // Don't filter this CFI since it's used as the annotation identifier in this case
  let fullCFI = Helpers.locObjToFullCFI(this.readium.packageDocument, cfiObj, false);

  let filteredContentCFI = Helpers.applyFilteringToCFI(cfiObj.contentCFI);
  let colorValue = null;
  let name = $("input[id^='highlightColor']")[0] ? $("input[id^='highlightColor']")[0].name : 'selected-highlight-color';
  inlineHighlightOptions.$el.find("input[name='" + name + "']").each(function() {
    if(this.checked === true){
      colorValue = this.value;
    }
  });

  if (colorValue != 'Delete') {

    if (this.isNewHighlight === true) {
      this.annotationUtils.saveHighlights(
        colorValue,
        cfiObj,
        this.textSelectionEvent().text,
        this.isbn,
      ).then(function(){
        self.usageManager.fetchAnnotationsFromRemote(self.isbn).then(function(response){
          console.log('Fetched Annotations from Remote');
        });
      });
      $('#statusDiv').text('');
    }else{
      this.readium.reader.plugins.highlights.updateAnnotation(fullCFI, colorValue, null);
      this.annotation.type = 'Highlight';
      this.annotation.page = '';
      this.annotation.notetext = '';
      this.annotation.createdate = this.annotationUtils.getCurrentDate();
      this.annotation.updateAnnotation = 'true';
      this.annotation.color = colorValue;
      this.annotationUtils.updateAnnotation(textSelectionEvent.cfi.idref, this.annotation, this.isbn)
        .then(function() {
          console.log('Annotations updated online');
          self.usageManager.fetchAnnotationsFromRemote(self.isbn).then(function(response) {
            console.log('Fetched Annotations from Remote');
          });
        })
        .catch(function(error) {
          console.log('Updating Annotations  offline :  error');
          throw error;
        });
    }
  }
};

InlineHighlightsMenu.prototype.bindEvents =function() {
  inlineHighlightOptions.$el
    .off('hideInlineHighlights')
    .one('hideInlineHighlights', $.proxy(function(e) {
      InlineHighlightsMenu.prototype.hide.call(this);
    }, this)
    )
}

export default InlineHighlightsMenu;
