// sourced from webpack define plugin
var config = _app_config || {};

var accountid = config.fa_accountid || 'BTTest';
export default {
  nodeServer: config.nodeServer || '',

  imagePathPrefix: config.imagePathPrefix || '',

  epubLibraryPath: config.epubLibraryPath || '',

  canHandleUrl: config.canHandleUrl || false,
  canHandleDirectory: config.canHandleDirectory || false,

  fa_accountid: accountid,

  epubReadingSystemUrl: config.epubReadingSystemUrl || '/EPUBREADINGSYSTEM.js',

  workerUrl: config.workerUrl || '/READIUMWORKER.js',

  annotationCSSUrl: config.annotationCSSUrl || '/ANNOTATIONS.css',
  themesCSSUrl: config.themesCSSUrl || '/themes.less',
  mathJaxUrl: config.mathJaxUrl || '/MATHJAX.js',
  jsLibRoot: config.jsLibRoot || '/zip-js/',
  fonts: config.fonts || [],

  useSimpleLoader: config.useSimpleLoader || false,

  assetsUrl: config.assetsUrl || 'assets',

  releaseStage: config.releaseStage || 'development',

  versionUrl: config.versionUrl || '',

  contentDirectoryUrl: config.contentDirectoryUrl || 'assets/common/content',

  googleSearchEngineID: config.googleSearchEngineID || '016017638605632953755:bggehv7mz6y',

  googleAPIKey: config.googleAPIKey || 'AIzaSyB3SkPiYJ68LtqdaClw8FfvAM067QHJcYY',

  applicationInsightsKey: config.applicationInsightsKey
};
