import initialize from './initialize';
import { Readium as ReadiumCompat } from '@axisnow/ng-r1-compat';
import { Readium as Readium1 } from './Readium1';

class Readium extends ReadiumCompat {
  constructor(readiumOptions, readerOptions) {
    super(readiumOptions, readerOptions);

    window.ReadiumNG = this;

    this._readium1 = new Readium1({ ...readiumOptions, deferReaderCreation: true }, readerOptions);
    this._readiumOptions = readiumOptions;
    this._readerOptions = readerOptions;
  }

  async initPackageDocument(bookUrl) {
    this.packageDocument = await this._readium1.initPackageDocument(bookUrl);

    await this.reader.openPublicationFromJSON(
      JSON.stringify(this.packageDocument.serialize()),
      this._readerOptions.iframeLoader,
      this.packageDocument,
    );

    this.reader.setIdRefHrefMap(this.collectSpine());

    return this.packageDocument;
  }

  async openPackageDocument(openPageRequest) {
    this.reader.openPage(openPageRequest);
    return Promise.resolve();
  }

  getRights() {
    return this._readium1.getRights();
  }

  getCurrentPublicationFetcher() {
    return this._readium1.getCurrentPublicationFetcher();
  }

  closePackageDocument() {
    //TODO
  }

  cacheEpub(bookRoot, progressBar) {
    return this._readium1.cacheEpub(bookRoot, progressBar);
  }

  collectSpine() {
    const spine = [];
    const spineItemCount = this.packageDocument.spineLength();
    for (let i = 0; i < spineItemCount; i += 1) {
      spine.push(this.packageDocument.getSpineItem(i));
    }
    return spine;
  }
}

initialize(Readium);

export { Readium };
