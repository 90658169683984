import $ from 'jquery';
import _ from 'underscore';
import PageListParser from './PagelistParser';
import PaginationHelpers from './PaginationHelpers';

var EpubPagingHandler = function(readium, packageDocument) {
  var that = this;
  var _mapByIdref = {};
  var _mapByLabel = {};
  var _tocTitleMap = {};
  var _mapExtendedByIdref = {};
  var _mapExtendedByLabel = {};

  var _readium = null;
  var _pageListParser = null;

  // Legacy data that should be removed
  var _firstParseablePageNumber = NaN;
  var _lastParseablePageNumber = NaN;

  this.helpers = null;
  // this.pageListParser = null;
  this.rawPageList = [];
  this.mappedPageList = [];
  this.mappedExtendedLabelsList = [];
  this.rawToc = [];

  this.init = function(readium, packageDocument) {
    return new Promise(function(resolve, reject) {
      // Mutate the nav-doc spine item's href to add a query param to force cache revalidation
      // Follow the code in `app/controllers/epub_pages_controller.rb:8` to see what this does
      // var navDocSpineItem = epubViewer.packageDocument.getNavDoc();
      // navDocSpineItem.href = navDocSpineItem.href + "?must_revalidate=1";
      that.helpers = new PaginationHelpers(readium, packageDocument);

      var pagelistResolved = false;
      _pageListParser = new PageListParser(packageDocument);

      _pageListParser.generatePageListJSON(function(pageList) {
        that.onPageListLoaded(pageList);
        pagelistResolved = true;
        if (pagelistResolved) {
          resolve();
        }
      });
    });
  };

  this.onPageListLoaded = function(pageList) {
    if (!pageList || !pageList.length) {
      return;
    }
    that.rawPageList = pageList;

    var spineItems = that.helpers.getSpine().items;

    // Complex mapper functions
    var pageListIndex = -1;
    var mapPageListEntryFn = function(entry) {
      var mapEntry;
      var resolved;

      that.addIdrefsToPagelist(pageList);

      pageListIndex += 1;

      if (entry.href) {
        resolved = that.helpers.resolveHref(entry.href);
        mapEntry = _.extend(
          {
            label: entry.label,
            hrefURL: entry.href,
            index: pageListIndex,
            idref: entry.idref,
          },
          resolved,
        );
      } else if (entry.cfi) {
        // Modify the contentCFI to at least be compliant with spec
        // Should be safe because readium internall uses .split('!') for contentCFI extraction,
        // and if there was an indirection step but no following content, we would get an empty string
        if (entry.cfi.contentCFI === '') {
          entry.cfi.contentCFI = '/0';
        }
        mapEntry = _.extend(
          {
            label: entry.label,
            index: pageListIndex,
            idref: entry.idref,
          },
          entry.cfi,
        );
      } else {
        console.error('[Paging Handler] Invalid page list entry!');
        return;
      }

      // IDRef
      if (!_mapByIdref[mapEntry.idref]) {
        _mapByIdref[mapEntry.idref] = [];
      }
      _mapByIdref[mapEntry.idref].push(mapEntry);

      // Label
      // It seems to be our convention that we store these labels as lower case
      // at the risk of overwriting entries. This seems reasonable, given that we already
      // run such a risk by blindly assigning based on label, which can be duplicated
      // in a provided page-list.
      if (_.isString(mapEntry.label)) {
        _mapByLabel[mapEntry.label.toLowerCase()] = mapEntry;
      } else {
        _mapByLabel[mapEntry.label] = mapEntry;
      }

      // All
      that.mappedPageList.push(mapEntry);
      that.mapByIdfref = _mapByIdref;
      that.mapByLabel = _mapByLabel;
    };

    var parsedLabel = NaN;
    _firstParseablePageNumber = -1;
    _lastParseablePageNumber = 0;
    _.each(pageList, function(entry) {
      mapPageListEntryFn(entry, pageListIndex);

      // Legacy data parsing
      parsedLabel = parseInt(entry.label);
      if (!_.isNaN(parsedLabel)) {
        _lastParseablePageNumber = parsedLabel;
        if (_firstParseablePageNumber < 0) {
          _firstParseablePageNumber = parsedLabel;
        }
      }
    });
  };

  this.mapExtendedLabels = function(extendedLabels) {
    var pageListIndex = -1;

    _.each(extendedLabels, function(entry) {
      pageListIndex = that.mapExtendedListEntryFn(entry, pageListIndex);
    });
  };

  this.mapExtendedListEntryFn = function(entry, pageListIndex) {
    var mapEntry;
    var resolved;

    if (entry.href) {
      resolved = that.helpers.resolveHref(entry.href);
      mapEntry = _.extend(
        {
          label: entry.label.label,
          hrefURL: entry.href,
          index: pageListIndex + 1,
        },
        resolved,
      );
    } else if (entry.cfi) {
      // Modify the contentCFI to at least be compliant with spec
      // Should be safe because readium internall uses .split('!') for contentCFI extraction,
      // and if there was an indirection step but no following content, we would get an empty string
      if (entry.cfi.contentCFI === '') {
        entry.cfi.contentCFI = '/0';
      }
      mapEntry = _.extend(
        {
          label: entry.label.label,
          index: pageListIndex + 1,
        },
        entry.cfi,
      );
    } else {
      console.error('[Paging Handler] Invalid page list entry!');
      return;
    }

    pageListIndex += 1;

    // IDRef
    if (!_mapExtendedByIdref[mapEntry.idref]) {
      _mapExtendedByIdref[mapEntry.idref] = [];
    }
    _mapExtendedByIdref[mapEntry.idref].push(mapEntry);

    // Label
    // It seems to be our convention that we store these labels as lower case
    // at the risk of overwriting entries. This seems reasonable, given that we already
    // run such a risk by blindly assigning based on label, which can be duplicated
    // in a provided page-list.
    if (_.isString(mapEntry.label)) {
      _mapExtendedByLabel[mapEntry.label.toLowerCase()] = mapEntry;
    } else {
      _mapExtendedByLabel[mapEntry.label.label] = mapEntry;
    }

    // All
    that.mappedExtendedLabelsList.push(mapEntry);
    return pageListIndex;
  };

  this.getIdrefForHref = function(href, spineIndex) {
    var spineItems = that.helpers.getSpine().items;
    var idref = undefined;

    for (var i = spineIndex; i < spineItems.length; i++) {
      var spineItem = spineItems[i];
      idref = spineItem.idref;
      spineIndex = i;

      if (_.includes(href, spineItem.href)) {
        return { idref: idref, spineIndex: spineIndex };
      }
    }

    return { idref: idref, spineIndex: spineIndex };
  };

  this.addIdrefsToPagelist = function(pagelist) {
    var index = 0;
    var spineIndex = 0;
    pagelist.forEach(function(entry) {
      if (!entry.href) return;
      var refObj = that.getIdrefForHref(entry.href, spineIndex);
      pagelist[index] = _.extend(entry, { idref: refObj.idref });
      spineIndex = refObj.spineIndex;
      index++;
    });
  };

  this.getFromLabel = function(label) {
    var entry = _mapByLabel[_.isString(label) ? label.toLowerCase() : label];
    if (!entry) {
      entry = _mapExtendedByLabel[_.isString(label) ? label.toLowerCase() : label];
    }
    return entry;
  };

  // Assume input is 1-based page number
  this.getFromPageNumber = function(pageNum) {
    if (!that.mappedPageList || that.mappedPageList.length < 1) {
      console.error('[Paging Handler] Mising pagelist!');
      return null;
    }
    return that.mappedPageList[Math.max(Math.min(that.mappedPageList.length, pageNum) - 1, 0)];
  };

  this.getListByIdref = function(idref) {
    return _mapByIdref[idref];
  };

  this.getLastNumericallyParsedPageLabel = function() {
    return _lastParseablePageNumber;
  };

  this.getTotalPageNumber = function() {
    return that.mappedPageList.length;
  };

  // 1-based page number
  this.getLabelFromPageNumber = function(pageNumber) {
    if (
      !_.isNumber(pageNumber) ||
      _.isNaN(pageNumber) ||
      pageNumber < 1 ||
      pageNumber > that.mappedPageList.length
    ) {
      //console.error("Invalid page number: " + pageNumber);
      return null;
    }
    return that.mappedPageList[pageNumber - 1].label;
  };

  this.getLabelsFromPageNumberRange = function(pageNumberRange) {
    // Input field
    var labels = null;
    var pageTokens, pageLabels, firstLabel, lastLabel;
    var labelizerFn = function(token) {
      var pageNumber = parseInt(token);
      var pageLabel = that.getLabelFromPageNumber(pageNumber);
      return pageLabel;
    };
    // Parse range if it exists
    if (_.isString(pageNumberRange) && pageNumberRange.match(/\d+-\d+/)) {
      pageTokens = pageNumberRange.split('-');
      pageLabels = _.map(pageTokens, labelizerFn);
      pageLabels = _.filter(pageLabels, function(label) {
        return label != null;
      });
      // Merge range into string if it exists
      if (pageLabels.length > 0) {
        firstLabel = _.first(pageLabels);
        lastLabel = _.last(pageLabels);
        if (firstLabel !== lastLabel) {
          labels = [firstLabel, lastLabel];
        } else {
          labels = [firstLabel];
        }
      }
    }
    if (!labels) {
      labels = [labelizerFn(pageNumberRange)];
    }

    return labels;
  };

  // Returns 1-based page number
  this.getPageNumberFromLabel = function(label) {
    var entry = _mapByLabel[_.isString(label) ? label.toLowerCase() : label];
    if (entry) {
      return entry.index + 1;
    }
    return null;
  };

  this.getFirstNumericallyParsedPageLabel = function() {
    return _firstParseablePageNumber;
  };

  this.jumpToEntry = function(entry) {
    if (entry.hrefURL) {
      return that.helpers.goToHref(entry.hrefURL);
      // that.helpers.gotoHref(entry.hrefURL);
    } else if (entry.contentCFI) {
      return that.helpers.goToCfi(entry);
      // that.helpers.gotoCfi(entry);
    }
  };

  this.jumpToLabel = function(label) {
    var that = this;
    return new Promise(function(resolve) {
      var entry = that.getFromLabel(label);
      if (!entry) throw new Error('Label does not exist');
      that.jumpToEntry(entry).then(function () {
        resolve();
      })
    }).catch(TypeError,function (err) {
      return;
    });
  };

  // Assumes 1-based page number
  this.jumpToPageNumber = function(pageNumber) {
    var entry = this.getFromPageNumber(pageNumber);
    this.jumpToEntry(entry);
  };

  this.jumpToIndex = function(index) {
    var entry = this.mappedPageList[index];
    return this.jumpToLabel(entry.label);
  };

  function getPageBreakCFIs(labelItem) {
    var $el;
    if (labelItem.elementId) {
      $el = readium.reader.getElementById(labelItem.idref, labelItem.elementId);
      if ($el) {
        return readium.reader.getNearestCfiFromElement($el[0]);
      }
      console.warn('getPageBreakCFIs: element not found in DOM with id ', labelItem.elementId);
    } else if (labelItem.contentCFI) {
      return labelItem;
    } else {
      $el = readium.reader.getElements(labelItem.idref, 'body');
      if ($el) {
        var elementCfi = readium.reader.getNearestCfiFromElement($el[0]);

        let range = document.createRange();
        range.setStart($el[0], 0);
        range.setEnd($el[0], 0);
        var bodyCfi = readium.reader.getRangeCfiFromDomRange(range);

        var fullContentCfi = bodyCfi.contentCFI + elementCfi.contentCFI;

        return _.extend(elementCfi, { contentCFI: fullContentCfi });
      }
      console.warn('getPageBreakCFIs: body not found for spine idref ', labelItem.idref);
    }
    return null;
  }

  this.getVisibleLabelCFIs = function() {
    var spineItems = that.helpers.getCurrentSpineItems();
    var result = {};
    _.each(spineItems, function(spineItem) {
      var labels = that.getListByIdref(spineItem.idref);
      var cfiList = _.map(labels, getPageBreakCFIs);
      var visibleCfis = _.filter(cfiList, function(cfi) {
        return that.helpers.isVisibleCFI(cfi);
      });
      result[spineItem.idref] = visibleCfis;
    });
    //assumes only one spine Item
    result = result[Object.keys(result)[0]];
    return result;
  };

  this.isOnLastPageOfCurrentSpread = function(paginationInfo) {
    var openPage = _.last(paginationInfo.openPages);
    return openPage.spineItemPageIndex === openPage.spineItemPageCount - 1;
  };

  this.isOnFirstPageOfCurrentSpread = function(paginationInfo) {
    var openPage = _.first(paginationInfo.openPages);
    return openPage.spineItemPageIndex === 0;
  };

  this.getLabelItemsByIdRef = function(idref) {
    var spineItem = readium.reader.spine().getItemById(idref);
    var isFixedLayout = spineItem.isFixedLayout();
    var startCfi = readium.reader.getStartCfi(idref);
    var endCfi = readium.reader.getEndCfi(idref);
    var firstVisibleCfi = readium.reader.getFirstVisibleCfi(idref);
    var lastVisibleCfi = readium.reader.getLastVisibleCfi(idref);
    var labels = that.getListByIdref(spineItem.idref);
    var cfiList = _.map(labels, getPageBreakCFIs);
    var labelsAndCfis = _.zip(labels, cfiList);

    var cfiCompare = _.bind(that.helpers.contentCfiComparator, that.helpers);

    if (!startCfi || !cfiList.length) {
      return [];
    }

    if (!firstVisibleCfi || !firstVisibleCfi.contentCFI) {
      firstVisibleCfi = _.first(this.getVisibleLabelCFIs());
    }

    if (!lastVisibleCfi || !lastVisibleCfi.contentCFI) {
      lastVisibleCfi = _.last(this.getVisibleLabelCFIs());
      if (!lastVisibleCfi) {
        lastVisibleCfi = { idref: idref, contentCFI: endCfi.contentCFI };
      }
    }

    if (!firstVisibleCfi || !lastVisibleCfi) {
      return [];
    }

    // TODO: Remove undefined or null entries in the cfi list for now
    cfiList = _.compact(cfiList);

    var compareFirst, compareLast;
    if (!isFixedLayout) {
      compareFirst = cfiCompare(_.first(cfiList).contentCFI, startCfi.contentCFI);
      if (compareFirst) {
        labelsAndCfis.unshift([
          {
            label: _.first(labels).label,
            index: _.first(labels).index,
            inferred: true,
          },
          startCfi,
        ]);
      }
      compareLast = cfiCompare(_.last(cfiList).contentCFI, endCfi.contentCFI);
      if (compareLast) {
        labelsAndCfis.push([
          {
            label: _.last(labels).label,
            index: _.last(labels).index,
            inferred: true,
          },
          endCfi,
        ]);
      }
    }

    var comparedCfis = _.map(labelsAndCfis, function(labelCfiPair) {
      var label = labelCfiPair[0];
      var cfi = labelCfiPair[1];
      if (!cfi) {
        return null;
      }
      return {
        label: label,
        cfi: cfi,
        first: cfiCompare(firstVisibleCfi.contentCFI, cfi.contentCFI),
        last: cfiCompare(lastVisibleCfi.contentCFI, cfi.contentCFI),
      };
    });
    if (comparedCfis.length === 1) {
      return comparedCfis;
    }

    // TODO: Remove undefined or null entries in the cfi list for now
    comparedCfis = _.compact(comparedCfis);

    return comparedCfis;
  };

  this.getVisibleLabelItemsByIdRef = function(idref) {
    var result = [];
    var comparedCfis = this.getLabelItemsByIdRef(idref);
    var foundLastVisibleLabel = false;

    _.each(comparedCfis, function(value, index, list) {
      var previous = list[index - 1];

      if (value.first !== value.last || (value.first === 0 && value.last === 0)) {
        if (previous && previous.first === 1 && previous.last === 1 && value.first !== 0) {
          result.push({ cfi: previous.cfi, label: previous.label });
        }
        result.push({ cfi: value.cfi, label: value.label });
      } else if (
        previous &&
        value.first === -1 &&
        value.last === -1 &&
        !result.length &&
        !foundLastVisibleLabel
      ) {
        result.push({ cfi: previous.cfi, label: previous.label });
        foundLastVisibleLabel = true;
      }
    });
    return result;
  };

  this.getLabelFromIndex = function(index) {
    if (this.mappedPageList.length < 1) {
      return undefined;
    }
    var entry = this.mappedPageList[index];
    return entry.label;
  };

  this.getLabelItems = function() {
    var spineItems = that.helpers.getCurrentSpineItems();
    var results = [];
    _.each(spineItems, function(spineItem) {
      results.push(that.getLabelItemsByIdRef(spineItem.idref));
    });

    if (!_mapExtendedByLabel.length) {
      this.mapExtendedLabels(_.flatten(results));
    }

    return _.flatten(results);
  };

  this.getVisibleLabelItems = function() {
    var spineItems = that.helpers.getCurrentSpineItems();
    var results = [];
    _.each(spineItems, function(spineItem) {
      results.push(that.getVisibleLabelItemsByIdRef(spineItem.idref));
    });
    return _.flatten(results);
  };

  this.getFormattedVisibleLabelItems = function() {
    return that.helpers.formatLabelItems(that.getVisibleLabelItems());
  };

  this.getFormattedLabelItems = function() {
    return that.helpers.formatLabelItems(that.getLabelItems());
  };

  this.isBeforeFirstLabel = function() {
    var lastVisibleCfi = readium.getLastVisibleCfi();
    var firstPageEntry = _.first(this.mappedPageList);
    var spineItems, match;

    if (!lastVisibleCfi) {
      console.warn('[EpubPagingHandler] No lastVisibleCFI!');
    } else if (!firstPageEntry) {
      console.warn('[EpubPagingHandler] Missing firstPageEntry!');
    }
    // If we can discern by idref
    else if (lastVisibleCfi.idref !== firstPageEntry.idref) {
      spineItems = readium.spine().items;
      match = _.find(spineItems, function(sItem) {
        return sItem.idref === firstPageEntry.idref || sItem.idref === lastVisibleCfi.idref;
      });
      if (!match) {
        console.error('[Paging Handler]: Spine Item Mismatch!');
        return false;
      }
      return match.idref === lastVisibleCfi.idref;
    }

    // In theory the spine item in question must be loaded, thus allowing us to CFI-compare
    // TODO:
    return false;
  };

  this.decrementLabel = function(label) {
    var result;
    //if label is numerical decrement like normal
    if (!_.isNaN(parseInt(label))) {
      return (label - 1).toString();
    }

    //label is non-numeric, treat as roman numeral
    result = this.romanToNum(label);
    result--;
    result = this.numToRoman(result);
    return result.toString();
  };

  this.romanToNum = function(label) {
    var char = label.toUpperCase();
    char = char.split('');
    var numerals = { I: 1, V: 5, X: 10, L: 50, C: 100, D: 500, M: 1000 };
    var length = char.length;
    var number = 0;

    var i; // Hoisting
    for (i = 0; i < length; i++) {
      if (numerals[char[i]] < numerals[char[i + 1]]) {
        number -= numerals[char[i]];
      } else {
        number += numerals[char[i]];
      }
    }
    return number;
  };

  this.numToRoman = function(label) {
    var numerals = {
      M: 1000,
      CM: 900,
      D: 500,
      CD: 400,
      C: 100,
      XC: 90,
      L: 50,
      XL: 40,
      X: 10,
      IX: 9,
      V: 5,
      IV: 4,
      I: 1,
    };
    var roman = '';
    var i;
    var label2 = label; // Avoid modifying input
    for (i in numerals) {
      while (label2 >= numerals[i]) {
        roman += i;
        label2 -= numerals[i];
      }
    }
    return roman.toLowerCase();
  };
};

export default EpubPagingHandler;
