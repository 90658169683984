export default [
  'color',
  'font-family',
  'font-size',
  'font-weight',
  'font-style',
  //"line-height",
  'text-decoration',
  'text-transform',
  'text-shadow',
  'letter-spacing',

  'text-rendering',
  'font-kerning',
  'font-language-override',
  'font-size-adjust',
  'font-stretch',
  'font-synthesis',
  'font-variant',
  'font-variant-alternates',
  'font-variant-caps',
  'font-variant-east-asian',
  'font-variant-ligatures',
  'font-variant-numeric',
  'font-variant-position',
  '-webkit-font-smoothing ',

  '-ms-writing-mode',
  '-webkit-writing-mode',
  '-moz-writing-mode',
  '-ms-writing-mode',
  'writing-mode',

  '-webkit-text-orientation',
  '-moz-text-orientation',
  '-ms-text-orientation',
  'text-orientation: mixed',
];
