let { Globals } = require('@axisnow/readium-shared-js');
var dataStore;
var _STORE_NAMES;

var MemoryStorageManager = function() {
  this.STORE_NAMES = _STORE_NAMES = Globals.STORE_NAMES;
  dataStore = new Map();
  init();
};

let init = function () {
  for (const key in _STORE_NAMES) {
    dataStore.set( _STORE_NAMES[key], new Map() );
  }
};

/**
 * Get data from the memory store. e.g format is dataStore['PageUsageStore']['uuid|isbn']
*/
MemoryStorageManager.prototype.get = function(objStoreName, propertyName, propertyValue) {
  return new Promise((resolve, reject) => {
    if (dataStore.has(objStoreName) && propertyValue) {
      resolve(dataStore.get(objStoreName).get(propertyValue));
    } else if (!dataStore.has(objStoreName)){
      reject();
      console.error( objStoreName + " does not exist in Memory Storage" );
    } else if (!propertyValue) {
      reject();
      console.error("The key " + propertyName + " is not valid");
    }
    resolve(undefined);
  });
};

/**
 * Get all data from one certian store. e.g format is dataStore['PageUsageStore']
*/
MemoryStorageManager.prototype.getAll = function(objStoreName, propertyName, propertyValue) {
  if (dataStore.has(objStoreName)) {
    return dataStore.get(objStoreName);
  }
  return undefined;
};

/**
 * Add data to the store. If already exists, wont save it.
*/
MemoryStorageManager.prototype.add = function(objStoreName, savedObject) {
  let key = savedObject.uuid_profile_isbn || savedObject.uuid_profile_id;
  return new Promise((resolve, reject) => {
    if (dataStore.has(objStoreName) && key && dataStore.get(objStoreName).has(key)) {
      reject();
      console.error( key + " already exists" );
    } else if (dataStore.has(objStoreName) && key) {
      dataStore.get(objStoreName).set(key, savedObject);
      resolve(dataStore.get(objStoreName).get(key));
    } else if (!dataStore.has(objStoreName)) {
      reject();
      console.error( objStoreName + " does not exist in Memory Storage" );
    } else if (!key) {
      reject();
      console.error("The key is not valid");
    }
    resolve(undefined);
  });
};

/**
 * Add data to the store. If exists, replace the old one with new value.
*/
MemoryStorageManager.prototype.put = function(objStoreName, savedObject) {
  let key = savedObject.uuid_profile_isbn || savedObject.uuid_profile_id;
  return new Promise((resolve, reject) => {
    if (dataStore.has(objStoreName) && key) {
      dataStore.get(objStoreName).set(key, savedObject);
      resolve(dataStore.get(objStoreName).get(key));
    } else if (!dataStore.has(objStoreName)) {
      reject();
      console.error( objStoreName + " does not exist in Memory Storage" );
    } else if (!key) {
      reject();
      console.error("The key is not valid");
    }
    resolve(undefined);
  });
};

// close method: Takes no parameters. Simply empty the Map
MemoryStorageManager.prototype.close = function() {
  dataStore.clear();
};

export default MemoryStorageManager;
