var _objectStores = {};

var MigrationManager12To13 = function(db, transaction) {
  this.db = db;
  this.transaction = transaction;

  const _STORE_NAMES = (this.STORE_NAMES = {
    ComicBookPageUsage: 'ComicBookPageUsageStore',
    ComicBookAnnotationsUsage: 'ComicBookAnnotationsUsageStore'
  });

  
  _objectStores[_STORE_NAMES.ComicBookPageUsage] = {
    name: _STORE_NAMES.ComicBookPageUsage,
    value: { keyPath: 'uuid_isbn' },
    indexes: [
      {
        name: 'uuid',
        keyPath: 'uuid',
        optionalParameters: { unique: false },
      },
      {
        name: 'isbn',
        keyPath: 'isbn',
        optionalParameters: { unique: false },
      },
    ],
  };

  _objectStores[_STORE_NAMES.ComicBookAnnotationsUsage] = {
    name: _STORE_NAMES.ComicBookAnnotationsUsage,
    value: { keyPath: 'uuid_isbn' },
    indexes: [
      {
        name: 'uuid',
        keyPath: 'uuid',
        optionalParameters: { unique: false },
      },
      {
        name: 'isbn',
        keyPath: 'isbn',
        optionalParameters: { unique: false },
      },
    ],
  };
};

MigrationManager12To13.prototype.createNewTables = function() {
  var self = this;
  return new Promise((resolve, reject) => {
    for (var key in _objectStores) {
      if (!_objectStores.hasOwnProperty(key)) continue;
  
      var obj = _objectStores[key];
  
      var objStore;
      if (self.db.objectStoreNames.contains(key)) {
        continue;
      } else {
        objStore = self.db.createObjectStore(key, obj.value);
      }
  
      if (typeof obj.indexes !== 'undefined') {
        var indexNames = objStore.indexNames;
        /* jshint ignore:start */
        // Ignoring JSHint error : Functions declared within loops referencing an outer scoped variable may lead to confusing semantics.
        obj.indexes.forEach(function(element, index, array) {
          if (indexNames.contains(element.name)) {
            var myIndex = objStore.index(element.name);
            var currentKeyPath = myIndex.keyPath;
            var updateIndex = currentKeyPath != element.keyPath;
  
            if (element.optionalParameters) {
              updateIndex =
                element.optionalParameters.unique != element.optionalParameters.unique || updateIndex;
            }
            if (updateIndex) {
              objStore.deleteIndex(element.name);
              objStore.createIndex(element.name, element.keyPath, element.optionalParameters);
            }
          } else {
            objStore.createIndex(element.name, element.keyPath, element.optionalParameters);
          }
        });
        /* jshint ignore:end */
      }
    }
    resolve();
  });
};

MigrationManager12To13.prototype.migrate = async function() {
  var self = this;
  await self.createNewTables();
  console.debug('12 to 13 done');
};

export default MigrationManager12To13;