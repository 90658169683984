import _ from 'underscore';
import $ from 'jquery';
import { getURLQueryParams,getTimezone } from '@axisnow/util/Helpers';
import { Globals } from '@axisnow/readium';
import momentCore from 'moment';

var Helpers = {};
Helpers.focusIframeBasedOnPageChange = function(readium, pageChangeData) {
  try {
    var iframe;
    var element;
    var doc;

    var id = pageChangeData.elementId;
    if (!id) {
      var bookmark = JSON.parse(readium.reader.bookmarkCurrentPage());

      //bookmark.idref; //manifest item
      if (pageChangeData.spineItem) {
        element = readium.reader.getElementByCfi(
          pageChangeData.spineItem.idref,
          bookmark.contentCFI,
          ['cfi-marker', 'mo-cfi-highlight'],
          [],
          ['MathJax_Message'],
        );
        element = element[0];

        if (element) {
          iframe = $('#epub-reader-frame iframe')[0];
          doc = (iframe.contentWindow || iframe.contentDocument).document;
          if (element.ownerDocument !== doc) {
            iframe = $('#epub-reader-frame iframe')[1];
            if (iframe) {
              doc = (iframe.contentWindow || iframe.contentDocument).document;
              if (element.ownerDocument !== doc) {
                iframe = undefined;
              }
            }
          }
        }
      }
    } else {
      iframe = $('#epub-reader-frame iframe')[0];
      doc = (iframe.contentWindow || iframe.contentDocument).document;
      element = doc.getElementById(id);
      if (!element) {
        iframe = $('#epub-reader-frame iframe')[1];
        if (iframe) {
          doc = (iframe.contentWindow || iframe.contentDocument).document;
          element = doc.getElementById(id);
          if (!element) {
            iframe = undefined;
          }
        }
      }
    }

    if (!iframe) {
      iframe = lastIframe;
    }

    if (iframe) {
      //var doc = ( iframe.contentWindow || iframe.contentDocument ).document;
      var toFocus = iframe; //doc.body
      setTimeout(function() {
        toFocus.focus();
      }, 50);
    }
  } catch (e) {
    //
  }
};

Helpers.applyVersioningInfo = function(packageInfo) {
  //TODO: Bring back epubReadingSystem object (not critical at the moment)
  // window.navigator.epubReadingSystem.name = packageInfo.name;
  // window.navigator.epubReadingSystem.version = packageInfo.version;
  // Debug check:
  //console.debug(JSON.stringify(window.navigator.epubReadingSystem, undefined, 2));
};

Helpers.openPageRequestGotoOverride = function(openPageRequest, urlParams) {
  var goto = urlParams.goto;
  if (goto) {
    console.log('Goto override? ' + goto);

    try {
      var gotoObj = JSON.parse(goto);

      var openPageRequest_;

      // See ReaderView.openBook()
      // e.g. with accessible_epub_3:
      // &goto={"contentRefUrl":"ch02.xhtml%23_data_integrity","sourceFileHref":"EPUB"}
      // or: {"idref":"id-id2635343","elementCfi":"/4/2[building_a_better_epub]@0:10"} (the legacy spatial bookmark is wrong here, but this is fixed in intel-cfi-improvement feature branch)
      if (gotoObj.idref) {
        if (gotoObj.spineItemPageIndex) {
          openPageRequest_ = {
            idref: gotoObj.idref,
            spineItemPageIndex: gotoObj.spineItemPageIndex,
          };
        } else if (gotoObj.elementCfi) {
          openPageRequest_ = { idref: gotoObj.idref, elementCfi: gotoObj.elementCfi };
        } else {
          openPageRequest_ = { idref: gotoObj.idref };
        }
      } else if (gotoObj.contentRefUrl && gotoObj.sourceFileHref) {
        openPageRequest_ = {
          contentRefUrl: gotoObj.contentRefUrl,
          sourceFileHref: gotoObj.sourceFileHref,
        };
      }

      if (openPageRequest_) {
        openPageRequest = openPageRequest_;
        console.debug('Open request (goto): ' + JSON.stringify(openPageRequest));
      }
    } catch (err) {
      console.error(err);
    }
  }
  return openPageRequest;
};

Helpers.applyFilteringToCFI = function(cfiExpression) {
  return cfiExpression.replace(/\[.*?]/g, '');
};

Helpers.cfiEquals = function(cfi1, cfi2) {
  return Helpers.applyFilteringToCFI(cfi1) === Helpers.applyFilteringToCFI(cfi2);
};

Helpers.locObjToFullCFI = function(packageDocument, location, applyFiltering) {
  var contentCFI = location.contentCFI;
  if (applyFiltering) {
    contentCFI = Helpers.applyFilteringToCFI(contentCFI);
  }

  return 'epubcfi(' + packageDocument.getCFIByIdRef(location.idref) + contentCFI + ')';

  /** Photon:Begin Readalong last read audion position persistance */ 
  //ReadAlong: Position in Percentage (raAudioPosition)
  // if(location.raAudioPosition) {
  //  return 'epubcfi(' + packageDocument.getCFIByIdRef(location.idref) + contentCFI + '!' + location.raAudioPosition + ')';
  // } else {
  //  return 'epubcfi(' + packageDocument.getCFIByIdRef(location.idref) + contentCFI + ')';
  // }
  /** Photon:End Readalong last read audion position persistance */
};

Helpers.locObjFromFullCFI = function(packageDocument, fullCFI, applyFiltering) {
  if (
    fullCFI &&
    fullCFI != '' &&
    fullCFI.indexOf('!') !== -1 &&
    fullCFI.indexOf('epubcfi(') !== -1
  ) {
    var cfi = {};
    fullCFI = fullCFI.substring(8, fullCFI.length - 1);
    if (applyFiltering) {
      fullCFI = Helpers.applyFilteringToCFI(fullCFI); //filter out assertion expressions in square bracket
    }
    var parts = fullCFI.split('!');

    /** Photon:Begin Readalong last read audion position persistance */ 
    //ReadAlong: Position in Percentage (raAudioPosition)
    cfi.raAudioPosition = parts.length > 2 ? Number(parts.pop()) : 0;
    /** Photon:End Readalong last read audion position persistance */
    
    cfi.idref = packageDocument.getIdRefFromCFI(parts.shift());
    try {
      cfi.contentCFI = parts.pop();
      if(cfi.contentCFI === 'null'){
        throw new Error('contentCFI is null')
      }
    }
    catch(err) {
      console.error(err);
      cfi.contentCFI = '';
    }
    return cfi;
  }
};

function flattenTocJSON(output, input) {
  _.each(input, function(child) {
    if (child.children && child.children.length) {
      flattenTocJSON(output, child.children);
    }
    output.push({ href: child.href, title: child.title });
  });
}

Helpers.flattenTocJSON = function(tocData) {
  var list = [];
  flattenTocJSON(list, tocData);
  return list;
};

Helpers.invokeOncePaginationChanged = function(reader) {
  return new Promise(function(resolve) {
    reader.once(Globals.Events.PAGINATION_CHANGED, function() {
      setTimeout(function() {
        resolve();
      }, 0);
    });
  });
};

Helpers.getEngageUrlParams = function(name){
  var regEx = new RegExp('[\?&]' + name + '=([^&#]*)');
  var results = regEx.exec(window.location.href);
  //Web application forwarded via dap/director which carriers the required paramters. 
  if (results==null) {
    var reffererUrl = document.referrer 
    //When running in local with Readium redirector proxy
    if(document.referrer.includes("/redirector/dap/director")) {
      var urlParams = new URLSearchParams(document.referrer);
      reffererUrl = decodeURIComponent(urlParams.get('url'));
    }
    
    results = regEx.exec(reffererUrl);
    if (results==null) {
    return null;
    }
  }
  return decodeURI(results[1]) || 0;
};

/** Generic Methods **/
Helpers.getCurrentDate = function () {
  let currentDate = momentCore().utc().format('MM/DD/YYYY hh:mm:ss A Z');
  return currentDate;
};

Helpers.getCurrentDateInUTCISO = function () {
  let currentDate = momentCore().utc().toISOString();
  return currentDate;
};

Helpers.getCurrentDateFormat = function () {
  let currentDateFormat = momentCore().utc().format();
  return currentDateFormat;
};

Helpers.getUrlParam = function(name){
  var regEx = new RegExp('[\?&]' + name + '=([^&#]*)');
  var results = regEx.exec(window.location.href);
  
  //Web application forwarded via dap/director which carriers the required paramters. 
  if (results==null) {
    var reffererUrl = document.referrer 
  
    //When running in local with Readium redirector proxy
    if(document.referrer.includes("/redirector/dap/director")) {
      var urlParams = new URLSearchParams(document.referrer);
      reffererUrl = decodeURIComponent(urlParams.get('url'));
    }
    results = regEx.exec(reffererUrl);    
    if (results==null) {
      return null;
    }
  }
  return decodeURI(results[1]) || 0;
};

/**
 *
 * @returns object (map between URL query parameter names and corresponding decoded / unescaped values)
 */
Helpers.getURLQueryParams = getURLQueryParams;
Helpers.getTimezone = getTimezone; //it generic helper function, used to get timezoneoffset

export default Helpers;
