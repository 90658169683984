import $ from 'jquery';
import _ from 'underscore';
import Menu from '../Menu';
import Strings from '../../i18n/Strings';
import utils from '@axisnow/util/utils';
import PopupFactory from '../../popup/PopupFactory';
import moment from 'moment-timezone';
import Helpers from '../../EpubReaderHelpers';
import splashMessage from '../../SplashMessage';
import AnnotationUtils from '../AnnotationUtils';
import AccessibilityManager from '../../accessibility/AcessibilityManager';
import errorMonitor from '../../ErrorMonitor';

//Confirmation modal
var popupModal = PopupFactory.create(PopupFactory.TYPE.MODAL, { id: 'annotationDelete' });

var AnnotationsMenuUtils = function() {
  Menu.apply(this, arguments);
  this.annotationUtils = new AnnotationUtils(this.readium, this.usageManager, this.findaway, this.comic);
};

AnnotationsMenuUtils.prototype = Object.create(Menu.prototype);

//Public API
AnnotationsMenuUtils.prototype.deleteItem = function(annotation) {
  var self = this;

  var setFocus = function(annotation) {
    if (annotation.type == 'Highlight' || annotation.type == 'Note') {
      // setTimeout(function(){
      //     AccessibilityManager.panelCloseHandler(function() {
      //         AccessibilityManager.enableElements(["#annotationsListNotes"])
      //     })
      // }, 500)
    }
  };
  function performDelete() {
    var idref;
    if (self.isAudiobook()) {
      idref = annotation.chapter;
    } else if (self.isComicBook()) {
      idref = annotation.label;
    } else {
      var locObj =
        Helpers.locObjFromFullCFI(self.readium.packageDocument, annotation.location, false) || {};
      idref = locObj.idref;
    }
    self.annotationUtils.deleteAnnotation(idref, annotation.location, self.isbn).then(function() {
      self.show().then(function() {
        console.log("Delete Annotation called");
      }).catch(function(error) {
        console.error(error);
        errorMonitor.bugsnag(error);
    });
      if (!self.isAudiobook()  && !self.isComicBook()) {
        self.readium.reader.plugins.highlights.removeHighlight(annotation.location);
      }
    });
  }

  // Deleting bookmarks
  if (annotation.type == 'Bookmark') {
    annotation.deleteAnnotation = 'true';
    performDelete();
    return;
  }

  // Don't delete other annotations for an audiobook
  if (this.isAudiobook()) {
    return;
  }

  // Delete other annotations
  popupModal.clear();
  popupModal.setTitle('Remove ' + annotation.type);
  popupModal.addButton(
    'No',
    function(e) {
      popupModal.hide();
      setFocus(annotation);
    },
    false,
  );
  popupModal.setMessage('Do you want to remove ' + annotation.type + '?');

  popupModal.addButton(
    'Yes',
    function(e) {
      /*if (annotation.type != "Bookmark") {
                    self.readium.reader.plugins.highlights.removeHighlight(annotation.location);
                }else{
                    annotation.deleteAnnotation = "true";
                }
                var locObj = Helpers.locObjFromFullCFI(self.readium.packageDocument, annotation.location);
                self.annotationUtils.deleteAnnotation(locObj.idref, annotation.location, self.isbn).then(function () {
                    self.show();
                });*/
      performDelete();
      popupModal.hide();
      setFocus(annotation);
    },
    true,
  );

  popupModal.show();

  // setTimeout(function(){
  //     console.log("settimeout working")
  //     AccessibilityManager.disableElements(["#annotationsListNotes"])
  //     AccessibilityManager.setTabGroup("notes_highlight_delete_popup")
  //     AccessibilityManager.updateTabOrder("notes_highlight_delete_popup")
  // }, 500)
};

AnnotationsMenuUtils.prototype.navigateToAnnotation = function(data) {
  var self = this;

  if (this.isAudiobook()) {
    var player = this.findaway;
    var currTrack = player.currentTrack;
    var currChapter = player.currentTitle.playlist[currTrack].chapter_number;
    var noIntro = currChapter != currTrack;
    var dataTrack = noIntro ? parseInt(data.chapter) - 1 : parseInt(data.chapter);
    var time = data.timeoffset;

    if (dataTrack != currTrack) {
      player
        .changeTrack(dataTrack)
        .then(function() {
          Window.isAnnotation = true;
          player.seekTo(time);
        })
        .catch(function(error) {
          utils
            .isOnline()
            .then(function() {
              error.code = 'Audiobook Error';
              error.message = 'Unable to access audiobook. Please try again later.';

              self.displayError(error);
            })
            .catch(function() {
              error.code = 'Device Is Offline';
              error.message = 'Please return online for full audiobook functionality.';
              error.feedbackBtn = false;

              self.displayError(error);
            });
        });
    } else {
      player.seekTo(time);
      player.play();
    }

    var duration = player.currentTitle.chapters[dataTrack].duration;
    $('#wdg-slider-input').prop('max', duration);
    $('#wdg-slider-input').prop('value', time);

    $('#btnPause span:first').attr('class', 'icon icon-audio-pause');
    setTimeout(function() {
      $('#btnPause').attr('title', 'pause');
    }, 200);

    return;
  }
  else if (this.isComicBook()) {
   var currentPage = data.location;
   var comicData = self.comic.comicMetaData.pages.filter((page,index) => {
         if(data.booktext.includes('Frame')) { 
            if(page.title == data.booktext.split(';')[0]) {
              page.index = parseInt(index) + 2;
              return page; 
            }
        } else {
            if(page.title == data.booktext) {
            page.index = parseInt(index) + 2;
            return page; 
          }
        }
      })
    window.sessionStorage.setItem('BOOKMARK-CLICKED-ITEM-KEY',JSON.stringify({index:comicData[0].index,data:comicData[0]}));
    $('#app-container').trigger('navigatePage', [currentPage]);
  } 
  else {
    var locObj = Helpers.locObjFromFullCFI(this.readium.packageDocument, data.location, true);
    this.readium.reader.openSpineItemElementCfi(locObj.idref, locObj.contentCFI);
  }
};

AnnotationsMenuUtils.prototype.getBookUsage = function() {
  let getAnnotUsage = function (self) {
    if(self.isAudiobook()) {
      return self.usageManager.getAudioAnnotationsUsage(self.isbn) 
    } else if(self.isComicBook()) {
      console.log("AnnotationsMenuUtils.prototype.getBookUsage : getComicBookAnnotationsUsage");
      return self.usageManager.getComicBookAnnotationsUsage(self.isbn);
    } else {
      return self.usageManager.getAnnotationsUsage(self.isbn);
    }
  };
  return utils
    .isOnline()
    .bind(this)
    .then(function() {
      return getAnnotUsage(this);
    })
    .then(function(annotUsage) {
      var syncRequired = annotUsage.isChanged();
      if (syncRequired) {
        if (this.isAudiobook()) {
          return this.usageManager.deleteAudioPositionsFromRemote(this.isbn, true)
          .bind(this)
          .then(function() {
            return this.usageManager.saveAudioPositionsToRemote(this.isbn);
          })
        }
        return this.usageManager.deleteAnnotationsFromRemote(this.isbn, true)
          .bind(this)
          .then(function() {
            return this.usageManager.saveAnnotationsToRemote(this.isbn);
          }).bind(this)
          .then(function() {
             return this.usageManager.fetchAnnotationsFromRemote(this.isbn);
          });
      } else {
        if (this.isAudiobook()) {
          return this.usageManager.fetchAudioPositionsFromRemote(this.isbn);
        }
        return this.usageManager.fetchAnnotationsFromRemote(this.isbn);
      }
    })
    .catch(function(error) {
      console.log('Error in getBookUsage');
      return getAnnotUsage(this)
        .then(function(annotUsage) {
          return annotUsage;
        });
    });
};

AnnotationsMenuUtils.prototype.formatDate = function(dateString) {
  var parsedDate = moment.utc(dateString, 'MM/DD/YYYY hh:mm:ss A'); // date format should match the backend's
  return parsedDate.tz(moment.tz.guess()).format('MMMM Do YYYY h:mm a');
};

// Date formatting code added for audiobook 'Add Bookmark' popup.
AnnotationsMenuUtils.prototype.formatDateNoTime = function(dateString) {
  var parsedDate = moment.utc(dateString, 'YYYY-MM-DDThh:mm:ssZ'); //"MM/DD/YYYY hh:mm:ss A"); // date format should match the backend's
  return parsedDate.tz(moment.tz.guess()).format('MMMM Do, YYYY');
};

// Chapter title formatting for audiobook 'Add Bookmark' popup.
AnnotationsMenuUtils.prototype.formatChapterTitle = function(chapter) {
  var string = chapter == 0 ? 'Introduction' : 'Chapter ' + chapter;
  return string;
};

// Date formatting code added for audiobook 'Add Bookmark' popup.
AnnotationsMenuUtils.prototype.formatTime = function(duration) {
  var minutes = Math.floor(duration / 60000);
  var seconds = ((duration % 60000) / 1000).toFixed(0);
  return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
};

AnnotationsMenuUtils.prototype.displayError = function(error) {
  error.Messages = error.message;
  error.Type = 'Warning';

  splashMessage.init({
    closeBtn: false,
    cssClass: 'splash-system',
  });

  if (typeof error == 'string') {
    error = {
      Type: 'Error',
      Messages: ['An unexpected error has occurred.', error],
    };
  }

  error.Title = error.code ? error.code.replace('_', ' ') : Strings.err_dlg_title;

  splashMessage.message(error);
  splashMessage.$el.show();
};

AnnotationsMenuUtils.prototype.getLocation = function(loc) {
  return Helpers.locObjFromFullCFI(this.readium.packageDocument, loc, true);
};

export default AnnotationsMenuUtils;
