import DisableCopy from './disablecopy';
import TextSelection from './selection/selection';
import Accessibility from './accessibility/accessibility';
import Pagination from './pagination/main';
import Highlights from './highlights/main';
import AnchorElement from './anchor-element';

export function registerPlugins() {
  window.ReadiumSDKExport.Plugins.register('disablecopy', DisableCopy);
  window.ReadiumSDKExport.Plugins.register('selection', TextSelection);
  window.ReadiumSDKExport.Plugins.register('accessibility', Accessibility);
  window.ReadiumSDKExport.Plugins.register('pagination', Pagination);
  window.ReadiumSDKExport.Plugins.register('highlights', Highlights);
  window.ReadiumSDKExport.Plugins.register('anchorElement', AnchorElement);
}
