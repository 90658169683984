import $ from 'jquery';
import _ from 'underscore';
import hogan from 'hogan.js';
import OverlayTemplate from './templates/overlay-view.hbs';

var OverlayView = function(options) {
  // setting default options
  options = $.extend(
    {
      id: _.uniqueId('ovw'),
      wrapper: 'body',
      viewClass: '', // class appended with 'page-view'
      title: '', // shown in navbar
      attr: {}, // attribute for root container
      contents: '',
    },
    options,
  );

  // creating UI
  var $el = $(
    OverlayTemplate({
      overlayID: options.id,
      title: options.title,
      cssClass: options.viewClass,
    }),
  );

  $el.attr(options.attr);
  $(options.wrapper).append($el);

  var self = this;
  $el.on('click.close', '.close', function(e) {
    self.hide();
  });

  Object.defineProperties(this, {
    $el: {
      enumerable: false,
      writable: false,
      value: $el,
    },
    $body: {
      enumerable: false,
      writable: false,
      value: $el.find('.page-view'),
    },
  });
};

OverlayView.prototype.show = function() {
  if (this.$el && !this.$el.is(':visible')) {
    this.$el.show();
  }
};

OverlayView.prototype.hide = function() {
  this.$el.hide();
  // AccessibilityManager.panelCloseHandler(function() {
  //     setTimeout(function() {
  //         AccessibilityManager.setFocus("#btnToggleMainMenu")
  //     }, 500)
  // })
};

OverlayView.prototype.setContent = function(content) {
  if (!content) return;
  this.$body.empty();
  if ($.type(content) === 'string') {
    this.$body.html(content);
    return;
  }
  if (content instanceof jQuery) {
    this.$body.append(content);
  }
};

OverlayView.prototype.setView = function(view, model) {
  if (!view || typeof view != 'string') return;
  model = model || {};
  var tpl = hogan.compile(view);
  this.setContent(tpl.render(model));
};

OverlayView.prototype.focusTo = function(selector) {
  this.show();
  this.$body.scrollTop(0); //  scrolling up first
  var offset = this.$body.find(selector).offset();
  if (offset) {
    //this.$body.find(selector).focus();
    this.$body.scrollTop(offset.top - 60); // 60 = navbar
  }
};

export default OverlayView;
