var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"list-group-item\">\n    <span class=\"badge page-num\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"annotation") : depth0)) != null ? lookupProperty(stack1,"page") : stack1), depth0))
    + " <!--hogan var page--></span>\n    <!-- highlight colors are yellow, blue, red and green -->\n    <blockquote tabindex=\"0\" class=\"highlight-color "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"annotation") : depth0)) != null ? lookupProperty(stack1,"color") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"annotation") : depth0)) != null ? lookupProperty(stack1,"booktext") : stack1), depth0))
    + "<!--hogan var text--></blockquote>\n    <div class=\"edit-options\">\n            <p aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"annotation") : depth0)) != null ? lookupProperty(stack1,"notetext") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"annotation") : depth0)) != null ? lookupProperty(stack1,"notetext") : stack1), depth0))
    + " <!--hogan var note--></p>\n        <!-- order is important for following buttons -->\n        <!-- Bug230502 Tooltip is not available for the edit and delete icons-->\n        <button class=\"btn btn-default btn-sm btn-icon edit\" title=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"editBtnTitle") || (depth0 != null ? lookupProperty(depth0,"editBtnTitle") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"editBtnTitle","hash":{},"data":data,"loc":{"start":{"line":9,"column":68},"end":{"line":9,"column":84}}}) : helper)))
    + "\" style=\"right: 35px;bottom:10px;\" aria-label=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"editBtnClassAriaLabel") || (depth0 != null ? lookupProperty(depth0,"editBtnClassAriaLabel") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"editBtnClassAriaLabel","hash":{},"data":data,"loc":{"start":{"line":9,"column":131},"end":{"line":9,"column":156}}}) : helper)))
    + "\">\n            <span class=\"icon "
    + alias2(((helper = (helper = lookupProperty(helpers,"editBtnClass") || (depth0 != null ? lookupProperty(depth0,"editBtnClass") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"editBtnClass","hash":{},"data":data,"loc":{"start":{"line":10,"column":30},"end":{"line":10,"column":46}}}) : helper)))
    + "\" aria-hidden=\"true\" ></span></span>\n        </button>\n        <button class=\"btn btn-default btn-sm btn-icon delete\" title=\"Delete\" style=\"bottom:10px;\" aria-label=\"delete\">\n            <span class=\"icon icon-delete\" aria-hidden=\"true\" ></span>\n        </button>\n        \n        <span class=\"time-stamp\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"annotation") : depth0)) != null ? lookupProperty(stack1,"createdate") : stack1), depth0))
    + " <!--hogan var date--></span>\n    </div>\n</li>\n";
},"useData":true});