import findaway from './audiobook/findaway-sdk.min';
import moduleConfig from './ModuleConfig';
import $ from 'jquery';
import _ from 'underscore';
import Settings from './storage/Settings';
import Strings from './i18n/Strings';
import PopupFactory from './popup/PopupFactory';
import splashMessage from './SplashMessage';
import AudioNavbar from './templates/audiobook-navbar.hbs';
import AudioLanding from './templates/audiobook-landing.hbs';
import ReaderBookTitleHeader from './templates/reader-book-title-header.hbs';
import Keyboard from './Keyboard';
import UsageManager from './usage/UsageManager';
import Managers from '@axisnow/data/Managers';
import utils from '@axisnow/util/utils';
import Errors from '@axisnow/util/Errors';
import errorMonitor from './ErrorMonitor';
import moment from 'moment-timezone';
import AudioSpeedMenu from './menus/navbar/AudioSpeedMenu';
import BookmarksMenu from './menus/navbar/BookmarksMenu';
import SleepTimerMenu from './menus/navbar/SleepTimerMenu';
import HelpMenu from './menus/navbar/HelpMenu';
import MainMenu from './menus/navbar/MainMenu';
import Helpers from './EpubReaderHelpers';
import AccessibilityManager from './accessibility/AcessibilityManager';
import AccessibilityJson from './accessibility/accessibility.json';
import ActivateCST from './CacheStatusTool';
import UsageService from './usage/UsageService';
import PubSub from 'pubsub-js';
import AXISAnalyticshelper from './AXISAnalyticshelper';
import EngageManager from './EngageManager'; 

var _isReady;
var usageManager, usageService;
var player;
var engageManager;

//All Popup declarations, Confirmation moda and navbar and inline menu declarations
var popupModal = PopupFactory.create(PopupFactory.TYPE.MODAL);
var audioSpeedMenu; //Audio Speed
var bookmarksMenu; //Bookmarks
var sleepTimerMenu; //Sleep Timer
var helpMenu; //Help
var mainMenu; //MainMenu
var book;
var remoteInterval;
var localInterval;
var remotePosition;
var update;
var $appContainer;
var $chapterTitle;
var $coverImage;
var $currentTime;
var $remainingTime;
var $wdgSlider;
var $stRemainingTime;
var $imageWidth;
var sleepTimer;
var env;
var user;
var updatedTime;
// A flag for dragging the slider and stopping the timeChange event
var flagSliderPress = false;

Window.isAnnotation = false;

function bindToggleMenu(selector, hotkey, menu) {
  $(selector).on('click', function() {
     // update the icon color
    if (selector == "#btnToggleHelp") {
      $('#btnToggleHelp span:first').attr('class', 'icon icon-active-help');
      $('#btnToggleBookmark span:first').attr('class', 'icon icon-bookmark-outline');
    } else if (selector == "#btnToggleBookmark") {
      $('#btnToggleHelp span:first').attr('class', 'icon icon-help');
      $('#btnToggleBookmark span:first').attr('class', 'icon icon-active-bookmark-outline'); // update other higlighted icons
    } else {
      $('#btnToggleHelp span:first').attr('class', 'icon icon-help');
      $('#btnToggleBookmark span:first').attr('class', 'icon icon-bookmark-outline');
    }
    //Remove all active classes
    $('.navbar-btn').removeClass('active');

    if (menu.isVisible()) {
      $('#btnToggleBookmark span:first').attr('class', 'icon icon-bookmark-outline'); //update the icon
      $('#btnToggleHelp span:first').attr('class', 'icon icon-help'); //update the icon
      menu.hide();
    } else {
      $('.popup:visible').hide();
      menu.show();
      $(this).addClass('active');
    }
  });

  $(menu._popup.$header).on('touchmove', function(event) {
    if (event.target.type != 'button') {
      console.log('preventDefault ' + this);
      event.preventDefault();
    }
  });

  Keyboard.on(hotkey, 'reader', function() {
    $(selector).trigger('click');
  });
}
/**
 * Audio Player is working which based on event trigger so we used subscriber method
 * bcoz we removed popup dialog 
 */
PubSub.subscribe('AUDIO-PLAY-KEY',function (msg,data) {
   if(data){
       PlayEvent(data);
   }
  })
function setupMenus() {
  Keyboard.scope('reader');
  var id = player.currentTitle.id;
  audioSpeedMenu = new AudioSpeedMenu(null, usageManager, id, null, player);
  bookmarksMenu = new BookmarksMenu(null, usageManager, id, null, player);
  sleepTimerMenu = new SleepTimerMenu(null, usageManager, id, null, player);
  helpMenu = new HelpMenu(null, usageManager, id, null, player);
  mainMenu = new MainMenu(null, usageManager, id, null, player);

  bindToggleMenu('#btnToggleAudioSpeed', Keyboard.AudioSpeedMenu, audioSpeedMenu);
  bindToggleMenu('#btnToggleBookmark', Keyboard.Bookmark, bookmarksMenu);
  bindToggleMenu('#btnToggleSleepTimer', Keyboard.SleepTimerMenu, sleepTimerMenu);
  bindToggleMenu('#btnToggleMainMenu', Keyboard.Menu, mainMenu);
  bindToggleMenu('#btnToggleHelp', Keyboard.HelpMenu, helpMenu);

  Keyboard.on(Keyboard.HidePopups, 'reader', function() {
    popupModal.hideAll();
    $('.navbar-btn').removeClass('active');

    // closing overlay views
    //HelpPage.close();
  });
  Keyboard.on(Keyboard.PlayerNext, 'reader', function() {
    var sliderInput = document.getElementById('wdg-slider-input');
    var v = parseInt(sliderInput.value);
    player.seekTo(player.getPosition() + parseInt(sliderInput.step)).then(function() {
    });
  });
  Keyboard.on(Keyboard.PlayerPrev, 'reader', function() {
    var sliderInput = document.getElementById('wdg-slider-input');
    var v = parseInt(sliderInput.value);
    player.seekTo(player.getPosition() - parseInt(sliderInput.step));
  });
  var $slider = $('.audio-speed-slider');
  Keyboard.on(Keyboard.IncreaseAudioSpeed, 'reader', function() {
    $('#audioSpeed').show();
    setTimeout(function() {
      var value = parseInt($slider.val());
      $slider.prop('value', value + parseInt($slider.prop('step')));
      $slider.change();
    }, 300);
  });
  Keyboard.on(Keyboard.DecreaseAudioSpeed, 'reader', function() {
    $('#audioSpeed').show();
    setTimeout(function() {
      var value = parseInt($slider.val());
      $slider.prop('value', value - parseInt($slider.prop('step')));
      $slider.change();
    }, 300);
  });
  $slider.on('mouseup touchend', function(event) {
    $('#audioSpeed').show();
    setTimeout(function() {
      var value = parseInt($slider.val());
      $slider.change();
    }, 300);
  });

  $slider.on('keyup', function(event) {
    if (event.key == 'Escape') {
      $('#audioSpeed .close').trigger('click');
    }
  });
  var $sliderSleepTimer = $('.sleep-time-slider');
  Keyboard.on(Keyboard.IncreasePlayerSleepTimer, 'reader', function() {
    $('#sleepTimer').show();
    setTimeout(function() {
      var value = parseInt($sliderSleepTimer.val());
      $sliderSleepTimer.prop('value', value + parseInt($sliderSleepTimer.prop('step')));
      $sliderSleepTimer.change();
    }, 300);
  });
  Keyboard.on(Keyboard.DecreasePlayerSleepTimer, 'reader', function() {
    $('#sleepTimer').show();
    setTimeout(function() {
      var value = parseInt($sliderSleepTimer.val());
      $sliderSleepTimer.prop('value', value - parseInt($sliderSleepTimer.prop('step')));
      $sliderSleepTimer.change();
    }, 300);
  });
  $sliderSleepTimer.on('mouseup touchend', function(event) {
    $('#sleepTimer').show();
    setTimeout(function() {
      var value = parseInt($sliderSleepTimer.val());
      $sliderSleepTimer.change();
    }, 300);
  });
  Keyboard.on(Keyboard.PlayerPrevChapter, 'reader', function() {
    $('#btnPreviousChapter').click();
  });
  Keyboard.on(Keyboard.PlayerNextChapter, 'reader', function() {
    $('#btnNextChapter').click();
  });
  $sliderSleepTimer.on('keyup', function(event) {
    if (event.key == 'Escape') {
      $('#sleepTimer .close').trigger('click');
    }
  });
}

// Engage Module: Tracking user time spent on listening a audio book
var trackPageActivity = function (currentTrack, finalChap) {
  return utils.isOnline().then(function () {
    
    return engageManager.trackActivityToRemote(player.id
      , currentTrack
      , finalChap
      , 'audiobook'
    ).then(function (response) {
      // could not determine timeout so default to 15 minutes out
      //inactivityTimerId = window.setTimeout(triggerInactiveNotice, 900000);
    });
  }).catch(Errors.BrowserOffline, function () {
    //Unable to capture Activity, Browser Offline
    console.log('Browser is in offline');
  });
};

function msToTime(duration) {
  var seconds = parseInt((duration / 1000) % 60, 10),
    minutes = parseInt((duration / (1000 * 60)) % 60, 10),
    hours = parseInt((duration / (1000 * 60 * 60)) % 24, 10);

  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;

  return hours + ':' + minutes + ':' + seconds;
}

function minToMs(minutes) {
  var ms = minutes * 60 * 1000;
  return ms;
}

function updateUI() {
  var track = player.currentTrack;
  var chapter_number = player.currentTitle.chapters[track].chapter_number;
  $chapterTitle.empty();
  mainMenu.hide();
  bookmarksMenu.hide();
  audioSpeedMenu.hide();
  sleepTimerMenu.hide();
  var string = chapter_number === 0 ? 'Introduction' : 'Chapter ' + chapter_number;
  $chapterTitle.text(string);

  var duration = player.currentTitle.chapters[track].duration;
  $wdgSlider.prop('max', duration);
  $wdgSlider.attr('aria-valuemin', 0);
  $wdgSlider.attr('aria-valuemax', duration);
  var audioTitle = player.currentTitle.title;
  setBookTitle(audioTitle);
}

function onPlayerReady() {
  _isReady = true;

  Settings.get('reader', function(json) {
    Keyboard.applySettings(json);
    console.log('player', player);
    $coverImage.prepend(
      "<img src='" +
        player.currentTitle.cover_url +
        "' alt='" +
        player.currentTitle.title +
        ' ' +
        player.currentTitle.sub_title +
        "'>",
    );
    initPlayer();

    setupMenus();

    $('#svg-spinner').css('margin-top', '-145px');
    $appContainer.show();

    updateUI();

    initSaveIntervals();
    AXISAnalyticshelper.endTiming('Load', '');
    $('.hide-until-loaded').removeClass('hide-until-loaded');
  });
}

function determineRemotePosition(options) {
    if (options) {
       /**
        * We make the event using sub when we get information of user last listened track details
        */
      PubSub.publish('AUDIO-PLAY-KEY',options);      
    }
}

function loadUI(data) {

  $('.modal-backdrop').remove();

  $appContainer = $('#app-container');
  $appContainer.empty();
  $appContainer.append(
    AudioLanding({ strings: Strings, /* dialogs: Dialogs, BH-325 */ keyboard: Keyboard }),
  );

  $chapterTitle = $('#chapterTitle');
  $coverImage = $('.landing-icon');
  $currentTime = $('.pagination-current');
  $remainingTime = $('.pagination-remaining');
  $wdgSlider = $('#wdg-slider-input');
  $stRemainingTime = $('.st-time-remaining');

  var $navBar = $('#app-navbar');
  $navBar.empty();
  //$('nav').attr("aria-label", Strings.i18n_toolbar);
  $navBar.append(
    AudioNavbar({
      strings: Strings /* dialogs: Dialogs, BH-325 */,
      keyboard: Keyboard,
    }),
  );

  //Moving show navbar button at the top
  $navBar.before($('#buttShowToolBar'));

  $appContainer.hide();

  bindEvents();
  var id = data.audiobookid;

  usageManager = new UsageManager(Managers.user);
  engageManager = new EngageManager(Managers.user);
  usageService = new UsageService();
  Managers.user
    .getCurrentUserData()
    .then(function(data) {
      user = data;
      return (env = {
        accountId: moduleConfig.fa_accountid,
        sessionKey: data.fndSessionID,
        externalListener: data.bookvaultId,
        testAPIKey: '96cc0708-97ca-4ae3-a494-de43ccf7cd9e',
        contentId: data.fndContentID,
        licenseId: data.fndLicenseID,
        patronId: data.id,
        libraryPrefix: data.libraryPrefix,
        drmFormatName: 'ACOUSTIK',
      });
    })
    .then(function() {
      return findaway.init({
        session_key: env.sessionKey,
        external_listener_id: env.externalListener,
        debug: true,
      });
    })
    .then(function() {
      player = new findaway.Player(env.accountId);

      book = new findaway.Audiobook(env.contentId, env.accountId);
      book
        .load()
        .then(function() {
          return book.loadPlaylist(null, env.licenseId);
        })
        .catch(function() {
          return renewDRMSession()
            .then(function() {
              return book.load().then(function() {
                return book.loadPlaylist(null, env.licenseId).then(function() {
                  console.log('Session has been renewed successfully');
                });
              });
            })
            .catch(function(error) {
              displayError(error);
            });
        })
        .then(function() {
          var options;
          var authData = Managers.user.getCurrentAuthData();
          if(authData){
          var tempObj ={id:authData.fndContentID,uuid:authData.bookVaultID, profileid:authData.profile.id, isPrimary:authData.profile.isPrimary}
          return usageService.fetchLastAudioListeningPositionFromRemote(tempObj).then(function(posUsage) {
            console.log('respoonse-audio-position',JSON.stringify(posUsage))
            var positionsUsage = posUsage;
            var index = positionsUsage.audiopositions
              ? positionsUsage.audiopositions.length - 1
              : undefined;
            var localPosition = positionsUsage.audiopositions.length > 0 ? positionsUsage.audiopositions[index] : false;
            if (localPosition) {
              options = {
                book: book,
                track:parseInt(localPosition.chapter),
                position: localPosition.timeoffset,
                accountId: book.account_id,
              };
              /**
               *  If chapter without introduction we need to change the track index because chapter mismatched happened between natiave and web
               */
               if (options.book.chapters[0].chapter_number == 1) {
                var temp_track = options.track;
                options.track = (parseInt(temp_track) === 0 ? 0 : parseInt(temp_track) - 1 );
              }
              determineRemotePosition(options)
            } else {
              options = {
                book: book,
                track: 0,
                position: 0,
                accountId: book.account_id,
              };
              player.load(options).then(
             function() {
                onPlayerReady();
                player.seekTo(parseInt(options.position)) .then(function() {
                  togglePlayPause('play')
                });
                Window.isAnnotation = true;
              },
              function(error) {
                // findaway sometimes doesn't load the initial track and shows this message:
                //      "Audio load suspended by browser."
                // Seems to be OK with continuing as normal, but it rejects the promise
                console.warn('loaded player with possible error:', error);
                onPlayerReady();
              },
             
            );
            }        
          });
          }else {
            /**
             * Local storage doesnt has any data we will play from the beginning
             */
            options = {
                book: book,
                track: 0,
                position: 0,
                accountId: book.account_id,
              };
              player.load(options).then(
             function() {
                onPlayerReady();
                player.seekTo(options.position) .then(function() {
                  togglePlayPause('play')
                });
                Window.isAnnotation = true;
              },
              function(error) {
                // findaway sometimes doesn't load the initial track and shows this message:
                //      "Audio load suspended by browser."
                // Seems to be OK with continuing as normal, but it rejects the promise
                console.warn('loaded player with possible error:', error);
                onPlayerReady();
              },
             
            );
          }
        })
        .catch(function(error) {
          displayError(error);
        });
    });
    
}
/**
 * Below Method going to be make audio sync
 * @param {*} options 
 */
 function  PlayEvent(options){
   try {
    console.log('after-enter-to-remotePosition-timeoffset',JSON.stringify(options.timeoffset))
    console.log('after-enter-to-options-chapter',JSON.stringify(options.track))
        player.load(options).then(
             function() {
                onPlayerReady();
                player.seekTo(options.timeoffset) .then(function() {
                  togglePlayPause('play')
                });
                Window.isAnnotation = true;
              },
              function(error) {
                // findaway sometimes doesn't load the initial track and shows this message:
                //      "Audio load suspended by browser."
                // Seems to be OK with continuing as normal, but it rejects the promise
                console.warn('loaded player with possible error:', error);
                onPlayerReady();
              },
             
            ); 
   } catch (error) {
     console.log('PlayEvent-func-catch',error.message);
   }
 }
function setBookTitle(title) {
  //set title of tab in browser
  //document.title = document.title + ' - ' + title; // we removed brand name  ticket #205732
  document.title = title;

  var $titleEl = $('.book-title-header');
  if ($titleEl.length) {
    $titleEl.text(title);
  } else {
    var html = ReaderBookTitleHeader({ title: title });
    $(html).insertBefore('#app-container');
  }
}
function unloadUI() {}

function initPlayer() {
  $('.js-volume').val(player.volume);

  player.on('playback:started', function() {
    if (player.isSample) {
      return;
    }

    var id = player.currentTitle.id,
      $book = $('#book-' + id);

    // controllers.collection.bookPlay($book);
    // controllers.player.play(player.currentTitle);
    // controllers.collection.bookPlay($book);
  });
  player.on('load:recoveryStart', function() {
    console.log('recovery started');
  });
  player.on('load:recoveryEnd', function() {
  });
  player.on('load:recoveryFailed', function(error) {
    if (error == undefined) {
      error = new Error();
    }
    utils
      .isOnline()
      .then(function() {
        genericError(error);
      })
      .catch(function(error) {
        alertOffline(error);
      });
  });
  player.on('trackCompleted', function() {
    var finalChap = player.currentTitle.chapters.length - 1;
    if (player.currentTrack == finalChap) {
      trackPageActivity(player.currentTitle.chapters.length, player.currentTitle.chapters.length);
      player.changeTrack(0);
      return;
    }
    trackPageActivity((player.currentTrack + 1), player.currentTitle.chapters.length);
    player.next();
  });

  player.on('trackChanged', function() {
    update = true;
    savePlace();
    updateUI();
    var sleepTimer = sleepTimerMenu.getSleepTimer();
    if (_isReady && sleepTimer && sleepTimer.isEndOfChap()) {
      sleepTimer.clearTimer();
      $wdgSlider.prop('value', 0);
      $wdgSlider.attr('aria-valuenow', 0);
      player.setPosition(0);
      $('#btnPause span:first').attr('class', 'icon icon-audio-play');
      setTimeout(function() {
        $('#btnPause').attr('title', 'play');
        $('#btnPause').attr('aria-label', 'play');
      }, 200);
      AXISAnalyticshelper.endTiming('Track', {audiobookId: audiobookId,'type': 'Audiobook'});
    } else if (_isReady && !player.isPlaying()) {
      player.play();
      player.setSpeed(player.getSpeed());
      $('#btnPause span:first').attr('class', 'icon icon-audio-pause');
      setTimeout(function() {
        $('#btnPause').attr('title', 'pause');
        $('#btnPause').attr('aria-label', 'pause');
      }, 200);
      AXISAnalyticshelper.endTiming('Track', '');
    }
  });

  player.on('timeChanged', function(time) {
    /**
     * Some of the time if time variable value come is undefined so it display like as "NAN"
     */
    if(time){
    updatedTime = time;
    $currentTime.empty();
    $remainingTime.empty();

    $currentTime.append(msToTime(time));
    var rTime = player.currentTitle.chapters[player.currentTrack].duration - time;
    $remainingTime.append('-' + msToTime(rTime));

    if (!flagSliderPress) {
      $wdgSlider.prop('value', time);
      $wdgSlider.attr('aria-valuenow', time);
      $wdgSlider.attr('aria-valuetext', 'Time = ' + msToTime(time));
    }
  }
  });

  player.on('playback:paused', function(_player) {
    if (!_player.isSample) {
      var $book = $('#book-' + _player.currentTitle.id);
      // controllers.collection.bookPause($book);
    }
  });

  player.on('unloaded', function() {
    // controllers.collection.bookUnload();
    // controllers.player.unload();
  });

  player.on('load:success', function() {
    if (Window.isAnnotation) {
      player.seekTo(updatedTime);
      Window.isAnnotation = false;
    }
  });

  player.on('bookCompleted', function(_player) {
    console.log('bookCompleted');
    var trackAtCompletion = player.currentTrack;
    _.defer(function() {
      player.changeTrack(0).then(function() {
        updateUI();
      });
    });
  });
}

function hideAllPopupMenus() {
  popupModal.hideAll();
  // update the icon
  $('#btnToggleHelp span:first').attr('class', 'icon icon-help');
  $('#btnToggleBookmark span:first').attr('class', 'icon icon-bookmark-outline');
  $('.navbar-btn').removeClass('active');
}

function bindEvents() {
  var permissionManager = Managers.appPermission;
  var cstPermission = permissionManager.permission('CacheStatusTool');
  if (!cstPermission.disableByDefault) {
    $('.app-version').click(ActivateCST);
  }

  $('body').on('click', function(e) {
    if ($(e.target).parents('.popup, #app-navbar').length == 0) {
      hideAllPopupMenus();
    }
  });

  $appContainer.find('.js-player-close').on('click', function(event) {
    event.preventDefault();
    clearSaveIntervals();
    player.trigger('unload');
  });

  // not used, toggle btnPause instead
  // $appContainer.find('#btnPlay').on('click', function (event) {
  //     event.preventDefault();
  //     player.play();
  //     $('.js-player .active').removeClass('active');
  //     $(this).addClass('active');
  // });

  $appContainer.find('#btnPause').on('click', function(event) {
    event.preventDefault();
    $('#btnPause').removeClass('active');

    if ($('#btnPause span:first').hasClass('icon-audio-pause')) {
      $('#btnPause span:first').attr('class', 'icon icon-audio-play');
      player.pause();
      update = true;
      savePlace();
      setTimeout(function() {
        $('#btnPause').attr('title', 'play');
        $('#btnPause').attr('aria-label', 'play');
      }, 200);
    } else {
      $('#btnPause span:first').attr('class', 'icon icon-audio-pause');
      player.play();
      player.setSpeed(player.getSpeed());
      setTimeout(function() {
        $('#btnPause').attr('title', 'pause');
        $('#btnPause').attr('aria-label', 'pause');
      }, 200);
    }
  });

  $appContainer.find('#btnPreviousChapter').on('click', function(event) {
    event.preventDefault();
    if (player.currentTrack === 0) {
      player.seekTo(0);
    } else {
      trackPageActivity((player.currentTrack + 1), player.currentTitle.chapters.length);
      player.play();
      setTimeout(function() {
        $('#btnPause').attr('title', 'pause');
        $('#btnPause').attr('aria-label', 'pause');
      }, 200);
      player
        .prev()
        .catch(function(error) {
          if (!window.navigator.onLine) {
            alertOffline(error);
          } else {
            genericError(error);
          }
        })
        .finally(function() {
          AXISAnalyticshelper.endTiming('Track', '');
        });
    }
  });

  $appContainer.find('#btnNextChapter').on('click', function(event) {
    event.preventDefault();
    AXISAnalyticshelper.endTiming('Track', '');
    var finalChap = player.currentTitle.chapters.length - 1;
    if (player.currentTrack > finalChap) {
      return;
    } else if (player.currentTrack == finalChap) {
      // Do nothing (fixing AX-1188)
      //player.seekTo(player.currentTitle.chapters[finalChap].duration);
    } else {
      trackPageActivity((player.currentTrack + 1), player.currentTitle.chapters.length);
      player.play();
      setTimeout(function() {
        $('#btnPause').attr('title', 'pause');
        $('#btnPause').attr('aria-label', 'pause');
      }, 200);
      player
        .next()
        .catch(function(error) {
          if (!window.navigator.onLine) {
            alertOffline(error);
          } else {
            genericError(error);
          }
        })
        .finally(function() {
        });
    }
  });

  $appContainer.find('#btnPrevious').on('click', function(event) {
    event.preventDefault();
    $(this).prop('disabled', true);
    window.setTimeout(function() {
      $appContainer.find('#btnPrevious').prop('disabled', false);
    }, 750);

    player.skipBack(30);
    update = true;
    savePlace();
    AXISAnalyticshelper.endTiming('Track', '');
  });

  $appContainer.find('#btnNext').on('click', function(event) {
    event.preventDefault();
    $(this).prop('disabled', true);
    window.setTimeout(function() {
      $appContainer.find('#btnNext').prop('disabled', false);
    }, 750);

    player.seekTo(player.getPosition() + 30000);
    update = true;
    savePlace();
    AXISAnalyticshelper.endTiming('Track', '');
  });

  $appContainer.find('#wdg-slider-input').on('input', function(event) {
    event.preventDefault();
    updatePositionAndFlag(true);
  });

  $appContainer.find('#wdg-slider-input').on('change', function(event) {
    event.preventDefault();
    updatePositionAndFlag(false);
    update = true;
    savePlace();
  });

  //not yet incorporated into ListenNow
  $appContainer.find('.js-player-skipToFirst').on('click', function(event) {
    event.preventDefault();
    player.changeTrack(0);
  });

  $appContainer.find('.js-player-skipToLast').on('click', function(event) {
    event.preventDefault();
    player.changeTrack(book.playlist.length - 1);
  });

  $appContainer.find('.js-volume').on('change', function(event) {
    event.preventDefault();
    var vol = $(this).val();
    player.setVolume(vol);
  });
}

function updatePositionAndFlag(flag) {
  var x = $wdgSlider.prop('value');
  player.setPosition(x);
  flagSliderPress = flag;
}

function initSaveIntervals() {
  //var remoteTime = 2 * 60 * 1000; //5min
  var remoteTime = 180000; //3min
  var localTime = 10 * 1000; //10sec
  update = false;

  remoteInterval = setInterval(function() {
    update = true;
  }, remoteTime);

  localInterval = setInterval(function() {
    savePlace();
  }, localTime);
}

function clearSaveIntervals() {
  clearInterval(remoteInterval);
  clearInterval(localInterval);
}
function togglePlayPause(action) {
  $('#btnPause span:first').attr('class', `icon icon-icon-${action}`);
  // AN - 11/23/30 - Not sure timeout is needed
  setTimeout(() => {
    $('#btnPause').attr('aria-label', action);
  }, 200);
}
function savePlace() {
  var id = player.currentTitle.id;
  var track = player.currentTrack;
  var chapter_number = player.currentTitle.chapters[track].chapter_number;
  usageManager.getPositionsUsage(id)
  .then(function(posUsage) {
    try {
      console.log('In method savePlace of AudiobookPlayer.js file');
      var positionsUsage = posUsage;
      var trackObj = {
        timeoffset: player.getPosition(),
        chapter: chapter_number,
        part: player.currentTitle.chapters[track].part_number,
      };
      console.log('audiopositions value:' + JSON.stringify(positionsUsage.audiopositions));
      //var lastPosition = (positionsUsage.audiopositions && positionsUsage.audiopositions.length>0) ? positionsUsage.audiopositions[positionsUsage.audiopositions.length-1]:undefined;
      var lastPosition = positionsUsage.audiopositions[positionsUsage.audiopositions.length - 1];
      var useNew = true;
      if (lastPosition) {
        useNew = lastPosition.part != trackObj.part;
        useNew = useNew || lastPosition.chapter != trackObj.chapter;
        useNew = useNew || lastPosition.timeoffset != trackObj.timeoffset.toFixed(0);
      }

      if (useNew) {
        positionsUsage.addTrackUsage(trackObj);
        usageManager.savePositionsUsage(positionsUsage);
      }

      if (update) {
        update = false;
        return utils.isOnline().then(function() {
          usageManager
            .fetchListeningPositionFromRemote(id)
            .then(function(response) {
              var remotePosition;
              if (typeof response.audiopositions != 'undefined') {
                remotePosition = response.audiopositions[0];
              }
              var lastPosition = positionsUsage.audiopositions[
                positionsUsage.audiopositions.length - 1
              ];

              if (remotePosition == undefined) {
                return usageManager.saveListeningPositionsToRemote(id);
              }

              var diffTimeoffset = remotePosition.timeoffset != trackObj.timeoffset.toFixed(0);
              var diffChapter = remotePosition.chapter != trackObj.chapter;
              var diffPart = remotePosition.part != trackObj.part;
              var newPosition = diffTimeoffset || diffChapter || diffPart;

              var lastDate = new Date(lastPosition.usercreatedate);
              var remoteDate = new Date(remotePosition.usercreatedate);
              remoteDate = remoteDate > new Date() ? 0 : remoteDate; //this serves as a sanity check

              if (newPosition && lastDate.valueOf() > remoteDate.valueOf()) {
                return usageManager.saveListeningPositionsToRemote(id);
              }
            })
            .catch(function(error) {
              // Do nothing for now
            });
        });
      }
    } catch (error) {
      var metaData = {
        'Findaway Metadata': {
          id: player.currentTitle.id,
          title: player.currentTitle.title,
          account_id: player.currentTitle.account_id,
          publisher: player.currentTitle.publisher,
        },
      };
      errorMonitor.bugsnag(error, metaData);
    }
  });
}

var renewDRMSession = function() {
  var jqXhr = $.ajax({
    type: 'POST',
    data: JSON.stringify({
      patronID: env.patronId,
      formatName: env.drmFormatName,
      library: env.libraryPrefix,
    }),
    contentType: 'application/json',
    url: utils.getRemoteServerOrigin().path('drmSession'),
  });
  return utils
    .deferredToPromise(jqXhr)
    .then(function(response) {
      if (response.status && response.status.code != '0000') {
        throw response;
      } else {
        env.sessionKey = response.drmSessionID;
        user.fndSessionID = response.drmSessionID;
        // The purpose of the call below is not clear, as the return value is ignored.
        // Can this line be removed?
        // It also has potential to cause a race condition. See bug AX-1022
        Managers.user.importUserData(user);

        return findaway.init({
          session_key: env.sessionKey,
          external_listener_id: env.externalListener,
          debug: true,
        });
      }
    })
    .catch(function(error) {
      var errorObj = {
        Title: 'Error Renewing DRM Session',
        Type: 'Error',
        code: 'Error Renewing DRM Session',
      };

      if (error.status) {
        $.extend(errorObj, {
          message: error.status.statusMessage,
          error_number: error.status.code,
        });
      } else {
        $.extend(errorObj, { message: error.ErrorMessage, error_number: error.ErrorCode });
      }

      error = new Errors.DRMSessionServiceError(errorObj);
      errorMonitor.bugsnag(error);
      throw error;
    });
};

var displayError = function(error) {
  error.Messages = error.message;
  error.Type = 'Warning';
  var metaData = {
    'Findaway metadata': {
      id: player.currentTitle.id,
      title: player.currentTitle.title,
      account_id: player.currentTitle.account_id,
      publisher: player.currentTitle.publisher,
    },
  };
  errorMonitor.bugsnag(error, metaData);

  var urlParams = Helpers.getURLQueryParams();
  $.extend(error, { bookId: env.contentId, UUID: env.externalListener, urlParams: urlParams });

  setBookTitle(urlParams.audiobook);
  splashMessage.init({
    closeBtn: false,
    cssClass: 'splash-system',
    urlParams: urlParams,
  });

  if (typeof error == 'string') {
    error = {
      Type: 'Error',
      Messages: ['An unexpected error has occurred.', error],
    };
  }

  error.Title = error.code ? error.code.replace('_', ' ') : Strings.err_dlg_title;

  splashMessage.message(error);
  splashMessage.$el.show();
};

var alertOffline = function(error) {
  error.code = 'Device Is Offline';
  error.message = 'Please return online for full audiobook functionality.';
  error.feedbackBtn = false;

  displayError(error);
};

var genericError = function(error) {
  error.code = 'Audiobook Error';
  error.message = 'Unable to access audiobook. Please try again later.';

  displayError(error);
};

var setTitle = function(branding) {
  document.title = branding;
};
/**
 * it used to save audio position information to server when user close the tab
 */
 window.onbeforeunload = function () {
  savePlace();
 };

export default {
  loadUI: loadUI,
  unloadUI: unloadUI,
  setTitle: setTitle,
};
