import $ from 'jquery';
import utils from '@axisnow/util/utils';
//import version from '../Version';
import Managers from '@axisnow/data/Managers';
import Promise from 'bluebird';
import Errors from '@axisnow/util/Errors';
import moment from 'moment-timezone';
import PackageInfo from '../PackageInfo';


//var authToken = "EDE24724-76A4-4AAC-BEBD-0B0D32D17D93";
var appid;
var featureManager = Managers.feature;
var usageServerRequestTimeout = 20000; // milliseconds

function getAppId(obj) {
  return new Promise(function(resolve, reject) {
    if (!obj) {
      obj = {};
    }
    if (!appid) {
      appid = 'AxisNow-' + PackageInfo.version;
    }
    obj.appid = appid;
    resolve(obj);
  });
}

var UsageService = function() {};

/*********** FETCH AJAX *************/
// bookKey: isbn for ebooks; id for audio books
var getAjax = routeName => bookKey => profile => {
   /**
   * Kidszone fetch annotation,page and position from profile based and not Audult user
   */
    var path;
    if (profile.profileid && !profile.isPrimary) {
        
        if (routeName === 'annotations')
          path = routeName + '/v2/' + profile.uuid + '/ /' + profile.profileid + '/' + bookKey
        else 
          path = routeName + '/v2/' + profile.uuid + '/' + profile.profileid + '/' + bookKey
    } else {
      path = routeName + '/' + profile.uuid + '/' + bookKey;  
    }
  var jqXhrPage = $.ajax({
    type: 'GET',
    cache: false,
    contentType: 'application/json',
    url: utils.getRemoteServerOrigin().path(path),
    timeout: usageServerRequestTimeout,
  });
  var promise = utils.deferredToPromise(jqXhrPage);
  return promise
    .then(function(response) {
      if (
        !response ||
        (response.code == 'ETIMEDOUT' ||
          response.code == 'ECONNRESET' ||
          response.code == 'ECONNREFUSED')
      ) {
        var error = new Error(response.code);
        error.timeout = true;
        error.responseText = response.code;
        console.error(response,error);
        throw error;
      }
      return response;
    })
    .catch(function(error) {      
      return error;
    });
}

var getPageUsageAjax = getAjax('page');
var getAnnotationsUsageAjax = getAjax('annotations');
var getPositionsUsageAjax = getAjax('positions');
var getAudioAnnotationsUsageAjax = getAjax('audiobookmarks');

/*********** SAVE AJAX *************/
var postAjax = routeName => data => {
  var jqXhr = $.ajax({
    type: 'POST',
    data: JSON.stringify(data),
    contentType: 'application/json',
    url: utils.getRemoteServerOrigin().path(routeName),
    timeout: usageServerRequestTimeout,
  });
  return utils
    .deferredToPromise(jqXhr)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {      
      return error;
    });
};

/**
 * Save Bulk Offline Data to server
 * @param {*} routeName 
 * @returns 
 */
var postBulkData = routeName => data => {
$.when.apply(null, data).done(function() {
    console.log('Data-sync-done');
})
 }

var saveBulkData = postBulkData('pages');
var savePageUsageAjax = postAjax('pages');
var saveAnnotationsUsageAjax = postAjax('annotations');
var deleteAnnotationsUsageAjax = postAjax('annotations-delete');

var savePositionUsageAjax = postAjax('positions');
var saveAudioAnnotationsUsageAjax = postAjax('audiobookmarks');
var deleteAudioAnnotationsUsageAjax = postAjax('audiobookmarks-delete');

/*********** EBOOK ANNOTATIONS *************/
var getAnnotationDataWithFlags  = function(annotationsUsage, isAddUpdate) {
  var annotationsUsage = annotationsUsage;
  var chapterArray = [];
  for (var refid in annotationsUsage.spines) {
    var spineItem = {
      refid: refid,
      annotations: [],
    };

    /* jshint ignore:start */
    // Ignore JSHint error: Functions declared within loops referencing an outer scoped variable may lead to confusing semantics.
    if (isAddUpdate) {
      annotationsUsage.spines[refid].annotations.forEach(function(annotation) {
        if (annotation.newAnnotation) {
          console.log('New annotation in spine :' + refid);
          spineItem.annotations.push(annotation);
        }
        if (annotation.updateAnnotation) {
          console.log('Updated annotation in spine :' + refid);
          spineItem.annotations.push(annotation);
        }
      });
    } else {
      annotationsUsage.spines[refid].annotations.forEach(function(annotation) {
        if (annotation.deleteAnnotation) {
          console.log('Deleting annotation from refid :' + refid);
          var annotationCopy = $.extend(true, {}, annotation);
          delete annotationCopy.deleteAnnotation;
          spineItem.annotations.push(annotationCopy);
        }
      });
    }
    
    /* jshint ignore:end */
    if (spineItem.annotations.length > 0) {
      chapterArray.push(spineItem);
    }
  }
  if (isAddUpdate) {
    return getAppId(annotationsUsage).then(function(annotationsUsage) {
      var annotationsData = $.extend(true, {}, annotationsUsage);
      annotationsData.spines = chapterArray;
      return annotationsData;
    });
  }
  else {
    return new Promise(function(resolve) {
      var annotationsData = $.extend(true, {}, annotationsUsage);
      annotationsData.spines = chapterArray;
      annotationsData.siteid = '';
      resolve(annotationsData);
    })
  }
}

UsageService.prototype.fetchAnnotationsFromRemote = function(annotUsage) {
  // TODO:
  // if (userUsage.bookExists(isbn)) {
  //   userUsage.removeBookFromUserUsage();
  //   //return userUsage.getBook(isbn);
  // }
  return getAnnotationsUsageAjax(annotUsage.isbn)(annotUsage)
    .then(function(response) {
      var annotationsUsage = annotUsage;
      annotationsUsage = $.extend(annotationsUsage, response);
      return annotUsage.update(annotationsUsage);
    })
    .catch(function(error) {
      return utils
        .isOnline(function() {
          console.error('annotationsPromise', error);
          if (error.ReturnCode === 9300) {
            return annotUsage;
          } else {
            var newError = new Errors.AnnotationsServiceError(error);
            newError.origin = 'annotations';
            throw newError;
          }
        })
        .catch(function() {
          // browser is most likely offline
          return annotUsage;
        });
    });
};

UsageService.prototype.saveNotesHighlights = function(usage, skipLocalUpdate) {
  let annotationsUsage = usage;
  return getAnnotationDataWithFlags(annotationsUsage, true).then(function(annotationsData) {
    if (annotationsData.spines.length > 0) {

       /**
        * profileID attribute added on annotation payload but If find user as primary we removed the profileid from the annotation payload
        */
       
      if (annotationsData.profileid && annotationsData.isPrimary) {
             
          delete annotationsData.profileid;
          delete annotationsData.profileType;
          delete annotationsData.isPrimary;
       }
      // send the list of annotations to save to remote
      return saveAnnotationsUsageAjax(annotationsData)
        .then(function(response) {
          /**
          * user added the bookmark once we get response from server focus color is removed
         */
          $('#btnToggleBookmark').removeClass('currentActive');
          if (skipLocalUpdate) {
            return usage;
          }
          let annotationsWithoutFlags = usage;
          // replace the local collection with how the server sees it
          response.spines.forEach(function(element) {
            annotationsWithoutFlags.spines[element.refid] = element;
          });
          return annotationsUsage.update(annotationsWithoutFlags);
        })
        .catch(function(error) {
          return utils
            .isOnline(function() {
              throw error;
            })
            .catch(function() {
              // browser is most likely offline
              return annotationsUsage;
            });
        });
    }
    return annotationsUsage;
  });
};

UsageService.prototype.deleteNotesHighlights = function(usage, skipLocalUpdate) {
  var annotationsUsage = usage;

  return getAnnotationDataWithFlags(annotationsUsage, false).then(function(annotationsData) {
    if (annotationsData.spines.length > 0) {
      return deleteAnnotationsUsageAjax(annotationsData)
      .then(function(response) {
        if (skipLocalUpdate) {
          return annotationsUsage;
        }
        var annotationsWithoutFlags = annotationsUsage
  
        // update annotationsUsage list if there is no annotation in that spine after deleting
        if (response.spines.length == 0 && annotationsData.spines.length > 0) {
          annotationsData.spines.forEach(function(element) {
            delete annotationsWithoutFlags.spines[element.refid];
          })
        };
  
        // replace the local collection with how the server sees it
        response.spines.forEach(function(element) {
          if (element.annotations != null) {
            annotationsWithoutFlags.spines[element.refid] = element;
          } else {
            delete annotationsWithoutFlags.spines[element.refid];
          }
        });
        return annotationsUsage.update(annotationsWithoutFlags);
      })
      .catch(function(error) {
        return utils
          .isOnline(function() {
            throw error;
          })
          .catch(function() {
            // browser is most likely offline
            return annotationsUsage;
          });
      });
    }
    return annotationsUsage;
  })
};

/*********** AUDIO BOOKMARKS *************/
var getAudioAnnotationData = function(annotUsage, isAddUpdate) {
  var audioPositionArray = [];
  var annotationsUsage = annotUsage;
  if (isAddUpdate) {
     //without flags
    annotationsUsage.audiopositions.forEach(function(audioPosition) {
      if (audioPosition.newPosition) {
        console.log('New Position in audiopositions:' + audioPosition);
        delete audioPosition.newPosition;
        audioPositionArray.push(audioPosition);
      }
      if (audioPosition.updatePosition) {
        console.log('Updated Position in audiopositions:' + audioPosition);
        delete audioPosition.updatePosition;
        audioPositionArray.push(audioPosition);
      }
    });
  } else {
    annotationsUsage.audiopositions.forEach(function(audioPosition) {
      if (audioPosition.deleteAnnotation) {
        console.log('Deleting audioPosition:' + audioPosition);
        var audioPositionCopy = $.extend(true, {}, audioPosition);
        delete audioPositionCopy.deleteAnnotation;
        audioPositionArray.push(audioPositionCopy);
      }
    });
  }
  return getAppId(annotationsUsage).then(function(annotationsUsage) {
    var annotationsData = $.extend(true, {}, annotationsUsage);
    annotationsData.audiopositions = audioPositionArray;
    return annotationsData;
  });
}

UsageService.prototype.saveAudioPositionsToRemote = function(usage, skipLocalUpdate) {
  let annotationsUsage = usage;
  return getAudioAnnotationData(annotationsUsage, true).then(function(annotationsData) {
    if (annotationsData.audiopositions.length > 0) {

       /**
        * profileID attribute added on page payload but If find user as primary we removed the profileid from the pageusage payload
       */
        var temp_annotations_array = [];
        var temp_position = annotationsData.audiopositions[annotationsData.audiopositions.length - 1];
        if (annotationsData.profileid && !annotationsData.isPrimary) {
          temp_position.profileid = annotationsData.profileid;
          temp_annotations_array.push(temp_position);
          annotationsData.audiopositions = temp_annotations_array;
        }

      return saveAudioAnnotationsUsageAjax(annotationsData)
        .then(function(response) {
          if (skipLocalUpdate) {
            return annotationsUsage;
          }
          let annotationsWithoutFlags = annotationsUsage;
          annotationsUsage.update(annotationsWithoutFlags);
          return annotationsUsage;
        })
        .catch(function(error) {
          return utils
            .isOnline(function() {
              throw error;
            })
            .catch(function() {
              // browser is most likely offline
              return annotationsUsage;
            });
        });
    }
    return annotationsUsage;
  });
};

UsageService.prototype.deleteAudioPositionsFromRemote = function(annotUsage, skipLocalUpdate) {
  let annotationsUsage = annotUsage;
  return getAudioAnnotationData(annotationsUsage, false).then(function(annotationsData) {
    return deleteAudioAnnotationsUsageAjax(annotationsData)
      .then(function(response) {
        if (skipLocalUpdate) {
          return annotationsUsage;
        }
        let annotationsWithoutFlags = annotationsUsage;
        return annotationsUsage.update(annotationsWithoutFlags);
      })
      .catch(function(error) {
        return utils
          .isOnline(function() {
            throw error;
          })
          .catch(function() {
            // browser is most likely offline
            return annotationsUsage;
          });
      });
  });
};

UsageService.prototype.fetchAudioPositionsFromRemote = function(annotUsage) {
  // TODO:
  // if (userUsage.bookExists(isbn)) {
  //   userUsage.removeBookFromUserUsage();
  // }
  return getAudioAnnotationsUsageAjax(annotUsage.id)(annotUsage)
    .then(function(response) {
      var annotationsUsage = annotUsage;
      annotationsUsage = $.extend(annotationsUsage, response);
      return annotUsage.update(annotationsUsage);
    })
    .catch(function(error) {
      console.error('annotationsPromise', error);

      if (error.ReturnCode === 9300) {
        return annotUsage;
      } else {
        throw new Errors.AnnotationsServiceError(error);
      }
    });
};

/*********** PAGE / READING POSITION *************/
UsageService.prototype.saveCurrentPositionToRemote = function(pageUsage) {
  var currentpositions = [];
  var locationArray = pageUsage.locationArray;
  locationArray.split(',').map(function(item) {
    if (!item) {
      item = pageUsage.location;
    }
    /**
      * Kidszone profileid related changes done
      */
      var currentposition = {
        uuid: pageUsage.uuid,
        isbn: pageUsage.isbn,
        location: item,
        wordsonpage: pageUsage.wordsonpage,
        usercreatedate: pageUsage.usercreatedate,
      }
    if (pageUsage.profileid && !pageUsage.isPrimary) {
       currentposition.profileid = pageUsage.profileid;
    }
    currentpositions.push(currentposition);
  });
  return getAppId()
    .then(function(data) {
      data.currentpositions = currentpositions;
      return savePageUsageAjax(data);
    }).then(function(res) {
      pageUsage.locationArray = '';
    });
};

/**
 * Send Offline Book usage bulk Data To server
 * @param {*} pageUsage 
 * @returns 
 */
UsageService.prototype.sendBulkDataPositionToRemote = function(pageUsage) {
  var currentpositions = [],promises = [];
  getAppId()
    .then(function(data) {
  for (let index = 0; index < pageUsage.length; index++) {
    const element = pageUsage[index];
  var locationArray = element.locationArray;
  locationArray.split(',').map(function(item) {
    if (!item) {
      item = element.location;
    }
    currentpositions.push({
      uuid: element.uuid,
      isbn: element.isbn,
      location: item,
      wordsonpage: element.wordsonpage,
      usercreatedate: element.startTime,
    });
  });
      data.currentpositions = currentpositions;
      console.log('json-data',JSON.stringify(data))
         var request = $.ajax({
          type: 'POST',
          data: JSON.stringify(data),
          contentType: 'application/json',
          url: utils.getRemoteServerOrigin().path('pages'),
          timeout: usageServerRequestTimeout,
            success: function (resp) {
              console.log('response-bulk-data-to-server',resp)
            }
        })
        console.log('promise-request',JSON.stringify(request))
        promises.push(request); 
}
return saveBulkData(promises);
});
};

UsageService.prototype.fetchCurrentPositionFromRemote = function(pgUsage) {
  // TODO:
  // if (userUsage.bookExists(isbn)) {
  //   userUsage.removeBookFromUserUsage();
  //   //return userUsage.getBook(isbn);
  // }
  /**
   * 
   */
  return getPageUsageAjax(pgUsage.isbn)(pgUsage)
    .then(function(response) {
      var pageUsage = pgUsage;
      var pageUsageCreateddate = moment(pageUsage.usercreatedate)
        .utc()
        .format();
      var reponseCreatedDate =
        typeof response.usercreatedate != 'undefined'
          ? moment(new Date(response.usercreatedate))
              .format()
              .split('+')[0] + 'Z'
          : pageUsageCreateddate;
      if (
        typeof pageUsage.usercreatedate == 'undefined' ||
        reponseCreatedDate >= pageUsageCreateddate
      ) {
         /**
       * Kidszone profileid changes
       */
        if (!response.hasOwnProperty('location')) {
            pageUsage.location = '';
            pageUsage = $.extend(pageUsage, response);
        } else {
            pageUsage = $.extend(pageUsage, response);
        }
      }

      return pgUsage.update(pageUsage);
    })
};

/*********** AUDIO LISTENING POSITION *************/
UsageService.prototype.fetchListeningPositionFromRemote = function(posUsage) {
  return getPositionsUsageAjax(posUsage.id)(posUsage)
    .then(function(response) {

      if (!response.hasOwnProperty('audiopositions')) { // used for video book titles when network status changed while playing
         posUsage.isOffline = true;
         var positionsUsage = posUsage;
         positionsUsage = $.extend(true, positionsUsage, response);
      } else {
         posUsage.isOffline = false;
         var positionsUsage = posUsage;
         positionsUsage = $.extend(true, positionsUsage, response);
      }

      return posUsage.update(positionsUsage);
    })
    .catch(function(error) {
      if (error.ReturnCode === 9300) {
        console.log('No audiopositions saved.  Creating new audiobookUsage object.');
        return posUsage;
      } else {
        console.error('positionsPromise', error);
        throw new Errors.AnnotationsServiceError(error);
      }
    });
};
/*********** GET AUDIO LAST LISTENING POSITION *************/
UsageService.prototype.fetchLastAudioListeningPositionFromRemote = function(posUsage) {
  console.log('fetchLastAudioListeningPositionFromRemote-request',JSON.stringify(posUsage))
  return getPositionsUsageAjax(posUsage.id)(posUsage)
    .then(function(response) {
      var positionsUsage = posUsage;
      positionsUsage = $.extend(true, positionsUsage, response);
      console.log('fetchLastAudioListeningPositionFromRemote',JSON.stringify(positionsUsage)) 
      return positionsUsage;
    })
    .catch(function(error) {
      if (error.ReturnCode === 9300) {
        console.log('No audiopositions saved.  Creating new audiobookUsage object.');
        return posUsage;
      } else {
        console.error('positionsPromise', error);
        throw new Errors.AnnotationsServiceError(error);
      }
    });
};

UsageService.prototype.saveListeningPositionsToRemote = function(posUsage) {
  return getAppId()
    .then(function(data) {
      var audiopositions = posUsage.audiopositions;
      var index = audiopositions.length - 1;
      data.audiopositions = [audiopositions[index]];
      
      /** Kidszone profileid changes **/
    if (posUsage.profileid && !posUsage.isPrimary) {
     data.audiopositions[0]['profileid'] = posUsage.profileid;
    }
    return savePositionUsageAjax(data);
    }).then(function(res) {});
};

export default UsageService;
