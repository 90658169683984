var SleepTimer = function(player, $slider, $display) {
  this.player = player;
  this.$slider = $slider;
  this.$display = $display;
};

var interval;
var stopTime;
var endDate;
var endOfChap;

//Public API
SleepTimer.prototype.setTimer = function(time) {
  var max = parseInt(this.$slider.prop('max'));
  var min = parseInt(this.$slider.prop('min'));
  var step = parseInt(this.$slider.prop('step'));

  if (time <= min) {
    //sleepTimer: OFF
    this.clearTimer();
  } else if (time >= max) {
    //sleepTimer: end of Chapter
    endOfChap = true;
  } else {
    if (time == max - step) {
      //sleepTimer: 1 hour
      time = 60;
    }
    time = time * 60 * 1000; //convert min to ms
    endOfChap = false;
    stopTime = new Date().getTime() + time;
    endDate = new Date(stopTime);

    var checkTime = this._checkTime.bind(this);
    interval = typeof interval === 'undefined' ? setInterval(checkTime, 1000) : interval;
  }
  this._updateDisplay();
};

SleepTimer.prototype.clearTimer = function() {
  clearInterval(interval);
  interval = undefined;
  this.$display.empty();
  this.$slider.prop('value', 0);
  endOfChap = false;
};

SleepTimer.prototype._updateDisplay = function() {
  this.$display.empty();

  if (this.$slider.prop('value') == 0) {
    this.$display.append('Off');
  } else if (endOfChap) {
    this.$display.append('Until end of chapter');
  } else {
    var hours = endDate.getHours();
    var ampm = 'AM';
    if (hours > 11 && hours != 24) ampm = 'PM';
    if (hours > 12) hours = hours - 12;
    var mins = endDate.getMinutes();
    if (mins.toString().length < 2) mins = '0' + mins;
    var time = hours + ':' + mins + ' ' + ampm;
    this.$display.append('Stops at ' + time);
  }
};

SleepTimer.prototype._checkTime = function() {
  var currTime = new Date().getTime();
  var diffTime = stopTime - currTime;
  if (diffTime <= 0) {
    this.player.pause();
    this.clearTimer();
    endOfChap = false;
    $('#btnPause span:first').attr('class', 'icon icon-audio-play');
    setTimeout(function() {
      $('#btnPause').attr('title', 'play');
    }, 200);
  }
};

SleepTimer.prototype.isEndOfChap = function() {
  return endOfChap;
};

export default SleepTimer;
