import $ from 'jquery';
import moduleConfig from './ModuleConfig';
import PackageParser from './PackageParser';
import WorkerProxy from './workers/WorkerProxy';
import StorageManager from './storage/StaticStorageManager';
import URI from 'urijs';
import EpubLibraryOPDS from './EpubLibraryOPDS';
import Managers from '@axisnow/data/Managers';
import FileSystem from '@axisnow/storage/filesystem';
import 'bluebird';

var _library;

var LibraryManager = function() {
  _library = Managers.library;
};

var adjustEpubLibraryPath = function(path) {
  if (!path || !moduleConfig.epubLibraryPath) return path;

  var pathUri;
  try {
    pathUri = new URI(path);
  } catch (err) {
    console.error(err);
    console.log(path);
  }

  if (pathUri && pathUri.is('absolute')) return path; // "http://", "https://", "data:", etc.

  if (path.indexOf('epub_content/') == 0) {
    path = path.replace('epub_content/', '');
  }

  var parts = moduleConfig.epubLibraryPath.split('/');
  parts.pop();

  var root = parts.join('/');
  path = root + (path.charAt(0) == '/' ? '' : '/') + path;

  return path;
};

function arrayBufferWrapperToBlob(input) {
  return new Blob([input.arrayBuffer], { type: input.type });
}

LibraryManager.prototype = {
  _getFullUrl: function(packageUrl, relativeUrl) {
    if (!relativeUrl) {
      return null;
    }

    var parts = packageUrl.split('/');
    parts.pop();

    var root = parts.join('/');

    return root + (relativeUrl.charAt(0) == '/' ? '' : '/') + relativeUrl;
  },

  // TODO: see disabled usage in EpubLibrary.js
  // resetLibraryData: function() {
  //     this.libraryData = undefined;
  // },

  retrieveAvailableEpubs: function(success) {
    //We always fetch the latest epub.
    /*if (this.libraryData){
                success(this.libraryData);
                return;
            }*/

    var self = this;

    var indexUrl = moduleConfig.epubLibraryPath
      ? StorageManager.getPathUrl(moduleConfig.epubLibraryPath)
      : StorageManager.getPathUrl('/epub_library.json');

    Managers.ConfirmStoreDataValidity();
    var dataFail = function() {
      console.error('Ebook library fail: ' + indexUrl);

      self.libraryData = [];
      self.libraryData = JSON.parse(window.localStorage.getItem('library-data')) || [];
      success(self.libraryData);
    };

    var dataSuccess = function(data) {
      console.log('Ebook library success: ' + indexUrl);

      if (moduleConfig.epubLibraryPath) {
        for (var i = 0; i < data.length; i++) {
          data[i].coverHref = adjustEpubLibraryPath(data[i].coverHref);
          data[i].rootUrl = adjustEpubLibraryPath(data[i].rootUrl);
        }
      }

      self.libraryData = data;
      window.localStorage.setItem('library-data', JSON.stringify(data));
      if (data[0] && data[0].bookvaultUUID) {
        window.localStorage.setItem('bookvaultUUID', data[0].bookvaultUUID);
      }
      success(data);
    };
    var url = new URI(indexUrl);
    if (url.protocol() === 'offline') {
      var libraryData = [];
      Managers.GetAllBookInfosForCurrentUser().then(function(bookInfos) {
        Promise.filter(bookInfos, function(bookInfo) {
          if (bookInfo.downloaded) {
            return Managers.license
              .fetchLicense(bookInfo.isbn)
              .then(function(licenseObj) {
                return licenseObj.license.isValidAndNotExpired();
              })
              .then(function() {
                return true;
              })
              .catch(function(error) {
                console.log(error);
                //Remove expired books from the library
                Managers.library.deleteBookInfo(bookInfo.isbn);
              });
          }
        })
          .map(function(bookInfo) {
            return Managers.bookData.getBookData(bookInfo.isbn).then(function(bookData) {
              var metadata = bookData.metadata;
              url.protocol('https').path('content/stream/' + bookInfo.isbn);
              var bookLibraryData = {
                author: metadata.author,
                coverHref: URL.createObjectURL(arrayBufferWrapperToBlob(bookData.cover)),
                isExternalLink: undefined,
                isSubLibraryLink: undefined,
                rootUrl: url.toString(),
                title: metadata.title,
                isbn: bookInfo.isbn,
                type: bookInfo.type,  //To identify CBZ type.
              };

              libraryData.push(bookLibraryData);

              return bookLibraryData;
            });
          })
          .then(function() {
            dataSuccess(libraryData);
          })
          .catch(function(error) {
            console.error(error);
            dataFail();
          });
      });
    } else {
      if (/\.json$/.test(indexUrl)) {
        $.ajax({
          url: indexUrl,
          dataType: 'json',
        })
          .done(function(data) {
            dataSuccess(data);
          })
          .fail(function() {
            dataFail();
          });
      } else {
        EpubLibraryOPDS.tryParse(indexUrl, dataSuccess, dataFail);
      }
    }
  },

  removeBookFromLibrary: function(isbn) {
    return Managers.library.deleteBookInfo(isbn).then(function() {
      var filesystem = new FileSystem();
      filesystem.removeRecursively(isbn);
    });
  },

  deleteEpubWithId: function(id, success, error) {
    WorkerProxy.deleteEpub(id, this.libraryData, {
      success: this._refreshLibraryFromWorker.bind(this, success),
      error: error,
    });
  },
  retrieveFullEpubDetails: function(
    packageUrl,
    rootUrl,
    rootDir,
    noCoverBackground,
    success,
    error,
  ) {
    var self = this;

    $.get(packageUrl, function(data) {
      if (typeof data === 'string') {
        var parser = new window.DOMParser();
        data = parser.parseFromString(data, 'text/xml');
      }
      var jsonObj = PackageParser.parsePackageDom(data, packageUrl);
      jsonObj.coverHref = jsonObj.coverHref
        ? self._getFullUrl(packageUrl, jsonObj.coverHref)
        : undefined;
      jsonObj.packageUrl = packageUrl;
      jsonObj.rootDir = rootDir;
      jsonObj.rootUrl = rootUrl;
      jsonObj.noCoverBackground = noCoverBackground;

      success(jsonObj);
    }).fail(error);
  },
  _refreshLibraryFromWorker: function(callback, newLibraryData) {
    this.libraryData = newLibraryData;
    callback();
  },
  handleZippedEpub: function(options) {
    WorkerProxy.importZip(options.file, this.libraryData, {
      progress: options.progress,
      overwrite: options.overwrite,
      success: this._refreshLibraryFromWorker.bind(this, options.success),
      error: options.error,
    });
    //Dialogs.showModalProgress()
    //unzipper.extractAll();
  },
  handleDirectoryImport: function(options) {
    var rawFiles = options.files,
      files = {};
    for (var i = 0; i < rawFiles.length; i++) {
      var path = rawFiles[i].webkitRelativePath;
      // don't capture paths that contain . at the beginning of a file or dir.
      // These are hidden files. I don't think chrome will ever reference
      // a file using double dot "/.." so this should be safe
      if (path.indexOf('/.') != -1) {
        continue;
      }
      var parts = path.split('/');

      parts.shift();
      var shiftPath = parts.join('/');

      files[shiftPath] = rawFiles[i];
    }

    WorkerProxy.importDirectory(files, this.libraryData, {
      progress: options.progress,
      overwrite: options.overwrite,
      success: this._refreshLibraryFromWorker.bind(this, options.success),
      error: options.error,
    });
  },
  handleUrlImport: function(options) {
    WorkerProxy.importUrl(options.url, this.libraryData, {
      progress: options.progress,
      overwrite: options.overwrite,
      success: this._refreshLibraryFromWorker.bind(this, options.success),
      error: options.error,
    });
  },
  handleMigration: function(options) {
    WorkerProxy.migrateOldBooks({
      progress: options.progress,
      success: this._refreshLibraryFromWorker.bind(this, options.success),
      error: options.error,
    });
  },
  handleUrl: function(options) {},
  canHandleUrl: function() {
    return moduleConfig.canHandleUrl;
  },
  canHandleDirectory: function() {
    return moduleConfig.canHandleDirectory;
  },
};

window.cleanEntireLibrary = function() {
  StorageManager.deleteFile(
    '/',
    function() {
      console.log('done');
    },
    console.error,
  );
};
export default new LibraryManager();
