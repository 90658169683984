import moduleConfig from './ModuleConfig';
import SettingsDialog from './templates/settings-dialog.hbs';
import KeyboardSettings from './ReaderSettingsDialog_Keyboard';
import Strings from './i18n/Strings';
import Dialogs from './Dialogs';
import Settings from './storage/Settings';
import Keyboard from './Keyboard';
import ReaderHelpers from './EpubReaderHelpers';
import $ from 'jquery';
import _ from 'underscore';

var defaultSettings = {
  fontSize: 100,
  syntheticSpread: 'auto',
  scroll: 'auto',
  columnGap: 60,
};

var setPreviewTheme = function($previewText, newTheme) {
  var previewTheme = $previewText.attr('data-theme');
  $previewText.removeClass(previewTheme);
  $previewText.addClass(newTheme);
  $previewText.attr('data-theme', newTheme);
};

var updateReader = function(reader, readerSettings, savedLocation) {
  // If the current view is fixed layout, simply apply the settings.
  // Otherwise (reflowable layout), hide the reader, apply the settings (triggering pagination), and show the reader,
  // for a better experience while the reader reflows.

  if (!reader.isCurrentViewFixedLayout()) {
    var bookmark = reader.getFirstVisibleCfi();
    if (bookmark) {
      $('#epub-reader-container').css('visibility', 'hidden');
      ReaderHelpers.invokeOncePaginationChanged(reader).then(function() {
        $('#epub-reader-container').css('visibility', '');
      });
    }
  }

  reader.updateSettings(readerSettings, savedLocation); // triggers on pagination changed

  if (readerSettings.theme == 'night-theme' && reader.isCurrentViewFixedLayout() && readerSettings.colorMode == 'dark') {
    $('html').attr('data-theme', 'author-theme');
    updateStylesForTheme('author-theme');
  }else if(readerSettings.theme){
    $('html').attr('data-theme', readerSettings.theme);
    updateStylesForTheme(readerSettings.theme);
  }
  // Nikita: Hiding the settings dialog because this is already the current behavior due to the re-paginating code in
  // updateReader. However this repaginating code isn't always called for fixed layout books, so we make the behavior
  // explicit.
  // $('#displayOptions').hide();
};

var updateStylesForTheme = function(theme){
  $('#reading-area').removeClass();
  switch(theme){
    case 'author-theme':
      $('#reading-area').addClass('authorTheme');
      updateStylesForIframe('authorTheme');
      break;
    case 'default-theme':
      $('#reading-area').addClass('defaultTheme');
      updateStylesForIframe('defaultTheme');
      break;
    case 'night-theme':
      $('#reading-area').addClass('nightTheme');
      updateStylesForIframe('nightTheme');
      break;
    case 'parchment-theme':
      $('#reading-area').addClass('parchmentTheme');
      updateStylesForIframe('parchmentTheme');
      break;
    case 'ballard-theme':
      $('#reading-area').addClass('ballardTheme');
      updateStylesForIframe('ballardTheme');
      break;
    case 'vancouver-theme':
      $('#reading-area').addClass('vancouverTheme');
      updateStylesForIframe('vancouverTheme');
      break;
  }
};
/**
 *
 * @param themeClass
 * Here we go through each iframe inside the reading-area, find the body element and extract the class if exists.
 * The below are the three scenarios considered to fix the issue.
 * 1) if any class do not exist -> Add the themeClass to the body
 * 2) If any class exist, but not theme class -> Add the themeClass also to the body
 * 3) If atleast one class exist, that includes old themeClass -> Find the old themeClass and replace it with updated themeClass.
 */
var updateStylesForIframe = function(themeClass){
  let iframes = $('#reading-area').find('iframe');
  _.each(iframes, function(iframe) {
    if (iframe.contentDocument) {
      let iContent = $(iframe).contents();
      if(iContent.find('body').attr('class') != null && iContent.find('body').attr('class').length > 0){
        if(iContent.find('body').attr('class').includes("Theme") == true){
          let classes = iContent.find('body').attr('class').split(/\s+/);
          if(classes != null && classes.length > 0){
            $.each(classes, function(i){
              if(classes[i].includes('Theme')){
                classes[i]= themeClass;
              }
            });
          }
          classes = classes.join(" ");
          iContent.find('body').removeClass();
          iContent.find('body').find('h1').removeClass();
          iContent.find('body').addClass(classes);
          iContent.find('body').find('h1').addClass(themeClass);
        }else{
          iContent.find('body').addClass(themeClass);
          iContent.find('body').find('h1').addClass(themeClass);
        }
      }else{
        iContent.find('body').addClass(themeClass);
        iContent.find('body').find('h1').addClass(themeClass);
      }
    }
  });
}

var readerModeSettings =function (reader, mode){
  Settings.get('reader', function(json) {
    if (!json) {
      json = {};
    }
    json.colorMode = mode;
    if(json.colorMode == 'dark'){
      json.theme ='night-theme';
    }else{
      json.theme ='default-theme';
    }
    Settings.put('reader', json);

    if (reader) updateReader(reader, json);
  });
}

var updateSliderLabels = function($slider, val, txt, label) {
  $slider.attr('aria-valuenow', val + '');
  $slider.attr('aria-value-now', val + '');

  $slider.attr('aria-valuetext', txt + '');
  $slider.attr('aria-value-text', txt + '');

  $slider.attr('title', label + ' ' + txt);
  $slider.attr('aria-label', label + ' ' + txt);
};

var initDialog = function(reader) {
  $('#app-container').append(
    SettingsDialog({
      imagePathPrefix: moduleConfig.imagePathPrefix,
      strings: Strings,
      dialogs: Dialogs,
      keyboard: Keyboard,
    }),
  );

  var $previewText = $('.preview-text');
  $('.theme-option').on('click', function() {
    var newTheme = $(this).attr('data-theme');
    setPreviewTheme($previewText, newTheme);
  });

  var $marginSlider = $('#margin-size-input');
  $marginSlider.on('change', function() {
    var val = $marginSlider.val();

    updateSliderLabels($marginSlider, val, val + 'px', Strings.i18n_margins);
  });

  var $fontSizeSlider = $('#font-size-input');
  $fontSizeSlider.on('change', function() {
    var fontSize = $fontSizeSlider.val();

    $previewText.css({ fontSize: fontSize / 100 + 'em' });

    updateSliderLabels($fontSizeSlider, fontSize, fontSize + '%', Strings.i18n_font_size);
  });

  $('#tab-butt-main').on('click', function() {
    $('#tab-keyboard').attr('aria-expanded', 'false');
    $('#tab-main').attr('aria-expanded', 'true');
  });
  $('#tab-butt-keys').on('click', function() {
    $('#tab-main').attr('aria-expanded', 'false');
    $('#tab-keyboard').attr('aria-expanded', 'true');
  });
  $('#buttSave').on('keydown', function(evt) {
    if (evt.which === 9 && !(evt.shiftKey | evt.ctrlKey | evt.metaKey | evt.altKey)) {
      // TAB pressed
      evt.preventDefault();
      $('#closeSettingsCross').focus();
    }
  });
  $('#closeSettingsCross').on('keydown', function(evt) {
    if (evt.which === 9 && evt.shiftKey) {
      // shift-TAB pressed
      evt.preventDefault();
      $('#buttSave').focus();
    }
  });

  $('#settings-dialog').on('hide.bs.modal', function() {
    // IMPORTANT: not "hidden.bs.modal"!! (because .off() in

    // Safety: "save" button click
    setTimeout(function() {
      $('#keyboard-list').empty();
    }, 500);
  });

  $('#settings-dialog').on('show.bs.modal', function() {
    // IMPORTANT: not "shown.bs.modal"!! (because .off() in library vs. reader context)

    $('#tab-butt-main').trigger('click');
    KeyboardSettings.initKeyboardList();

    setTimeout(function() {
      $('#closeSettingsCross')[0].focus();
    }, 1000); //tab-butt-main

    Settings.get('reader', function(readerSettings) {
      readerSettings = readerSettings || defaultSettings;
      for (var prop in defaultSettings) {
        if (
          defaultSettings.hasOwnProperty(prop) &&
          !readerSettings.hasOwnProperty(prop) &&
          !readerSettings[prop]
        ) {
          readerSettings[prop] = defaultSettings[prop];
        }
      }

      $fontSizeSlider.val(readerSettings.fontSize);
      updateSliderLabels(
        $fontSizeSlider,
        readerSettings.fontSize,
        readerSettings.fontSize + '%',
        Strings.i18n_font_size,
      );

      $marginSlider.val(readerSettings.columnGap);
      updateSliderLabels(
        $marginSlider,
        readerSettings.columnGap,
        readerSettings.columnGap + 'px',
        Strings.i18n_margins,
      );

      if (readerSettings.syntheticSpread == 'double') {
        $('#two-up-option input').prop('checked', true);
      } else if (readerSettings.syntheticSpread == 'single') {
        $('#one-up-option input').prop('checked', true);
      } else {
        $('#spread-default-option input').prop('checked', true);
      }

      if (readerSettings.scroll == 'scroll-doc') {
        $('#scroll-doc-option input').prop('checked', true);
      } else if (readerSettings.scroll == 'scroll-continuous') {
        $('#scroll-continuous-option input').prop('checked', true);
      } else {
        $('#scroll-default-option input').prop('checked', true);
      }

      if (readerSettings.pageTransition === 0) {
        $('#pageTransition-1-option input').prop('checked', true);
      } else if (readerSettings.pageTransition === 1) {
        $('#pageTransition-2-option input').prop('checked', true);
      } else if (readerSettings.pageTransition === 2) {
        $('#pageTransition-3-option input').prop('checked', true);
      } else if (readerSettings.pageTransition === 3) {
        $('#pageTransition-4-option input').prop('checked', true);
      } else {
        $('#pageTransition-none-option input').prop('checked', true);
      }

      if (readerSettings.theme) {
        setPreviewTheme($previewText, readerSettings.theme);
      }

      $previewText.css({ fontSize: readerSettings.fontSize / 100 + 'em' });
    });
  });

  var save = function() {
    var readerSettings = {
      fontSize: Number($fontSizeSlider.val()),
      syntheticSpread: 'auto',
      columnGap: Number($marginSlider.val()),
      scroll: 'auto',
    };

    if ($('#scroll-doc-option input').prop('checked')) {
      readerSettings.scroll = 'scroll-doc';
    } else if ($('#scroll-continuous-option input').prop('checked')) {
      readerSettings.scroll = 'scroll-continuous';
    }

    if ($('#two-up-option input').prop('checked')) {
      readerSettings.syntheticSpread = 'double';
    } else if ($('#one-up-option input').prop('checked')) {
      readerSettings.syntheticSpread = 'single';
    }

    if ($('#pageTransition-1-option input').prop('checked')) {
      readerSettings.pageTransition = 0;
    } else if ($('#pageTransition-2-option input').prop('checked')) {
      readerSettings.pageTransition = 1;
    } else if ($('#pageTransition-3-option input').prop('checked')) {
      readerSettings.pageTransition = 2;
    } else if ($('#pageTransition-4-option input').prop('checked')) {
      readerSettings.pageTransition = 3;
    } else {
      readerSettings.pageTransition = -1;
    }

    readerSettings.theme = $previewText.attr('data-theme');
    if (reader) {
      updateReader(reader, readerSettings);
    }

    var keys = KeyboardSettings.saveKeys();

    Settings.get('reader', function(json) {
      if (!json) {
        json = {};
      }

      for (var prop in readerSettings) {
        if (readerSettings.hasOwnProperty(prop)) {
          json[prop] = readerSettings[prop];
        }
      }

      json.keyboard = keys;
      // if (keys)
      // {
      //     for (prop in keys)
      //     {
      //         if (keys.hasOwnProperty(prop))
      //         {
      //             json.keyboard[prop] = keys[prop];
      //         }
      //     }
      // }

      Settings.put('reader', json);

      setTimeout(function() {
        Keyboard.applySettings(json);
      }, 100);
    });
  };

  Keyboard.on(Keyboard.NightTheme, 'settings', function() {
    Settings.get('reader', function(json) {
      if (!json) {
        json = {};
      }

      var isNight = json.theme === 'night-theme';
      json.theme = isNight ? 'author-theme' : 'night-theme';

      Settings.put('reader', json);

      if (reader) updateReader(reader, json);
    });
  });

  Keyboard.on(Keyboard.SettingsModalSave, 'settings', function() {
    save();
    $('#settings-dialog').modal('hide');
  });

  Keyboard.on(Keyboard.SettingsModalClose, 'settings', function() {
    $('#settings-dialog').modal('hide');
  });

  $('#settings-dialog .btn-primary').on('click', save);
};

export default {
  initDialog: initDialog,
  updateReader: updateReader,
  defaultSettings: defaultSettings,
  updateStylesForTheme: updateStylesForTheme,
  readerModeSettings: readerModeSettings
};
