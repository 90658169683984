import $ from 'jquery';
import moduleConfig from './ModuleConfig';
import URI from 'urijs';

function processFont(fontCssUrl) {
  return new Promise(function (resolve, reject) {
    $.get(fontCssUrl, function(cssContent){
      var cssUrlRegexp = /[Uu][Rr][Ll]\(\s*([']([^']+)[']|["]([^"]+)["]|([^)]+))\s*\)/g;

      var fontCSS = new URI(moduleConfig.fonts);
      var fontFolder = fontCSS.directory();

      var fontFileURLs = {};
      var fontFilesLoaded = [];

      cssContent.replace(cssUrlRegexp, function(match, p1, p2) {
        var filePath = new URI(p2);
        if (!filePath.directory()) {
          filePath.directory(fontFolder);
        }

        var prom = new Promise(function (resolve, reject) {
          var xhr = new XMLHttpRequest();
          xhr.timeout = $.ajaxSettings.timeout;
          xhr.open('GET', filePath, true);
          xhr.responseType = 'arraybuffer';
          xhr.addEventListener("error", reject);
          xhr.addEventListener("timeout", reject);

          xhr.onload = function() {
            let blob = new Blob([xhr.response], {type: 'font/woff'});
            fontFileURLs[p2] = window.URL.createObjectURL(blob);
            resolve();
          };

          xhr.send();
        });

        fontFilesLoaded.push(prom);

        // can't replace on first run
        // replace after all blobs have been created
        return match;
      });

      Promise.all(fontFilesLoaded).then(function () {
        var blobURLContent = cssContent.replace(cssUrlRegexp, function(match, p1, p2) {
          if (fontFileURLs[p2]) {
            return match.replace(p2, fontFileURLs[p2]);
          }
        });

        let blob = new Blob([blobURLContent], {type: 'text/css'});
        resolve(window.URL.createObjectURL(blob));
      }).catch(function () {
        reject();
      });
    });
  });
}

export default processFont
