import $ from 'jquery';
import _ from 'underscore';
import Helpers from './helpers';

function Accessibility(api) {
  var _reader = api.reader;

  this.initialize = function() {
    var setFocusOnIframe = true;

    $(document).on('POPUP_VISIBILITY_CHANGED', function(e, value) {
      setFocusOnIframe = !value;
    });

    var canFocusScroll = Helpers.supportsFocusScrollingProperly();

    _reader.on(
      ReadiumSDK.Events.CONTENT_DOCUMENT_LOAD_START,
      _.bind(function($iframe, spineItem) {
        $iframe.attr('aria-busy', 'true');
      }, this),
    );

    _reader.on(
      ReadiumSDK.Events.CONTENT_DOCUMENT_LOADED,
      _.bind(function($iframe, spineItem) {
        if (setFocusOnIframe) {
          $iframe.focus();
        }
        var sheet = Helpers.createStyleSheet($iframe[0].contentDocument, 'accessibility');
        _reader.once(ReadiumSDK.Events.PAGINATION_CHANGED, function() {
          sheet.insertRule('[data-rd-accessibility-focus-temp]:focus {outline: none;}', 0);
          $iframe.attr('aria-busy', 'false');
        });
      }, this),
    );

    _reader.on(ReadiumSDK.Events.PAGINATION_CHANGED, function(pageRequest) {
      if (
        !canFocusScroll ||
        _reader.getCurrentViewType() !== _reader.constructor.VIEW_TYPE_COLUMNIZED
      ) {
        //not a reflowableView, or it can't do element focus like we want to. exit
        return;
      }

      if (!setFocusOnIframe) {
        return;
      }

      var reflowableView = _reader.getCurrentView();

      if (pageRequest.initiator && pageRequest.initiator === reflowableView) {
        //skip requests when internally scrolling
        return;
      }

      var $focusedElement;

      if (pageRequest.elementId) {
        $focusedElement = reflowableView._navigationLogic.getElementById(pageRequest.elementId);
      } else if (pageRequest.elementCfi) {
        try {
          $focusedElement = reflowableView._navigationLogic.getElementByCfi(
            pageRequest.elementCfi,
            ['cfi-marker', 'mo-cfi-highlight'],
            [],
            ['MathJax_Message', 'MathJax_SVG_Hidden'],
          );
        } catch (e) {
          console.error(e);
        }
      } else if (pageRequest.firstPage) {
        $focusedElement = $(
          'body > :first-child',
          reflowableView._navigationLogic.getRootElement(),
        );
      } else if (pageRequest.lastPage) {
        $focusedElement = $('body > :last-child', reflowableView._navigationLogic.getRootElement());
      }

      if (!$focusedElement || !$focusedElement.length) {
        var firstVisibleCfi = _reader.getFirstVisibleCfi();
        if (firstVisibleCfi && firstVisibleCfi.contentCFI) {
          var range = _reader.getDomRangeFromRangeCfi(firstVisibleCfi);
          var startContainer = range.startContainer;
          if (startContainer.nodeType === Node.TEXT_NODE && range.startOffset === 0) {
            startContainer = $(startContainer).parent();
          } else if (
            startContainer.nodeType === Node.TEXT_NODE &&
            reflowableView._navigationLogic
          ) {
            var visibleLeafNode = reflowableView._navigationLogic.getVisibleLeafNodes();
            if ($(visibleLeafNode).length > 0) {
              startContainer = $(visibleLeafNode.element);
            }
          }
          $focusedElement = $(startContainer);
        }
      }

      if ($focusedElement && $focusedElement.length) {
        Helpers.focusOnElement($focusedElement);
      }
    });
  };
}

export default Accessibility;
