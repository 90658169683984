export class Metadata {
  constructor(data) {
    this.author = data.author;
    this.description = data.description;
    this.epub_version = data.epub_version;
    this.id = data.id;
    this.language = data.language;
    this.modified_date = data.modified_date;
    this.ncx = data.ncx;
    this.pubdate = data.pubdate;
    this.publisher = data.publisher;
    this.rights = data.rights;
    this.title = data.title;
    this.rendition_orientation = data.rendition_orientation;
    this.rendition_layout = data.rendition_layout;
    this.rendition_spread = data.rendition_spread;
    this.rendition_flow = data.rendition_flow;
    this.rendition_viewport = data.rendition_viewport;
    this.rendition_viewports = data.rendition_viewports;
    this.mediaItems = data.mediaItems;
    this.media_overlay = data.media_overlay;
    this.page_prog_dir = data.page_prog_dir;

    // Initialize indexes
    this._mediaItemIndexByRefinesId = {};
    if (this.mediaItems) {
      this.mediaItems.forEach((item) => {
        var id = item.refines;
        var hash = id.indexOf('#');
        if (hash >= 0) {
          var start = hash + 1;
          var end = id.length - 1;
          id = id.substr(start, end);
        }
        id = id.trim();

        this._mediaItemIndexByRefinesId[id] = item;
      });
    }
  }

  getMediaItemByRefinesId(id) {
    return this._mediaItemIndexByRefinesId[id];
  }

  setMoMap(mediaOverlaysMap) {
    this.media_overlay.smil_models = mediaOverlaysMap;
  }

  serialize() {
    const out = {
      '@type': 'http://schema.org/Book',
      title: this.title,
      identifier: this.id,
      author: this.author,
      description: this.description,
      publisher: this.publisher,
      published: this.pubdate,
      modified: this.modified_date,
      language: this.language,
      readingProgession: this.page_prog_dir || 'auto',
      rendition: {
        overflow: this.rendition_flow,
        layout: this.rendition_layout === 'pre-paginated' ? 'fixed' : this.rendition_layout,
        orientation: this.rendition_orientation,
        spread: this.rendition_spread,
      },
    };

    // form a valid identifier
    try {
      out.identifier = new URL(out.identifier).toString();
    } catch (ignored) {
      const isbnRegex = /^((?:[\dX]{13})|(?:[\d\-X]{17})|(?:[\dX]{10})|(?:[\d\-X]{13}))$/;
      const uuidRegex = /^\w{8}-\w{4}-\w{4}-\w{4}-\w{12}$/;
      if (out.identifier.match(isbnRegex)) {
        out.identifier = 'urn:isbn:' + out.identifier;
      } else if (out.identifier.match(uuidRegex)) {
        out.identifier = 'urn:uuid:' + out.identifier;
      } else {
        out.identifier = 'urn:X-unknown:' + out.identifier;
      }
    }

    // try to form valid dates
    try {
      out.published = new Date(out.published).toISOString();
    } catch (ignored) {
      out.published = undefined;
    }
    try {
      out.modified = new Date(out.modified).toISOString();
    } catch (ignored) {
      out.modified = undefined;
    }

    // clean up empty string rendition entries
    Object.keys(out.rendition).map((key) => {
      const value = out.rendition[key];
      out.rendition[key] = value && value.length ? value : undefined;
    });

    return out;
  }
}
