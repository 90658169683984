/**
 * Speech module using WebSpeech
 */
import _ from 'underscore';
import Promise from 'bluebird';
var self = {};
var isAndroid = navigator.userAgent.match(/(Android)/g) ? true : false;
var isIOS = navigator.userAgent.match(/(iPad|iPhone|iPod)/g) ? true : false;
var isMac = navigator.userAgent.match(/(Macintosh)/g) ? true : false;
var isSafari = isIOS || isMac ? true : false;
var currentVoice = undefined;
var currentRate = undefined;
var currentPitch = undefined;
var currentVolume = undefined;

var onWordBoundaryCallback = undefined;
var onErrorCallback = undefined;
var onEndCallback = undefined;
var onStartCallback = undefined;
var onResumeCallback = _.noop;

var availableVoices = [];

var voicesInited = false;
var initResolve;
var initReject;
var initPromise = new Promise(function(resolve, reject) {
  initResolve = resolve;
  initReject = reject;
});

self.STATE_STOPPED = 0;
self.STATE_PLAYING = 1;
self.STATE_PAUSED = 2;
self.state = self.STATE_STOPPED;
self.pausedByRefresh = false;

/* -------------------------------------------------------------------- */

self.isSupported = function() {
  return window.speechSynthesis !== undefined;
};

self.isAndroidMode = function() {
  return isAndroid;
};

self.isIOSMode = function() {
  return isIOS;
};

self.isSafariMode = function() {
  return isSafari;
};

self.isMacMode = function() {
  return isMac;
};

self.waitToInitialize = function() {
  return initPromise.catch(function() {
    // ignore errors
  });
};

self.updateUtterance = function(utterance) {
  if (utterance) {
    if (currentVoice) {
      if (!self.isAndroidMode()) {
        utterance.voice = currentVoice;
      }
      if (currentVoice['lang']) {
        utterance.lang = currentVoice.lang; // Android Chrome keys in on language, not voice
      }
      if (currentVoice['voiceURI']) {
        utterance.voiceURI = currentVoice.voiceURI;
      }
    }
    if (currentRate) {
      utterance.rate = currentRate;
    }
    if (currentPitch) {
      utterance.pitch = currentPitch;
    }
    if (currentVolume) {
      utterance.volume = currentVolume;
    }
  }
};

/**
 * Inputs:
 *  text - Text that will be spoken
 *  metadata - Data that will be passed back to callback functions
 */
self.createUtterance = function(text, metadata) {
  var utterance = new SpeechSynthesisUtterance(text);

  self.updateUtterance(utterance);

  if (onWordBoundaryCallback) {
    utterance.onboundary = function(ev) {
      if (ev.name && ev.name.toLowerCase() === 'word') {
        onWordBoundaryCallback(ev, metadata);
      }
    };
  }
  if (onErrorCallback) {
    utterance.onerror = function(ev) {
      onErrorCallback(ev, metadata);
    };
  }
  if (onEndCallback) {
    utterance.onend = function(ev) {
      onEndCallback(ev, metadata);
    };
  }

  // always handle 'onstart' so we can have a chance to deal with Firefox pause-near-utterance-end issue
  utterance.onstart = function(ev) {
    // Firefox (and maybe other browsers also) ignores a Pause if fired within a few words of the end of an utterance.
    // The following "if" refires Pause when following utterance starts (if any), if we are supposed to be in a Paused mode.
    // It may result in a few more words being spoken, but it does seem to pause.
    // If the Pause was properly handled the first time (i.e. in Chrome), the 'onstart' would not be fired while in paused mode,
    // so this fix is safe for browsers that work properly.
    if (self.state === self.STATE_PAUSED && !self.isAndroidMode()) {
      window.speechSynthesis.pause();
    }
    if (onStartCallback) {
      onStartCallback(ev, metadata);
    }
  };

  return utterance;
};

// These functions are asynchronous (return promises)

self.speak = function(utterance) {
  self.pausedByRefresh = false;
  return self.waitToInitialize().then(function() {
    if (self.isPaused()) {
      self.resume();
    }
    window.speechSynthesis.speak(utterance);
    self.state = self.STATE_PLAYING;
  });
};

self.getVoices = function() {
  return self.waitToInitialize().then(function() {
    return availableVoices || [];
  });
};

self.pause = function() {
  self.pausedByRefresh = false;
  return self.waitToInitialize().then(function() {
    if (!self.isAndroidMode()) {
      window.speechSynthesis.pause();
      self.state = self.STATE_PAUSED;
    }
  });
};

self.resume = function() {
  self.pausedByRefresh = false;
  return self.waitToInitialize().then(function() {
    if (self.state == self.STATE_PAUSED) {
      window.speechSynthesis.resume();
      self.state = self.STATE_PLAYING;
      onResumeCallback();
    }
  });
};

self.stop = function() {
  self.pausedByRefresh = false;
  return self.waitToInitialize().then(function() {
    window.speechSynthesis.cancel();
    self.state = self.STATE_STOPPED;
  });
};

self.refresh = function() {
  if (!self.isSafariMode() && self.isSpeaking() && !self.isPaused()) {
    self.pause(); // calling this periodically allows Google voices to keep playing past 19 second undocumented limit
    self.pausedByRefresh = true;
    setTimeout(function() {
      if (self.pausedByRefresh) {
        // Resume only if we were really paused by refresh timer, not by user
        self.resume();
      }
    }, 10); // of course, if we paused it, we must also resume it
  }
};

// These functions are synchronous

self.isPaused = function() {
  return self.state === self.STATE_PAUSED;
};

self.isSpeaking = function() {
  return self.state === self.STATE_PLAYING || self.isPaused();
};

self.setVoiceByName = function(voiceName) {
  var result = self.getVoiceByName(voiceName);
  if (result) {
    currentVoice = result;
  }
  return result;
};

self.getVoiceByName = function(voiceName) {
  var result;
  if (availableVoices && availableVoices.length > 0) {
    result = _.find(availableVoices, { name: voiceName }) || availableVoices[0];
  }
  return result;
};

self.getDefaultVoice = function() {
  return availableVoices && availableVoices.length > 0 && availableVoices[0];
};

self.setRate = function(rate) {
  var DEFAULT_SPEEDS = [-1, 0.6, 0, 1, 1, 1.5, 2, 2.0];
  var MS_SPEEDS = [-1, 0.4, 0, 0.85, 1, 2.0, 2, 3.2];
  var GOOGLE_SPEEDS = [-1, 0.64, 0, 0.95, 1, 1.2, 2, 1.4];
  var MAC_SPEEDS = [-1, 0.7, 0, 1.0, 1, 1.4, 2, 1.9];
  var SAFARI_SPEEDS = [-1, 0.7, 0, 1.0, 1, 1.15, 2, 1.29];
  var ANDROID_SPEEDS = [-1, 0.6, 0, 1.0, 1, 2.1, 2, 2.8];
  // Normalize speeds between voice types, as of this writing, I only had Microsoft, Google and Safari voices to test (HS 6/19/2018)
  var voiceName = (currentVoice && currentVoice.name && currentVoice.name.toLowerCase()) || '';
  var speedArray = DEFAULT_SPEEDS;
  // Microsoft baseline: .85, slowest .45
  if (voiceName.indexOf('microsoft') >= 0) {
    speedArray = MS_SPEEDS;
  } else if (voiceName.indexOf('google') >= 0) {
    speedArray = GOOGLE_SPEEDS;
  } else if (self.isMacMode()) {
    speedArray = MAC_SPEEDS;
  } else if (self.isSafariMode()) {
    speedArray = SAFARI_SPEEDS;
  } else if (self.isAndroidMode()) {
    speedArray = ANDROID_SPEEDS;
  }
  // find nearest matching speed
  var lastDelta = 10000000000;
  for (var i = 0; i < speedArray.length; i += 2) {
    var newDelta = Math.abs(speedArray[i] - rate);
    if (newDelta < lastDelta) {
      currentRate = speedArray[i + 1];
      lastDelta = newDelta;
    }
  }
};

self.setPitch = function(pitch) {
  currentPitch = pitch;
};

self.setVolume = function(volume) {
  currentVolume = volume;
};

self.onWordBoundary = function(callback) {
  onWordBoundaryCallback = callback;
};

self.onStart = function(callback) {
  onStartCallback = callback;
};

self.onResume = function(callback) {
  onResumeCallback = callback;
};

self.onEnd = function(callback) {
  onEndCallback = callback;
};

self.onError = function(callback) {
  onErrorCallback = callback;
};

if (self.isSupported()) {
  var onVoicesTimer;
  var OnVoicesChanged = function() {
    clearTimeout(onVoicesTimer); // clear timeout timer if we process the event
    var allVoices = window.speechSynthesis.getVoices();
    if (!voicesInited && allVoices.length > 0) {
      self.state = self.STATE_STOPPED;
      voicesInited = true;
      var langMap = {};
      var englishAndSpanishVoices = [];
      allVoices.forEach(function(elem) {
        var addit = false;
        // android Chrome languages use underscores not dashes
        var lang = (elem && elem.lang && elem.lang.toLowerCase().replace('_', '-')) || null;
        var name = (elem && elem.name && elem.name.toLowerCase()) || '';
        if (lang) {
          addit = lang.indexOf('en-') === 0 || lang.indexOf('es-') === 0;
          if (addit) {
            if (self.isAndroidMode()) {
              elem = _.clone(elem); // Android voices will need modification, so we clone
              elem.localService = false;
              if (lang.indexOf('en-') === 0) {
                lang = 'en-'; // we only save the first english variant for Android (all voices of a lang are identical sounding)
                name = elem.name = 'English';
              } else if (lang.indexOf('es-') === 0) {
                lang = 'es-'; // we only save the first spanish variant for Android (all voices of a lang are identical sounding)
                name = elem.name = 'Spanish';
              }
            }
            if (name.indexOf('google') !== 0 && name.indexOf('microsoft') !== 0) {
              // google and microsoft voices are all useable
              // but others are identical unless language type is different (e.g. all en-US use same voice, but en-GB is different)
              if (!langMap[lang]) {
                langMap[lang] = true;
              } else {
                addit = false;
              }
            }
          }
        }
        if (addit) {
          englishAndSpanishVoices.push(elem);
        }
      });
      englishAndSpanishVoices = _.sortBy(englishAndSpanishVoices, function(elem) {
        return [!elem.localService, elem.lang, elem.name]; // order 'highlight capable' on top, subsort language and name
      });
      if (englishAndSpanishVoices.length > 0) {
        availableVoices = englishAndSpanishVoices;
        if (availableVoices && availableVoices.length > 0) {
          initResolve();
        } else {
          initReject('No local voices available.');
        }
      } else {
        initReject('No english language voices available.');
      }
    }
  };
  if (window.speechSynthesis.getVoices().length > 0) {
    OnVoicesChanged(); // Firefox (and maybe Safari ???) doesn't fire the event, just has voices on start
  } else {
    onVoicesTimer = setTimeout(function() {
      OnVoicesChanged();
    }, 3000); // if we don't get the event, fire function anyway
    window.speechSynthesis.onvoiceschanged = OnVoicesChanged;
  }
} else {
  initReject('Webspeech is not supported in this browser.');
}

export default self;
