import UUID from 'node-uuid';
var _objectStores_Temp = {};
var _objectStores = {};
var _STORE_NAMES;
var _STORE_NAMES_TEMP;
/**
 * Migrate and Temp Collection List used in MigrateData Function
 */
var tempcollectionList = ['LibraryDataProfileStore', 'PageUsageProfileStore', 'PositionsUsageProfileStore', 'ComicBookPageUsageProfileStore','ComicBookAnnotationsUsageProfileStore','EbookAnnotationsUsageStoreProfile','AudioAnnotationsUsageStore'] 
var migrateDatacollectionList = ['LibraryDataStore', 'PageUsageStore', 'PositionsUsageStore', 'ComicBookPageUsageStore','ComicBookAnnotationsUsageStore','EbookAnnotationsUsageStore','AudioAnnotationsUsageStore'] 
var MigrationManager13To14 = function(db, transaction) {
  this.db = db;
  this.transaction = transaction;

  /**
   * Temp  Collection List used in CreateTables Function
   */

const  _STORE_NAMES_TEMP = (this.STORE_NAMES_TEMP = {
    ProfileStore:'ProfileStore',
    LibraryDataProfileStore: 'LibraryDataProfileStore',
	  PageUsageProfileStore: 'PageUsageProfileStore',
    PositionsUsageProfileStore: 'PositionsUsageProfileStore',
    ComicBookPageProfileUsageStore: 'ComicBookPageUsageProfileStore',
    ComicBookAnnotationsProfileUsageStore: 'ComicBookAnnotationsUsageProfileStore',
    EbookAnnotationsUsageProfile: 'EbookAnnotationsUsageStoreProfile',
    AudioAnnotationsProfileUsageStore: 'AudioAnnotationsUsageStore',
  });
    /**
   *  Migrate Collection List in CreateTables Function
   */
  const _STORE_NAMES = (this.STORE_NAMES = {
    ProfileStore:'ProfileStore',
    LibraryDataStore: 'LibraryDataStore',
    PageUsage: 'PageUsageStore',
    PositionsUsage: 'PositionsUsageStore',
    ComicBookPageUsage: 'ComicBookPageUsageStore',
    ComicBookAnnotationsUsage: 'ComicBookAnnotationsUsageStore',
    EbookAnnotationsUsage: 'EbookAnnotationsUsageStore',
    AudioAnnotationsUsage: 'AudioAnnotationsUsageStore',
  });
 
  _objectStores[_STORE_NAMES.LibraryDataStore] = {
    name: _STORE_NAMES.LibraryDataStore,
    value: { keyPath: 'uuid_profile_isbn' },
    indexes: [
      {
        name: 'uuid',
        keyPath: 'uuid',
        optionalParameters: { unique: false },
      },
      {
        name: 'isbn',
        keyPath: 'isbn',
        optionalParameters: { unique: false },
      },
      {
        name: 'profileid',
        keyPath: 'profileid',
        optionalParameters: { unique:false },
      },
    ],
  };

   _objectStores[_STORE_NAMES.PageUsage] = {
    name: _STORE_NAMES.PageUsage,
    value: { keyPath: 'uuid_profile_isbn' },
    indexes: [
      {
        name: 'uuid',
        keyPath: 'uuid',
        optionalParameters: { unique: false },
      },
      {
        name: 'isbn',
        keyPath: 'isbn',
        optionalParameters: { unique: false },
      },
      {
        name: 'profileid',
        keyPath: 'profileid',
        optionalParameters: { unique:false },
      },
    ],
  };

  _objectStores[_STORE_NAMES.PositionsUsage] = {
    name: _STORE_NAMES.PositionsUsage,
    value: { keyPath: 'uuid_profile_id' },
    indexes: [
      {
        name: 'uuid',
        keyPath: 'uuid',
        optionalParameters: { unique: false },
      },
      {
        name: 'id',
        keyPath: 'id',
        optionalParameters: { unique: false },
      },
      {
        name: 'profileid',
        keyPath: 'profileid',
        optionalParameters: { unique:false },
      },
    ],
  };

   _objectStores[_STORE_NAMES.ComicBookPageUsage] = {
    name: _STORE_NAMES.ComicBookPageUsage,
    value: { keyPath: 'uuid_profile_isbn' },
    indexes: [
      {
        name: 'uuid',
        keyPath: 'uuid',
        optionalParameters: { unique: false },
      },
      {
        name: 'isbn',
        keyPath: 'isbn',
        optionalParameters: { unique: false },
      },
      {
        name: 'profileid',
        keyPath: 'profileid',
        optionalParameters: { unique:false },
      },
    ],
  };

  _objectStores[_STORE_NAMES.ComicBookAnnotationsUsage] = {
    name: _STORE_NAMES.ComicBookAnnotationsUsage,
    value: { keyPath: 'uuid_profile_isbn' },
    indexes: [
      {
        name: 'uuid',
        keyPath: 'uuid',
        optionalParameters: { unique: false },
      },
      {
        name: 'isbn',
        keyPath: 'isbn',
        optionalParameters: { unique: false },
      },
      {
        name: 'profileid',
        keyPath: 'profileid',
        optionalParameters: { unique:false },
      },
    ],
  };
   
   _objectStores[_STORE_NAMES.EbookAnnotationsUsage] = {
    name: _STORE_NAMES.EbookAnnotationsUsage,
    value: { keyPath: 'uuid_profile_isbn' },
    indexes: [
      {
        name: 'uuid',
        keyPath: 'uuid',
        optionalParameters: { unique: false },
      },
      {
        name: 'isbn',
        keyPath: 'isbn',
        optionalParameters: { unique: false },
      },
      {
        name: 'profileid',
        keyPath: 'profileid',
        optionalParameters: { unique:false },
      },
    ],
  };

  _objectStores[_STORE_NAMES.AudioAnnotationsUsage] = {
    name: _STORE_NAMES.AudioAnnotationsUsage,
    value: { keyPath: 'uuid_profile_id' },
    indexes: [
      {
        name: 'uuid',
        keyPath: 'uuid',
        optionalParameters: { unique: false },
      },
      {
        name: 'id',
        keyPath: 'id',
        optionalParameters: { unique: false },
      },
      {
        name: 'profileid',
        keyPath: 'profileid',
        optionalParameters: { unique:false },
      },
    ],
  };


  _objectStores_Temp[_STORE_NAMES_TEMP.ProfileStore] = {
    name: _STORE_NAMES_TEMP.ProfileStore,
    value: { keyPath: 'profileid' },
  };
 
 _objectStores_Temp[_STORE_NAMES_TEMP.LibraryDataProfileStore] = {
    name: _STORE_NAMES_TEMP.LibraryDataProfileStore,
    value: { keyPath: 'uuid_profile_isbn' },
  };

  _objectStores_Temp[_STORE_NAMES_TEMP.PageUsageProfileStore] = {
    name: _STORE_NAMES_TEMP.PageUsageProfileStore,
    value: { keyPath: 'uuid_profile_isbn' },
  };

   _objectStores_Temp[_STORE_NAMES_TEMP.PositionsUsageProfileStore] = {
    name: _STORE_NAMES_TEMP.PositionsUsageProfileStore,
    value: { keyPath: 'uuid_profile_id' },
  }

   _objectStores_Temp[_STORE_NAMES_TEMP.ComicBookPageProfileUsageStore] = {
    name: _STORE_NAMES_TEMP.ComicBookPageProfileUsageStore,
    value: { keyPath: 'uuid_profile_isbn' },
  };

  _objectStores_Temp[_STORE_NAMES_TEMP.ComicBookAnnotationsProfileUsageStore] = {
    name: _STORE_NAMES_TEMP.ComicBookAnnotationsProfileUsageStore,
    value: { keyPath: 'uuid_profile_id' },
  };

   _objectStores_Temp[_STORE_NAMES_TEMP.EbookAnnotationsUsageProfile] = {
    name: _STORE_NAMES_TEMP.EbookAnnotationsUsageProfile,
    value: { keyPath: 'uuid_profile_isbn' },
  };

  _objectStores_Temp[_STORE_NAMES_TEMP.AudioAnnotationsProfileUsageStore] = {
    name: _STORE_NAMES_TEMP.AudioAnnotationsProfileUsageStore,
    value: { keyPath: 'uuid_profile_id' },
  };
  
  
};

var applyMeta = function(document) {
  var meta = {};
  var timeNow = Date.now();

  meta.createTime = timeNow;
  meta.deleted = false;
  meta.updateTime = timeNow;
  meta.revision = UUID.v4();

  document._meta = meta;

  return document;
};

/**
 *  it used to create a bunch of Temp table names
 * @param {*} objectStores 
 * @returns 
 */
MigrationManager13To14.prototype.createNewTables = async function(objectStores) {
  try {
    return await new Promise((resolve, reject) => {
      var self = this;

      for (var key in objectStores) {
        if (!objectStores.hasOwnProperty(key))
          continue;

        var obj = objectStores[key];
        var objStore;
        if (self.db.objectStoreNames.contains(key)) {
          self.updateIndex(obj, key);
          continue;
        }
        objStore = self.db.createObjectStore(key, obj.value);
        if (typeof obj.indexes !== 'undefined') {
          var indexNames = objStore.indexNames;
          /* jshint ignore:start */
          // Ignoring JSHint error : Functions declared within loops referencing an outer scoped variable may lead to confusing semantics.
          obj.indexes.forEach(function (element, index, array) {
            if (indexNames.contains(element.name)) {
              var myIndex = objStore.index(element.name);
              var currentKeyPath = myIndex.keyPath;
              var updateIndex = currentKeyPath != element.keyPath;

              if (element.optionalParameters) {
                updateIndex =
                  element.optionalParameters.unique != element.optionalParameters.unique || updateIndex;
              }
              if (updateIndex) {
                objStore.deleteIndex(element.name);
                objStore.createIndex(element.name, element.keyPath, element.optionalParameters);
              }
            } else {
              objStore.createIndex(element.name, element.keyPath, element.optionalParameters);
            }
          });
        }
      }
      resolve();
    });
  } catch (err) {
    console.log('err', err.message);
  }
};

MigrationManager13To14.prototype.deleteTable = function(objectStoreName) {
  return new Promise((resolve, reject) => {
    var self = this;
    if (self.db.objectStoreNames.contains([objectStoreName])) {
      self.db.deleteObjectStore([objectStoreName]);
    }
    resolve();
  })
};

/**
 * Once Done all the data migration successfully we removed the all temp tables
 * @param {*} index 
 * @returns 
 */
MigrationManager13To14.prototype.removedAllTempTables = function(index) {
  try {

    if (tempcollectionList.length -1 ==  index) {
       return true
    }
    var self = this;  
        if (self.db.objectStoreNames.contains([tempcollectionList[index]])) {
          self.db.deleteObjectStore([tempcollectionList[index]])
          this.removedAllTempTables((index + 1))
      }
     } catch (error) {
       console.log('remove-all-collection-catch',error.message) 
  } 
}

/**
 * it used to create a specified table name
 * @param {*} tableName 
 * @returns 
 */

MigrationManager13To14.prototype.createNewTable = async function(tableName) {
  
  try {
    return await new Promise((resolve, reject) => {
      var self = this;

      for (var key in _objectStores) {
        if (!_objectStores.hasOwnProperty(key))
          continue;

        var obj = _objectStores[key];
        var objStore;
        if (self.db.objectStoreNames.contains(key)) {
          self.updateIndex(obj, key);
          continue;
        }
         if (key == tableName) {
            objStore = self.db.createObjectStore(key, obj.value);
            if (typeof obj.indexes !== 'undefined') {
              var indexNames = objStore.indexNames;
              /* jshint ignore:start */
              // Ignoring JSHint error : Functions declared within loops referencing an outer scoped variable may lead to confusing semantics.
              obj.indexes.forEach(function (element, index, array) {
                if (indexNames.contains(element.name)) {
                  var myIndex = objStore.index(element.name);
                  var currentKeyPath = myIndex.keyPath;
                  var updateIndex = currentKeyPath != element.keyPath;

                  if (element.optionalParameters) {
                    updateIndex =
                      element.optionalParameters.unique != element.optionalParameters.unique || updateIndex;
                  }
                  if (updateIndex) {
                    objStore.deleteIndex(element.name);
                    objStore.createIndex(element.name, element.keyPath, element.optionalParameters);
                  }
                } else {
                  objStore.createIndex(element.name, element.keyPath, element.optionalParameters);
                }
              });
            }
         }
      }
      resolve();
    });
  } catch (err) {
    console.log('create-singleTable', err.message);
  }

};

MigrationManager13To14.prototype.updateIndex = function(storeSchema, storeName) {
  console.debug('update', storeName);
  const self = this;
  const store = self.transaction.objectStore(storeName);
  if(storeSchema.indexes){
    for(const index of storeSchema.indexes){
      console.debug('update index', storeName);
      if(!store.indexNames.contains(index.name)){
        store.createIndex(index.name, index.keyPath, index.optionalParameters)
      }
    }
  }
}

/**
 * Data migration with new key path
 */
MigrationManager13To14.prototype.migrateData =  function(migrate_Table_index, temp_table_index) {
   return  new Promise (async (resolve,reject)=>{
    var self = this;
    var results = [];
        /**
         * Get the data from migration Data collection based on the param index(migrate_Table_index)
         */   
        var responseStore = await self.transaction.objectStore([migrateDatacollectionList[migrate_Table_index]]); 
        var request = await responseStore.openCursor();
            request.onsuccess = async function(evt) {
          var cursor = await evt.target.result;
          /**
           * Check cursor has data or not
           */
          if (cursor) {
            results.push(cursor.value);
            cursor.continue();
            
          } else if (cursor == null) {
             /**
             * The responseStore data send to MigrateToTempCollection function with migrate_table_index and temp_table_index
             */
             await self.migrateToTempCollection(results, migrate_Table_index, temp_table_index);

          } else {
              /**
             * The responseStore data send to MigrateToTempCollection function with migrate_table_index and temp_table_index
             * 
             */
             if (results.length > 0) {
              await self.migrateToTempCollection(results, migrate_Table_index, temp_table_index);
            }

          }
          
      resolve(true);
      
    };
    request.onerror = function(evt) {
      console.log(evt.target.error);
      reject();
    };
    
  })
};

/**
 * It used for to store data in temp collection with based on the migrate_Table_index and temp_table_index
 */

MigrationManager13To14.prototype.migrateToTempCollection = async function(row, migrate_Table_index, temp_table_index) {
  try {

  var self = this;
  var result = {};
   /**
    * Check row array has data or not
    */
   if (row.length  > 0) {
      
      row.forEach(element => {
         
        result = element;
         /**
          * it used to construct new keypath if result has not below mentioned attribute(uuid_profile_isbn)
          */
       if (!result.profileid) {
            result.uuid_profile_isbn = result.uuid + '|' + result.profileid + '|' + result.isbn;
        }

        })
        /**
         * check Library data store collection if yes, need to add timestamp
         */
        if (migrateDatacollectionList[migrate_Table_index] === "LibraryDataStore")
          applyMeta(result);
         /**
          * Data is stored in Temp collection with based on the temp_table_index params index value
          */
        const temp_save_resp = await self.transaction.objectStore([tempcollectionList[temp_table_index]]).add(result);
        temp_save_resp.onsuccess = (event) => {
          /**
          * Deleted the old table name from migrateDataCollectionList array with based on migrate_Table_index params
          */
          this.deleteTable([migrateDatacollectionList[migrate_Table_index]]).then(() => {
           /**
          * Create a table using migrateDataCollectionList array based on migrate_Table_index params index value, new keypath & indexes like [uuid_profile_isbn]
          */
            this.createNewTable([migrateDatacollectionList[migrate_Table_index]]).then(async () => {
           /**
          * Saved the data into newly created keypath collection from migrateDatacollectionList array with based on migrate_Table_index params index value
          */
          const migrate_resp = await self.transaction.objectStore([migrateDatacollectionList[migrate_Table_index]]).add(result);
                migrate_resp.onsuccess = (event) => {
                  console.log('migrate_resp-save',JSON.stringify(event.target.result));
                   /**
                     * check migration table index on every migration finish, if it done, stop it
                     */

                    if (migrateDatacollectionList.length -1 != migrate_Table_index) {

                     /**
                     * It start next migration with based on the  migrate_Table_index and temp_table_index params index value
                     */

                      this.migrateData((migrate_Table_index + 1), (temp_table_index + 1));

                    } else {

                      this.removedAllTempTables(0); //once done the migration successfull removed temp collection from indexedDB

                    }
                  
                }

            });
          })
        }

   } else {
      /**
        * Deleted the old table name from migrateDataCollectionList array with based on migrate_Table_index params
        */
     this.deleteTable([migrateDatacollectionList[migrate_Table_index]]).then(() => {
       /**
          * Create a table using migrateDataCollectionList array based on migrate_Table_index params index value, new keypath & indexes like [uuid_profile_isbn]
          */

          this.createNewTable([migrateDatacollectionList[migrate_Table_index]]).then(async () => {
              /**
                 * check migration table index on every migration, if it done, stop it
                 */

                if (migrateDatacollectionList.length -1 != migrate_Table_index) {
                   /**
                    * It start next migration with based on the  migrate_Table_index and temp_table_index params index value
                    */

                  this.migrateData((migrate_Table_index + 1), (temp_table_index + 1));

                } else {

                  this.removedAllTempTables(0); //once done the migration successfull removed temp collection from indexedDB
                  
                }

          });
     })
   
  }

  } catch (error) {

    console.log('migration-error',error.message);

  }
};
MigrationManager13To14.prototype.migrate = async function() {
  try {

   var self = this;
   await self.createNewTables(_objectStores_Temp);
  await self.migrateData(0 , 0);
  console.debug('13 to 14 done');
  } catch (error) {
      console.error(error.message)
  }
};

export default MigrationManager13To14;