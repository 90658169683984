import { LayoutView } from '@readium/navigator-web'; // For Axis360
// import { LayoutView } from '@fss/navigator-web'; // For FSS

LayoutView.prototype.ensureConentLoadedAtRange = async function ensureConentLoadedAtRange(start, end, token) {
    
    console.log("*************************** Overriden LayoutView.ensureConentLoadedAtRange ***************************");
    
    /**
    * Photon: Page Load Performance Fix. Loading one page per position
    */
    if (end > this.getLoadedEndPosition() && this.hasMoreAfterEnd()) {
      if (!(token && token.isCancelled)) {
        await this.loadNewSpineItemAtEnd(token);
      }
    }

    if (start < this.getLoadedStartPostion() && this.hasMoreBeforeStart()) {
      if (!(token && token.isCancelled)) {
        await this.loadNewSpineItemAtStart(token);
      }
    }

    this.updatePaginatedRange();
  }