import * as EpubCfi from '@evidentpoint/readium-cfi-js';
var self = {};

/**
 * Input: partial CFI string
 * Output: fake EPub CFI string
 *
 * Example:
 *  "/4/3" => "epubcfi(/99!/4/3)"
 */
self.makeFakeEPubCFI = function(aCFI) {
  return aCFI && 'epubcfi(/99!' + aCFI + ')';
};

/**
 *
 * Input: partial CFI A and partial CFI B
 * Output: 0 if CFIs are equal, -1 if CFI A < CFI B, 1 if CFI A > CFI B
 */
self.compareCFIs = function(cfiA, cfiB) {
  var cfiAEPub = self.makeFakeEPubCFI(cfiA);
  var cfiBEPub = self.makeFakeEPubCFI(cfiB);
  var comp = EpubCfi.Interpreter.compareCFIs(cfiAEPub, cfiBEPub);
  var result = 0;
  var i = 0;
  // loop until we hit an inequality level, else it's equal
  while (result == 0 && i < comp.length) {
    result = comp[i];
    i++;
  }
  return result;
};

/**
 *
 * Input: a partial CFI string and a start and end partial CFI
 * Output: true if partial CFI is within start and end CFI range (inclusive), otherwise false
 */
self.cifInRangeInclusive = function(aCFI, startCFI, endCFI) {
  return self.compareCFIs(aCFI, startCFI) >= 0 && self.compareCFIs(aCFI, endCFI) <= 0;
};

/**
 * Input: range CFI string
 * Output: Object containing partialCFIs for both start and end of range.
 *
 * Example:
 *  "/4/3, /1:322, /2:16" => { start: "/4/3/1:322", end: "/4/3/2:16" }
 */
self.cfiParsePartialRange = function(cfiRange) {
  var result = null;
  if (cfiRange) {
    var comps = cfiRange.split(',');
    if (comps.length == 3) {
      result = {
        start: comps[0] + comps[1],
        end: comps[0] + comps[2],
      };
    }
  }
  return result;
};

/**
 * Inputs: start CFI string, end CFI string
 * Output: A range CFI string
 *
 * Example:
 *   "/4/3/1:322", "/4/3/2:16" => "/4/3, /1:322, /2:16"
 */
self.cfiMakePartialRange = function(cfi1, cfi2) {
  var common = '';
  var one = '';
  var two = '';
  var l = 1;
  cfi1 = cfi1.replace(/\[.*?\]/g, '');
  cfi2 = cfi2.replace(/\[.*?\]/g, '');
  var c1 = cfi1.split('/');
  var c2 = cfi2.split('/');
  while (l < c1.length - 1 && l < c1.length - 1) {
    if (c1[l] == c2[l]) {
      common = common + ('/' + c1[l]);
    } else {
      break;
    }
    l++;
  }
  if (l < c1.length) {
    for (var l1 = l; l1 < c1.length; l1++) one = one + ('/' + c1[l1]);
  }
  if (l < c2.length) {
    for (var l2 = l; l2 < c2.length; l2++) two = two + ('/' + c2[l2]);
  }
  return common + ',' + one + ',' + two;
};

export default self;
