var _objectStores = {};

var MigrationManager10To11 = function(db, transaction) {
  this.db = db;
  this.transaction = transaction;

  const _STORE_NAMES = (this.STORE_NAMES = {
    PageUsage: 'PageUsageStore',
    EbookAnnotationsUsage: 'EbookAnnotationsUsageStore',
    PositionsUsage: 'PositionsUsageStore',
    AudioAnnotationsUsage: 'AudioAnnotationsUsageStore',
  });

  
  _objectStores[_STORE_NAMES.PageUsage] = {
    name: _STORE_NAMES.PageUsage,
    value: { keyPath: 'uuid_isbn' },
    indexes: [
      {
        name: 'uuid',
        keyPath: 'uuid',
        optionalParameters: { unique: false },
      },
      {
        name: 'isbn',
        keyPath: 'isbn',
        optionalParameters: { unique: false },
      },
    ],
  };

  _objectStores[_STORE_NAMES.EbookAnnotationsUsage] = {
    name: _STORE_NAMES.EbookAnnotationsUsage,
    value: { keyPath: 'uuid_isbn' },
    indexes: [
      {
        name: 'uuid',
        keyPath: 'uuid',
        optionalParameters: { unique: false },
      },
      {
        name: 'isbn',
        keyPath: 'isbn',
        optionalParameters: { unique: false },
      },
    ],
  };

  _objectStores[_STORE_NAMES.PositionsUsage] = {
    name: _STORE_NAMES.PositionsUsage,
    value: { keyPath: 'uuid_id' },
    indexes: [
      {
        name: 'uuid',
        keyPath: 'uuid',
        optionalParameters: { unique: false },
      },
      {
        name: 'id',
        keyPath: 'id',
        optionalParameters: { unique: false },
      },
    ],
  };

  _objectStores[_STORE_NAMES.AudioAnnotationsUsage] = {
    name: _STORE_NAMES.AudioAnnotationsUsage,
    value: { keyPath: 'uuid_id' },
    indexes: [
      {
        name: 'uuid',
        keyPath: 'uuid',
        optionalParameters: { unique: false },
      },
      {
        name: 'id',
        keyPath: 'id',
        optionalParameters: { unique: false },
      },
    ],
  };
};

MigrationManager10To11.prototype.createNewTables = function() {
  var self = this;
  return new Promise((resolve, reject) => {
    for (var key in _objectStores) {
      if (!_objectStores.hasOwnProperty(key)) continue;
  
      var obj = _objectStores[key];
  
      var objStore;
      if (self.db.objectStoreNames.contains(key)) {
        continue;
      } else {
        objStore = self.db.createObjectStore(key, obj.value);
      }
  
      if (typeof obj.indexes !== 'undefined') {
        var indexNames = objStore.indexNames;
        /* jshint ignore:start */
        // Ignoring JSHint error : Functions declared within loops referencing an outer scoped variable may lead to confusing semantics.
        obj.indexes.forEach(function(element, index, array) {
          if (indexNames.contains(element.name)) {
            var myIndex = objStore.index(element.name);
            var currentKeyPath = myIndex.keyPath;
            var updateIndex = currentKeyPath != element.keyPath;
  
            if (element.optionalParameters) {
              updateIndex =
                element.optionalParameters.unique != element.optionalParameters.unique || updateIndex;
            }
            if (updateIndex) {
              objStore.deleteIndex(element.name);
              objStore.createIndex(element.name, element.keyPath, element.optionalParameters);
            }
          } else {
            objStore.createIndex(element.name, element.keyPath, element.optionalParameters);
          }
        });
        /* jshint ignore:end */
      }
    }
    resolve();
  });
};


MigrationManager10To11.prototype.migrateToPageUsageStore = async function(book) {
  var self = this;
    if (!book.hasOwnProperty('pageUsage')) {
      return;
    }
    var pageUsage = book.pageUsage;
    var result = {};
    result.uuid_isbn = book.uuid + '|' + book.isbn;
    for (var property in pageUsage) {
      result[property] = pageUsage[property];
    }
    await this.transaction.objectStore('PageUsageStore').add(result);
};

MigrationManager10To11.prototype.migrateToAnnotationsUsageStore = async function(book) {
  if (!book.hasOwnProperty('annotationsUsage')) {
    return;
  }
  var annotationsUsage = book.annotationsUsage;
  var result = {};
  result.uuid_isbn = book.uuid + '|' + book.isbn;
  for (var property in annotationsUsage) {
    result[property] = annotationsUsage[property];
  }
  await this.transaction.objectStore('EbookAnnotationsUsageStore').add(result);
};

MigrationManager10To11.prototype.migrateToPositionsUsageStore = async function(audio) {
  if (!audio.hasOwnProperty('positionsUsage')) {
    return;
  }
  var positionsUsage = audio.positionsUsage;
  var result = {};
  result.uuid_id = audio.uuid + '|' + audio.id;
  for (var property in positionsUsage) {
    result[property] = positionsUsage[property];
  }
  await this.transaction.objectStore('PositionsUsageStore').add(result);
};

MigrationManager10To11.prototype.migrateToAudioAnnotationsUsageStore = async function(audio) {
  if (!audio.hasOwnProperty('annotationsUsage')) {
    return;
  }
  var annotationsUsage = audio.annotationsUsage;
  var result = {};
  result.uuid_id = audio.uuid + '|' + audio.id;
  for (var property in annotationsUsage) {
    result[property] = annotationsUsage[property];
  }
  await this.transaction.objectStore('AudioAnnotationsUsageStore').add(result);
};

MigrationManager10To11.prototype.migrateOldData = function() {
  var self = this;
  return new Promise((resolve, reject) => {
    if (!self.db.objectStoreNames.contains('UsageStore')){
      return;
    }
    var UsageStore = self.transaction.objectStore('UsageStore');
    var request = UsageStore.openCursor();
  
    request.onsuccess = async function(evt) {
      var cursor = evt.target.result;
      if (cursor) {
        let row = cursor.value;
        for (var isbn in row._userUsage) {
          if (!row._userUsage.hasOwnProperty(isbn)) continue;
          let book = row._userUsage[isbn];
          /**
           * Check if the book is ebook: has pageUsage; or audio book: has positionsUsage
           * If audio book: update position & audioAnnotation Usages
           * If ebook: update page & annotation Usages
           */ 
          if (!book.hasOwnProperty('pageUsage') && book.hasOwnProperty('positionsUsage')) {
            await self.migrateToPositionsUsageStore(book);
            await self.migrateToAudioAnnotationsUsageStore(book);
          } else if (book.hasOwnProperty('pageUsage') && !book.hasOwnProperty('positionsUsage')) {
            await self.migrateToPageUsageStore(book);
            await self.migrateToAnnotationsUsageStore(book);
          }
        }
        cursor.continue();
      }
      else {
        // after migrating to new objests, delete the old Table
        self.deleteTable();
      }
      resolve();
    };
    request.onerror = function(evt) {
      console.log(evt.target.error);
      reject();
    };
  });
};

MigrationManager10To11.prototype.deleteTable = function() {
  if (this.db.objectStoreNames.contains('UsageStore')){
    this.db.deleteObjectStore('UsageStore');
  }
};

MigrationManager10To11.prototype.migrate = async function() {
  var self = this;
  await self.createNewTables();
  await self.migrateOldData();
  console.debug('10 to 11 done');
};

export default MigrationManager10To11;
