import $ from 'jquery';
import utils from '@axisnow/util/utils';
import Managers from '@axisnow/data/Managers';
import URI from 'urijs';
import FileSystem from '@axisnow/storage/filesystem';
import moduleConfig from './ModuleConfig';
import { now } from 'moment';

var ComicManager = function (comicMetaData, srcImg, tocData) {
  self = this;
  self.comicMetaData = comicMetaData;
  self.srcImg = $(srcImg);
  self.tocData = tocData;
};


ComicManager.prototype.fetchComicFromRemote = function (isbn, publicationFetcher, encryptionHandler) {
  return new Promise(function (resolve, reject) {
    publicationFetcher.relativeToPackageFetchComicBookFileContents(isbn + '.acbf', "text", encryptionHandler, function (status, encryptedData) {
      if(status == "success"){
        resolve(encryptedData);
      } else {
        console.error("Fetch acbf metadata error");
        reject(null);
      }
    });
  });
};

ComicManager.prototype.getEncyptionDom = function(xmlFilePathRelativeToPackageRoot, publicationFetcher, callback, onError){
  console.log('ComicManager getEncyptionDom :', utils.getRemoteServerOrigin().path(xmlFilePathRelativeToPackageRoot));
  
  publicationFetcher.getXmlFileDom(xmlFilePathRelativeToPackageRoot, function(encryptionDom, error) {
    if (error) {
      console.log('ComicManager getEncyptionDom :', error);
      callback(null, error);
    } else {
      callback(encryptionDom)
    }
  }, onError);
};

ComicManager.prototype.getXmlFileDom = function(xmlFilePathRelativeToPackageRoot, publicationFetcher, callback){
  console.log('ComicManager getXmlFileDom :', utils.getRemoteServerOrigin().path(xmlFilePathRelativeToPackageRoot));

  publicationFetcher.relativeToPackageFetchComicBookFileContents(xmlFilePathRelativeToPackageRoot, "text", encryptionHandler, function (status, encryptedData) {
    if(status == "success"){
      console.log("ComicManager getXmlFileDom :", encryptedData);
      resolve(encryptedData);
    } else {
      console.error("Fetch acbf metadata error");
      reject(null, "Fetch xml metadata error");
    }
  });
}

ComicManager.prototype.cacheComicBook = function(bookRoot, progressBar) {
  /**
   * Get the current AuthData from Usermanager
   * Kidszone profileId stored once downloaded the comic book
   */
  var authData = Managers.user.getCurrentAuthData();
  var bookRootURI = new URI(bookRoot);
  bookRoot = bookRoot.replace(bookRootURI.pathname(), '/content/v2/download/cbz/' + bookRootURI.filename());
  bookRoot = new URI(bookRoot);
  
  var isbn = bookRoot.filename();
  var metaData = this.comicMetaData;

  if ($.isEmptyObject(progressBar)) {
    progressBar = {};
  }

  //Fetch License
  return Managers.license
    .fetchLicense(isbn)
    .then(function() {
      var promises = [];
      //Download zip files
      var fileSystem = new FileSystem(moduleConfig.jsLibRoot);
      var importZipFromHttp = utils.promisify(fileSystem.importZipFromHttp);
      promises.push(importZipFromHttp(bookRoot.toString(), progressBar));

      //Fetch cover
      promises.push(Managers.bookData.fetchBookCover(isbn));

      //Fetch metadata
      promises.push(
        Managers.bookData.saveMetadata(
          isbn,
          JSON.parse(JSON.stringify(metaData))
        ),
      );
      return Promise.all(promises);
    })
    .then(function() {
      return Managers.library.getBookInfo(isbn);
    })
    .then(function(book) {
      //kidszone profileid store in bookInfo object
      if (authData.hasOwnProperty('profile')) {
        book.profileid = authData.profile.id;
      }
      book.downloaded = true;
      book.type ='cbz';
      return Promise.all([
        book,
        Managers.library.saveBookInfo(book),
        Managers.bookData.saveMetadata(
          isbn,
          JSON.parse(JSON.stringify(metaData)),
        ),
      ]);
    })
    .spread(function(book, _, __) {
      return book;
    })
    .catch(function(err) {
      console.error(err);
    });
};

//CheckFormat
ComicManager.prototype.checkFormat = function (isbn) {
  var jqXhr = $.ajax({
    type: 'GET',
    dataType: "json",
    // url: 'https://qa-frontdoor.axisnow.com/formats/v2/'+isbn
    url: utils.getRemoteServerOrigin().path('/formats/v2/'+isbn)
  });
  return utils.deferredToPromise(jqXhr).then(function (response) {  
    console.log('Format Response', response);
    return response;
  }).catch(function(error) {
    console.error("Fetch format error:"+JSON.stringify(error));
    return Promise.reject(error);
  });
};

export default ComicManager;


