var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h2 class=\"popup-title search-border-btm\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data,"loc":{"start":{"line":1,"column":42},"end":{"line":1,"column":51}}}) : helper)))
    + "</h2>\n<button type=\"button\" class=\"close js-close-popup\" aria-label=\"Close\">\n    <span aria-hidden=\"true\" class=\"icon icon-close\"></span>\n</button>\n<form id=\"search-box-input-form\">\n    <div class=\"input-group\">\n        <label class=\"sr-only\" for=\"txtSearch\">Search</label>\n        <input aria-describedby=\"searchErrorMsg\" checkDisable=\"search-box\" id=\"txtSearch\" type=\"text\" class=\"search-box form-control\" placeholder=\"Search for...\" required=\"required\">\n        <button type=\"reset\" class=\"btn-search-clear glyphicon glyphicon-remove-circle\" aria-label=\"Reset\"></button>\n        <span class=\"input-group-addon\">\n            <button class=\"btn btn-icon btn-search\" type=\"submit\" aria-label=\"Search\">\n                <span aria-hidden=\"true\" class=\"icon icon-inner-search\"></span>\n            </button>\n        </span>\n    </div>\n    <div id=\"searchErrorMsg\"></div>\n</form>";
},"useData":true});