var name = '';
var self;
//Feature Class which accepts two callback function -enable and disable and First argument can be of type String or Object type
var FeatureGroup = function(featureGroupName, featuresArray) {
  self = this;
  this.name = featureGroupName;
  this.featuresArray = featuresArray;
  //enable all features associated in a single feature Group
  FeatureGroup.prototype.enable = function() {
    try {
      this.featuresArray.forEach(function(feature) {
        feature.enable();
      });
    } catch (ex) {
      throw ex;
    }
  };
  //disable all features associated in a single feature Group
  FeatureGroup.prototype.disable = function() {
    try {
      this.featuresArray.forEach(function(feature) {
        feature.disable();
      });
    } catch (ex) {
      throw ex;
    }
  };
};
export default FeatureGroup;
