import $ from 'jquery';
import Promise from 'bluebird';

import zip from 'zip-js-esm';
import 'zip-js-esm/src/mime-types';
import 'zip-js-esm/src/zip-fs';
import 'zip-js-esm/src/zip-ext';

import utils from '@axisnow/util/utils';
import './lib/idb.filesystem';

var filesystem, zipFs; // = new zip.fs.FS();
var parseURL = function(url) {
  var a = document.createElement('a');
  a.href = url;

  var parser = document.createElement('a');
  parser.href = url;

  /* jshint ignore:start */
  // Ignoring the error:  Expected an assignment or function call and instead saw an expression
  parser.protocol; // => "http:"
  parser.hostname; // => "example.com"
  parser.port; // => "3000"
  parser.pathname; // => "/pathname/"
  parser.search; // => "?search=test"
  parser.hash; // => "#hash"
  parser.host; // => "example.com:3000"
  /* jshint ignore:end */

  var pathObj = {
    source: url,
    protocol: a.protocol.replace(':', ''),
    host: a.hostname,
    port: a.port,
    query: a.search,
    /* params: (function(){
             var ret = {},
             seg = a.search.replace(/^?/,'').split('&'),
             len = seg.length, i = 0, s;
             for (;i<len;i++) {
             if (!seg[i]) { continue; }
             s = seg[i].split('=');
             ret[s[0]] = s[1];
             }
             return ret;
             })(),*/
    filename: a.pathname.split('/').pop(),
    directory: '',
    //directory: a.pathname.replace(/^([^/])/,'/$1')
    // , hash: a.hash.replace('#',''), path: a.pathname.replace(/^([^/])/,'/$1'),relative: (a.href.match(/tps?://[^/]+(.+)/) || [,''])[1],segments: a.pathname.replace(/^//,'').split('/');
  };
  var pathArray = a.pathname.split('/');

  if (pathArray[0] === '') {
    pathArray.shift();
  }

  if (pathArray[0] == 'epub_content' && pathArray[1] == 'content') {
    //Remove the first two directories
    pathArray.shift();
    pathArray.shift();
  } else if (pathArray[0] == 'epub_content' && pathArray[1] == 'assets') {
    //Remove the first two directories
    pathArray.shift();
    pathArray.shift();
  } else if (pathArray[0] == 'content') {
    pathArray.shift();
  }

  for (var i = 0; i < pathArray.length - 1; i++) {
    //if (pathArray[i] !== "")
    pathObj.directory += '/' + pathArray[i];
  }

  return pathObj;
};
var quotaSize = 20;

var FileSystem = function(libDir) {
  var self = this;

  zipFs = new zip.fs.FS();

  //Private APIz
  /*var _default_onerror = function (message) {
            console.error(message);
        }*/

  var errorHandler = function(e) {
    var msg = '';
    switch (e.code) {
      case FileError.QUOTA_EXCEEDED_ERR:
        msg = 'QUOTA_EXCEEDED_ERR';
        break;
      case FileError.NOT_FOUND_ERR:
        msg = 'NOT_FOUND_ERR';
        break;
      case FileError.SECURITY_ERR:
        msg = 'SECURITY_ERR';
        break;
      case FileError.INVALID_MODIFICATION_ERR:
        msg = 'INVALID_MODIFICATION_ERR';
        break;
      case FileError.INVALID_STATE_ERR:
        msg = 'INVALID_STATE_ERR';
        break;
      default:
        msg = 'Unknown Error';
        break;
    }

    console.log('Error: ' + msg);
  };

  //Public API
  this.getFs = function() {
    return new Promise(function(resolve, reject) {
      // Start the app by requesting a FileSystem (if the browser supports the API)
      window.requestFileSystem = window.requestFileSystem || window.webkitRequestFileSystem;
      if (typeof window.requestFileSystem !== 'undefined') {
        if (typeof navigator.webkitPersistentStorage !== 'undefined') {
          navigator.webkitPersistentStorage.requestQuota(
            1024 * 1024 * quotaSize,
            function(grantedSize) {
              // Request a file system with the new size.
              window.requestFileSystem(window.PERSISTENT, grantedSize, resolve, reject);
            },
            errorHandler,
          );
        } else {
          window.requestFileSystem(
            PERSISTENT,
            1024 * 1024 * quotaSize,
            function(myFs) {
              console.log('Opened ' + myFs.name);
              resolve(myFs);
            },
            reject,
          );
        }
      } else {
        reject(new Error("Sorry! Your browser doesn't support the FileSystem API :("));
        //alert('Sorry! Your browser doesn\'t support the FileSystem API :(');
      }
    });
  };

  /*this.removeRecursively = function (entry, onend, onerror) {
            var rootReader = entry.createReader();
            rootReader.readEntries(function(entries) {
                var i = 0;

                function next() {
                    i++;
                    removeNextEntry();
                }

                function removeNextEntry() {
                    var entry = entries[i];
                    if (entry) {
                        if (entry.isDirectory)
                            removeRecursively(entry, next, onerror);
                        if (entry.isFile)
                            entry.remove(next, onerror);
                    } else
                        onend();
                }

                removeNextEntry();
            }, onerror);
        };*/

  this.writeBlob = function(path, name, blob) {
    return this.getDirectory(path, { create: true }).then(function(directoryEntry) {
      console.log('path:' + path);
      return new Promise(function(resolve, reject) {
        directoryEntry.getFile(
          name,
          { create: true },
          function(fileEntry) {
            console.log('name:' + name);
            fileEntry.createWriter(function(fileWriter) {
              fileWriter.onwriteend = function(e) {
                resolve();
              };

              fileWriter.onerror = function(e) {
                reject();
                console.log('Write failed: ' + e.toString());
              };

              // Create a new Blob and write it to log.txt.
              fileWriter.write(blob);
            }, reject);
          },
          reject,
        );
      });
    });
  };

  this.importZipFromHttp = function(url, progressBar, callback) {
    progressBar = checkProgressBar(progressBar);
    var filename = url
      .split('/')
      .pop()
      .split('.')
      .shift();
    utils.fetchAsArrayBuffer(url, progressBar.progress).spread(function(data, xhr) {
      //var typedArray = new Uint8Array(data);
      progressBar.reset('Please wait...');
      var blob = new Blob([data], { type: xhr.getResponseHeader('Content-Type') });
      importZipAsBlob(filename, blob, progressBar, callback);
    });
  };

  var checkProgressBar = function(progressBar) {
    if ($.isEmptyObject(progressBar)) {
      progressBar = {
        reset: function() {},
        progress: function() {},
      };
    }
    return progressBar;
  };
  var importZipAsBlob = function(filename, blob, progressBar, callback) {
    var totalItems,
      itemsProcessed = 1;
    progressBar = checkProgressBar(progressBar);

    var getDirectoryContents = function(directoryEntry, path, callback) {
      if (directoryEntry.directory) {
        path += '/' + directoryEntry.name;
        /*filesystem.getDirectory(path, {create: true}, function(dirEntry){
                     console.log("Create dir:" + path);
                     });*/
        processEntries(directoryEntry.children, path, function() {
          callback();
        });
      }
    };
    var processEntries = function(entries, path, callback) {
      var updateEntryCount = function (counter) {
        counter.count++;
        itemsProcessed++; // total items processed
        progressBar.progress(Math.round((itemsProcessed / totalItems) * 100, 1));
        if (entries.length == counter.count) {
          console.log('Done with path:' + counter.path);
          callback();
        }
      };

      var entryCounter = { count: 0, path: path };
      if (entries.length) {
        entries.reduce(function(accumulatorPromise, entry) {
          return accumulatorPromise.then(function() {
            return new Promise(function(resolve, reject) {
              /* jshint ignore:start */
              // Functions declared within loops referencing an outer scoped variable may lead to confusing semantics.
              if (entry.directory) {
                getDirectoryContents(entry, path, function() {
                  updateEntryCount(entryCounter);
                  resolve();
                });
              } else {
                if (path === '') {
                  path = '/';
                }
                console.log('path: ' + path);
                console.log('filename:' + entry.name);
                writeFile(path, entry, function() {
                  console.log('Write completed.', entry.name);
                  /*if (callback !== undefined){
                                         callback();
                                         }*/
                  updateEntryCount(entryCounter);
                  resolve();
                });
              }
            });
            /* jshint ignore:end */
          });
        }, Promise.resolve());
      }
    };

    var writeFile = function(path, entry, callback) {
      var name = entry.name;
      entry.getBlob(zip.getMimeType(name), function(blob) {
        console.log('entry.name:' + entry.name);
        self.writeBlob(path, name, blob).then(function() {
          callback();
        });
      });
    };

    //var filename = URL.split("/").pop().split(".").shift();
    /*this.getFs(function(filesystem){
             zipFs.importHttpContent(URL, false, function() {
             filesystem.root.getDirectory(filename, {create: true},function(dataDir){
             zipFs.root.getFileEntry(dataDir, callback, null, onerror);
             });
             }, onerror);
             });*/
    zip.workerScriptsPath = libDir;
    zipFs.importBlob(blob, function() {
      progressBar.reset('Processing...');
      var entries = zipFs.root.children;
      totalItems = zipFs.entries.length; // total number of files
      processEntries(entries, filename, function() {
        callback();
      });
    });
    /*zipFs.importHttpContent(url, false, function(){
                //zipFs.root.getFileEntry()
                var entries = zipFs.root.children;
                processEntries (entries, filename, function(){
                    callback();
                });
            });*/
  };

  this.importZipAsBlob = importZipAsBlob;

  this.getDirectory = function(directory, options) {
    options = options || { create: false };
    return this.getFs().then(function(filesystem) {
      return new Promise(function(resolve, reject) {
        filesystem.root.getDirectory(
          directory,
          options,
          function(dirEntry) {
            console.log('Created folder: ' + dirEntry.fullPath);
            resolve(dirEntry);
          },
          function(error) {
            if (
              error != undefined &&
              error.target != undefined &&
              error.target.error.name == 'QuotaExceededError'
            ) {
              $(window).trigger('RunOutOfSpace');
              reject(new Error(error.target.error.name));
              return;
            }
            reject(new Error(error.name));
          },
        );
      });
    });
  };

  this.getFileAsText = function(directory, filename) {
    return self.getFile(directory, filename, 'text');
  };
  this.getFileAsArrayBuffer = function(directory, filename) {
    return self.getFile(directory, filename, 'arrayBuffer');
  };
  this.getFileAsBinaryString = function(directory, filename) {
    return self.getFile(directory, filename, 'binaryString');
  };
  this.getFileAsDataURL = function(directory, filename) {
    return self.getFile(directory, filename, 'dataUrl');
  };
  this.getFile = function(directory, filename, type) {
    /*return this.getFileEntry(directory, filename).then(function(fileEntry){
                return new Promise (function(resolve, reject){
                    fileEntry.file(function(file) {
                        var reader = new FileReader();
                        reader.onloadend = function(event) {
                            resolve(event.target.result);
                        };
                        switch (type){
                            case "dataUrl": reader.readAsDataURL(file); break;
                            case "arrayBuffer": reader.readAsArrayBuffer(file); break;
                            case "binaryString": reader.readAsBinaryString(file); break;
                            case "text":
                            default: reader.readAsText(file);
                        }
                    }, function(e) {
                        console.log('Error', e);
                        reject(e);
                    });
                });
            });*/
    var self = this;
    return new Promise(function(resolve, reject) {
      self
        .getDirectory(directory)
        .then(function(directoryEntry) {
          /* directoryEntry.getFile(filename, null, function(fileEntry) {
                     Promise.resolve(fileEntry);
                     }, Promise.reject);*/
          /*var getFile = Promise.promisify(directoryEntry.getFile,{
                        context: directoryEntry
                    });
                    return getFile(filename, null).then(function(fileEntry){
                        var readFile = Promise.promisify(fileEntry.file,{
                            context: fileEntry
                        });
                        return readFile();
                    }).then(function(file){
                        var reader = new FileReader();
                        reader.onloadend = function(event) {
                            resolve(event.target.result);
                        };
                        switch (type){
                            case "dataUrl": reader.readAsDataURL(file); break;
                            case "arrayBuffer": reader.readAsArrayBuffer(file); break;
                            case "binaryString": reader.readAsBinaryString(file); break;
                            case "text":
                            default: reader.readAsText(file);
                        }
                    });*/

          directoryEntry.getFile(
            filename,
            null,
            function(fileEntry) {
              fileEntry.file(
                function(file) {
                  var reader = new FileReader();
                  reader.onloadend = function(event) {
                    resolve(event.target.result);
                  };
                  switch (type) {
                    case 'dataUrl':
                      reader.readAsDataURL(file);
                      break;
                    case 'arrayBuffer':
                      reader.readAsArrayBuffer(file);
                      break;
                    case 'binaryString':
                      reader.readAsBinaryString(file);
                      break;
                    case 'text':
                    default:
                      reader.readAsText(file);
                  }
                },
                function(e) {
                  console.log('Error', e);
                  reject(e);
                },
              );
            },
            function(error) {
              reject(new Error(error.name));
            },
          );
        })
        .catch(function(error) {
          reject(error);
        });
    });
  };

  this.getFileEntry = function(directory, filename) {
    //onerror = onerror || _default_onerror;
    return this.getDirectory(directory).then(function(directoryEntry) {
      /* directoryEntry.getFile(filename, null, function(fileEntry) {
                    Promise.resolve(fileEntry);
                }, Promise.reject);*/
      /*var getFile = Promise.promisify(directoryEntry.getFile)
                return getFile(filename, null);*/
      return new Promise(function(resolve, reject) {
        directoryEntry.getFile(
          filename,
          null,
          function(fileEntry) {
            resolve(fileEntry);
          },
          function(error) {
            reject(error);
          },
        );
      });
    });
  };

  this.removeRecursively = function(dirname) {
    return this.getDirectory(dirname).then(function(dirEntry) {
      return new Promise(function(resolve, reject) {
        dirEntry.removeRecursively(function() {
          console.log('Directory removed.');
          resolve();
        }, reject);
      });
    });
  };
};

export default FileSystem;
