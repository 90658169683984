import $ from 'jquery';

var ErrorClassFactory = function(name) {
  var CustomError = function(title, messages, type, code) {
    if (typeof title !== 'string') {
      $.extend(this, title);
    } else {
      this.Title = title;
      this.Messages = messages;
      this.ReturnCode = code;
      this.Type = type;
    }

    this.name = name;

    if (typeof Error.captureStackTrace == 'function') Error.captureStackTrace(this, CustomError);
  };

  CustomError.prototype = Object.create(Error.prototype);
  CustomError.prototype.constructor = CustomError;

  return CustomError;
};

var Errors = {
  UnableToRetrieveLicense: ErrorClassFactory('UnableToRetrieveLicense'),
  LicenseExpired: ErrorClassFactory('LicenseExpired'),
  UserHasNoRights: ErrorClassFactory('UserHasNoRights'),
  UnableToOpenBook: ErrorClassFactory('UnableToOpenBook'),
  UserOrTokenNotFound: ErrorClassFactory('UserOrTokenNotFound'),
  BrowserOffline: ErrorClassFactory('BrowserOffline'),
  PageServiceError: ErrorClassFactory('PageServiceError'),
  SearchServiceError: ErrorClassFactory('SearchServiceError'),
  AnnotationsServiceError: ErrorClassFactory('AnnotationsServiceError'),
  UnableToLogin: ErrorClassFactory('UnableToLogin'),
  BadData: ErrorClassFactory('BadData'),
  UnexpectedError: ErrorClassFactory('UnexpectedError'),
  BookOwnershipNotFound: ErrorClassFactory('BookOwnershipNotFound'),
  UserNotFound: ErrorClassFactory('UserNotFound'),
  DatabaseAlreadyOpen: ErrorClassFactory('DatabaseAlreadyOpen'),
  DRMSessionServiceError: ErrorClassFactory('DRMSessionServiceError'),
  ReadiumCFIError: ErrorClassFactory('ReadiumCFIError')
};

export default Errors;
