var MigrationManager8To9 = function(db, transaction) {
  this.db = db;
  this.transaction = transaction;
};

MigrationManager8To9.prototype.convertRedHighlightsToGreen = function() {
  var self = this;
  return new Promise((resolve, reject) => {
    var convertedData = [];

    var index;
    var request;
    if (!this.db.objectStoreNames.contains('UsageStore')){
      return;
    }
    var objStore = this.transaction.objectStore('UsageStore');
    console.log('convertRedHighlightsToGreen');
  
    request = objStore.openCursor();
  
    request.onsuccess = function(evt) {
      var cursor = evt.target.result;
      if (cursor) {
        var user = cursor.value;
        self.convertSingleRow(user);
        convertedData.push(user);
        cursor.continue();
      }
  
      var clearRequest = objStore.clear();
      clearRequest.onsuccess = async function(ev) {
        await self.resaveData(convertedData);
        resolve();
      };
  
      clearRequest.onerror = function(ev) {
        console.log('clearRequest error:', ev.target.error);
        reject(ev.target.error)
      };
    };
  
    request.onerror = function(ev) {
      console.log('bookvaultId cursor error:', ev.target.error);
      reject(ev.target.error)
    };
  });
};

MigrationManager8To9.prototype.convertSectionsOrSpines = function(section) {
  for (var prop in section) {
    if (section.hasOwnProperty(prop)) {
      if ('annotations' in section[prop]) {
        var annotations = section[prop].annotations;
        for (var highlight in annotations) {
          if ('color' in annotations[highlight]) {
            if (annotations[highlight].color == 'red') {
              annotations[highlight].color = 'green';
            }
          }
        }
      }
    }
  }
};

MigrationManager8To9.prototype.convertSingleRow = function(user) {
  for (var isbn in user._userUsage) {
    var book = user._userUsage[isbn];
    if ('annotationsUsage' in book) {
      if ('sections' in book.annotationsUsage) {
        this.convertSectionsOrSpines(book.annotationsUsage.sections);
      }
      if ('spines' in book.annotationsUsage) {
        this.convertSectionsOrSpines(book.annotationsUsage.spines);
      }
    }
  }
};

MigrationManager8To9.prototype.resaveData = async function(data) {
  if (!this.db.objectStoreNames.contains('UsageStore')){
    return;
  }
  var usageStore = this.transaction.objectStore('UsageStore');

  for(let user of data){
    var request = await usageStore.put(user);

    request.onerror = function(ev) {
      console.log('resaveData error:', ev.target.error);
    };
  }
};

MigrationManager8To9.prototype.migrate = async function() {
  await this.convertRedHighlightsToGreen();
  console.debug('8 to 9 done');
  return;
};

export default MigrationManager8To9;
