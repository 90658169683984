import * as UnibabelIndex from 'unibabel/index';
import 'webcrypto-shim';
const Unibabel = UnibabelIndex.Unibabel;

var Digest = function() {};

//Gets this crypto algorithm settings from the XML encryption Identifier
Digest.prototype.getAlgorithm = function(identifier) {
  var identifiers = {
    'http://www.w3.org/2000/09/xmldsig#sha1': this.getSHA('SHA-1'),
    'http://www.w3.org/2001/04/xmlenc#sha256': this.getSHA('SHA-256'),
  };
  return identifiers[identifier];
};

Digest.prototype.getSHA = function(name) {
  return {
    name: name,
  };
};

Digest.prototype.digest = function(algo, data) {
  return window.crypto.subtle
    .digest(
      algo,
      Unibabel.strToUtf8Arr(data), //The data you want to hash as an ArrayBuffer
    )
    .then(function(hash) {
      //returns the hash as an ArrayBuffer
      console.log(new Uint8Array(hash));
      hash = Unibabel.arrToBase64(new Uint8Array(hash));
      return hash;
    });
};

export default Digest;
