import $ from 'jquery';
import Popup from './Popup';
import PopupMenuHeaderContentsTemplate from '../templates/popup/popup-menu-header-contents.hbs';

var PopupMenu = Popup.prototype.extends(function(popupNumber, type, title, options) {
  options = this.appendPopup(options);
  this.popupId = options.id;
  this.size = 0;
  //this.popupNum = popupNum;
  this.popupCountClass = 'popup-menu-' + this.popupNum;

  //var $wrapper = $(options.wrapper);

  //
  this.$header = this.getHeader().append(PopupMenuHeaderContentsTemplate({ title: title }));
  this.$header.prependTo(this.$el);

  this.$el.addClass('popup-sticky').addClass(this.popupCountClass);
  if (this.popupId) {
    this.$el.attr('id', this.popupId);
  }

  switch (type) {
    case Popup.TYPE.MAIN_MENU:
      this.$el.addClass('app-main-menu popup-left');
      this.$el.find('.popup-header').addClass('inverted');
      break;
    default:
    case Popup.TYPE.NAVBAR_MENU:
      this.$el.addClass('popup-navbar popup-right');
      break;
  }

  //$wrapper.append(this.$el);
});

PopupMenu.prototype.updateTitle = function(title) {
  this.$header.html(PopupMenuHeaderContentsTemplate({ title: title }));
};

PopupMenu.prototype.addContainerLayout = function(content, options) {
  this.size++;

  options = $.extend(
    {
      hasFooter: false,
    },
    options,
  );

  var containerLayoutCountClass = this.popupCountClass + '-container-layout-' + this.size;
  var $content = $('<div>')
    .addClass('container-layout size height unit-100pr')
    .addClass(containerLayoutCountClass);
  $content.append(content);

  if (options.hasFooter) {
    $content.addClass('has-footer');
  } else {
    $content.find('.pane.footer-pane').addClass('hidden');
  }

  if (options.id) {
    $content.attr('id', options.id);
  }

  this.$body.append($content);
  return $content;
};

PopupMenu.prototype.addContainerLayouts = function(containerLayouts) {
  containerLayouts.forEach(function(layout) {
    this.addContainerLayout(layout.content, layout.options);
  });
};

PopupMenu.prototype.clearContainerLayouts = function() {
  this.size = 0;
  this.$body.empty();
};

PopupMenu.prototype.position = function(position) {
  var positions = ['left', 'right']; // possible positions

  // checking for validity
  if (positions.indexOf(position) !== -1) {
    this.$el
      .removeClass('popup-right popup-left') // reset previous position (class)
      .addClass('popup-' + position); // set new position (class)
  }
};
export default PopupMenu;
