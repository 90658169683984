//Feature Class which accepts two callback function -enable and disable and First argument can be of type String or Object type
var Feature = function(feature, enable, disable, isAsync) {
  var isAsynchronous = false,
    enableAsync,
    disableAsync;
  //check for feature is of type asynchronous or synchronous
  // if feature is of type object then fetch "isAsync" attribute from feature otherwise use argument "isAsync" otherwise
  // use "false" value by default
  isAsynchronous = feature.isAsync != undefined ? feature.isAsync : isAsync ? isAsync : false;
  var self = this;
  var isEnabled = true;
  //if type of feature is string then enabled feature by default
  if (typeof feature != 'string') {
    createFeatureEnableDisable(feature);
    console.log('Created Feature object for: ' + feature.name);
  } else {
    createFeaturebyFeatureName(feature, enable, disable);
    console.log('Created Feature object for(enabled): ' + feature);
  }
  this.isEnabled = function() {
    return isEnabled;
  };
  //enable feature
  this.enable = function() {
    isEnabled = true;
    //call enableAsync funtion only for Sync calls
    if (!isAsynchronous) {
      enableAsync();
    }
  };
  //disable feature
  this.disable = function() {
    isEnabled = false;
    //call disableAsync funtion only for Sync calls
    if (!isAsynchronous) {
      disableAsync();
    }
  };
  if (isAsynchronous) {
    this.promise = Promise.method(function() {
      try {
        if (self.isEnabled()) {
          return enableAsync.apply(null, arguments);
        }
        return disableAsync.apply(null, arguments);
      } catch (ex) {
        throw ex;
      }
    });
  }
  //Create Feature class object which is enabled by default
  function createFeaturebyFeatureName(featureName, enable, disable) {
    var feature = {
      name: featureName,
      enable: enable,
      disable: disable,
    };
    createFeatureEnableDisable(feature);
  }
  //Create Feature class object which accepts Object as parameter-
  // also it contains boolean property "disableFeature" through which we can initially disabled feature
  function createFeatureEnableDisable(feature) {
    self.name = feature.name;
    enableAsync = feature.enable;
    disableAsync = feature.disable;
  }
};
export default Feature;
