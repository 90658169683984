/**
 * TTS Module.
 */
import _ from 'underscore';
import Managers from '@axisnow/data/Managers';
import SplashMessage from '../SplashMessage';
import * as EpubCfi from '@evidentpoint/readium-cfi-js';
import ttsCfiUtils from './ttsCfiUtils';
import speechAPI from './webspeech';
import rangy from 'rangy';
import Promise from 'bluebird';
import { Globals } from '@axisnow/readium-shared-js';
var CHUNK_SIZE = 400; // arbitrary value, hope a sentence fits in this size (or at least a comma)
var GOOGLE_VOICE_REFRESH = 5000; // pause/resume chunks at this interval because otherwise Google voices stall after 19 seconds of continuous single utterance playback
var DEFAULT_TTS_RATE = 0; // integer, 0 is normal rate, negative values are slower, positive values faster
var HIGHLIGHT_ID = 'wstts';
var HIGHLIGHT_COLOR = 'yellow';

var HIGHLIGHT_MODE_WORD = 0;
var HIGHLIGHT_MODE_SENTENCE = 1;
var HIGHLIGHT_MODE_NONE = 2;

var cachedPageTTSData = null;
var nextUtterance = null;

var self = {};
var pseudoPause = false;
var onTTSStartCallback = undefined;
var onTTSPauseCallback = undefined;
var onTTSStopCallback = undefined;
var onTTSTextAvailabilityCallback = undefined;

var hasTTSText = false;

var isPlayingSelection = false;

var intervalTimer;

// globals for TTS utterance chunking
var utteranceChunks;
var utteranceIndex = -1;
var parsedMetaData;
var speakableData;

var ttsSettings = {
  rate: DEFAULT_TTS_RATE,
  highlight: HIGHLIGHT_MODE_WORD,
  voiceName: null,
};

/* ------------------------------------------------------------------- */

self.isSupported = function() {
  return speechAPI.isSupported();
};

self.isAndroidMode = function() {
  return speechAPI.isAndroidMode();
};

self.isIOSMode = function() {
  return speechAPI.isIOSMode();
};

self.isSafariMode = function() {
  return speechAPI.isSafariMode();
};

self.isPlayingSelection = function() {
  return isPlayingSelection
}

self.init = function(readium) {
  self.readium = readium;
  console.log('TTS - Initialize');
  if (!speechAPI.isSupported()) {
    console.log('WebSpeech API is not supported!');
    return Promise.reject('WebSpeech API is not supported!');
  }

  return speechAPI.waitToInitialize().then(function() {
    return self.loadSettings().then(function() {
      isPlayingSelection = false;

      speechAPI.onStart(onStart);
      speechAPI.onWordBoundary(onWordBoundary);
      speechAPI.onError(onSpeechError);
      speechAPI.onEnd(onSpeechEnd);

      // Change the voices to something other than the default roboty voice on Dan's machine.
      speechAPI
        .getVoices()
        .then(function(voices) {
          console.log('Available TTS voices for this browser:', voices);
        })
        .catch(function(err) {
          console.log('No TTS voices, err:', err);
        });

      function stopOnlyIfSelectionPlaying() {
        // if we're playing a full page, don't stop just because selection status changes, but
        // if we're playing a selection, then stop if selection status changes
        if (isPlayingSelection) {
          properStop();
        }
      }
      self.readium.reader.plugins.highlights.on('textSelectionEvent', stopOnlyIfSelectionPlaying);
      self.readium.reader.plugins.selection.on('cleared', stopOnlyIfSelectionPlaying);

      self.readium.reader.on(Globals.Events.CONTENT_DOCUMENT_UNLOADED, onDocumentUnloaded);
      self.readium.reader.on(Globals.Events.CONTENT_DOCUMENT_LOADED, onDocumentLoaded);

      self.readium.reader.on(Globals.Events.PAGINATION_CHANGED, function() {
        cachedPageTTSData = null;
        return stopAndCheckForText();
      });

      function unloadTab(returnUndefined) {
        properStop();
        if (returnUndefined) {
          return undefined;
        }
        return false;
      }
      document.addEventListener(
        'visibilitychange',
        function() {
          if (document.visibilityState != 'visible') {
            // 'hidden' is what we want, but perhaps not returned/available for all browsers/platforms, so use !=
            return unloadTab(); // if we are switching away from this tab, kill TTS
          }
          return false;
        },
        false,
      );
      $(window).on(speechAPI.isSafariMode() ? 'pagehide' : 'beforeunload', function() {
        return unloadTab(true); // if tab closed, stop TTs
      }); // if tab closes, stop TTS
      $(window).on('loadlibrary', function() {
        return unloadTab(); // if open offline library, stop TTS
      });
      if (SplashMessage && SplashMessage.$el && SplashMessage.$el.show) {
        // wrap modal "show" function so we can kill TTS when modal displays - (i.e. when session time out message shows, etc.)
        var oldShow = SplashMessage.$el.show;
        SplashMessage.$el.show = function() {
          unloadTab();
          return oldShow.apply(this, arguments); // call original functions with any passed-in parameters
        };
      }
    });
  });
};

function onDocumentUnloaded($iframe, spineItem) {
  return properStop();
}

function onDocumentLoaded($iframe, spineItem) {
  console.log('TTS - onDocumentLoaded');
  if (!speechAPI.isSupported()) {
      console.log('WebSpeech API is not supported!');
      return;
  }
  isPlayingSelection = false;
   _stopInterval();
}

function stopAndCheckForText() {
  hasTTSText = false;
  return properStop()
  .then(function() {
      if (onTTSTextAvailabilityCallback) {
          if (!speechAPI.getDefaultVoice()) {
              onTTSTextAvailabilityCallback(hasTTSText); // we don't have a voice, we can't do TTS
          } else {
              isPlayingSelection = false;
              ttsText(getSpeakableDataForPageOrSelection());

              function ttsText (speakableDataCheck) {
                const ttsData = speakableDataCheck.next();
                if (ttsData.done) {
                  hasTTSText = !!(parsedMetaData && parsedMetaData.text && parsedMetaData.text.trim().length > 0);
                  onTTSTextAvailabilityCallback(hasTTSText); // we have no selectable text on current page, no TTS
                } else {
                  ttsData.value.then(() => {
                    hasTTSText = !!(parsedMetaData && parsedMetaData.text && parsedMetaData.text.trim().length > 0);
                    if (!hasTTSText) {
                      return ttsText(speakableDataCheck);
                    } else {
                      onTTSTextAvailabilityCallback(hasTTSText); // we have no selectable text on current page, no TTS
                    }
                  });
                }
              }
          }
      }
  });
}

self.hasTTSText = function() {
  return hasTTSText;
};

function properStop() {
  pseudoPause = false;
  isPlayingSelection = false;
  _stopInterval();
  return speechAPI.waitToInitialize().then(function() {
    return self.stop();
  });
}

function isValidRate(rate) {
  return rate >= -2 || rate <= 3; // this excludes garbage or undefined value
}

function isValidHighlight(highlight) {
  return highlight >= HIGHLIGHT_MODE_WORD && highlight <= HIGHLIGHT_MODE_NONE;
}

self.getStorage = function() {
  return Managers.user
    .getCurrentUser()
    .then(function(user) {
      return !user || user.isTemporary ? sessionStorage : localStorage;
    })
    .catch(function(err) {
      return sessionStorage; // if user error, just persist in session
    });
};

self.loadSettings = function() {
  return self.getStorage().then(function(theStorage) {
    var settings = theStorage.getItem('tts_settings');
    ttsSettings = (settings && JSON.parse(settings)) || ttsSettings;
    var setVoice = speechAPI.setVoiceByName(ttsSettings.voiceName);
    if (!setVoice) {
      setVoice = speechAPI.getDefaultVoice();
    }
    if (!isValidRate(ttsSettings.rate)) {
      ttsSettings.rate = DEFAULT_TTS_RATE;
    }
    speechAPI.setRate(ttsSettings.rate);
    if (setVoice) {
      ttsSettings.voiceName = setVoice.name;
      if (!isValidHighlight(ttsSettings.highlight)) {
        ttsSettings.highlight = HIGHLIGHT_MODE_WORD;
      }
      if (ttsSettings.highlight == HIGHLIGHT_MODE_WORD && !setVoice.localService) {
        ttsSettings.highlight = HIGHLIGHT_MODE_SENTENCE;
      }
    } else {
      ttsSettings.highlight = HIGHLIGHT_MODE_NONE;
    }
    return self.saveSettings(); // save back with possibly default settings
  });
};

self.saveSettings = function() {
  return self.getStorage().then(function(theStorage) {
    theStorage.setItem('tts_settings', JSON.stringify(ttsSettings));
  });
};

self.getVoices = function() {
  return speechAPI.getVoices();
};

self.setVoiceByName = function(voiceName) {
  voiceName = voiceName || speechAPI.getDefaultVoice().name;
  var setVoice = speechAPI.setVoiceByName(voiceName); // get actually selected voice (if we tried to set invalid, speechAPI will return first usable)
  if (setVoice && setVoice.name) {
    ttsSettings.voiceName = setVoice.name;
  }
  // reset rate because different voice voice types have different relative rate values (setRate also saves settings down for us)
  self.setRate(ttsSettings && ttsSettings.rate !== undefined ? ttsSettings.rate : DEFAULT_TTS_RATE);
};
self.getVoice = function() {
  return speechAPI.getVoiceByName(ttsSettings && ttsSettings.voiceName);
};

self.setRate = function(rate) {
  rate = isValidRate(rate) ? rate : DEFAULT_TTS_RATE;
  speechAPI.setRate(rate);
  ttsSettings.rate = rate;
  return self.saveSettings();
};
self.getRate = function() {
  return (ttsSettings && ttsSettings.rate) || DEFAULT_TTS_RATE;
};

self.setHighlightMode = function(highlight) {
  highlight = isValidHighlight(highlight) ? highlight : HIGHLIGHT_MODE_WORD;
  ttsSettings.highlight = highlight;
  return self.saveSettings();
};
self.getHighlightMode = function() {
  return (ttsSettings && ttsSettings.highlight) || HIGHLIGHT_MODE_WORD;
};

/* ------------------------------------------------------------------- */

self.onTTSStop = function(callback) {
  onTTSStopCallback = callback;
};

self.onTTSPause = function(callback) {
  onTTSPauseCallback = callback;
};

self.onTTSStart = function(callback) {
  onTTSStartCallback = callback;
};

self.onTTSTextAvailability = function(callback) {
  onTTSTextAvailabilityCallback = callback;
};

self.changedSettings = function() {
  if (speechAPI.isSupported() && hasSpeech() && utteranceIndex > -1) {
    if (pseudoPause || speechAPI.isPaused()) {
      // if we're currently paused, we don't want to unpause, but it needs to be handled specially
      // pseudoPause handles things properly (i.e. it resets remaining utterances to new settings when Play is resumed)
      if (!pseudoPause) {
        // non pseudoPause needs to become pseudoPause
        return self.pauseInternal(true);
      }
    } else {
      // if we're playing, simulate pseudopause and resume, to get the remaining utterances reset to new settings
      return self.pauseInternal(true).then(function() {
        setTimeout(function() {
          self.play(isPlayingSelection);
        }, 500);
      });
    }
  } else {
    return Promise.resolve();
  }
};

self.play = function(playSelectedIfTrue) {
  var prom = Promise.resolve();
  var playModeChanged = Boolean(playSelectedIfTrue) !== Boolean(isPlayingSelection);
  isPlayingSelection = playSelectedIfTrue;
  if (speechAPI.isSupported()) {
    if (playModeChanged) {
      // switch between playing selection or full page, kill anything else and start playing new mode
      pseudoPause = false;
      prom = self.stop().then(function() {
        if (playSelectionOrFullPage() && onTTSStartCallback) {
          onTTSStartCallback(isPlayingSelection);
        }
      });
    } else {
      var didStart = false;
      if ((pseudoPause || speechAPI.isPaused()) && !playModeChanged) {
        // pause - should resume
        if (pseudoPause) {
          // special resume for android (restarts from current chunk)
          // also used when changing settings since utterances must get updated with newly changed settings
          pseudoPause = false;
          didStart = resumeSpeakingFromIndex(); // re-speak starting with last spoken utterance
        } else {
          // normal resume
          prom = speechAPI.resume();
          didStart = hasSpeech(utteranceIndex);
        }
      } else {
        // normal play
        didStart = playSelectionOrFullPage();
      }
      if (didStart && onTTSStartCallback) {
        onTTSStartCallback(isPlayingSelection);
      }
    }
  }
  return prom;
};

self.pause = function() {
  return self.pauseInternal(speechAPI.isAndroidMode());
};
self.pauseInternal = function(pseudoPauseMode) {
  var prom = Promise.resolve();
  if (speechAPI.isSpeaking()) {
    if (pseudoPauseMode) {
      pseudoPause = true;
      prom = speechAPI.stop();
    } else {
      prom = speechAPI.pause();
    }
    if (onTTSPauseCallback) {
      onTTSPauseCallback(isPlayingSelection);
    }
  }
  return prom;
};

self.stopSelection = function() {
  if (isPlayingSelection) {
    return self.stop();
  } else {
    return Promise.resolve();
  }
};

self.stop = function() {
  pseudoPause = false;
  utteranceIndex = -1;
  utteranceChunks = null;
  var prom = speechAPI.stop();
  clearHighlight();
  if (onTTSStopCallback) {
    onTTSStopCallback(isPlayingSelection);
  }
  return prom;
};

function hasSpeech(index) {
  index = index || 0;
  return utteranceChunks && index >= 0 && index < utteranceChunks.length;
}

    // Main player entry point, grabs desired text, then starts TTS player
    function playSelectionOrFullPage() {
      var didStart = false;
      utteranceIndex = 0;
      utteranceChunks = [];
      speakableData = getSpeakableDataForPageOrSelection();
      if (speakableData) {
          nextUtterance = prepareUtterances(CHUNK_SIZE);
          if (nextUtterance) {
            // if we have something to say, say it
            didStart = true;
            if (onTTSStartCallback) {
              onTTSStartCallback(isPlayingSelection);
            }
            startSpeaking();
          }
      } else {
          speechAPI.stop(); // stop any other sounds playing
      }
      return didStart;
    }

  function getRangeNodes(rangeCfi, theFrameDocument) {
      // Note, this code partially ripped from controller.js
      var selectedElements = null;
      var ccfi = ttsCfiUtils.makeFakeEPubCFI(rangeCfi);
      var CFIRangeInfo = null;
      try {
          CFIRangeInfo = EpubCfi.Interpreter.getRangeTargetElements(
            ccfi,
            theFrameDocument,
            ['cfi-marker', 'cfi-blacklist', 'mo-cfi-highlight'],
            [],
            ['MathJax_Message', 'MathJax_SVG_Hidden'],
          );
      } catch (err) {
          console.log('TTS: getRangeNodes err - getRangeTargetElements: ', err);
      }
      if (CFIRangeInfo) {
          var startNode = CFIRangeInfo.startElement;
          var endNode = CFIRangeInfo.endElement;
          var range = rangy.createRange(theFrameDocument);
          if (startNode.length < CFIRangeInfo.startOffset) {
              //this is a workaround
              // 'Uncaught IndexSizeError: Index or size was negative, or greater than the allowed value.' errors
              // the range cfi generator outputs a cfi like /4/2,/1:125,/16
              // can't explain, investigating..
              CFIRangeInfo.startOffset = startNode.length;
          }
          try {
              range.setStart(startNode, CFIRangeInfo.startOffset);
          } catch (err) {
              console.log('TTS: getRangeNodes err - setStart: ', err);
              range.setStart(startNode, startNode.length || 0);
          }
          try {
              range.setEnd(endNode, CFIRangeInfo.endOffset);
          } catch (err) {
              console.log('TTS: getRangeNodes err - setEnd: ', err);
              range.setEnd(endNode, endNode.length || 0);
          }
          selectedElements = range.getNodes();
          return [selectedElements, CFIRangeInfo.startOffset, CFIRangeInfo.endOffset];
      }
      return [selectedElements, null, null];
  }

function getOffset(cfi) {
  if (cfi.indexOf(':') !== -1) {
    return cfi.replace(/.*:/, '');
  }
  return 0;
}

/** Ensure we're within word boundaries so we don't speak partial words **/
function adjustOffsets(selectedNodes, startOffset, endOffset) {
  if (selectedNodes !== undefined && selectedNodes.length > 0) {
    var startNodeData = selectedNodes[0].data;
    var endNodeData = selectedNodes[selectedNodes.length - 1].data;

    if (startNodeData !== undefined && startOffset < startNodeData.length) {
      while (startOffset > 0 && !/\s/.test(startNodeData.charAt(startOffset))) {
        startOffset--;
      }
    }

    if (endNodeData !== undefined && endOffset > 0) {
      while (endOffset < endNodeData.length - 1 && !/\s/.test(endNodeData.charAt(endOffset))) {
        endOffset++;
      }
    }
  }

  return {
    startOffset: startOffset,
    endOffset: endOffset,
  };
}

function pushIfNotNull(array, value) {
  if (value) {
      array.push(value);
  }
}

function getSpeakableDataForPageOrSelection() {
  var rcfi = isPlayingSelection ? self.readium.reader.plugins.highlights.getCurrentSelectionCfi() : null;
  if (isPlayingSelection && !rcfi) {
      // we are supposed to play selection, but there's nothing selected, nothing to play
      return null;
  }
  // get full visible page start/end CFIs
  var speakableRange = [];
  var spines = self.readium.reader.getLoadedSpineItems();
  if (spines && spines.length) {
    if (rcfi) {
      spines.forEach(function(aSpine) {
        pushIfNotNull(speakableRange, getViewableSpeakableNodesForPage(rcfi, aSpine)); // find viewable nodes
      });
    } else {
      spines.forEach(function(aSpine) {
        pushIfNotNull(speakableRange, getViewableSpeakableNodesForPage(null, aSpine)); // find viewable nodes
      });
    }

    return getWordOffsetCFIData(speakableRange); // parse word boundaries as individual CFI ranges for highlighting
  }
}

function getViewableSpeakableNodesForPage(selectionRange, spineItem) {
  // determine start/end range (current selection OR visible page)
  try {
    var iframes = self.readium.reader.getLoadedContentFrames();
    var spineFrameDocument;
    iframes.forEach(function(aFrame) {
      if (aFrame.spineItem.idref == spineItem.idref) {
        spineFrameDocument = aFrame.$iframe.length > 0 && aFrame.$iframe[0].contentDocument;
      }
    });
    if (!spineFrameDocument) {
      return;
    }
    var rawStartCfiData = self.readium.reader.getFirstVisibleCfi(spineItem.idref);
    var rawStartCfi = rawStartCfiData && rawStartCfiData.contentCFI || self.readium.reader.getStartCfi(spineItem.idref).contentCFI;
    var rawEndCfiData = self.readium.reader.getLastVisibleCfi(spineItem.idref);
    var rawEndCfi = rawEndCfiData && rawEndCfiData.contentCFI || self.readium.reader.getEndCfi(spineItem.idref).contentCFI;
    if (selectionRange) {
      if (selectionRange.idref != spineItem.idref) {
        return;
      }
      var parsedRange = ttsCfiUtils.cfiParsePartialRange(selectionRange.cfi);
      // make sure we only get part of selection that is visible on current page
      if (ttsCfiUtils.cifInRangeInclusive(parsedRange.start, rawStartCfi, rawEndCfi)) {
        rawStartCfi = parsedRange.start;
      }
      if (ttsCfiUtils.cifInRangeInclusive(parsedRange.end, rawStartCfi, rawEndCfi)) {
        rawEndCfi = parsedRange.end;
      }
    }
    if (!rawStartCfi) {
      console.log('NO Start CFI for spine: ', spineItem);
    }
    if (!rawEndCfi) {
      console.log('NO End CFI for spine: ', spineItem);
    }
    if (rawStartCfi && rawEndCfi) {
      var range = ttsCfiUtils.cfiMakePartialRange(rawStartCfi, rawEndCfi);
      var selectedNodes;
      [selectedNodes, startOffset, endOffset] = getRangeNodes(range, spineFrameDocument);
      if (!selectedNodes || selectedNodes.length == 0) {
          return;
      }

      selectedNodes = _.filter(selectedNodes, {nodeType: 3});

      var adjustedOffsets = adjustOffsets(selectedNodes, startOffset, endOffset);
      var startOffset = adjustedOffsets.startOffset;
      var endOffset = adjustedOffsets.endOffset;

      return {
          idref: spineItem.idref,
          nodes: selectedNodes,
          startOffset: startOffset,
          endOffset: endOffset
      };
    }
  } catch (err) {
    console.error('TTS fail in getViewableSpeakableNodesForPage: ', err);
    return;
  }
}

function* getWordOffsetCFIData(nodeDataArray) {
  if (nodeDataArray && nodeDataArray.length > 0) {
    parsedMetaData = {};
    let offsetData = {};
    parsedMetaData.words = offsetData;
    let fullText = '';
    parsedMetaData.text = fullText;
    // generates offsetData (hash with word boundary index as key):
    // {
    // 	startOffset: n, // offset of each word within the main, full string sent to webspeech
    // 	cfiStart: 'nnnn', // start and end cfi to highlight
    // 	cfiEnd: 'nnnn'
    // }

    for (let i = 0; i < nodeDataArray.length; i++) {
      let nodeData = nodeDataArray[i];

      for (let idx = 0; idx < nodeData.nodes.length; idx++) {
        let isFirstNode = idx == 0;
        let isLastNode = idx == nodeData.nodes.length - 1;
        let aNode = nodeData.nodes[idx];

        let theText = aNode.data;
        let cfiOffset = 0;
        if (isLastNode) { // trim isLastNode first, isFirstNode second
          theText = theText.substring(0, nodeData.endOffset);
        }
        if (isFirstNode && (!isLastNode || nodeData.startOffset < nodeData.endOffset)) { // trim isFirstNode second, isLastNode first
          theText = theText.substring(nodeData.startOffset);
          cfiOffset = +nodeData.startOffset;
        }
        // fullText += (fullText.length > 0 ? ' ' : ''); // need to see if we need extra spaces, can mess up capitalized chapter starts though
        let wordOffsetBase = fullText.length;
        fullText = fullText + theText;
        parsedMetaData.text = fullText;

        let words = theText.split(/\s/);
        let lastOffset = 0;
        let offset = 0;

        yield Promise.each(words, (word) => {
          let computeOffsetData;
          if (word.length > 0) {
            // If for some super weird reason, there is a double-space then we must skip the space for the utterance

            offset += word.length;
            const currentNode = aNode;
            const currentCfiOffset = cfiOffset;
            // console.log('tts processing issue; tts.js; getWordOffsetCFIData(); currentCfiOffset: ', currentCfiOffset);
            const currentLastOffset = lastOffset;
            const currentOffset = offset;
            const currentWordOffsetBase = wordOffsetBase;
            const currentNodeData = nodeData;
            computeOffsetData = Promise.delay(0).then(() => {
              return EpubCfi.Generator.generateCharacterOffsetCFIComponent(
                currentNode,
                (currentCfiOffset + currentLastOffset),
                ['cfi-marker', 'cfi-blacklist', 'mo-cfi-highlight'],
                [],
                ['MathJax_Message', 'MathJax_SVG_Hidden'],
              );
            }).then(startCFI => {
              const endCFI = EpubCfi.Generator.generateCharacterOffsetCFIComponent(
                currentNode,
                (currentCfiOffset + currentOffset),
                ['cfi-marker', 'cfi-blacklist', 'mo-cfi-highlight'],
                [],
                ['MathJax_Message', 'MathJax_SVG_Hidden'],
              );

              return [startCFI, endCFI];
            }).then(([startCFI, endCFI]) => {
              const wordBoundary = currentWordOffsetBase + currentLastOffset;

              offsetData['W' + wordBoundary] = {
                idref: currentNodeData.idref,
                boundary: wordBoundary,
                startCfi: startCFI,
                endCfi: endCFI
              };
            });
          }

          offset++;   // Increment to skip over whitespace
          lastOffset = offset;

          return computeOffsetData;
        });
      }
    }
  }
}

/******************************************************************************************************
 * TTS specific code - chunks, plays and highlights as it plays
 ******************************************************************************************************/

function _startInterval() {
  if (!speechAPI.isAndroidMode() && !speechAPI.isSafariMode()) {
    window.clearInterval(intervalTimer);
    intervalTimer = setInterval(function() {
      speechAPI.refresh();
    }, GOOGLE_VOICE_REFRESH);
  }
}

function _stopInterval() {
  if (!speechAPI.isAndroidMode() && !speechAPI.isSafariMode()) {
    window.clearInterval(intervalTimer);
    intervalTimer = null;
  }
}

function resumeSpeakingFromIndex() {
  var result = hasSpeech(utteranceIndex);
  if (result) {
    for (var i = utteranceIndex; i < utteranceChunks.length; i++) {
      utteranceChunks[i].utterance = speechAPI.createUtterance(
        utteranceChunks[i].chunkText.replace(/\n/gm, ' '),
        parsedMetaData,
      );
      utteranceChunks[i].highlightMode = self.getHighlightMode();
      speakChunkData(utteranceChunks[i]); // chain up chunks, they play in sequence per webspeech api
    }
  }
  return result;
}

function speakUtterancesFromIndex() {
  function speakNextUtterance() {
    const nextChunk = nextUtterance.next();
    if (nextChunk.done) {
      return;
    }

    const chunks = nextChunk.value;
    Promise.each(chunks, chunk => {
      chunk.utterance = speechAPI.createUtterance(
        chunk.chunkText,
        parsedMetaData,
      );
      chunk.highlightMode = self.getHighlightMode();
      if (pseudoPause) {
        return;
      } else if (!speechAPI.isPaused()) {
        speakChunkData(chunk); // chain up chunks, they play in sequence per webspeech api
      } else {
        return new Promise(resolve => {
          speechAPI.onResume(() => {
            speakChunkData(chunk);
            resolve();
          });
        });
      }
    }).then(speakNextUtterance);
  }

  speakNextUtterance();
}

function startSpeaking() {
  _startInterval();
  utteranceIndex = 0;
  speakUtterancesFromIndex();
}

function speakChunkData(curUtterance) {
  speechAPI.speak(curUtterance.utterance);
}

function highlightWordCFIs(partialCfi, idref) {
  self.readium.reader.plugins.highlights.addHighlight(
    idref,
    partialCfi,
    HIGHLIGHT_ID,
    HIGHLIGHT_COLOR,
    null,
    true,
  );
}

// Highlight individual words as they are spoken
function clearHighlight() {
  self.readium.reader.plugins.highlights.removeHighlight(HIGHLIGHT_ID);
}

function highlightWholeUtterance(utteranceData) {
  clearHighlight();
  if (utteranceData && utteranceData.highlightChunks) {
      utteranceData.highlightChunks.forEach(function(chunker) {
          highlightWordCFIs(ttsCfiUtils.cfiMakePartialRange(chunker.startCfi, chunker.endCfi), chunker.idref);
      });
  }
}

function highlightWord(charIndex, data) {
  var boundaryData = findNearestCFI(data.words, charIndex);
  clearHighlight();
  if (boundaryData) {
      highlightWordCFIs(ttsCfiUtils.cfiMakePartialRange(boundaryData.startCfi, boundaryData.endCfi), boundaryData.idref);
  }
}

function findNearestCFI(words, offset, beforeThis) {
  var result = null;
  if (words) {
      result = words['W' + offset]; // try for exact match
      if (!result) {
          var keys = Object.keys(words);
          var nearestDelta;
          keys.forEach(function(key) {
              var word = words[key];
              if (word) {
                  var val = word.boundary;
                  var delta = offset - val;
                  if (!beforeThis || delta >= 0) {
                      delta = Math.abs(delta);
                      if (nearestDelta === undefined || delta < nearestDelta) {
                          nearestDelta = delta;
                          result = words[key];
                      }
                  }
              }
          });
      }
  }
  return result;
}

function findAllCFIsInclusive(words, startWord, endWord) {
  var result = [];
  if (words) {
      var looping = false;
      var done = false;
      var keys = Object.keys(words);
      keys.forEach(function(key) {
          var word = words[key];
          if (!done && word) {
              if (looping) {
                  result.push(word);
                  if (word.endCfi == endWord.endCfi) {
                      done = true;
                  }
              } else {
                  if (word.startCfi == startWord.startCfi) {
                      result.push(word);
                      looping = true;
                      if (startWord.endCfi == endWord.endCfi) {
                          done = true;
                      }
                  }
              }
          }
      });
  }
  return result;
}

function* prepareUtterances(chunkMax) {
  const moreText = speakableData;
  // Note, it looks like often, Webspeech will stop sending word boundary events on
  // text that's longer than some size (couple hundred characters). As a result, this
  // code will chunk text into multiple utterances which are chained together.

  // 'parsedMetaData' contains the full text string to speak, and a list of word boundary offsets and a CFI range to highlight each word
  utteranceChunks = [];

  var p = 0;
  var lastChunk = 0;
  var lastSentence = 0;
  var lastBreak = 0;
  var lastWhitespace = 0;
  var aborted = false;
  function* pushUtteranceIfNeeded() {
    var startingLastChunk = lastChunk;
      while (true) {
          var breakPoint = lastSentence; // favor sentence endings
          if (breakPoint <= lastChunk) {
              breakPoint = lastBreak; // fall back to a natural sentence break
              if (breakPoint <= lastChunk) {
                  breakPoint = lastWhitespace; // lastly, fall back to any whitespace break
              }
          }
          if (breakPoint > lastChunk) {
              // make a chunk from lastChunk to lastBreak
              var chunkText = parsedMetaData.text.substring(lastChunk, breakPoint);
              let chunk;
              if (chunkText.length > 0) { // note: we want spaces too, because the silences are part of speech too
                  var utterance = speechAPI.createUtterance(chunkText, parsedMetaData);
                  // store first and last word CFIs into utterance - to be used for chunk (sentence) highlighting
                  var starter = findNearestCFI(parsedMetaData.words, lastChunk);
                  var ender = findNearestCFI(parsedMetaData.words, lastChunk + chunkText.length, true);
                  var highlightCfis = [];
                  var highlightCfiWords = findAllCFIsInclusive(parsedMetaData.words, starter, ender);
                  var curStart = starter;
                  var prevStart = null;
                  var prevCfi = starter;
                  highlightCfiWords.forEach(function(curCfi) {
                      if (curCfi.idref != curStart.idref) {
                          highlightCfis.push({startCfi: curStart.startCfi, endCfi: prevCfi.endCfi, idref: curStart.idref});
                          prevStart = curStart;
                          curStart = curCfi;
                      }
                      prevCfi = curCfi;
                  });
                  if (prevStart != curStart) {
                      highlightCfis.push({startCfi: curStart.startCfi, endCfi: prevCfi.endCfi, idref: curStart.idref});
                  }
                  chunk = {chunkText: chunkText, offset: lastChunk, utterance: utterance, highlightMode: self.getHighlightMode(), highlightChunks: highlightCfis};
                  utteranceChunks.push(chunk);
              }
              lastChunk = breakPoint;
              yield chunk;
          }
          if (startingLastChunk == lastChunk) {
            aborted = true;
            break; // something's wrong, we'll be stuck in an infinite loop here without this
          }
      }
  }

  const tryPushUtterance = pushUtteranceIfNeeded();
  var lastWasSentenceBreak = false;
  let nextText = moreText.next();
  while (!nextText.done) {
    yield nextText.value.then(() => {
      let chunks = [];
      while (p < parsedMetaData.text.length) {
        const forceMaxChunk = p - lastChunk >= chunkMax || lastSentence > lastChunk;
        if (forceMaxChunk) {
          const utterancePushedLocal = tryPushUtterance.next(); // always try to break on sentences (or on breaks if maxChunk overstepped)
          if (utterancePushedLocal.value) {
            chunks.push(utterancePushedLocal.value);
          }
        }
        var ch = parsedMetaData.text.charAt(p);
        if (/\s/.test(ch)) {
          lastWhitespace = p;
          if (lastWasSentenceBreak) { // only treat it as sentence if followed by whitespace, to avoid issue with "$1.25" (i.e. decimal points)
            lastSentence = p;
          }
        }
        lastWasSentenceBreak = false;
        if (/[\,\;\:\(\)]+/.test(ch)) {
          lastBreak = p + 1;
        }
        if (/[\.\!\?]+/.test(ch)) {
          lastWasSentenceBreak = true;
        }
        p++;
      }
      nextText = moreText.next();
      return chunks;
    });
  }

  lastSentence = lastBreak = lastWhitespace = parsedMetaData.text.length;
  const utterancePushed = tryPushUtterance.next();
  if (utterancePushed.value) {
    yield Promise.resolve([utterancePushed.value]);
  }
}

var onStart = function(ev, data) {
  clearHighlight(); // clean any highlighted
  if (hasSpeech()) {
    var curUtterance = utteranceChunks[utteranceIndex];
    if (curUtterance.utterance && curUtterance.highlightMode == HIGHLIGHT_MODE_SENTENCE) {
      highlightWholeUtterance(curUtterance);
    }
  }
};

var onWordBoundary = function(ev, data) {
  if (
    ev.name == 'word' &&
    hasSpeech(utteranceIndex) &&
    utteranceChunks[utteranceIndex].highlightMode == HIGHLIGHT_MODE_WORD
  ) {
    highlightWord(ev.charIndex + utteranceChunks[utteranceIndex].offset, data);
  }
};

var onSpeechError = function(ev) {
  console.log('Utterance error; ', ev);
};

var onSpeechEnd = function(ev) {
  clearHighlight(); // clean any highlighted
  if (pseudoPause) {
    // for android pseudoPause just stops current speech, no advance
  } else {
    if (hasSpeech(utteranceIndex)) {
      utteranceIndex++;
      if (!hasSpeech(utteranceIndex)) {
        _stopInterval();
        utteranceIndex = 0;
        self.stop();
      }
    }
  }
};

export default self;
