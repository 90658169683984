import $ from 'jquery';
import Strings from '../../i18n/Strings';
import Managers from '@axisnow/data/Managers';
import PopupFactory from '../../popup/PopupFactory';
import BookmarkMenu from '../../templates/bookmark-menu.hbs';
import BookmarkItem from '../../templates/bookmark-item.hbs';
import AnnotationsMenu from '../../menus/navbar/AnnotationsMenu';
import Feature from '@axisnow/data/app-permission/Feature';
import AccessibilityManager from '../../accessibility/AcessibilityManager';
import Menu from '../../menus/Menu';
import SearchResultHighlighter from '../../search/ui/SearchResultHighlighter';
import errorMonitor from '../../ErrorMonitor';
import PubSub from 'pubsub-js'; //for panel update

//Confirmation modal
var popupModal = PopupFactory.create(PopupFactory.TYPE.MODAL);
var sortedAnnotationsArray = [];
var checkAddBookmark = false;
var checkDeleteBookmark = false;

var featureManager = Managers.feature;
//Bookmarks
var bookmarkListPopup = PopupFactory.create(
  PopupFactory.TYPE.NAVBAR_WITH_HEADER_BUTTON_MENU,
  {
    title: 'Add Bookmarks',
    icon: 'icon-add',
  },
  { id: 'annotationsListBookmarks' },
);

bookmarkListPopup.$body.html(BookmarkMenu({}));

featureManager.feature(
  new Feature(
    'Bookmarks',
    function() {
      $('header#app-navbar')
        .find('#btnToggleBookmark')
        .show();
      console.log('Bookmarks feature enabled');
    },
    function() {
      $('header#app-navbar')
        .find('#btnToggleBookmark')
        .hide();
      console.log('Bookmarks feature disabled');
    },
  ),
);

var BookmarksMenu = function() {
  AnnotationsMenu.apply(this, arguments);
  this.popup(bookmarkListPopup);
  var count = 0;
  bookmarkListPopup.$el.find('.btn-add-bookmark')
  .off('click')
  .on(
    'click',
    $.proxy(function(e) {
      if (count === 0) {
        count++;
        checkAddBookmark = true;        
        this.annotationUtils
          .saveBookmarks(this.isbn)
          .then($.proxy(this.show, this))
          .catch(function(error) {
            console.log('Unable to save bookmark:', error);
            checkAddBookmark = false;
          });
        setTimeout(function(args) {
          count = 0;
        }, 2000);
      }
    }, this),
  );
  if(!this.isAudiobook() && !this.isComicBook()){
    this.searchResultHighlighter = new SearchResultHighlighter(this.readium, 'bookmark');
  }
};

// Adding attributes for WA
bookmarkListPopup.$el.attr('role', 'dialog').attr('aria-label', Strings.bookmark);

BookmarksMenu.prototype = Object.create(AnnotationsMenu.prototype);

BookmarksMenu.prototype.show = function() {
  var self = this;
  return self.getBookUsage().then(function(bookUsage) {
    var ascending = true;
    var type = 'Bookmark';
    var $listGrp = bookmarkListPopup.$body.find('.list-group');
    $listGrp.empty();

    if (bookUsage != undefined) {
       sortedAnnotationsArray = bookUsage.getSorted(
        type,
        bookUsage.sortBy.DATE,
        ascending,
      );
      /**
       * Segerate the bookmark comic and epub
       */
      if (!self.isAudiobook()) {
        var filterMode = self.isComicBook() ? 'cbzloc' : 'epubcfi';
        if(filterMode){
          var filteredBkMarkList = sortedAnnotationsArray.filter(data => data.location.includes(filterMode))
          sortedAnnotationsArray = filteredBkMarkList;
        }
      }
      
      PubSub.publish('BOOKMARK-MENU-LIST-KEY',sortedAnnotationsArray);
      sortedAnnotationsArray.forEach(function(annotation) {
        if (annotation.type == 'Bookmark' && !annotation.deleteAnnotation) {
          annotation.page = '';
          var obj = { annotation: annotation };
          var $item = $(BookmarkItem(obj));
          $item.find('.delete').on('click', function(e) {
            self.deleteItem(annotation);
            e.stopPropagation();
            e.preventDefault();
            checkDeleteBookmark = true;
          });

          $item.data(annotation);

          if (self.isAudiobook()) {
            // Below code for audiobook
            $item.find('.item-heading').text(self.formatChapterTitle(annotation.chapter));
            $item.find('.time-stamp').text(self.formatDateNoTime(annotation.usercreatedate));

            $item
              .find('.badge')
              .addClass('page-time')
              .removeClass('page-num');
            $item.find('.page-time').text(self.formatTime(annotation.timeoffset));
          } else {
            // Below code for readerbook, and commented temporarily
            $item.find('.time-stamp').text(self.formatDate(annotation.createdate));
            $item
              .find('.badge')
              .addClass('page-num')
              .removeClass('page-time');
          }

          $item.on('click', function(e) {
            Menu.prototype.hide.call(self);
            e.preventDefault();
            var data = $(e.currentTarget).data();
            if (self.isAudiobook()) {
              self.navigateToAnnotation(data);
            } else if (self.isComicBook()) {
              self.navigateToAnnotation(data);
            } else {
              var locObj = self.getLocation(data.location);
              self.searchResultHighlighter.setCfiCollection([locObj]);
              self.searchResultHighlighter.setFocusedSearchResult(locObj.idref, locObj.contentCFI);
              self.readium.reader.openSpineItemElementCfi(locObj.idref, locObj.contentCFI);
            }
          });

          $listGrp.append($item);
          if (checkAddBookmark) {
            $('#statusDiv').text('Bookmark Added');
            checkAddBookmark = false;
          }
        }
      });
     // setTimeout(function() {
      //     AccessibilityManager.setTabGroup("bookmark_popup");
      //     AccessibilityManager.updateTabOrder("bookmark_popup");
      // }, 200)
    }
    if (checkDeleteBookmark) {
      $('#statusDiv').text('Bookmark Removed');
      checkDeleteBookmark = false;
    }

    $('#annotationsListBookmarks').addClass('size height unit-auto');
    var bookmarksHeight = $('#annotationsListBookmarks')
      .css('height')
      .replace('px', '');
    bookmarksHeight = parseInt(bookmarksHeight) + 30;
    if (
      bookmarksHeight >=
      parseInt(
        $('#annotationsListNotes')
          .css('height')
          .replace('px', ''),
      )
    ) {
      $('#annotationsListBookmarks').removeClass('size height unit-auto');
    }

    // bookmarkListPopup.$el.show();
    Menu.prototype.show.call(self, arguments).then(function() {
      console.log("BookMarks Menu displayed");
    }).catch(function(error) {
      console.error(error);
      errorMonitor.bugsnag(error);
    });
  });
};

BookmarksMenu.prototype.clearHighlight = function() {
  this.searchResultHighlighter.clear();
};
export default BookmarksMenu;