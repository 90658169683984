import IndexDBStorageManager from './IndexedDBStorage';
import UUID from 'node-uuid';

var objStoreQueues = {};
var storageManager = new IndexDBStorageManager();

var applyMeta = function(document) {
  var meta = getMeta(document);
  var timeNow = Date.now();

  if (!meta.createTime) {
    meta.createTime = timeNow;
  }

  if (typeof meta.deleted === 'undefined') {
    meta.deleted = false;
  }

  meta.updateTime = timeNow;
  meta.revision = UUID.v4();

  document._meta = meta;

  return document;
};

var withMeta = function(document) {
  if (!document._meta) {
    return applyMeta(document);
  }

  return document;
};

var getMeta = function(document) {
  var meta = document._meta || {};

  return {
    createTime: meta.createTime,
    updateTime: meta.updateTime,
    revision: meta.revision,
    deleted: meta.deleted,
  };
};

var determineConflict = function(documentA, documentB) {
  return getMeta(documentA).revision !== getMeta(documentB).revision;
};

var resolveConflictDefaultHandler = function(documentA, documentB) {
  var updateTimeA = new Date(documentA._meta.updateTime);
  var updateTimeB = new Date(documentB._meta.updateTime);

  if (updateTimeA > updateTimeB) {
    return documentA;
  } else {
    return documentB;
  }
};

var conflictResolver = function(resolveConflictFunction, documentA, documentB) {
  if (determineConflict(documentA, documentB)) {
    return resolveConflictFunction(documentA, documentB);
  }

  return documentA;
};

var IDBDataManager = function(objStoreName, resolveConflictHandler) {
  this._objStoreName = objStoreName;
  // Init queue or use existing
  objStoreQueues[objStoreName] = objStoreQueues[objStoreName] || {
    currentTransaction: null,
    queue: [],
  };

  this._context = objStoreQueues[objStoreName];
  this._conflictResolver = conflictResolver.bind(
    this,
    resolveConflictHandler || resolveConflictDefaultHandler,
  );
};

IDBDataManager.prototype._enqueue = function(transaction) {
  var self = this;

  // Add a transaction to the queue, if provided
  if (transaction) {
    this._context.queue.push(transaction);
  } else {
    this._context.currentTransaction = null;
  }

  if (this._context.currentTransaction) {
    return;
  }

  // Process the queue
  var next = this._context.queue.shift();
  if (next) {
    this._context.currentTransaction = next;
    var transactionPromise;

    if (next.put) {
      transactionPromise = storageManager
        .get(this._objStoreName, next.put.keyName, next.put.keyValue)
        .then(function(result) {
          var resolvedDocument = next.put.document;
          if (!resolvedDocument._meta) {
            console.log('Error: putting foreign object into the database');
            console.log('objStoreName: ' + self._objStoreName);
            console.log('keyName: ' + next.put.keyName);
            console.log('keyValue: ' + next.put.keyValue);
            console.log('document:');
            console.log(resolvedDocument);
            throw new Error('Error: putting foreign object into the database');
          }
          if (result) {
            resolvedDocument = self._conflictResolver(resolvedDocument, result);
          }
          return storageManager.put(self._objStoreName, applyMeta(resolvedDocument));
        });
    } else if (next.get) {
      transactionPromise = storageManager
        .get(this._objStoreName, next.get.keyName, next.get.keyValue)
        .then(function(result) {
          if (result === null || typeof result === 'undefined') {
            return withMeta({});
          } else if (result._meta && result._meta.deleted) {
            // In the case of a deleted object, return a new empty object with new create/update time metadata,
            // but preserving the revision of the old object
            var newResult = {};
            newResult._meta = getMeta(result);
            newResult._meta.deleted = false;
            var timeNow = Date.now();
            newResult._meta.createTime = timeNow;
            newResult._meta.updateTime = timeNow;
            return newResult;
          } else {
            return result;
          }
        });
    } else if (next.getAll) {
      transactionPromise = storageManager.getAll(
        this._objStoreName,
        next.getAll.keyName,
        next.getAll.keyValue,
      ).then(function(result){
        return result.filter(function(r) {
            return !(r._meta && r._meta.deleted)
        });
    });
    }

    transactionPromise
      .then(function(result) {
        console.log(result);
        next.resolve(result);
      })
      .catch(function(error) {
        console.error(error);
        next.reject(error);
      })
      .finally(function() {
        // Continue processing
        self._enqueue();
      });
  }
};

IDBDataManager.prototype.get = function(keyName, keyValue) {
  var self = this;
  return new Promise(function(resolve, reject) {
    self._enqueue({
      get: {
        keyName: keyName,
        keyValue: keyValue,
      },
      resolve: resolve,
      reject: reject,
    });
  });
};

IDBDataManager.prototype.getAll = function(keyName, keyValue) {
  var self = this;
  return new Promise(function(resolve, reject) {
    self._enqueue({
      getAll: {
        keyName: keyName,
        keyValue: keyValue,
      },
      resolve: resolve,
      reject: reject,
    });
  });
};

IDBDataManager.prototype.put = function(keyName, keyValue, document) {
  var self = this;
  return new Promise(function(resolve, reject) {
    self._enqueue({
      put: {
        keyName: keyName,
        keyValue: keyValue,
        document: document,
      },
      resolve: resolve,
      reject: reject,
    });
  });
};

IDBDataManager.prototype.remove = function(keyName, keyValue, document) {
  var deletedDocument = withMeta(document);
  deletedDocument._meta.deleted = true;

  return this.put(keyName, keyValue, deletedDocument);
};

export default IDBDataManager;
