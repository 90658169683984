import $ from 'jquery';
import Managers from '../Managers';
import FileSystem from '@axisnow/storage/filesystem';
import utils from '@axisnow/util/utils';
import Promise from 'bluebird';
import '@axisnow/modernizr';

const axis_now_dataloader_config = {
  default_readium_options: {
    jsLibRoot: "/zip-js/",
    openBookOptions: {},
    doNotFollowEpubRedirect: true,
    doNotParseIBooksSpecificXml : true
  },
  default_reader_options: {
    el: "",
    annotationCSSUrl: "",
    mathJaxUrl: ""
  }
}

const RunProcessWithSessionUser = (bookvaultId, process, finishedMessage = "Process With Session User Complete.") => {
  const SetSessionUser = () => {
    return Managers.user.getUserFromBookvaultId(bookvaultId)
      .then( currentUser => {
        window.sessionStorage.setItem("currentUser", currentUser.id);
        return Managers.user.userFromToken();
      })
      .disposer(function() {
        Managers.user.logoutCurrentUser();
        console.log(finishedMessage)
      });
  }

  return Promise.using(Promise.try(SetSessionUser), () => {
    return Promise.try(process).then( results => {
      return results;
    })
  });
}

const AxisNowDataLoader = function(Readium) {

  this.init = function (options) {
    const readiumOptions = $.extend(axis_now_dataloader_config.default_readium_options, options)
    const readerOptions = axis_now_dataloader_config.default_reader_options
    this.readium = new Readium(readiumOptions, readerOptions);
    this.fileSystem = new FileSystem(readiumOptions.jsLibRoot);
    return this
  }

  this.FetchLicense = function(bookvaultId,isbn){
    console.log("AxisNowDataLoader: FetchLicense Start");
    const CallFetchLicense = () => {
      return Managers.license.fetchLicense(isbn)
    }
    return RunProcessWithSessionUser(bookvaultId, CallFetchLicense, "AxisNowDataLoader: FetchLicense Finished")
  }

  this.ImportUser = function(currentUserData){
    console.log("AxisNowDataLoader: ImportUser Start");
    return Managers.user.importUserData(currentUserData).then(function(currentUser){

      // Remove Current User Data from sessionStorage and any global variables that get set with native Readium API. User data does not need to be exposed to AxisNowDataLoader API user
      Managers.user.logoutCurrentUser();

      console.log("AxisNowDataLoader: ImportUser Finish");
      return currentUser;

    }).catch(function(error){

      // Remove Current User Data from sessionStorage and any global variables that get set with native Readium API. User data does not need to be exposed to AxisNowDataLoader API user
      Managers.user.logoutCurrentUser();

      console.log("AxisNowDataLoader: ImportUser Error");
      throw error;

    });
  }

  //TODO Create a method that gets a stored user, also write feature/scenario for test
  this.GetUser = function(){}

  this.ImportEpub = function(bookvaultId, isbn, blob, epubURL){

    //TODO Only accept BLOB to Import and ePub NOT a URL

    //TODO Make sure Blob and ISBN

    console.log("AxisNowDataLoader: ImportEpub Start");
    const self = this;
    const SaveBook = () => {
      const importZipAsBlob = utils.promisify(self.fileSystem.importZipAsBlob);

      return importZipAsBlob(isbn, blob, undefined).bind(self).then( () => {
        const promises = []
        promises.push( this.readium.initPackageDocument(epubURL) )

        return Promise.all(promises)
      }).spread( packageDocument => {
        const promises = []

        promises.push( Managers.bookData.fetchBookCover(isbn) )
        promises.push( packageDocument.getMetadata() )

        return Promise.all(promises)
      }).spread( (_, metaData) => {
        const promises = []

        promises.push( Managers.library.getBookInfo(isbn) )
        promises.push( metaData )

        return Promise.all(promises)
      }).spread( (book, metaData) => {
        book.downloaded = true;
        const promises = []

        promises.push( book )
        promises.push( Managers.library.saveBookInfo(book) )
        promises.push( Managers.bookData.saveMetadata( isbn, JSON.parse(JSON.stringify(metaData))) )

        return Promise.all(promises)
      }).spread( (book, _, __) => {
        return book
      })
    }
    return RunProcessWithSessionUser(bookvaultId, SaveBook, "AxisNowDataLoader: ImportEpub Finish")

  }

  this.putBackButtonUrl = function (url) {
    const backBtn = {
      btnType: 'back',
      url: url
    }
    return Managers.navigation.putButton(backBtn)
  }

  this.getBackButtonUrl = function () {
    return Managers.navigation.getButton('back')
  }
};

export default AxisNowDataLoader;
