import $ from 'jquery';
import _ from 'underscore';
import HighlightHelpers from './helpers';
import HighlightGroup from './models/group';
import rangy from 'rangy';
import Errors from '@axisnow/util/Errors';
class HighlightsController {
  constructor(context, options) {
    this.offsetTopAddition = 0;
    this.offsetLeftAddition = 0;
    this.readerBoundElement = undefined;
    (this.scale = 0), (this.usesScrollOffsets = false);

    this.context = context;

    this.epubCFI = window.ReadiumSDKExport.EpubCfi;
    this.readerBoundElement = this.context.document.documentElement;

    this.highlights = [];
    this.annotationHash = {};

    if (options.getVisibleCfiRangeFn) {
      this.getVisibleCfiRange = options.getVisibleCfiRangeFn;
    }

    if (options.scrollOffsetTechniqueFn) {
      this.usesScrollOffsets = options.scrollOffsetTechniqueFn(this.context.document);
    }

    // inject annotation CSS into iframe
    if (this.context.cssUrl) {
      this._injectExternalStyleSheetIntoIframe(this.context.cssUrl, 'annotationCss');
    }

    if (this.context.themesCssUrl) {
      this._injectExternalStyleSheetIntoIframe(this.context.themesCssUrl,'themesCss');
    }

    // emit an event when user selects some text.
    var that = this;
    this.context.document.addEventListener('mouseup', function(event) {
      var range = that._getCurrentSelectionRange();
      if (range === undefined) {
        return;
      }
      if (range.startOffset - range.endOffset) {
        that.context.manager.trigger('textSelectionEvent', event, range, that.context.iframe);
      }
    });

    if (!rangy.initialized) {
      rangy.init();
    }
  }

  getVisibleCfiRange() {
    // returns {firstVisibleCfi: <>, lastVisibleCfi: <>}
    // implemented in Readium.ReaderView, passed in via options
  }

  // ------------------------------------------------------------------------------------ //
  //  "PUBLIC" METHODS (THE API)                                                          //
  // ------------------------------------------------------------------------------------ //

  redraw(removeOnly) {
    var that = this;

    var paginationOffsets = this._getPaginationOffsets();
    var isVerticalWritingMode = this.context.isVerticalWritingMode;
    var visibleCfiRange = this.getVisibleCfiRange(this.context.idref);

    // Highlights
    _.each(this.highlights, function(highlightGroup) {
      var visible = true;

      if (
        visibleCfiRange &&
        visibleCfiRange.firstVisibleCfi &&
        visibleCfiRange.firstVisibleCfi.contentCFI &&
        visibleCfiRange.lastVisibleCfi &&
        visibleCfiRange.lastVisibleCfi.contentCFI
      ) {
        visible = that._cfiIsBetweenTwoCfis(
          highlightGroup.CFI,
          visibleCfiRange.firstVisibleCfi.contentCFI,
          visibleCfiRange.lastVisibleCfi.contentCFI,
        );
      }
      highlightGroup.visible = visible;
      highlightGroup.resetHighlights(
        that.readerBoundElement,
        paginationOffsets.top,
        paginationOffsets.left,
        removeOnly,
      );
    });
  }

  getHighlight(id) {
    var highlight = this.annotationHash[id];
    if (highlight) {
      return highlight.toInfo();
    } else {
      return undefined;
    }
  }

  getHighlights() {
    var highlights = [];
    _.each(this.highlights, function(highlight) {
      highlights.push(highlight.toInfo());
    });
    return highlights;
  }

  removeHighlight(annotationId) {
    var annotationHash = this.annotationHash;
    var highlights = this.highlights;

    delete annotationHash[annotationId];

    highlights = _.reject(highlights, function(highlightGroup) {
      if (highlightGroup.id == annotationId) {
        highlightGroup.destroyCurrentHighlights();
        return true;
      } else {
        return false;
      }
    });

    this.highlights = highlights;
  }

  removeHighlightsByType(type) {
    var annotationHash = this.annotationHash;
    var highlights = this.highlights;

    // the returned list only contains HLs for which the function returns false
    highlights = _.reject(highlights, function(highlightGroup) {
      if (highlightGroup.type === type) {
        delete annotationHash[highlightGroup.id];
        highlightGroup.destroyCurrentHighlights();
        return true;
      } else {
        return false;
      }
    });

    this.highlights = highlights;
  }

  // generate unique prefix for HL ids
  generateIdPrefix() {
    var idPrefix = 'xxxxxxxx'.replace(/[x]/g, function(c) {
      var r = (Math.random() * 16) | 0;
      return r.toString(16);
    });
    idPrefix += '_';
    return idPrefix;
  }

  // takes partial CFI as parameter
  addHighlight(CFI, id, type, styles, excludeStartEndElements) {
    var range;
    var selectedElements;

    var contentDoc = this.context.document;
    //get transform scale of content document
    var scale = 1.0;
    var matrix = HighlightHelpers.getMatrix($('html', contentDoc));
    if (matrix) {
      scale = HighlightHelpers.getScaleFromMatrix(matrix);
    }

    //create a dummy test div to determine if the browser provides
    // client rectangles that take transform scaling into consideration
    var $div = $(
      '<div style="font-size: 50px; position: absolute; background: red; top:-9001px;">##</div>',
    );
    $(contentDoc.documentElement).append($div);
    range = contentDoc.createRange();
    range.selectNode($div[0]);
    var renderedWidth = this._normalizeRectangle(range.getBoundingClientRect()).width;
    var clientWidth = $div[0].clientWidth;
    $div.remove();
    var renderedVsClientWidthFactor = renderedWidth / clientWidth;
    if (renderedVsClientWidthFactor === 1) {
      //browser doesn't provide scaled client rectangles (firefox)
      scale = 1;
    } else if (this.context.isIe9 || this.context.isIe10) {
      //use the test scale factor as our scale value for IE 9/10
      scale = renderedVsClientWidthFactor;
    }
    this.scale = scale;
    var arbitraryPackageDocCFI = '/99!';
    var fullFakeCFI = 'epubcfi(' + arbitraryPackageDocCFI + CFI + ')';
    var startMarker, endMarker;
    if (this.epubCFI.Interpreter.isRangeCfi(fullFakeCFI)) {
      if (excludeStartEndElements) {
        let markers = this.epubCFI.Interpreter.getRangeTargetElements(
          fullFakeCFI,
          contentDoc,
          ['cfi-marker', 'cfi-blacklist', 'mo-cfi-highlight'],
          [],
          ['MathJax_Message', 'MathJax_SVG_Hidden'],
        );

        var startNode = markers.startElement,
          endNode = markers.endElement;
        range = rangy.createRange(contentDoc);
        range.setStart(startNode, markers.startOffset);
        range.setEnd(endNode, markers.endOffset);
        selectedElements = range.getNodes();
      } else {
        try{
          let markers = this.epubCFI.Interpreter.injectRangeElements(
            fullFakeCFI,
            contentDoc,
            $("<span class='highlight-marker' id='MathJax_Message' position='start'></span>"),
            $("<span class='highlight-marker' id='MathJax_Message' position='end'></span>"),
            ['cfi-marker', 'cfi-blacklist', 'mo-cfi-highlight'],
            [],
            ['MathJax_Message', 'MathJax_SVG_Hidden'],
          );

          var startNode = markers.startElement,
            endNode = markers.endElement;
          startMarker = startNode;
          endMarker = endNode;
          range = rangy.createRange(contentDoc);
          range.setStart(startNode, 0);
          range.setEnd(endNode, 0);
          selectedElements = range.getNodes();
        }catch (ex){
          throw new Errors.ReadiumCFIError('Something went wrong', ex.message, 'Error');
        }
      }
    } else {
      var element = this.epubCFI.Interpreter.getTargetElement(
        fullFakeCFI,
        contentDoc,
        ['cfi-marker', 'cfi-blacklist', 'mo-cfi-highlight'],
        [],
        ['MathJax_Message', 'MathJax_SVG_Hidden'],
      );
      if (element) {
        var startNode = (element[0].data === '' && element[1] && element[1].data !== '') ? element[1] : element[0];
          endNode = element[element.length - 1];
        range = rangy.createRange(contentDoc);
        range.setStart(startNode, 0);
        range.setEnd(endNode, endNode.length);
        selectedElements = range.getNodes();        
      } else {
        selectedElements = [null];
        range = null;
      }
    }

    var paginationOffsets = this._getPaginationOffsets();

    this._addHighlightHelper(
      CFI,
      id,
      type,
      styles,
      selectedElements,
      range,
      startNode,
      endNode,
      paginationOffsets.top,
      paginationOffsets.left,
      startMarker,
      endMarker,
    );

    return {
      selectedElements: selectedElements,
      CFI: CFI,
    };
  }

  focusHighlight(cfi) {
    var highlighted;
    _.each(this.highlights, function(highlightGroup) {
      if (highlightGroup.CFI === cfi) {
        console.log('focus highlight matched!');
        highlighted = highlightGroup.setFocus();
      }
    });
    return highlighted;
  }

  getRangeTargetElements(partialCfi) {
    var arbitraryPackageDocCFI = '/99!';
    var fullFakeCFI = 'epubcfi(' + arbitraryPackageDocCFI + partialCfi + ')';

    return this.epubCFI.Interpreter.getRangeTargetElements(
      fullFakeCFI,
      this.context.document,
      ['cfi-marker', 'cfi-blacklist', 'mo-cfi-highlight'],
      [],
      ['MathJax_Message', 'MathJax_SVG_Hidden'],
    );
  }

  // this returns a partial CFI only!!
  getCurrentSelectionCFI() {
    var currentSelection = this._getCurrentSelectionRange();
    var CFI, selectionInfo;
    if (currentSelection) {
      selectionInfo = this._getSelectionInfo(currentSelection);
      CFI = selectionInfo.CFI;
    }

    return CFI;
  }

  // this returns a partial CFI only!!
  getCurrentSelectionOffsetCFI() {
    var currentSelection = this._getCurrentSelectionRange();

    var CFI;
    if (currentSelection) {
      CFI = this._generateCharOffsetCFI(currentSelection);
    }
    return CFI;
  }

  addSelectionHighlight(id, type, styles, clearSelection) {
    var CFI = this.getCurrentSelectionCFI();
    if (CFI) {
      // if clearSelection is true
      if (clearSelection) {
        var iframeDocument = this.context.document;
        if (iframeDocument.getSelection) {
          var currentSelection = iframeDocument.getSelection();
          currentSelection.collapseToStart();
        }
      }
      return this.addHighlight(CFI, id, type, styles);
    } else {
      throw new Error('Nothing selected');
    }
  }

  updateAnnotation(id, type, styles) {
    var annotationViews = this.annotationHash[id];
    if (annotationViews) {
      annotationViews.update(type, styles);
    }
    return annotationViews;
  }

  replaceAnnotation(id, cfi, type, styles) {
    var annotationViews = this.annotationHash[id];
    if (annotationViews) {
      // remove an existing annotatio
      this.removeHighlight(id);

      // create a new HL
      this.addHighlight(cfi, id, type, styles);
    }
    return annotationViews;
  }

  updateAnnotationView(id, styles) {
    var annotationViews = this.annotationHash[id];
    if (annotationViews) {
      annotationViews.setStyles(styles);
    }
    return annotationViews;
  }

  setAnnotationViewState(id, state, value) {
    var annotationViews = this.annotationHash[id];
    if (annotationViews) {
      annotationViews.setState(state, value);
    }
    return annotationViews;
  }

  setAnnotationViewStateForAll(state, value) {
    var annotationViews = this.annotationHash;
    _.each(annotationViews, function(annotationView) {
      annotationView.setState(state, value);
    });
  }

  // ------------------------------------------------------------------------------------ //
  //  "PRIVATE" HELPERS                                                                   //
  // ------------------------------------------------------------------------------------ //

  _contentCfiComparator(cfiA, cfiB) {
    return this.epubCFI.Interpreter.compareCFIs(
      'epubcfi(/99!' + cfiA + ')',
      'epubcfi(/99!' + cfiB + ')',
    );
  }

  // determine if a given Cfi falls between two other cfis.2
  _cfiIsBetweenTwoCfis(cfi, firstVisibleCfi, lastVisibleCfi) {
    if (!firstVisibleCfi || !lastVisibleCfi) {
      return null;
    }

    var first = this._contentCfiComparator(cfi, firstVisibleCfi);
    var second = this._contentCfiComparator(cfi, lastVisibleCfi);

    const wholeBetween = first[0] >= 0 && second[1] <= 0;
    const startBetween = first[0] >= 0 && second[0] <= 0;
    const endBetween = first[1] >= 0 && second[1] <= 0;
    const wholePageHighlight = first[0] <= 0 && second[1] >= 0;

    return wholeBetween || startBetween || endBetween || wholePageHighlight;
  }

  _addHighlightHelper(
    CFI,
    annotationId,
    type,
    styles,
    highlightedNodes,
    range,
    startNode,
    endNode,
    offsetTop,
    offsetLeft,
    startMarker,
    endMarker,
  ) {
    if (!offsetTop) {
      offsetTop = this.offsetTopAddition;
    }
    if (!offsetLeft) {
      offsetLeft = this.offsetLeftAddition;
    }

    var visible;
    // check if the options specify lastVisibleCfi/firstVisibleCfi. If they don't fall back to displaying the highlights anyway.
    var visibleCfiRange = this.getVisibleCfiRange(this.context.idref);
    if (
      !this.context.isFixedLayout &&
      visibleCfiRange &&
      visibleCfiRange.firstVisibleCfi &&
      visibleCfiRange.firstVisibleCfi.contentCFI &&
      visibleCfiRange.lastVisibleCfi &&
      visibleCfiRange.lastVisibleCfi.contentCFI
    ) {
      visible = this._cfiIsBetweenTwoCfis(
        CFI,
        visibleCfiRange.firstVisibleCfi.contentCFI,
        visibleCfiRange.lastVisibleCfi.contentCFI,
      );
    } else {
      visible = true;
    }

    annotationId = annotationId.toString();
    if (this.annotationHash[annotationId]) {
      throw new Error('That annotation id already exists; annotation not added');
    }

    var highlightGroup = new HighlightGroup(this.context, {
      CFI: CFI,
      selectedNodes: highlightedNodes,
      offsetTopAddition: offsetTop,
      offsetLeftAddition: offsetLeft,
      usesScrollOffsets: this.usesScrollOffsets,
      styles: styles,
      id: annotationId,
      type: type,
      scale: this.scale,
      selectionText: range ? range.toString() : '',
      visible: visible,
      rangeInfo: range
        ? {
            startNode: startNode,
            startOffset: range.startOffset,
            endNode: endNode,
            endOffset: range.endOffset,
          }
        : null,
      startMarker: startMarker,
      endMarker: endMarker,
    });

    this.annotationHash[annotationId] = highlightGroup;
    this.highlights.push(highlightGroup);

    highlightGroup.renderHighlights(this.readerBoundElement);
  }

  _normalizeRectangle(rect) {
    return {
      left: rect.left,
      right: rect.right,
      top: rect.top,
      bottom: rect.bottom,
      width: rect.right - rect.left,
      height: rect.bottom - rect.top,
    };
  }

  _getSelectionInfo(selectedRange, elementType) {
    // Generate CFI for selected text
    var CFI = this._generateRangeCFI(selectedRange);
    var intervalState = {
      startElementFound: false,
      endElementFound: false,
    };
    var selectedElements = [];

    if (!elementType) {
      elementType = ['text'];
    }

    this._findSelectedElements(
      selectedRange.commonAncestorContainer,
      selectedRange.startContainer,
      selectedRange.endContainer,
      intervalState,
      selectedElements,
      elementType,
    );

    // Return a list of selected text nodes and the CFI
    return {
      CFI: CFI,
      selectedElements: selectedElements,
    };
  }

  _generateRangeCFI(selectedRange) {
    var startNode = selectedRange.startContainer;
    var endNode = selectedRange.endContainer;
    var commonAncestor = selectedRange.commonAncestorContainer;
    var startOffset;
    var endOffset;
    var rangeCFIComponent;

    startOffset = selectedRange.startOffset;
    endOffset = selectedRange.endOffset;

    rangeCFIComponent = this.epubCFI.Generator.generateRangeComponent(
      startNode,
      startOffset,
      endNode,
      endOffset,
      ['cfi-marker', 'cfi-blacklist', 'mo-cfi-highlight'],
      [],
      ['MathJax_Message', 'MathJax_SVG_Hidden'],
    );
    return rangeCFIComponent;
  }

  _generateCharOffsetCFI(selectedRange) {
    // Character offset
    var startNode = selectedRange.startContainer;
    var startOffset = selectedRange.startOffset;
    var charOffsetCFI;

    if (startNode.nodeType === Node.TEXT_NODE) {
      charOffsetCFI = this.epubCFI.Generator.generateCharacterOffsetCFIComponent(
        startNode,
        startOffset,
        ['cfi-marker', 'cfi-blacklist', 'mo-cfi-highlight'],
        [],
        ['MathJax_Message', 'MathJax_SVG_Hidden'],
      );
    }
    return charOffsetCFI;
  }

  // REFACTORING CANDIDATE: Convert this to jquery
  _findSelectedElements(
    currElement,
    startElement,
    endElement,
    intervalState,
    selectedElements,
    elementTypes,
  ) {
    if (currElement === startElement) {
      intervalState.startElementFound = true;
    }

    if (intervalState.startElementFound === true) {
      this._addElement(currElement, selectedElements, elementTypes);
    }

    if (currElement === endElement) {
      intervalState.endElementFound = true;
      return;
    }

    if (currElement.firstChild) {
      this._findSelectedElements(
        currElement.firstChild,
        startElement,
        endElement,
        intervalState,
        selectedElements,
        elementTypes,
      );
      if (intervalState.endElementFound) {
        return;
      }
    }

    if (currElement.nextSibling) {
      this._findSelectedElements(
        currElement.nextSibling,
        startElement,
        endElement,
        intervalState,
        selectedElements,
        elementTypes,
      );
      if (intervalState.endElementFound) {
        return;
      }
    }
  }

  _addElement(currElement, selectedElements, elementTypes) {
    // Check if the node is one of the types
    _.each(elementTypes, function(elementType) {
      if (elementType === 'text') {
        if (currElement.nodeType === Node.TEXT_NODE) {
          selectedElements.push(currElement);
        }
      } else {
        if ($(currElement).is(elementType)) {
          selectedElements.push(currElement);
        }
      }
    });
  }

  // Rationale: This is a cross-browser method to get the currently selected text
  _getCurrentSelectionRange() {
    var currentSelection;
    var iframeDocument = this.context.document;
    if (iframeDocument.getSelection) {
      currentSelection = iframeDocument.getSelection();
      if (!currentSelection || currentSelection.rangeCount === 0) {
        return undefined;
      }

      var range = currentSelection.getRangeAt(0);

      if (range.toString() !== '') {
        return range;
      } else {
        return undefined;
      }
    } else if (iframeDocument.selection) {
      return iframeDocument.selection.createRange();
    } else {
      return undefined;
    }
  }

  _getPaginationOffsets() {
    if (!this.context.paginationInfo) {
      return {
        top: 0,
        left: 0,
      };
    }

    var offset;
    if (this.context.isRTL && !this.context.isVerticalWritingMode) {
      offset = -this.context.paginationInfo.pageOffset;
    } else {
      offset = this.context.paginationInfo.pageOffset;
    }

    if (this.context.isVerticalWritingMode) {
      return {
        top: offset,
        left: 0,
      };
    }
    return {
      top: 0,
      left: offset,
    };
  }

  _injectExternalStyleSheetIntoIframe(styleSheetURL, styleSheetId) {
    var doc = this.context.document;
    setTimeout(function() {
      var $contentDocHead = $('head', doc);
      $contentDocHead.append(
        $('<link/>', {
          rel: 'stylesheet',
          href: styleSheetURL,
          type: 'text/css',
          id: styleSheetId,
        }),
      );
    }, 0);
  }
}

export default HighlightsController;
