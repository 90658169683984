import * as UnibabelIndex from 'unibabel/index';
import 'bluebird';
import 'webcrypto-shim';

var NEEDS_IV = ['AES-CBC'];
var _ivLenHeaderSize = 4;
var _indentifiers;

const Unibabel = UnibabelIndex.Unibabel;

var Encryption = function() {
  this.utils = Unibabel;
};

//Internal Functions
function separateIvFromData(arr) {
  var ivLen = arr.subarray(0, _ivLenHeaderSize);
  console.log('ivlen:' + new DataView(ivLen.buffer).getUint32(0, true));
  ivLen = new DataView(ivLen.buffer).getUint32(0, true);
  console.log(ivLen);
  var iv = arr.subarray(_ivLenHeaderSize, ivLen + _ivLenHeaderSize);
  var data = arr.subarray(ivLen + _ivLenHeaderSize);
  return { iv: iv, data: data };
}

//Public API
Encryption.prototype.getRSAOAEP = function(padding, modulusLength, publicExponent) {
  modulusLength = modulusLength || 2048;
  padding = padding || 'SHA-1';
  publicExponent = publicExponent || new Uint8Array([0x01, 0x00, 0x01]);
  return {
    name: 'RSA-OAEP',
    modulusLength: modulusLength,
    publicExponent: publicExponent,
    hash: { name: padding },
  };
};

Encryption.prototype.getAESCBC = function(length) {
  length = length || 256;
  return {
    name: 'AES-CBC',
    length: length,
  };
};

//Gets this crypto algorithm settings from the XML encryption Identifier
Encryption.prototype.getAlgorithm = function(identifier) {
  var indentifiers = {
    'http://www.w3.org/2009/xmlenc11#mgf1sha1': this.getRSAOAEP('SHA-1'),
    'http://www.w3.org/2009/xmlenc11#mgf1sha256': this.getRSAOAEP('SHA-256'),
    'http://www.w3.org/2001/04/xmlenc#aes128-cbc': this.getAESCBC(128),
    'http://www.w3.org/2001/04/xmlenc#aes256-cbc': this.getAESCBC(256),
  };
  return indentifiers[identifier];
};

Encryption.prototype.decrypt = function(algo, key, data, wrapperKeyAlgo, wrapperKey) {
  if (NEEDS_IV.indexOf(algo.name) != -1) {
    var parts = separateIvFromData(data);
    algo.iv = parts.iv;
    data = parts.data;
  }
  var self = this;
  return new Promise(function(resolve, reject) {
    if (typeof wrapperKeyAlgo !== 'undefined' || typeof wrapperKey !== 'undefined') {
      if (typeof wrapperKey.privateKey !== 'undefined') {
        wrapperKey = wrapperKey.privateKey;
      }
      return self.unwrapKey(algo, key, wrapperKeyAlgo, wrapperKey);
    } else {
      resolve(key);
    }
  }).then(function(key) {
    return window.crypto.subtle.decrypt(
      algo,
      key, //from generateKey or importKey above
      data, //ArrayBuffer of the data
    );
  });
};

Encryption.prototype.unwrapKey = function(
  algo,
  key,
  wrapperKeyAlgo,
  wrapperKey,
  extractable,
  keyUsages,
) {
  if (typeof extractable == 'undefined') {
    extractable = false;
  }
  keyUsages = keyUsages || ['encrypt', 'decrypt'];
  return window.crypto.subtle.unwrapKey(
    'raw', //the import format, must be "raw" (only available sometimes)
    key, //the key you want to unwrap
    wrapperKey, //the private key with "unwrapKey" usage flag
    wrapperKeyAlgo,
    algo,
    extractable, //whether the key is extractable (i.e. can be used in exportKey)
    keyUsages, //the usages you want the unwrapped key to have
  );
};

Encryption.prototype.exportKey = function(key, format) {
  format = format || 'jwk';
  return window.crypto.subtle
    .exportKey(
      format, //can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
      key, //can be a publicKey or privateKey, as long as extractable was true
    )
    .then(function(keydata) {
      //returns the exported key data
      console.log(keydata);

      //var pemPublicKey = convertBinaryToPem(keydata, "PUBLIC KEY");
      console.log(Unibabel.bufferToBase64(Unibabel.strToUtf8Arr(keydata)));

      return keydata;
    });
};

Encryption.prototype.generateKey = function(algo, extractable, keyUsages) {
  if (typeof extractable == 'undefined') {
    extractable = false;
  }
  keyUsages = keyUsages || ['encrypt', 'decrypt'];
  return window.crypto.subtle.generateKey(algo, extractable, keyUsages);
};
export default Encryption;
