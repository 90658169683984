var libraryDataStoreDefUpdate = {
    name: 'LibraryDataStore',
    indexes: [
      {
        name: 'isbn',
        keyPath: 'isbn',
        optionalParameters: { unique: false },
      },
    ],
  };
  
  var MigrationManager11To12 = function(db, transaction) {
    this.db = db;
    this.transaction = transaction;
  }
  
  MigrationManager11To12.prototype.updateObjectStore = function(storeDefUpdate) {
    var objectStore = this.transaction.objectStore(storeDefUpdate.name);
    storeDefUpdate.indexes.forEach(function(element) {
      objectStore.deleteIndex(element.name);
      objectStore.createIndex(element.name, element.keyPath, element.optionalParameters)
    });
  };
  
  MigrationManager11To12.prototype.migrate = function() {
    this.updateObjectStore(libraryDataStoreDefUpdate);
  };
  
  export default MigrationManager11To12;