import $ from 'jquery';
import Strings from '../../i18n/Strings';
import Managers from '@axisnow/data/Managers';
import PopupFactory from '../../popup/PopupFactory';
import NotesMenu from '../../templates/notes-menu.hbs';
import NotesEditMenu from '../../templates/notes-edit-menu.hbs';
import AnnotationItem from '../../templates/annotation-item.hbs';
import AnnotationItemEdit from '../../templates/annotation-item-edit.hbs';
import AnnotationsMenu from '../../menus/navbar/AnnotationsMenu';
import Helpers from '../../EpubReaderHelpers';
import Feature from '@axisnow/data/app-permission/Feature';
import AccessibilityManager from '../../accessibility/AcessibilityManager';
import Menu from '../../menus/Menu';
import errorMonitor from '../../ErrorMonitor';
import moment from 'moment-timezone';

//Confirmation modal
var popupModal = PopupFactory.create(PopupFactory.TYPE.MODAL);
var featureManager = Managers.feature;
//Highlights and Notes
var annotationsListPopup = PopupFactory.create(PopupFactory.TYPE.NAVBAR_MENU, 'Notes/Highlights', {
  id: 'annotationsListNotes',
  cssClass: 'app-notes-menu',
});

// Adding attributes for WA
annotationsListPopup.$el.attr('role', 'dialog').attr('aria-label', Strings.notes_highlights);

annotationsListPopup.clearContainerLayouts();
var $containerLayoutContent = $(NotesMenu({}));
var $containerLayout = annotationsListPopup.addContainerLayout($containerLayoutContent);
var $containerLayoutContent2 = $(NotesEditMenu({}));
var $containerLayout2 = annotationsListPopup.addContainerLayout($containerLayoutContent2);

var sortedAnnotationsArray = [];

var HighlightsNotesMenu = function() {
  AnnotationsMenu.apply(this, arguments);
  this.popup(annotationsListPopup);
};

HighlightsNotesMenu.prototype = Object.create(AnnotationsMenu.prototype);

featureManager.feature(
  new Feature({
    name: 'Notes/Highlights-navbar',
    enable: function() {
      console.log('Notes/highlightes feature enabled');
      //$('.app-word-reference .icon-edit,.icon-add-note').closest('button').show();
      $('header#app-navbar')
        .find('#btnToggleNotes')
        .show();
    },
    disable: function() {
      console.log('Notes/highlightes feature disabled');
      //$('.app-word-reference .icon-edit,.icon-add-note').closest('button').hide();
      //$('.app-word-reference').find('.toolbar').css('min-width', '0px');
      $('header#app-navbar')
        .find('#btnToggleNotes')
        .hide();
    },
    disableFeature: false,
  }),
);

HighlightsNotesMenu.prototype.show = function() {
  console.log('HighlightsNotesMenu.prototype.show', arguments);
  var self = this;

  /*********** Rendering Sorted Annotations ***********/
  function renderSortedAnnotations(sortedAnnotationsArray, $containerLayoutContent) {
    var $listGrp = $containerLayoutContent.find('.list-group');
    $listGrp.empty();

    sortedAnnotationsArray.forEach(function(annotation) {
      if (annotation.type != 'Bookmark' && !annotation.deleteAnnotation) {
        var obj = { annotation: annotation };
        //Bug230502 Tooltip is not available for the edit and delete icons
        obj.editBtnClass = 'icon-edit';
        obj.editBtnClassAriaLabel = 'edit';
        obj.editBtnTitle='Edit';
        if (obj.annotation.notetext === '') {
          obj.editBtnClass = 'icon-add';
          obj.editBtnClassAriaLabel = 'add';
          obj.editBtnTitle='Add';
        }
        var $item = $(AnnotationItem(obj));
        $item.find('.edit').on('click', function(e) {
          e.stopPropagation();
          e.preventDefault();
          var locObj = Helpers.locObjFromFullCFI(
            self.readium.packageDocument,
            annotation.location,
            false,
          );
          editAnnotationLayout(
            self.readium,
            self.usageManager,
            self.isbn,
            $containerLayoutContent2,
            locObj.idref,
            annotation,
          );
          $containerLayout.hide();
          $containerLayout2.show();
          $('#addNote').focus();
          // setTimeout(function(){
          //     AccessibilityManager.updateTabOrder("notes_highlight_popup", function() {
          //         AccessibilityManager.setFocus("#annotationsListNotes .popup-body .container-layout:eq(1) .option-selector input[type='radio']:checked")
          //     })
          // }, 500)
        });

        $item.find('.delete').on('click', function(e) {
          e.stopPropagation();
          e.preventDefault();

          var highlightsNotesMenu = new HighlightsNotesMenu();
          self.deleteItem(annotation, self.show);
        });

        //render the create date using the correct time zone offset
        $item.data(annotation);
        $item
          .find('.time-stamp')
          .text(self.formatDate(annotation.createdate))
          .attr('aria-label', self.formatDate(annotation.createdate));
        $item.find('blockQuote').on('click', function(e) {
          Menu.prototype.hide.call(self);
          e.preventDefault();
          var data = $($item.find('blockQuote').closest('li')).data();
          if (self.isAudiobook()) {
            self.navigateToAnnotation(data);
          } else {
            var locObj = self.getLocation(data.location);
            $(document).trigger('REQUEST_FOCUS', [
              { idref: locObj.idref, cfi: locObj.contentCFI, type: '' },
            ]);
            self.readium.reader.openSpineItemElementCfi(locObj.idref, locObj.contentCFI);
          }
        });
        if (obj.annotation.notetext === '') {
          $('<p>Add Note...</p>').insertAfter($item.find('.edit'));
        }
        $item.find('p').on('click', function() {
          $item.find('.edit').click();
        });

        $listGrp.append($item);
      }
    });
  }

  //****************Edit Annotations *************************/
  function editAnnotationLayout(
    readium,
    usageManager,
    isbn,
    $containerLayoutContent,
    idref,
    annotation,
  ) {
    var $listGrp = $containerLayoutContent.find('.list-group-item');
    var html = AnnotationItemEdit(annotation);
    $listGrp.html(html);

    //automatically select note colour based off of highlight colour
    var highlightColor = annotation.color;
    $listGrp.find('input[value = "' + highlightColor + '"]')[0].checked = true;

    var blockquote = $listGrp.find('blockquote');

    $listGrp
      .find('.edit-options')
      .find('.option-selector')
      .find('.highlight-color')
      .on('click', function(e) {
        updateOptionSelector(e);
      });

    $listGrp
      .find('.edit-options')
      .find('.option-selector')
      .find('.highlight-color')
      .on('keyup', function(e) {
        if (e.key.includes('Arrow')) {
          updateOptionSelector(e);
        }
      });

    $listGrp.find('button.cancel').on('click', function(e) {
      e.preventDefault();
      readium.reader.plugins.highlights.updateAnnotation(annotation.location, highlightColor, null);
      $containerLayoutContent
        .parent()
        .parent()
        .find('.container-layout')
        .show();
      $containerLayout2.hide();
      // setTimeout(function(){
      //     AccessibilityManager.updateTabOrder("notes_highlight_popup")
      // }, 1000)
    });

    annotationsListPopup.$el.find("input[name='selected-note-color']").each(function() {
      $(this).attr('aria-checked', 'false');
      $(this).attr('aria-label', $(this).val());
    });
    annotationsListPopup.$el
      .find("input[name='selected-note-color']:checked")
      .attr('aria-checked', 'true');

    $('#addNote').on('focus', function(e) {
      $(this).attr('placeholder', null);
      $('label.form-control-placeholder').css('visibility', 'visible');
    });

    $('#addNote').on('focusout', function(e) {
      e.preventDefault();
      $('label.form-control-placeholder').css('visibility', 'hidden');
      $(this).attr('placeholder', 'Add Note...');
      return false;
    });

    $listGrp.find('button.save').on('click', function(e) {
      e.preventDefault();
      var notes = $containerLayoutContent.find('.form-control').val();
      annotation.type = 'Note';
      annotation.page = '';
      annotation.notetext = notes;
      annotation.createdate = self.annotationUtils.getCurrentDate();
      annotation.updateAnnotation = 'true';
      annotation.color = $containerLayoutContent
        .find("input[name='selected-note-color']:checked")
        .val();

      self.annotationUtils
        .updateAnnotation(idref, annotation, isbn)
        .then(function() {
          console.log('Updating Annotations online');
          self.show(readium, usageManager, isbn);
        })
        .catch(function(error) {
          console.log('Updating Annotations offline :  error');
          self.show(readium, usageManager, isbn);
        });

      setTimeout(function() {
        $containerLayout2.hide();
        // AccessibilityManager.updateTabOrder("notes_highlight_popup")
      }, 1000);
    });
    function updateOptionSelector(e) {
      annotationsListPopup.$el.find("input[name='selected-note-color']").each(function() {
        $(this).attr('aria-checked', 'false');
      });
      $(e.currentTarget)
        .find('input')
        .attr('aria-checked', 'true');

      var selectedColor = $containerLayoutContent
        .find("input[name='selected-note-color']:checked")
        .val();
      if (!selectedColor) {
        selectedColor = e.currentTarget.classList[1];
        $listGrp.find('input[value = "' + selectedColor + '"]')[0].checked = true;
      }

      blockquote.removeClass();
      blockquote.addClass('highlight-color ' + selectedColor);
      readium.reader.plugins.highlights.updateAnnotation(annotation.location, selectedColor, null);
    }
  }

  //Reset all container layouts' visibility
  annotationsListPopup.$el.find('.container-layout').show();

  return self.getBookUsage().then(function(annotUsage) {
    var annotationsUsage = annotUsage;
    var sortBy = 'date';
    var ascending = true;
    var type = 'Highlight-Note';
    var $sortDate = $('#sort-date');
    var $sortColor = $('#sort-color');

    var sortUIWA = function(sortBy, asc) {
      if (!sortBy || typeof asc != 'boolean') return;
      var label = asc ? 'Ascending' : 'Descending';
      if (sortBy == 'color') {
        $sortColor
          .find('a')
          .attr('aria-pressed', 'true')
          .attr('aria-label', 'Sort Color ' + label);
        $sortDate
          .find('a')
          .attr('aria-pressed', 'false')
          .attr('aria-label', 'Sort Date');
      }
      if (sortBy == 'date') {
        $sortDate
          .find('a')
          .attr('aria-pressed', 'true')
          .attr('aria-label', 'Sort Date ' + label);
        $sortColor
          .find('a')
          .attr('aria-pressed', 'false')
          .attr('aria-label', 'Sort Color');
      }
    };
    sortUIWA(sortBy, ascending);

    $sortDate.addClass('active');
    $sortColor.removeClass();

    $sortColor.off('click').on('click', function(e) {
      e.preventDefault();

      $sortDate.removeClass();
      $sortColor.addClass('active');

      if ($sortColor.hasClass('desc')) {
        ascending = true;
      } else {
        ascending = false;
      }
      $sortColor.toggleClass('desc');
      sortBy = 'color';
      sortedAnnotationsArray = annotationsUsage.getSorted(
        type,
        annotationsUsage.sortBy.COLOR,
        ascending,
      );
      renderSortedAnnotations(sortedAnnotationsArray, $containerLayoutContent);
      sortUIWA(sortBy, ascending);
     // AccessibilityManager.updateTabOrder("notes_highlight_popup", function() {
      //     AccessibilityManager.setFocus("#annotationsListNotes .popup-body .container-layout:eq(0) #sort-color>a")
      // });
    });

    $sortDate.off('click').on('click', function(e) {
      e.preventDefault();

      $sortColor.removeClass();
      $sortDate.addClass('active');

      if ($sortDate.hasClass('desc')) {
        ascending = true;
      } else {
        ascending = false;
      }
      $sortDate.toggleClass('desc');
      sortBy = 'date';
      sortedAnnotationsArray = annotationsUsage.getSorted(
        type,
        annotationsUsage.sortBy.DATE,
        ascending,
      );
      renderSortedAnnotations(sortedAnnotationsArray, $containerLayoutContent);
      sortUIWA(sortBy, ascending);
     // AccessibilityManager.updateTabOrder("notes_highlight_popup", function() {
      //     AccessibilityManager.setFocus("#annotationsListNotes .popup-body .container-layout:eq(0) #sort-date>a")
      // });
    });

    sortedAnnotationsArray = annotationsUsage.getSorted(
      type,
      annotationsUsage.sortBy.DATE,
      ascending,
    );
    renderSortedAnnotations(sortedAnnotationsArray, $containerLayoutContent);

    // annotationsListPopup.$el.show();
    Menu.prototype.show.call(self, arguments).then(function() {
      console.log("Highlight Notes Menu displayed");
    }).catch(function(error) {
      console.error(error);
      errorMonitor.bugsnag(error);
    });

    // setTimeout(function(){
    //     AccessibilityManager.setTabGroup("notes_highlight_popup");
    //     AccessibilityManager.updateTabOrder("notes_highlight_popup");
    // }, 500)
  });
};
export default HighlightsNotesMenu;
