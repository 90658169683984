var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <li class=\"tts-speeds\">\n                                <label id=\"rdoTTSSpeed"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":14,"column":54},"end":{"line":14,"column":62}}}) : helper)))
    + "Label\" data-value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":14,"column":81},"end":{"line":14,"column":90}}}) : helper)))
    + "\" role=\"button\" for=\"rdoTTSSpeed"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":14,"column":122},"end":{"line":14,"column":130}}}) : helper)))
    + "\" aria-label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":14,"column":144},"end":{"line":14,"column":152}}}) : helper)))
    + "\">\n                                    <span aria-hidden=\"true\" class=\"sr-only\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":15,"column":77},"end":{"line":15,"column":85}}}) : helper)))
    + "</span>\n                                    <input aria-hidden=\"true\" id=\"rdoTTSSpeed"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":16,"column":77},"end":{"line":16,"column":85}}}) : helper)))
    + "\" name=\"tts-speed-settings\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":16,"column":120},"end":{"line":16,"column":129}}}) : helper)))
    + "\"\n                                           class=\"tts-speed-radio\" type=\"radio\">\n                                    <span class=\"option-type option-text\">\n                                        <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":19,"column":46},"end":{"line":19,"column":54}}}) : helper)))
    + "</span>\n                                    </span>\n                                </label>\n                            </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li id=\"tts-highlight-mode-"
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":47,"column":35},"end":{"line":47,"column":44}}}) : helper)))
    + "\" class=\"tts-highlight-mode\"><a role=\"button\" class=\"highlight-a\" href=\"\" aria-label=\"Words\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":47,"column":137},"end":{"line":47,"column":145}}}) : helper)))
    + "</a></li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <li class=\"tts-voices tts-word-capable-"
    + alias4(((helper = (helper = lookupProperty(helpers,"localService") || (depth0 != null ? lookupProperty(depth0,"localService") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"localService","hash":{},"data":data,"loc":{"start":{"line":64,"column":67},"end":{"line":64,"column":83}}}) : helper)))
    + "\">\n                                <label id=\"tts-voice-id-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":65,"column":56},"end":{"line":65,"column":62}}}) : helper)))
    + "\" data-value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":65,"column":76},"end":{"line":65,"column":84}}}) : helper)))
    + "\" aria-label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":65,"column":98},"end":{"line":65,"column":106}}}) : helper)))
    + "\" class=\"tts-voice-label\">\n                                    <span aria-hidden=\"true\" class=\"sr-only\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":66,"column":77},"end":{"line":66,"column":85}}}) : helper)))
    + "</span>\n                                    <input type=\"radio\" aria-hidden=\"true\" name=\"tts-voice-settings\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":67,"column":108},"end":{"line":67,"column":116}}}) : helper)))
    + "\"\n                                            class=\"tts-voice-radio\" type=\"radio\">\n                                    <span class=\"option-type option-text\">\n                                        <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":70,"column":46},"end":{"line":70,"column":54}}}) : helper)))
    + "</span>\n                                    </span>\n                                </label>\n                            </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<!-- speeds -->\n<div class=\"pane\">\n    <div class=\"pane-body\">\n        <div class=\"list-group no-list-hover\">\n            <div class=\"list-group-item layout-options tts-speed-list\">\n                <h2 class=\"text-uppercase tts-speeds-header-valign\">\n                    Speeds\n                </h2>\n                <div class=\"edit-options\">\n                    <div class=\"speed-selector option-selector option-selector-lg\">\n                        <ul role=\"radiogroup\" aria-label=\"Speeds\">\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"speeds") || (depth0 != null ? lookupProperty(depth0,"speeds") : depth0)) != null ? helper : alias2),(options={"name":"speeds","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":28},"end":{"line":23,"column":39}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"speeds")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "                        </ul>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div> <!-- speeds -->\n\n<!-- highlighting -->\n<div class=\"pane\">\n    <div class=\"pane-body\">\n        <div class=\"list-group no-list-hover\">\n            <div class=\"list-group-item layout-options\">\n                <h2 class=\"text-uppercase\">\n                    Highlight Options\n                </h2>\n            </div>\n        </div>\n    </div>\n</div>\n<div class=\"pane header-pane highlight-Options tts-voice-options-valign\">\n    <ul class=\"nav nav-pills  highlighting-selector\" aria-label=\"Highlighting Options\">\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"highlighting") || (depth0 != null ? lookupProperty(depth0,"highlighting") : depth0)) != null ? helper : alias2),(options={"name":"highlighting","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":8},"end":{"line":48,"column":25}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"highlighting")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "    </ul>\n</div> <!-- highlighting -->\n\n<!-- voices -->\n<div class=\"pane tts-voice-list-valign\">\n    <div class=\"pane-body\">\n        <div class=\"list-group no-list-hover top-alignment\">\n            <div class=\"list-group-item layout-options tts-voices-list\">\n                <h2 class=\"text-uppercase tts-speeds-header-valign\">\n                    Voices\n                </h2>\n                <div class=\"edit-options\">\n                    <div class=\"voice-selector option-selector-lg\">\n                        <ul class=\"tts-voices-outer\" role=\"radiogroup\" aria-label=\"Types\">\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"voices") || (depth0 != null ? lookupProperty(depth0,"voices") : depth0)) != null ? helper : alias2),(options={"name":"voices","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":28},"end":{"line":74,"column":39}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"voices")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                        </ul>\n                    </div>\n                </div>\n            </div>                                 \n        </div>\n    </div>\n</div> <!-- voices --> \n";
},"useData":true});