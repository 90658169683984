import PageUsage from './PageUsage';

function ComicBookPageUsage(isbn, uuid, eventEmitter) {
  PageUsage.apply(this, arguments);
}

ComicBookPageUsage.usageName = 'ComicBookPageUsage';

ComicBookPageUsage.prototype.update = function(pageUsage) {
  console.log("ComicBookPageUsage update:", pageUsage);
  if (pageUsage.isbn == this.isbn && pageUsage.uuid == this.uuid) {
    if (!(pageUsage instanceof ComicBookPageUsage)) {
      pageUsage = $.extend(
        true,
        new ComicBookPageUsage(pageUsage.isbn, pageUsage.uuid, this._eventEmitter),
        pageUsage,
      );
    }
    this._eventEmitter.emit('new:updated', pageUsage);
  } else {
    throw new Errors.BadData("The ISBN and/or UUID values don't match.");
  }
  return pageUsage;
};

export default ComicBookPageUsage;
