import $ from 'jquery';
import utils from '@axisnow/util/utils';
import KeyStore from '@axisnow/crypto/keystore';
import License from './License';
import Encryption from '@axisnow/crypto/encryption';
import Errors from '@axisnow/util/Errors';
import uuid from 'node-uuid';

var LicenseDocumentFetcher = function(userManager) {
  var _encryption = new Encryption();
  var _keyStore = new KeyStore();

  //Internal Functions
  var getLicenseUrl = function(params) {
    var url = utils.getRemoteServerOrigin();
    try {
      var path = utils
        .restFullPath()
        .add(params.bookvaultId)
        .add(params.deviceId)
        .add(params.clientip)
        .add(params.isbn)
        .add(params.modulus)
        .add(params.exponent);

        if (params.productTypeId) {
          path.add(params.productTypeId);
        }

      //url = new URI(relativeUrl).absoluteTo(url).search('').hash('').toString();
      url.path('license/' + path);
      url = url.toString();
    } catch (err) {
      console.error(err);
      console.log(url);
    }

    return url; // + (url.charAt(url.length-1) == '/' ? "" : "/")
  };

  function generateRSAKey(name) {
    return _encryption
      .generateKey(_encryption.getRSAOAEP(), false, ['wrapKey', 'unwrapKey', 'encrypt', 'decrypt'])
      .then(function(key) {
        return _keyStore.saveKey(key.publicKey, key.privateKey, name);
      })
      .then(function(key) {
        return key;
      });
  }

  function fetchLicenseFromRemote(settings, retryCount) {
    var params = settings.params;
    var key = settings.key;
    retryCount = retryCount || 0;
    var xhr = $.ajax({
      url: getLicenseUrl(params),
    });
    return utils
      .deferredToPromise(xhr)
      .then(function(data) {
        return new License(data, key);
      })
      .catch(function(errorJson) {
        errorJson = errorJson || { ReturnCode: -1 };
        //TODO do a valid check to see if the book is expired
        switch (errorJson.ReturnCode) {
          case 9200:
            throw new Errors.BookOwnershipNotFound(errorJson);
          case 9210:
            throw new Errors.LicenseExpired(errorJson);
          case 9500:
            throw new Errors.UserHasNoRights(errorJson);
          default:
            if (!errorJson.Title) {
              throw new Errors.UnableToRetrieveLicense(
                'License Error',
                'Unable retrieve a license. Please try again later.',
                'Warning',
              );
            } else {
              throw new Errors.UnableToRetrieveLicense(errorJson);
            }
        }
      });
  }

  //Get RSA keys from IndexedDB if they exists, otherwise it generates, saves, and returns new keys.
  var getRSAKeys = function(name) {
    return _keyStore
      .getKey('name', name)
      .then(function(key) {
        if (key && key.privateKey instanceof CryptoKey) {
          return key;
        } else {
          return generateRSAKey(name);
          //throw ("Invalid key");
        }
      })
      .catch(function(err) {
        return generateRSAKey(name).catch(function(error) {
          console.error(error);
          throw error;
        });
      });
  };

  //Public API
  $.fn.fetchPublicKey = function(name) {
    return generateRSAKey(name).then(function(keys) {
      return keys.publicKey;
    });
  };

  this.getLicenseDocument = function(isbn, productTypeId) {
    var self = this;

    return utils
      .isOnline()
      .bind(this)
      .then(function(currentUser) {
        return userManager.getCurrentUser();
      })
      .then(function(currentUser) {
        //return fetchLicenseFromRemote(user);
        //return  fetchLicenseFromRemote.call(this, user);
        return Promise.all([currentUser, getRSAKeys(currentUser.bookvaultId)]);
      })
      .spread(function(currentUser, key) {
        return Promise.all([currentUser, key, _encryption.exportKey(key.publicKey)]);
      })
      .spread(function(currentUser, key, jwk) {
        var deviceId = localStorage.getItem('deviceId');
        if (deviceId == null) {
          deviceId = uuid.v4();
          localStorage.setItem('deviceId', deviceId);
        }
        var params = {
          bookvaultId: currentUser.bookvaultId,
          deviceId: deviceId,
          clientip: '192.168.0.1',
          isbn: isbn,
          modulus: jwk.n,
          exponent: jwk.e,
        };

        // FSS needs to add a productTypeId to license retrieval
        if (productTypeId) {
          params.productTypeId = productTypeId;
        }

        return {
          params: params,
          key: key,
        };
      })
      .then(fetchLicenseFromRemote);
  };
};

export default LicenseDocumentFetcher;
