import $ from 'jquery';
import _ from 'underscore';
import URI from 'urijs';

var PageListParser = function(packageDocument) {
  var that_ = this;
  this.packageDocument = packageDocument;

  this.generatePageListJSON = function(callback) {
    var that = this;
    //check for page-list in file with properties = 'nav'
    var navDoc = this.getNavDoc();
    if (navDoc) {
      this.getDomFromNav(navDoc, function(navDocDom) {
        if (navDocDom) {
          var $pageList = $([]);
          var $navList = $('nav', navDocDom);

          if ($navList.length < 1 && typeof navDocDom === 'string') {
            var $navDoc = $(navDocDom);
            $navList = _.filter($navDoc, function(node) {
              return node.nodeName.toLowerCase() === 'nav';
            });
          }

          $navList = _.filter($navList, function(node) {
            return getNamespacedAttribute(node, 'epub', 'type') === 'page-list';
          });

          if ($navList.length >= 1) {
            $pageList = $('ol>li', $navList[0]);
          }

          if ($pageList.length > 0) {
            that.getPackageDOM(function(packageDom) {
              var result = _.map($pageList, function(node) {
                var $node = $(node)
                  .children()
                  .first();
                var label = $node.text();
                var href = $node.attr('href');
                return generatePageListItem(label, href, packageDom);
              });
              callback(result);
            });
          } else {
            // if page-list not found, check ncx file
            getPageListFromNCX(callback);
          }
        }
      });
    } else {
      //if no file found with properties = 'nav', try to get page-list from ncx file
      getPageListFromNCX(callback);
    }
  };

  function getPageListFromNCX(callback) {
    var nav = that_.getNcxDoc();
    that_.getDomFromNav(nav, function(navDocDom) {
      if (navDocDom) {
        var $pageList = $('pageList>pageTarget', navDocDom);
        that_.getPackageDOM(function(packageDom) {
          var result = _.map($pageList, function(node) {
            var $node = $(node);
            var label = $node.attr('value') || $node.attr('playOrder');
            var href = $node.find('content').attr('src');
            return generatePageListItem(label, href, packageDom);
          });
          callback(result);
        });
      }
      else {
        callback(undefined);
      }
    });
  }

  function generatePageListItem(label, href, packageDom) {
    if (isIntraPubCfiLink(href)) {
      var parsedCfi = parseIntraPubCfiLink(href, packageDom);
      if (!parsedCfi) {
        return null;
      }
      return {
        label: label,
        cfi: new window.ReadiumSDKExport.BookmarkData(parsedCfi.idref, parsedCfi.cfi),
      };
    } else {
      return {
        label: label,
        href: href,
      };
    }
  }

  function getNamespacedAttribute(node, namespace, name) {
    return node.getAttributeNS(namespace, name) || node.getAttribute(namespace + ':' + name);
  }

  function isIntraPubCfiLink(href) {
    return href.indexOf('#epubcfi(') !== -1;
  }

  function parseIntraPubCfiLink(href, packageDom) {
    var rawCfi = /#epubcfi\((.*?)\)/g.exec(href)[1];
    var splitCfi = rawCfi.split('!');
    //var spine_id = /\[(.*?)]/g.exec(splitCfi[0])[1]; // not idref!
    var $spineItemElement = window.ReadiumSDKExport.EpubCfi.Interpreter.getTargetElementWithPartialCFI(
      'epubcfi(' + splitCfi[0] + ')',
      packageDom,
    );
    var cfi = splitCfi[1];
    var idref = $spineItemElement.attr('idref');
    var spine = getSpineFromPackageDocument(packageDom);
    var spineItem = _.findWhere(spine, { idref: idref });
    if (!spineItem) {
      console.warn('parseIntraPubCfiLink: cannot find spine entry with id', idref);
      return null;
    }
    return {
      idref: spineItem.idref,
      cfi: cfi,
    };
  }

  function getSpineFromPackageDocument() {
    return that_.packageDocument.getSharedJsPackageData().spine.items;
  }

  this.getNavDocHref = function() {
    var item = this.getNavDoc();
    if (item) {
      return item.href;
    }
    return null;
  };

  this.getNavDoc = function() {
    var item = this.packageDocument.manifest.getNavItem();
    if (item) {
      return item;
    }

    return null;
  };

  this.getNcxDoc = function() {
    var spine_id = this.packageDocument.getMetadata().ncx;
    if (spine_id && spine_id.length > 0) {
      return this.packageDocument.manifest.getManifestItemByIdref(spine_id);
    }
    return null;
  };

  this.getDomFromNav = function(nav, callback) {
    if (!nav) {
       callback(undefined);
       return;
    }
    if (this.packageDocument.tocText){
      callback(this.packageDocument.tocText)
      return;
    }
    this.packageDocument.resourceFetcher.relativeToPackageFetchFileContents(
      nav.href,
      'text',
      function(navDocumentText) {
        callback(navDocumentText);
      },
      function(err) {
        console.error('ERROR fetching NAV from [' + nav + ']:');
        console.error(err);
        callback(undefined);
      },
    );
  };

  this.navDocIsNCX = function(navDoc) {
    var navDocHref = navDoc.href;
    return new URI(navDocHref).suffix() === 'ncx';
  };

  var cachedPackageDOM;
  this.getPackageDOM = function(callback) {
    if (cachedPackageDOM) {
      callback(cachedPackageDOM);
      return;
    }

    this.packageDocument.resourceFetcher.getPackageDom(
      function(result) {
        cachedPackageDOM = result;
        callback(cachedPackageDOM);
      },
      function(err) {
        console.error('ERROR fetching Package Document');
        console.error(err);
        callback(undefined);
      },
    );
  };
};

export default PageListParser;
