import $ from 'jquery';
import PopupFactory from '../../popup/PopupFactory';
import moduleConfig from '../../ModuleConfig';
import InlineAnnotationsMenu from '../../menus/inline/InlineAnnotationsMenu';
import SearchMenu from '../../templates/popup/search-menu-container-layout.hbs';
import SearchMenuItem from '../../templates/popup/search-menu-item.hbs';
import AccessibilityManager from '../../accessibility/AcessibilityManager';
//Notes
var inlineSearch = PopupFactory.create(PopupFactory.TYPE.NAVBAR_MENU, 'Google/Wiki', {
  cssClass: 'app-search-menu',
  id: 'googleSearchPopup',
});
inlineSearch.position('left');
inlineSearch.clearContainerLayouts();
var $googleResultsContainerLayoutContent = inlineSearch.addContainerLayout(SearchMenu({}));
var $wikiResultsContainerLayoutContent = inlineSearch.addContainerLayout(SearchMenu({}));

var InlineGoogleSearch = function() {
  InlineAnnotationsMenu.apply(this, arguments);
  this.popup(inlineSearch);
};

InlineGoogleSearch.prototype = Object.create(InlineAnnotationsMenu.prototype);

InlineGoogleSearch.prototype.show = Promise.method(function() {
  this.hideAll();
  this.annotationUtils.clearSelection();

  var selectionText = this.textSelectionEvent().text;

  getGoogle(selectionText, function(googleResults) {
    inlineSearch.updateTitle('Google Search');
    if (googleResults == undefined || googleResults.length == 0) {
      $googleResultsContainerLayoutContent
        .find('.pane.header-pane h2')
        .text('No results were found');
    } else {
      $googleResultsContainerLayoutContent
        .find('.pane.header-pane h2')
        .text(googleResults.length + ' Results');
    }
    inlineSearch.$el.find('.container-layout').show();
    var $listGrp = $googleResultsContainerLayoutContent.find('.list-group');
    $listGrp.empty();
    if (!(googleResults == undefined || googleResults.length == 0)) {
      googleResults.forEach(function(googleResultItem) {
        $listGrp.append(SearchMenuItem(googleResultItem));
      });
    }
    $wikiResultsContainerLayoutContent.hide();
    $googleResultsContainerLayoutContent.show();
    inlineSearch.show();
  });
  inlineSearch.$el.show();
  return;
});

function getGoogle(theWord, callback) {
  // NEW GOOGLE API:
  var url =
    'https://www.googleapis.com/customsearch/v1?key=' +
    moduleConfig.googleAPIKey +
    '&cx=' +
    moduleConfig.googleSearchEngineID +
    '&q=' +
    theWord.replace(' ', '+') +
    '&callback=?';
  var jxhr = $.ajax({
    url: url,
    contentType: 'application/json; charset=utf-8',
    dataType: 'json',
    async: false,
    success: function(data, status) {
      var array = data.items;
      console.log(data);
      //callback (array [0].words)
      callback(array);
    },
    error: function(status) {
      console.log(
        'getGoogle: url == ' + url + ', error == ' + JSON.stringify(status, undefined, 4),
      );
    }
  });
}

export default InlineGoogleSearch;
