import $ from 'jquery';
var instance;
var _mapFeatureGroup, _mapFeature;
//Default constructor which will initially feature array to Blank
var FeatureManager = function() {
  _mapFeatureGroup = new Map();
  _mapFeature = new Map();
};
//Feature function which will return feature if "feature Name" passed as argument
// otherwise if this is object type then add this to feature array
FeatureManager.prototype.feature = function(features) {
  try {
    if (typeof features == 'string') {
      return fetchFeatureName(features);
    }
    if ($.isArray(features)) {
      $.each(features, function(index, feature) {
        addFeature(feature);
      });
      return;
    }
    addFeature(features);
  } catch (ex) {
    throw ex;
  }
};
FeatureManager.prototype.featureGroup = function(featureGroup) {
  try {
    if (typeof featureGroup == 'string') {
      return fetchFeatureGroupName(featureGroup);
    }
    addFeatureGroup(featureGroup);
  } catch (ex) {
    throw ex;
  }
};
//Adds new/Update existing feature group in feature group array
function addFeatureGroup(featureGroup) {
  if (_mapFeatureGroup.has(featureGroup.name)) {
    var existingFeatures = fetchFeatureGroupName(featureGroup.name);
    if (!$.isArray(existingFeatures.featuresArray)) {
      existingFeatures.featuresArray = [existingFeatures.featuresArray];
    }
    featureGroup.featuresArray = $.merge(
      featureGroup.featuresArray,
      existingFeatures.featuresArray,
    );
  }
  _mapFeatureGroup.set(featureGroup.name, featureGroup);
  console.log('Feature added for: ' + featureGroup.name);
}
//fetch Feature group from Feature group  array using Feature group name
function fetchFeatureGroupName(featureGroupName) {
  if (_mapFeatureGroup.has(featureGroupName)) {
    return _mapFeatureGroup.get(featureGroupName);
  }
  console.log('Feature group details not found for: ' + featureGroupName);
  return '';
}
FeatureManager.prototype.getAllFeatureGroups = function() {
  console.log('Total no of features group stored: ' + _mapFeatureGroup.size);
  return Array.from(_mapFeatureGroup);
};
//fetch Feature from Feature array using Feature name
function fetchFeatureName(featureName) {
  if (_mapFeature.has(featureName)) {
    return _mapFeature.get(featureName);
  }
  console.log('Feature details not found for: ' + featureName);
  return '';
}
//Adds new/Update existing feature in feature array
function addFeature(featureObject) {
  _mapFeature.set(featureObject.name, featureObject);
  console.log('Feature added for: ' + featureObject.name);
}
FeatureManager.prototype.getAllFeatures = function() {
  console.log('Total no of features stored: ' + _mapFeature.size);
  return Array.from(_mapFeature);
};
//Single instance will always be created-to prevent overriding features
export default {
  getInstance: function() {
    if (instance == null) {
      instance = new FeatureManager();
      // Hide the constructor so the returned objected can't be new'd...
      instance.constructor = null;
    }
    return instance;
  },
};
