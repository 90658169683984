import $ from 'jquery';
import createFocusTrap from 'focus-trap';

var css = {
  body: 'popup-body',
  header: 'popup-header',
};
var triggerElements = [];
var annotationDeleteVisibilty = false;

function trackTriggerElement() {
  triggerElements.push(document.activeElement);
}

function isMobileSafari() {
  return /iP(ad|od|hone)/i.test(navigator.userAgent);
}

// Trigger elements are tracked so if one of popups
// in the sequence is closed, we can trace back further
// to set focus in the right context
function tryRecoverFocusElement() {
  var visibleTriggerElement;
  for (var i = triggerElements.length - 1; i >= 0; i--) {
    var element = triggerElements[i];
    if ($(element).is(':visible')) {
      visibleTriggerElement = element;
      break;
    }
  }

  if (
    visibleTriggerElement != undefined &&
    visibleTriggerElement !== triggerElements[triggerElements.length - 1]
  ) {
    visibleTriggerElement.focus();
  }

  // To avoid too many tracked elements. Five should be enough
  if (triggerElements.length >= 10) {
    triggerElements.splice(0, 5);
  }
}

var Popup = function(count) {
  var $el = $('<div>').addClass('popup');

  this.getBody().appendTo($el);
  Object.defineProperties(this, {
    $el: {
      enumerable: false,
      writable: false,
      value: $el,
    },
    $body: {
      numerable: false,
      writable: false,
      value: $el.find('.popup-body'),
    },
  });
  this.popupNum = count;
  var self = this;
  $('body').on('hideAllPopupMenus', function() {
    if (!self.$body.hasClass('annotations')) {
      self.hide.apply(self, arguments);
    }
  });
};

Popup.TYPE = {
  NAVBAR_MENU: 'navbar-menu',
  MAIN_MENU: 'main-menu',
  NAVBAR_WITH_HEADER_BUTTON_MENU: 'navbar-with-header-button-menu',
  NAVBAR_WITH_SEARCH_BOX_MENU: 'navbar-with-search-box-menu',
  IN_PLACE: 'inplace',
  AUDIO_POSITION: 'audioposition',
  RUN_OUT_OF_SPACE: 'runoutofspace',
  EMPTY_NO_BOOKMARK: 'empty-no-bookmark',
  MODAL: 'modal',
};

Popup.prototype.extends = function(Cls) {
  var constructor = this.constructor;
  var ObjectWrapper = function() {
    constructor.apply(this, arguments);
    Cls.apply(this, arguments);
  };

  ObjectWrapper.prototype = $.extend({}, Popup.prototype, Cls.prototype);
  ObjectWrapper.prototype.constructor = ObjectWrapper;

  return ObjectWrapper;
};

Popup.prototype.appendPopup = function(options) {
  options = $.extend(
    {
      wrapper: '#popup-container',
    },
    options,
  );

  var $wrapper = $(options.wrapper);
  $wrapper.append(this.$el);

  if (typeof options.cssClass !== 'undefined') {
    this.$el.addClass(options.cssClass);
  }
  if (typeof options.id !== 'undefined') {
    this.$el.attr('id', options.id);
  }

  var self = this;
  this.$el.on('click', 'button.js-close-popup', function() {
    console.log('options ####', options.id);
    if (options.id == "ttsSettings") { // disable the  tts setting button
      $('#btnToggleTTSSettings span:first').attr('class', 'icon icon-icon-tts-settings');
    } else if (options.id == "searchPopup") {
      $('#btnToggleSearch span:first').attr('class', 'icon icon-search');
      $('.btnToggleSearchRA span:first').attr('class', 'icon icon-search');
      $('.btnToggleSearch span:first').attr('class', 'icon icon-search');
    } else if(options.id == "annotationsListBookmarks") {
      $('#btnToggleBookmark span:first').attr('class', 'icon icon-bookmark-outline');
      $('.btnToggleBookmarkRA span:first').attr('class', 'icon icon-bookmark-outline');
    }
    self.hide();
    $('.navbar-btn').removeClass('active');
  });

  this.focusTrap = createFocusTrap(this.$el[0], {
    onDeactivate: function() {
      self.onFocusTrapDeactive();
    },
    ignoreClick: true,
    allowOutsideClick: function() {
      return true;
    }
  });
  this.isFocusTrapActive = false;

  return options;
};

Popup.prototype.getBody = function() {
  return $('<div>').addClass(css.body);
};

Popup.prototype.getHeader = function() {
  return $('<div>').addClass(css.header);
};

Popup.prototype.show = function(triggerElement) {
  console.log('pop up Show>>>', this.$el);
  this.$el.show();
  if (!isMobileSafari()) {
    this.focusTrap.activate({ triggerElement: triggerElement });
    this.isFocusTrapActive = true;
  }
  $(document).trigger('POPUP_VISIBILITY_CHANGED', [true]);
  if (!isMobileSafari()) {
    trackTriggerElement();
  }
  if ($('#annotationDelete').css('display') == 'block') {
    $('#annotationsListNotes').attr('aria-hidden', 'true');
    annotationDeleteVisibilty = true;
  } else {
    $('#app-navbar, #app-container').attr('aria-hidden', 'true');
  }
};

Popup.prototype.hide = function(returnFocus) {
  // console.log('hide..popup>>>>');
  this.$el.hide();
  if (this.focusTrap && this.isFocusTrapActive) {
    var shouldReturnFocus = returnFocus === false ? false : true;
    this.focusTrap.deactivate({
      onDeactivate: false,
      returnFocus: shouldReturnFocus,
    });
    this.isFocusTrapActive = false;
    if (shouldReturnFocus) {
      tryRecoverFocusElement();
    }
  }
  if (annotationDeleteVisibilty) {
    $('#annotationsListNotes').attr('aria-hidden', 'false');
    annotationDeleteVisibilty = false;
  } else {
    $('#app-navbar, #app-container').attr('aria-hidden', 'false');
  }
  $(document).trigger('POPUP_VISIBILITY_CHANGED', [false]);
};

Popup.prototype.onFocusTrapDeactive = function() {
  this.$el.hide();
  this.isFocusTrapActive = false;
  if (annotationDeleteVisibilty) {
    $('#annotationsListNotes').attr('aria-hidden', 'false');
    annotationDeleteVisibilty = false;
  } else {
    $('#app-navbar, #app-container').attr('aria-hidden', 'false');
  }
  tryRecoverFocusElement();
};

Popup.prototype.hideAll = function() {
  $('body').trigger('hideAllPopupMenus');
};

Popup.prototype.isVisible = function() {
  // console.log("visible>>");
  return this.$el.is(':visible');
};

export default Popup;
