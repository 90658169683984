var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "No Author Listed";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        \n        data-library=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"epub") : depth0)) != null ? lookupProperty(stack1,"rootUrl") : stack1), depth0))
    + "\"\n        \n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            \n"
    + ((stack1 = alias2.call(depth0,alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"epub") : depth0)) != null ? lookupProperty(stack1,"isExternalLink") : stack1), depth0),{"name":"epub.isExternalLink","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":24,"column":36}}})) != null ? stack1 : "")
    + "    \n"
    + ((stack1 = alias2.call(depth0,alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"epub") : depth0)) != null ? lookupProperty(stack1,"isExternalLink") : stack1), depth0),{"name":"epub.isExternalLink","hash":{},"fn":container.noop,"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":26,"column":12},"end":{"line":30,"column":36}}})) != null ? stack1 : "")
    + "        \n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            \n            data-link=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"epub") : depth0)) != null ? lookupProperty(stack1,"rootUrl") : stack1), depth0))
    + "\"\n            \n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            \n            data-book=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"epub") : depth0)) != null ? lookupProperty(stack1,"rootUrl") : stack1), depth0))
    + "\"\n            \n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div aria-hidden=\"true\" class=\"no-cover has-cover\" style=\"background-image:url("
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"epub") : depth0)) != null ? lookupProperty(stack1,"coverHref") : stack1), depth0))
    + ");\"><p>&nbsp;</p></div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div aria-hidden=\"true\" class=\"no-cover\" style=\"background-image: url('"
    + alias1(((helper = (helper = lookupProperty(helpers,"noCoverBackground") || (depth0 != null ? lookupProperty(depth0,"noCoverBackground") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"noCoverBackground","hash":{},"data":data,"loc":{"start":{"line":39,"column":79},"end":{"line":39,"column":100}}}) : helper)))
    + "')\"><p>"
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"epub") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</p></div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            \n"
    + ((stack1 = container.hooks.blockHelperMissing.call(depth0,container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"epub") : depth0)) != null ? lookupProperty(stack1,"isExternalLink") : stack1), depth0),{"name":"epub.isExternalLink","hash":{},"fn":container.noop,"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":54,"column":12},"end":{"line":62,"column":36}}})) != null ? stack1 : "")
    + "        \n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    \n                <div class=\"buttons hidden\">\n        \n                <button aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_details") : stack1), depth0))
    + " ("
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"count") : depth0)) != null ? lookupProperty(stack1,"n") : stack1), depth0))
    + ") "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"epub") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\" tabindex=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"count") : depth0)) != null ? lookupProperty(stack1,"tabindex") : stack1), depth0))
    + "\" data-toggle=\"modal\" data-target=\"#details-modal\" data-package=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"epub") : depth0)) != null ? lookupProperty(stack1,"rootUrl") : stack1), depth0))
    + "/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"epub") : depth0)) != null ? lookupProperty(stack1,"packagePath") : stack1), depth0))
    + "\" data-root=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"epub") : depth0)) != null ? lookupProperty(stack1,"rootUrl") : stack1), depth0))
    + "\" data-root-dir=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"epub") : depth0)) != null ? lookupProperty(stack1,"rootDir") : stack1), depth0))
    + "\" "
    + ((stack1 = container.hooks.blockHelperMissing.call(depth0,alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"epub") : depth0)) != null ? lookupProperty(stack1,"coverHref") : stack1), depth0),{"name":"epub.coverHref","hash":{},"fn":container.noop,"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":58,"column":285},"end":{"line":58,"column":362}}})) != null ? stack1 : "")
    + " class=\"btn btn-info details\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_details") : stack1), depth0))
    + "</button>\n                \n                </div>\n                \n";
},"16":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-no-cover=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"noCoverBackground") || (depth0 != null ? lookupProperty(depth0,"noCoverBackground") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"noCoverBackground","hash":{},"data":data,"loc":{"start":{"line":58,"column":320},"end":{"line":58,"column":341}}}) : helper)))
    + "\" ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div role=\"listitem\" class=\"library-item\">\n    <div class='info-wrap'>\n        <!-- <div class='caption book-info'>\n            <h4 class='title'>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"epub") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</h4>\n            <div class='author'>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"epub") : depth0)) != null ? lookupProperty(stack1,"author") : stack1), depth0))
    + ((stack1 = alias3.call(depth0,alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"epub") : depth0)) != null ? lookupProperty(stack1,"author") : stack1), depth0),{"name":"epub.author","hash":{},"fn":container.noop,"inverse":container.program(1, data, 0),"data":data,"loc":{"start":{"line":5,"column":48},"end":{"line":5,"column":96}}})) != null ? stack1 : "")
    + "</div>\n        </div> -->\n\n        <button type=\"button\" class=\"read\"\n\n        data-book-type=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"epub") : depth0)) != null ? lookupProperty(stack1,"type") : stack1), depth0))
    + "\"\n        \n"
    + ((stack1 = alias3.call(depth0,alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"epub") : depth0)) != null ? lookupProperty(stack1,"isSubLibraryLink") : stack1), depth0),{"name":"epub.isSubLibraryLink","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":16,"column":34}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = alias3.call(depth0,alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"epub") : depth0)) != null ? lookupProperty(stack1,"isSubLibraryLink") : stack1), depth0),{"name":"epub.isSubLibraryLink","hash":{},"fn":container.noop,"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":32,"column":34}}})) != null ? stack1 : "")
    + "        \n        aria-label=\"("
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"count") : depth0)) != null ? lookupProperty(stack1,"n") : stack1), depth0))
    + ") "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"epub") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\">\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"epub") : depth0)) != null ? lookupProperty(stack1,"coverHref") : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":36,"column":8},"end":{"line":40,"column":15}}})) != null ? stack1 : "")
    + "    </button>\n    </div>\n\n    <!-- <div class=\"caption buttons\">\n        <a href=\"#\" class=\"btn btn-default read\" data-book=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"epub") : depth0)) != null ? lookupProperty(stack1,"packageUrl") : stack1), depth0))
    + "\" role=\"button\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_read") : stack1), depth0))
    + "</a>\n        <a href=\"#details-modal\" data-book=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"epub") : depth0)) != null ? lookupProperty(stack1,"packageUrl") : stack1), depth0))
    + "\" aria-pressed=\"true\" class=\"btn btn-default details\" data-toggle=\"modal\" role=\"button\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_details") : stack1), depth0))
    + "</a>\n    </div> -->\n    <div class='caption book-info'>\n        <h4 class='title' title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"epub") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"epub") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</h4>\n        <div class='author' title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"epub") : depth0)) != null ? lookupProperty(stack1,"author") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"epub") : depth0)) != null ? lookupProperty(stack1,"author") : stack1), depth0))
    + "</div>\n\n"
    + ((stack1 = alias3.call(depth0,alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"epub") : depth0)) != null ? lookupProperty(stack1,"isSubLibraryLink") : stack1), depth0),{"name":"epub.isSubLibraryLink","hash":{},"fn":container.noop,"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":52,"column":8},"end":{"line":64,"column":34}}})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"book-actions\">\n        <button data-book-index=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"count") : depth0)) != null ? lookupProperty(stack1,"n") : stack1), depth0))
    + "\" class=\"btn btn-primary btn-bg-none remove-book\" type=\"button\" aria-label=\"Remove Book\" data-isbn=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"epub") : depth0)) != null ? lookupProperty(stack1,"isbn") : stack1), depth0))
    + "\">Remove</button>\n    </div>\n    <div class=\"library-splash\">\n        <div class=\"close\"><span class=\"icon icon-close\"></span></div>\n        <p><em>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"epub") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</em> has been removed from your browser.</p>\n    </div>\n</div>\n";
},"useData":true});