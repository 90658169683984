import EpubPagingHandler from './PagingHandler';

var _epubPagingHandler;

function Pagination(api) {
  this.init = function(readium, packageDocument) {
    _epubPagingHandler = new EpubPagingHandler(readium, packageDocument);

    return _epubPagingHandler.init(readium, packageDocument);
  };

  this.getMappedPageList = function() {
    return _epubPagingHandler.mappedPageList;
  };

  this.getLabelFromIndex = function(index) {
    return _epubPagingHandler.getLabelFromIndex(index);
  };

  this.getLabelItemsByIdRef = function(idref) {
    return _epubPagingHandler.getLabelItemsByIdRef(idref);
  };

  this.getLabelItems = function() {
    return _epubPagingHandler.getFormattedLabelItems();
  };

  this.getVisibleLabelItems = function() {
    return _epubPagingHandler.getFormattedVisibleLabelItems();
  };

  this.getVisibleLabelCFIs = function() {
    return _epubPagingHandler.getVisibleLabelCFIs();
  };

  this.getLabelsFromPageNumberRange = function() {
    return _epubPagingHandler.getLabelsFromPageNumberRange();
  };

  this.getTotalPageCount = function() {
    return _epubPagingHandler.getTotalPageNumber();
  };

  this.getLastPageForIdref = function(idref) {
    var idrefList = _epubPagingHandler.getListByIdref(idref);
    return idrefList[idrefList.length - 1];
  };

  this.getLastPageNumber = function() {
    var lastPageNumber = this.getTotalPageCount();
    return _epubPagingHandler.getFromPageNumber(lastPageNumber).label;
  };

  this.jumpToPageNumber = function(pageNumber) {
    _epubPagingHandler.jumpToPageNumber(pageNumber);
  };

  this.jumpToIndex = function(index) {
    return _epubPagingHandler.jumpToIndex(index);
  };

  this.jumpToLabel = function(label) {
    return _epubPagingHandler.jumpToLabel(label);
  };
}

export default Pagination;
