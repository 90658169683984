import $ from 'jquery';
import Popup from './Popup';
import PopupModalBody from '../templates/popup/popup-audio-position.hbs';
import PopupModalPanelHeading from '../templates/popup/popup-modal-panel-heading.hbs';

var PopupModal = function(count, type, options) {
  options = this.appendPopup(options);
  options = $.extend(
    {
      //width : "24em"
    },
    options,
  );

  this.$el.addClass('popup-modal');
  //No need now as we reduce audiobook image size
  //this.$el.css("height",screen.height);
  this.$body.innerWidth(options.width);
  this.$body.innerHeight(options.height);
  this.$body.html(PopupModalBody({}));
  this._buttons = [];
};

PopupModal = Popup.prototype.extends(PopupModal);

PopupModal.prototype.setTitle = function(title) {
  var $heading = this.$body.find('.panel-heading');
  $heading.html(PopupModalPanelHeading({ title: title }));
  var self = this;
  $heading.find('button.close').hide();
};

PopupModal.prototype.setMessage = function(msg) {
  this.$body.css('min-height', '270px');
  var $body = this.$body.find('.panel-body');
  $body.html(msg);
};

PopupModal.prototype.addButton = function(label, callback, isPrimary) {
  var $el = $('<button>').addClass('btn');
  if (isPrimary) {
    $el.addClass('btn-primary btn-bg-none');
  } else {
    $el.addClass('btn-default btn-bg-none');
  }
  $el.text(label);
  $el.on('click', callback);
  this.$body.find('.panel-footer').append($el);

  this._buttons.push({
    label: label,
    $el: $el,
  });
};
PopupModal.prototype.addRadioButton = function(label, isChecked, Value, callback) {
  var $el = $(
    '<input type="radio" name="radiobtn" value=' +
      Value.replace(' ', '') +
      '> ' +
      label +
      '</input><br/>',
  );

  if (isChecked) {
    $el.attr('checked', 'checked');
  }
  //$el.on("click", callback);
  this.$body.find('.panel-body').append($el);

  this._buttons.push({
    label: label,
    $el: $el,
  });
};

export default PopupModal;
