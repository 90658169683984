import $ from 'jquery';
import Strings from '../../i18n/Strings';
import PopupFactory from '../../popup/PopupFactory';
import Menu from '../../menus/Menu';
import HelpPage from '../../HelpPage';
import HelpMenuTemplate from '../../templates/popup/help-menu.hbs';
import HelpEditMenuTemplate from '../../templates/popup/help-edit-menu.hbs';
import PackageInfo from '../../PackageInfo';
import AccessibilityManager from '../../accessibility/AcessibilityManager';
import errorMonitor from '../../ErrorMonitor';

//Help
var helpListPopup = PopupFactory.create(PopupFactory.TYPE.NAVBAR_MENU, 'Help', {
  id: 'helpList',
  cssClass: 'app-help-menu',
});

// Adding attributes for WA
helpListPopup.$el.attr('role', 'dialog').attr('aria-label', Strings.help);

var $helpContainerLayoutContent;

HelpPage.init();

var HelpMenu = function() {
  Menu.apply(this, arguments);
  this.popup(helpListPopup);
  helpListPopup.$el.find('.navbar-btn').on(
    'click',
    '.icon-help:not(.close)',
    $.proxy(function(e) {
      //this.annotationUtils.saveBookmarks(this.isbn).then($.proxy(this.show, this));
    }, this),
  );
};

HelpMenu.prototype = Object.create(Menu.prototype);

HelpMenu.prototype.show = function() {
  Menu.prototype.show.call(this, arguments).then(function() {
    console.log("Help Menu displayed");
  }).catch(function(error) {
    console.error(error);
    errorMonitor.bugsnag(error);
  });
  // helpListPopup.$el.show();
  // AccessibilityManager.setTabGroup("help_popup")
  // AccessibilityManager.updateTabOrder("help_popup")
};

$helpContainerLayoutContent = $(HelpMenuTemplate( { 'version':PackageInfo.version, 'buildId': PackageInfo.buildId }));
helpListPopup.addContainerLayout($helpContainerLayoutContent);
$helpContainerLayoutContent
  .find('.pane-body')
  .empty()
  .append(HelpEditMenuTemplate({}));

HelpPage.init(function(toc) {
  if (!toc) return;
  $helpContainerLayoutContent
    .find('.pane-body')
    .empty()
    .append(HelpEditMenuTemplate({ toc: toc }));

  //attaching events
  helpListPopup.$el.off('click.help').on('click.help', 'a.js-view-help', function(e) {
    e.preventDefault();
    helpListPopup.$el.hide();
      // AccessibilityManager.panelCloseHandler(function() {
      //     AccessibilityManager.setFocus("#btnToggleHelp")
      // })
    $('#btnToggleHelp').removeClass('active');
    HelpPage.show(this.hash);
  });
});

export default HelpMenu;
