import $ from 'jquery';
import moduleConfig from './ModuleConfig';
import MessageTemplate from './templates/messages/message.hbs';
import MessageContentTemplate from './templates/messages/message-content.hbs';
import utils from '@axisnow/util/utils';

var messageInfo;

var SplashMessage = function(options) {
  options = $.extend(
    {
      wrapper: 'body',
    },
    options,
  );

  var $el = $('#splash');

  if ($el.length == 0) {
    $el = $('<div>').attr('id', 'splash');
    var $wrapper = $(options.wrapper);
    $('#app-fullscreen').append($el);
  }

  Object.defineProperty(this, '$el', {
    enumerable: false,
    writable: false,
    value: $el,
  });

  this.init(options);

  Object.defineProperty(this, '$content', {
    numerable: false,
    writable: true,
    value: $el.find('.splash-content'),
  });
};

SplashMessage.icons = {
  CHECK: 'check.svg',
  ERROR: 'exclamation.svg',
  WARNING: 'exclamation.svg',
  INFORMATIONAL: 'none',
  RELOAD: 'reload.svg',
  CHECKALT: 'checkalt.svg',
  NONE: 'none',
};

var getIconPath = function(messageData) {
  var icon = {};

  if (messageData.Type) {
    icon = SplashMessage.icons[messageData.Type.toUpperCase()];
    messageData.Icon = {
      type: icon,
      path: moduleConfig.assetsUrl + '/common/svg/' + icon,
    };
  } else if (messageData.Icon !== SplashMessage.icons.NONE) {
    messageData.Icon = {
      type: messageData.Icon,
      path: moduleConfig.assetsUrl + '/common/svg/' + messageData.Icon,
    };
  } else {
    delete messageData.Icon;
  }
  return messageData;
};

//Public API
SplashMessage.prototype.icons = SplashMessage.icons;

SplashMessage.prototype.init = function(options) {
  this.$el.empty();
  this.$el.removeClass().addClass('splash');

  var self = this;
  this.$el.on('click', '.js-close-message', function() {
    self.$el.hide();
    // restore main content
    $('#app-container').removeAttr('display');
    $('#app-header').removeAttr('display');
  });

  this.$el.on('click', '.btn-feedback', function(event) {
    event.stopImmediatePropagation();
    self.sendFeedback(false);
  });

  this.$el.html(MessageTemplate(options));
  this.$content = this.$el.find('.splash-content');

  if (typeof options.cssClass !== 'undefined') {
    this.$el.addClass(options.cssClass);
  }
  if (typeof options.id !== 'undefined') {
    this.$el.attr('id', options.id);
  }
};

SplashMessage.prototype.transparent = function(isTransparent) {
  if (isTransparent) {
    this.$el.addClass('see-through');
  } else {
    this.$el.removeClass('see-through');
  }
};

SplashMessage.prototype.message = function(messageData) {
  var self = this;
  if (messageData.Type == 'Error' || messageData.Type == 'Warning') {
    //declare feedbackBtn, default value is true
    var feedbackBtn =
      typeof messageData.feedbackBtn == 'undefined' ? true : messageData.feedbackBtn;

    var splashButtons = {
      Buttons: [
        {
          ButtonMessage: [
            {
              btnMessageClass: 'feedbackBtnMessage',
              btnMessage: 'Send us information to help resolve this issue.',
            },
          ],
          label: 'Send',
          cssClass: 'btn-feedback',
        },
      ],
    };

    if (messageData.name == 'DRMSessionServiceError') {
      console.log('automatically sending feedback');
      self.sendFeedback(true);
    }

    if (feedbackBtn) $.extend(messageData, splashButtons);
  }

  messageInfo = {
    errorName: messageData.name || messageData.Title,
    errorMessage: messageData.message || messageData.Messages,
    bookId: messageData.bookId,
    urlParams: messageData.urlParams,
    stackTrace: messageData.stack,
    uuid: messageData.UUID,
    errorCode: messageData.error_number,
  };

  messageData = getIconPath(messageData);
  this.$content.html(MessageContentTemplate(messageData));
  $('#app-container').attr('display', 'none'); // Hide the actual book from screen readers
  $('#app-header').attr('display', 'none'); // Hide the actual book from screen readers
};

// This method directly sends any info to the feedback service, with no UI involved.
SplashMessage.prototype.sendInfo = function(messageInfo, onsuccess, onerror) {
  return utils.sendInfo(messageInfo, onsuccess, onerror);
};

// This method sends the information being displayed by the splash message to the feedback service.
SplashMessage.prototype.sendFeedback = function(isAutomatic) {
  $.extend(messageInfo, { userAgent: navigator.userAgent });
  this.sendInfo(
    messageInfo,
    function(result) {
      if (!isAutomatic)
        $('.splash-content').html('<h3 class="text-uppercase">Thanks for your feedback.</h3>');
    },
    function(xhr, status, errorThrown) {
      console.error('Error when AJAX fetching ' + JSON.stringify(url));
      console.error(status);
      console.error(errorThrown);
    },
  );
};

export default new SplashMessage();
