import $ from 'jquery';
import Promise from 'bluebird';

let __sw_config
let __CST_ACTIVATED = false

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

function ActivateCST() {
  if(__CST_ACTIVATED) {
    console.log("CacheStatusTool Already Active.")
    return
  }
  __CST_ACTIVATED = true
  console.log("Activating CacheStatusTool")
  AddTouchListener()
  document.addEventListener('keydown', KeyDownHandler)

  const cs_config = {
    hot_key: "KeyA",
    element_ids: {
      status_modal: "cacheStatusModal"
    },
    statusTypes: {
      appcache: (window && window.applicationCache) ? GetAppCacheStatusCodes() : [],
      serviceworker: {
        none: "NO SERVICE WORKER",
        active: "ACTIVE",
        waiting: "WAITING",
        active_waiting: "1 ACTIVE, 1 WAITING"
      }
    }
  }

  function GetAppCacheStatusCodes() {
    const UNCACHED = window.applicationCache.UNCACHED
    const IDLE = window.applicationCache.IDLE
    const CHECKING = window.applicationCache.CHECKING
    const DOWNLOADING = window.applicationCache.DOWNLOADING
    const UPDATEREADY = window.applicationCache.UPDATEREADY
    const OBSOLETE = window.applicationCache.OBSOLETE
    const statusCodes = []
    statusCodes[UNCACHED] = "UNCACHED"
    statusCodes[IDLE] = "IDLE"
    statusCodes[CHECKING] = "CHECKING"
    statusCodes[DOWNLOADING] = "DOWNLOADING"
    statusCodes[UPDATEREADY] = "UPDATEREADY"
    statusCodes[OBSOLETE] = "OBSOLETE"
    return statusCodes
  }

  function KeyDownHandler(e) {
    let isLetterKey = e.code === cs_config.hot_key
    let isControlKey = e.ctrlKey
    let isAltKey = e.altKey || e.metaKey || e.shiftKey
    console.log(isLetterKey, isControlKey, isAltKey)
    // Control + Alt + [cs_config.hot_key] (Windows) || Control + Command + [cs_config.hot_key] (Mac)
    if(isLetterKey && isControlKey && isAltKey){
      if(isHelpMenuOpen()){
        Main()
      }
    }
  }

  function AddTouchListener(){
    let timer
    let versionTouchedCount = 0
    VersionTouchCounter()

    function VersionTouchCounter(){
      document.addEventListener('touchstart', ListenToBubble)
      document.addEventListener('click', ListenToBubble)

      function ListenToBubble(e) {
        if(e.target.classList.contains("app-version")){
          versionClickedHandle(e)
        }

        function versionClickedHandle(e){
          versionTouchedCount = versionTouchedCount + 1
          if(versionTouchedCount === 5){
            AddHoldListeners()
            window.alert(`Hold Help Menu Close Button for 5 Seconds.`)
          }

          function AddHoldListeners() {
            const closeBtn = document.querySelector('#helpList button.close')
            closeBtn.addEventListener('touchstart', helpCloseBtnHoldHandle)
            closeBtn.addEventListener('touchend', helpCloseBtnReleaseHandle)
            closeBtn.addEventListener('mousedown', helpCloseBtnHoldHandle)
            closeBtn.addEventListener('mouseup', helpCloseBtnReleaseHandle)

            function helpCloseBtnHoldHandle(e){
              if(!timer){
                timer = setTimeout(onLongTouch, 5000)
              }

              function onLongTouch(){
                if(versionTouchedCount > 4){
                  Main()
                }
              }
            }

            function helpCloseBtnReleaseHandle(e){
              if(timer){
                clearTimeout(timer)
                timer = null
              }
              versionTouchedCount = 0
            }

          }
        }

      }
    }
  }

  function isHelpMenuOpen() {
    const elem = document.querySelector("#helpList")
    return elem.style.display !== "none"
  }

  function Main() {
    const isModal = document.getElementById(cs_config.element_ids.status_modal)
    if(isModal){
      return
    }
    TesterInit()

    async function TesterInit() {
      const modal = ModalInit()
      const generalStatus = await GeneralCacheStatus({modal})
      const axisNowServiceWorkerResult = await AxisNowServiceWorkerTest({generalStatus, modal})
    }

    function ModalInit() {
      const modalContainer = document.createElement('div')
      modalContainer.id = cs_config.element_ids.status_modal
      modalContainer.onclick = e => e.stopPropagation()

      // set the position of modalContainer
      var $appContainer = $('#app-container');
      var heightOffset = $appContainer[0].offsetHeight;
      modalContainer.style.position = "relative";
      modalContainer.style.top = -heightOffset + 'px';

      const modal = document.createElement('div')
      AddBtnGroup(modal)

      const modalContent = document.createElement('div')
      modalContent.id = 'modalContent'

      document.body.append(modalContainer)
      modalContainer.append(modal)
      modal.append(modalContent)
      return modalContent

      function AddBtnGroup(node){
        const btnGroup = document.createElement('div')
        node.append(btnGroup)
        btnGroup.id = 'modalBtnGroup'
        /** Removing BTN Temporarily until service worker can be completely removed with indexedDB **/
        // AddRemoveServiceWorkerButton(btnGroup)
        AddCloseModalBtn(btnGroup)
      }

      function AddCloseModalBtn(node){
        const closeBtn = document.createElement('button')
        node.append(closeBtn)
        closeBtn.innerText = "Close"
        closeBtn.onclick = () => {
          modalContainer.remove()
        }
      }

      function AddRemoveServiceWorkerButton(node) {
        const btn = document.createElement('button')
        node.append(btn)
        btn.innerText = 'Remove SW'
        btn.onclick = HandleClick

        async function HandleClick(){
          const service_worker_msg = await removeServiceWorker()
          const caches_msg = await removeAllCaches()
          window.alert(`${service_worker_msg}\n${caches_msg}`)
        }

        async function removeServiceWorker() {
          let msg
          if(navigator && 'serviceWorker' in navigator){
            const myRegistration = await navigator.serviceWorker.getRegistration()
            if(myRegistration){
              const isRemoved = await myRegistration.unregister()
              msg = `Service worker unregistered: ${isRemoved}`
            } else {
              msg = `No service worker registration`
            }
          } else {
            msg = `No service worker in navigator`
          }
          return msg
        }

        async function removeAllCaches() {
          let msg = `All Caches Removed`
          if(window.caches){
            const cacheNames = await getAllCacheNames()
            if(cacheNames.length > 0){
              await asyncForEach(cacheNames, deleteCache)
              let cacheNamesLeft = await getAllCacheNames()
              if(cacheNamesLeft.length > 0){
                msg = `Unable to remove caches : ${JSON.stringify(cacheNamesLeft)}`
              }
            }
          } else {
            msg = `No caches in window object`
          }

          return msg

          async function getAllCacheNames() {
            const names = await window.caches.keys()
            return names
          }

          async function deleteCache(name) {
            await window.caches.delete(name)
          }
        }
      }

    }

    async function GeneralCacheStatus({modal}) {
      const allStatus = {
        appcache: GetAppCacheStatus(),
        serviceworker: await GetServiceWorkerStatus()
      }
      const container = AddGenCacheStatusContainer(modal)
      Object.entries(allStatus).forEach(x => {
        AddStatusToUI(x[1], container)
      })

      return allStatus

      function AddGenCacheStatusContainer(modal){
        let container = document.createElement('div')
        container.id = 'generalCacheStatusContainer'
        modal.append(container)
        return container
      }

      function GetAppCacheStatus() {
        if( window && window.applicationCache ){
          return AppCacheExists()
        } else {
          return AppCacheNotPresent()
        }

        function AppCacheExists() {
          const result = {
            type: "Application Cache",
            legend: cs_config.statusTypes.appcache,
            status: GetCurrentAppCacheStatus(),
            StatusRefresh: GetCurrentAppCacheStatus
          }

          return result

          function GetCurrentAppCacheStatus() {
            const statusCodes = GetAppCacheStatusCodes()
            const result = statusCodes[window.applicationCache.status]
            return result
          }


        }

        function AppCacheNotPresent() {
          const result = {
            type: "Application Cache",
            legend: [],
            status: "AppCache Not Supported In This Browser."
          }
          return result
        }

      }

      function GetServiceWorkerStatus() {
        if (navigator && 'serviceWorker' in navigator) {
          return ServiceWorkerSupported()
        } else {
          return ServiceWorkerNotSupported()
        }

        async function ServiceWorkerSupported() {
          const myServiceWorkerRegistration = await navigator.serviceWorker.getRegistration()
          const statusLegend = cs_config.statusTypes.serviceworker
          const result = {
            type: "Service Worker",
            legend: MakeLegend(),
            status: GetCurrentSWStatus(),
            StatusRefresh: GetCurrentSWStatus
          }

          return result

          function GetCurrentSWStatus() {
            const {active,waiting} = myServiceWorkerRegistration ? myServiceWorkerRegistration : {active: false, waiting: false}
            if(!active && !waiting){
              return statusLegend.none
            } else {
              if(!waiting){
                return statusLegend.active
              } else if(!active){
                return statusLegend.waiting
              } else {
                return statusLegend.active_waiting
              }
            }
          }

          function MakeLegend() {
            let result = []
            Object.entries(statusLegend).forEach(x => result.push(x[1]))
            return result
          }
        }

        function ServiceWorkerNotSupported() {
          const result = {
            type: "Service Worker",
            legend: [],
            status: "Service Worker Not Supported In This Browser."
          }
          return result
        }
      }

      function AddStatusToUI(StatusConfig, modal) {
        const {type, legend, status, StatusRefresh} = StatusConfig
        const container = document.createElement('div')
        container.classList.add('statusContainer')

        modal.append(container)
        container.append(MakeHeader(type), MakeStatus(status, StatusRefresh) , MakeStatusLegend(legend))

        function MakeHeader(name) {
          const header = document.createElement('h4')
          header.innerText = name
          return header
        }

        function MakeStatus(status, refresh) {
          const currentStatus = document.createElement('div')

          const value = document.createElement('p')
          value.innerText = `Status = ${status}`
          currentStatus.append(value)

          // if(refresh){
          //     const refreshBtn = document.createElement('button')
          //     refreshBtn.innerText = 'Refresh Status'
          //     refreshBtn.onclick = (e) => {
          //         e.stopPropagation()
          //         refresh()
          //     }
          //     currentStatus.append(refreshBtn)
          // }

          return currentStatus
        }

        function MakeStatusLegend(legend) {
          const statusLegendContainer = document.createElement('div')

          const toggleBtn = document.createElement('button')
          toggleBtn.innerText = 'Status Legend'
          toggleBtn.onclick = OpenStatusLegend

          const statusLegend = document.createElement('ul')
          statusLegend.classList.add('statusLegendList')

          statusLegendContainer.append(toggleBtn)

          if(legend.length > 0){
            legend.forEach(x => {
              const statusItem = document.createElement('li')
              statusItem.innerText = x
              statusLegend.append(statusItem)
            })
            return statusLegendContainer
          } else {
            return null
          }

          function OpenStatusLegend (){
            statusLegendContainer.append(statusLegend)
            toggleBtn.innerText = `Close Possible Status`
            toggleBtn.onclick = CloseStatusLegend
          }

          function CloseStatusLegend() {
            statusLegend.remove()
            toggleBtn.innerText = 'Status Legend'
            toggleBtn.onclick = OpenStatusLegend
          }
        }
      }
    }

    async function AxisNowServiceWorkerTest({generalStatus, modal}) {
      const {serviceworker} = generalStatus
      const currentStatus = serviceworker.StatusRefresh()
      if(currentStatus !== cs_config.statusTypes.serviceworker.none){
        AxisNowSWStatus({modal})
      }



      async function AxisNowSWStatus({modal}) {
        const container = AddContainerToModal({modal})
        const contentContainer = AddContentContainer({container})
        await AddAllStatus({container: contentContainer})

        function GetConfig() {

          const config = {
            rootURL: location.origin,
            htmlFileName: 'index.html',
            swConfigFileName: "sw_config.json",
            registerFileName: "register_min.js",
            expectedSWConfigKeys : ['serviceWorker_path', 'abort_serviceWorker_install', 'web_app_version', 'paths','network_first'],
            statusItems: {
              sw_config_file: {
                name: "SW Config File",
                currentStatus: SWConfigFileStatus
              },
              register_file: {
                name: "Register File",
                currentStatus: RegisterFileStatus
              },
              worker_file: {
                name: "Worker File",
                currentStatus: WorkerFileStatus
              },
              html_file: {
                name: "HTML File",
                currentStatus: IndexHTMLStatus
              },
              cache: {
                name: "Cache",
                currentStatus: CacheStatus
              }

            },
            statusItemTypes: {
              VALID: "VALID",
              INVALID: "INVALID",
              DOES_NOT_EXIST: "DOES NOT EXIST",
              NO_SW_CONFIG: "Status Unavailable: sw_config unavailable",
              SW_CONFIG_INVALID: "Status Unavailable: sw_config invalid",
              OUTDATED: "OUTDATED"
            }
          }
          return config
        }

        function AddContainerToModal({modal}){
          const HOC = document.createElement('div')
          HOC.id = 'axisNowSWTestContainer'
          const container = document.createElement('div')
          HOC.append(container)
          modal.append(HOC)

          const title = document.createElement('h4')
          title.id = 'axisNowSWTestTitle'
          title.innerText = 'Axis Now Service Worker'
          container.append(title)
          return container
        }

        function AddContentContainer({container}){
          const contentContainer = document.createElement('div')
          contentContainer.id = 'axisNowStatusContentContainer'
          container.append(contentContainer)
          return contentContainer
        }

        async function AddAllStatus({container}) {
          const active_sw_version = await GetServiceWorkerVersion()
          if(!active_sw_version){
            let message = `Unable to retrieve Service Worker Version from IndexedDB`
            AddFeedback(message, {warning:true})
          }
          const sw_config = await GetServerSWConfig()
          const allStatus = await GetStatus()
          const statusContainer = document.createElement('div')
          const feedbackContainer = MakeFeedBackContainer()
          container.append(statusContainer, feedbackContainer)
          let isFeedback = false
          Object.values(allStatus).forEach(x => AddStatusToContainer(x, statusContainer))
          if(!isFeedback){
            const AllPassMessage = `AxisNow Service Worker [${active_sw_version}] is working as expected for AxisNow Reader [ ${sw_config.web_app_version} ]`
            AddFeedback(AllPassMessage)
          }

          function AddStatusToContainer({name, value}, container){
            let statusValue
            if(typeof value !== 'string'){
              statusValue = value.value
              AddFeedback(value.feedback)
            } else {
              statusValue = value
            }
            const statusItemContainer = document.createElement('div')
            statusItemContainer.classList.add('axisNowStatusItemContainer')
            container.append(statusItemContainer)

            const nameElm = document.createElement('p')
            nameElm.innerText = `${name} = `
            statusItemContainer.append(nameElm)

            const valueElm = document.createElement('h5')
            valueElm.innerText = statusValue
            statusItemContainer.append(valueElm)
            if(statusValue === 'VALID'){
              statusItemContainer.style.backgroundColor = 'lightgreen'
            } else {
              statusItemContainer.style.backgroundColor = 'yellow'
            }
          }

          function MakeFeedBackContainer() {
            const div = document.createElement('div')
            const title = document.createElement('h5')
            title.innerText = 'Feedback'
            div.append(title)
            return div
          }

          function AddFeedback(message, options){
            isFeedback = true
            let bg_color = "none"
            if(options){
              const {warning} = options
              if(warning){
                bg_color = "yellow"
              }
            }
            const feedback = document.createElement('p')
            feedback.classList.add('feedback')
            feedback.innerText = message
            feedback.style.backgroundColor = bg_color
            feedbackContainer.append(feedback)
          }
        }

        async function GetServiceWorkerVersion(){
          let version = null
          const db = await GetDB()

          if(db){
            version = await GetVersion(db)
          }

          return version

          function GetDB() {
            return new Promise((resolve) => {
              let request = indexedDB.open('service_worker')
              request.onsuccess = e => {
                let db = e.target.result
                resolve(db)
              }
              request.onerror = e => {
                resolve(null)
              }
            })
          }

          function GetVersion(db) {
            const obj_store = db.transaction("config").objectStore("config")
            const index = obj_store.index("is_active")
            const request = index.get('true')
            return new Promise((resolve) => {
              request.onsuccess = e => {
                const sw_config = request.result
                const {service_worker_version} = sw_config
                db.close()
                resolve(service_worker_version)
              }
              request.onerror = e => {
                resolve(null)
              }
            })
          }
        }

        async function GetStatus() {
          const config = GetConfig()
          const status = {}
          await asyncForEach(Object.entries(config.statusItems), makeItem)

          async function makeItem(x) {
            status[x[0]] = {
              name: x[1].name,
              value: await x[1].currentStatus()
            }
          }

          return status
        }

        async function GetServerSWConfig(){
          if(!__sw_config){
            const config = GetConfig()
            const path = config.rootURL + '/' + config.swConfigFileName
            const response = await fetch(path)
            if(response.status === 200){
              __sw_config = await response.json()
              return __sw_config
            } else {
              return null
            }
          } else {
            return __sw_config
          }
        }

        async function SWConfigFileStatus() {
          const config = GetConfig()
          const sw_config = await GetServerSWConfig()
          if(sw_config){
            const {status, feedback} = isSWConfigValid(sw_config)
            if(feedback){
              return {
                value: status,
                feedback
              }
            }
            return status
          } else {
            return config.statusItemTypes.DOES_NOT_EXIST
          }

          function isSWConfigValid(fetched_config) {
            let result = true
            let feedback = null
            let notPresent = []
            let presentKeys = Object.keys(fetched_config)
            let expectedKeys = config.expectedSWConfigKeys
            expectedKeys.forEach(x => {
              const check = presentKeys.includes(x)
              if(!check){
                notPresent.push(x)
              }
              result = result && check
            })
            if(notPresent.length > 0){
              feedback = `Properties not included in config: ${JSON.stringify(notPresent)}`
            }
            if(result){
              return {
                status: config.statusItemTypes.VALID,
                feedback
              }
            } else {
              return {
                status:config.statusItemTypes.INVALID,
                feedback
              }
            }
          }

        }

        async function RegisterFileStatus(){
          const config = GetConfig()
          const path = config.rootURL + "/" + config.registerFileName
          const response = await fetch(path)
          if(response.status === 200){
            return config.statusItemTypes.VALID
          } else {
            return config.statusItemTypes.DOES_NOT_EXIST
          }
        }

        async function WorkerFileStatus(){
          const config = GetConfig()
          const sw_config = await GetServerSWConfig()
          if(sw_config){
            if(!sw_config.serviceWorker_path){
              return config.statusItemTypes.SW_CONFIG_INVALID
            }
            const path = config.rootURL + "/" + sw_config.serviceWorker_path
            const response = await fetch(path)
            if(response.status === 200){
              return config.statusItemTypes.VALID
            } else {
              return config.statusItemTypes.DOES_NOT_EXIST
            }
          } else {
            return config.statusItemTypes.NO_SW_CONFIG
          }
        }

        async function IndexHTMLStatus() {
          const config = GetConfig()
          const htmlFileText = await GetHTMLFile()
          if(!htmlFileText){
            return config.statusItemTypes.DOES_NOT_EXIST
          }
          return isHTMLFileValid(htmlFileText)

          async function GetHTMLFile(){
            const path = config.rootURL + "/" + config.htmlFileName
            const response = await fetch(path)
            if (response.status == 200) {
              const htmlText = await response.text()
              return htmlText
            } else {
              return null
            }
          }

          function isHTMLFileValid(htmlText) {
            // Inspect HTML Text and see if the <script type="text/javascript" src="[ GetConfig().registerFileName ]"> is included
            const config = GetConfig()
            const registerFileName = config.registerFileName
            let result = false
            let scriptTag
            const matches = htmlText.match(/\<script\s[^>]*type[^>]*\>/g)
            matches.forEach(x => {
              if(x.includes(registerFileName)){
                scriptTag = x
              }
              result = result || x.includes(registerFileName)
            })
            if(result){
              return config.statusItemTypes.VALID
            } else {
              const feedback = `${registerFileName} script NOT FOUND in the reader html. Scripts found: ${JSON.stringify(matches)}`

              return {
                value: config.statusItemTypes.INVALID,
                feedback
              }
            }
          }

        }

        async function CacheStatus() {
          const config = GetConfig()
          const sw_config = await GetServerSWConfig()
          if(sw_config){
            const {web_app_version, paths} = sw_config
            const cacheNames = await window.caches.keys()
            const cacheName = cacheNames[0]
            if(cacheName){
              if(cacheName !== web_app_version){
                return config.statusItemTypes.OUTDATED
              }
              const cache = await window.caches.open(cacheName)
              const cached_reqs = await cache.keys()
              const cached_paths = cached_reqs.map(x => {
                return x.url.split(window.location.host)[1]
              })
              let notIncluded = []
              paths.forEach(x => {
                const result = cached_paths.includes(x)
                if(!result){
                  notIncluded.push(x)
                }
              })
              if(notIncluded.length > 0){
                const feedback = `The following paths were not cached: \n\n ${JSON.stringify(notIncluded)}`
                return {
                  value: config.statusItemTypes.INVALID,
                  feedback
                }
              } else {
                return config.statusItemTypes.VALID
              }
            } else {
              return config.statusItemTypes.DOES_NOT_EXIST
            }
          } else {
            return config.statusItemTypes.NO_SW_CONFIG
          }
        }
      }
    }
  }
}

export default ActivateCST;