var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"reading-area\" role=\"main\" class=\"page-view page-landing comic-landing\">\n\n  <div class=\"container\">\n\n    <!-- img aria-hidden=\"true\" src=\"images/pagination1.svg\" -->\n\n\n    <div id=\"readium-page-btns\" role=\"navigation\" class=\"page-navigation-buttons comic-page-nav-btns\">\n      <button id=\"left-page-btn\" class=\"page-switch-overlay-icon btn-default page-nav-button nav-left comic-nav-left-buttons\" \n        aria-label=\"Previous\" accesskey=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"keyboard") : depth0)) != null ? lookupProperty(stack1,"accesskeys") : stack1)) != null ? lookupProperty(stack1,"PagePreviousAlt") : stack1), depth0))
    + "\">\n        <span class=\"icon  icon-arrow-left\" aria-hidden=\"true\"></span>\n      </button>\n      <button id=\"right-page-btn\" class=\"page-switch-overlay-icon btn-default page-nav-button nav-right comic-nav-right-buttons\" \n        aria-label=\"Next\" accesskey=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"keyboard") : depth0)) != null ? lookupProperty(stack1,"accesskeys") : stack1)) != null ? lookupProperty(stack1,"PageNextAlt") : stack1), depth0))
    + "\" style=\"\">\n        <!-- img aria-hidden=\"true\" src=\"images/pagination1.svg\" -->\n        <span class=\"icon  icon-arrow-left\" aria-hidden=\"true\"></span>\n      </button>\n    </div>\n\n    <!-- <select id=\"pageOnly\" name=\"pageOnly\">\n      <option value=\"page\">Page Viewer</option>\n    </select> -->\n    <!-- This section is moved to navbar-->\n    <!--div class=\"viewer-changer\">\n      <select id=\"viewerSelect\" class=\"viewer-select\" name=\"viewerSelect\">\n          <option value=\"page\">Page Viewer</option>\n          <option value=\"panel\">Panel Viewer</option>\n        </select>\n    </div> -->\n\n    <div class=\"img-magnifier-container\" id=\"img-magnifier-container\">\n      <img src=\"#\" alt=\"Main Content\" aria-label=\"Main Content\" class=\"baseImg\" id=\"comicImg\">\n    </div>\n\n  </div>\n  <div id=\"checkOutSnackbar\"></div>\n</div>\n<div id=\"statusDiv\" role=\"alert\" aria-live=\"assertive\" tabindex=\"-1\" style=\"position: absolute; height: 0px; width: 0px; opacity: 0.01; overflow: hidden;\"></div>\n<footer class=\"app-footer\">\n    <div class=\"container\">\n        \n    </div>\n\n</footer>";
},"useData":true});