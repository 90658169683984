import $ from 'jquery';
import _ from 'underscore';
import utils from '@axisnow/util/utils';
import Promise from 'bluebird';
import Helpers from '../EpubReaderHelpers';
import errorMonitor from '../ErrorMonitor';
import PopupFactory from '../popup/PopupFactory';
import moment from 'moment-timezone';

var usageManager, readium, findaway, comic;
var noBookmarkPopup = PopupFactory.create(PopupFactory.TYPE.EMPTY_NO_BOOKMARK);

var AnnotationUtils = function(readium_, usageMgr, findaway_, _comic) {
  usageManager = usageMgr || usageManager;
  readium = readium_ || readium;
  findaway = findaway_ || findaway;
  comic = _comic
};

var isAudiobook = function() {
  return !!findaway;
};

var isComicbook = function () {
  return !!comic;
}

let getAnnotUsage = function (isbn) {
  if(isAudiobook()){
    return usageManager.getAudioAnnotationsUsage(isbn);
  } else if(isComicbook()){
    return usageManager.getComicBookAnnotationsUsage(isbn);
  } else {
    return usageManager.getAnnotationsUsage(isbn);
  } 
}

var addAnnotation = function(idref, annotationObj, isbn) {
  isbn = annotationObj.isbn || isbn;
  return getAnnotUsage(isbn)
  .then(function(annotUsage) {
    var annotationsUsage = annotUsage;
    function findAnnotation(element, index, array) {
      return element.location == annotationObj.location;
    }

    function saveAnnotation(idref, annotationObj) {
      if (isAudiobook()) {
        annotationsUsage.addAudioPosition(idref, annotationObj);
        return usageManager.saveAudioPositionsToRemote(isbn);
      }
      annotationsUsage.addAnnotation(idref, annotationObj);
      if(isComicbook()){
        return usageManager.saveComicBookAnnotationsToRemote(isbn);
      }
      return usageManager.saveAnnotationsToRemote(isbn);
    }

    return utils
      .isOnline()
      .then(function() {
        console.log('Saving annotation online');
        return saveAnnotation(idref, annotationObj);
      })
      .catch(function(error) {
        console.log('Saving annotation offline');
        var index;
        var annotationsArray;

        if (isAudiobook()) {
          if (annotationsUsage.audiopositions) {
            annotationsArray = annotationsUsage.spines[idref].annotations;
            var audioPositionArray = annotationsUsage.audiopositions;
            index = annotationsArray.audioPositionArray(findAudioPosition, audioPositionObj);

            if (index > -1) {
              console.log('Annotation found in indexedDB');
              annotationsUsage.updateAnnotation(idref, annotationObj)
              return usageManager.saveAudioPositionsToRemote(isbn);
            } else {
              console.log('Annotation not found in indexedDB');
              saveAnnotation(idref, annotationObj);
            }
          } else {
            console.log('Annotation not found in indexedDB');
            saveAnnotation(idref, annotationObj);
          }
          return;
        } else if(isComicbook()){
          if (annotationsUsage.sections[idref]) {
            annotationsArray = annotationsUsage.sections[idref].annotations;
            index = annotationsArray.findIndex(findAnnotation, annotationObj);
            if (index > -1) {
              console.log('Annotation found in indexedDB');
              annotationsUsage.updateAnnotation(idref, annotationObj);
              return usageManager.saveComicBookAnnotationsToRemote(isbn);
            } else {
              console.log('Annotation not found in indexedDB');
              saveAnnotation(idref, annotationObj);
            }
          } else {
            console.log('Annotation not found in indexedDB');
            saveAnnotation(idref, annotationObj);
          }
        } else {
          if (annotationsUsage.spines[idref]) {
            annotationsArray = annotationsUsage.spines[idref].annotations;
            index = annotationsArray.findIndex(findAnnotation, annotationObj);
            if (index > -1) {
              console.log('Annotation found in indexedDB');
              annotationsUsage.updateAnnotation(idref, annotationObj);
              return usageManager.saveAnnotationsToRemote(isbn);
            } else {
              console.log('Annotation not found in indexedDB');
              saveAnnotation(idref, annotationObj);
            }
          } else {
            console.log('Annotation not found in indexedDB');
            saveAnnotation(idref, annotationObj);
          }
        }
      });
  });
};

var showBookmarkUnavailablePopup = function() {
  noBookmarkPopup.setTitle('Bookmark Unavailable');
  noBookmarkPopup.setMessage(
    'Bookmarks cannot be made on empty pages. Please move to a page with visible content to make a bookmark.',
  );
  if (!noBookmarkPopup._buttons.length) {
    noBookmarkPopup.addButton(
      'OK',
      function() {
        noBookmarkPopup.hide();
      },
      true,
    );
  }
  noBookmarkPopup.show();
};

//Public API
AnnotationUtils.prototype.getAnnotation = function(idref, location, isbn) {
  if (isAudiobook()) {
    return usageManager.getAudioAnnotationsUsage(isbn).then(function(annotationsUsage) {
      return annotationsUsage.getAudioPosition(isbn);
    })
  } else if(isComicbook()){
    return usageManager.getComicbookAnnotationsUsage(isbn).then(function(annotationsUsage) {
      return annotationsUsage.getAnnotation(idref, location);
    })
  }
  return usageManager.getAnnotationsUsage(isbn).then(function(annotationsUsage) {
    return annotationsUsage.getAnnotation(idref, location);
  })
};
AnnotationUtils.prototype.clearSelection = function() {
  if (isAudiobook()) {
    return;
  }

  var iframes = $('#epub-reader-container').find('iframe');
  _.each(iframes, function(iframe) {
    if (iframe.contentDocument) {
      iframe.contentDocument.getSelection().removeAllRanges();
    }
  });
};

AnnotationUtils.prototype.saveHighlights = function(color, highlightsRange, selectedText, isbn) {
  if (isAudiobook()) {
    return;
  }

  var fullCFI = Helpers.locObjToFullCFI(readium.packageDocument, highlightsRange, false);
  console.debug('************ START Saving Highlights ************');

  console.log('Highlights selected CFI : ' + fullCFI);
  console.log('Highlighted Text - ' + selectedText);

  return addAnnotation(
    highlightsRange.idref,
    {
      type: 'Highlight',
      createdate: this.getCurrentDate(),
      label: 'Highlight',
      color: color,
      location: fullCFI,
      booktext: selectedText,
      notetext: '',
      newAnnotation: true,
    },
    isbn,
  ).catch(function(err) {
    console.error(err);
  });
  console.debug('************ END Saving Highlights ************');
};

AnnotationUtils.prototype.saveNotes = function(color, noteText, notesRange, selectedText, isbn) {
  if (isAudiobook()) {
    return;
  }

  var fullCFI = Helpers.locObjToFullCFI(readium.packageDocument, notesRange, false);
  console.log('Note Text', noteText);
  console.log('Notes Selected CFI : ' + fullCFI);

  return addAnnotation(
    notesRange.idref,
    {
      type: 'Note',
      createdate: this.getCurrentDate(),
      label: 'Note',
      color: color,
      location: fullCFI,
      booktext: selectedText,
      notetext: noteText,
      newAnnotation: true,
    },
    isbn,
  ).catch(function(err) {
    console.error(err);
  });
};

AnnotationUtils.prototype.getBookmarkLabel = function(bookmark, length) {
  if (isAudiobook()) {
    var track = findaway.currentTrack;
    var label = track === 0 ? 'Introduction' : 'Chapter ' + track;

    if (label.length > length) {
      label = label.trim().substr(0, length);
    }

    return label;
  }

  var firstVisibleCfi = readium.reader.getFirstVisibleCfi(bookmark.idref);
  var lastVisibleCfi = readium.reader.getLastVisibleCfi(bookmark.idref);
  var workaroundNeeded = !lastVisibleCfi || !lastVisibleCfi.contentCFI;

  var bookmarkRange = readium.reader.getDomRangeFromRangeCfi(firstVisibleCfi, lastVisibleCfi);
  var textRegion = '';
  if (workaroundNeeded) {
    var warningError = new Error(
      'Bookmark label failed to be generated. Using fallback workaround.',
    );
    bookmarkRange.setEndAfter(bookmarkRange.endContainer);
    textRegion = bookmarkRange.toString();
    if (textRegion.length < length && bookmarkRange.endContainer.nextSibling) {
      bookmarkRange.setEndAfter(bookmarkRange.endContainer.nextSibling);
      textRegion = bookmarkRange.toString();
    } else if (textRegion.length < length) {
      warningError = new Error(
        'Bookmark label failed to be generated. Workaround method also failed.',
      );
    }
    errorMonitor.bugsnag(warningError);
    console.warn(warningError.message);
  } else {
    textRegion = bookmarkRange.toString();
  }
  textRegion = textRegion.replace(/\s+/g, ' ');
  return { 'label':textRegion.trim().substr(0, length), 'range':bookmarkRange };
};

AnnotationUtils.prototype.saveBookmarks = function(isbn) {
  var bookmark, location, label, idref;
  var error = null;

  if (isAudiobook()) {
    bookmark = null;
    var trackObj = findaway.currentTitle.playlist[findaway.currentTrack];
    var timeoffset = JSON.parse(findaway.thisSecond);
    console.log('audiobook location.track : ' + findaway.currentTrack);
    console.log('audiobook location.chapter : ' + trackObj.chapter_number);
    console.log('audiobook location.part : ' + trackObj.part_number);
    return usageManager.getAudioAnnotationsUsage(isbn).then(function(annotationsUsage) {
      var audioPositions = annotationsUsage.audiopositions;
      var foundIndex = -1;
      $.each(audioPositions, function(index, element) {
        if (
          element.chapter === trackObj.chapter_number.toString() &&
          element.timeoffset === timeoffset * 1000 &&
          trackObj.part_number.toString() === element.part
        ) {
          foundIndex = index;
          return false;
        }
      });
      if (foundIndex !== -1) {
        return audioPositions;
      }
      return addAnnotation(isbn, {
        uuid: annotationsUsage.uuid,
        isbn: isbn,
        chapter: trackObj.chapter_number,
        part: trackObj.part_number,
        timeoffset: timeoffset * 1000,
        usercreatedate: this.getCurrentDate(),
        newAnnotation: true,
      }).catch(function(err) {
        console.error(err);
      });
    }.bind(this));
  } 
  else if (isComicbook()) {
    var currPage = window.sessionStorage.getItem('COMIC_CURRENT_PAGE');
    var curArea = window.sessionStorage.getItem('COMIC_CURRENT_AREA');
    console.log('currentPage', comic, currPage, curArea);

    var pageTitle;
    if(currPage == null || currPage == -1){
      pageTitle =  'Page ' + comic.comicMetaData.title;
    }else if(currPage == 0 || currPage == 1){
      pageTitle = 'Page ' + comic.comicMetaData.title;
    } else{
      pageTitle = comic.comicMetaData.pages[(parseInt(currPage) - 2)].title;
    }

    var location = (currPage == null) ? '-1' : currPage;

    if (curArea && curArea != -1) {
      location = location + '|' + curArea;
      pageTitle = pageTitle + ';Frame ' + (parseInt(curArea) + 1);
    }

    location = 'cbzloc(' + location + ')';

    return addAnnotation(
      pageTitle,
      {
        type: 'Bookmark',
        createdate: this.getCurrentDate(),
        label: pageTitle,
        location: location,
        booktext: pageTitle,
        notetext: 'User entered text for bookmark',
        newAnnotation: true,
      },
      isbn,
    ).catch(function (err) {
      console.error(err);
    });
  } else {
    bookmark = JSON.parse(readium.reader.bookmarkCurrentPage());
    console.log('bookmark.idref : ' + bookmark.idref);
    console.log('bookmark.contentCFI : ' + bookmark.contentCFI);
    location = Helpers.locObjToFullCFI(readium.packageDocument, bookmark);
    idref = bookmark.idref;
    //console.log("bookmark fullCFI: " + fullCFI);
  }

  if (!bookmark.contentCFI) {
    var loadedSpineItems = readium.reader.getLoadedSpineItems();

    if (loadedSpineItems[1]) {
      //get new bookmark for other page view
      idref = loadedSpineItems[1].idref;
      bookmark = readium.reader.getFirstVisibleCfi(idref);
      if (!bookmark.contentCFI) {
        showBookmarkUnavailablePopup();
        error = new Error('Bookmarks cannot be made on empty pages');
      }
      location = Helpers.locObjToFullCFI(readium.packageDocument, bookmark);
    } else {
      showBookmarkUnavailablePopup();
      error = new Error('Bookmarks cannot be made on empty pages');
    }
  }

  if (error) {
    return Promise.reject(error);
  }

  var labelAndRange = this.getBookmarkLabel(bookmark, 20);
  label = labelAndRange.label;
  console.log('label : ' + label);
  if (label == '') {
    var $iframe = readium.reader.getLoadedContentFrames()[0].$iframe;
    if ($iframe.contents().find('body div.cover').length > 0) {
      label = $('.book-title-header').text();
    } else {
      var bookmarkRange = labelAndRange.range;
      var imgElement = (bookmarkRange.collapsed && bookmarkRange.startContainer.nodeName.toLowerCase() === 'img') ?
        bookmarkRange.startContainer : bookmarkRange.cloneContents().querySelector('img');
      var imgALTtext = !!imgElement ? $(imgElement).attr('alt') : 'Blank Page';
      if (!imgALTtext) {
        var pageLabel = readium.reader.plugins.pagination.getVisibleLabelItems();
        imgALTtext = pageLabel.length > 0 ? `Page label ${pageLabel[0].label.label}` : 'Image';
      }
      label = imgALTtext;
    }
  }
  return addAnnotation(
    idref,
    {
      type: 'Bookmark',
      createdate: this.getCurrentDate(),
      label: label,
      color: '#0000FF', //blue
      location: location,
      booktext: label,
      notetext: 'User entered text for bookmark',
      newAnnotation: true,
    },
    isbn,
  ).catch(function(err) {
    console.error(err);
  });
};

AnnotationUtils.prototype.updateAnnotation = function(idref, annotationObj, isbn) {
  return getAnnotUsage(isbn).then(function(annotUsage) {
    var annotationsUsage = annotUsage;
    if (isAudiobook()) {
      annotationsUsage.updateAudioPosition(idref, annotationObj);
      return usageManager.saveAudioPositionsToRemote(isbn);
    }
    annotationsUsage.updateAnnotation(idref, annotationObj);
    return usageManager.saveAnnotationsToRemote(isbn);
  });
};

AnnotationUtils.prototype.deleteAnnotation = function(idref, location, isbn) {
  return getAnnotUsage(isbn).then(function(annotUsage) {
    var annotationsUsage = annotUsage;
    if (isAudiobook()) {
      annotationsUsage.deleteAudioPosition(idref, location);
      return usageManager.deleteAudioPositionsFromRemote(isbn);
    }
    annotationsUsage.deleteAnnotation(idref, location);
    return usageManager.deleteAnnotationsFromRemote(isbn);
  });
};

AnnotationUtils.prototype.getSelectionText = function(iframe) {
  var win = iframe.contentWindow;
  var doc = iframe.contentDocument || win.document;

  if (win.getSelection) {
    return win.getSelection().toString();
  } else if (doc.selection && doc.selection.createRange) {
    return doc.selection.createRange().text;
  }
};

AnnotationUtils.prototype.getCurrentDate = function() {
  let currentDate = moment().utc().format('MM/DD/YYYY hh:mm:ss A Z');
  return currentDate;
};

export default AnnotationUtils;
