import _ from 'underscore';

function AudioAnnotationsUsage(id, uuid, profileId, profileType, isPrimary, eventEmitter) {
  var audioPositions = [];

  Object.defineProperties(this, {
    uuid: {
      value: uuid,
      writable: true,
      enumerable: true,
    },
    id: {
      value: id,
      writable: true,
      enumerable: true,
    },
    profileid: {
      value: profileId,
      writable: true,
      enumerable: true,
    },
    profileType: {
      value: profileType,
      writable: true,
      enumerable: true,
    },
    isPrimary: {
      value: isPrimary,
      writable: true,
      enumerable: true,
    },
    _eventEmitter: {
      value: eventEmitter,
      writable: false,
      enumerable: false,
    },
    uuid_id: {
      value: uuid +'|'+id,
      writable: true,
      enumerable: true,
    },
    uuid_profile_id: {
      value: uuid + '|' + profileId + '|' + id,
      writable: true,
      enumerable: true,
    },
    audiopositions: {
      get: function() {
        return audioPositions;
      },
      set: function(value) {
        if (value instanceof Array) {
          value.forEach(function(element, index) {
            element.type = 'Bookmark';
          });
          audioPositions = value;
        } else {
          throw new Error('Unable to set audiopositions. Invalid data.');
        }
        eventEmitter.emit('new:updated', this);
      },
      enumerable: true,
    },
  });
}

AudioAnnotationsUsage.usageName = 'AudioAnnotationsUsage';

AudioAnnotationsUsage.sortBy = {
  DATE: function(annotationsArray, ascending) {
    annotationsArray = annotationsArray.sort(function(a, b) {
      var dateA = new Date(a.usercreatedate);
      var dateB = new Date(b.usercreatedate);

      if (ascending) {
        return dateB - dateA;
      } else {
        return dateA - dateB;
      }
    });
    return annotationsArray;
  },
};

//Public API
AudioAnnotationsUsage.prototype.sortBy = AudioAnnotationsUsage.sortBy;

AudioAnnotationsUsage.prototype.addAudioPosition = function(track, audioPosition) {
  var audioPositions = this.audiopositions;
  audioPosition.newPosition = true;
  audioPositions.push(audioPosition);
  this.audiopositions = audioPositions;
};

AudioAnnotationsUsage.prototype.updateAudioPosition = function(track, audioPosition) {
  var foundIndex = _.findIndex(this.audiopositions, function(element) {
    return element.chapter === audioPosition.chapter;
  });

  if (foundIndex === -1) {
    console.log('Did not find the position to be updated');
  } else {
    console.log('Position updated');
    this.audiopositions[foundIndex].updatePosition = true;
  }
};

AudioAnnotationsUsage.prototype.deleteAudioPosition = function(track) {
  var foundIndex = _.findIndex(this.audiopositions, function(element) {
    return element.chapter === track;
  });

  if (foundIndex === -1) {
    console.log('Did not find the position to be deleted');
  } else {
    console.log('Deleting position');
    this.audiopositions[foundIndex].deletePosition = true;
  }
};

AudioAnnotationsUsage.prototype.getAudioPosition = function(track) {
  var foundIndex = _.findIndex(this.audiopositions, function(element) {
    return element.chapter === track;
  });

  if (foundIndex === -1) {
    console.log('Did not find the position');
  } else {
    console.log('Found position');
    return this.audiopositions[foundIndex];
  }
};

AudioAnnotationsUsage.prototype.isChanged = function() {
  var isChanged = false;

  for (var audioPosition in this.audiopositions) {
    if (audioPosition.newPosition || audioPosition.updatePosition || audioPosition.deletePosition) {
      isChanged = true;
      break;
    }
  }

  return isChanged;
};

//Sort Annotations Array
AudioAnnotationsUsage.prototype.getSorted = function(type, sortBy, ascending) {
  var annotationsArray = this.audiopositions;
  var annotationsArraySorted = sortBy(annotationsArray, ascending);

  return annotationsArraySorted;
};

AudioAnnotationsUsage.prototype.update = function(annotationsUsage) {
  if (annotationsUsage.id == this.id && annotationsUsage.uuid == this.uuid) {
    if (!(annotationsUsage instanceof AudioAnnotationsUsage)) {
      var usage = new AudioAnnotationsUsage(annotationsUsage.id, annotationsUsage.uuid, eventEmitter);
      usage.audiopositions = annotationsUsage.audiopositions;
      annotationsUsage = usage;
    }
    this._eventEmitter.emit('new:updated', annotationsUsage);
  } else {
    throw new Errors.BadData("The ISBN and/or UUID values don't match.");
  }
  return annotationsUsage;
};

export default AudioAnnotationsUsage;
