import Popup from './Popup';
import PopupMenu from './PopupMenu';
import PopupMenuHeaderContentsTemplate from '../templates/popup/popup-menu-header-contents-with-search-box.hbs';

var PopupMenuWithSearchBox = Popup.prototype.extends(function(popupNumber, type, title, options) {
  options = this.appendPopup(options);
  this.popupId = options.id;
  this.size = 0;
  //this.popupNum = popupNum;
  this.popupCountClass = 'popup-menu-' + this.popupNum;

  //var $wrapper = $(options.wrapper);

  //
  this.$header = this.getHeader().append(PopupMenuHeaderContentsTemplate({ title: title }));
  this.$header.addClass('header-lg');

  this.$header.prependTo(this.$el);

  this.$el.addClass('popup-sticky').addClass(this.popupCountClass);
  if (this.popupId) {
    this.$el.attr('id', this.popupId);
  }

  this.$el.addClass('popup-navbar popup-right');

  //$wrapper.append(this.$el);
  var popUpCloseButton = this.$el.find('.close');
  this.$el.find('#txtSearch').on('keyup', function(event) {
    if (event.keyCode === 27) {
      popUpCloseButton.trigger('click');
    }
  });
});

PopupMenuWithSearchBox.prototype = Object.create(PopupMenu.prototype);
PopupMenuWithSearchBox.prototype.constructor = PopupMenuWithSearchBox;

export default PopupMenuWithSearchBox;
