import $ from 'jquery';
import _ from 'underscore';
import Strings from './i18n/Strings';
import Settings from './storage/Settings';
import Keyboard from './Keyboard';
import VideoPlayer from './templates/videobook-player.hbs';
import utils from '@axisnow/util/utils';
import UsageManager from './usage/UsageManager';
import Managers from '@axisnow/data/Managers';
import UsageService from './usage/UsageService';
import EngageManager from './EngageManager';
import splashMessage from './SplashMessage';
import PopupFactory from './popup/PopupFactory';
import ReaderBookTitleHeader from './templates/reader-book-title-header.hbs';

var usageManager, usageService;
var $appContainer;
var video;
var id;
var url;
//var flag; // TODO later
var update = false;
var remoteInterval;
var localInterval;
var lastSeenPos = 0;
var engageManager;
var videoNetworkInterval;
var captions;
var videoTitle;
var videoId;
var source;
var seekBar;
var isPaused = false;
var popupModalFormatType;
var videoCaptionStatus;

function loadUI(data) {
  $appContainer = $('#app-container');
  $appContainer.empty();
  var $docBody = $(document.body);
      $docBody.addClass('video-ui');
  console.log('hey i am video player load UI', data);
  
  usageManager = new UsageManager(Managers.user);
  usageService = new UsageService();
  engageManager = new EngageManager(Managers.user);
  /**
   * Check if browser is reload or not
   */
  var check_video_reload = JSON.parse(window.sessionStorage.getItem('VIDEO_DETAILS') || '{}');
   if (Object.keys(check_video_reload).length > 0) {
    id = check_video_reload.id;
    videoTitle = check_video_reload.videoTitle;
    videoId = check_video_reload.videoId;
    url = check_video_reload.url;
    captions = check_video_reload.caption;
    videoCaptionStatus = check_video_reload.videoCaptionStatus;
   } else {
    id = data.itemId;
    url = data.videoUrl;
    captions = data.caption;
    videoTitle = data.videoTitle;
    videoId = data.videoId;
  }
  $appContainer.append(
  VideoPlayer({ strings: Strings, /* dialogs: Dialogs, BH-325 */ keyboard: Keyboard, url:url}),
  );
   getLastSeenposition();
   $('#app-navbar').hide(); //hide the header
   $('html, body').css('overflow', 'hidden');
   onPlayerReady();
   bindEvents();
   //setBookTitle(videoTitle) //video title name updated in tab
   
  $(window).on('beforeunload', function() {
    /**
     *  reader having reload issue, so we captured last watching position and video id on local storage
    */     
    window.sessionStorage.setItem('PAGE_REFRESH',true);
    window.sessionStorage.setItem('VIDEO_DETAILS', JSON.stringify({id:id, videoId:videoId, videoTitle:videoTitle, url:url, caption: captions, videoCaptionStatus:video.textTracks[0].mode}));
    window.sessionStorage.setItem('VIDEO_CURRENT_TIME', video.currentTime);
});
}

function onPlayerReady() {
  
  Settings.get('reader', function(json) {
    Keyboard.applySettings(json);
    $appContainer.show();
     initSaveIntervals();
     splashMessagePopup(); // when internet is not available popup display
  });
}
/**
 * Initialize Video player Ready
 */
function bindEvents() {
  try {
  var videoContainer = document.getElementById("video-container");  
	// Video
	  video = document.getElementById("video");
	// Buttons
	var playButton = document.getElementById("play-pause");
	var muteButton = document.getElementById("mute");
	var fullScreenButton = document.getElementById("full-screen");
      source = document.getElementById('change-src');

	// Sliders
	    seekBar = document.getElementById("seek-bar");
	var volumeBar = document.getElementById("volume-bar");
	var currentTime = document.getElementById("currentTime");
	var durationTime = document.getElementById("durationTime");
  var fullScreenToggle = document.getElementById("fullScreenToggle");
	var capButton = document.getElementById("capButton");
  var capBtnToggle = document.getElementById("capBtnToggle");
	var videoTitleName = document.getElementById("topText");
      videoTitleName.innerHTML = videoTitle; // video title set
  video.addEventListener("contextmenu", (e) => {e.preventDefault()}); // disable mouse right click
  
  /**
   * Captions and subtitles added
   */
  if (captions.length == 0)   
    $('#capButton').hide(); // hide the caption button

   //screen click play and pause
   video.addEventListener("click", function() {      
    if (video.paused == true) {
       video.play();
       isPaused = true;
       switchPlay_PauseIcon();
       var css = '#video-container:hover #video-controls{opacity: 0.7;transition: opacity 0s;}';
       var style = document.createElement('style');
       style.appendChild(document.createTextNode(css));
       document.getElementById('video-container').appendChild(style);
       document.getElementById('video-controls').style.opacity = null;
    } else {
      video.pause();
      isPaused = true;
      document.getElementById('video-controls').style.opacity = 0.7;
      switchPlay_PauseIcon();
      update = true;
      savePlace();
      trackPageActivity(video.currentTime, video.duration) //save the last seen position  of video
    }
         
   })
	// Event listener for the play/pause button
	playButton.addEventListener("click", function() {
		if (video.paused == true) {
			// Play the video
			video.play();
      isPaused = false;
      switchPlay_PauseIcon();
      var css = '#video-container:hover #video-controls{opacity: 0.7;transition: opacity 0s;}';
       var style = document.createElement('style');
       style.appendChild(document.createTextNode(css));
       document.getElementById('video-container').appendChild(style);
       document.getElementById('video-controls').style.opacity = null;
		} else {
			// Pause the video
			video.pause();
      isPaused = true;
      document.getElementById('video-controls').style.opacity = 0.7;
      update = true;
      savePlace();
      trackPageActivity(video.currentTime, video.duration) //save the last seen position  of video
      switchPlay_PauseIcon();
		}
	});

 video.onended = function() {
    $('#play-pause span:first').attr('class', 'icon icon-icon-video-play');
    update = true;
    trackPageActivity(video.currentTime, video.duration) //save the last seen position  of video
   };

  video.onerror = function() {
   //get signed url when browser reload happen or video token got expired
   GetSignedUrl(id, videoId)  

  }

	// Event listener for the mute button
	capButton.addEventListener("click", function() {
		 if (captions.length > 0 && video.textTracks.length == 0 && capBtnToggle.classList.contains('icon-cc-off')) {
       AppendCaption(); //display caption subtitles
     } else if (video.textTracks.length > 0 && capBtnToggle.classList.contains('icon-cc-off')) { 
       video.textTracks[0].mode = "showing";
       $('#capButton span:first').attr('class', 'icon icon-cc-on');
     } else {
       video.textTracks[0].mode = "hidden";
       $('#capButton span:first').attr('class', 'icon icon-cc-off');
     }
	});

	// Event listener for the mute button
	muteButton.addEventListener("click", function() {
		if (video.muted == false) {
			// Mute the video
			video.muted = true;
      $('#mute span:first').attr('class', 'icon icon-mute');
		} else {
			// Unmute the video
			video.muted = false;      
      $('#mute span:first').attr('class', 'icon icon-unmute');
		}
	});


	// Event listener for the full-screen button
	fullScreenButton.addEventListener("click", function() {
    /**
     * Check full screen or not
     */
		if((document.fullscreenElement !== null && document.fullscreen === true)||
     document.webkitCurrentFullScreenElement === true || fullScreenToggle.classList.contains('icon-exit-full-screen')){
      $('#full-screen span:first').attr('class', 'icon icon-full-screen');
      if (document.exitFullscreen) {
        document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
    }
			// document.webkitExitFullscreen();
		} else {
      if (videoContainer.requestFullscreen) {
        videoContainer.requestFullscreen();
      } else if (videoContainer.mozRequestFullScreen) {
        videoContainer.mozRequestFullScreen(); // Firefox
      } else if (videoContainer.webkitRequestFullscreen) {
        videoContainer.webkitRequestFullscreen(); // Chrome and Safari
      }
      $('#full-screen span:first').attr('class', 'icon icon-exit-full-screen');
  }
	});
 /**
  * seekbar-click event captured
  */
  $("#seek-bar").on("click", function(e){
  var offset = $(this).offset();
  var left = (e.pageX - offset.left);
  var totalWidth = $("#seek-bar").width();
  var percentage = ( left / totalWidth );
  var vidTime = video.duration * percentage;
  video.currentTime = vidTime;
  console.log("inside Seek-bar new method")
  });//click()

	// Event listener for the seek bar
	seekBar.addEventListener("change", function() {
		// Calculate the new time
		var time = video.duration * (seekBar.value / 100);
		// Update the video time
		video.currentTime = time;
	});

	
	// Update the seek bar as the video plays
	video.addEventListener("timeupdate", function() {
		// Calculate the slider value
		var value = (100 / video.duration) * video.currentTime;
		// Update the slider value
		seekBar.value = value;
    function formatTime(seconds) {
      var minutes = Math.floor(seconds / 60);
      minutes = (minutes >= 10) ? minutes : "0" + minutes;
      var seconds = Math.floor(seconds % 60);
      seconds = (seconds >= 10) ? seconds : "0" + seconds;
      return minutes + ":" + seconds;
    }
     var seconds = video.currentTime;
     lastSeenPos = video.currentTime; // used this position to start playing when internet/wifi restore
      currentTime.innerHTML = formatTime(seconds);
      seconds = '';
      if (isNaN(video.duration)) { //if video duration is not number we make it as 00
        seconds = video.duration;
        durationTime.innerHTML = "00:00";
      } else {
        seconds = video.duration;
        durationTime.innerHTML = formatTime(seconds);
      }
      

	});

	// Pause the video when the seek handle is being dragged
	seekBar.addEventListener("mousedown", function() {
    update = true;      
    trackPageActivity(video.currentTime, video.duration) //save the last seen position  of video
    isPaused = video.paused;
		video.pause();
	});

	// Play the video when the seek handle is dropped
	seekBar.addEventListener("mouseup", function() {
    if (!isPaused)
		  video.play();
	});

	// Event listener for the volume bar
	volumeBar.addEventListener("change", function() {
		// Update the video volume
		video.volume = volumeBar.value;
	});

	volumeBar.addEventListener("change", function() {
		// Update the video volume
		video.volume = volumeBar.value;
	});
   /**
   *  it used to fast backward 2 minutes
   */
  $appContainer.find('#btnVideoPrevious').on('click', function(event) {
    event.preventDefault();
    console.log('video-btn-previous');
    let tempCurrTime = video.currentTime;
        if (tempCurrTime) {
          video.currentTime = (tempCurrTime > 10) ? (tempCurrTime - 10) : 0 ; 
        } else {
          video.currentTime  = 0;
        }

    update = true;
    savePlace();
  });
   
  /**
   *  it used to fast forward 2 minutes
   */

  $appContainer.find('#btnVideoNext').on('click', function(event) {
    event.preventDefault();
    console.log('video-btn-next');
    let tempCurrTime = video.currentTime;
         if (tempCurrTime) {
          video.currentTime += 10; 
        } else {
          video.currentTime  = 10;
        }

    update = true;
    savePlace();
  });

  /**
   * check if browser reload or not, if yes, fetch signed url
   */
   if (checkRefresh() == "true") {
     GetSignedUrl(id, videoId);
   }

   /**
    * Space keypad press video play and pause event
    */
  $(window).keypress(function(e) {
   if (e.which == 32) {
      document.activeElement?.blur(); // remove focus element
      if (video.paused) {
        video.play();
        switchPlay_PauseIcon();
      } else {
        video.pause();
        switchPlay_PauseIcon();
      }  
  }
  });
  } catch (error) {
      console.error('bind-event',error.message);
  }
}
/**
 * Append Caption
 */
function AppendCaption() {
  try {
  captions.forEach(element => {
      var track = document.createElement('track');
      track.kind = "captions", track.label = element.label || element.title,
      track.srclang = element.srclang || element.language, track.src = element.url || element.uri;
      $('#video').append(track);
      });
      video.textTracks[0].mode = "showing";
      $('#capButton span:first').attr('class', 'icon icon-cc-on');
  } catch (error) {
    console.error('append-caption-catch',error.message)
  }
}
/**
 * Check if reload happen or video token got expired, get signed url
 * @returns 
 */
function GetSignedUrl(id, videoId) {
  try {
      video.pause();
      return utils.isOnline().then(function () {
        return engageManager.getVideoSignedlUrl(id, videoId).then(function (response) {
        if (response.statusCode == 200) {
            source.setAttribute('src', response.signedResult.url);
            video.load();
            video.currentTime = checkRefresh() == "true" ? getVideoCurrentTime() : lastSeenPos;
            video.play();
            captions = response.signedResult.caption;
            // check video title already having caption and enable or not if yes, make it enable, when token got expired or page refresh
          if (captions.length > 0 && video.textTracks.length > 0 && videoCaptionStatus == "showing") {
            captions.forEach((element, index) => {
            video.textTracks[index].kind = "captions", video.textTracks[index].label = element.label || element.title,
            video.textTracks[index].srclang = element.srclang || element.language, video.textTracks[index].src = element.url || element.uri;
            video.textTracks[index].url = element.url || element.uri;
            });
            video.textTracks[index].mode = "showing";
            $('#capButton span:first').attr('class', 'icon icon-cc-on');
          } else if(captions.length > 0 && video.textTracks.length == 0 && videoCaptionStatus === "showing") {
            AppendCaption(); //display caption subtitles
          } else if(captions.length == 0) {
            $('#capButton').hide(); // hide the caption button
          }
        }
       });
      }).catch(error => {
      console.log('signed-url-activity',error.message);
  });
  } catch (error) {
    console.error('reloadGetSignedUrl-catch',error.message)
  }
}
 /**
  * Internet not available splash message
  */
function splashMessagePopup() {
  try {
  var checkOnline = window.navigator.onLine;
    if (!checkOnline) {
      switchPlay_PauseIcon();
      displayInternetMessagePopup();
    }
   var localTime = 1 * 1000; //1sec
   var temp_offline_status_check;
    videoNetworkInterval = setInterval(function() {
      var temp_online = window.navigator.onLine
      if(!temp_online) {

        if (!video.paused) {
          video.pause(); // once internet disconnected we pause video
        }  
        switchPlay_PauseIcon();
        displayInternetMessagePopup();
        temp_offline_status_check = true;
      } else if(temp_offline_status_check && temp_online) {
        temp_offline_status_check = false;
        // if (popupModalFormatType) //check popup reference
        //   popupModalFormatType.hide(); // internet is resotre remove the splash popup message
          $(".popup:visible").hide();
          video.currentTime = lastSeenPos;       
          video.play();
          switchPlay_PauseIcon();
      }
    }, localTime);
  } catch (error) {
    console.log('error-splash',error.message);
  }  
}

function initSaveIntervals() {
  //var remoteTime = 2 * 60 * 1000; //5min
  var remoteTime = 120000; //2min
  var localTime = 1 * 1000; //1sec
  update = false;

  remoteInterval = setInterval(function() {
    update = true;
  }, remoteTime);

  localInterval = setInterval(function() {
    if (update) { 
      trackPageActivity(video.currentTime, video.duration) //save the last seen position  of video

    }  

  }, localTime);
}

/**
 * Save the Last seen video position
 */
function savePlace() {
  usageManager.getPositionsUsage(id)
  .then(function(posUsage) {
    try {
      console.log('In method savePlace of video-file-position',video.currentTime);
      var positionsUsage = posUsage;
      var trackObj = {
        timeoffset: video.currentTime,
        chapter: 0,
        part: 0,
      };
        positionsUsage.addTrackUsage(trackObj);
        usageManager.savePositionsUsage(positionsUsage);
         if (update) {
          update = false;
          return utils.isOnline().then(function() {
            usageManager
              .fetchListeningPositionFromRemote(id)
              .then(function(response) {
                var remotePosition;
                if (typeof response.audiopositions != 'undefined') {
                  remotePosition = response.audiopositions[0];
                }
                var lastPosition = positionsUsage.audiopositions[
                  positionsUsage.audiopositions.length - 1
                ];

                if (remotePosition == undefined) {
                  return usageManager.saveListeningPositionsToRemote(id);
                }

                var diffTimeoffset = remotePosition.timeoffset != trackObj.timeoffset.toFixed(0);
                var diffChapter = remotePosition.chapter != trackObj.chapter;
                var diffPart = remotePosition.part != trackObj.part;
                var newPosition = diffTimeoffset || diffChapter || diffPart;

                var lastDate = new Date(lastPosition.usercreatedate);
                var remoteDate = new Date(remotePosition.usercreatedate);
                remoteDate = remoteDate > new Date() ? 0 : remoteDate; //this serves as a sanity check

                //if (newPosition && lastDate.valueOf() > remoteDate.valueOf()) {
                  return usageManager.saveListeningPositionsToRemote(id);
               // }
              })
              .catch(function(error) {
                // Do nothing for now
                console.log('save-catch',error.message);
              });
          });

         }

    } catch (error) {
      console.log('save-catch',error.message)
    }
  });
}

// Engage Module: Tracking user time spent on listening a video book
var trackPageActivity = function (currentTrack, finalChap) {
  return utils.isOnline().then(function () {
    
    return engageManager.trackActivityToRemote(id
      , currentTrack.toFixed(0)
      , finalChap.toFixed(0)
      , 'videobook'
    ).then(function (response) {
       savePlace();
    });
  }).catch(error => {
     console.log('track-page-activity',error.message)
   });
};

function getLastSeenposition() {
   return utils.isOnline().then(function() {
        usageManager
          .fetchListeningPositionFromRemote(id)
          .then(function(response) {
            var remotePosition;
            if (typeof response.audiopositions != 'undefined') {
              remotePosition = response.audiopositions[0];
            }    
            if (checkRefresh() == "true") {
                video.currentTime = getVideoCurrentTime();
                window.sessionStorage.setItem('PAGE_REFRESH', false);
            } else {
               lastSeenPos = remotePosition ? remotePosition.timeoffset : 0;
               video.currentTime = lastSeenPos;
            }
         })
          .catch(function(error) {
            // Do nothing for now
         });
    });
}
/**
 * return Check refresh or not
 */
function checkRefresh() {
return window.sessionStorage.getItem('PAGE_REFRESH');
  
}

/**
 * Get video current time/ when browser get reload
 * @returns 
 */
function getVideoCurrentTime() {
  var video_current_time =  window.sessionStorage.getItem('VIDEO_CURRENT_TIME');
      return parseFloat(video_current_time);;
}

var setTitle = function(branding) {
  //document.title = branding; // // we removed brand name  ticket #205732 later TODO
  document.title = videoTitle;
};
/**
 * Display Internet Popup Message
 */
function displayInternetMessagePopup(){
    popupModalFormatType = PopupFactory.create(PopupFactory.TYPE.MODAL, {id: "VideoInternetMessage"});
    popupModalFormatType.clear();
    popupModalFormatType.setTitle('Error')
    popupModalFormatType.setMessage('The video player could not complete the action. Please try again.');            
    popupModalFormatType.addButton("OK", function () {  
    // popupModalFormatType.hide();
    $(".popup:visible").hide();
    }, false);
    popupModalFormatType.show();
}

/**
 * Update Play and Pause Icon
 */
function switchPlay_PauseIcon() {
   if (video.paused) {
     $('#play-pause span:first').attr('class', 'icon icon-icon-video-play');
   } else {
     $('#play-pause span:first').attr('class', 'icon icon-icon-video-pause');
   }  
}

export default {
  loadUI: loadUI,
  setTitle: setTitle,
};