import $ from 'jquery';
import PopupFactory from '../../popup/PopupFactory';
import InlineAnnotationsMenu from '../../menus/inline/InlineAnnotationsMenu';
import DictionaryMenuItem from '../../templates/popup/dictionary-menu-item.hbs';
import AccessibilityManager from '../../accessibility/AcessibilityManager';

//Define - Dictionary Search
var dictionaryMenu = PopupFactory.create(PopupFactory.TYPE.NAVBAR_MENU, 'Define', {
  cssClass: 'app-dictionary-menu',
  id: 'defineSearchPopup',
});
dictionaryMenu.position('left');
dictionaryMenu.clearContainerLayouts();

var inlineDictionarySearch = function() {
  InlineAnnotationsMenu.apply(this, arguments);
  this.popup(dictionaryMenu);
};

inlineDictionarySearch.prototype = Object.create(InlineAnnotationsMenu.prototype);

inlineDictionarySearch.prototype.show = Promise.method(function() {
  this.hideAll();
  this.annotationUtils.clearSelection();

  var selectionText = this.textSelectionEvent().text;

  dictionaryMenu.$body.empty();
  dictionaryMenu.updateTitle(selectionText);
  var word = $.trim(selectionText).split(' ')[0];
  getDefinition(word, function(definitions) {
    var s = '';
    if ($.trim(definitions) == '') {
      dictionaryMenu.$body.append('NO RESULTS WERE FOUND');
      // AccessibilityManager.panelCloseHandler(function() {
      //     AccessibilityManager.setTabGroup("define_search_no_result")
      //     AccessibilityManager.updateTabOrder("define_search_no_result")
      // })
    } else {
      definitions.forEach(function(defination) {
        dictionaryMenu.$body.append(DictionaryMenuItem(defination));
      });
      // AccessibilityManager.panelCloseHandler(function() {
      //     AccessibilityManager.setTabGroup("define_search")
      //     AccessibilityManager.updateTabOrder("define_search")
      // })
    }
    dictionaryMenu.show();
  });
  dictionaryMenu.$el.show();
  return;
});

function getDefinition(theWord, callback) {
  var apiKey = 'd5bac3f4923d25b180218050e01064b2d4ed64f124cbd0cef'; //Pro account key from developer.wordnik.com
  var config = {
    api_key: apiKey,
    limit: 10,
    sourceDictionaries: 'all',
  };
  var url =
    '//api.wordnik.com/v4/word.json/' +
    theWord +
    '/definitions?useCanonical=true&includeRelated=true';
  var jxhr = $.ajax({
    url: url,
    dataType: 'text',
    timeout: 30000,
    data: config,
    success: function(data) {
      var array = JSON.parse(data);
      console.log(data);
      callback(array);
    },
    error: function(jxhr) {
      // It seems like wordnik api became case sensitive(as 2019.05.24),
      // it will fail to find 'Unlike' but 'unlike' will work and it also
      // treats 'Apple' and 'apple' as different words. So we will query
      // the word as it is and query again if it returns 404
      if (jxhr.status === 404 && theWord !== theWord.toLowerCase()) {
        getDefinition(theWord.toLowerCase(), callback);
      } else {
        console.log(
          'getDefinition: url == ' + url + ', error == ' + JSON.stringify(status, undefined, 4),
        );
        callback('');
      }
    }
  });
}

export default inlineDictionarySearch;
