var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h5>"
    + alias4(((helper = (helper = lookupProperty(helpers,"partOfSpeech") || (depth0 != null ? lookupProperty(depth0,"partOfSpeech") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"partOfSpeech","hash":{},"data":data,"loc":{"start":{"line":1,"column":4},"end":{"line":1,"column":20}}}) : helper)))
    + "</h5>\n<ol class=\"list-group ordered-list no-list-hover\">\n    <li class=\"list-group-item\">\n        <p>"
    + alias4(((helper = (helper = lookupProperty(helpers,"text") || (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data,"loc":{"start":{"line":4,"column":11},"end":{"line":4,"column":19}}}) : helper)))
    + "</p>\n        <p class=\"subtle\">\n            "
    + alias4(((helper = (helper = lookupProperty(helpers,"attributionText") || (depth0 != null ? lookupProperty(depth0,"attributionText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"attributionText","hash":{},"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":6,"column":31}}}) : helper)))
    + "\n        </p>\n    </li>\n</ol>";
},"useData":true});