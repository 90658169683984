import $ from 'jquery';
import PopupFactory from '../../popup/PopupFactory';
import InlineAnnotationsMenu from '../../menus/inline/InlineAnnotationsMenu';
import SearchMenu from '../../templates/popup/search-menu-container-layout.hbs';
import SearchMenuWikiItem from '../../templates/popup/search-menu-wiki-item.hbs';

//Notes
var inlineSearch = PopupFactory.create(PopupFactory.TYPE.NAVBAR_MENU, 'Wikipedia Search', {
  cssClass: 'app-search-menu',
});
inlineSearch.position('left');
inlineSearch.clearContainerLayouts();
var $googleResultsContainerLayoutContent = inlineSearch.addContainerLayout(SearchMenu({}));
var $wikiResultsContainerLayoutContent = inlineSearch.addContainerLayout(SearchMenu({}));

var InlineWikiSearch = function() {
  InlineAnnotationsMenu.apply(this, arguments);
  this.popup(inlineSearch);
};

InlineWikiSearch.prototype = Object.create(InlineAnnotationsMenu.prototype);

InlineWikiSearch.prototype.show = Promise.method(function() {
  this.hideAll();
  this.annotationUtils.clearSelection();

  var selectionText = this.textSelectionEvent().text;

  getWiki(selectionText, function(wikiResults) {
    inlineSearch.updateTitle('Wikipedia Search');
    if (wikiResults[1].length == 0) {
      $wikiResultsContainerLayoutContent.find('.pane.header-pane h2').text('No results were found');
    } else {
      $wikiResultsContainerLayoutContent
        .find('.pane.header-pane h2')
        .text(wikiResults[1].length + ' Results');
    }
    inlineSearch.$el.find('.container-layout').show();

    var $listGrp = $wikiResultsContainerLayoutContent.find('.list-group');
    $listGrp.empty();
    wikiResults[1].forEach(function(wikiResultsItem, index, array) {
      var html = SearchMenuWikiItem({
        title: wikiResultsItem,
        description: wikiResults[2][index],
        link: wikiResults[3][index],
      });
      $listGrp.append(html);
    });
    $wikiResultsContainerLayoutContent.show();
    $googleResultsContainerLayoutContent.hide();
  });
  inlineSearch.$el.show();
  return;
});

function getWiki(theWord, callback) {
  //var url = "https://en.wikipedia.org/w/api.php?action=opensearch&search=india&callback=?";
  var url =
    'https://en.wikipedia.org/w/api.php?action=opensearch&search=' +
    theWord.replace(' ', '+') +
    '&callback=?';
  var jxhr = $.ajax({
    url: url,
    contentType: 'application/json; charset=utf-8',
    async: false,
    dataType: 'json',
    success: function(data, textStatus, jqXHR) {
      var array = data;
      console.log(data);
      //callback (array [0].words)
      callback(data);
    },
    error: function(status) {
      console.log('getWiki: url == ' + url + ', error == ' + JSON.stringify(status, undefined, 4));
    },
  });
}

export default InlineWikiSearch;
