var Menu = function(readium, usageManager, isbn, ebookURL, findaway, comic) {
  this.readium = readium;
  this.usageManager = usageManager;
  this.isbn = isbn;
  this.ebookURL = ebookURL;
  this.findaway = findaway;
  this.comic = comic;
};

//Public API
Menu.prototype.popup = function(popupInstance) {
  if (popupInstance) {
    this._popup = popupInstance;
  } else {
    return this._popup;
  }
};

Menu.prototype.hide = function() {
  this._popup.hide();
};

Menu.prototype.hideAll = function() {
  return this._popup.hideAll();
};

Menu.prototype.show = Promise.method(function() {
  this._popup.show();
  return;
});

Menu.prototype.isVisible = function() {
  return this._popup.isVisible();
};

Menu.prototype.isAudiobook = function() {
  return !!this.findaway;
};

Menu.prototype.isComicBook = function () {
  return !!this.comic;
};

export default Menu;
