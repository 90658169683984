import $ from 'jquery';
import Modernizr from '@axisnow/modernizr';
import MigrationManager4To5 from './migration/MigrationManager4To5';
import MigrationManager5To7 from './migration/MigrationManager5To7';
import MigrationManager7to8 from './migration/MigrationManager7To8';
import MigrationManager8to9 from './migration/MigrationManager8To9';
import MigrationManager9To10 from './migration/MigrationManager9To10';
import MigrationManager10To11 from './migration/MigrationManager10To11';
import MigrationManager11To12 from './migration/MigrationManager11To12';
import MigrationManager12To13 from './migration/MigrationManager12To13';
import MigrationManager13To14 from './migration/MigrationManager13To14';

import Promise from 'bluebird';
import Errors from '@axisnow/util/Errors';
// import Globals from '@axisnow/readium-shared-js';
let { Globals } = require('@axisnow/readium-shared-js');

var _version = 14;
var _openDB;
var indexedDB;
var _objectStores = {};
var _dbName;
var _STORE_NAMES;
var _DEBUG = window._a360_debug_idb || false;
var isIndexedDBIndexesSupported = false;

var isIE = !!window.msCrypto,
  isWebkit = false,
  isIndexDBSupported = Modernizr.indexdbkeysupported;

if (typeof isIndexDBSupported == 'undefined') {
  Modernizr.on('indexdbkeysupported', function(result) {
    isIndexDBSupported = isIE ? true : result;
  });
}

var IndexedDBStorageManager = function() {
  var self = this;
  isIndexedDBIndexesSupported = Modernizr.indexeddbindexesupported;

  if (typeof isIndexedDBIndexesSupported == 'undefined') {
    Modernizr.on('indexeddbindexesupported', function(result) {
      isIndexedDBIndexesSupported = result;
    });
  }

  indexedDB =
    window.indexedDB ||
    window.mozIndexedDB ||
    window.webkitIndexedDB ||
    window.msIndexedDB ||
    window.shimIndexedDB;
  _dbName = 'AxisNowDB';
  this.STORE_NAMES = _STORE_NAMES = Globals.STORE_NAMES;
  /**
   * we added profile store collection inside store name
   */
     this.STORE_NAMES['ProfileStore'] = 'ProfileStore'

  _objectStores[_STORE_NAMES.UsageStore] = {
    name: 'UsageStore',
    value: { keyPath: 'id' },
  };

  _objectStores[_STORE_NAMES.ProfileStore] = {
    name: 'ProfileStore',
    value: { keyPath: 'profileid' },
  };

  _objectStores[_STORE_NAMES.PageUsage] = {
    name: _STORE_NAMES.PageUsage,
    value: { keyPath: 'uuid_profile_isbn' },
    indexes: [
      {
        name: 'uuid',
        keyPath: 'uuid',
        optionalParameters: { unique: false },
      },
      {
        name: 'isbn',
        keyPath: 'isbn',
        optionalParameters: { unique: false },
      },
      {
        name: 'profileid',
        keyPath: 'profileid',
        optionalParameters: { unique:false },
      },
    ],
  };

  _objectStores[_STORE_NAMES.EbookAnnotationsUsage] = {
    name: _STORE_NAMES.EbookAnnotationsUsage,
    value: { keyPath: 'uuid_profile_isbn' },
    indexes: [
      {
        name: 'uuid',
        keyPath: 'uuid',
        optionalParameters: { unique: false },
      },
      {
        name: 'isbn',
        keyPath: 'isbn',
        optionalParameters: { unique: false },
      },
      {
        name: 'profileid',
        keyPath: 'profileid',
        optionalParameters: { unique:false },
      },
    ],
  };

//Comicbook AnnotationsUsage
_objectStores[_STORE_NAMES.ComicBookPageUsage] = {
  name: _STORE_NAMES.ComicBookPageUsage,
  value: { keyPath: 'uuid_profile_isbn' },
  indexes: [
    {
      name: 'uuid',
      keyPath: 'uuid',
      optionalParameters: { unique: false },
    },
    {
      name: 'isbn',
      keyPath: 'isbn',
      optionalParameters: { unique: false },
    },
    {
        name: 'profileid',
        keyPath: 'profileid',
        optionalParameters: { unique:false },
    },
  ],
};

_objectStores[_STORE_NAMES.ComicBookAnnotationsUsage] = {
  name: _STORE_NAMES.ComicBookAnnotationsUsage,
  value: { keyPath: 'uuid_profile_isbn' },
  indexes: [
    {
      name: 'uuid',
      keyPath: 'uuid',
      optionalParameters: { unique: false },
    },
    {
      name: 'isbn',
      keyPath: 'isbn',
      optionalParameters: { unique: false },
    },
    {
        name: 'profileid',
        keyPath: 'profileid',
        optionalParameters: { unique:false },
    },
  ],
};

  _objectStores[_STORE_NAMES.PositionsUsage] = {
    name: _STORE_NAMES.PositionsUsage,
    value: { keyPath: 'uuid_profile_id' },
    indexes: [
      {
        name: 'uuid',
        keyPath: 'uuid',
        optionalParameters: { unique: false },
      },
      {
        name: 'id',
        keyPath: 'id',
        optionalParameters: { unique: false },
      },
      {
        name: 'profileid',
        keyPath: 'profileid',
        optionalParameters: { unique:false },
    },
    ],
  };

  _objectStores[_STORE_NAMES.AudioAnnotationsUsage] = {
    name: _STORE_NAMES.AudioAnnotationsUsage,
    value: { keyPath: 'uuid_profile_id' },
    indexes: [
      {
        name: 'uuid',
        keyPath: 'uuid',
        optionalParameters: { unique: false },
      },
      {
        name: 'id',
        keyPath: 'id',
        optionalParameters: { unique: false },
      },
      {
        name: 'profileid',
        keyPath: 'profileid',
        optionalParameters: { unique:false },
      },
    ],
  };
  _objectStores[_STORE_NAMES.UserStore] = {
    name: _STORE_NAMES.UserStore,
    value: { keyPath: 'id' },
    indexes: [
      {
        name: 'bookvaultId',
        keyPath: 'bookvaultId',
        optionalParameters: { unique: true },
      },
      {
        name: 'uuid',
        keyPath: 'uuid',
        optionalParameters: { unique: false },
      },
      {
        name: 'profileid',
        keyPath: 'profileid',
        optionalParameters: { unique:false },
      },
    ],
  };
  _objectStores[_STORE_NAMES.KeyStore] = {
    name: _STORE_NAMES.KeyStore,
    value: { autoIncrement: true },
    indexes: [
      {
        name: 'name',
        keyPath: 'name',
        optionalParameters: { unique: true },
      },
      {
        name: 'spki',
        keyPath: 'spki',
        optionalParameters: { unique: false },
      },
    ],
  };
  _objectStores[_STORE_NAMES.LibraryDataStore] = {
    name: _STORE_NAMES.LibraryDataStore,
    value: { keyPath: 'uuid_profile_isbn' },
    indexes: [
      {
        name: 'uuid',
        keyPath: 'uuid',
        optionalParameters: { unique: false },
      },
      {
        name: 'isbn',
        keyPath: 'isbn',
        optionalParameters: { unique: true },
      },
       {
        name: 'profileid',
        keyPath: 'profileid',
        optionalParameters: { unique:false },
      },
    ],
  };
  _objectStores[_STORE_NAMES.LicenseStore] = {
    name: _STORE_NAMES.LicenseStore,
    value: { keyPath: 'uuid_isbn' },
    indexes: [
      {
        name: 'uuid',
        keyPath: 'uuid',
        optionalParameters: { unique: false },
      },
      {
        name: 'isbn',
        keyPath: 'isbn',
        optionalParameters: { unique: false },
      }
    ],
  };
  _objectStores[_STORE_NAMES.BookDataStore] = {
    name: _STORE_NAMES.BookDataStore,
    value: { keyPath: 'isbn' },
    indexes: [
      {
        name: 'isbn',
        keyPath: 'isbn',
        optionalParameters: { unique: false },
      },
    ],
  };
  _objectStores[_STORE_NAMES.NavigationStore] = {
    name: _STORE_NAMES.NavigationStore,
    value: { keyPath: 'btnType' },
  };
 
  //Internal Functions
  this._open = function(onSuccess) {
    return new Promise(function(resolve, reject) {
      if (self.db) {
        return onSuccess(self.db);
      }
      if (!indexedDB) {
        return reject(new Error('IndexedDB is not supported by this browser.'));
      }

      var req = indexedDB.open(_dbName, _version);
      req.onsuccess = function(evt) {
        self.db = req.result;
        console.log('db_open');
        onSuccess(self.db);
      };
      req.onerror = function(evt) {
        throw evt.target.error;
      };
      req.onblocked = function(evt) {
        console.error('Database already open');
        reject(
          new Errors.DatabaseAlreadyOpen(
            'Close Your Reader Browser tabs!!!',
            'Reader is open in other tabs. Please close them and try again.',
            'Error',
          ),
        );
      };
      // If the database is being created or upgraded to a new version,
      // see if the object store and its indexes need to be created.
      req.onupgradeneeded = async function(evt) {
        self.db = evt.target.result;
        console.debug('upgrading IDB from: ', evt.oldVersion);
        // Sequential migration updates - updating one version at a time.
        // This is why these top level if statements are not "else if".

        if (evt.oldVersion <= 4) {
          if (evt.oldVersion == 0) {
            // No database found
            var migrationManager4To5 = new MigrationManager4To5(
              evt.target.result,
              evt.target.transaction,
            );
            await migrationManager4To5.createNewTables();
          } else {
            var migrationManager4To5 = new MigrationManager4To5(
              evt.target.result,
              evt.target.transaction,
            );
            console.debug(evt.target.transaction);
            console.debug('IDB upgrade 4 to 5');
            await migrationManager4To5.migrate();
          }
        }

        if (evt.oldVersion <= 6) {
          // migrate path is from 5 to 7 because flawed logic flow caused databases <=4 on the 5 to 6 migration to only migrate up to 5,
          // migration logic from 5 to 6 is identical to 5 to 7, the additional version increase was a result of
          // needing to trigger an upgrade for the databases labelled as 6 but with structure of version 5
          var migrationManager5To7 = new MigrationManager5To7(
            evt.target.result,
            evt.target.transaction,
          );
          console.debug('IDB upgrade 5 to 7');
          await migrationManager5To7.migrate();
        }

        if (evt.oldVersion <= 7) {
          var migrationManager7to8 = new MigrationManager7to8(
            evt.target.result,
            evt.target.transaction,
          );
          console.debug('IDB upgrade 7 to 8');
          await migrationManager7to8.migrate();
        }

        if (evt.oldVersion <= 8) {
          var migrationManager8to9 = new MigrationManager8to9(
            evt.target.result,
            evt.target.transaction,
          );
          console.debug('IDB upgrade 8 to 9');
          await migrationManager8to9.migrate();
        }

        if (evt.oldVersion <= 9) {
          var migrationManager9To10 = new MigrationManager9To10(
            evt.target.result,
            evt.target.transaction,
          );
          console.debug('IDB upgrade 9 to 10');
          await migrationManager9To10.migrate();
        }

        if (evt.oldVersion <= 10) {
          var migrationManager10To11 = new MigrationManager10To11(
            evt.target.result,
            evt.target.transaction,
          );
          console.debug('IDB upgrade 10 to 11');
          await migrationManager10To11.migrate();
        }
        
        if (evt.oldVersion <= 11) {
          var migrationManager11To12 = new MigrationManager11To12(
            evt.target.result,
            evt.target.transaction,
          );
          migrationManager11To12.migrate();
        }

        if (evt.oldVersion <= 12) {
          var migrationManager12To13 = new MigrationManager12To13(
            evt.target.result,
            evt.target.transaction,
          );
          migrationManager12To13.migrate();
        }
        
        if (evt.oldVersion <= 14) {
         
           var migrationManager13To14 = new MigrationManager13To14(
            evt.target.result,
            evt.target.transaction,
          );
          migrationManager13To14.migrate();
        }
        
      };
    });
  };
};

//Public API

IndexedDBStorageManager.prototype.isIndexDBSupported = function() {
  //for the prokarma iOS app, return true
  return new Promise(function(resolve, reject) {
    if (typeof isIndexDBSupported === 'undefined') {
      return new Promise(function(resolve, reject) {
        Modernizr.on('indexdbkeysupported', function(result) {
          isIndexDBSupported = isIE ? true : result;
          return resolve(isIndexDBSupported);
        });
      });
    } else {
      return resolve(isIndexDBSupported);
    }
  });
};

IndexedDBStorageManager.prototype.isWebkit = function() {
  if (typeof window.crypto != 'undefined' && typeof window.crypto.webkitSubtle != 'undefined') {
    if (window.crypto.webkitSubtle === window.crypto.subtle) {
      isWebkit = true;
    }
  }

  //for the prokarma iOS app, return false always
  return isWebkit;
};

IndexedDBStorageManager.prototype.get = function(objStoreName, propertyName, propertyValue) {
  if (_DEBUG) console.log('idb:get', objStoreName, propertyName, propertyValue);
  var self = this;
  return new Promise(function(resolve, reject) {
    self
      ._open(function(db) {
        var transaction = db.transaction([objStoreName], 'readwrite');
        var objectStore = transaction.objectStore(objStoreName);

        var request;
        var useCursor = false;
        var patronId = '';

        if (propertyName === _objectStores[objStoreName].value.keyPath) {
          request = objectStore.get(propertyValue);
        } else {
          if (!isIndexedDBIndexesSupported) {
            request = objectStore.openCursor();
            useCursor = true;
          } else {
            var found = false;
            _objectStores[objStoreName].indexes.forEach(function(element, index, array) {
              if (propertyName === element.name) {
                request = objectStore.index(element.name).get(propertyValue);
                found = true;
              }
            });
            if (!found) {
              // reject(new Error("No such property: " + propertyName));
              return null;
            }
            /* if (found){
                            resolve(patronId);
                            console.log("Bookvault ID found");
                            var cursor = event.target.result;
                            if(cursor.bookvaultId === propertyValue){
                                patronId = cursor.id;
                                console.log("PatronID = " + patronId);
                                resolve(patronId);
                            }else{
                                reject();
                            }
                        }*/
          }
        }

        request.onsuccess = function(evt) {
          try {
            if (_DEBUG) console.info('idb:get:success', evt.target.result);

            var cursor = evt.target.result;
            if (cursor && useCursor) {
              if (cursor.value[propertyName] == propertyValue) {
                resolve(cursor.value);
              }
              cursor.continue();
            } else {
              resolve(evt.target.result);
            }
          } catch (ex) {
            console.error(ex);
            if (evt.target.readyState === 'pending') {
              console.error('Pending detected. Error: ' + ex);
            }
            reject(ex);
          }
        };

        request.onerror = function(evt) {
          if (_DEBUG) console.error('idb:get:error', evt.target.error);
          reject(evt.target.error);
        };
      })
      .catch(function(error) {
        reject(error);
      });
  });
};

IndexedDBStorageManager.prototype.getAll = function(objStoreName, propertyName, propertyValue) {
  if (_DEBUG) console.log('idb:getAll', objStoreName, propertyName, propertyValue);
  var self = this;
  return new Promise(function(resolve, reject) {
    self
      ._open(function(db) {
        var transaction = db.transaction([objStoreName], 'readwrite');
        var objectStore = transaction.objectStore(objStoreName);

        var request = objectStore.openCursor();

        var results = [];

        request.onsuccess = function(evt) {
          try {
            if (_DEBUG) console.info('idb:getAll:success', evt.target.result);

            var cursor = evt.target.result;
            if (cursor) {
              if (cursor.value[propertyName] === propertyValue) {
                results.push(cursor.value);
              }
              cursor.continue();
            } else {
              resolve(results);
            }
          } catch (ex) {
            console.error(ex);
            if (evt.target.readyState === 'pending') {
              console.error('Pending detected. Error: ' + ex);
            }
            reject(ex);
          }
        };

        request.onerror = function(evt) {
          if (_DEBUG) console.error('idb:getAll:error', evt.target.error);
          reject(evt.target.error);
        };
      })
      .catch(function(error) {
        reject(error);
      });
  });
};

IndexedDBStorageManager.prototype.add = function(objStoreName, savedObject) {
  if (_DEBUG) console.log('idb:add', objStoreName, savedObject);
  var self = this;

  return new Promise(function(resolve, reject) {
    self
      ._open(function(db) {
        var transaction = db.transaction([objStoreName], 'readwrite');
        var objectStore = transaction.objectStore(objStoreName);
        transaction.onerror = function(evt) {
          if (_DEBUG) console.error('idb:add:error', savedObject);
          reject(evt.target.error);
        };
        transaction.onabort = function(evt) {
          if (_DEBUG) console.warn('idb:add:abort', savedObject);
          reject(evt.target.error);
        };
        transaction.oncomplete = function(evt) {
          if (_DEBUG) console.info('idb:add:success', savedObject);
          resolve(savedObject);
        };

        var request = objectStore.add(savedObject);
      })
      .catch(function(error) {
        reject(error);
      });
  });
};

IndexedDBStorageManager.prototype.put = function(objStoreName, savedObject) {
  if (_DEBUG) console.log('idb:put', objStoreName, savedObject);
  var self = this;

  return new Promise(function(resolve, reject) {
    self
      ._open(function(db) {
        if (navigator.storage != undefined && navigator.storage.estimate != undefined) {
          //Storage API to check available Quota-Supported for chrome/Firefox browser
          navigator.storage.estimate().then(function(estimate) {
            //If used browser quota plus + 10 MB (threshold limit to maintain some usable memory) is greater than browser allotted quota then below Insufficient memory dialog popup will display.
            if (estimate.quota <= 10000000 + estimate.usage) {
              $(window).trigger('RunOutOfSpace');
            }
          });
        }
        var transaction = db.transaction([objStoreName], 'readwrite');
        var objectStore = transaction.objectStore(objStoreName);
        transaction.onerror = function(evt) {
          if (_DEBUG) console.error('idb:put:error', savedObject);
          reject(evt.target.error);
        };
        transaction.onabort = function(evt) {
          if (_DEBUG) console.warn('idb:put:abort', savedObject);
          if (evt.target.error == 'QuotaExceededError') {
            $(window).trigger('RunOutOfSpace');
          }
          reject(evt.target.error);
        };
        transaction.oncomplete = function(evt) {
          if (_DEBUG) console.info('idb:put:success', savedObject);
          resolve(savedObject);
        };
        var request = objectStore.put(savedObject);
      })
      .catch(function(error) {
        reject(error);
      });
  });
};
// close method
//
// Takes no parameters.
//
// Simply closes the database and returns immediately. Note that
// the IndexedDB system actually closes the database in a separate
// thread, and there is no way to know when that process is complete.
//
IndexedDBStorageManager.prototype.close = function() {
  var self = this;

  return new Promise(function(fulfiresolvell, reject) {
    if (!self.db) {
      reject(new Error('DB is not open.'));
    }

    self.db.close();
    self.db = null;
    resolve();
  });
};

export default IndexedDBStorageManager;
