import $ from 'jquery';
import { ReadiumReaderViewAdapter } from '@axisnow/ng-r1-compat';
import { Globals, Helpers, StyleCollection } from '@axisnow/readium-shared-js';
import { MediaOverlayDataInjector } from './media_overlay_data_injector';
import { MediaOverlayPlayer } from './media_overlay_player';
import { isNumber } from 'underscore';
// import './models/package'; //Override Package to set Audio file format.

ReadiumReaderViewAdapter.prototype.onMediaPlayerStatusChanged = function (status) {
  Globals.logEvent('MEDIA_OVERLAY_STATUS_CHANGED', 'EMIT', 'reader_view.js (via MediaOverlayPlayer + AudioPlayer)');

  if (
    typeof status.playPosition !== 'undefined' &&
    typeof status.smilIndex !== 'undefined' &&
    typeof status.parIndex !== 'undefined'
  ) {
    var packageData = this.package();

    var playPositionMS = status.playPosition * 1000;
    var percent = packageData.media_overlay.positionToPercent(
      status.smilIndex,
      status.parIndex,
      playPositionMS,
    );

    if (percent < 0) {
      percent = 0;
    }

    this._raAudioPosition = percent;

    console.log("onMediaPlayerStatusChanged _raAudioPosition: ", this._raAudioPosition);
  }

  this.emit(Globals.Events.MEDIA_OVERLAY_STATUS_CHANGED, status);
};

ReadiumReaderViewAdapter.prototype.getFirstVisibleMediaOverlayElement = function () {
  var spItemViewStatusList = this.rendCtx.rendition.bookView.spineItemViewStatus
  
  for(var i = 0; i < spItemViewStatusList.length; i++) {
    //Look for element in current loaded spine or in next pages (Not in previous pages).
    if(this.spineInView.length == 0 || spItemViewStatusList[i].spineItemIndex >=  this.spineInView[0]) {
      var el = getFirstVisibleMediaOverlayElement(spItemViewStatusList[i].view.contentView.contentViewImpl);

      if(el){
        return el;
      }
    }
  }

  return;
};

ReadiumReaderViewAdapter.prototype.pauseMediaOverlay = function (isSystem) {
  this.mediaOverlayPlayer.pause(isSystem);
};

ReadiumReaderViewAdapter.prototype.resetMediaOverlay = function (isSystem) {
  this.mediaOverlayPlayer.reset();
};

ReadiumReaderViewAdapter.prototype.getElement = function (spineItemIdref, selector) {
  var spItemViewStatus = this.rendCtx.rendition.bookView.spineItemViewStatus

  if(spItemViewStatus.length > 1 && spItemViewStatus[2]){
    return spItemViewStatus[2].view.contentView.contentViewImpl.getElement(spineItemIdref, selector);
  } else {
    return undefined;
  }
};

/**
 * Start/Resume playback of media overlays.
 */
ReadiumReaderViewAdapter.prototype.playMediaOverlay = function () {
  this.mediaOverlayPlayer.play();
};

/**
 * Determine if media overlays are currently playing.
 * @returns {boolean}
 */


ReadiumReaderViewAdapter.prototype.isPlayingMediaOverlay = function () {
  return this.mediaOverlayPlayer.isPlaying();
}; //

ReadiumReaderViewAdapter.prototype.nextMediaOverlay = function () {
  this.mediaOverlayPlayer.nextMediaOverlay();
};

ReadiumReaderViewAdapter.prototype.previousMediaOverlay = function () {
  this.mediaOverlayPlayer.previousMediaOverlay();
};

/**
 * Returns the user CSS styles collection
 *
 * @returns {Collections.StyleCollection}
 */
ReadiumReaderViewAdapter.prototype.userStyles = function () {
  return this.contentViewFactory.getBookStyle();
};
  
ReadiumReaderViewAdapter.prototype.insureElementVisibility = function (spineItemId, element, initiator) {
  //Dummy to prevent errors 
};

//Overriding to enable MediaOverlay
ReadiumReaderViewAdapter.prototype.isMediaOverlayAvailable = function () {
  return (this.mediaOverlayPlayer && this.mediaOverlayPlayer.isMediaOverlayAvailable()) || this.rsjPackage.media_overlay.smil_models.length > 0
};

//Overriding to initialize and inject MediaOverlay
ReadiumReaderViewAdapter.prototype.openPublication = (function () {
  var cahced_openPublic_fn = ReadiumReaderViewAdapter.prototype.openPublication;
  
  return async function(publication, iframeLoader, packageDoc) {
    var _this = this;
    var result = cahced_openPublic_fn.apply(this, [publication, iframeLoader, packageDoc]);

    result.then(function(val){
      try{
        _this.spineInView = [];
        _this.ignorePageChangeEvent = false;
        _this._userStyles = new StyleCollection();
        updateR1SettingMaps(_this.vsAdapter);

        // if(_this.rsjPackage.moReplaced == undefined){
        //   _this.rsjPackage.replaceMediaOverlay(_this.contentViewFactory.rsjPackageDoc.getSharedJsPackageData());
        // }
          //Enable Readalong only for the supported ebooks.
        if (_this.rsjPackage && _this.rsjPackage.media_overlay && _this.rsjPackage.media_overlay.smil_models.length > 0) {
          _this.mediaOverlayPlayer = new MediaOverlayPlayer(_this, $.proxy(_this.onMediaPlayerStatusChanged, _this));
          _this.mediaOverlayPlayer.setAutomaticNextSmil(true);
          _this.mediaOverlayDataInjector = new MediaOverlayDataInjector(_this.rsjPackage.media_overlay, _this.mediaOverlayPlayer);
          
          _this.on(Globals.Events.CONTENT_DOCUMENT_LOADED, function($iframe, spineItem) {
            // performance degrades with large DOM (e.g. word-level text-audio sync)
            _this.mediaOverlayDataInjector.attachMediaOverlayData($iframe, spineItem, _this.viewerSettings()); 
          });
        }
        
        _this.on(Globals.Events.PAGINATION_CHANGED, function(pageChangeData) {
          if(pageChangeData.paginationInfo.openPages && !_this.ignorePageChangeEvent){
            _this.spineInView = [];

            pageChangeData.paginationInfo.openPages.forEach(page => {
              _this.spineInView.unshift(page.spineItemIndex);
            });
          } else {
            //Ignoring the page turn event by readalong and initial book open.
            _this.ignorePageChangeEvent = false;
          }
        });
      } catch(err){
        console.log("ReadiumReaderViewAdapter.openPublication :", err);
      }
    });
  }
})();

//Overriding: to start from last read audio position
ReadiumReaderViewAdapter.prototype.openPage = (function() {
  var cahced_openPage_fn = ReadiumReaderViewAdapter.prototype.openPage; 
  
  return async function(openPageRequest) {
    var _this = this;

    console.log("ReadiumReaderViewAdapter.prototype.openPage : ", openPageRequest);
    _this._raAudioPosition = (openPageRequest && openPageRequest.raAudioPosition)? Number(openPageRequest.raAudioPosition) : 0;

    _this.ignorePageChangeEvent = true;

    var result = cahced_openPage_fn.apply(this, [openPageRequest]);

    result.then(function(){
      _this.spineInView = [];

      if(openPageRequest && openPageRequest.idref){
        var contentView = _this.getContentViewFromIdref(openPageRequest.idref);
        _this.spineInView.push(contentView.spineItemIndex);
      } else {
        _this.ignorePageChangeEvent = false;
      }
    });
  }
})();

//Overriding for ReadAlong auto navigation
ReadiumReaderViewAdapter.prototype.openContentUrl = (function() {
  var cahced_openContentUrl_fn = ReadiumReaderViewAdapter.prototype.openContentUrl; 
  
  return async function(contentUrl, sourceFileUrl) {
    var _this = this;

    _this.ignorePageChangeEvent = true;

    try{
      cahced_openContentUrl_fn.apply(this, [contentUrl, sourceFileUrl]);

      var parts = this.resolveContentUrl(contentUrl, sourceFileUrl);
      this.spineInView = [];

      if(parts && parts.idref){
        var contentView = _this.getContentViewFromIdref(parts.idref);
        this.spineInView.push(contentView.spineItemIndex);
      } else {
        _this.ignorePageChangeEvent = false;
      }
    } catch(err){
      _this.ignorePageChangeEvent = false;
    }
  }
})();

// it used to play last read page
ReadiumReaderViewAdapter.prototype.playLastReadPage = function(par) {
  this.mediaOverlayPlayer.playParTrack(par);
}

//Overriding to include the last read location part of bookmark object.
//EpubReader can use this data to persist in indexDB or remote part of last read location (not implemented as of now). 
ReadiumReaderViewAdapter.prototype.bookmarkCurrentPage = function () {
  var bookmark = this.getFirstVisibleCfi();
    
  if(bookmark){
    bookmark.raAudioPosition = this._raAudioPosition? this._raAudioPosition : 0;
  }
  
  return bookmark ? JSON.stringify(bookmark) : null;
};

ReadiumReaderViewAdapter.prototype.userStyles = function() {
  return this._userStyles;
};

ReadiumReaderViewAdapter.prototype.setStyles = function(styles, doNotUpdateView) {
  var count = styles.length;

  for (var i = 0; i < count; i++) {
    if (styles[i].declarations) {
      this._userStyles.addStyle(styles[i].selector, styles[i].declarations);
    } else {
      this._userStyles.removeStyle(styles[i].selector);
    }
  }

  this.applyStyles(doNotUpdateView);
};

ReadiumReaderViewAdapter.prototype.applyStyles = function(doNotUpdateView) {
  if (doNotUpdateView) return;

  if(this.rendCtx.rendition.bookView.spineItemViewStatus.length > 1 && this.rendCtx.rendition.bookView.spineItemViewStatus[2]){
    var navigation = this.rendCtx.rendition.bookView.spineItemViewStatus[2].view.contentView.contentViewImpl.getNavigator()
    var $root = $(navigation.getBodyElement());

    Helpers.setStyles(this._userStyles.getStyles(), $root);
  }

  if (this.mediaOverlayPlayer) this.mediaOverlayPlayer.applyStyles();
}

ReadiumReaderViewAdapter.prototype.mediaOverlaysOpenContentUrl = function (contentRefUrl, sourceFileHref, offset) {
  this.mediaOverlayPlayer.mediaOverlaysOpenContentUrl(contentRefUrl, sourceFileHref, offset);
};

ReadiumReaderViewAdapter.prototype.mediaOverlaysPlayUserContent = function (par, sourceFileHref, offset) {
  this.mediaOverlayPlayer.mediaOverlaysPlayUserContent(par, sourceFileHref, offset);
};

function updateR1SettingMaps(vsAdapter){
  vsAdapter.R1_SETTING_MAP.set(
    "mediaOverlaysRate",
    { name: 'mediaOverlaysRate', valueConverter: toNumberValueConverter },
  );

  vsAdapter.R1_SETTING_REVERSE_MAP.set(
    "mediaOverlaysRate",
    { name: 'mediaOverlaysRate', valueConverter: toNumberValueConverter },
  );

  vsAdapter.R1_SETTING_MAP.set(
    "mediaOverlaysVolume",
    { name: 'mediaOverlaysVolume', valueConverter: toNumberValueConverter },
  );

  vsAdapter.R1_SETTING_REVERSE_MAP.set(
    "mediaOverlaysVolume",
    { name: 'mediaOverlaysVolume', valueConverter: toNumberValueConverter },
  );

  vsAdapter.R1_SETTING_MAP.set(
    "mediaOverlaysSkipSkippables",
    { name: 'mediaOverlaysSkipSkippables', valueConverter: toBooleanValueConverter },
  );

  vsAdapter.R1_SETTING_REVERSE_MAP.set(
    "mediaOverlaysSkipSkippables",
    { name: 'mediaOverlaysSkipSkippables', valueConverter: toBooleanValueConverter },
  );

  vsAdapter.R1_SETTING_MAP.set(
    "mediaOverlaysAutomaticPageTurn",
    { name: 'mediaOverlaysAutomaticPageTurn', valueConverter: toBooleanValueConverter },
  );

  vsAdapter.R1_SETTING_REVERSE_MAP.set(
    "mediaOverlaysAutomaticPageTurn",
    { name: 'mediaOverlaysAutomaticPageTurn', valueConverter: toBooleanValueConverter },
  );
  
  vsAdapter.R1_SETTING_MAP.set(
    "mediaOverlaysPreservePlaybackWhenScroll",
    { name: 'mediaOverlaysPreservePlaybackWhenScroll', valueConverter: toBooleanValueConverter },
  );

  vsAdapter.R1_SETTING_REVERSE_MAP.set(
    "mediaOverlaysPreservePlaybackWhenScroll",
    { name: 'mediaOverlaysPreservePlaybackWhenScroll', valueConverter: toBooleanValueConverter },
  );

  vsAdapter.R1_SETTING_MAP.set(
    "mediaOverlaysEnableClick",
    { name: 'mediaOverlaysEnableClick', valueConverter: toBooleanValueConverter },
  );

  vsAdapter.R1_SETTING_REVERSE_MAP.set(
    "mediaOverlaysEnableClick",
    { name: 'mediaOverlaysEnableClick', valueConverter: toBooleanValueConverter },
  );

  vsAdapter.R1_SETTING_MAP.set(
    "mediaOverlaysSynchronizationGranularity",
    { name: 'mediaOverlaysSynchronizationGranularity', valueConverter: genericValueConverter },
  );

  vsAdapter.R1_SETTING_REVERSE_MAP.set(
    "mediaOverlaysSynchronizationGranularity",
    { name: 'mediaOverlaysSynchronizationGranularity', valueConverter: genericValueConverter },
  );
}

function genericValueConverter(value) {
  return value;
}

function toNumberValueConverter(value) {
  return Number(value);
}

function toBooleanValueConverter(value) {
  return Boolean(value);
}

function getFirstVisibleMediaOverlayElement(view) {
  var navigation = view.getNavigator();
  var $root = $(navigation.getBodyElement());
  if (!$root || !$root.length || !$root[0]) return undefined;
  var that = navigation;
  var firstPartial = undefined;
  var availableMediaOverlay = undefined;

  function traverseArray(arr) {
    if (!arr || !arr.length) return undefined;

    for (var i = 0, count = arr.length; i < count; i++) {
      var item = arr[i];
      if (!item) continue;
      var $item = $(item);

      if ($item.data('mediaOverlayData')) {
        var visible = that.getElementVisibility($item, null);

        if (visible) {
          if (!firstPartial) firstPartial = item;
          if (visible == 100) return item;
        } else {
          availableMediaOverlay = item;
        }
      } else {
        var elem = traverseArray(item.children);
        if (elem) return elem;
      }
    }
    console.log("No Visible MediaOverlay Element....");
    return undefined;
  }

  var el = traverseArray([$root[0]]);
  if (!el) el = firstPartial;
  if(!el) el = availableMediaOverlay;
  return el; // var $elements = this.getMediaOverlayElements($root);
  // return this.getVisibleElements($elements, visibleContentOffsets);
};