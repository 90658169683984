import _ from 'underscore';
import URI from 'urijs';
import Helpers from '../EpubReaderHelpers';

var readium;
var TocUtils = function(readium_) {
  readium = readium_ || readium;
};

var _tocTitleMap = {};

//Internal Functions

//Public API
TocUtils.prototype.getTocTitleMap = function() {
  return _tocTitleMap;
};

TocUtils.prototype.setTocData = function(tocData) {
  _tocTitleMap = {};
  _.each(Helpers.flattenTocJSON(tocData), function(tocItem) {
    var href = URI(tocItem.href).path();
    var spineItem = readium.reader.spine().getItemByHref(href);
    if (spineItem && spineItem.idref && !_tocTitleMap[spineItem.idref]) {
      _tocTitleMap[spineItem.idref] = tocItem.title;
    }
  });
};

export default TocUtils;
