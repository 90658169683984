export default function AnchorElement(api) {
  const elements = {};
  const reader = api.reader;

  /**
   * Add an element to anchor now, and persist it across future page turns or reflows.
   * 
   * @param {string} idref 
   *    Spine item's idref. Example: `ch03`
   * @param {string} partialCfi 
   *    CFI fragment (contentCFI) Example: `/4/74,/1:321,/1:687`
   * @param {HTMLElement} elementToAdd 
   *    A DOM element to anchor to the paginated spine item and CFI location.
   * 
   * @returns {HTMLElement | null} 
   *    A new reference to the anchored element or `null` depending if the operation succeeded.
   * 
   * Element anchoring tips:
   *  - Element can come pre-styled using the `style` attribute.
   *  - `top`, `left`, and `position` style properties 
   *    will be overwritten for anchoring purposes.
   *  - Use `margin` positive/negative values to offset the positioning if needed.
   *  - Use data:base64 URIs or blob URIs from the top level context 
   *    for `src` attributes. 
   */
  this.addElement = function(idref, partialCfi, elementToAdd) {
    if (!elements[idref]) {
      elements[idref] = {};
    }

    if (elements[idref][partialCfi]) {
      return null;
    }

    elements[idref][partialCfi] = elementToAdd;

    let boundElement;
    invokeInContext((idref, frame, cfi, element) => {
      if (elementToAdd === element && partialCfi === cfi) {
        boundElement = bindElement(frame, element);
        anchorElement(idref, cfi, element);
      }
    });

    return elements[idref][partialCfi] = boundElement;
  };

  /**
   * Remove a previously added element now, and stop persisting it.
   * 
   * @param {string} idref 
   *    Spine item's idref. Example: `ch03`
   * @param {string} partialCfi 
   *    CFI fragment (contentCFI) Example: `/4/74,/1:321,/1:687`
   * 
   * @returns {boolean} 
   *    `true` or `false` depending if the operation succeeded.
   */
  this.removeElement = function(idref, partialCfi) {
    let removed = false;

    invokeInContext((idrefInContext, _frame, cfi, element) => {
      if (idrefInContext === idref && cfi === partialCfi) {
        element.remove();
        removed = true;
      }
    });

    if (elements[idref]) {
      delete elements[idref][partialCfi];
    }

    return removed;
  };

  reader.on(ReadiumSDK.Events.CONTENT_DOCUMENT_LOADED, ($iframe, spineItem) => {
    bindElementsToFrame(spineItem.idref, $iframe[0]);
  });

  reader.on(ReadiumSDK.Events.PAGINATION_CHANGED, () => {
    invokeInContext((idref, _frame, cfi, element) => {
      setTimeout(() => {
        anchorElement(idref, cfi, element);  
      }, 0);
    });
  });

  function invokeInContext(callback) {
    const loadedFrames = reader.getLoadedContentFrames();

    if (!loadedFrames) {
      return;
    }
    for (const spineItemFramePair of loadedFrames) {
      const idref = spineItemFramePair.spineItem.idref;
      const frame = spineItemFramePair.$iframe[0];
      const elementsInFrame = elements[idref];
      if (!frame || !elementsInFrame) {
        continue;
      }
      for (const [cfi, element] of Object.entries(elementsInFrame)) {
        callback(idref, frame, cfi, element);
      }
    }
  }

  function bindElementsToFrame(idref, frame) {
    const elementsInFrame = elements[idref];
    if (!frame || !elementsInFrame) {
      return;
    }
    for (const [cfi, element] of Object.entries(elementsInFrame)) {
      if (element.ownerDocument !== frame.contentDocument) {
        elements[idref][cfi] = bindElement(frame, element);
      }
    }
  }

  function bindElement(frame, element) {
    return frame.contentDocument.documentElement.appendChild(element);
  }

  function anchorElement(idref, partialCfi, element) {
    const range = reader.getDomRangeFromRangeCfi({ idref, contentCFI: partialCfi });
    if (!range) {
      return;
    }

    const [firstClientRect] = range.getClientRects();
    if (firstClientRect) {
      element.style.top = firstClientRect.top;
      element.style.left = firstClientRect.left;
      element.style.position = 'absolute';
    }
  }
}
