var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- BH-325 (Removing old TOC)\n<div id=\"readium-toc-body\"\naria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"toc") : stack1), depth0))
    + "\"\nrole=\"navigation\"\n>\n</div>\n-->\n\n<footer class=\"footer-epub\">\n    <div class=\"container-offline text-center\">ONLINE MODE</div>\n    <div class=\"container-pagination\">\n        <div class=\"container-progress wdg-slider-control\">\n            <div class=\"progress-slider wdg-slider\">\n                <input id=\"progress-slider-input\" aria-label=\"Page\" type=\"range\" value=\"0\"  min=\"0\" max=\"100\" role=\"slider\" aria-valuemin=\"0\" aria-valuemax=\"100\"  step=\"1\"  aria-valuenow=\"0\" aria-valuetext=\"\">\n            </div>\n        </div>\n        <div class=\"container-pages\">\n            <div class=\"page-current\"></div>\n            <!-- <button class=\"btn-show-ui\"><div class=\"icon icon-chevron-up\"></div></button> -->\n            <div class=\"page-remaining\"></div>\n        </div>\n    </div>\n</footer>\n\n<div id=\"reading-area\" role=\"main\" aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_reading_area") : stack1), depth0))
    + "\">\n    <div id=\"readium-page-btns\" role=\"navigation\"  class=\"page-navigation-buttons\">\n        <!-- page left/right buttons inserted here when EPUB is loaded (page progression direction) -->\n    </div>\n    <div id=\"epub-reader-container\">\n        <div id=\"epub-reader-frame\">\n        </div>\n    </div>\n</div>\n\n<div id=\"statusDiv\" role=\"alert\" aria-live=\"assertive\" tabindex=\"-1\" style=\"position: absolute; height: 0px; width: 0px; opacity: 0.01; overflow: hidden;\"></div>\n";
},"useData":true});