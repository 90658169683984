import $ from 'jquery';
import _ from 'underscore';
import Strings from '../../i18n/Strings';
import Menu from '../Menu';
import PopupFactory from '../../popup/PopupFactory';
import SearchMenuTemplate from '../../templates/popup/search-menu-container-layout.hbs';
import SearchMenuBookItem from '../../templates/popup/search-menu-book-item.hbs';
import SearchManager from '../../search/SearchManager';
import SearchResultHighlighter from '../../search/ui/SearchResultHighlighter';
import Helpers from '../../EpubReaderHelpers';
import TocUtils from '../../menus/TocUtils';
import Managers from '@axisnow/data/Managers';
import Feature from '@axisnow/data/app-permission/Feature';
import AccessibilityManager from '../../accessibility/AcessibilityManager';
import errorMonitor from '../../ErrorMonitor';
import utils from '@axisnow/util/utils';
import UsageManager from '../../usage/UsageManager';
import Dialogs from '../../Dialogs';
var SearchMenu = function() {
  var that = this;
  Menu.apply(this, arguments);

  this.popup(
    PopupFactory.create(PopupFactory.TYPE.NAVBAR_WITH_SEARCH_BOX_MENU, 'Search', {
      id: 'searchPopup',
      cssClass: 'app-search-menu',
    }),
  );
  this.$bookSearchMenu = this.popup().$el;
  this.$bookSearchContainerLayoutContent = this.popup().addContainerLayout(SearchMenuTemplate({}));
  this.$searchBox = this.$bookSearchMenu.find('.search-box');
  this.$searchContainerHeader = this.$bookSearchContainerLayoutContent.find('.pane.header-pane h2');
  this.$searchContainerList = this.$bookSearchContainerLayoutContent.find('.list-group');

  // WA enhancements
  this.$bookSearchMenu.attr('role', 'dialog').attr('aria-label', Strings.search);
  this._tocUtils = new TocUtils(this.readium);
  this._searchManager = new SearchManager(this.isbn);
  this._searchResultHighlighter = new SearchResultHighlighter(this.readium, 'search');
  this.usageManager = null;
  this.currentLocObj = null;
  this.$bookSearchMenu.find('#search-box-input-form').submit(function(e) {
    // prevent page navigation on form submit
    e.preventDefault();

    // Only one word supported for now (exact search)
    var searchQuery = that.$searchBox.val();
    that.searchBook(searchQuery);
  });

  this.$bookSearchMenu.find('.btn-search-clear').on('click', function() {
    that.clearSearch();
    that.$searchBox.focus();
  });

  this.$bookSearchMenu.find('#search-box-input-form .btn-search').on('click', function(e) {
    if (!that.$searchBox.val().trim().length) {
      $('#search-box-input-form')
        .find('#searchErrorMsg')
        .text('Please fill out the field.')
        .css('color', 'red');
      $('#search-box-input-form')
        .find('#searchErrorMsg')
        .attr('aria-invalid', 'true');
    } else {
      $('#search-box-input-form')
        .find('#searchErrorMsg')
        .text('');
      $('#search-box-input-form')
        .find('#searchErrorMsg')
        .attr('aria-invalid', 'false');
    }
  });

  this.$searchBox.on('input change', function() {
    if (!that.$searchBox.val().trim().length && that.$searchContainerHeader.text().trim().length) {
      that.clearSearch();
    }
  });
  this.$searchBox.on('keypress', function(e) {
    if (e.key != 'Enter') {
      $('#search-box-input-form')
        .find('#searchErrorMsg')
        .text('');
    }
    if (e.key === 'Escape') {
      that.clearSearch();
      $('#searchPopup .js-close-popup').trigger('click');
    }
  });
};

SearchMenu.prototype = Object.create(Menu.prototype);

//Manage feature of Search menu through Feature API
var featureManager = Managers.feature;
featureManager.feature(
  new Feature(
    'SearchMenu-navbar',
    function() {
      $('header#app-navbar')
        .find('#btnToggleSearch')
        .show();
      console.log('Search Menu feature enabled');
    },
    function() {
      $('header#app-navbar')
        .find('#btnToggleSearch')
        .hide();
      console.log('Search Menu feature disabled');
    },
  ),
);

SearchMenu.prototype.show = function() {
  this.hideAll();
  Menu.prototype.show.call(this, arguments).then(function() {
    console.log("Search Menu displayed");
  }).catch(function(error) {
    console.error(error);
    errorMonitor.bugsnag(error);
  });
  // AccessibilityManager.setTabGroup("search_right_popup")
  // AccessibilityManager.updateTabOrder("search_right_popup")
};

SearchMenu.prototype.clearSearch = function() {
  this._searchResultHighlighter.clear();
  this.$searchContainerList.empty();
  this.$searchContainerHeader.text('');
  this.$searchBox.val('');
};

SearchMenu.prototype.searchBook = function(query) {
  this.clearSearch();
  query = query.trim();

  //limit search to 200 characters
  if (query.length > 199) {
    query = query.slice(0, 199);
  }

  this._searchForWord(query);
};

SearchMenu.prototype._searchForWord = function(searchWord) {
  var that = this;

  this.$searchBox.val(searchWord);
  this.usageManager = new UsageManager(Managers.user);
  this.usageManager.getPageUsage(this.isbn).then(function(currentPosition){
    let location = currentPosition.location;
    that.currentLocObj = Helpers.locObjFromFullCFI(
      that.readium.packageDocument,
      location,
      true,
    );
  });

  this._searchManager.searchByWord(searchWord).then(function(response) {
    var tocTitleMap = that._tocUtils.getTocTitleMap();
    that._searchResultHighlighter.clear();
    if (response.count == 0) {
      that.$searchContainerHeader.text('No results were found');
      $('#statusDiv').text('No results were found for ' + searchWord);
    } else {
      that.$searchContainerHeader.text(response.count + ' Results');
      $('#statusDiv').text(response.count + ' Results found for ' + searchWord);
    }
    that.$searchContainerList.empty();
    var searchResultLocations = [];
    _.each(response.results, function(searchResults, baseCfi) {
      _.each(searchResults, function(searchResult) {
        var textPositionArray = _.map(searchResult.position.split(':'), function(n) {
          return parseInt(n);
        });
        var resultText = searchResult.text;
        var textContext =
          resultText.slice(0, textPositionArray[0]) +
          '<span class="colored">' +
          resultText.slice(textPositionArray[0], textPositionArray[1]) +
          '</span>' +
          resultText.slice(textPositionArray[1]);
        var locObj = that._locObjFromFullCFI(searchResult.cfi);

        var bookItem = {
          text: textContext,
          cfi: searchResult.cfi,
        };

        if (tocTitleMap[locObj.idref]) {
          bookItem.title = tocTitleMap[locObj.idref];
        }

        that.$searchContainerList.append(SearchMenuBookItem(bookItem));
        searchResultLocations.push(locObj);
      });
    });

    that._searchResultHighlighter.setCfiCollection(searchResultLocations);
  }).catch((ex) =>{
    console.error(ex);
    errorMonitor.bugsnag(ex);
    let messageInfo = {
      errorName: 'Search Results Error',
      errorIsbn: this.isbn
    };
    utils.sendInfo(messageInfo);
  }).then(function(){
    that.$searchContainerList
      .find('.list-group-item')
      .off('click')
      .on('click', function (e) {
        Menu.prototype.hide.call(that);
        $('[role=dialog][aria-label=Search]').addClass('WindowResize');
        let locObj = that._locObjFromFullCFI($(this).data('cfi'));
        that._searchResultHighlighter.setFocusedSearchResult(locObj.idref, locObj.contentCFI);
        that.readium.reader.openSpineItemElementCfi(locObj.idref, locObj.contentCFI).catch((exc) => {
          console.error(exc);
          errorMonitor.bugsnag(exc);
          let messageInfo = {
            errorName: 'Search Results - Loading Page Issue',
            errorIsbn: this.isbn
          };
          utils.sendInfo(messageInfo)
          Dialogs.showErrorWithDetails('Search Results Error', 'Unable to navigate the appropriate page due to internal data issue');
          that.readium.reader.openSpineItemElementCfi(that.currentLocObj.idref, that.currentLocObj.contentCFI);
        });
        e.preventDefault();
        return false;
      });
  });
    // setTimeout(function() {
    //     AccessibilityManager.updateTabOrder("search_right_popup", function() {
    //         AccessibilityManager.setFocus(".popup.app-search-menu.popup-right .header-pane")
    //     })
    // }, 500)

};

SearchMenu.prototype._locObjFromFullCFI = function(unwrappedCfi) {
  return Helpers.locObjFromFullCFI(
    this.readium.packageDocument,
    'epubcfi(' + unwrappedCfi + ')',
    true,
  );
};

export default SearchMenu;
