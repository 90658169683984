import TrackUsage from './TrackUsage';
import utils from '@axisnow/util/utils';


function PositionsUsage(id, uuid, profileId, profileType, isPrimary, eventEmitter) {
  Object.defineProperty(this, 'id', {
    value: id,
    writable: true,
    enumerable: true,
  });

  Object.defineProperty(this, 'uuid', {
    value: uuid,
    writable: true,
    enumerable: true,
  });

  Object.defineProperty(this, 'profileid', {
    value: profileId,
    writable: true,
    enumerable: true,
  });

  Object.defineProperty(this, 'profileType', {
    value: profileType,
    writable: true,
    enumerable: true,
  });

  Object.defineProperty(this, 'isPrimary', {
    value: isPrimary,
    writable: true,
    enumerable: true,
  });

  Object.defineProperty(this, 'uuid_id', {
    value: uuid +'|'+id,
    writable: true,
    enumerable: true,
  });

  Object.defineProperty(this, 'uuid_profile_id', {
    value: uuid + '|' + profileId + '|' + id,
    writable: true,
    enumerable: true,
  });

  var audiopositions = [];
  Object.defineProperty(this, 'audiopositions', {
    enumerable: true,
    get: function() {
      return audiopositions;
    },
    set: function(positions) {
      if (positions instanceof Array || typeof positions == 'object') {
        audiopositions = positions;
      } else {
        throw new Error('Unable to set audiopositions. Invalid data.');
      }
      this.eventEmitter.emit('new:updated', this);
    },
  });

  Object.defineProperty(this, 'eventEmitter', {
    value: eventEmitter,
    writable: false,
    enumerable: false,
  });
}

PositionsUsage.usageName = 'PositionsUsage';

PositionsUsage.prototype.addTrackUsage = function(trackObj) {
  var trackUsage = new TrackUsage(this.id, this.uuid, this.eventEmitter);
  trackUsage.setCurrentPosition(trackObj.timeoffset, trackObj.chapter, trackObj.part);
  var ap = this.audiopositions;
  ap.push(trackUsage);
  this.audiopositions = ap;
};

PositionsUsage.prototype.updateAudioPositions = function(audiopositions) {
  this.audiopositions.length = 0;
  this.audiopositions = audiopositions;
};

PositionsUsage.prototype.update = function(positionsUsage) {
  if (positionsUsage.id == this.id && positionsUsage.uuid == this.uuid) {
    if (!(positionsUsage instanceof PositionsUsage)) {
      var usage = new PositionsUsage(positionsUsage.id, positionsUsage.uuid, eventEmitter);
      usage.audiopositions = positionsUsage.audiopositions;
      positionsUsage = usage;
    }
    this.eventEmitter.emit('new:updated', positionsUsage);
  } else {
    throw new Errors.BadData("The ID and/or UUID values don't match.");
  }
  return positionsUsage;
};

export default PositionsUsage;
