import $ from 'jquery';
import AnnotationsUsage from './AnnotationsUsage';
import EpubReaderHelpers from '../EpubReaderHelpers';
var ComicBookAnnotationsUsage = function() {
  AnnotationsUsage.apply(this, arguments);
 Object.defineProperties(this, {
    spines: {
      get: function() {
        return this.sections;
      },
      set: function(value) {
        if (value instanceof Array) {
          value.forEach(function(element, index) {
            element.sectionId = element.refid;
          });
        }
        this.sections = value;
      },
      enumerable: true,
    },
  });
};

ComicBookAnnotationsUsage.usageName = 'ComicBookAnnotationsUsage';

ComicBookAnnotationsUsage.prototype = Object.create(AnnotationsUsage.prototype);

ComicBookAnnotationsUsage.locationEquals = EpubReaderHelpers.cfiEquals;

ComicBookAnnotationsUsage.sortBy = $.extend({}, AnnotationsUsage.sortBy, {
  COLOR: function(annotationsArray, ascending) {
    annotationsArray = annotationsArray.sort(function(a, b) {
      var colorA = a.color.toLowerCase();
      var colorB = b.color.toLowerCase();

      if (ascending) {
        if (colorA < colorB) {
          return -1;
        } else if (colorA > colorB) {
          return 1;
        } else {
          return 0;
        }
      } else {
        if (colorA > colorB) {
          return -1;
        } else if (colorA < colorB) {
          return 1;
        } else {
          return 0;
        }
      }
    });
    return annotationsArray;
  },
});

//Public API
ComicBookAnnotationsUsage.prototype.sortBy = ComicBookAnnotationsUsage.sortBy;

ComicBookAnnotationsUsage.prototype.locationEquals = ComicBookAnnotationsUsage.locationEquals;

export default ComicBookAnnotationsUsage;
