import _ from 'underscore';
import { Globals } from '@axisnow/readium';

function SearchResultHighlighter(readium, type) {
  this._readium = readium;
  this._type = type; // 'search' or 'bookmark'
  this._highlights = readium.reader.plugins.highlights;
  readium.reader.on(
    Globals.Events.CONTENT_DOCUMENT_LOADED,
    function($iframe, spineItem) {
      _.defer(_.bind(this._loaded, this), spineItem);
    },
    this,
  );
}

function getRandomId() {
  return 'search-' + Math.floor(Math.random() * 0x12345).toString(16);
}

SearchResultHighlighter.prototype._loaded = function(spineItem) {
  if (!this._cfiCollection) return;
  _.each(
    this._cfiCollection[spineItem.idref],
    function(cfi) {
      this._highlights.addHighlight(
        spineItem.idref,
        cfi,
        getRandomId(),
        'selection-color-' + this._type,
      );
    },
    this,
  );
};

SearchResultHighlighter.prototype.clear = function() {
  this._cfiCollection = null;
  this._highlights.removeHighlightsByType('selection-color-' + this._type);
};

SearchResultHighlighter.prototype.setCfiCollection = function(locationCollection) {
  this.clear();
  this._cfiCollection = {};

  _.each(
    locationCollection,
    function(locObj) {
      if (!this._cfiCollection[locObj.idref]) {
        this._cfiCollection[locObj.idref] = [];
      }
      this._cfiCollection[locObj.idref].push(locObj.contentCFI);
    },
    this,
  );

  _.each(this._readium.reader.getLoadedSpineItems(), _.bind(this._loaded, this));
};

SearchResultHighlighter.prototype.setFocusedSearchResult = function(idref, cfi) {
  $(document).trigger('REQUEST_FOCUS', [{ idref: idref, cfi: cfi, type: this._type }]);
};

export default SearchResultHighlighter;
