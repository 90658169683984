var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div tabindex=\"-1\" class=\"modal fade\" id=\"settings-dialog\" role=\"dialog\" aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"settings") : stack1), depth0))
    + "\" aria-hidden=\"true\">\n    <div class=\"modal-dialog\">\n        <div class=\"modal-content\">\n\n           <!-- div class=\"modal-header\">\n            <h4 class=\"modal-title\" id=\"settings-label\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"settings") : stack1), depth0))
    + "</h4>\n           </div -->\n\n            <div class=\"modal-body\">\n           <button type=\"button\" class=\"close\" id=\"closeSettingsCross\" data-dismiss=\"modal\" title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_close") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"settings") : stack1), depth0))
    + "\" aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_close") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"settings") : stack1), depth0))
    + "\"><span aria-hidden=\"true\">&times;</span></button>\n\n           <ul class=\"nav nav-tabs\" role=\"tablist\" aria-owns=\"tab-butt-style tab-butt-layout tab-butt-keys\">\n             <li class=\"active\" role=\"presentation\"><button id=\"tab-butt-style\"  title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"style") : stack1), depth0))
    + "\" aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"style") : stack1), depth0))
    + "\" role='tab' aria-controls=\"tab-style\" data-toggle=\"tab\" data-target=\"#tab-style\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"style") : stack1), depth0))
    + "</button></li>\n             <li role=\"presentation\"><button id=\"tab-butt-layout\"  title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"layout") : stack1), depth0))
    + "\" aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"layout") : stack1), depth0))
    + "\" role='tab' aria-controls=\"tab-layout\" data-toggle=\"tab\" data-target=\"#tab-layout\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"layout") : stack1), depth0))
    + "</button></li>\n             <li role=\"presentation\"><button id=\"tab-butt-keys\"  title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_keyboard_shortcuts") : stack1), depth0))
    + "\" aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_keyboard_shortcuts") : stack1), depth0))
    + "\" role='tab' aria-controls=\"tab-keyboard\" data-toggle=\"tab\" data-target=\"#tab-keyboard\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_keyboard_shortcuts") : stack1), depth0))
    + "</button></li>\n           </ul>\n\n\n           <div class=\"tab-content\">\n                <div id=\"tab-style\" class=\"tab-pane active\" role=\"tabpanel\" aria-expanded=\"true\">\n\n                <h5 aria-hidden=\"true\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"preview") : stack1), depth0))
    + "</h5>\n                <div  aria-hidden=\"true\" class=\"row\">\n                    <div data-theme=\"default-theme\" class=\"col-xs-10 col-xs-offset-1 preview-text default-theme\">\n                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus neque dui, congue a suscipit non, feugiat eu urna. Cras in felis sed orci aliquam sagittis.\n                    </div>\n                </div>\n\n<!-- button  type=\"button\" title=\"TESTING\" aria-label=\"TESTING\">TESTING DANIEL</button -->\n\n                    <h5 id=\"setting-header-font-size\" class=\"setting-header\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_font_size") : stack1), depth0))
    + "</h5>\n                    <div class=\"row\">\n                        <div class=\"col-xs-2 icon-scale-down\">\n                            <img src=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"imagePathPrefix") || (depth0 != null ? lookupProperty(depth0,"imagePathPrefix") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"imagePathPrefix","hash":{},"data":data,"loc":{"start":{"line":34,"column":38},"end":{"line":34,"column":59}}}) : helper))) != null ? stack1 : "")
    + "images/glyphicons_115_text_smaller.png\" alt=\"\" aria-hidden=\"true\">\n                        </div>\n                        <div class=\"col-xs-8\">\n                            <input  type=\"range\" role=\"slider\" aria-labelledby=\"setting-header-font-size\" id=\"font-size-input\" min=\"60\" aria-value-min=\"60\" aria-valuemin=\"60\" step=\"10\" max=\"170\" aria-value-max=\"170\" aria-valuemax=\"170\" value=\"100\" aria-valuenow=\"100\" aria-value-now=\"100\" aria-valuetext=\"1em\" aria-value-text=\"1em\" title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_font_size") : stack1), depth0))
    + "\" aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_font_size") : stack1), depth0))
    + "\" />\n                        </div>\n                        <div class=\"col-xs-2\">\n                            <img src=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"imagePathPrefix") || (depth0 != null ? lookupProperty(depth0,"imagePathPrefix") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"imagePathPrefix","hash":{},"data":data,"loc":{"start":{"line":40,"column":38},"end":{"line":40,"column":59}}}) : helper))) != null ? stack1 : "")
    + "images/glyphicons_116_text_bigger.png\" alt=\"\" aria-hidden=\"true\">\n                        </div>\n                    </div>\n\n                    <h5 id=\"setting-header-color-legend\" class=\"setting-header\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_text_and_background_color") : stack1), depth0))
    + "</h5>\n                    <div role=\"group\" aria-labelledby=\"setting-header-color-legend\" id=\"theme-radio-group\" class=\"row\">\n\n                        <button role=\"button\" data-theme=\"author-theme\"  title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_author_theme") : stack1), depth0))
    + "\" aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_author_theme") : stack1), depth0))
    + "\" class=\"col-xs-8 col-xs-offset-2 theme-option author-theme clickable\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_author_theme") : stack1), depth0))
    + "</button>\n\n                        <button role=\"button\" data-theme=\"default-theme\"  title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_black_and_white") : stack1), depth0))
    + "\" aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_black_and_white") : stack1), depth0))
    + "\" class=\"col-xs-8 col-xs-offset-2 theme-option default-theme clickable\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_black_and_white") : stack1), depth0))
    + "</button>\n\n                        <button role=\"button\" data-theme=\"night-theme\"  title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_arabian_nights") : stack1), depth0))
    + " ["
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"keyboard") : depth0)) != null ? lookupProperty(stack1,"NightTheme") : stack1), depth0))
    + "]\" aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_arabian_nights") : stack1), depth0))
    + " ["
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"keyboard") : depth0)) != null ? lookupProperty(stack1,"NightTheme") : stack1), depth0))
    + "]\" class=\"col-xs-8 col-xs-offset-2 theme-option night-theme clickable\" >"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_arabian_nights") : stack1), depth0))
    + "</button> <!-- accesskey=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"keyboard") : depth0)) != null ? lookupProperty(stack1,"accesskeys") : stack1)) != null ? lookupProperty(stack1,"NightTheme") : stack1), depth0))
    + "\" -->\n\n                        <button role=\"button\" data-theme=\"parchment-theme\"  title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_sands_of_dune") : stack1), depth0))
    + "\" aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_sands_of_dune") : stack1), depth0))
    + "\" class=\"col-xs-8 col-xs-offset-2 theme-option parchment-theme clickable\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_sands_of_dune") : stack1), depth0))
    + "</button>\n\n                        <button role=\"button\" data-theme=\"ballard-theme\"  title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_ballard_blues") : stack1), depth0))
    + "\" aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_ballard_blues") : stack1), depth0))
    + "\" class=\"col-xs-8 col-xs-offset-2 theme-option ballard-theme clickable\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_ballard_blues") : stack1), depth0))
    + "</button>\n\n                        <button role=\"button\" data-theme=\"vancouver-theme\"  title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_vancouver_mist") : stack1), depth0))
    + "\" aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_vancouver_mist") : stack1), depth0))
    + "\" class=\"col-xs-8 col-xs-offset-2 theme-option vancouver-theme clickable\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_vancouver_mist") : stack1), depth0))
    + "</button>\n                    </div>\n                </div>\n                <div id=\"tab-layout\" class=\"tab-pane\" role=\"tabpanel\">\n\n                    <h5 id=\"setting-header-margins-legend\" class=\"setting-header\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_margins") : stack1), depth0))
    + "</h5>\n                     <div class=\"row\">\n                        <div class=\"col-xs-2 icon-scale-down\">\n                            <img style=\"height: 32px;\" src=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"imagePathPrefix") || (depth0 != null ? lookupProperty(depth0,"imagePathPrefix") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"imagePathPrefix","hash":{},"data":data,"loc":{"start":{"line":65,"column":60},"end":{"line":65,"column":81}}}) : helper))) != null ? stack1 : "")
    + "images/margin1_off.png\" alt=\"\" aria-hidden=\"true\">\n                        </div>\n                        <div class=\"col-xs-8\">\n                            <input  type=\"range\" role=\"slider\" aria-labelledby=\"setting-header-margins-legend\" id=\"margin-size-input\" min=\"20\" aria-value-min=\"20\" aria-valuemin=\"20\" step=\"20\" max=\"100\" aria-value-max=\"100\" aria-valuemax=\"100\" value=\"20\" aria-valuenow=\"20\" aria-value-now=\"20\" aria-valuetext=\"20\" aria-value-text=\"20\" title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_margins") : stack1), depth0))
    + "\" aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_margins") : stack1), depth0))
    + "\"/>\n                        </div>\n                        <div class=\"col-xs-2\">\n                            <img style=\"height: 32px;\" src=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"imagePathPrefix") || (depth0 != null ? lookupProperty(depth0,"imagePathPrefix") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"imagePathPrefix","hash":{},"data":data,"loc":{"start":{"line":71,"column":60},"end":{"line":71,"column":81}}}) : helper))) != null ? stack1 : "")
    + "images/margin4_off.png\" alt=\"\" aria-hidden=\"true\">\n                        </div>\n                    </div>\n\n                    <h5 id=\"setting-header-display-legend\" class=\"setting-header\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_display_format") : stack1), depth0))
    + "</h5>\n\n                    <div role=\"radiogroup\" class=\"row\" style=\"width:100%;text-align:center;\" aria-labelledby=\"setting-header-display-legend\">\n\n                        <div role=\"radio\" id=\"spread-default-option\"\n                        style=\"vertical-align:middle;width:30%;display:inline-block;position:relative;\">\n                            <input style=\"\"  name=\"display-format\" value=\"single\" type=\"radio\" id=\"spread-default-radio\"/>\n                            <label style=\"\" for=\"spread-default-radio\" class=\"underlinedLabel\">\n                                "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_spread_auto") : stack1), depth0))
    + "\n                            </label>\n                        </div>\n\n\n                        <div role=\"radio\" id=\"one-up-option\"\n                        style=\"vertical-align:middle;width:30%;display:inline-block;position:relative;\">\n\n                            <input  name=\"display-format\" value=\"single\" type=\"radio\" id=\"single-page-radio\" />\n                                            <label for=\"single-page-radio\" class=\"underlinedLabel\">\n                                            <img src=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"imagePathPrefix") || (depth0 != null ? lookupProperty(depth0,"imagePathPrefix") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"imagePathPrefix","hash":{},"data":data,"loc":{"start":{"line":93,"column":54},"end":{"line":93,"column":75}}}) : helper))) != null ? stack1 : "")
    + "images/ico_singlepage_up.png\" alt=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_display_format") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_single_pages") : stack1), depth0))
    + "\"/>\n                                            </label>\n                        </div>\n                        <div role=\"radio\" id=\"two-up-option\"\n                        style=\"vertical-align:middle;width:30%;display:inline-block;position:relative;\">\n\n                                <input  name=\"display-format\" value=\"double\" type=\"radio\" id=\"double-page-radio\" />\n                                            <label for=\"double-page-radio\" class=\"underlinedLabel\">\n                                            <img src=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"imagePathPrefix") || (depth0 != null ? lookupProperty(depth0,"imagePathPrefix") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"imagePathPrefix","hash":{},"data":data,"loc":{"start":{"line":101,"column":54},"end":{"line":101,"column":75}}}) : helper))) != null ? stack1 : "")
    + "images/ico_doublepage_up.png\" alt=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_display_format") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_double_pages") : stack1), depth0))
    + "\">\n                                            </label>\n                        </div>\n                    </div>\n\n                    <h5 id=\"setting-header-scroll-legend\" class=\"setting-header\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_scroll_mode") : stack1), depth0))
    + "</h5>\n\n                    <div role=\"radiogroup\" class=\"row\" style=\"width:100%;text-align:center;\" aria-labelledby=\"setting-header-scroll-legend\">\n\n                        <div role=\"radio\" id=\"scroll-default-option\" style=\"vertical-align:middle;width:30%;display:inline-block;position:relative;\">\n                            <input style=\"\"  name=\"scrolling\" value=\"single\" type=\"radio\" id=\"scroll-default-radio\"/>\n                            <label style=\"\" for=\"scroll-default-radio\" class=\"underlinedLabel\">\n                                "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_scroll_mode_auto") : stack1), depth0))
    + "\n                            </label>\n                        </div>\n\n                        <div role=\"radio\" id=\"scroll-doc-option\" style=\"vertical-align:middle;width:30%;display:inline-block;position:relative;\">\n                            <input style=\"\"  name=\"scrolling\" value=\"single\" type=\"radio\" id=\"scroll-doc-radio\"/>\n                            <label style=\"\" for=\"scroll-doc-radio\" class=\"underlinedLabel\">\n                                <span style=\"font-size:150%;color:#888888;\" class=\"glyphicon glyphicon-file\" aria-hidden=\"true\"></span> "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_scroll_mode_doc") : stack1), depth0))
    + "\n                            </label>\n                        </div>\n\n                        <div role=\"radio\" id=\"scroll-continuous-option\" style=\"vertical-align:middle;width:30%;display:inline-block;position:relative;\">\n                            <input style=\"\"  name=\"scrolling\" value=\"single\" type=\"radio\" id=\"scroll-continuous-radio\"/>\n                            <label style=\"\" for=\"scroll-continuous-radio\" class=\"underlinedLabel\">\n                                <span style=\"font-size:150%;color:#888888;\" class=\"glyphicon glyphicon-road\" aria-hidden=\"true\"></span> "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_scroll_mode_continuous") : stack1), depth0))
    + "\n                            </label>\n                        </div>\n\n                    </div>\n\n                    <!-- Page transitions are seriously broken (see readium-shared-js one_page_view.js disablePageTransitions boolean), so we hide the UI config options -->\n                    <h5 style=\"display:none;\" hiddenx=\"hidden\" id=\"setting-header-pageTransition-legend\" class=\"setting-header\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_page_transition") : stack1), depth0))
    + "</h5>\n\n                    <div style=\"display:none; width:100%;text-align:center;\" hiddenx=\"hidden\" role=\"radiogroup\" class=\"row\" aria-labelledby=\"setting-header-pageTransition-legend\">\n\n                        <div role=\"radio\" id=\"pageTransition-none-option\" style=\"vertical-align:middle;width:15%;display:inline-block;position:relative;\">\n                            <input style=\"\"  name=\"pageTransition\" value=\"single\" type=\"radio\" id=\"pageTransition-none-radio\"/>\n                            <label style=\"\" for=\"pageTransition-none-radio\" class=\"underlinedLabel\">\n                                "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_page_transition_none") : stack1), depth0))
    + "\n                            </label>\n                        </div>\n\n                        <div role=\"radio\" id=\"pageTransition-1-option\" style=\"vertical-align:middle;width:15%;display:inline-block;position:relative;\">\n                            <input style=\"\"  name=\"pageTransition\" value=\"single\" type=\"radio\" id=\"pageTransition-1-radio\"/>\n                            <label style=\"\" for=\"pageTransition-1-radio\" class=\"underlinedLabel\">\n                                "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_page_transition_fade") : stack1), depth0))
    + "\n                            </label>\n                        </div>\n\n                        <div role=\"radio\" id=\"pageTransition-2-option\" style=\"vertical-align:middle;width:15%;display:inline-block;position:relative;\">\n                            <input style=\"\"  name=\"pageTransition\" value=\"single\" type=\"radio\" id=\"pageTransition-2-radio\"/>\n                            <label style=\"\" for=\"pageTransition-2-radio\" class=\"underlinedLabel\">\n                                "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_page_transition_slide") : stack1), depth0))
    + "\n                            </label>\n                        </div>\n\n                        <div role=\"radio\" id=\"pageTransition-3-option\" style=\"vertical-align:middle;width:15%;display:inline-block;position:relative;\">\n                            <input style=\"\"  name=\"pageTransition\" value=\"single\" type=\"radio\" id=\"pageTransition-3-radio\"/>\n                            <label style=\"\" for=\"pageTransition-3-radio\" class=\"underlinedLabel\">\n                                "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_page_transition_swoosh") : stack1), depth0))
    + "\n                            </label>\n                        </div>\n\n                        <div role=\"radio\" id=\"pageTransition-4-option\" style=\"vertical-align:middle;width:15%;display:inline-block;position:relative;\">\n                            <input style=\"\"  name=\"pageTransition\" value=\"single\" type=\"radio\" id=\"pageTransition-4-radio\"/>\n                            <label style=\"\" for=\"pageTransition-4-radio\" class=\"underlinedLabel\">\n                                "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_page_transition_butterfly") : stack1), depth0))
    + "\n                            </label>\n                        </div>\n\n\n                    </div>\n\n\n                    </div>\n                    <div id=\"tab-keyboard\" class=\"tab-pane\" role=\"tabpanel\">\n\n                    <div class=\"row\" style=\"position:relative;\">\n\n                        <div id=\"invalid_keyboard_shortcut_ALERT\"></div>\n\n                        <ul id=\"keyboard-list\">\n                        </ul>\n                    </div>\n                     </div>\n                </div>\n            </div>\n            <div class=\"modal-footer\">\n                <button id=\"buttClose\"  type=\"button\" class=\"btn btn-default\" data-dismiss=\"modal\" title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_close") : stack1), depth0))
    + " ["
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"keyboard") : depth0)) != null ? lookupProperty(stack1,"SettingsModalClose") : stack1), depth0))
    + "]\" aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_close") : stack1), depth0))
    + " ["
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"keyboard") : depth0)) != null ? lookupProperty(stack1,"SettingsModalClose") : stack1), depth0))
    + "]\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_close") : stack1), depth0))
    + "</button>\n                <button id=\"buttSave\"  type=\"button\" class=\"btn btn-primary\" data-dismiss=\"modal\" title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_save_changes") : stack1), depth0))
    + " ["
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"keyboard") : depth0)) != null ? lookupProperty(stack1,"SettingsModalSave") : stack1), depth0))
    + "]\" aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_save_changes") : stack1), depth0))
    + " ["
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"keyboard") : depth0)) != null ? lookupProperty(stack1,"SettingsModalSave") : stack1), depth0))
    + "]\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_save_changes") : stack1), depth0))
    + "</button>\n            </div>\n        </div>\n        <!-- /.modal-content -->\n    </div>\n    <!-- /.modal-dialog -->\n</div>\n";
},"useData":true});