import Popup from './Popup';

var PopupInPlace = Popup.prototype.extends(function(count, type, options) {
  options = this.appendPopup(options);
  this.$el.addClass('popup-inplace');
  /*if (type === Popup.TYPE.MODAL){
             options.height = options.height || "20em";
             options.width = options.width || "30em";
             this.$el.addClass("popup-modal");
             this.$body.innerWidth(options.width);
             this.$body.innerHeight(options.height);
             }*/
});

PopupInPlace.prototype.hide = function() {
  Popup.prototype.hide.call(this);
  this.$el.removeData();
};

export default PopupInPlace;
