export class SpineItem {
  constructor(data) {
    this.idref = data.idref;
    this.href = data.href;
    this.manifest_id = data.manifest_id;
    this.media_type = data.media_type;
    this.media_overlay_id = data.media_overlay_id;
    this.linear = data.linear;
    this.properties = data.properties;
    this.cfi = data.cfi;
    this.rendition_viewport = data.rendition_viewport;

    Object.assign(this, this._parsePropertiesString(this.properties, false));
  }

  /**
   * Serialize into a Readium Web Manifest - Link Object
   * https://readium.org/webpub-manifest/#24-the-link-object
   *
   * Properties are extended to include EPUB idref and leading CFI information.
   */
  serialize() {
    const rwpmLink = {
      href: this.href,
      type: this.media_type,
      properties: {
        idref: this.idref,
        cfi: this.cfi,
      },
    };

    Object.assign(rwpmLink.properties, this._parsePropertiesString(this.properties, true));

    return rwpmLink;
  }

  _parsePropertiesString(properties, serialization) {
    const splitProps = properties.split(' ');
    const outProperties = {};
    const outSerialization = {};

    splitProps.forEach((property) => {
      //ReadiumSDK.Models.SpineItem.RENDITION_ORIENTATION_LANDSCAPE
      if (property === 'rendition:orientation-landscape') {
        outProperties.rendition_orientation = outSerialization.orientation = 'landscape';
      }

      //ReadiumSDK.Models.SpineItem.RENDITION_ORIENTATION_PORTRAIT
      if (property === 'rendition:orientation-portrait') {
        outProperties.rendition_orientation = outSerialization.orientation = 'portrait';
      }

      //ReadiumSDK.Models.SpineItem.RENDITION_ORIENTATION_AUTO
      if (property === 'rendition:orientation-auto') {
        outProperties.rendition_orientation = outSerialization.orientation = 'auto';
      }

      //ReadiumSDK.Models.SpineItem.RENDITION_SPREAD_NONE
      if (property === 'rendition:spread-none') {
        outProperties.rendition_spread = outSerialization.spread = 'none';
      }

      //ReadiumSDK.Models.SpineItem.RENDITION_SPREAD_LANDSCAPE
      if (property === 'rendition:spread-landscape') {
        outProperties.rendition_spread = outSerialization.spread = 'landscape';
      }

      //ReadiumSDK.Models.SpineItem.RENDITION_SPREAD_PORTRAIT
      if (property === 'rendition:spread-portrait') {
        outProperties.rendition_spread = outSerialization.spread = 'portrait';
      }

      //ReadiumSDK.Models.SpineItem.RENDITION_SPREAD_BOTH
      if (property === 'rendition:spread-both') {
        outProperties.rendition_spread = outSerialization.spread = 'both';
      }

      //ReadiumSDK.Models.SpineItem.RENDITION_SPREAD_AUTO
      if (property === 'rendition:spread-auto') {
        outProperties.rendition_spread = outSerialization.spread = 'auto';
      }

      //ReadiumSDK.Models.SpineItem.RENDITION_FLOW_PAGINATED
      if (property === 'rendition:flow-paginated') {
        outProperties.rendition_flow = outSerialization.overflow = 'paginated';
      }

      //ReadiumSDK.Models.SpineItem.RENDITION_FLOW_SCROLLED_CONTINUOUS
      if (property === 'rendition:flow-scrolled-continuous') {
        outProperties.rendition_flow = outSerialization.overflow = 'scrolled-continuous';
      }

      //ReadiumSDK.Models.SpineItem.RENDITION_FLOW_SCROLLED_DOC
      if (property === 'rendition:flow-scrolled-doc') {
        outProperties.rendition_flow = 'scrolled-doc';
        outSerialization.overflow = 'scrolled';
      }

      //ReadiumSDK.Models.SpineItem.RENDITION_FLOW_AUTO
      if (property === 'rendition:flow-auto') {
        outProperties.rendition_flow = outSerialization.overflow = 'auto';
      }

      //ReadiumSDK.Models.SpineItem.SPREAD_CENTER
      if (property === 'rendition:page-spread-center') {
        outProperties.page_spread = 'page-spread-center';
        outSerialization.page = 'center';
      }

      //ReadiumSDK.Models.SpineItem.SPREAD_LEFT
      if (property === 'page-spread-left') {
        outProperties.page_spread = 'page-spread-left';
        outSerialization.page = 'left';
      }

      //ReadiumSDK.Models.SpineItem.SPREAD_RIGHT
      if (property === 'page-spread-right') {
        outProperties.page_spread = 'page-spread-right';
        outSerialization.page = 'right';
      }

      //ReadiumSDK.Models.SpineItem.RENDITION_LAYOUT_REFLOWABLE
      if (property === 'rendition:layout-reflowable') {
        outProperties.rendition_layout = 'reflowable';
        outSerialization.layout = 'reflowable';
      }

      //ReadiumSDK.Models.SpineItem.RENDITION_LAYOUT_PREPAGINATED
      if (property === 'rendition:layout-pre-paginated') {
        outProperties.rendition_layout = 'pre-paginated';
        outSerialization.layout = 'fixed';
      }
    });

    if (serialization) {
      return outSerialization;
    }

    return outProperties;
  }
}
