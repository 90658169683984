import './less/main.less';
import 'console-polyfill';
import '@babel/polyfill';
import 'core-js/features/url';
import * as Promise from 'bluebird';
import { initialize } from './ReadiumViewer';

Promise.config({
  // Enables all warnings except forgotten return statements.
  warnings: {
    wForgottenReturn: false,
  },
});

initialize();
