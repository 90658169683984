import _ from 'underscore';
function DisableCopy(api) {
  var _reader = api.reader;
  var _enabled = true;
  var loadedFrames = new Set();

  this.start = function() {
    _enabled = true;
    loadedFrames.forEach(function(frame) {
      bind(frame);
    });
  };

  this.stop = function() {
    _enabled = false;
    loadedFrames.forEach(function(frame) {
      unbind(frame);
    });
  };

  function eventHandler(e) {
    e.preventDefault();
    return false;
  }

  function bind(frame) {
    if (!_enabled) return;
    frame.contentDocument.addEventListener('copy', eventHandler);
    frame.contentWindow.addEventListener('contextmenu', eventHandler);
  }

  function unbind(frame) {
    frame.contentDocument.removeEventListener('copy', eventHandler);
    frame.contentWindow.removeEventListener('contextmenu', eventHandler);
  }

  _reader.on(
    ReadiumSDK.Events.CONTENT_DOCUMENT_LOADED,
    _.bind(function($iframe, spineItem) {
      bind($iframe[0]);
      loadedFrames.add($iframe[0]);
    }, this),
  );

  _reader.on(
    ReadiumSDK.CONTENT_DOCUMENT_UNLOADED,
    _.bind(function($iframe, spineItem) {
      unbind($iframe[0]);
      loadedFrames.delete($iframe[0]);
    }, this),
  );
}

export default DisableCopy;
