import _ from 'underscore';
import moment from 'moment-timezone';

function AnnotationsUsage(isbn, uuid, profileId, profileType, isPrimary, eventEmitter) {
  var sections = {};

  Object.defineProperties(this, {
    uuid: {
      value: uuid,
      writable: true,
      enumerable: true,
    },
    isbn: {
      value: isbn,
      writable: true,
      enumerable: true,
    },
     profileid: {
      value: profileId,
      writable: true,
      enumerable: true,
    },
     profileType: {
      value: profileType,
      writable: true,
      enumerable: true,
    },
     isPrimary: {
      value: isPrimary,
      writable: true,
      enumerable: true,
    },
    _eventEmitter: {
      value: eventEmitter,
      writable: false,
      enumerable: false,
    },
    uuid_isbn: {
      value: uuid +'|'+isbn,
      writable: true,
      enumerable: true,
    },
     uuid_profile_isbn: {
      value: uuid + '|' + profileId + '|' + isbn ,
      writable: true,
      enumerable: true,
    },
    siteid: {
      value: '',
      writable: true,
      enumerable: true,
    },
    sections: {
      get: function() {
        return sections;
      },
      set: function(value) {
        if (value instanceof Array) {
          sections = {};
          value.forEach(function(element, index) {
            sections[element.sectionId] = element;
          });
        } else if (typeof value === 'object') {
          sections = value;
        } else {
          throw new Error('Unable to set sections. Invalid data.');
        }
        eventEmitter.emit('new:updated', this);
      },
      enumerable: true,
    },
  });
}

AnnotationsUsage.usageName = 'AnnotationsUsage';

AnnotationsUsage.locationEquals = function(left, right) {
  return left === right;
};

AnnotationsUsage.sortBy = {
  DATE: function(annotationsArray, ascending) {
    annotationsArray = annotationsArray.sort(function(a, b) {
      var dateA = moment.utc(a.createdate, 'MM/DD/YYYY hh:mm:ss A'); // date format should match the backend's
      var dateB = moment.utc(b.createdate, 'MM/DD/YYYY hh:mm:ss A'); // date format should match the backend's

      if (ascending) {
        return dateB - dateA;
      } else {
        return dateA - dateB;
      }
    });
    return annotationsArray;
  },
};

//Public API
AnnotationsUsage.prototype.sortBy = AnnotationsUsage.sortBy;

AnnotationsUsage.prototype.locationEquals = AnnotationsUsage.locationEquals;

AnnotationsUsage.prototype.addAnnotation = function(sectionId, annonation) {
  var sections = this.sections;
  if (annonation != undefined && typeof annonation.siteid === 'undefined') {
    annonation.siteid = '';
  }
  if (typeof sections[sectionId] === 'undefined') {
    sections[sectionId] = { sectionId: sectionId, annotations: [] };
  }
  annonation.newAnnotation = true;
  sections[sectionId].annotations.push(annonation);
  this.sections = sections;
};

AnnotationsUsage.prototype.updateAnnotation = function(sectionId, annonation) {
  var sections = this.sections;
  var updatedAnnotation = annonation;
  for (var i in sections[sectionId].annotations) {
    if (this.locationEquals(sections[sectionId].annotations[i].location, annonation.location)) {
      annonation.updateAnnotation = true;
      sections[sectionId].annotations[i] = annonation;
      console.log('Location updated');
    } else {
      console.log('Did not find the location to be updated');
    }
  }
  this.sections = sections;
};

AnnotationsUsage.prototype.deleteAnnotation = function(section, location) {
  let self = this;
  self.sections[section].annotations.map(function(item) {
    if (self.locationEquals(item.location, location)) {
      let index = self.sections[section].annotations.findIndex(item => item.location === location);
      //Issue AXNG-414 and AXNG-426: The property 'updateAnnotation' is checked for the annotation object and
      // set undefined while deleting the annotation, here we expect only one flag at a time for the annotation object.
      if(item.updateAnnotation)
        self.sections[section].annotations[index].updateAnnotation = undefined;
      self.sections[section].annotations[index].deleteAnnotation = true;
      console.log('Deleting location : ' + location);
      if(item.newAnnotation === true){
        self.sections[section].annotations.splice(index,1);
      }
    } else {
      console.log('Did not find the location to be deleted');
    }
  });
  this.sections = self.sections;
};

AnnotationsUsage.prototype.getAnnotation = function(sectionId, location) {
  var sections = this.sections;
  if (!sections[sectionId]) return;

  for (var i in sections[sectionId].annotations) {
    if (this.locationEquals(sections[sectionId].annotations[i].location, location)) {
      console.log('Found Annotation ');
      //Issue AXNG-414: If the annotation has property deleteAnnotation, which means the annotation which is being added to
      // the selected text had an annotation earlier for the same text and was deleted. This scenario can only happen in
      // Offline mode. If property deleteAnnotation found, we have to treat this as a fresh annotation while adding.
      let data = sections[sectionId].annotations[i];
      if(data.deleteAnnotation == true){
        return undefined;
      }else{
        return sections[sectionId].annotations[i];
      }
    } else {
      console.log('Did not find the annotation');
    }
  }
  this.sections = sections;
};

AnnotationsUsage.prototype.isChanged = function() {
  var isChanged = false;
  for (var sectionId in this.sections) {
    for (var i = 0; i < this.sections[sectionId].annotations.length; i++) {
      var annotation = this.sections[sectionId].annotations[i];
      if (annotation.newAnnotation || annotation.updateAnnotation || annotation.deleteAnnotation) {
        isChanged = true;
        break;
      }
    }
    if (isChanged) {
      break;
    }
  }
  return isChanged;
};
//Sort Annotations Array
AnnotationsUsage.prototype.getSorted = function(type, sortBy, ascending) {
  var annotationsArray = [];
  var annotationsArraySorted = [];

  var types = type.split('-');

  for (var sectionId in this.sections) {
    if (!this.sections.hasOwnProperty(sectionId)) {
      continue;
    }
    /* jshint ignore:start */
    // Ignore JSHint error: Functions declared within loops referencing an outer scoped variable may lead to confusing semantics.
    this.sections[sectionId].annotations.forEach(function(annotation) {
      annotation.sectionId = sectionId;
      if (_.contains(types, annotation.type)) {
        annotationsArray.push(annotation);
      }
    });
    /* jshint ignore:end */
  }

  annotationsArraySorted = sortBy(annotationsArray, ascending);

  return annotationsArraySorted;
};

AnnotationsUsage.prototype.update = function(annotationsUsage) {
  if (annotationsUsage.isbn == this.isbn && annotationsUsage.uuid == this.uuid) {
    if (!(annotationsUsage instanceof AnnotationsUsage)) {
      annotationsUsage = $.extend(
        true,
        new AnnotationsUsage(annotationsUsage.isbn, annotationsUsage.uuid, eventEmitter),
        annotationsUsage,
      );
    }
    this._eventEmitter.emit('new:updated', annotationsUsage);
  } else {
    throw new Errors.BadData("The ISBN and/or UUID values don't match.");
  }
  return annotationsUsage;
};

export default AnnotationsUsage;
