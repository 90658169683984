import moduleConfig from './ModuleConfig';
import $ from 'jquery';
import _ from 'underscore';
import URI from 'urijs';
import utils from '@axisnow/util/utils';
import Settings from './storage/Settings';
import Strings from './i18n/Strings';
import PopupFactory from './popup/PopupFactory';
import splashMessage from './SplashMessage';
import SettingsDialog from './ReaderSettingsDialog';
import HelpPage from './HelpPage';
import ReaderNavbar from './templates/reader-navbar.hbs';
import ReaderBody from './templates/reader-body.hbs';
import ReaderBodyPageButtons from './templates/reader-body-page-btns.hbs';
import ReaderBookTitleHeader from './templates/reader-book-title-header.hbs';
import DisplayOptionsMenu from './templates/display-options-menu.hbs';
import Analytics from './analytics/Analytics';
import Keyboard from './Keyboard';
import EpubReaderMediaOverlays from './EpubReaderMediaOverlays';
import tts from './tts/tts'
import ttsDialog from './TTSSettingsDialog';
import EpubReaderBackgroundAudioTrack from './EpubReaderBackgroundAudioTrack';
import GesturesHandler from './gestures';
import { Readium } from '@axisnow/readium/Readium2';
import { Globals } from '@axisnow/readium';
import UsageManager from './usage/UsageManager';
import Managers from '@axisnow/data/Managers';
import Helpers from './EpubReaderHelpers';
import Errors from '@axisnow/util/Errors';
import errorMonitor from './ErrorMonitor';
import HighlightsNotesMenu from './menus/navbar/HighlightsNotesMenu';
import BookmarksMenu from './menus/navbar/BookmarksMenu';
import HelpMenu from './menus/navbar/HelpMenu';
import InlineWordReferenceMenu from './menus/inline/InlineWordReferenceMenu';
import SearchMenu from './menus/navbar/SearchMenu';
import TocUtils from './menus/TocUtils';
import MainMenu from './menus/navbar/MainMenu';
import BrowserDetect from 'browser-detect';
import FocusTrap from 'focus-trap';
import Feature from '@axisnow/data/app-permission/Feature';
import moment from 'moment-timezone';
import ActivateCST from './CacheStatusTool';
import processFont from './FontProcessing';
import ResourceCache from '../packages/fetch/resource_cache';
import FormatViewOptions from './templates/formatview-options.hbs';
import AXISAnalyticshelper from './AXISAnalyticshelper';
import EngageManager from './EngageManager';
import './readalong/LayoutViewExtension'; // For page load performance 
import './readalong/ReaderViewAdapterExtension'; //Readalong for Epub
import ComicManager from './ComicManager';


var isInReadingArea;

// initialised in initReadium()
var readium;

var resourceCache = new ResourceCache(window)
// initialised in loadReaderUI(), with passed data.embedded
var embedded;

// initialised in loadReaderUI(), with passed data.epub
var ebookURL;
var ebookURL_filepath;
var isbn;

// initialised in loadEbook() >> readium.openPackageDocument()
var currentPackageDocument;

// initialised in initReadium()
// (variable not actually used anywhere here, but top-level to indicate that its lifespan is that of the reader object (not to be garbage-collected))
var gesturesHandler;

var usageManager;
var _selectionContextMenuHandler;
var currentlyHighlightedText;

var $appContainer; // TODO: is this variable actually used anywhere here??
// (bad naming convention, hard to find usages of "el")
var el = document.documentElement;

//All Popup declarations, Confirmation moda and navbar and inline menu declarations
var popupModal = PopupFactory.create(PopupFactory.TYPE.MODAL);
var popupInfo = PopupFactory.create(PopupFactory.TYPE.MODAL);
var highlightsNotesMenu; //Highlights and Notes
var bookmarksMenu; //Bookmarks
var inlineWordReferenceMenu; //Inline
var helpMenu; //Help
var searchMenu; //Search
var mainMenu; //MainMenu
var contentLoadedOnce = false;

var tocUtils; //Toc Data Manager

var previewModeMaxReadingPercentage;
var previewModeWordCountInBook;
var previewModeLastAccessiblePage;
var wordsRead = 0;
var wordsReadOnCurrentPage = 0;
var directionOfLastNavigation;
var totalPageCount;
var lastLabel;

var canGoLeft = false;
var canGoRight = false;

var isMobileBrowser = false;

var initDisplayOptionsUISettingsChange = false; // Check if UI settings is changed.
var resizeIsTriggered = 0;
var navbarOverflowMenuFocusTrap;
var navbarOverflowMenuFocusTrapActive = false;

var savedLocation;
var paginated;
var eventPayload;
var formatResponseLength;
var engageManager;
var totalPagesLength = 0;
var eBookType;

//display options
var displayOptionsPopup = PopupFactory.create(PopupFactory.TYPE.NAVBAR_MENU, 'Display Options', {
  id: 'displayOptions',
  cssClass: 'size height unit-auto app-display-options-menu ',
});

//Format View options
var formatviewOptionsPopup = PopupFactory.create(PopupFactory.TYPE.NAVBAR_MENU, 'Format', {
  id: 'formatviewOptions',
  cssClass: 'size height unit-auto app-formatbookview-options ',
});

var _annotationsCSSUrl = new URL(moduleConfig.annotationCSSUrl, document.location).toString();
var _themesCSSUrl = new URL(moduleConfig.themesCSSUrl, document.location).toString();


var _fonts = new URL(moduleConfig.fonts, document.location).toString();

// Setup Fonts array
var openDyslexicFont = {
  displayName: "Open Dyslexic",
  fontFamily: "OpenDyslexic",
  url: _fonts
};
var _annotationDeferred;
// Future fonts need to be added to this array
var fontsArray = [openDyslexicFont];

// Adding attributes for WA
displayOptionsPopup.$el.attr('role', 'dialog').attr('aria-label', Strings.display_options);

function setBookTitle(title) {
  //set title of tab in browser
  //document.title = document.title + ' - ' + title; // we removed brand name  ticket #205732
    document.title = title;

  var $titleEl = $('.book-title-header');
  if ($titleEl.length) {
    $titleEl.text(title);
  } else {
    var html = ReaderBookTitleHeader({ title: title });
    $(html).insertBefore('#app-container');
  }
}

$(document).bind('fullscreenchange webkitfullscreenchange mozfullscreenchange msfullscreenchange', function (e) {
	var fullscreenElement = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullscreenElement || document.msFullscreenElement;
	
	if (!fullscreenElement) {
		$("#btnFullScreenView").removeClass("hidden").addClass("active");
    $("#btnExitFullScreenView").addClass("hidden");
    // $('#btnFullScreenView span:first').attr('class', 'icon-full-screen');
	} else {
		$("#btnFullScreenView").addClass("hidden");
    $("#btnExitFullScreenView").removeClass("hidden").addClass("active");
    $('#btnFullScreenView span:first').attr('class', 'icon icon-active-full-screen');
	}
});

/* // BH-325 (Removing code related to old TOC)
        var tocShowHideToggle = function () {

            unhideUI();

            var $appContainer = $('#app-container'),
                hide = $appContainer.hasClass('toc-visible');
            var bookmark;
            if (readium.reader.handleViewportResize && !embedded) {
                bookmark = JSON.parse(readium.reader.bookmarkCurrentPage());
            }

            if (hide) {
                $appContainer.removeClass('toc-visible');

                setTimeout(function () {
                    $('#tocButt')[0].focus();
                }, 100);
            }
            else {
                $appContainer.addClass('toc-visible');

                setTimeout(function () {
                    $('#readium-toc-body button.close')[0].focus();
                }, 100);
            }

            if (embedded) {
                hideLoop(null, true);
            } else if (readium.reader.handleViewportResize) {

                readium.reader.handleViewportResize(bookmark);

                // setTimeout(function()
                // {
                //     readium.reader.openSpineItemElementCfi(bookmark.idref, bookmark.contentCFI, readium.reader);
                // }, 90);
            }
        };
        */

var showScaleDisplay = function() {
  $('.zoom-wrapper').show();
  if (!isMobileBrowser) {
    $('.zoom-options').show();
  }
};
var setScaleDisplay = function() {
  var scale = readium.reader.getViewScale();
  $('.zoom-wrapper input').val(Math.round(scale) + '%');
};

var hideScaleDisplay = function() {
  $('.zoom-wrapper').hide();
  $('.zoom-options').hide();
};

var hideFontOptions = function() {
  $('.font-options').hide();
};

var hideDyslexicFontOption = function () {
  $('.dyslexic-font-option').hide();
};


/* // BH-325 (Removing code related to old TOC)
        var _tocLinkActivated = false;

        var loadToc = function (dom) {

            if (dom) {
                $('script', dom).remove();

                var tocNav;

                var $navs = $('nav', dom);
                Array.prototype.every.call($navs, function (nav) {
                    if (nav.getAttributeNS('http://www.idpf.org/2007/ops', 'type') == 'toc') {
                        tocNav = nav;
                        return false;
                    }
                    return true;
                });

                var isRTL = false;
                var pparent = tocNav;

                while (pparent && pparent.getAttributeNS) {
                    var dir = pparent.getAttributeNS("http://www.w3.org/1999/xhtml", "dir") || pparent.getAttribute("dir");

                    if (dir && dir === "rtl") {
                        isRTL = true;
                        break;
                    }
                    pparent = pparent.parentNode;
                }

                var toc = (tocNav && $(tocNav).html()) || $('body', dom).html() || $(dom).html();
                var tocUrl = currentPackageDocument.getToc();

                if (toc && toc.length) {
                    var $toc = $(toc);

                    // "iframe" elements need to be stripped out, because of potential vulnerability when loading malicious EPUBs
                    // e.g. src="javascript:doHorribleThings(window.top)"
                    // Note that "embed" and "object" elements with AllowScriptAccess="always" do not need to be removed,
                    // because unlike "iframe" the @src URI does not trigger the execution of the "javascript:" statement,
                    // and because the "data:" base64 encoding of an image/svg that contains ecmascript
                    // automatically results in origin/domain restrictions (thereby preventing access to window.top / window.parent).
                    // Also note that "script" elements are discarded automatically by jQuery.
                    $('iframe', $toc).remove();

                    $('#readium-toc-body').append($toc);

                    if (isRTL) {
                        $toc[0].setAttributeNS("http://www.w3.org/1999/xhtml", "dir", "rtl");
                        $toc[0].style.direction = "rtl"; // The CSS stylesheet property does not trigger :(
                    }
                }

            } else {
                var tocUrl = currentPackageDocument.getToc();

                $('#readium-toc-body').append("?? " + tocUrl);
            }

            $('#readium-toc-body').on('click', 'a', function (e) {
                try {
                    $spinner.spinner("start");

                    var href = $(this).attr('href');
                    //href = tocUrl ? new URI(href).absoluteTo(tocUrl).toString() : href;

                    _tocLinkActivated = true;

                    readium.reader.openContentUrl(href, tocUrl, undefined);

                    if (embedded) {
                        $('.toc-visible').removeClass('toc-visible');
                        unhideUI();
                    }
                } catch (err) {

                    console.error(err);

                } finally {
                    //e.preventDefault();
                    return false;
                }
            });
            $('#readium-toc-body').prepend('<button tabindex="50" type="button" class="close" data-dismiss="modal" aria-label="' + Strings.i18n_close + ' ' + Strings.toc + '" title="' + Strings.i18n_close + ' ' + Strings.toc + '"><span aria-hidden="true">&times;</span></button>');

        };
        */

var loadlibrary = function() {
  $('html').attr('data-theme', 'library');

  var urlParams = Helpers.getURLQueryParams();
  var ebookURL = urlParams.epub;
  var libraryURL;
  if (ebookURL) {
    libraryURL = new URI('/epub_library.json', ebookURL);
    libraryURL.protocol('offline:');
  } else {
    libraryURL = urlParams.epubs;
  }

  $(window).triggerHandler('loadlibrary', libraryURL.toString());
  //$(window).trigger('loadlibrary');
};

var downloadEpub = function() {
  //$("#reading-area").hide();
  $('.popup').hide();
  $('#reading-area').empty();
  //$("#app-container").empty();

  var ms = (Math.random() * 3 + 1) * 1000;
  //console.log("timeout:" + ms + " ms");
  //setTimeout(function(){
  console.log('ebookURL:' + ebookURL);

  var label = 'Return to your book';

  if (!!currentPackageDocument) {
    label += ': ' + currentPackageDocument.getMetadata().title;
  } else {
    label += '.';
  }

  var progressBar = function() {

    _spinner.setValue.bind(_spinner);

    this.progress = function(val) {
      _spinner.setValue(val);
    };

    this.reset = function(msg) {
      $(_spinner.$el)
        .find('svg .bar')
        .css({ stroke: '#004976' });
      _spinner.reset();
      $(_spinner.$el)
        .find('.spin-prgs-msg.line2')
        .text(msg);
    };
  };

  readium
    .cacheEpub(ebookURL, progressBar)
    .then(function() {
      $(window).one('loadlibrary.done', function(e, eventPayload) {
        splashMessage.init({
          closeBtn: false,
          cssClass: 'splash-busy app-splash-book-downloaded',
        });
        splashMessage.message({
          Title: 'Success!',
          Messages: [
            'Your book is saved for offline access.',
            'For easy access later, go to the Offline Content Library and create a bookmark in your browser.',
          ],
          Buttons: [
            {
              label: label,
              cssClass: 'readPrevBook btn-primary',
            },
          ],
          Icon: splashMessage.icons.CHECK,
        });
        splashMessage.$el.find('.readPrevBook').data({ book: ebookURL });

        splashMessage.$el.find('.readPrevBook').one('click', function(e) {
          splashMessage.$el.hide();
          $(window).triggerHandler('readepub', {
            epub: $(e.currentTarget).data('book'),
          });
        });
        splashMessage.$el.show();
      });
      loadlibrary();

      //$('#offlineLibraryModal').modal();
    })
    .catch(function(err) {
      console.error(err);
    });
};

var throttledDownload = _.throttle(downloadEpub, 6000);
var clearSelection = function() {
  var iframes = $('#epub-reader-container').find('iframe');
  _.each(iframes, function(iframe) {
    if (iframe.contentDocument) {
      iframe.contentDocument.getSelection().removeAllRanges();
    }
  });
  if (readium && readium.reader.plugins && readium.reader.plugins.selection) {
    readium.reader.plugins.selection.clearSelection();
  }
};

var onEbookLoaded = function() {
  var that = this;
  var lastIframe;

  var paginationSpinner;
  var spinnerStartTimer = null;
  var spinnerDeadmanTimer = null;

  var startPaginationSpinner = function() {
    AXISAnalyticshelper.startTiming('Paging', 'Content Load');
  }

  var getPagingTimingMetaData = function (pageChangeData) {
    return Managers.library.getBookInfo(isbn) // should be relatively fast since bookinfo is in browser storage, and the timing logging is asynchronous anyway
    .catch(function(err) {
      return {};  // ignore error just so we can still send timings
    }).then(function(book) {
      return {
        isbn: isbn,
        isAudiobook: false,
        isReflowable: !!(pageChangeData && pageChangeData.spineItem && pageChangeData.spineItem.isReflowable()),
        isFixedLayout: !!(pageChangeData && pageChangeData.spineItem && pageChangeData.spineItem.isFixedLayout()),
        isDownloaded: !!(book && book.downloaded),
        type: 'Epub'
      }
    });
  }

  var stopPaginationSpinner = function(pageChangeData) {
    spinnerStartTimer && clearTimeout(spinnerStartTimer);
    spinnerStartTimer = null;
    spinnerDeadmanTimer && clearTimeout(spinnerDeadmanTimer);
    spinnerDeadmanTimer = null;

    // log the page turn timing, note that on the first time book open, this will end up logging the full open-to-render time
    getPagingTimingMetaData(pageChangeData)
    .then(function(eventMetaData) {
      if (window['photonTimer_Load']) {
        // This fires only once, on first pagination after book load (this timer was started in index.html)
        AXISAnalyticshelper.endTiming('Load', eventMetaData);
      } else {
        // this fires on every pagination event
        AXISAnalyticshelper.endTiming('Paging', eventMetaData);
      }
    });
  }

  readium.reader.on(Globals.Events.CONTENT_DOCUMENT_LOAD_START, startPaginationSpinner); // spin when caching pages
  readium.reader.on(Globals.Events.PAGINATION_CHANGED, stopPaginationSpinner); // stop spinning when rendered

  function getSelectionText(iframe) {
    console.log('getSelectionText start');
    var win = iframe.contentWindow;
    var doc = iframe.contentDocument || win.document;

    if (win.getSelection) {
      return win.getSelection().toString();
    } else if (doc.selection && doc.selection.createRange) {
      return doc.selection.createRange().text;
    }
    console.log('getSelectionText end');
  }

  /*var loadPopover = function () {
             /!*
             var html = "" +
             "<div id='menuitems' class='dropdown-toggle' data-toggle='dropdown'></div>" +
             "<ul class='dropdown-menu' id='xxx' role='menu' aria-labelledby='menuitems'>" +
             "<li id='annotations' class='icon-annotations' role='presentation'>" +
             "<a href='javascript:void(0)'>Add Highlight</a></li>" +
             "<li role='presentation' class='add-note'>" +
             "   <a href='javascript:void(0)'> Add Note</a>" +
             "</li> " +
             // "<li role='separator' class='divider'></li>"+
             "<li role='presentation' class='define'>" +
             "   <a href='javascript:void(0)'> Define</a>" +
             "</li> " +
             //  "<li role='separator' class='divider'></li>"+
             "<li role='presentation'  class='search-google'>" +
             "   <a href='javascript:void(0)'> Search Google</a>" +
             "</li> " +
             "<li role='presentation'  class='search-wiki'>" +
             "   <a href='javascript:void(0)'> Search Wikipedia</a>" +
             "</li> " +
             "</ul>";
             *!/
             var html = InlineWordReferenceMenu({});
             $('#app-container').append(html);
             }
             loadPopover();*/

  function hideAllPopupMenus() {
    let $inplacePopupModal = $('.popup-inplace[style*="display: block"]')[0];
    let inplacePopupModalId = $inplacePopupModal? $inplacePopupModal.id : undefined;
    if (resizeIsTriggered <= 0 && initDisplayOptionsUISettingsChange === false) {
      popupModal.hideAll();
      utils.updateHighligtedIcon(''); //update all highlighted icon status
      $('.navbar-btn').removeClass('active');
      resizeIsTriggered = 0;
    } else {
      initDisplayOptionsUISettingsChange = false;
      resizeIsTriggered -= 1;
      if (inplacePopupModalId && inplacePopupModalId.includes('Highlight')) {
        inlineWordReferenceMenu.updateHighlightMenu();
      }
      if (inplacePopupModalId && inplacePopupModalId.includes('Note')) {
        inlineWordReferenceMenu.updateNoteMenu();
      }
    }
    if (navbarOverflowMenuFocusTrap && navbarOverflowMenuFocusTrapActive) {
      navbarOverflowMenuFocusTrap.deactivate();
    }
  }

  $('body').on('click', function(e) {
    if ($(e.target).parents('.popup, #app-navbar').length == 0) {
      hideAllPopupMenus();
      clearSelection();
    }
  });

  $('.comicAutoHide').css('opacity', '');
  $('.comicAutoHide').css('display', 'block');
  $('.navbar-default').removeClass('comicAutoHide');
  hidePagination(); // The use of setTimeout and debounce is important here
  readium.reader.addIFrameEventListener(
    'mouseup touchend click',
    _.debounce(
      function(e) {
        isInReadingArea = null;
        $('.container-fluid').on('mouseover', function() {
          isInReadingArea = false;
        });
        $('#epubContentIframe').on('mouseover', function() {
          isInReadingArea = true;
        });
        window.setTimeout(function() {
          // the setTimeout is used to ensure we check for the existance of a selection at the right time
          // because the order of events appears to be 'touchend' then 'selectionchanged' in Safari

          if (isInReadingArea === null) {
            isInReadingArea = true;
          }
          if (!(readium.reader.plugins && readium.reader.plugins.selection)) {
            return;
          }
          if (
            !readium.reader.plugins.selection.hasSelection() || 
            (tts.isPlayingSelection() && window.speechSynthesis.speaking)) {
            hideAllPopupMenus();

            if (typeof window.swiped === 'undefined' || !window.swiped) {
              unhideUI();
            }
          }
          window.swiped = false;
        }, 5);
      },
      10,
      true,
    ),
    {},
    { 'useCapture':true },
  );
  // 350ms is the time it takes for Safari to trigger certain click and mouse events,
  // We need to account for that inside the debounce timing (extra +10ms as a buffer)
  if (readium.reader.plugins && readium.reader.plugins.selection) {
    readium.reader.plugins.selection.on('cleared', function() {
      _.defer(function() {
        if (inlineWordReferenceMenu && inlineWordReferenceMenu.isVisible()) {
          inlineWordReferenceMenu.hide();
        }
      });
    });
  }

  readium.reader.on(Globals.Events.FXL_VIEW_RESIZED, function() {
    Globals.logEvent('FXL_VIEW_RESIZED', 'ON', 'EpubReader.js');
    setScaleDisplay();
  });

  hidePageLayout(function(){updateSyntheticSpreadSettings('single')});

  // reapplying theme settings after iframe has initialized
  Settings.get('reader', function(readerSettings) {
    if (!embedded) {
      readerSettings = readerSettings || SettingsDialog.defaultSettings;
      if (!readerSettings) {
        readerSettings = {};
      }
      SettingsDialog.updateReader(readium.reader, readerSettings);
    } else {
      readium.reader.updateSettings({
        syntheticSpread: 'auto',
        scroll: 'auto',
      });
    }
  });

  readium.reader.on(Globals.Events.CONTENT_DOCUMENT_LOADED, function($iframe, spineItem) {
    Globals.logEvent('CONTENT_DOCUMENT_LOADED', 'ON', 'EpubReader.js [ ' + spineItem.href + ' ]');

    var isFixed = readium.reader.isCurrentViewFixedLayout();

    // TODO: fix the pan-zoom feature!
    if (isFixed) {
      showScaleDisplay();
      hideFontOptions();
      hideDyslexicFontOption();
    } else {
      hideScaleDisplay();
    }

    //TODO not picked-up by all screen readers, so for now this short description will suffice
    //$iframe.attr('title', Strings.i18n_reading_area_content);
    $iframe.attr('aria-label', Strings.i18n_reading_area_content);
    // $iframe.attr('aria-role', 'document');

    // Hides controls until book is loaded - S-46143
    $('.hide-until-loaded').removeClass('hide-until-loaded');

    lastIframe = $iframe[0];
    $('#epubContentIframe')
      .contents()
      .find('body')
      .on('mousedown', function() {
        $(document.body).removeClass('keyboard');
        $('#epubContentIframe')
          .contents()
          .find('body')
          .removeClass('keyboard');
      });

    $('#epubContentIframe')
      .contents()
      .find('body')
      .on('keyup', function() {
        $('#epubContentIframe')
          .contents()
          .find('body')
          .addClass('keyboard');
      });
  });

  readium.reader.on(ReadiumSDK.Events.CONTENT_DOCUMENT_LOAD_FAILED, function() {
    console.warn('ReadiumSDK.Events.CONTENT_DOCUMENT_LOAD_FAILED');
    splashMessage.init({
      closeBtn: false,
      cssClass: 'splash-system',
    });
    var error = new Errors.UnableToOpenBook(
      'Unable to load content',
      'Unexpected Error! Please report error.',
      'Error',
    );
    $.extend(error, { urlParams: ReadiumHelpers.getURLQueryParams() });
    splashMessage.message(error);
    splashMessage.$el.show();
  });

  var savePlaceThrottled = _.throttle(savePlace, 2000); // rate limit this to at most every 2 seconds
  // Page flip event listener

  var currentSpineItem;
  readium.reader.on(Globals.Events.PAGINATION_CHANGED, function(pageChangeData) {
    paginated = true;
    console.log('ReadiumSDK.Events.PAGINATION_CHANGED');
    $('.book-title-header').focus();
    Globals.logEvent('PAGINATION_CHANGED', 'ON', 'EpubReader.js');

    document.getElementById("statusDiv").innerText="";
    setTimeout(() => {document.getElementById("statusDiv").innerText="Page Changed"},1000)

    updateWordsRead();
    savePlaceThrottled();
    let theme = $('html').attr('data-theme');
    if(theme !== undefined && theme !== null){
      Settings.get('reader', function (json) {
        if (!json) {
          json = {};
        }
        Settings.put('reader', json);
        if(json.colorMode == 'dark' && json.theme == 'night-theme' && checkIfFixedOrComicBook()){
          SettingsDialog.updateStylesForTheme('author-theme');
          let $themeOptions = displayOptionsPopup.$el.find('.theme-options');
          let $themeOptionsSelectors = $themeOptions.find('.option-selector');
          $themeOptionsSelectors.find('input[data-theme="author-theme"]')[0].checked = true;
          setRadioButtonsAriaChecked(
              $themeOptionsSelectors,
              $themeOptionsSelectors.find('input:checked'),
          );
        }else{
          SettingsDialog.updateStylesForTheme(json.theme);
        }
        if(checkIfFixedOrComicBook()){
          let $colormodeOption = displayOptionsPopup.$el.find('.colormode-options');
          $colormodeOption[0].style.display = 'none';
        }
      });
    }else{
      if(checkIfFixedOrComicBook()){
        let $colormodeOption = displayOptionsPopup.$el.find('.colormode-options');
        $colormodeOption[0].style.display = 'none';
      }
    }
    updateUI(pageChangeData);
    hideAllPopupMenus();
    if(!checkIfFixedOrComicBook()){
      Settings.get('reader', function(readerSettings) {
        let $colormodeOption = displayOptionsPopup.$el.find('.colormode-options');
        if(readerSettings.colorMode){
          if(readerSettings.colorMode === 'dark'){
            SettingsDialog.updateStylesForTheme('night-theme');
            $(document.body).addClass('darkModeClass');
            $colormodeOption.find('#chkSwitchMode')[0].checked = true;
          }else if(readerSettings.colorMode === 'light'){
            SettingsDialog.updateStylesForTheme('default-theme');
            $(document.body).removeClass('darkModeClass');
            $colormodeOption.find('#chkSwitchMode')[0].checked = false;
          }
        }
        else {
          $colormodeOption.find('#chkSwitchMode')[0].checked = false; //this is implemented for dark mode disable when choose any other theme change
        }
      });
    }
    // Chapter change event listener
    var loadedIframes = readium.reader.getLoadedContentFrames();
    if (loadedIframes) {
      for (var iframe of loadedIframes) {
        var spine = iframe.spineItem;
        if ( !currentSpineItem || spine.index !== currentSpineItem.index) {
          currentSpineItem = spine;
          renderAnnotation();
          break;
        }
      }
    }

    readium.reader.plugins.highlights.redrawAnnotations();

    if (requestFocus.idref === '') {
      bookmarksMenu.clearHighlight();
    }

    if (loadedIframes.length > 0 && requestFocus.idref === currentSpineItem.idref) {
      var $highlighted = readium.reader.plugins.highlights.focusHighlight(
        requestFocus.idref,
        requestFocus.cfi,
      );
      if ($highlighted) {
        if (requestFocus.type === 'bookmark') {
          $highlighted.on('focusout', function() {
            bookmarksMenu.clearHighlight();
          });
        }
        requestFocus.idref = '';
        requestFocus.cfi = '';
        updateFocusRequest(requestFocus);
      }
    }
  });

  var renderAnnotation = function(useFetchedAnnotation) {
    console.log('**************** START Rendering Annotations ************');
    usageManager.getAnnotationsUsage(isbn).then(function(annotationsUsage) {
      var loadedIframes = readium.reader.getLoadedContentFrames();
      loadedIframes.forEach(function(element) {
        if (element.$iframe.attr("highlightsProcessed")) {
          return;
        }
        if (useFetchedAnnotation) element.$iframe.attr("highlightsProcessed", "true");
        utils.isOnline().catch(function() {
          setTimeout(() => {
            var $cssLink = element.$iframe.contents().find('#annotationCss');
            var xhr = new XMLHttpRequest();
            xhr.timeout = $.ajaxSettings.timeout;
            xhr.open('GET', $cssLink.attr('href'), true);
            xhr.responseType = 'arraybuffer';

            xhr.onload = function(loadEvent) {
              var blob = new Blob([xhr.response], {
                type: 'text/css',
              });

              $cssLink.attr('href', URL.createObjectURL(blob));
            };

            xhr.send();
          }, 0);          
        });
        var idref = element.spineItem.idref;
        if (
          typeof annotationsUsage.spines[idref] != 'undefined' &&
          annotationsUsage.spines[idref].annotations instanceof Array
        ) {
          annotationsUsage.spines[idref].annotations.forEach(function(annotation) {
            var type = annotation.color;
            console.log('type : ' + type);

            var annotationId = annotation.location;
            console.log('annotation.location : ' + annotation.location);

            try {
              if (
                readium.reader.getPaginationInfo().openPages.some(function(element) {
                  return element.idref === idref;
                })
              ) {
                var locObj = Helpers.locObjFromFullCFI(
                  readium.packageDocument,
                  annotation.location,
                  true,
                );
                if (annotation.type !== 'Bookmark' && !annotation.deleteAnnotation)
                  if (readium.reader.plugins && readium.reader.plugins.highlights) {
                    readium.reader.plugins.highlights.addHighlight(
                      idref,
                      locObj.contentCFI,
                      annotationId,
                      type,
                    );
                  }
              }
            } catch (err) {
              console.error('Error while rendering annotations :', err);
            }
          });
        }
      });
      console.log('**************** END Rendering Annotations ************');
    });
  };
  
  
  var requestFocus = { idref: '', cfi: '', type: 'search' };
  $(document).on('REQUEST_FOCUS', function(e, request) {
    updateFocusRequest(request);
  });

  var updateFocusRequest = function(request) {
    requestFocus = request;
    $(document).trigger('POPUP_VISIBILITY_CHANGED', [request.idref !== '']);
  };

  //************************ Main Menu ***************************/
  $('#btnToggleMainMenu').on('click', function() {
    //Remove all active classes
    $('.navbar-btn').removeClass('active');
    popupModal.hideAll();
    mainMenu.show();
  });

  Keyboard.on(Keyboard.FullScreen, 'reader', function() {
    $('#btnFullScreenView').trigger('click');
  });
  Keyboard.on(Keyboard.ExitFullScreen, 'reader', function() {
    $('#btnExitFullScreenView').trigger('click');
  });

  Keyboard.on(Keyboard.Menu, 'reader', function() {
    triggerMenuOpener('#btnToggleMainMenu');
  });
  //***************************************************/

  Keyboard.on(Keyboard.ShowInlineMenu, 'reader', function() {
    if (
      document.activeElement.id === 'epubContentIframe' &&
      readium.reader.plugins.selection.hasSelection()
    ) {
      inlineWordReferenceMenu.show();
    }
  });

  Keyboard.on(Keyboard.HidePopups, 'reader', function() {
    // closing overlay views
    HelpPage.close();
    // setTimeout(function() {
    //     if (popupId == "mainMenu2") {
    //         $('#btnToggleMainMenu').focus();
    //         // AccessibilityManager.setFocus('#btnToggleMainMenu')
    //     } else if (popupId == "displayOptions") {
    //         $('#btnToggleFontOptions').focus();
    //         // AccessibilityManager.setFocus('#btnToggleFontOptions')
    //     } else if (popupId == "annotationsListBookmarks") {
    //         $('#btnToggleBookmark').focus();
    //         // AccessibilityManager.setFocus('#btnToggleBookmark')
    //     } else if (popupId == "annotationsListNotes") {
    //         $('#btnToggleNotes').focus();
    //         // AccessibilityManager.setFocus('#btnToggleNotes')
    //     } else if (popupId == "searchPopup") {
    //         $('#btnToggleSearch').focus();
    //         // AccessibilityManager.setFocus('#btnToggleSearch')
    //     } else if (popupId == "helpList") {
    //         $('#btnToggleHelp').focus();
    //         // AccessibilityManager.setFocus('#btnToggleHelp')
    //     } else if (popupId == "audioSpeed") {
    //         $('#btnToggleAudioSpeed').focus();
    //         // AccessibilityManager.setFocus('#btnToggleAudioSpeed')
    //     }  else if (popupId == "sleepTimer") {
    //         $('#btnToggleSleepTimer').focus();
    //         // AccessibilityManager.setFocus('#btnToggleSleepTimer')
    //     } else if(popupId == "appWordReference" || popupId == "appHighlightOptions" || popupId == "inlineNotesMenu") {
    //         setTimeout(function() {
    //             clearSelection()
    //             // AccessibilityManager.panelCloseHandler()
    //             // AccessibilityManager.dispatchAction("focusContentElement")
    //         }, 100)
    //     } else if(popupId == "googleSearchPopup" || popupId == "defineSearchPopup" || popupId == "goToPagePopup") {
    //         console.log("else if working")
    //         // AccessibilityManager.panelCloseHandler(function() {
    //         //     AccessibilityManager.dispatchAction("focusContentElement")
    //         // })
    //     }

    //     if(document.activeElement.id == "epubContentIframe") {
    //         console.log('document.activeElement.id')
    //         $('#btnToggleMainMenu').focus();
    //         // AccessibilityManager.setFocus('#btnToggleMainMenu')
    //         setTimeout(function() {
    //             hideLoop()
    //         }, 200)
    //     }
    // }, 100)
  });
};

function selectedOption(optionId){
  if (optionId == 'cbz') {
    displaySwitchToComicViewPopup();      
  } 
  if (optionId == 'epub') {
    //$(window).triggerHandler('readepub', eventPayload);
  }
}

/* Format Drop down */
var initFormatViewUI = function() {
  $(".default_option").click(function(){
    $('.navbar-btn').removeClass('active');
    popupModal.hideAll();
    $(this).parent().toggleClass("active");    
    var expandStatus = $('.default_option').attr('aria-expanded');
    if((expandStatus == undefined) || (expandStatus == "false")) {
      $('.default_option').attr('aria-expanded', 'true');   
    }
    else{
      $('.default_option').attr('aria-expanded', 'false');
    }
  });
  
  /**** Only for Accessibility **
  * Purpose of SetTimeout is added to announce the combo box state(expand/collapse)
  * **/
  $(".default_option").keydown(function (e) {  
    if(e.key === "Enter"){
      $('.navbar-btn').removeClass('active');
      popupModal.hideAll();
      $(this).parent().toggleClass("active");
      if($('.select_wrap').hasClass('active')){
        $('.default_option').attr('aria-expanded', 'true'); 
      }
      var selFormat = $('#Format_selected').text();
      if(selFormat == "Comic Book"){
        $("#cbz").focus();
        $('.select_ul').attr('aria-expanded', 'true'); 
      }  
      else{
        $("#epub").focus();
        $('.select_ul').attr('aria-expanded', 'true');
      } 
    }
    if($(e.target).attr('id') == "formattextId"){
      var inputval, filter, ul, li, a, i, txtValue, span, div, p;
      //var inputval = $(e.target.value);
      filter = e.target.value.toUpperCase();
      ul = document.getElementById("formatepubswitcher");
      li = ul.getElementsByTagName("li");
      for (i = 0; i < li.length; i++) {
          a = li[i].getElementsByTagName("p")[0];
          txtValue = a.textContent || a.innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
              li[i].style.display = "";           
          } else {
              li[i].style.display = "none";
          }
          $('.select_wrap').addClass('active');
          $(".default_option").attr('aria-expanded', 'true');
      }
    }
    $('#formattextId').keyup(function(e) {
      if(e.which == 40){
        if($("#formatepubswitcher li.focusedSel").length!=0) {
          var storeTarget	= $('#selFormatList').find("li.focusedSel").next();
          $("#formatepubswitcher li.focusedSel").removeClass("focusedSel");
          storeTarget.focus().addClass("focusedSel");
        }
        else {
          $('#formatepubswitcher').find("li:first").focus().addClass("focusedSel");
        }
        return ;
      }
      if(e.which == 38){
        if($("#formatepubswitcher li.focusedSel").length!=0) {
          var storeTarget	= $('#formatepubswitcher').find("li.focusedSel").prev();
          $("#formatepubswitcher li.focusedSel").removeClass("focusedSel");
          storeTarget.focus().addClass("focusedSel");
        }
        else {
          $('#formatepubswitcher').find("li:first").focus().addClass("focusedSel");
        }
        return ;
      }      
    });
  });

  $(".select_ul li").keydown(function(e){
    if(e.keyCode === 38){ //uparrow
      var dataprevious = $(this).attr('data-previous');
      $('#'+dataprevious).focus();              
    }
    if(e.keyCode === 40){  //Downarrow
      var datanext = $(this).attr('data-next');
      $('#'+datanext).focus();
    }
    if(e.key === "Escape") {
      $('.select_wrap').removeClass('active');
      $(".default_option").attr('aria-expanded', 'false');
      $('.default_option').focus();
    }
    if(e.key === "Enter"){
      var activeElementId = document.activeElement.id;
      selectedOption(activeElementId);
      $('.select_wrap').removeClass('active');
      $(".default_option").attr('aria-expanded', 'false');
      $(".default_option").focus();
    }
    if(e.key == "Tab") {
      $('.select_wrap').removeClass('active');
    }    
  });

  $(".select_ul li").click(function(){
    var currentele = $(this).html();
    //$(".default_option li").html(currentele);
    var selectedformattext = stripHtml(currentele).trim();
    $("#formattextId").val(selectedformattext).change();
    $(this).parents(".select_wrap").removeClass("active");
    $('.default_option').attr('aria-expanded', 'false');
    $(".default_option").focus();
    selectedOption(this.id);
  })
}

/****/

// Book View popup show/hide
var initFormatViewOptionsUI = function() {
  $('#btnToggleFormatView').on('click', function() {
    //Remove all active classes
    $('.navbar-btn').removeClass('active');

    if (formatviewOptionsPopup.$el.is(':visible')) {
      formatviewOptionsPopup.hide();
    } else {
      popupModal.hideAll();
      formatviewOptionsPopup.show();
      $('#ebook').prop('checked', true);
      $(this).addClass('active');
    }
  });
  Keyboard.on('Format View', 'reader', function() {
    triggerMenuOpener('#btnToggleFormatView');
  });
}

var $formatviewOptionsContainerLayoutContent = $(FormatViewOptions({ strings: Strings }));
var $formatviewOptionsContainerLayout = formatviewOptionsPopup.addContainerLayout(
  $formatviewOptionsContainerLayoutContent,
);

// triggers handler of the menu openers
var triggerMenuOpener = function(opener) {
  //unhiding application navbar
  unhideUI();
  $(opener).trigger('click');
};

var unhideUI = function() {
  hideLoop();
};

// Web Accessibility enhancement
var showUIWA = function(show) {
  if (show === false) {
    // hide UI
    // $("#app-navbar").attr("aria-hidden", true);
    //$(".navbar-btn").hide(); // prevents blank tabs
    $('#buttHideToolBar').addClass('hidden');
    $('#buttShowToolBar').removeClass('hidden');
  } else {
    // show UI
    // $("#app-navbar").attr("aria-hidden", false);
    //$(".navbar-btn").show();
    $('#buttShowToolBar').addClass('hidden');
    $('#buttHideToolBar').removeClass('hidden');
  }
};

var hidePagination = function() {
  $(document.body).addClass('hide-pagination');
  updateEpubFooter();
};

var updateEpubFooter = function() {
  var $docBody = $(document.body);

  if ($docBody.hasClass('hide-footer-epub')) {
    $docBody.removeClass('hide-footer-epub');
  }

  if ($docBody.hasClass('hide-ui') && $docBody.hasClass('hide-pagination')) {
    $docBody.addClass('hide-footer-epub');
  }

  if (!$docBody.hasClass('hide-ui')) {
    $docBody.addClass('hide-btn-show-ui');
  } else {
    $docBody.removeClass('hide-btn-show-ui');
  }
};

var hideUI = function() {
  hideTimeoutId = null;
  /* // BH-325 (Removing code related to old TOC)
            // don't hide it toolbar while toc open in non-embedded mode
            if (!embedded && $('#app-container').hasClass('toc-visible')) {
                hideLoop()
                return;
            }
            */

  var navBar = document.getElementById('app-navbar');
  if (document.activeElement) {
    var within = jQuery.contains(navBar, document.activeElement);
    if (within) {
      hideLoop();
      return;
    }
  }

  var $navBar = $(navBar);
  // BROEKN! $navBar.is(':hover')
  var isMouseOver = $navBar.find(':hover').length > 0;
  if (isMouseOver) {
    hideLoop();
    return;
  }

  var isFocus = $navBar.find('button:focus').length > 0;
  if (isFocus) {
    hideLoop();
    return;
  }

  if ($('#audioplayer').hasClass('expanded-audio')) {
    hideLoop();
    return;
  }

  // Loop to prevent hiding with any menu is opened
  if ($('.popup-sticky').is(':visible')) {
    hideLoop();
    return;
  }

  var isProgressSliderHasFocus = $('#progress-slider-input:focus').length;
  if (isProgressSliderHasFocus) {
    hideLoop();
    return;
  }

  $(document.body).addClass('hide-ui');
  updateEpubFooter();
  showUIWA(false);
};

var hideTimeoutId;

var hideLoop = function(e, immediate) {
  // if (!embedded){
  //     return;
  // }

  if (hideTimeoutId) {
    window.clearTimeout(hideTimeoutId);
    hideTimeoutId = null;
  }

  /**
   * The class toc-visible and found it to be no longer used as originally designed and the
   * !$('#app-container').hasClass('toc-visible') && code could be removed from the following conditional.
   *
   * However, this could cause a possible conflict with the open source Readium code.  Since it's always going to
   * return true for the first part so it should be fine if we leave it like this.
   */
  //$(document.body).removeClass('clicked');
  /*            $(document.body).removeClass('over');
             $('.container-fluid').on('mouseover',function(e) {
             $(document.body).addClass('over');
             return false;
             });*/

  if ($('#app-container').hasClass('toc-visible') || $('.popup').is(':visible')) {
    $(document.body).removeClass('hide-ui');
    updateEpubFooter();
    showUIWA(true);
  } else {
    if (
      $('#app-navbar').find('button:focus').length > 0 ||
      (document.activeElement &&
        (document.activeElement.id == 'epubContentIframe' ||
          document.activeElement.tagName.toLowerCase() == 'body'))
    ) {
      $(document.body).removeClass('hide-ui');
      updateEpubFooter();
      showUIWA(true);
    } else if ($('#left-page-btn:focus').length || $('#right-page-btn:focus').length) {
      $(document.body).removeClass('hide-ui');
      updateEpubFooter();
      showUIWA(true);
    } else if ($('#btnSkipToContent:focus').length) {
      $(document.body).removeClass('hide-ui');
      updateEpubFooter();
      showUIWA(true);
    } else if ($('#progress-slider-input:focus').length) {
      $(document.body).removeClass('hide-ui');
      updateEpubFooter();
      showUIWA(true);
    } else {
      // $(document.body).toggleClass('hide-ui');
      // updateEpubFooter();
      // showUIWA($(document.body).hasClass('hide-ui'));
      // FSS - revert to pre RC16 code - new toggle code above causes navbar to flash when tabbing between paragraphs
      $(document.body).addClass('hide-ui');
      updateEpubFooter();
      showUIWA(false);
    }
  }
  if (!$('#app-container').hasClass('toc-visible') && $('.container-fluid:hover').length > 0) {
    if ($('body').hasClass('hide-ui')) {
      $(document.body).removeClass('hide-ui');
      updateEpubFooter();
      showUIWA();
    }
  }
  $('.footer-epub').hover(function() {
    if (!$('#app-container').hasClass('toc-visible')) {
      if ($('body').hasClass('hide-ui')) {
        $(document.body).removeClass('hide-ui');
        updateEpubFooter();
        showUIWA();
      }
    }
  });
  if (immediate) {
    hideUI();
  } else {
    hideTimeoutId = window.setTimeout(hideUI, 4000);
  }
};

var updateUI = function(pageChangeData) {
  clearSelection();
  if (pageChangeData.paginationInfo.canGoLeft()) {
    canGoLeft = true;
    $('#left-page-btn').show();
  } else {
    canGoLeft = false;
    $('#left-page-btn').hide();
  }

  if (pageChangeData.paginationInfo.canGoRight()) {
    canGoRight = true;
    $('#right-page-btn').show();
  } else {
    canGoRight = false;
    $('#right-page-btn').hide();
  }
  if (readium.reader.plugins && readium.reader.plugins.pagination) {
    var $progressSliderInput = $('#progress-slider-input');
    $(document.body).removeClass('hide-pagination');
    $(document.body).removeClass('hide-footer-epub');

    var mappedPagelist = readium.reader.plugins.pagination.getMappedPageList();

    if (mappedPagelist.length > 0) {
      var min = mappedPagelist[0].index;
      var max = mappedPagelist[mappedPagelist.length - 1].index;
      totalPagesLength = mappedPagelist.length;

      var pageIndex = 0;
      var pages = readium.reader.plugins.pagination.getVisibleLabelItems();

      // if only one item and not in prod mode
      if(mappedPagelist.length === 1 && !utils.isProductionBuild() ){
        var messageInfo = {
          errorName: 'Only one item in the page list',
          errorIsbn: isbn,
          errorPageList: mappedPagelist,
        };
        utils.sendInfo(messageInfo);
      }
      
      var hasVisiblePages = pages.length > 0;
      if (hasVisiblePages) {
        var lastPageEntry = pages[pages.length - 1];
        pageIndex = lastPageEntry.label ? lastPageEntry.label.index : lastPageEntry.index;
      }

      getMaxReadingPercentage().then(function(maxReadingPercentage) {
        var lastAccessiblePage = max;
        if (maxReadingPercentage != 100 && maxReadingPercentage != 0) {
          lastAccessiblePage = mappedPagelist[previewModeLastAccessiblePage].index;
          if (pageIndex >= lastAccessiblePage) {
            canGoRight = false;
          }
        }
        if (hasVisiblePages){
          $progressSliderInput.prop('value', pageIndex);
          $progressSliderInput.attr('aria-valuenow', pageIndex);
          $progressSliderInput.attr('aria-valuetext', 'Page ' + pageIndex);
        } else if (mappedPagelist.length > 1 && !utils.isProductionBuild()){
            var messageInfo = {
              errorName: 'This chapter lacks label in the page list',
              errorIsbn: isbn,
              errorSpineItemIndex: pageChangeData.spineItem.index,
              errorCfi: pageChangeData.spineItem.cfi,
              errorHref: pageChangeData.spineItem.href,
              errorIdref: pageChangeData.spineItem.idref,
            };
            utils.sendInfo(messageInfo);
        }
          $progressSliderInput.prop('min', min);
          $progressSliderInput.prop('max', lastAccessiblePage);
          $progressSliderInput.attr('aria-valuemin', min);
          $progressSliderInput.attr('aria-valuemax', lastAccessiblePage);
        //----END------
        totalPageCount = lastAccessiblePage;
        lastLabel = mappedPagelist[mappedPagelist.length - 1].label;
        if (hasVisiblePages) {
          updateProgressSlider(pages, pages[pages.length - 1].cfi.idref);
        } else {
          console.warn('no visible pages: do not update page progress slider');
        }
      });
    } else {
      hidePagination();
    }
  } else {
    hidePagination();
  }
};

var updateProgressSlider = function(pages, idref) {
  var lastPageNum = totalPageCount; // readium.reader.plugins.pagination.getLastPageNumber();

  var pageStr = 'Page ' + pages[0].label.label;
  if($.isArray(pages)){
  if (pages.length > 1) {
    var endPageLabel = pages[pages.length - 1].label.label;
    if (endPageLabel !== pages[0].label.label) {
      pageStr = pageStr + ' - ' + endPageLabel;
    }
  }
  }
  pageStr = pageStr + ' of ' + lastLabel;
  $('.page-current').text(pageStr);

  if (!readium.reader.isCurrentViewFixedLayout()) {
    var remainingChapPages =
      readium.reader.plugins.pagination.getLastPageForIdref(idref).index -
      pages[pages.length - 1].label.index;
    var inChapStr =
      remainingChapPages === 1
        ? remainingChapPages + ' page remain in chapter.'
        : remainingChapPages + ' pages remain in chapter. ';
    $('.page-remaining').text(inChapStr);
  }
};

var updateActiveProgressSlider = function(label) {
  if (label === undefined) {
    $('.page-current').text('');
  } else {
    var lastPageNum = totalPageCount; // readium.reader.plugins.pagination.getLastPageNumber();
    var pageStr = 'Page ' + label + ' of ' + lastLabel;
    $('.page-current').text(pageStr);
  }

  $('.page-remaining').text('');
};

var countWords = function(s) {
  s = s.replace(/(^\s*)|(\s*$)/gi, ''); //exclude  start and end white-space
  s = s.replace(/[ ]{2,}/gi, ' '); //2 or more space to 1
  s = s.replace(/\n /, '\n'); // exclude newline with a start spacing
  if (s === '') {
    return 0;
  } else {
    return s.split(' ').length;
  }
};

var getWordCountInBook = function() {
  return new Promise(function(resolve, reject) {
    if (previewModeWordCountInBook !== undefined) {
      resolve(previewModeWordCountInBook);
      return;
    }
    readium
      .getCurrentPublicationFetcher()
      .getWordCount()
      .then(function(wordCount) {
        previewModeWordCountInBook = wordCount;
        resolve(wordCount);
      });
  });
};

// Maximum allowed preview reading percentage. 0 is a special value that means no restrictions.
var getMaxReadingPercentage = function() {
  return new Promise(function(resolve, reject) {
    if (previewModeMaxReadingPercentage !== undefined) {
      resolve(previewModeMaxReadingPercentage);
      return;
    }
    return Promise.join(Managers.license.fetchLicense(isbn), getWordCountInBook()).spread(function(
      licenseContainer,
      maxWordCount,
    ) {
      var license = licenseContainer.license;
      if (license) {
        var drmrights = license.drmrights;
        if (drmrights) {
          var preview = drmrights.preview;
          if (preview) {
            var quantity = preview.quantity;
            if (quantity !== undefined) {
              if (maxWordCount === undefined || maxWordCount === 0) {
                if (quantity == 0) {
                  // If quantity is 0, preview percentage is of course 0 too.
                  // We don't need to know the word count.
                  previewModeMaxReadingPercentage = 0;
                  previewModeLastAccessiblePage = 0;
                  resolve(0);
                  return;
                } else {
                  // Not enough information, so just read the entire book
                  previewModeMaxReadingPercentage = 100;
                  previewModeLastAccessiblePage = readium.reader.plugins.pagination.getLastPageNumber();
                  resolve(100);
                  return;
                }
              } else {
                var measurement = preview.measurement;
                if (measurement == 'percentage') {
                  previewModeMaxReadingPercentage = quantity;
                  previewModeLastAccessiblePage = Math.round(
                    (readium.reader.plugins.pagination.getLastPageNumber() * quantity) / 100,
                  );
                  resolve(previewModeMaxReadingPercentage);
                  return;
                } else if (measurement == 'words') {
                  previewModeMaxReadingPercentage = (100 * quantity) / maxWordCount;
                  previewModeLastAccessiblePage = Math.round(words / 250);
                  resolve(previewModeMaxReadingPercentage);
                  return;
                }
              }
            }
          }
        }
      }
      // Not enough information to determine if in preview mode
      previewModeMaxReadingPercentage = 100;
      previewModeLastAccessiblePage =
        readium.reader.plugins.pagination.getMappedPageList().length > 0
          ? readium.reader.plugins.pagination.getLastPageNumber()
          : undefined;
      resolve(100);
      return;
    });
  });
};

var updateWordsRead = function() {
  // Must be called once per page being navigated to, or else the results will be off

  var wordsReadOnLastPage = wordsReadOnCurrentPage;

  var firstVisibleCfi = readium.reader.getFirstVisibleCfi();
  var lastVisibleCfi = readium.reader.getLastVisibleCfi();
  var visibileText;
  if (firstVisibleCfi.contentCFI && lastVisibleCfi.contentCFI) {
    //Fixed #BH-570
    //visibileText = readium.reader.getDomRangeFromRangeCfi(readium.reader.getFirstVisibleCfi(), lastVisibleCfi).toString();
      let visTextCFI = readium.reader.getDomRangeFromRangeCfi(
        firstVisibleCfi,
        readium.reader.getLastVisibleCfi(firstVisibleCfi.idref),
      );
      visibileText = visTextCFI ? visTextCFI.toString() : '';
      wordsReadOnCurrentPage = countWords(visibileText);
  } else {
    wordsReadOnCurrentPage = 0;
  }

  if (directionOfLastNavigation == 'left') {
    wordsRead -= wordsReadOnLastPage;
  } else {
    wordsRead += wordsReadOnCurrentPage;
  }
};

var savePlace = function() {
  Settings.put(ebookURL_filepath, readium.reader.bookmarkCurrentPage(), $.noop);

  console.debug('************ BEGIN Saving - Post POSITION to Usage Service ************');

  Promise.join(usageManager.getPageUsage(isbn), getMaxReadingPercentage())
    .spread(function(pgUsage, maxPercentage) { 
      var pageUsage = pgUsage;
      var location = readium.reader.bookmarkCurrentPage();
      pageUsage.setCurrentPositionFromReadiumCfi(readium.packageDocument, location);

      if (wordsReadOnCurrentPage > 0) {
        pageUsage.wordsonpage = wordsReadOnCurrentPage;
      } else {
        pageUsage.wordsonpage = -1;
      }
      pageUsage.usercreatedate = new Date();
            // update local
      return usageManager.savePageUsage(pageUsage).then(function () {
          return utils
            .isOnline()
            .then(function() {
              return usageManager.saveCurrentPositionToRemote(isbn);
            })
            .catch(function(err) {
             console.error(err);
            });
      })
    
    })
    .catch(function(err) {
      console.error(err);
    });

  console.debug('************ END Saving - Post POSITION to Usage Service ************');
};

var nextPage = function () {
  AXISAnalyticshelper.startTiming('Paging', 'Next Page');
  
  $('#mainMenu2').hide(function(){
    $('.navbar-btn').removeClass('active');
    if (canGoRight) {
      directionOfLastNavigation = 'right';
      canGoRight = false; // these values will be updated at next updateUI()
      canGoLeft = false; // and this prevents us from skipping pages
      // readium.reader.openPageRight();
      usageManager.getPageUsage(isbn).then(function(pageUsage) {
        if (!readium.reader.isMediaOverlayAvailable()) {
          trackPageActivity(pageUsage);
        }
        readium.reader.openPageRight();
      })
    }
  });
  return false;
};

var prevPage = function () {
  AXISAnalyticshelper.startTiming('Ebook Paging', 'Prev page');
  if (canGoLeft) {
    directionOfLastNavigation = 'left';
    canGoRight = false; // these values will be updated at next updateUI()
    canGoLeft = false; // and this prevents us from skipping pages
    usageManager.getPageUsage(isbn).then(function(pageUsage) {
      if (!readium.reader.isMediaOverlayAvailable()) {
         trackPageActivity(pageUsage);
        }
      readium.reader.openPageLeft();      
    })
  }
  return false;
};

var $displayOptionsContainerLayoutContent = $(DisplayOptionsMenu({ strings: Strings }));
var $displayOptionsContainerLayout = displayOptionsPopup.addContainerLayout(
  $displayOptionsContainerLayoutContent,
);

var updateSyntheticSpreadSettings = function (spreadValue) {
  initDisplayOptionsUISettingsChange = true;
  readium.reader.plugins.highlights.removeAllHighlights();
  readium.reader.updateSettings({ syntheticSpread: spreadValue });
  Settings.get('reader', function(json) {
    if (!json) {
      json = {};
    }
    json.syntheticSpread = spreadValue;

    Settings.put('reader', json);
  });
};

/********Accessibility*****/
document.onkeyup = function(e) {
  if (e.altKey && (e.keyCode == 70))
  {
    e.preventDefault();
    $('#btnFullScreenView').trigger('click');
  }
  if (e.altKey && (e.keyCode == 69))
  {
    e.preventDefault();
    $('#btnExitFullScreenView').trigger('click');
  }

  if(e.key === "Escape") {
   $('.select_wrap').removeClass('active');
  }

  if(e.key == "Tab" && e.target.id == "btnExitFullScreenView" )
  {
    $('.select_wrap').removeClass('active');
  }
  
  var targetid = $(e.target).attr('id');
  if( targetid == "btnToggleFormatDropdown") {
    if(e.key === "Enter" || e.keyCode == 13){
      $('.select_wrap').addClass('active');
      $('#cbz').focus();
    }    
  }
}

var initDisplayOptionsUI = function() {
  /*********** Display Options ********************/
  let $colormodeOption = displayOptionsPopup.$el.find('.colormode-options');
  let $colormodeOptionBtn = $colormodeOption.find('#chkSwitchMode');
  $('#btnFullScreenView').on('click', function() {
    $("#app-fullscreen").attr("style", "background-color:#FFFFFF");
    $('.navbar-btn').removeClass('active');
    popupModal.hideAll();
    $("#btnFullScreenView").addClass("hidden");
    $("#btnExitFullScreenView").removeClass("hidden").addClass("active");
    setToFullScreen(true);   
  });
  
  $('#btnExitFullScreenView, #helpList a').on('click', function() {
    $("#app-fullscreen").attr("style", "");
    $('.navbar-btn').removeClass('active');
    popupModal.hideAll();
    $("#btnExitFullScreenView").addClass("hidden");
    $("#btnFullScreenView").removeClass("hidden").addClass("active");   
    setToFullScreen(false);
  });
  
  $('#btnToggleFontOptions').on('click', function() {
    addAriaFontValues();
    //Remove all active classes
    $('.navbar-btn').removeClass('active');
    $('.select_wrap').removeClass('active');
    utils.updateHighligtedIcon('btnToggleFontOptions'); //update other highlighted
    if (displayOptionsPopup.$el.is(':visible')) {
      $('#btnToggleFontOptions span:first').attr('class', 'icon icon-display');
      displayOptionsPopup.hide();
    } else {
      savedLocation = readium.reader.getFirstVisibleCfi();
      popupModal.hideAll();
      $('.navbar-overflow-menu').css('display', 'none');
      $('#btnToggleFontOptions span:first').attr('class', 'icon icon-active-display');
      displayOptionsPopup.show();
      $(this).addClass('active');
      // displayOptionsPopup.$el.find('.js-close-popup').focus();
    }
  });

  Keyboard.on(Keyboard.DisplayOptionsMenu, 'reader', function() {
    triggerMenuOpener('#btnToggleFontOptions');
  });

  Keyboard.on(Keyboard.MediaOverlaysAdvancedPanelShowHide, 'reader', function() {
    $('#btn-expand-audio').trigger('click');    
  });
 
  // zoom buttons
  var $zoomOptions = displayOptionsPopup.$el.find('.zoom-options');
  var $zoomOptionsRadioGroup = $zoomOptions.find('.option-selector');
  $zoomOptionsRadioGroup.on('click ', function(e) {
    if (e.target.value && e.target.value === 'height') {
      setFitScreen();
    }
    if (e.target.value && e.target.value === 'width') {
      setFitWidth();
    }
  });

  //page layout buttons
  var $pageOptions = displayOptionsPopup.$el.find('.layout-options');
  var $pageOptionsRadioGroup = $pageOptions.find('.option-selector');

  $pageOptionsRadioGroup.on('click', function(e) {
    if (
      (e.target.value && e.target.value === 'single') ||
      e.target.value === 'double' ||
      e.target.value === 'auto'
    ) {
      // if layout change happen store in readium reader and use it in readalong
      readium.reader.layoutChange = true;
      updateSyntheticSpreadSettings(e.target.value);
    }
    setRadioButtonsAriaChecked($pageOptionsRadioGroup, e.target);
  });

  readium.reader.on(Globals.Events.SETTINGS_APPLIED, function() {
    var viewerSettings = readium.reader.viewerSettings();
    switch (viewerSettings.syntheticSpread) {
      case 'auto':
        $pageOptionsRadioGroup.find('input[value="auto"]')[0].checked = true;
        break;
      case 'single':
        $pageOptionsRadioGroup.find('input[value="single"]')[0].checked = true;
        break;
      case 'double':
        $pageOptionsRadioGroup.find('input[value="double"]')[0].checked = true;
        break;
    }
    setRadioButtonsAriaChecked(
      $pageOptionsRadioGroup,
      $pageOptionsRadioGroup.find('input:checked'),
    );
  });

  // Initialize dyslexic font user selection
  let dyslexicFontOption = displayOptionsPopup.$el.find('.dyslexic-font-option');

  setDyslexicFont(dyslexicFontOption, false, false);

  readium.reader.on(Globals.Events.SETTINGS_APPLIED, function () {
    var viewerSettings = readium.reader.viewerSettings();

    switch (viewerSettings.fontSelection) {
      case '1':
        dyslexicFontOption.find('#btnDyslexicFontOption')[0].checked  = true;
        break;
      case '0':
        dyslexicFontOption.find('#btnDyslexicFontOption')[0].checked  = false;
        break;
    }
  });

  function setDyslexicFont(accessibilityOptions, offline, clicked, e) {
    Settings.get('reader', function (json) {
      if (!json) {
        json = {};
      }
      if (json.fontSelection) {
        if (offline) {
          readium.reader.updateSettings({fontSelection: json.fontSelection});
        }
        dyslexicFontOption.find('#btnDyslexicFontOption')[0].checked = true;
      } else {
        dyslexicFontOption.find('#btnDyslexicFontOption')[0].checked = false;
      }
    });
  }

  $('#btnDyslexicFontOption').on('click', function(e){
    initDisplayOptionsUISettingsChange = true;
    readium.reader.plugins.highlights.removeAllHighlights();
    readium.reader.off(Globals.Events.FONT_APPLIED);
    let fontSelectionVal = e.target.checked == true ? 1: 0;
    readium.reader.once(Globals.Events.FONT_APPLIED, function() {
      if(e.target.checked == true){
        initDisplayOptionsUISettingsChange = true;
        readium.reader.openSpineItemElementCfi(savedLocation.idref, savedLocation.contentCFI);
      }
    });
    readium.reader.updateSettings({ fontSelection: fontSelectionVal }, savedLocation);
    Settings.get('reader', function(json) {
      if (!json) {
        json = {};
      }
      json.fontSelection = fontSelectionVal;

      Settings.put('reader', json);
    });
  });


  //font slider
  var $pageFontOptions = displayOptionsPopup.$el.find('.font-size-options');
  var initialFontSize = readium.reader.viewerSettings().fontSize;
  var scalar = (initialFontSize * 5.7) / 100;
  var offset = 0.8 * initialFontSize;

  loadFontSizeOptions(initialFontSize, offset, scalar);

  $('#btnTextDecrease').on('click', function(e) {
    var ctrlRange = $pageFontOptions.find("input[type='range']");
    if (ctrlRange.length > 0) {
      var changedVal = Number(ctrlRange.prop('value')) - 1;
      var minVal = Number(ctrlRange.prop('min')) || 0;
      if (changedVal >= minVal) {
        ctrlRange.prop('value', changedVal);
        ctrlRange.trigger('mouseup');
        addAriaFontValues();
      }
    }
  });

  $('#btnTextIncrease').on('click', function(e) {
    var ctrlRange = $pageFontOptions.find("input[type='range']");
    if (ctrlRange.length > 0) {
      var changedVal = Number(ctrlRange.prop('value')) + 1;
      var maxVal = Number(ctrlRange.prop('max')) || 100;
      if (changedVal <= maxVal) {
        ctrlRange.prop('value', changedVal);
        ctrlRange.trigger('mouseup');
        addAriaFontValues();
      }
    }
  });

  function addAriaFontValues() {
    $('#rngFontSize').attr('aria-valuenow', $('#rngFontSize').val() + '%');
    $('#rngFontSize').attr('aria-valuetext', $('#rngFontSize').val() + '%');
  }

  function loadFontSizeOptions(initialFontSize, offset, scalar){

    $('#rngFontSize').attr('aria-valuemin', 0 + '%');
    $('#rngFontSize').attr('aria-valuemax', 100 + '%');

    $('#rngFontSize').on('input change', function() {
      addAriaFontValues();
    });

    $pageFontOptions
        .find('input[type="range"]')
        .prop('value', (initialFontSize - offset) / scalar)
        .on('keyup', function(e) {
          if (e.key === 'Escape') {
            $('#displayOptions .close').trigger('click');
          }
        });

    $pageFontOptions.on('mouseup touchend keyup', function(e) {
      if (e.keyCode && !(e.keyCode == 37 || e.keyCode == 38 || e.keyCode == 39 || e.keyCode == 40)) {
        return;
      }

      let fontSize = scalar * e.target.value + offset;
      initDisplayOptionsUISettingsChange = true;
      Settings.get('reader', function(json) {
        if (!json) {
          json = {};
        }
        json.fontSize = fontSize;

        Settings.put('reader', json);

        readium.reader.plugins.highlights.removeAllHighlights();
        SettingsDialog.updateReader(readium.reader, json, savedLocation);
      });
    });
  }

  readium.reader.on(Globals.Events.SETTINGS_APPLIED, function() {
    var currentFontSize = readium.reader.viewerSettings().fontSize;
    var sliderVal = (currentFontSize - offset) / scalar;
    $pageFontOptions.find('input[type="range"]')[0].value = sliderVal;
    Settings.get('reader', function(json) {
      if (!json) {
        json = {};
      }
      if (json.fontSize) {
        $pageFontOptions.find('input[type="range"]')[0].value = (json.fontSize - offset) / scalar;
      }
    });
  });

  //Theme buttons
  var $themeOptions = displayOptionsPopup.$el.find('.theme-options');
  var $themeOptionsSelectors = $themeOptions.find('.option-selector');

  Settings.get('reader', function(json) {
    if (!json) {
      json = {};
    }
    if (json.theme) {
      $themeOptionsSelectors.find('input[data-theme="' + json.theme + '"]')[0].checked = true;
    } else {
      $themeOptionsSelectors.find('input[data-theme="author-theme"]')[0].checked = true;
    }
    setRadioButtonsAriaChecked(
      $themeOptionsSelectors,
      $themeOptionsSelectors.find('input:checked'),
    );
  });

  $themeOptionsSelectors.on('click', function(e) {
    var theme = $(e.target).data('theme');
    if (!theme) return;
    //Theme selection should override Darkmode/Lightmode
    SettingsDialog.updateStylesForTheme('default-theme');
    $(document.body).removeClass('darkModeClass');
    initDisplayOptionsUISettingsChange = true;
    Settings.get('reader', function(json) {
      if (!json) {
        json = {};
      }
      json.theme = theme;
      //On themeselection, the colorMode set to empty
      json.colorMode = "";
      Settings.put('reader', json);

      readium.reader.plugins.highlights.removeAllHighlights();
      SettingsDialog.updateReader(readium.reader, json);
      $themeOptionsSelectors.find('label').each(function(index, element) {
        // AccessibilityManager.toggleState(element, 0)
      });
    });
    setRadioButtonsAriaChecked($themeOptionsSelectors, e.target);
  });

  $themeOptionsSelectors.keypress(function(e) {
    var key = e.which;
    if (key == 13) {
      var theme = $(e.target).data('value');
      if (!theme) return;

      initDisplayOptionsUISettingsChange = true;
      Settings.get('reader', function(json) {
        if (!json) {
          json = {};
        }
        json.theme = theme;

        Settings.put('reader', json);

        readium.reader.plugins.highlights.removeAllHighlights();
        SettingsDialog.updateReader(readium.reader, json);
        $themeOptionsSelectors.find('label').each(function(index, element) {
          // AccessibilityManager.toggleState(element, 0)
        });
      });
    }
  });

  $colormodeOptionBtn.on('click', function(e) {
    console.log('switch mode active');
    initDisplayOptionsUISettingsChange = true;
    if(e.target.checked == true){
      SettingsDialog.readerModeSettings(readium.reader, 'dark');
      SettingsDialog.updateStylesForTheme('night-theme');
      $(document.body).addClass('darkModeClass');
      /*
      The following code resets the theme selection to default once the dark mode selection done.
       */
      $themeOptionsSelectors.find('input[data-theme="author-theme"]')[0].checked = true;
      setRadioButtonsAriaChecked(
          $themeOptionsSelectors,
          $themeOptionsSelectors.find('input:checked'),
      );
    }else{
      SettingsDialog.readerModeSettings(readium.reader, 'light');
      SettingsDialog.updateStylesForTheme('default-theme');
      $(document.body).removeClass('darkModeClass');
    }
  });
};

var setRadioButtonsAriaChecked = function(radioGroup, checkedRadioButton) {
  $("#btnFullScreenView").removeClass("active");
  radioGroup.find('input').each(function() {
    $(this).attr('aria-checked', 'false');
  });
  if (checkedRadioButton) {
    $(checkedRadioButton).attr('aria-checked', 'true');
  }
};

var installReaderEventHandlers = function() {
  // Set handlers for click events
  /*$(".icon-annotations").on("click", saveHighlights);
             $(".icon-annotations").on("click", saveBookmarks);*/
  var permissionManager = Managers.appPermission;
  var cstPermission = permissionManager.permission('CacheStatusTool');
  if (!cstPermission.disableByDefault) {
    $('.app-version').click(ActivateCST);
  }

  var isWithinForbiddenNavKeysArea = function() {
    return (
      document.activeElement &&
      (document.activeElement === document.getElementById('volume-range-slider') ||
        document.activeElement === document.getElementById('time-range-slider') ||
        document.activeElement === document.getElementById('rate-range-slider') ||
        $(document.activeElement).parents('.popup-body,.popup').length > 0)
    );
  };

  var hideTB = function() {
    /* // BH-325 (Removing code related to old TOC)
                if (!embedded && $('#app-container').hasClass('toc-visible')) {
                    return;
                }
                */
    //if (document.activeElement) document.activeElement.blur();
   // AccessibilityManager.dispatchAction("focusContentElement");
    setTimeout(function() {
      hideUI();
    }, 160);
  };
  $('#buttHideToolBar').on('click', hideTB);
  Keyboard.on(Keyboard.ToolbarHide, 'reader', hideTB);

  var showTB = function() {
    $('#btnToggleMainMenu')[0].focus();
    unhideUI();
    setTimeout(function() {
      $('#btnToggleMainMenu')[0].focus();
    }, 50);
  };
  $('#buttShowToolBar').on('click', showTB);
  Keyboard.on(Keyboard.ToolbarShow, 'reader', showTB);

  Keyboard.on(Keyboard.PagePrevious, 'reader', function() {
    if (!isWithinForbiddenNavKeysArea()) prevPage();
  });

  Keyboard.on(Keyboard.PagePreviousAlt, 'reader', prevPage);

  Keyboard.on(Keyboard.PageNextAlt, 'reader', nextPage);

  Keyboard.on(Keyboard.PageNext, 'reader', function() {
    if (!isWithinForbiddenNavKeysArea()) nextPage();
  });

  Keyboard.on(Keyboard.PageNextSpace, 'reader', function() {
    if (!isWithinForbiddenNavKeysArea()) nextPage();
  });

  Keyboard.on(Keyboard.SwitchToLibrary, 'reader', function() {
    popupModal.hideAll();
    loadlibrary();
  });

  $('.offline-lib-btn').on('click', function() {
    $('.popup').hide();
    loadlibrary();
    return false;
  });

  Keyboard.on(
    Keyboard.DownloadCurrentBook,
    'reader',
    throttledDownload /* function(){setTimeout(, 30);} */,
  );

  var searchForKeyword = function() {
    var searchVaule = $('input').val();
  };

  $('.btn-info').on('click', function() {
    searchForKeyword();
    //return false;
  });

  //***************************** HIGHLIGHTS/NOTES**************************//
  $('#btnToggleNotes, .btnToggleNotesRA, .btnToggleNotes').on('click', function() {
    console.log('123', highlightsNotesMenu);
    //Remove all active classes
    $('.navbar-btn').removeClass('active');
    $('.select_wrap').removeClass('active');
    utils.updateHighligtedIcon('btnToggleNotes') // update other higlighted icons
    if (highlightsNotesMenu.isVisible()) {
      highlightsNotesMenu.hide();
      // AccessibilityManager.panelCloseHandler(function() {
      //     AccessibilityManager.setFocus("#btnToggleBookmark")
      // })
      $('#btnToggleNotes span:first').attr('class', 'icon icon-notes');
      $('.btnToggleNotesRA span:first').attr('class', 'icon icon-notes');
      $('.btnToggleNotes span:first').attr('class', 'icon icon-notes');
    } else {
      popupModal.hideAll();
      $('#navbar-overflow-overlay').css('display', 'none');
      $('.navbar-overflow-menu').css('display', 'none');
      $('#btnToggleNotes').addClass('active');
      clearSelection();
      $('#btnToggleNotes span:first').attr('class', 'icon icon-active-notes');
      $('.btnToggleNotesRA span:first').attr('class', 'icon icon-active-notes');
      $('.btnToggleNotes span:first').attr('class', 'icon icon-active-notes'); 
      highlightsNotesMenu.show();
      // setTimeout(function(){
      //     $(highlightsNotesMenu._popup.$header).find('.js-close-popup')[0].focus();
      // },3000)
    }
  });

  Keyboard.on(Keyboard.NotesHighlightsMenu, 'reader', function() {
    triggerMenuOpener('#btnToggleNotes');
  });

  //***************************** BOOKMARKS ******************************//
  $('#btnToggleBookmark , .btnToggleBookmarkRA').on('click', function() {
    //Remove all active classes
    $('.navbar-btn').removeClass('active');
    $('.select_wrap').removeClass('active');
    $('.navbar-overflow-menu').css('display', 'none'); 
    $('#navbar-overflow-overlay').css('display', 'none');
    utils.updateHighligtedIcon('btnToggleBookmark') // update other higlighted icons
    if (bookmarksMenu.isVisible()) {
      bookmarksMenu.hide();
      // AccessibilityManager.panelCloseHandler(function() {
      //     AccessibilityManager.setFocus("#btnToggleBookmark")
      // })
      $('#btnToggleBookmark span:first').attr('class', 'icon icon-bookmark-outline');
      $('.btnToggleBookmarkRA span:first').attr('class', 'icon icon-bookmark-outline');
    } else {
      popupModal.hideAll();
      bookmarksMenu.show();
      $(this).addClass('active');
      $('#btnToggleBookmark span:first').attr('class', 'icon icon-active-bookmark-outline');
      $('.btnToggleBookmarkRA span:first').attr('class', 'icon icon-active-bookmark-outline');
    }
  });

  Keyboard.on(Keyboard.Bookmark, 'reader', function() {
    triggerMenuOpener('#btnToggleBookmark');
  });

  //***************************** HELP ******************************//
  $('#btnToggleHelp, .btnToggleHelpRA, .btnToggleHelp').on('click', function() {
    //Remove all active classes
    $('.navbar-btn').removeClass('active');
    $('.select_wrap').removeClass('active');
    utils.updateHighligtedIcon('btnToggleHelp') // update other higlighted icons
    if (helpMenu.isVisible()) {
      $('#btnToggleHelp span:first').attr('class', 'icon icon-help');
      $('.btnToggleHelpRA span:first').attr('class', 'icon icon-help');
      $('.btnToggleHelp span:first').attr('class', 'icon icon-help');
      helpMenu.hide();
    } else {
      popupModal.hideAll();
      $('#navbar-overflow-overlay').css('display', 'none');
      $('.navbar-overflow-menu').css('display', 'none');
      console.log('help>>>');
      $('#btnToggleHelp span:first').attr('class', 'icon icon-active-help');
      $('.btnToggleHelpRA span:first').attr('class', 'icon icon-active-help');
      $('.btnToggleHelp span:first').attr('class', 'icon icon-active-help');
      helpMenu.show();
      $(this).addClass('active');
    }
  });

  Keyboard.on(Keyboard.HelpMenu, 'reader', function() {
    triggerMenuOpener('#btnToggleHelp');
  });

  //***************************** SEARCH ******************************//
  $('#btnToggleSearch, .btnToggleSearchRA').on('touchmove mouseover', function() {
    utils
      .isOnline()
      .then(function() {
        // enable button
        $('#btnToggleSearch, .btnToggleSearchRA').prop('disabled', false);
      })
      .catch(function(error) {
        //disable button, browser is offline
        $('#btnToggleSearch, .btnToggleSearchRA').prop('disabled', true);
      });
  });

  $('#btnToggleSearch, .btnToggleSearchRA, .btnToggleSearch').on('click', function() {
    //Remove all active classes
    $('.navbar-btn').removeClass('active');
    $('.select_wrap').removeClass('active');
    utils.updateHighligtedIcon('btnToggleSearch') // update other higlighted icons
    if (searchMenu.isVisible()) {
      searchMenu.hide();
      // AccessibilityManager.panelCloseHandler(function() {
      //     AccessibilityManager.setFocus("#btnToggleFontOptions")
      // })
      $('#btnToggleSearch span:first').attr('class', 'icon icon-search');
      $('.btnToggleSearchRA span:first').attr('class', 'icon icon-search');
      $('.btnToggleSearch span:first').attr('class', 'icon icon-search');
    } else {
      popupModal.hideAll();
      $('#navbar-overflow-overlay').css('display', 'none');
      $('.navbar-overflow-menu').css('display', 'none');
      $('#btnToggleSearch span:first').attr('class', 'icon icon-active-search');
      $('.btnToggleSearchRA span:first').attr('class', 'icon icon-active-search');
      $('.btnToggleSearch span:first').attr('class', 'icon icon-active-search');
      searchMenu.show();
      $(this).addClass('active');
      searchMenu.isVisible();
      window.inlineSearch = false;
    }
  });

  Keyboard.on(Keyboard.Search, 'reader', function() {
    triggerMenuOpener('#btnToggleSearch');
  });

  //**************************** OVERFLOW ***************************//

  $('#btnToggleMore').on('click', function() {
    $('.navbar-btn').removeClass('active');
    $('.select_wrap').removeClass('active');
    if (navbarOverflowMenuFocusTrapActive) {
      return;
    }
    popupModal.hideAll();
    if (($('.navbar-overflow-menu').css('display') == 'block') && ($('#navbar-overflow-overlay').css('display') == 'block')) {
      $('#navbar-overflow-overlay').css('display', 'none');
      $('.navbar-overflow-menu').css('display', 'none');
    }
    else{
      $('#navbar-overflow-overlay').css('display', 'block');
      $('.navbar-overflow-menu').css('display', 'block');
    }
    if (!($('#btnTTSPause').hasClass('hidden'))) {
      $('#navbar-overflow-overlay').css('display', 'none');
    }
    navbarOverflowMenuFocusTrap = FocusTrap($('.navbar-overflow-menu')[0], {
      onDeactivate: function() {
        $('.navbar-overflow-menu').css('display', '');
        if ($('#btnToggleMore').is(':visible')) {
          $('#btnToggleMore').focus();
        } else {
          $('#btnToggleMainMenu').focus(); // Rightmost button on the toolbar
        }
        navbarOverflowMenuFocusTrapActive = false;
      },
      clickOutsideDeactivates: true,
    });
    navbarOverflowMenuFocusTrap.activate();
    navbarOverflowMenuFocusTrapActive = true;
  });

  $('#navbar-overflow-overlay').on('click', function() {
    $('#navbar-overflow-overlay').css('display', 'none');
  });

  //*****************************************************************//
  //Is dropdown expand mode
  // $(".navbar-btn").on('click', function (e){
  //   $('.select_ul').hide();
  // });
  

  // Is Mobile View 
  $(".formatviewSelect").on('click touchend', function (e){
    e.preventDefault();
    popupModal.hideAll();
    formatviewOptionsPopup.hide();
    if ($(this).val() == 'cbz') {
      displaySwitchToComicViewPopup();      
    }
    if ($(this).val() == 'epub') {
      $(window).triggerHandler('readepub', eventPayload);
    }
  });

   //Click to land Comic Page 
   $('#viewerSelect').on('click', function (e) {
    e.preventDefault();
    if ($(this).val() == 'cbz') {
      displaySwitchToComicViewPopup();
    }
    if ($(this).val() == 'epub') {
      //$(window).triggerHandler('readepub', eventPayload);
    }
  }); 

  $("select[name='viewerSelect']").on('click touchend', function (e){       
    e.preventDefault();
    if(this.value == "cbz"){
      displaySwitchToComicViewPopup();     
    }
    if ($(this).val() == 'epub') {
      $(window).triggerHandler('readepub', eventPayload);
    }
  });

//   $('.btnCloseBook').on('click touchend', function () {
//     //closeWindow();
//      window.close();
//  });

  $('.zoom-wrapper input').on('click', function() {
    if (!this.disabled) {
      this.focus();
      return false;
    }
  });

  $('.zoom-wrapper input').on('click', function() {
    if (!this.disabled) {
      this.focus();
      return false;
    }
  });

  //***************************** Progress Slider ******************************//
  var $appContainer = $('#app-container');
  var $progressSlider = $appContainer.find('#progress-slider-input');

  $progressSlider.on('change', function(event) {
    event.preventDefault();
    /**
     * Change in progress slider will trigger ReadiumSDK.Events.PAGINATION_CHANGED
     * which cause the trigger of POPUP_VISIBILITY_CHANGED from Popup.prototype.hide,
     * and the focus is shift to the iframe.
     * To stop focus shift we set initDisplayOptionsUISettingsChange = true.
     */
    initDisplayOptionsUISettingsChange = true;
    $(document).trigger('POPUP_VISIBILITY_CHANGED', [true]);
    var x = $progressSlider.prop('value');
    var mappedPagelist = readium.reader.plugins.pagination.getMappedPageList();
    if (mappedPagelist.length > 0) {
      AXISAnalyticshelper.startTiming('Paging', 'Scrubber');
      readium.reader.plugins.pagination.jumpToIndex(x)
        .then(function stopSpinner() {
        });
    }
  });

  $progressSlider.on('blur', function() {
    $(document).trigger('POPUP_VISIBILITY_CHANGED', [false]);
  });

  $progressSlider.on('input', function(event) {
    event.preventDefault();
    var x = $progressSlider.prop('value');
    var label = readium.reader.plugins.pagination.getLabelFromIndex(x);
    updateActiveProgressSlider(label);
  });

  $progressSlider.on('touchend', function (event) {
    // allow scrubber to be arbitrary width and arbitrary position (regardless of device orientation)
    var sliderWidth = $('#progress-slider-input').width();
    if (sliderWidth && event.target.max && event.originalEvent.pageX && window.innerWidth) {
      var ratio = event.target.max / sliderWidth;  // pages to slider pixels ratio
      var offsetX = $('#progress-slider-input').offset().left;
      var px = event.originalEvent.pageX - offsetX; // mouse pixel position in slider (0 based)
      px = Math.min(Math.max(px, 0), sliderWidth); // cap mouse to slider min/max
      var pageNum = Math.round(px * ratio);
      var mappedPagelist = readium.reader.plugins.pagination.getMappedPageList();
      if (mappedPagelist.length > 0) {
        $progressSlider.prop('value', pageNum);
        readium.reader.plugins.pagination.jumpToIndex(pageNum);
      }
    }
  });

  /* // BH-325 (Removing code related to old TOC)
            Keyboard.on(Keyboard.TocShowHideToggle, 'reader', function () {
                var visible = $('#app-container').hasClass('toc-visible');
                if (!visible) {
                    tocShowHideToggle();
                }
                else {
                    setTimeout(function () {
                        $('#readium-toc-body button.close')[0].focus();
                    }, 100);
                }
            });

            $('.icon-toc').on('click', tocShowHideToggle);

            var setTocSize = function () {
                var appHeight = $(document.body).height() - $('#app-container')[0].offsetTop;
                $('#app-container').height(appHeight);
                $('#readium-toc-body').height(appHeight);
            };*/

  /* // BH-325 (Removing code related to ReaderSettingsDialog.js / setting-*.html)
            Keyboard.on(Keyboard.ShowSettingsModal, 'reader', function () {
                $('#settings-dialog').modal("show")
            });
            */
  $('#app-navbar').on('mouseenter touchstart', hideLoop);

  $(window).on('resize', function() {
    readium.reader.plugins.highlights.removeAllHighlights();
    resizeIsTriggered = 1;
    hidePageLayout(function(){
      updateSyntheticSpreadSettings('single');
      resizeIsTriggered = 2;
    });
  });

  $(window).on('orientationchange', function() {
    $(':input:focus').blur();
  });

  /* // BH-325 (Removing code related to old TOC)
            $(window).on('resize', setTocSize);
            setTocSize();
            */
  hideLoop();

  // captures all clicks on the document on the capture phase. Not sure if it's possible with jquery
  // so I'm using DOM api directly
  //document.addEventListener('click', hideLoop, true);
};

var setFitScreen = function(e) {
  if (readium.reader.isCurrentViewFixedLayout()) {
    readium.reader.setZoom({ style: 'fit-screen' });
    $('.active-zoom').removeClass('active-zoom');
    $('#zoom-fit-screen').addClass('active-zoom');
    $('.zoom-wrapper input').prop('disabled', true);
    $('.zoom-wrapper>a').dropdown('toggle');
  } else {
    if (readium.reader.getCurrentViewType() == readium.reader.constructor.VIEW_TYPE_COLUMNIZED) {
      var view = readium.reader.getCurrentView();
      view.setNarrowMargins(false);
    }
  }
  return false;
};

var setFitWidth = function(e) {
  if (readium.reader.isCurrentViewFixedLayout()) {
    readium.reader.setZoom({ style: 'fit-width' });
    $('.active-zoom').removeClass('active-zoom');
    $('#zoom-fit-width').addClass('active-zoom');
    $('.zoom-wrapper input').prop('disabled', true);
    $('.zoom-wrapper>a').dropdown('toggle');
  } else {
    if (readium.reader.getCurrentViewType() == readium.reader.constructor.VIEW_TYPE_COLUMNIZED) {
      var view = readium.reader.getCurrentView();
      view.setNarrowMargins(true);
    }
  }
  return false;
};

var enableCustom = function(e) {
  $('.zoom-wrapper input')
    .prop('disabled', false)
    .focus();
  $('.active-zoom').removeClass('active-zoom');
  $('#zoom-custom').addClass('active-zoom');
  $('.zoom-wrapper>a').dropdown('toggle');
  return false;
};

var zoomRegex = /^\s*(\d+)%?\s*$/;
var setCustom = function(e) {
  var percent = $('.zoom-wrapper input').val();
  var matches = zoomRegex.exec(percent);
  if (matches) {
    var percentVal = Number(matches[1]) / 100;
    readium.reader.setZoom({ style: 'user', scale: percentVal });
  } else {
    setScaleDisplay();
  }
};

var loadReaderUIPrivate = function() {
  $('.modal-backdrop').remove();
  var $appContainer = $('#app-container');
  $appContainer.empty();
  $appContainer.append(
    ReaderBody({ strings: Strings, /* dialogs: Dialogs, BH-325 */ keyboard: Keyboard }),
  );
  $('header#app-navbar').empty();
  //$('nav').attr("aria-label", Strings.i18n_toolbar);
  $('header#app-navbar').append(
    ReaderNavbar({ strings: Strings, /* dialogs: Dialogs, BH-325 */ keyboard: Keyboard }),
  );
  
  // Check if formatReponse contains both cbz/epub, then show comic dropdown
  utils.isOnline().then( function() {
    var comicManager = new ComicManager();
    comicManager.checkFormat(isbn).then(function (format) {
      if(format.formats.length > 1) {
        $('#btnToggleFormatView').show();
        $('#btnToggleFormatDropdown').show();
        $('#btnToggleFormatView').addClass('visible-xs');
        eBookType ='cbz';
      } else {
        $('#btnToggleFormatView').hide();
        $('#btnToggleFormatDropdown').hide();
        $('#viewerSelect').hide();
        $('#btnToggleFormatView').removeClass('visible-xs').addClass('hidden-xs');
        eBookType ='epub';
      }
    })
   }).catch(err =>{
      console.log('browser is offline',err.message);
      $('#btnToggleFormatView').hide();
      $('#btnToggleFormatDropdown').hide();
      $('#viewerSelect').hide();
      $('#btnToggleFormatView').removeClass('visible-xs').addClass('hidden-xs');
   })

  //Moving show navbar button at the top
  $('#app-navbar').before($('#buttShowToolBar'));

  //installReaderEventHandlers(); // BH-393 - this method should be called after setupMenus
  $('#zoom-fit-width a').on('click', setFitWidth);
  $('#zoom-fit-screen a').on('click', setFitScreen);
  $('#zoom-custom a').on('click', enableCustom);
  $('.zoom-wrapper input').on('change', setCustom);

  if (document.fullscreenEnabled) {
    $("#app-fullscreen").attr("style", "background-color:#FFFFFF");          
    $('.book-title-header').removeClass('book-title-header-white');
  }
};

var setupMenus = function() {
  highlightsNotesMenu = new HighlightsNotesMenu(readium, usageManager, isbn);
  bookmarksMenu = new BookmarksMenu(readium, usageManager, isbn);
  helpMenu = new HelpMenu(readium, usageManager, isbn);
  searchMenu = new SearchMenu(readium, usageManager, isbn);

  inlineWordReferenceMenu = new InlineWordReferenceMenu(readium, usageManager, isbn);

  inlineWordReferenceMenu.on('booksearch', function(query) {
    searchMenu.show();
    searchMenu.searchBook(query);
  });

  getMaxReadingPercentage().then(function(maxReadingPercentage) {
    mainMenu = new MainMenu(readium, usageManager, isbn, ebookURL);
    mainMenu.setLastAccessiblePage(previewModeLastAccessiblePage);
    if (maxReadingPercentage == 100) {
      Managers.appPermission.grant('TOCMenu');
    } else {
      Managers.appPermission.revoke('TOCMenu');
    }
  });
};

var hidePageLayout = function(callback) {
  var currentAspectRatio = $(window).width() / $(window).height();
  var $pageLayout = $('[aria-label="Page Layout"]').parents('.layout-options');
  const aspectRatio = 1 / 1.5;
  if (currentAspectRatio < aspectRatio) {
    callback();
    $pageLayout.addClass('hidden');
  } else {
    $pageLayout.removeClass('hidden');
  }
}

if((window.fullScreen) ||
(window.innerWidth == screen.width && window.innerHeight == screen.height)) {
 $("#btnFullScreenView").addClass("hidden");
 $("#btnExitFullScreenView").removeClass("hidden").addClass("active");
}

var loadReaderUI = function(data) {
  Keyboard.scope('reader');

  ebookURL = data.epub;
  isbn = new URI(ebookURL).filename();

  Analytics.trackView('/reader');
  embedded = data.embedded;

  previewModeMaxReadingPercentage = undefined;
  previewModeWordCountInBook = undefined;
  previewModeLastAccessiblePage = undefined;
  wordsRead = 0;
  if (data.hasOwnProperty("formatResponse")) {
    formatResponseLength = data.formatResponse.length;
  }
  loadReaderUIPrivate();

  //because we reinitialize the reader we have to unsubscribe to all events for the previews reader instance
  if (readium && readium.reader) {
    Globals.logEvent('__ALL__', 'OFF', 'EpubReader.js');
    readium.reader.off();
  }

  setTimeout(function() {
    processFont(moduleConfig.fonts)
      .then(function(fontCSSUrl) {
        openDyslexicFont.url = fontCSSUrl;
      })
      .catch(function() {
        fontsArray = undefined;
        hideDyslexicFontOption();
      })
      .finally(initReadium)
      .then(function() {
        setupMenus();
        installReaderEventHandlers(); // BH-393

        // AccessibilityManager.init(JSON.parse(AccessibilityJson), {}, function() {
        $('#btnSkipToContent').on('click', function() {
          $('#epubContentIframe').focus();
          hideUI();
        });

        //     AccessibilityManager.registerActionHandler('focusContentElement', document, '', function () {
        //         console.log('focusContentElement')
        //         setTimeout(function() {
        //             $("#epubContentIframe").focus();
        //         }, 100)
        //     });
        // })
      })
      .catch(
        EvalError,
        RangeError,
        ReferenceError,
        SyntaxError,
        SyntaxError,
        TypeError,
        URIError,
        function(err) {
          errorMonitor.bugsnag(err);
          setBookTitle(ebookURL);
          splashMessage.init({
            closeBtn: false,
            cssClass: 'splash-system',
          });
          var error = new Errors.UnexpectedError(
            'Unexpected Error',
            'Unexpected Error! Please report error.',
            'Error',
          );
          $.extend(error, { urlParams: Helpers.getURLQueryParams(), origError: err });
          splashMessage.message(error);
          splashMessage.$el.show();
          //console.error(error);
          throw err;
        },
      )
      .catch(function(error) {
        // throw error;
        isbn = new URI(ebookURL).filename();
        $.extend(error.metaData, { isbn: isbn });
        errorMonitor.bugsnag(error);
        setBookTitle(ebookURL);
        splashMessage.init({
          closeBtn: false,
          cssClass: 'splash-system',
        });

        if (typeof error == 'string') {
          error = {
            Type: 'Error',
            Messages: ['An unexpected error has occurred.', error],
          };
        }
        error.Title = error.Title || Strings.err_dlg_title;
        error.Type = error.Type || 'Error';
        $.extend(error, { urlParams: Helpers.getURLQueryParams() });

        splashMessage.message(error);
        splashMessage.$el.show();
      })
      .catch(function(err) {
        errorMonitor.bugsnag(err);
        //console.error(err);
        throw err;
      });
  }, 0);
};

var initReadium = function() {
  console.log('MODULE CONFIG:');
  console.log(moduleConfig);

  var getSettingsPromise = function() {
    return new Promise(function(resolve, reject) {
      Settings.getMultiple(['reader', ebookURL], function(settings) {
        resolve(settings);
      });
    });
  };

  return (function() {
    var readerOptions = {
      el: '#epub-reader-frame',
      annotationCSSUrl: _annotationsCSSUrl,
      themesCSSUrl: _themesCSSUrl,
      mathJaxUrl: moduleConfig.mathJaxUrl,
      fonts: fontsArray
    };

    var readiumOptions = {
      jsLibRoot: moduleConfig.jsLibRoot,
      openBookOptions: {},
      doNotFollowEpubRedirect: true,
      doNotParseIBooksSpecificXml: true,
      resourceCache: resourceCache,
    };
    //Readium.getVersion.skipGitRepositories = true;
    //
    if (moduleConfig.useSimpleLoader) {
      readiumOptions.useSimpleLoader = true;
    }

    readium = new Readium(readiumOptions, readerOptions);

    window.READIUM = readium;

    tocUtils = new TocUtils(readium);

    var browserVersion = BrowserDetect();
    if (browserVersion.mobile) {
      isMobileBrowser = true;
      $('.zoom-options').hide();
    }

    readium.reader.on(Globals.Events.PLUGINS_LOADED, function() {
      Globals.logEvent('PLUGINS_LOADED', 'ON', 'EpubReader.js');

      console.log('PLUGINS INITIALIZED!');

      if (readium.reader.plugins && readium.reader.plugins.disablecopy) {
        readium.getRights().then((rights)=>{
          if (rights.copy < 100) {
            readium.reader.plugins.disablecopy.start();
          } else {
            readium.reader.plugins.disablecopy.stop();
          }
        })
      }

      if (!readium.reader.plugins || !readium.reader.plugins.highlights) {
        $('.icon-annotations').css('display', 'none');
      } else {
        readium.reader.plugins.accessibility.initialize();
        readium.reader.plugins.highlights.initialize({
          annotationCSSUrl: _annotationsCSSUrl,
          themesCSSUrl:_themesCSSUrl,
        });

        readium.reader.plugins.highlights.on('annotationClicked', function(
          type,
          idref,
          cfi,
          id,
          event,
          documentFrame,
          rangeInfo,
        ) {
            inlineWordReferenceMenu.annotationUtils
              .getAnnotation(idref, id, isbn)
              .then(function(annotation) {
                if (!annotation) {
                  console.warn('No annotation found when annotation clicked.', idref, cfi);
                  return;
                }

                var cfiObj;
                if (id.match(/^epubcfi\(/)) {
                  cfiObj = Helpers.locObjFromFullCFI(readium.packageDocument, id, false);
                } else {
                  cfiObj = { idref: idref, contentCFI: cfi };
                }
                var builtTextEvent = {
                  cfi: cfiObj,
                  document: documentFrame.contentDocument,
                  range: readium.reader.getDomRangeFromRangeCfi(cfiObj),
                  clear: $.noop, // not needed in this case, so no-op
                  text: annotation.booktext,
                };
                inlineWordReferenceMenu.textSelectionEvent(builtTextEvent);
                //inlineWordReferenceMenu.isCreatingAnnotation(true);

                //Pass no annotation since this is new
                inlineWordReferenceMenu.annotation = annotation;

                var triggerElement = documentFrame.contentDocument.activeElement;
                if (annotation.type === 'Highlight') {
                  inlineWordReferenceMenu.showHighlightMenu(triggerElement);
                } else {
                  inlineWordReferenceMenu.showNoteMenu(triggerElement);
                }
              });
        });
      }
    });

    if (readium.reader.plugins && readium.reader.plugins.selection) {
      readium.reader.plugins.selection.on('end', function(e) {
        try{
          popupModal.hideAll();
          inlineWordReferenceMenu.textSelectionEvent(e);
          //Pass no annotation since this is new
          inlineWordReferenceMenu.annotation = undefined;
          inlineWordReferenceMenu.show();
        }catch(err){
          console.error(err);
          errorMonitor.bugsnag(err);
        }
      // setTimeout(function() {
      //     AccessibilityManager.setTabGroup("highlight_note_text")
      //     AccessibilityManager.updateTabOrder("highlight_note_text")
      // }, 500)
      });
    }

    gesturesHandler = new GesturesHandler(readium.reader, readerOptions.el);
    gesturesHandler.initialize();

    $(window).on('keyup', function(e) {
      if (e.keyCode === 9 || e.which === 9) {
        unhideUI();
      }
    });

    readium.reader.addIFrameEventListener('keydown', function(e) {
      Keyboard.dispatch(document.documentElement, e);
    });

    readium.reader.addIFrameEventListener('keyup', function(e) {
      Keyboard.dispatch(document.documentElement, e);
    });

    readium.reader.addIFrameEventListener('focus', function(e) {
      $('#reading-area').addClass('contentFocus');
      $(window).trigger('focus');
    });

    readium.reader.addIFrameEventListener('blur', function(e) {
      $('#reading-area').removeClass('contentFocus');
    });

    /* // BH-325 (Removing code related to ReaderSettingsDialog.js / setting-*.html)
                SettingsDialog.initDialog(readium.reader);

                $('#settings-dialog').on('hidden.bs.modal', function () {

                    Keyboard.scope('reader');

                    unhideUI()
                    setTimeout(function () {
                        $("#settbutt1").focus();
                    }, 50);

                    $("#buttSave").removeAttr("accesskey");
                    $("#buttClose").removeAttr("accesskey");
                });

                $('#settings-dialog').on('shown.bs.modal', function () {

                    Keyboard.scope('settings');

                    $("#buttSave").attr("accesskey", Keyboard.accesskeys.SettingsModalSave);
                    $("#buttClose").attr("accesskey", Keyboard.accesskeys.SettingsModalClose);
                });*/

    /* // BH-325 (Removing code related to about-dialog.html)
                $('#about-dialog').on('hidden.bs.modal', function () {
                    Keyboard.scope('reader');

                    unhideUI();
                    setTimeout(function () {
                        $("#aboutButt1").focus();
                    }, 50);
                });

                $('#about-dialog').on('shown.bs.modal', function () {
                    Keyboard.scope('about');
                });*/

    readium.reader.on(Globals.Events.CONTENT_DOCUMENT_LOAD_START, function($iframe, spineItem) {
      Globals.logEvent(
        'CONTENT_DOCUMENT_LOAD_START',
        'ON',
        'EpubReader.js [ ' + spineItem.href + ' ]',
      );
    });
    EpubReaderMediaOverlays.init(readium, savePlace);

    EpubReaderBackgroundAudioTrack.init(readium);

    initDisplayOptionsUI();
    initFormatViewOptionsUI();
    initFormatViewUI();

    ttsDialog.init(readium); // for now we don't chain this into getSettingsPromise promise train below, so we don't inadvertently lock up if TTS unavailable

    hidePageLayout(function(){updateSyntheticSpreadSettings('single')});
    
    return getSettingsPromise()
      .then(function(settings) {
        var readerSettings;
        if (settings.reader) {
          readerSettings = JSON.parse(settings.reader);
        }
        if (!embedded) {
          readerSettings = readerSettings || SettingsDialog.defaultSettings;
          SettingsDialog.updateReader(readium.reader, readerSettings);
        } else {
          readium.reader.updateSettings({
            syntheticSpread: 'auto',
            scroll: 'auto',
          });
        }
        return readium.initPackageDocument(ebookURL);
      })
      .then(function(packageDocument) {
        /*if (!packageDocument) {
                         spin(false);
                         setBookTitle(ebookURL);

                         Dialogs.showErrorWithDetails(Strings.err_epub_corrupt, ebookURL);
                         //Dialogs.showModalMessage(Strings.err_dlg_title, ebookURL);

                         throw new Error("ERROR OPENING EBOOK: " + ebookURL);
                         }*/

        currentPackageDocument = packageDocument;

        /* // BH-325 (Removing code related to old TOC)
                        currentPackageDocument.generateTocListDOM(function (dom) {
                            loadToc(dom);
                        });
                        */

        var metadata = packageDocument.getMetadata();

        setBookTitle(metadata.title);
        currentPackageDocument.generateTocListJSON(function(tocData) {
          let tocUrl = currentPackageDocument.getToc();

          let tocUtils = new TocUtils(readium);

          tocUtils.setTocData(tocData);
        });
        $('#left-page-btn').unbind('click');
        $('#right-page-btn').unbind('click');
        var $pageBtnsContainer = $('#readium-page-btns');
        $pageBtnsContainer.empty();
        var rtl = currentPackageDocument.getPageProgressionDirection() === 'rtl'; //_package.spine.isLeftToRight()
        $pageBtnsContainer.append(
          ReaderBodyPageButtons({
            strings: Strings,
            /* dialogs: Dialogs, BH-325 */ keyboard: Keyboard,
            pageProgressionDirectionIsRTL: rtl,
          }),
        );
        $('#left-page-btn').on('click', prevPage);
        $('#right-page-btn').on('click', nextPage);

        return utils.isOnline();
      })
      .then(function() {
        var pageUsage = usageManager.fetchCurrentPositionFromRemote(isbn);
        return pageUsage;
      })
      .catch(Errors.BrowserOffline, function() {
        return new Promise(function(resolve){
          UsageManager.prototype.getPageUsage(isbn)
          .then(function (pageUsage) {
            resolve(pageUsage);
          });
        });
      })
      .then(function(pageUsage) {
        var currentLocation = pageUsage.getLocationAsReadiumCfi(readium.packageDocument);
        if (currentLocation) {
          var openPageRequest = {
            idref: currentLocation.idref,
            elementCfi: currentLocation.contentCFI,
            //ReadAlong: Last read position.
            raAudioPosition: currentLocation.raAudioPosition
          };
          return openPageRequest;
        }
      })
      .then(function(openPageRequest) {
        var urlParams = Helpers.getURLQueryParams();
        openPageRequest = Helpers.openPageRequestGotoOverride(openPageRequest, urlParams);

        return new Promise(function(resolve, reject) {
          /* // BH-325 (Removing code related to About dialog)
                                $('#app-container').append(AboutDialog({
                                    imagePathPrefix: moduleConfig.imagePathPrefix,
                                    strings: Strings,
                                    dateTimeString: version.dateTimeString,
                                    viewerJs: version.readiumJsViewer,
                                    readiumJs: version.readiumJs,
                                    sharedJs: version.readiumSharedJs,
                                    cfiJs: version.readiumCfiJs
                                }));*/

          return readium.openPackageDocument(openPageRequest).then(function() {
            // it used  for layout change
            readium.reader.layoutChange = false;
            onEbookLoaded();

            if (readium.reader.plugins && readium.reader.plugins.pagination) {
              return resolve(
                readium.reader.plugins.pagination.init(readium, currentPackageDocument),
              );
            } else {
              return resolve();
            }
          });
        });
      });
  })();
};

var unloadReaderUI = function() {

  if (readium) {
    readium.reader.reset();
    readium.closePackageDocument();
    // unbind events in reader
    if (readium.reader) {
      _.each(Globals.Events,function(event) {
        readium.reader.off(event);
      });
    }
  }

  // unbind listeners
  if (mainMenu) {
    mainMenu.unbindListeners();
  }


  // needed only if access keys can potentially be used to open a book while a dialog is opened, because keyboard.scope() is not accounted for with HTML access keys :(
  // for example: settings dialogs is open => SHIFT CTRL [B] access key => library view opens with transparent black overlay!
  /* // BH-325 (Removing code related to Dialogs.js)
            Dialogs.closeModal();
            Dialogs.reset();*/

  /* // BH-325 (Removing code related to ReaderSettingsDialog.js / setting-*.html)
            $('#settings-dialog').modal('hide');*/

  /* // BH-325 (Removing code related to about-dialog.html)
            $('#about-dialog').modal('hide');*/
  $('.modal-backdrop').remove();
  $('#app-navbar').off('mousemove');

  Keyboard.off('reader');
  Keyboard.off('settings');

  /* // BH-325 (Removing code related to ReaderSettingsDialog.js / setting-*.html)
            $('#settings-dialog').off('hidden.bs.modal');
            $('#settings-dialog').off('shown.bs.modal');*/

  /* // BH-325 (Removing code related to about-dialog.html)
            $('#about-dialog').off('hidden.bs.modal');
            $('#about-dialog').off('shown.bs.modal');*/

  // visibility check fails because iframe is unloaded
  //if (readium.reader.isMediaOverlayAvailable())
  if (readium && readium.reader) {
    // window.push/popstate
    try {
      readium.reader.pauseMediaOverlay();
    } catch (err) {
      //ignore error.
      //can occur when ReaderView._mediaOverlayPlayer is null, for example when openBook() fails
    }
  }
  $(window).off('orientationchange');
  $(window).off('resize');
  $(window).off('mousemove');
  $(window).off('keyup');
  $(window).off('message');
  window.clearTimeout(hideTimeoutId);
  $(document.body).removeClass('embedded');
  $('.book-title-header').remove();

  $('.offline-lib-btn').off('click');
  $(document.body).removeClass('hide-ui');
  updateEpubFooter();
};

var trackPageActivity = function (pageUsage) {
  return utils.isOnline().then(function () {  
   var pages = readium.reader.plugins.pagination.getVisibleLabelItems();  
   var pageIndex = 0;
   var hasVisiblePages = pages.length > 0 ? true :false;  
   if (hasVisiblePages) {
       var currentIndexObj =pages[0]['label']
       if(parseInt(currentIndexObj['label']) == totalPagesLength){
         pageIndex =totalPagesLength ;
         canGoLeft = true;
       }else if(parseInt(currentIndexObj['label']) < totalPagesLength){
           pageIndex = pages[pages.length - 1].label.label;
          }        
     
   return engageManager.trackActivityToRemote(pageUsage.isbn
                                             , pageIndex
                                             , totalPagesLength
                                             , 'ebook'
                                             , pageUsage.wordsonpage).then(function (response) {
   // could not determine timeout so default to 15 minutes out
   //inactivityTimerId = window.setTimeout(triggerInactiveNotice, 900000);
   });
 }
 }).catch(Errors.BrowserOffline, function () {
  //Unable to capture Activity, Browser Offline
   console.log("Browser is in offline");
 });
};

var applyKeyboardSettingsAndLoadUi = function(data) {
  // override current scheme with user options
  $(window).on('usageSynchronized', function(e){
    let useFetchedAnnotation = true;
    if(paginated) renderAnnotation(useFetchedAnnotation);
  })
  Settings.get('reader', function(json) {
    Keyboard.applySettings(json);
    Managers.ConfirmStoreDataValidity().catch(function(err) {
      errorMonitor.bugsnag(err);
    });
    usageManager = new UsageManager(Managers.user);
    engageManager = new EngageManager(Managers.user);
    eventPayload = data;
    loadReaderUI(data);
    
    var fullscreenElement = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullscreenElement || document.msFullscreenElement;
    if (!fullscreenElement) {
      $("#btnFullScreenView").removeClass("hidden").addClass("active");
      $("#btnExitFullScreenView").addClass("hidden");
      $("#app-fullscreen").attr("style", "");
    } else {
      $("#btnFullScreenView").addClass("hidden");
      $("#btnExitFullScreenView").removeClass("hidden").addClass("active");
      $("#app-fullscreen").attr("style", "background-color:#FFFFFF");
    }
    
    // reset title header color
    $('.book-title-header').removeClass('book-title-header-white');
  });
};

var setTitle = function(branding) {
  document.title = branding;
};

$(".js-close-popup").click(function () {
  $('#navbar-overflow-overlay').css('display', 'none');
});

/**
 * POPUP function
 */
function displaySwitchToComicViewPopup(){
  var popupModalFormatType = PopupFactory.create(PopupFactory.TYPE.MODAL, {id: "chooseFormatType"});
        popupModalFormatType.clear();
        popupModalFormatType.setMessage('You will lose your place in your book if you change to Comic View. Are you sure you want to change?');            
        popupModalFormatType.addButton("YES", function () {
        popupModalFormatType.hide();
        window.sessionStorage.setItem('COMIC_CURRENT_PAGE', -1);
        $(window).triggerHandler('readcomics', eventPayload);
        }, true);            
        popupModalFormatType.addButton("CANCEL", function () {
          //$("#Format_selected").text('eBook View');
          $("#formattextId").val('eBook View').change();
          $("#Format_selected").attr({
            "title": 'eBook View',
            "aria-labelledby": 'eBook View',
          }); 
          popupModalFormatType.hide();               
        }, false);
        popupModalFormatType.show();
}

function stripHtml(html){
  // Create a new div element
  var temporalDivElement = document.createElement("div");
  // Set the HTML content with the providen
  temporalDivElement.innerHTML = html;
  return temporalDivElement.textContent || temporalDivElement.innerText || "";
}

function checkIfFixedOrComicBook(){
  let isFixedLayout = readium.reader.isCurrentViewFixedLayout();
  let isComicBook = eBookType == 'cbz';
  if(isFixedLayout || isComicBook){
    return true;
  }
}

export default {
  loadUI: applyKeyboardSettingsAndLoadUi,
  unloadUI: unloadReaderUI,
  setTitle: setTitle,
};
