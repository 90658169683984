import HighlightView from './view';

// This is not a backbone view.

class HighlightBorderView extends HighlightView {
  constructor() {
    super();
    this.template = '<div class="rd-highlight-border"></div>';
  }

  setCSS() {
    this.$el.css({
      backgroundClip: 'padding-box',
      borderStyle: 'solid',
      borderWidth: '5px',
      boxSizing: 'border-box',
    });
    this._super();
  }

  setBaseHighlight() {
    this.$el.addClass('highlight-border');
    this.$el.removeClass('hover-highlight-border').removeClass('focused-highlight-border');
  }

  setHoverHighlight() {
    this.$el.addClass('hover-highlight-border');
    this.$el.removeClass('highlight-border');
  }

  setFocusedHighlight() {
    this.$el.addClass('focused-highlight-border');
    this.$el.removeClass('highlight-border').removeClass('hover-highlight-border');
  }
}

export default HighlightBorderView;
