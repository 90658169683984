import $ from 'jquery';
import Popup from './Popup';
import PopupModalBody from '../templates/popup/popup-modal-body.hbs';
import PopupModalPanelHeading from '../templates/popup/popup-modal-panel-heading.hbs';

var PopupModal = function(count, type, options) {
  options = this.appendPopup(options);
  options = $.extend(
    {
      height: '10.5em',
      width: '20em',
    },
    options,
  );

  this.$el.addClass('popup-modal');

  this.$body.innerWidth(options.width);
  this.$body.innerHeight(options.height);
  this.$body.html(PopupModalBody({}));
  this._buttons = [];
};

PopupModal = Popup.prototype.extends(PopupModal);

PopupModal.prototype.setTitle = function(title) {
  var $heading = this.$body.find('.panel-heading');
  $heading.html(PopupModalPanelHeading({ title: title }));
  var self = this;
  $heading.find('button.close').on('click', function(e) {
    e.preventDefault();
    self.$el.hide();
  });
};

PopupModal.prototype.setMessage = function(msg) {
  var $body = this.$body.find('.panel-body');
  $body.html(msg);
  $body.attr('aria-label', msg);
};

PopupModal.prototype.addInputBox = function(placeholderTxt) {
  var $body = this.$body.find('.panel-body');
  $body.append(
    '<input type="text" id="inputBox" class="input-box form-control" placeholder="' +
      placeholderTxt +
      '" required="required"></input>',
  );
};

PopupModal.prototype.getInputValue = function() {
  return this.$body.find('#inputBox').val();
};
PopupModal.prototype.addButton = function(label, callback, isPrimary) {
  var $el = $('<button>').addClass('btn');

  if (isPrimary) {
    $el.addClass('btn-primary');
  } else {
    $el.addClass('btn-default');
  }
  $el.text(label);
  $el.on('click', callback);
  this.$body.find('.panel-footer').append($el);

  this._buttons.push({
    label: label,
    $el: $el,
  });
};

PopupModal.prototype.addButtons = function(buttons) {
  var self = this;
  buttons.forEach(function(button) {
    self.addButton(button.label, button.callback, button.isPrimary);
  });
};

PopupModal.prototype.getButtons = function() {
  return this._buttons;
};

PopupModal.prototype.clear = function() {
  this.$el.find('.panel-body, .panel-footer').empty();
  this.setTitle('');
};

export default PopupModal;
