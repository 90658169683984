import $ from 'jquery';
import URI from 'urijs';
import { getURLQueryParams } from './Helpers';
import Promise from 'bluebird';
import Errors from './Errors';
import PackageInfo from '../../src/PackageInfo';
import moduleConfig from '../../src/ModuleConfig';

/**
 * RequireJS doesn't replace the native Promise code, so we force it to use as a Global drop-in replacement.
 * Bluebird is spec compliant, and is faster and fuller featured when compare to native code.
 */

window.Promise = Promise;

var Utils = function() {
  var customTimeout;
  var isOnlineXhrPromise;
  this.isOnline = Promise.method(function() {
    var isOnline = window.navigator.onLine;
    var self = this;

    function setTimer() {
      setTimeout(function() {
        isOnlineXhrPromise = undefined;
      }, 10000);
    }

    if (!isOnline) {
      throw new Errors.BrowserOffline('Browser is offline.');
    } else {
      if (isOnlineXhrPromise instanceof Promise) {
        return isOnlineXhrPromise;
      } else {
        var host =
          window.location.protocol + '//' + window.location.host + window.location.pathname;
        var jqXhr = $.ajax({
          url: host,
          method: 'HEAD',
          cache: false,
        });

        isOnlineXhrPromise = self
          .deferredToPromise(jqXhr)
          .then(function() {
            setTimer();
            return true;
          })
          .catch(function(error) {
            setTimer();
            throw new Errors.BrowserOffline('Browser is offline.');
          });
        return isOnlineXhrPromise;
      }
    }
  });

  var RestFullUrlPath = function(host) {
    var _host = host;
    var parameters = [];
    this.add = function(item) {
      if (item instanceof Array) {
        parameters = item;
      } else {
        parameters.push(item);
      }
      return this;
    };
    this.remove = function(item) {
      var index = parameters.indexOf(item);
      if (index > -1) {
        parameters.splice(index, 1);
      }
      return this;
    };
    this.getUrl = function() {
      var url = _host;

      return url;
    };
    this.toString = function() {
      var path = '';
      parameters.forEach(function(currentValue, index, array) {
        path += currentValue;
        if (array.length - 1 > index) {
          path += '/';
        }
      });
      return path;
    };
  };
  this.restFullPath = function(host) {
    return new RestFullUrlPath(host);
  };

  this.getRemoteServerOrigin = function(location) {
    if (!location) {
      location = new URI(window.location.href);
      //location = location.search(true);
      location = getURLQueryParams();
      location =
        location.audiobook ||
        location.epub ||
        location.epubs ||
        location.videobook ||
        window.sessionStorage.getItem('epubLibraryPath');
    }

    var url = new URI(location).protocol('https').origin();
    url = new URI(url);

    return url;
  };

  /**
   * Wrapper to convert jQuery defers for ajax calls to Promises.
   * Currently only supports xhr.done() and xhr.fail()
   * @param options
   */
  this.deferredToPromise = function(jqXhr) {
    return new Promise(function(resolve, reject) {
      jqXhr.done(resolve).fail(function(jqXhr) {
        var error = new Error(jqXhr);
        if (typeof jqXhr.responseJSON != 'undefined') {
          let responseJSON = jqXhr.responseJSON;
          error = new Errors.UnexpectedError(
            responseJSON.Title,
            responseJSON.Messages,
            responseJSON.Type,
            responseJSON.ReturnCode
          );
        }
        reject(error);
      });
    });
  };

  this.fetchAsArrayBuffer = function(url, cbProgress) {
    return new Promise(function(resolve, reject) {
      if (typeof url === 'undefined') {
        throw 'Fetched url is undefined!';
      }

      var xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.timeout = 0;
      xhr.responseType = 'arraybuffer';
      xhr.onerror = function() {
        reject(xhr);
      };

      xhr.ontimeout = function() {
        reject(xhr);
      };

      xhr.onload = function() {
        window.clearTimeout(customTimeout);
        resolve([xhr.response, xhr]);
      };

      if (!customTimeout) {
        customTimeout = window.setTimeout(function() {
          reject(xhr);
        }, 60000);
      }

      if ($.isFunction(cbProgress)) {
        xhr.onprogress = function(e) {
          // reset customTimeout
          window.clearTimeout(customTimeout);
          customTimeout = window.setTimeout(function() {
            reject(xhr);
          }, 60000);

          if (e.lengthComputable) {
            var pct = e.loaded / e.total;
            pct = Math.round(pct * 100, 1);
            cbProgress(pct);
          }
        };
      }

      xhr.send();
    });
  };

  this.promisify = function(fn) {
    return function() {
      var args = arguments;
      return new Promise(function(resolve, reject) {
        try {
          Array.prototype.push.call(args, resolve);
          Array.prototype.push.call(args, reject);
          fn.apply(this, args);
        } catch (error) {
          reject(error);
        }
      });
    };
  };

  var appid;
  this.getAppId = function() {
    return new Promise(function(resolve, reject) {
      if (appid) {
        resolve(appid);
      } else {
        resolve('AxisNow-' + PackageInfo.version);
      }
    });
  };

  this.sendInfo = function(messageInfo, onsuccess, onerror) {
    var url = this.getRemoteServerOrigin().path('feedback');
    let self = this;
    return this.getAppId().then(function(appid) {
      var jqXhr = $.ajax({
        type: 'POST',
        data: JSON.stringify({ appid: appid, type: 'Error', content: JSON.stringify(messageInfo) }),
        contentType: 'application/json',
        url: url,
        success: function(result) {
          if (onsuccess) {
            onsuccess(result);
          }
        },
        error: function(xhr, status, errorThrown) {
          if (onerror) {
            onerror(xhr, status, errorThrown);
          }
        },
      });
      console.log(
        'data POST request',
        JSON.stringify({ appid: appid, type: 'Error', content: messageInfo }),
      );
      return self.deferredToPromise(jqXhr).then(function(response) {
        // discard the return value
      });
    });
  };

  this.isProductionBuild = function() {
    return moduleConfig.releaseStage === 'Production';
  };

  /**
 * It used for removed already highlighted
 */
this.updateHighligtedIcon = function(identifyName) {
  try {
  let temp_class_OR_Id = [{class_OR_ID_name: 'btnToggleNotes', find_same_id:true ,icon_name:'icon icon-notes'},
  {class_OR_ID_name: 'btnToggleFontOptions', find_same_id:false, icon_name:'icon icon-display'},
  {class_OR_ID_name: 'btn-expand-audio', find_same_id:false, icon_name:'icon icon-icon-tts-settings'},
  {class_OR_ID_name: 'btn-collapse-audio', find_same_id:false, icon_name:'icon icon-icon-tts-settings'},
  {class_OR_ID_name: 'btnToggleBookmark', find_same_id:false, icon_name:'icon icon-bookmark-outline'},
  {class_OR_ID_name: 'btnToggleTTSSettings', find_same_id:false, icon_name:'icon icon-icon-tts-settings'},{class_OR_ID_name: 'btnToggleSearch', find_same_id:true, icon_name:'icon icon-search'},{class_OR_ID_name: 'btnToggleHelp', find_same_id:true, icon_name:'icon icon-help'}]
     temp_class_OR_Id.forEach(element => {
        if (element.class_OR_ID_name != identifyName && element.find_same_id) {
          $(`.${element.class_OR_ID_name}RA span:first`).attr('class', element.icon_name);
          $(`#${element.class_OR_ID_name} span:first`).attr('class', element.icon_name);
          $(`.${element.class_OR_ID_name} span:first`).attr('class', element.icon_name);
        } else if(element.class_OR_ID_name != identifyName && !element.find_same_id) {
          $(`#${element.class_OR_ID_name} span:first`).attr('class', element.icon_name);
        }
     })
      } catch (error) {
      console.error('updateHighligtedIcon-catch',error.message)
  }
 }

};

export default new Utils();
