var _objectStores = {};

var MigrationManager5To7 = function(db, transaction) {
  this.db = db;
  this.transaction = transaction;

  const _STORE_NAMES = (this.STORE_NAMES = {
    UsageStore: 'UsageStore',
    UserStore: 'UserStore',
    KeyStore: 'KeyStore',
    LibraryDataStore: 'LibraryDataStore',
    LicenseStore: 'LicenseStore',
    BookDataStore: 'BookDataStore',
  });
  _objectStores[_STORE_NAMES.UsageStore] = {
    name: 'UsageStore',
    value: { keyPath: 'id' },
  };
  _objectStores[_STORE_NAMES.UserStore] = {
    name: _STORE_NAMES.UserStore,
    value: { keyPath: 'id' },
    indexes: [
      {
        name: 'bookvaultId',
        keyPath: 'bookvaultId',
        optionalParameters: { unique: true },
      },
    ],
  };

  _objectStores[_STORE_NAMES.KeyStore] = {
    name: _STORE_NAMES.KeyStore,
    value: { autoIncrement: true },
    indexes: [
      {
        name: 'name',
        keyPath: 'name',
        optionalParameters: { unique: true },
      },
      {
        name: 'spki',
        keyPath: 'spki',
        optionalParameters: { unique: false },
      },
    ],
  };

  _objectStores[_STORE_NAMES.LibraryDataStore] = {
    name: _STORE_NAMES.LibraryDataStore,
    value: { keyPath: 'uuid_isbn' },
    indexes: [
      {
        name: 'uuid',
        keyPath: 'uuid',
        optionalParameters: { unique: false },
      },
      {
        name: 'isbn',
        keypath: 'isbn',
        optionalParameters: { unique: true },
      },
    ],
  };

  _objectStores[_STORE_NAMES.LicenseStore] = {
    name: _STORE_NAMES.LicenseStore,
    value: { keyPath: 'uuid_isbn' },
    indexes: [
      {
        name: 'uuid',
        keyPath: 'uuid',
        optionalParameters: { unique: false },
      },
      {
        name: 'isbn',
        keypath: 'isbn',
        optionalParameters: { unique: true },
      },
    ],
  };

  _objectStores[_STORE_NAMES.BookDataStore] = {
    name: _STORE_NAMES.BookDataStore,
    value: { keyPath: 'isbn' },
    indexes: [
      {
        name: 'isbn',
        keypath: 'isbn',
        optionalParameters: { unique: true },
      },
    ],
  };
};

MigrationManager5To7.prototype.convertStoreIdsToLowercase = function(storeName) {
  return new Promise(async (resolve, reject) => {
    var self = this;
    var convertedData = [];
  
    var index;
    var request;
    var objStore = this.transaction.objectStore(storeName);
    console.log('convertStoreIdsToLowercase', storeName);
  
    if (storeName === 'UserStore') {
      index = objStore.index('bookvaultId');
      request = index.openCursor();
    } else if (storeName === 'UsageStore') {
      request = objStore.openCursor();
    }
  
    request.onsuccess = function(evt) {
      var cursor = evt.target.result;
      if (cursor) {
        var user = cursor.value;
        user.id = user.id.toLowerCase();
        convertedData.push(user);
        cursor.continue();
      }
  
      var clearRequest = objStore.clear();
      clearRequest.onsuccess = async function(ev) {
        await self.resaveDataToUserStore(storeName, convertedData);
        console.log(`%c ${storeName} converted`, 'background: #222; color: #00faff');
        resolve();
      };

      clearRequest.onerror = function(ev) {
        console.log('clearRequest error:', ev.target.error);
        reject();
      };
    }

    request.onerror = function(ev) {
      console.log('bookvaultId cursor error:', ev.target.error);
      reject();
    };
  })
};

MigrationManager5To7.prototype.resaveDataToUserStore = async function(storeName, data) {
  var userStore = this.transaction.objectStore(storeName);
  console.debug('%c convertedData ', 'background: #222; color: #00faff');
  console.debug(data)
  for(let user of data){
    console.debug('%c user entry ', 'background: #222; color: #00faff');
    console.debug(user)
    var request = await userStore.put(user);
    request.onerror = function(ev) {
      console.log('resaveData error:', ev.target.error);
      throw new Error(ev.target.error)
    };
  }
};

MigrationManager5To7.prototype.migrate = async function() {
  await this.convertStoreIdsToLowercase('UserStore');
  await this.convertStoreIdsToLowercase('UsageStore');
  console.debug('5 to 7 done');
};

export default MigrationManager5To7;
