import ErrorMonitor from './ErrorMonitor';
import $ from 'jquery';
import EpubLibrary from './EpubLibrary';
import EpubReader from './EpubReader';
import AudiobookPlayer from './AudiobookPlayer';
import VideoBookPlayer from './VideoBookPlayer';
import Helpers from './EpubReaderHelpers';
import moduleConfig from './ModuleConfig';
import Managers from '@axisnow/data/Managers';
import utils from '@axisnow/util/utils';
import UsageManager from './usage/UsageManager';
import splashMessage from './SplashMessage';
import PageBrowserSupportHeader from './templates/page-browser-support-header.hbs';
import PageBrowserSupport from './templates/page-browser-support.hbs';
import PopupFactory from './popup/PopupFactory';
import LoginTemplate from './templates/page-login.hbs';
import Errors from '@axisnow/util/Errors';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import 'pace-js'
// Replace pace-theme-minimal.css file to pace-theme-minimal.css' and imported again
import '../src/less/app-ui/pace-custom-theme.less'
import Modernizr from '@axisnow/modernizr';
import ComicReader from './ComicReader';
import URI from 'urijs';
import ComicManager from './ComicManager';

import moment from 'moment-timezone';

Pace.start();
var _initialLoad = true; // replaces pushState() with replaceState() at first load
//var _userManager;
//var BRANDING = 'Boundless'; //this changes the appearance in the tabs of browsers
var BRANDING = ''; //this changes the appearance in the tabs of browsers
var $loginForm;
$loginForm = $(LoginTemplate({}));
$loginForm.hide();
console.log("%c Adding Message Event Listener to Window", 'background: #222; color: #bada55');
var usageManager = new UsageManager(Managers.user);
// HandleMessageEvent() is used to display messages from the service worker via the browser native
// 'message' event and our SplashMessage api.
// For more details on the 'message' event: https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerGlobalScope/onmessage
function HandleMessageEvent(event) {
  console.log('%c Received Message Event In EpubReader', 'background: #222; color: #bada55')
  if(event.data.type == 'CONNECTED_TO_OLD_VERSION'){
    console.log('%c \n\n\n OLD VERSION DETECTED \n\n\n', 'background: #222; color: #bada55')
    splashMessage.init({
      closeBtn: false,
      cssClass: "splash-system"
    })
    splashMessage.message({
      Type: 'WARNING',
      Title: "Reader Downgrade Blocked",
      Messages: [
        'An attempt to downgrade the reader to an outdated version has been blocked'
      ]
    })
    splashMessage.$el.show()
  }
  if(event.data.type == 'NEW_VERSION'){
    console.log('%c \n\n\n NEW VERSION DETECTED \n\n\n', 'background: #222; color: #bada55')
    splashMessage.init({
      closeBtn: false,
      cssClass: "splash-system"
    })
    splashMessage.message({
      Type: 'WARNING',
      Title: "New Reader Version Available",
      Messages: [
        'Please close all tabs and open a new one to begin using the new version.'
      ]
    })
    splashMessage.$el.show()
  }
}

window.addEventListener('message', HandleMessageEvent)
// Attaching delegated blur event on login form
$loginForm.on('blur.login-input', '.form-control', function() {
  // Adding 'filled' class if input has any data
  if ($(this).val()) {
    $(this).addClass('filled');
  } else {
    $(this).removeClass('filled');
  }
});
var initialLoad = function() {
  var urlParams = Helpers.getURLQueryParams();
  var profileId = Helpers.getUrlParam("profileId"); //kidszone, to find out downloaded the book belong which profileId

  var ebookURL = urlParams.epub;
  var audioBookURL = urlParams.audiobook;
  var videoBookURL = urlParams.videobook;
  //Mobile App will send the bookType for comic.
  var ebookType = urlParams.bookType; 
  var libraryURL = urlParams.epubs;
  var embedded = urlParams.embedded;
  var token = urlParams.token;
  var viewerModeValue = urlParams.mode;
  var comicUrl = urlParams.comic;
  var isComics = false;
  var formatResponse = [];
  var user;
  var isbn;
  var BOOK_SUPPORT_TYPES = {
     EPUB : 'epub',
     CBZ: 'cbz'
  };
  $('#app-container').append($loginForm);
  $.ajaxSetup({
    timeout: 120000 , //2 mins
  });

  Managers.user
    .userFromToken()
    .then(function(userInfo) {
      user = userInfo;
      var comicManager = new ComicManager();
      
      //Format Issue : Offline mode doesnot carry ebookUrl
      if (ebookURL) {

        if(ebookType && ebookType == BOOK_SUPPORT_TYPES.CBZ){
          formatResponse = [BOOK_SUPPORT_TYPES.CBZ];
          loadController(BOOK_SUPPORT_TYPES.CBZ);
        } else {
            var isbn = new URI(ebookURL).filename();
            /**
             * if current book already downloaded we sync page information to server first.
             */
              Managers.library.getBookInfo(isbn).then(function(book) {
                  if(book && book.downloaded && book.type == BOOK_SUPPORT_TYPES.CBZ) {
                    usageManager.saveCurrentComicPositionToRemote(isbn).then(() => {

                      /**
                       *  compare current profileId and downloaded the book profileId, if not same delete the book
                       */
                        if (profileId) {
                            if (book.profileid != profileId) {
                              Managers.RemoveAllDownloadedBooks()
                                .then(function() {
                                /**
                                 * Get Format of Book
                                 */
                                getFormat(isbn,BOOK_SUPPORT_TYPES,comicManager);
                                })
                                .catch(function() {
                                  console.error('Unable to remove all books');
                                });
                            } else {
                              /**
                               * Get Format of Book
                                */
                              getFormat(isbn,BOOK_SUPPORT_TYPES,comicManager);
                            }
                        } else {
                          /**
                           * Get Format of Book
                           */
                          getFormat(isbn,BOOK_SUPPORT_TYPES,comicManager);
                        }
                      });
                  } else if(book && book.downloaded && book.type == BOOK_SUPPORT_TYPES.EPUB) {
                    usageManager.saveCurrentPositionToRemote(isbn).then(() => {
                      /**
                       *  compare current profileId and downloaded the book profileId, if not same delete the book 
                       */ 
                        if (profileId) {
                            if (book.profileid != profileId) {
                              Managers.RemoveAllDownloadedBooks()
                                .then(function() {
                                /**
                                 * Get Format of Book
                                */
                                getFormat(isbn,BOOK_SUPPORT_TYPES,comicManager);
                                })
                                .catch(function() {
                                  console.error('Unable to remove all books');
                                });    
                            } else {
                              /**
                              * Get Format of Book
                              */
                              getFormat(isbn,BOOK_SUPPORT_TYPES,comicManager);
                              
                            }
                        } else {
                          /**
                           * Get Format of Book
                           */
                          getFormat(isbn,BOOK_SUPPORT_TYPES,comicManager);
                        }
                    })
                  } else {
                      /**
                        * Get Format of Book
                        */
                        getFormat(isbn,BOOK_SUPPORT_TYPES,comicManager);
                  } 
              });
        }

      } else if (videoBookURL) {
            /**
             * We dont received video stream url display message
             */
          if (!user.hasOwnProperty('videoSignedUrl') || !user.videoSignedUrl) {
            splashMessage.init({
            closeBtn: false,
            cssClass: "splash-system"
            })
            splashMessage.message({
              Type: 'WARNING',
              Title: "Unable Play Video",
              Messages: [
                'Sorry, We are unable To play video. please close the tab and try again.'
              ]
            })
            splashMessage.$el.show()
          } else {
            var eventPayload = {videoUrl: user.videoSignedUrl, itemId: user.itemId, caption: user.caption, videoTitle:user.titleName, videoId:user.videoId};
            $(window).triggerHandler('watchvideobook', eventPayload);
          }
        
      } else {
        loadController();
      }
     
    })
    .catch(function(err) {
      console.error(err);

      //Dialogs.showModalMessage("Login Error", "Sorry, we are unable to verify your account.  Please return to Axis360 and try again. ");
      splashMessage.init({
        closeBtn: false,
        cssClass: 'splash-system',
        urlParams: Helpers.getURLQueryParams(),
      });

      err.title = err.title || 'Login Error';
      //error.type = error.type || "error";
      ErrorMonitor.bugsnag(err);
      splashMessage.message(err);
      splashMessage.$el.show();
    });

    function loadController(bookType) {
      $('#app-container').append($loginForm);
      $('#svg-spinner').hide();
      utils
        .isOnline()
        .then(function() {
          usageManager.syncAllBookUsages(true, ebookURL, (bookType == BOOK_SUPPORT_TYPES.CBZ));
        })
        .catch(function(error) {
          if (
            error != undefined &&
            error.name != undefined &&
            error.name.trim().toLowerCase() == 'browseroffline'
          ) {
            usageManager.syncAllBookUsages(false, ebookURL);
          }
        });
  
      var eventPayload;
      
      // ***********
  
      //var isCbzFormat = $.inArray('cbz', formatResponse)
      //Need to change the condition - remove comicUrl
          
      if (ebookURL && bookType == BOOK_SUPPORT_TYPES.EPUB) {
        //EpubReader.loadUI(urlParams);
        eventPayload = { embedded: embedded, epub: ebookURL, epubs: libraryURL, formatResponse: formatResponse };
        if (viewerModeValue) {
          eventPayload.viewerMode = viewerModeValue;
        }
        $(window).triggerHandler('readepub', eventPayload);
      } else if (ebookURL && bookType == BOOK_SUPPORT_TYPES.CBZ) {
        window.sessionStorage.removeItem('COMIC_CURRENT_PAGE');
        window.sessionStorage.removeItem('COMIC_CURRENT_AREA');
        if (viewerModeValue) {
          eventPayload.viewerMode = viewerModeValue;
        }
        eventPayload = { embedded: embedded, epub: ebookURL, epubs: libraryURL, formatResponse: formatResponse };
        $(window).triggerHandler('readcomics', eventPayload);
      } else if (audioBookURL) {
        eventPayload = {
          audiobookid: user.fndContentID,
        };
        $(window).triggerHandler('readaudiobook', eventPayload);
      } else {
        //EpubLibrary.loadUI({epubs: libraryURL});
        eventPayload = libraryURL;
        $(window).triggerHandler('loadlibrary', eventPayload);
      }
       
        
      $(document.body).on('click', function() {
        $(document.body).removeClass('keyboard');
      });

      $(document).on('keyup', function(e) {
        $(document.body).addClass('keyboard');
      });
      return null;
    }


    function clearSessionStorage(){
       //Removing session stroage values
       window.sessionStorage.removeItem('CURRENT_PAGE');
       window.sessionStorage.removeItem('CURRENT_FRAME');
    }

    /**
 * it used to get format type
 */
function getFormat(isbn,BOOK_SUPPORT_TYPES,comicManager) {

          comicManager.checkFormat(isbn).then(function (format) {
                // Updated Response variable to formats
                if (format && format.formats.length == 1) {
                  formatResponse = format.formats;
                  loadController(format.formats[0]);
                } else if (format && format.formats.length > 1){
                    formatResponse = format.formats;
                    if(format.formats.includes('cbz')){
                    loadController(BOOK_SUPPORT_TYPES.CBZ);
                    }else {
                    loadController(BOOK_SUPPORT_TYPES.EPUB);
                  }
                } else {
                  loadController(BOOK_SUPPORT_TYPES.EPUB);
                }
              })
              .catch(function (error) {
                  console.log('Controller', error);
                  //If check format fails fallback to EPub.
                  //loadController(BOOK_SUPPORT_TYPES.EPUB);
                  isbn = new URI(ebookURL).filename();
                  if(isbn) {
                  Managers.library.getBookInfo(isbn).then(function(book) {
                    if (book && book.downloaded) {
                      if(book.type == 'cbz') {
                        loadController(BOOK_SUPPORT_TYPES.CBZ)
                      }else {
                        loadController(BOOK_SUPPORT_TYPES.EPUB)
                      }
                    }
                  })
                }
              });
}
};

var pushState = $.noop;
var replaceState = $.noop;

var isChromeExtensionPackagedApp =
  typeof chrome !== 'undefined' && chrome.app && chrome.app.window && chrome.app.window.current; // a bit redundant?

if (
  !isChromeExtensionPackagedApp && // "history.pushState is not available in packaged apps"
  window.history &&
  window.history.pushState
) {
  $(window).on('popstate', function() {
    var state = history.state;
    if (!state) {
      state = Helpers.getURLQueryParams();
    }
    console.debug('BROWSER HISTORY POP STATE:');
    console.log(state);

    if (state && state.epub) {
      readerView(state);
    } else if (state && state.epubs) {
      libraryView(state.epubs);
    } else {
       if (Object.keys(state).length == 0) {
         initialLoad();
       } else {
         libraryView();
       }
    }
  });

  pushState = function(data, title, url) {
    console.debug('BROWSER HISTORY PUSH STATE:');
    //console.log(title);
    console.log(url);
    console.log(data);
    history.pushState(data, title, url);
  };

  replaceState = function(data, title, url) {
    console.debug('BROWSER HISTORY REPLACE STATE:');
    //console.log(title);
    console.log(url);
    console.log(data);
    history.replaceState(data, title, url);
  };
}

var tooltipSelector = 'header *[title]';
var libraryView = function(libraryURL, importEPUB) {
  //$(tooltipSelector).tooltip('destroy');

  EpubReader.unloadUI();
  EpubLibrary.unloadUI();
  AudiobookPlayer.unloadUI();
  ComicReader.unloadUI();

  if (libraryURL) {
    EpubLibrary.loadUI({ epubs: libraryURL });
  } else {
    EpubLibrary.loadUI({ epubs: undefined, importEPUB: importEPUB });
  }
  EpubLibrary.setTitle(BRANDING);
};

var readerView = function(data) {
  //$(tooltipSelector).tooltip('destroy');

  EpubLibrary.unloadUI();
  EpubReader.unloadUI();
  AudiobookPlayer.unloadUI();
  ComicReader.unloadUI();

  EpubReader.loadUI(data);
  EpubReader.setTitle(BRANDING);
  utils
    .isOnline()
    .then(function() {
      EpubReader.setTitle(BRANDING);
    })
    .catch(function(error) {
      console.log('Offline');
      $(window).trigger('offline');
    });
};

//Comic
var audiobookView = function(data) {
  //$(tooltipSelector).tooltip('destroy');

  EpubLibrary.unloadUI();
  EpubReader.unloadUI();
  AudiobookPlayer.unloadUI();
  ComicReader.unloadUI();

  AudiobookPlayer.loadUI(data);
  AudiobookPlayer.setTitle(BRANDING);

};

var comicView = function (data) {

  EpubLibrary.unloadUI();
  EpubReader.unloadUI();

  AudiobookPlayer.unloadUI();
  ComicReader.unloadUI();

  ComicReader.loadUI(data);
  ComicReader.setTitle(BRANDING);
};

var videobookView = function (data) {
  EpubLibrary.unloadUI();
  EpubReader.unloadUI();

  AudiobookPlayer.unloadUI();
  ComicReader.unloadUI();
  
  VideoBookPlayer.loadUI(data);
  VideoBookPlayer.setTitle(BRANDING); 
};

var URLPATH = window.location
  ? window.location.protocol +
    '//' +
    window.location.hostname +
    (window.location.port ? ':' + window.location.port : '') +
    window.location.pathname
  : 'index.html';

var buildUrlQueryParameters = function(urlpath, overrides) {
  var key;
  var val;
  var paramsString = '';

  for (key in overrides) {
    if (!overrides.hasOwnProperty(key)) continue;

    if (!overrides[key]) continue;

    val = overrides[key].trim();
    if (!val) continue;

    console.debug('URL QUERY PARAM OVERRIDE: ' + key + ' = ' + val);

    paramsString += key + '=' + encodeURIComponent(val);
    paramsString += '&';
  }

  var urlParams = Helpers.getURLQueryParams();
  for (key in urlParams) {
    if (!urlParams.hasOwnProperty(key)) continue;

    if (!urlParams[key]) continue;

    if (overrides[key]) continue;

    val = urlParams[key].trim();
    if (!val) continue;

    console.debug('URL QUERY PARAM PRESERVED: ' + key + ' = ' + val);

    paramsString += key + '=' + encodeURIComponent(val);
    paramsString += '&';
  }

  return urlpath + '#q?' + paramsString;
};
$(window).on('RunOutOfSpace', function() {
  setTimeout(function() {
    $('div.popup-body.annotations')
      .parent()
      .remove();
    if (!$('div.popup-body.annotations').is(':visible') && $('.library-items').length == 0) {
      var popupModelRunOutOfSpace = PopupFactory.create(PopupFactory.TYPE.RUN_OUT_OF_SPACE);
      popupModelRunOutOfSpace.setTitle('Insufficient Storage Available!!!');
      popupModelRunOutOfSpace.setMessage(
        'Your device has insufficient space available.  Please press Yes to review your Offline Library Content.<br/><br/>',
      );
      popupModelRunOutOfSpace.$body.css('min-height', '190px');
      popupModelRunOutOfSpace.$body.css('height', '190px');
      popupModelRunOutOfSpace.$body.parent('.popup-modal').css('z-index', '1000000');
      popupModelRunOutOfSpace.$body.addClass('annotations');
      popupModelRunOutOfSpace.addButton(
        'Yes',
        function(e) {
          $('#svg-spinner').hide();
          $('html').attr('data-theme', 'library');
          navigateToOfflineContentLibrary();
          popupModelRunOutOfSpace.hide();
        },
        true,
      );
      popupModelRunOutOfSpace.addButton(
        'No',
        function(e) {
          popupModelRunOutOfSpace.hide();
        },
        true,
      );
      popupModelRunOutOfSpace.show();
    }
  }, 1000);
});
Managers.user.on('LoginRequired', loginRequired);

function loginRequired(LoginUser, error) {
  function loginBtn() {
    return new Promise(function(resolve, reject) {
      $loginForm
        .find('.btn')
        .off()
        .one('click', function(evt) {
          evt.preventDefault();
          resolve(evt);
          return false;
        });
    });
  }
  if ($loginForm.is(':hidden')) {
    $loginForm.show();
    $(document).prop('title', BRANDING + ' - Login'); // adding title
  }
  if (error) {
    createErrorlabelTemplate(error);
  }
  loginBtn()
    .then(function(event) {
      event.preventDefault();
      var id = $loginForm.find('.form-control').val();
      if (id.trim() == '') {
        throw new Errors.UnableToLogin('No id submitted');
      }
      //Callback funtion called
      LoginUser(id.trim());
    })
    .catch(function(error) {
      createErrorlabelTemplate(error);
      loginRequired(LoginUser);
    });
}
function createErrorlabelTemplate(error) {
  //error handling for callback funtion,
  var $error = $('.page-login .container-form .error');
  $error.html('');
  if ($error.length == 0) {
    $('.page-login .container-form .row-buttons').before(
      "<div role='alert' class='error' aria-atomic='true'></div>",
    );
    $error = $('.page-login .container-form .error');
  }
  if (error.Title == 'Unable to login.') {
    ErrorMonitor.bugsnag(error);
  }
  $error.append("<span class='icon icon-warning'></span> " + error.Title);
}
$(window).on('readaudiobook', function(e, eventPayload) {
  if (!eventPayload || !eventPayload.audiobookid) return;
  _initialLoad = false;

  audiobookView(eventPayload);
  if ($('.container-offline').length == 0) {
    $(window).off('online offline');
  }

  Managers.appPermission.permission(_app_permissions);
  //new PermissionSettings().import(_app_permissions);

  // }).error(function(error) {
  //   console.log(error);
  // });
  //import all permission from configuration file
});

$(window).on('readcomics', function (e, eventPayload) {
  console.log('hey i am read comics  ******', eventPayload);
  if (!eventPayload || !eventPayload.epub) return;
  _initialLoad = false;
  comicView(eventPayload);
  if ($('.container-offline').length == 0) {
    $(window).off('online offline');
  }
});

function navigateToOfflineContentLibrary() {
  var urlParams = Helpers.getURLQueryParams();
  var ebookURL = undefined;
  var importEPUB = undefined;
  var urlState = buildUrlQueryParameters(URLPATH, {
    epubs: ebookURL,
    epub: ' ',
    goto: ' ',
  });
  var func = _initialLoad ? replaceState : pushState;
  func({ epubs: ebookURL }, 'Readium Library', urlState);
  _initialLoad = false;

  libraryView(ebookURL, importEPUB);

  $(window).triggerHandler('loadlibrary.done');
}
$(window).on('readepub', function(e, eventPayload) {
  if (!eventPayload || !eventPayload.epub) return;

  var epub = eventPayload.epub;

  var urlState = buildUrlQueryParameters(URLPATH, {
    epub: epub,
    epubs: eventPayload.epubs ? eventPayload.epubs : undefined,
    embedded: eventPayload.embedded ? eventPayload.embedded : undefined,
  });

  var func = _initialLoad ? replaceState : pushState;
  func({ epub: epub, epubs: eventPayload.epubs }, 'Readium Viewer', urlState);

  _initialLoad = false;

  readerView(eventPayload);
  $(window).on('online offline', function() {
    confirmUserIsOnline();
  });
  function confirmUserIsOnline() {
    var footerText = $('.footer-epub').find('.container-offline');
    utils
      .isOnline()
      .then(function() {
        footerText.text('ONLINE MODE');
        $('#btnToggleSearch, .btnToggleSearchRA').prop('disabled', false);
      })
      .catch(function(error) {
        if (
          error != undefined &&
          error.name != undefined &&
          error.name.trim().toLowerCase() == 'browseroffline'
        ) {
          $('#btnToggleSearch, .btnToggleSearchRA').prop('disabled', true);
          footerText.text('OFFLINE MODE');
        }
        if (navigator.onLine) {
          setTimeout(function() {
            confirmUserIsOnline();
          }, 15000);
        }
      });
  }
  //import all permission from configuration file
  Managers.appPermission.permission(_app_permissions);
  // .error(function(error) {
  //   console.log(error);
  // });
});

$(window).on('watchvideobook', function(e, eventPayload) {
  if (!eventPayload || !eventPayload.videoUrl) return;
  _initialLoad = false;

  videobookView(eventPayload);
  if ($('.container-offline').length == 0) {
    $(window).off('online offline');
 }
});

$(window).on('loadlibrary', function(e, eventPayload) {
  var libraryURL;
  var importEPUB;
  if (typeof eventPayload === 'string') {
    libraryURL = eventPayload;
  } else {
    //File/Blob
    importEPUB = eventPayload;
  }

  var urlState = buildUrlQueryParameters(URLPATH, {
    epubs: libraryURL ? libraryURL : undefined,
    epub: ' ',
    goto: ' ',
  });

  var func = _initialLoad ? replaceState : pushState;
  func({ epubs: libraryURL }, 'Readium Library', urlState);

  _initialLoad = false;

  libraryView(libraryURL, importEPUB);

  $(window).triggerHandler('loadlibrary.done');
});

/*$(document.body).tooltip({
        selector: tooltipSelector,
        placement: 'auto',
        container: 'body' // do this to prevent weird navbar re-sizing issue when the tooltip is inserted
    }).on('show.bs.tooltip', function (e) {
        $(tooltipSelector).not(e.target).each(function () {
            var $this = $(this);
            if ($this.data('bs.tooltip')) {
                var $data = $this.data('bs.tooltip');
                if ($data.$element) {
                    $this.tooltip('destroy');
                }
            }
        })
    });*/

if (
  window.File
  //&& window.FileReader
) {
  var fileDragNDropHTMLArea = $(document.body);
  fileDragNDropHTMLArea.on('dragover', function(ev) {
    ev.stopPropagation();
    ev.preventDefault();

    //$(ev.target)
    fileDragNDropHTMLArea.addClass('fileDragHover');
  });
  fileDragNDropHTMLArea.on('dragleave', function(ev) {
    ev.stopPropagation();
    ev.preventDefault();

    //$(ev.target)
    fileDragNDropHTMLArea.removeClass('fileDragHover');
  });
  fileDragNDropHTMLArea.on('drop', function(ev) {
    ev.stopPropagation();
    ev.preventDefault();

    //$(ev.target)
    fileDragNDropHTMLArea.removeClass('fileDragHover');

    var files = ev.target.files || ev.originalEvent.dataTransfer.files;
    if (files.length) {
      var file = files[0];
      console.log('File drag-n-drop:');
      console.log(file.name);
      console.log(file.type);
      console.log(file.size);

      if (file.type == 'application/epub+zip' || /\.epub$/.test(file.name)) {
        if (isChromeExtensionPackagedApp) {
          $(window).triggerHandler('loadlibrary', file);
        } else {
          var urlParams = Helpers.getURLQueryParams();
          //var ebookURL = urlParams['epub'];
          var libraryURL = urlParams.epubs;
          var embedded = urlParams.embedded;

          var eventPayload = { embedded: embedded, epub: file, epubs: libraryURL };
          $(window).triggerHandler('readepub', eventPayload);
        }

        // var reader = new FileReader();
        // reader.onload = function(e) {

        //     console.log(e.target.result);

        //     var ebookURL = e.target.result;
        //     $(window).triggerHandler('readepub', ...);
        // }
        // reader.readAsDataURL(file);
      }
    }
  });
}

function StartApplicationInsights() {
  console.log("Starting Application Insights")
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: moduleConfig.applicationInsightsKey,
      enableAutoRouteTracking: true,
      enableCorsCorrelation: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
      correlationHeaderExcludedDomains: ['*.findawayworld.com', 'api.wordnik.com']
    }
  })
  appInsights.loadAppInsights()
  appInsights.trackPageView()
}

function setToFullScreen(fullscreen){
  var elem = document.getElementById("app-fullscreen");
  try {
    if(fullscreen){
      if (elem.requestFullscreen) {
        return elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        return elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE11 */
        return elem.msRequestFullscreen();
      } else if(elem.webkitSupportsFullscreen && elem.webkitEnterFullscreen) { /* Mobile Safari */
        elem.webkitEnterFullscreen();       
      }
    } else {
        if (document.exitFullscreen) {
        return document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
        return document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
        return document.msExitFullscreen();
        } else if(elem.webkitSupportsFullscreen && elem.webkitExitFullscreen) { /* Mobile Safari */
          elem.webkitExitFullscreen();  
        }
    }
    //update icon status if any other icon has active
    utils.updateHighligtedIcon('btnFullScreenView');
  } catch (error) {
      console.error(error);
      return;
  }  
}

window.setToFullScreen = setToFullScreen;

export function initialize() {
  StartApplicationInsights()
  //This depends on Modernizr to have finisehd running, must wait for handler to be triggered before loading
  var beginInitialLoad = function() {
    if (Modernizr.indexeddb && Modernizr.crypto) {
      console.log('result indexeddb:' + Modernizr.indexeddb);
      console.log('result crypto:' + Modernizr.crypto);

      $(initialLoad);
    } else {
      $('#app-navbar').append(PageBrowserSupportHeader({}));
      $('#app-container').append(PageBrowserSupport({}));
    }
  };

  if (typeof Modernizr.indexdbkeysupported !== 'undefined') {
    beginInitialLoad();
  } else {
    Modernizr.on('customready', function(event, data) {
      console.log('data', data);
      beginInitialLoad();
    });
  }
  // Set session StartTime
  window.sessionStorage.setItem('startTimeStamp', moment().utc().format());
}
