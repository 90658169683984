import utils from '@axisnow/util/utils';
import IndexedDBDataManager from '@axisnow/storage/IndexedDBDataManager';
import FileSystem from '@axisnow/storage/filesystem';

var _dataManager = new IndexedDBDataManager('BookDataStore', manageConflict);
var fileSystem = new FileSystem();

var self;

var BookDataStoreManager = function() {
  self = this;
};

// Private API
function makeBook(isbn, emptyBook) {
  emptyBook.isbn = isbn;
  emptyBook.cover = undefined;
  emptyBook.metadata = undefined;
  return emptyBook;
}

function getBookDataFromStorage(isbn) {
  return _dataManager
    .get('isbn', isbn)
    .then(function(bookData) {
      if (bookData && bookData.isbn) {
        return bookData;
      } else {
        return makeBook(isbn, bookData);
      }
    })
    .catch(function(error) {
      console.log('Error: failed to get book data from storage');
      throw error;
    });
}

function saveBookDataToStorage(bookData) {
  return _dataManager.put('isbn', bookData.isbn, bookData).catch(function(error) {
    console.error('saveBookDataToStorage error', error);
  });
}

function manageConflict(bookData1, bookData2) {
  conflictResolution('cover', bookData1, bookData2);
  conflictResolution('metadata', bookData1, bookData2);
  return bookData1;
}

function conflictResolution(key, bookData1, bookData2) {
  var date1 = new Date(bookData1._meta.updateTime);
  var date2 = new Date(bookData2._meta.updateTime);
  //We check to see if we need to overwrite the bookData1[key] value with data from bookData2
  //bookData1 is updated if it is undefined.
  //if both objects are not undefined, conflict is resolved based on date
  if (bookData1[key] == undefined) {
    console.log('bookData1 ' + key + ' is undefined');
    bookData1[key] = bookData2[key];
  } else if (bookData2[key] != undefined && date2 > date1) {
    console.log('bookData2 ' + key + ' is define and bookData2 is more recent');
    bookData1[key] = bookData2[key];
  }
  return bookData1;
}

// Public API
BookDataStoreManager.prototype.fetchBookCover = function(isbn) {
  return getBookDataFromStorage(isbn).then(function(bookData) {
    if (bookData.cover == null) {
      return utils
        .fetchAsArrayBuffer(
          utils
            .getRemoteServerOrigin()
            .path('contentCafe/' + isbn)
            .toString(),
        )
        .spread(function(data, xhr) {
          bookData.cover = {
            arrayBuffer: data,
            type: xhr.getResponseHeader('Content-Type'),
          };
          bookData.isbn = isbn;

          //save the cover in Data Storage
          saveBookDataToStorage(bookData);

          return bookData;
        })
        .catch(function(error) {
          console.error('getBookData error', error);
          return bookData;
        });
    } else {
      return bookData;
    }
  });
};

BookDataStoreManager.prototype.saveMetadata = function(isbn, metaData) {
  return getBookDataFromStorage(isbn).then(function(bookData) {
    if (bookData.metadata == null) {
      bookData.metadata = metaData;
      bookData.isbn = isbn;

      //save to local storage
      saveBookDataToStorage(bookData);

      return bookData;
    } else {
      return bookData;
    }
  });
};

BookDataStoreManager.prototype.getBookData = function(isbn) {
  return getBookDataFromStorage(isbn).catch(function(error) {
    console.error('getBookData error', error);
  });
};

BookDataStoreManager.prototype.saveBookData = function(bookData) {
  return saveBookDataToStorage(bookData).catch(function(error) {
    console.error('getBookData error', error);
  });
};

BookDataStoreManager.prototype.deleteBookData = function(isbn) {
  return getBookDataFromStorage(isbn).then(function(bookData) {
    return _dataManager.remove('isbn', bookData.isbn, bookData);
  });
};

export default BookDataStoreManager;
