import $ from 'jquery';

/**
 *
 * @param reader
 * @constructor
 */
var MediaOverlayElementHighlighter = function(reader) {
  this.includeParWhenAdjustingToSeqSyncGranularity = true;

  var DEFAULT_MO_ACTIVE_CLASS = 'mo-active-default';
  var DEFAULT_MO_SUB_SYNC_CLASS = 'mo-sub-sync';

  //var BACK_COLOR = "#99CCCC";

  var _highlightedElementPar = undefined;
  this.isElementHighlighted = function(par) {
    return _highlightedElementPar && par === _highlightedElementPar;
  };

  var _highlightedCfiPar = undefined;
  this.isCfiHighlighted = function(par) {
    return _highlightedCfiPar && par === _highlightedCfiPar;
  };

  var _activeClass = '';
  var _playbackActiveClass = '';

  var _reader = reader;

  var HIGHLIGHT_ID = 'MO_SPEAK';

  var self = this;

  var $userStyle = undefined;
  var $head = undefined;
  
  this.reDo = function() {
    //this.reset();

    if ($userStyle) {
      $userStyle.remove();
    }
    $userStyle = undefined;

    var he = _highlightedElementPar;
    var hc = _highlightedCfiPar;
    var c1 = _activeClass;
    var c2 = _playbackActiveClass;

    if (_highlightedElementPar) {
      this.reset();

      this.highlightElement(he, c1, c2);
    } else if (_highlightedCfiPar) {
      this.reset();

      this.highlightCfi(hc, c1, c2);
    }
  };

  function ensureUserStyle($element, hasAuthorStyle, overrideWithUserStyle) {
    if ($userStyle) {
      try {
        if ($userStyle[0].ownerDocument === $element[0].ownerDocument) {
          return;
        }
      } catch (e) {}
    }

    $head = $('head', $element[0].ownerDocument.documentElement);

    $userStyle = $("<style type='text/css'> </style>");

    $userStyle.append('.' + DEFAULT_MO_ACTIVE_CLASS + ' {');

    var fallbackUserStyle =
      'background-color: yellow !important; color: black !important; border-radius: 0.4em;';

    var style = overrideWithUserStyle; //_reader.userStyles().findStyle("." + DEFAULT_MO_ACTIVE_CLASS);
    if (style) {
      var atLeastOne = false;
      for (var prop in style.declarations) {
        if (!style.declarations.hasOwnProperty(prop)) {
          continue;
        }
        console.log("HAS ATLEAST ONE USER STYLE");
        atLeastOne = true;
        $userStyle.append(prop + ': ' + style.declarations[prop] + '; ');
      }

      if (!atLeastOne && !hasAuthorStyle) {
        console.log("FALL BACK USER STYLE!!!");
        $userStyle.append(fallbackUserStyle);
      }
    } else if (!hasAuthorStyle) {
      console.log("FALL BACK USER STYLE!!!");
      $userStyle.append(fallbackUserStyle);
    }

    $userStyle.append('}');

    // ---- CFI
    //$userStyle.append(" .highlight {background-color: blue; border: 2x solid green;}"); //.hover-highlight

    $userStyle.appendTo($head);
    console.log("userStyle: ", $head);
    console.log($userStyle[0].textContent);
  }

  this.highlightElement = function(par, activeClass, playbackActiveClass) {
    if (!par || par === _highlightedElementPar) {
      return;
    }

    this.reset();

    _highlightedElementPar = par;
    _highlightedCfiPar = undefined;

    _activeClass = activeClass;
    _playbackActiveClass = playbackActiveClass;

    var seq = this.adjustParToSeqSyncGranularity(_highlightedElementPar);
    var element = seq.element;

    if (_playbackActiveClass && _playbackActiveClass !== '') {
      //console.debug("MO playbackActiveClass: " + _playbackActiveClass);
      $(element.ownerDocument.documentElement).addClass(_playbackActiveClass);
      //console.debug("MO playbackActiveClass 2: " + element.ownerDocument.documentElement.classList);
    }

    var $hel = $(element);

    var hasAuthorStyle = _activeClass && _activeClass !== '';
    var overrideWithUserStyle = _reader.userStyles().findStyle('.' + DEFAULT_MO_ACTIVE_CLASS);

    ensureUserStyle($hel, hasAuthorStyle, overrideWithUserStyle);

    if (overrideWithUserStyle || !hasAuthorStyle) {
      //console.debug("MO active NO CLASS: " + _activeClass);

      if (hasAuthorStyle) {
        $hel.addClass(_activeClass);
        //Photon: Fix for div synch highlight
        var spans = $hel.find('span');
        if (spans) {
          spans.addClass(_activeClass);
        }
      }

      $hel.addClass(DEFAULT_MO_ACTIVE_CLASS);

      //Photon: Fix for div synch highlight
        var spans = $hel.find('span');
        if (spans) {
          spans.addClass(DEFAULT_MO_ACTIVE_CLASS);
        }

      //$(element).css("background", BACK_COLOR);
    } else {
      //console.debug("MO activeClass: " + _activeClass);
      $hel.addClass(_activeClass);       
      //Photon: Fix for div synch highlight
      var spans = $hel.find('span');
      if (spans) {
        spans.addClass(_activeClass);
      }
    }

    if (this.includeParWhenAdjustingToSeqSyncGranularity || _highlightedElementPar !== seq) {
      $(_highlightedElementPar.element).addClass(DEFAULT_MO_SUB_SYNC_CLASS);
    }

    // ---- CFI
    //         try
    //         {
    //             // //noinspection JSUnresolvedVariable
    //             // var cfi = EPUBcfi.Generator.generateElementCFIComponent(element); //$hel[0]
    //             // if(cfi[0] == "!") {
    //             //     cfi = cfi.substring(1);
    //             // }
    //
    // //console.log(element);
    //
    //             var firstTextNode = getFirstTextNode(element);
    //             var txtFirst = firstTextNode.textContent;
    // //console.log(txtFirst);
    //
    //             var lastTextNode = getLastTextNode(element);
    //             var txtLast = lastTextNode.textContent;
    // //console.log(txtLast);
    //
    //             var cfi = EPUBcfi.Generator.generateCharOffsetRangeComponent(
    //                     firstTextNode,
    //                     0,
    //                     lastTextNode,
    //                     txtLast.length,
    //                     ["cfi-marker"],
    //                     [],
    //                     ["MathJax_Message"]
    //                     );
    //
    //             var id = $hel.data("mediaOverlayData").par.getSmil().spineItemId;
    //             _reader.addHighlight(id, cfi, HIGHLIGHT_ID,
    //             "highlight", //"underline"
    //             undefined // styles
    //                         );
    //         }
    //         catch(error)
    //         {
    //             console.error(error);
    //
    //             removeHighlight();
    //         }
  };

  this.highlightCfi = function(par, activeClass, playbackActiveClass) {
    if (!par || par === _highlightedCfiPar) {
      return;
    }

    this.reset();

    _highlightedElementPar = undefined;
    _highlightedCfiPar = par;

    _activeClass = activeClass;
    _playbackActiveClass = playbackActiveClass;

    var $hel = $(_highlightedCfiPar.cfi.cfiTextParent);

    var hasAuthorStyle = _activeClass && _activeClass !== '';
    var overrideWithUserStyle = _reader.userStyles().findStyle('.' + DEFAULT_MO_ACTIVE_CLASS); // TODO: performance issue?

    ensureUserStyle($hel, hasAuthorStyle, overrideWithUserStyle);

    var clazz =
      overrideWithUserStyle || !hasAuthorStyle
        ? (hasAuthorStyle ? _activeClass + ' ' : '') + DEFAULT_MO_ACTIVE_CLASS
        : _activeClass;

    if (_reader.plugins.highlights) {
      // same API, newer implementation
      try {
        //var id = $hel.data("mediaOverlayData").par.getSmil().spineItemId;
        var id = par.getSmil().spineItemId;
        _reader.plugins.highlights.addHighlight(
          id,
          par.cfi.partialRangeCfi,
          HIGHLIGHT_ID,
          'highlight', //"underline"
          undefined, // styles
        );
      } catch (error) {
        console.error(error);
      }
    } else if (_reader.plugins.annotations) {
      // legacy
      try {
        //var id = $hel.data("mediaOverlayData").par.getSmil().spineItemId;
        var id = par.getSmil().spineItemId;
        _reader.plugins.annotations.addHighlight(
          id,
          par.cfi.partialRangeCfi,
          HIGHLIGHT_ID,
          'highlight', //"underline"
          undefined, // styles
        );
      } catch (error) {
        console.error(error);
      }
    }
  };

  // ---- CFI
  //
  //     function getFirstTextNode(node)
  //     {
  //         if (node.nodeType === Node.TEXT_NODE)
  //         {
  //             if (node.textContent.trim().length > 0)
  //                 return node;
  //         }
  //
  //         for (var i = 0; i < node.childNodes.length; i++)
  //         {
  //             var child = node.childNodes[i];
  //             var first = getFirstTextNode(child);
  //             if (first)
  //             {
  //                 return first;
  //             }
  //         }
  //
  //         return undefined;
  //     }
  //
  //     function getLastTextNode(node)
  //     {
  //         if (node.nodeType === Node.TEXT_NODE)
  //         {
  //             if (node.textContent.trim().length > 0)
  //                 return node;
  //         }
  //
  //         for (var i = node.childNodes.length-1; i >= 0; i--)
  //         {
  //             var child = node.childNodes[i];
  //             var last = getLastTextNode(child);
  //             if (last)
  //             {
  //                 return last;
  //             }
  //         }
  //
  //         return undefined;
  //     }
  //

  this.reset = function() {
    if (_highlightedCfiPar) {
      var doc = _highlightedCfiPar.cfi.cfiTextParent.ownerDocument;

      if (_reader.plugins.highlights) {
        // same API, new implementation
        try {
          _reader.plugins.highlights.removeHighlight(HIGHLIGHT_ID);

          var toRemove = undefined;
          while ((toRemove = doc.getElementById('start-' + HIGHLIGHT_ID)) !== null) {
            console.log('toRemove START');
            console.log(toRemove);
            toRemove.parentNode.removeChild(toRemove);
          }
          while ((toRemove = doc.getElementById('end-' + HIGHLIGHT_ID)) !== null) {
            console.log('toRemove END');
            console.log(toRemove);
            toRemove.parentNode.removeChild(toRemove);
          }
        } catch (error) {
          console.error(error);
        }
      } else if (_reader.plugins.annotations) {
        // legacy
        try {
          _reader.plugins.annotations.removeHighlight(HIGHLIGHT_ID);

          var toRemove = undefined;
          while ((toRemove = doc.getElementById('start-' + HIGHLIGHT_ID)) !== null) {
            console.log('toRemove START');
            console.log(toRemove);
            toRemove.parentNode.removeChild(toRemove);
          }
          while ((toRemove = doc.getElementById('end-' + HIGHLIGHT_ID)) !== null) {
            console.log('toRemove END');
            console.log(toRemove);
            toRemove.parentNode.removeChild(toRemove);
          }
        } catch (error) {
          console.error(error);
        }
      }

      _highlightedCfiPar = undefined;
    }

    if (_highlightedElementPar) {
      var seq = this.adjustParToSeqSyncGranularity(_highlightedElementPar);
      var element = seq.element;
      if (this.includeParWhenAdjustingToSeqSyncGranularity || _highlightedElementPar !== seq) {
        $(_highlightedElementPar.element).removeClass(DEFAULT_MO_SUB_SYNC_CLASS);
      }

      if (_playbackActiveClass && _playbackActiveClass !== '') {
        //console.debug("MO RESET playbackActiveClass: " + _playbackActiveClass);
        $(element.ownerDocument.documentElement).removeClass(_playbackActiveClass);
      }

      if (_activeClass && _activeClass !== '') {
        //console.debug("MO RESET activeClass: " + _activeClass);
        $(element).removeClass(_activeClass);
        //Photon: Fix for div synch highlight
        var spans = $(element).find('span');
        if(spans) spans.removeClass(_activeClass);
      }
      //else
      //{
      //console.debug("MO RESET active NO CLASS: " + _activeClass);
      $(element).removeClass(DEFAULT_MO_ACTIVE_CLASS);
      //Photon: Fix for div synch highlight
      var spans = $(element).find('span');
      if(spans) spans.removeClass(DEFAULT_MO_ACTIVE_CLASS);
      //$(element).css("background", '');
      //}

      _highlightedElementPar = undefined;
    }

    _activeClass = '';
    _playbackActiveClass = '';
  };

  this.adjustParToSeqSyncGranularity = function(par) {
    if (!par) return undefined;

    var sync = _reader.viewerSettings().mediaOverlaysSynchronizationGranularity;
    if (sync && sync.length > 0) {
      var element = par.element || (par.cfi ? par.cfi.cfiTextParent : undefined);
      if (!element) {
        console.error('adjustParToSeqSyncGranularity !element ???');
        return par; // should never happen!
      }

      var seq = par.getFirstSeqAncestorWithEpubType(
        sync,
        this.includeParWhenAdjustingToSeqSyncGranularity,
      );
      if (seq && seq.element) {
        return seq;
      }
    }

    return par;
  };
};

export default MediaOverlayElementHighlighter;
