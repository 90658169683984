function TrackUsage(id, uuid, eventEmitter) {
  Object.defineProperty(this, 'uuid', {
    value: uuid,
    writable: false,
    enumerable: true,
  });

  Object.defineProperty(this, 'isbn', {
    value: id,
    writable: false,
    enumerable: true,
  });

  var chapter = '';
  Object.defineProperty(this, 'chapter', {
    enumerable: true,
    get: function() {
      return chapter;
    },
    set: function(chap) {
      chapter = chap;
      eventEmitter.emit('TrackUsage:updated', this);
    },
  });

  var part = '';
  Object.defineProperty(this, 'part', {
    enumerable: true,
    get: function() {
      return part;
    },
    set: function(par) {
      part = par;
      eventEmitter.emit('TrackUsage:updated', this);
    },
  });

  var timeoffset = '';
  Object.defineProperty(this, 'timeoffset', {
    enumerable: true,
    get: function() {
      return timeoffset;
    },
    set: function(offset) {
      timeoffset = offset;
      eventEmitter.emit('TrackUsage:updated', this);
    },
  });

  var date = new Date();
  Object.defineProperty(this, 'usercreatedate', {
    value: date,
    writable: false,
    enumerable: true,
  });
}

TrackUsage.prototype.setCurrentPosition = function(position, chapter, part) {
  this.timeoffset = position.toFixed(0);
  this.chapter = chapter;
  this.part = part;
};

TrackUsage.prototype.getPositionAsObject = function() {
  return { timeoffset: this.timeoffset, chapter: this.chapter, part: this.part };
};

export default TrackUsage;
