import $ from 'jquery';
import FeatureManager from './FeatureManager';
var featureManager = FeatureManager.getInstance();
var mapPermissions = new Map();
var AppPermissionManager = function() {
  var isPermissionOverridable;
  //If argument is of string type then return permission object with that name otherwise add this permission using map object
  this.permission = function(permission) {
    try {
      var self = this;
      if (typeof permission == 'string') {
        return fetchPermissionsByName(permission);
      }
      $.each(permission, function(index, permission) {
        addPermission(permission, self);
      });
    } catch (ex) {
      throw ex;
    }
  };
  //Enable all features associated with that permission
  this.grant = function(permissionName, isPermissionOverridable) {
    try {
      grantRevokePermission(permissionName, true, isPermissionOverridable);
    } catch (ex) {
      throw ex;
    }
  };
  //Disable all features associated with that permission
  this.revoke = function(permissionName, isPermissionOverridable) {
    try {
      grantRevokePermission(permissionName, false, isPermissionOverridable);
    } catch (ex) {
      throw ex;
    }
  };
  function grantRevokePermission(permissionName, isGranted, isPermissionOverridable) {
    var permission = fetchPermissionsByName(permissionName);
    if (typeof isPermissionOverridable == 'undefined') {
      isPermissionOverridable = permission.overridable;
    }
    if (permission && isPermissionOverridable) {
      var featureGroup = featureManager.featureGroup(permissionName);
      if (typeof featureGroup != 'string') {
        if (isGranted) {
          featureGroup.enable();
          console.log('feature group enabled ' + permissionName);
        } else {
          featureGroup.disable();
          console.log('featureGroup disabled ' + permissionName);
        }
      }
      var feature = featureManager.feature(permissionName);
      if (typeof feature != 'string') {
        if (isGranted) {
          feature.enable();
          console.log('feature enabled ' + permissionName);
        } else {
          feature.disable();
          console.log('feature disabled ' + permissionName);
        }
      }
    }
  }
  //fetch permission from mapped object using permissionName
  function fetchPermissionsByName(permissionName) {
    if (mapPermissions.has(permissionName)) {
      return mapPermissions.get(permissionName);
    }
    console.log('permission details not found for: ' + permissionName);
    return '';
  }
  //Adds new/Update existing permission
  function addPermission(permissionObject, self) {
    mapPermissions.set(permissionObject.name, permissionObject);
    if (permissionObject.disableByDefault == undefined) {
      permissionObject.disableByDefault = false;
    }
    if (!permissionObject.disableByDefault) {
      self.grant(permissionObject.name, true);
      console.log('permission granted for ' + permissionObject.name);
    } else if (permissionObject.disableByDefault) {
      self.revoke(permissionObject.name, true);
      console.log('permission revoked for ' + permissionObject.name);
    }
    console.log('Permission added for: ' + permissionObject.name);
  }
};
export default AppPermissionManager;
