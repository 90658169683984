import $ from 'jquery';
import InlineMenu from './InlineMenu';
import AnnotationUtils from '../AnnotationUtils';
import EventEmitter from 'eventemitter3';
import Helpers from '../../EpubReaderHelpers';

var listenerSet = false;

var selectedHighlightType, isCreatingAnnotation, annotation;

var InlineAnnotationMenu = function(readium, usageManager, isbn, eventEmitter) {
  InlineMenu.apply(this, arguments);
  this.annotationUtils = new AnnotationUtils(this.readium, this.usageManager);

  this.eventEmitter = eventEmitter || new EventEmitter();
  var data = {
    annotation: undefined,
  };

  $.extend(this, new EventEmitter());

  Object.defineProperties(this, {
    annotation: {
      enumerable: true,
      get: function() {
        return data.annotation;
      },
      set: function(annotation) {
        try{
          if (!annotation && this._textSelectionEvent) {
            var self = this;
            var fullCFI = Helpers.locObjToFullCFI(
              this.readium.packageDocument,
              this._textSelectionEvent.cfi,
            );
            this.annotationUtils
              .getAnnotation(this._textSelectionEvent.cfi.idref, fullCFI, this.isbn)
              .then(function(annotationObj) {
                data.annotation = annotationObj;
                self.emit('annotation:updated', data.annotation);
              });
          } else {
            data.annotation = annotation;
            this.emit('annotation:updated', data.annotation);
          }
        }catch(err){
          throw err;
        }
      },
    },
  });
};

InlineAnnotationMenu.prototype = Object.create(InlineMenu.prototype);

//Public API

InlineAnnotationMenu.prototype.selectedHighlightType = function(selectedHighlightType_) {
  if (!!selectedHighlightType_) {
    selectedHighlightType = selectedHighlightType_;
  } else {
    return selectedHighlightType;
  }
};

InlineAnnotationMenu.prototype.annotation = function(annotation_) {
  if (!!annotation_) {
    annotation = annotation_;
  } else {
    return annotation;
  }
};
export default InlineAnnotationMenu;
