import $ from 'jquery';
import utils from '@axisnow/util/utils';

var _isbn;

function RemoteSearch(isbn) {
  _isbn = isbn;
}

RemoteSearch.prototype.byWord = function(word) {
  var jqXhr = $.ajax({
    type: 'POST',
    data: JSON.stringify({ word: word }),
    contentType: 'application/json',
    url: utils.getRemoteServerOrigin().path('search/exact/' + _isbn),
  });
  return utils.deferredToPromise(jqXhr).then(function(response) {
    return response;
  });
};
export default RemoteSearch;
