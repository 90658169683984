import UUID from 'node-uuid';

var _objectStores = {};

var MigrationManager4To5 = function(db, transaction) {
  this.db = db;
  this.transaction = transaction;

  const _STORE_NAMES = (this.STORE_NAMES = {
    UsageStore: 'UsageStore',
    UserStore: 'UserStore',
    KeyStore: 'KeyStore',
    LibraryDataStore: 'LibraryDataStore',
    LicenseStore: 'LicenseStore',
    BookDataStore: 'BookDataStore',
  });
  _objectStores[_STORE_NAMES.UsageStore] = {
    name: 'UsageStore',
    value: { keyPath: 'id' },
  };
  _objectStores[_STORE_NAMES.UserStore] = {
    name: _STORE_NAMES.UserStore,
    value: { keyPath: 'id' },
    indexes: [
      {
        name: 'bookvaultId',
        keyPath: 'bookvaultId',
        optionalParameters: { unique: true },
      },
    ],
  };
  _objectStores[_STORE_NAMES.KeyStore] = {
    name: _STORE_NAMES.KeyStore,
    value: { autoIncrement: true },
    indexes: [
      {
        name: 'name',
        keyPath: 'name',
        optionalParameters: { unique: true },
      },
      {
        name: 'spki',
        keyPath: 'spki',
        optionalParameters: { unique: false },
      },
    ],
  };
  _objectStores[_STORE_NAMES.LibraryDataStore] = {
    name: _STORE_NAMES.LibraryDataStore,
    value: { keyPath: 'uuid_isbn' },
    indexes: [
      {
        name: 'uuid',
        keyPath: 'uuid',
        optionalParameters: { unique: false },
      },
      {
        name: 'isbn',
        keypath: 'isbn',
        optionalParameters: { unique: true },
      },
    ],
  };
  _objectStores[_STORE_NAMES.LicenseStore] = {
    name: _STORE_NAMES.LicenseStore,
    value: { keyPath: 'uuid_isbn' },
    indexes: [
      {
        name: 'uuid',
        keyPath: 'uuid',
        optionalParameters: { unique: false },
      },
      {
        name: 'isbn',
        keypath: 'isbn',
        optionalParameters: { unique: true },
      },
    ],
  };
  _objectStores[_STORE_NAMES.BookDataStore] = {
    name: _STORE_NAMES.BookDataStore,
    value: { keyPath: 'isbn' },
    indexes: [
      {
        name: 'isbn',
        keypath: 'isbn',
        optionalParameters: { unique: true },
      },
    ],
  };
};

var applyMeta = function(document) {
  var meta = {};
  var timeNow = Date.now();

  meta.createTime = timeNow;
  meta.deleted = false;
  meta.updateTime = timeNow;
  meta.revision = UUID.v4();

  document._meta = meta;

  return document;
};

// Rename the old data stores
MigrationManager4To5.prototype.ensureTables = function() {
  return new Promise((resolve, reject) => {
    var self = this;
    if (self.db.objectStoreNames.contains('LibraryDataStore')) {
      self.db.deleteObjectStore('LibraryDataStore');
    }
    resolve();
  })
};

MigrationManager4To5.prototype.updateIndex = function(storeSchema, storeName) {
  console.debug('update', storeName);
  const self = this;
  const store = self.transaction.objectStore(storeName);
  if(storeSchema.indexes){
    for(const index of storeSchema.indexes){
      console.debug('update index', storeName);
      if(!store.indexNames.contains(index.name)){
        store.createIndex(index.name, index.keyPath, index.optionalParameters)
      }
    }
  }
}

MigrationManager4To5.prototype.createNewTables = function() {
  return new Promise((resolve, reject)=>{
    var self = this;

    for (var key in _objectStores) {
      if (!_objectStores.hasOwnProperty(key)) continue;
  
      var obj = _objectStores[key];
      var objStore;
      if (self.db.objectStoreNames.contains(key)) {
        self.updateIndex(obj, key);
        continue;
      }
      objStore = self.db.createObjectStore(key, obj.value);
      if (typeof obj.indexes !== 'undefined') {
        var indexNames = objStore.indexNames;
        /* jshint ignore:start */
        // Ignoring JSHint error : Functions declared within loops referencing an outer scoped variable may lead to confusing semantics.
        obj.indexes.forEach(function(element, index, array) {
          if (indexNames.contains(element.name)) {
            var myIndex = objStore.index(element.name);
            var currentKeyPath = myIndex.keyPath;
            var updateIndex = currentKeyPath != element.keyPath;
  
            if (element.optionalParameters) {
              updateIndex =
                element.optionalParameters.unique != element.optionalParameters.unique || updateIndex;
            }
            if (updateIndex) {
              objStore.deleteIndex(element.name);
              objStore.createIndex(element.name, element.keyPath, element.optionalParameters);
            }
          } else {
            objStore.createIndex(element.name, element.keyPath, element.optionalParameters);
          }
        });
        /* jshint ignore:end */
      }
    }
    resolve()
  })
};

MigrationManager4To5.prototype.migrateToLibraryDataStore = async function(row) {
  var self = this;
  for (var key in row.books) {
    if (!row.books.hasOwnProperty(key)) {
      continue;
    }
    var book = row.books[key];
    var result = {};
    result.isbn = key;
    result.uuid = row.uuid;
    result.uuid_isbn = result.uuid + '|' + result.isbn;
    result.downloaded = !!book.downloaded;
    if (book.licenseId) {
      continue;
    }
    applyMeta(result);
    await self.transaction.objectStore('LibraryDataStore').add(result);
  }
};

MigrationManager4To5.prototype.migrateToBookDataStore = async function(row) {
  var self = this;
  for (var key in row.books) {
    if (!row.books.hasOwnProperty(key)) {
      continue;
    }
    var book = row.books[key];
    if (!book.downloaded) {
      continue;
    }
    var result = {};
    result.isbn = key;
    result.cover = book.cover;    
    if (book.metadata) {
      result.metadata = book.metadata;
    }
    applyMeta(result);
    await self.transaction.objectStore('BookDataStore').add(result);
  }
};

MigrationManager4To5.prototype.migrateToLicenseStore = async function(row) {
  var self = this;
  for (var key in row.books) {
    if (!row.books.hasOwnProperty(key)) {
      continue;
    }
    var book = row.books[key];
    var result = {};
    result.isbn = key;
    result.uuid = row.uuid;
    result.uuid_isbn = result.uuid + '|' + result.isbn;
    if (book.license) {
      result.license = book.license;
    } else {
      continue;
    }
    result.id = row.id;
    applyMeta(result);
    await self.transaction.objectStore('LicenseStore').add(result);
  }
};

MigrationManager4To5.prototype.migrateOldData = function() {
  return new Promise((resolve,reject)=>{
    var self = this;

    var libraryStore4 = self.transaction.objectStore('LibraryStore');
  
    var request = libraryStore4.openCursor();
    request.onsuccess = async function(evt) {
      var cursor = evt.target.result;
      if (cursor) {
        await self.migrateToLibraryDataStore(cursor.value);
        await self.migrateToBookDataStore(cursor.value);
        await self.migrateToLicenseStore(cursor.value);
        cursor.continue();
      }
      resolve();
    };
    request.onerror = function(evt) {
      console.log(evt.target.error);
      reject();
    };
  })


};

MigrationManager4To5.prototype.migrate = async function() {
  var self = this;
  await self.ensureTables();
  await self.createNewTables();
  await self.migrateOldData();
  console.debug('4 to 5 done');
};

export default MigrationManager4To5;
