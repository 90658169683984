var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"overlay-view\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"overlayID") || (depth0 != null ? lookupProperty(depth0,"overlayID") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"overlayID","hash":{},"data":data,"loc":{"start":{"line":1,"column":30},"end":{"line":1,"column":43}}}) : helper)))
    + "\" role=\"dialog\">\n    <nav class=\"navbar navbar-default navbar-fixed-top contains-heading\">\n        <div class=\"container-fluid\">\n            <!-- <div class=\"portal-logo\" role=\"img\" aria-label=\"Axis360 Logo\"> </div> -->\n            <h1>"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":25}}}) : helper)))
    + "\n            </h1>\n            <button type=\"button\" class=\"close\" aria-label=\"Close Help\">\n                <span aria-hidden=\"true\" class=\"icon icon-close\"></span>\n            </button>\n        </div>\n    </nav>\n    <div class=\"page-view "
    + alias4(((helper = (helper = lookupProperty(helpers,"cssClass") || (depth0 != null ? lookupProperty(depth0,"cssClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cssClass","hash":{},"data":data,"loc":{"start":{"line":12,"column":26},"end":{"line":12,"column":38}}}) : helper)))
    + "\">\n            "
    + alias4(((helper = (helper = lookupProperty(helpers,"content") || (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"content","hash":{},"data":data,"loc":{"start":{"line":13,"column":12},"end":{"line":13,"column":23}}}) : helper)))
    + "\n    </div>\n</div>";
},"useData":true});