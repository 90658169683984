import $ from 'jquery';
import _ from 'underscore';
import Menu from '../Menu';

var listenerSet = false;

var textSelectionEvent;
var selectedHighlightType;

var InlineMenu = function() {
  Menu.apply(this, arguments);
  var self = this;
};

InlineMenu.prototype = Object.create(Menu.prototype);

//Public API

InlineMenu.prototype.selectionContextMenuHandler = function() {
  console.warn('Using the default selection handler. Are you sure this is correct?');
};

InlineMenu.prototype.textSelectionEvent = function(textSelectionEvent_) {
  if (!!textSelectionEvent_) {
    textSelectionEvent = textSelectionEvent_;
  } else {
    return textSelectionEvent;
  }
};

InlineMenu.prototype.selectedHighlightType = function(selectedHighlightType_) {
  if (!!selectedHighlightType_) {
    selectedHighlightType = selectedHighlightType_;
  } else {
    return selectedHighlightType;
  }
};

InlineMenu.prototype.centerMenuToSelection = function() {
  var $el = this.popup().$el;
  var _document = textSelectionEvent.document;
  if (!_document) return;
  var iframe = _document.defaultView.frameElement;

  // Extract the scale from the scaler element (used in one_page_view for fixed layout books)
  var scale = 1.0;
  if (iframe.parentElement && iframe.parentElement.id === 'scaler') {
    var transform = iframe.parentElement.style.transform;
    if (transform) {
      var match = transform.match(/scale\((.*)\)/);
      if (match && match[1]) {
        scale = Number(match[1]);
      }
    }
  }

  var selectionRange = textSelectionEvent.range;
  var clientRects = selectionRange.getClientRects();

  var scaledTop = _.first(clientRects).top * scale;
  var scaledBottom = _.last(clientRects).bottom * scale;
  var scaledLeft = selectionRange.getBoundingClientRect().left * scale;

  var calculatedWidth = selectionRange.getBoundingClientRect().width * scale;
  if (calculatedWidth > iframe.clientWidth) {
    calculatedWidth = iframe.clientWidth;
  }

  var bottomPadding = 20;
  var topPadding = textSelectionEvent.isMobile ? 36 : 0;

  // Context menu height
  var contextMenuClientHeight = $el.height();
  var contextMenuClientWidth = $el.width();

  // very bottom of the selection area + padding
  var calculatedBottom = scaledBottom + bottomPadding;
  var calculatedTop = scaledTop - bottomPadding - topPadding - contextMenuClientHeight;

  // top position of the menu
  //var calculatedY = calculatedBottom;
  var calculatedY = calculatedTop;
  //if (calculatedY > iframe.clientHeight - contextMenuClientHeight) {
  if (calculatedY < 0) {
    //calculatedY = calculatedTop;
    calculatedY = calculatedBottom;
  }

  var calculatedLeft = scaledLeft;

  var calculatedX = calculatedLeft + Math.floor(calculatedWidth / 2 - contextMenuClientWidth / 2);

  if (calculatedLeft + contextMenuClientWidth > iframe.clientWidth) {
    calculatedX = iframe.clientWidth - contextMenuClientWidth;
  }
  if (calculatedX < 0) {
    calculatedX = 0;
  }
  //} else {
  //    var calculatedX = textSelectionEvent.coordinates.end.clientX;
  //    var calculatedY = textSelectionEvent.coordinates.end.clientY;
  //}

  var iframe_offset = $(iframe).offset();

  var convertedCoords = {
    x: calculatedX + iframe_offset.left,
    y: calculatedY + iframe_offset.top,
  };

  // if the inline menu is out of viewport
  let bookFrameWidth = $('#book-frame')[0].offsetWidth;
  let leftPadding = $('#viewport-content').offset().left;
  if (convertedCoords.x > bookFrameWidth - contextMenuClientWidth) {
    convertedCoords.x = bookFrameWidth - contextMenuClientWidth;
  }
  convertedCoords.x = (convertedCoords.x > 0) ? convertedCoords.x : leftPadding;

  $el.css('left', convertedCoords.x + 'px');
  $el.css('top', convertedCoords.y + 'px');
};

export default InlineMenu;
