var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\naria-label=\"Next\"\n\naccesskey=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"keyboard") : depth0)) != null ? lookupProperty(stack1,"accesskeys") : stack1)) != null ? lookupProperty(stack1,"PagePreviousSR") : stack1), depth0))
    + "\"\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\naria-label=\"Previous\"\n\naccesskey=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"keyboard") : depth0)) != null ? lookupProperty(stack1,"accesskeys") : stack1)) != null ? lookupProperty(stack1,"PagePreviousSR") : stack1), depth0))
    + "\"\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\naria-label=\"Previous\"\n\naccesskey=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"keyboard") : depth0)) != null ? lookupProperty(stack1,"accesskeys") : stack1)) != null ? lookupProperty(stack1,"PageNextSR") : stack1), depth0))
    + "\"\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\naria-label=\"Next\"\n\naccesskey=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"keyboard") : depth0)) != null ? lookupProperty(stack1,"accesskeys") : stack1)) != null ? lookupProperty(stack1,"PageNextSR") : stack1), depth0))
    + "\"\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", alias6=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<!-- Left page -->\n<button id=\"left-page-btn\" accesskey=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"keyboard") : depth0)) != null ? lookupProperty(stack1,"accesskeys") : stack1)) != null ? lookupProperty(stack1,"PagePreviousAlt") : stack1), depth0))
    + "\"  class=\"page-switch-overlay-icon btn-default page-nav-button nav-left\"\n\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"pageProgressionDirectionIsRTL") || (depth0 != null ? lookupProperty(depth0,"pageProgressionDirectionIsRTL") : depth0)) != null ? helper : alias4),(options={"name":"pageProgressionDirectionIsRTL","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":0},"end":{"line":9,"column":34}}}),(typeof helper === alias5 ? helper.call(alias3,options) : helper));
  if (!lookupProperty(helpers,"pageProgressionDirectionIsRTL")) { stack1 = alias6.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"pageProgressionDirectionIsRTL") || (depth0 != null ? lookupProperty(depth0,"pageProgressionDirectionIsRTL") : depth0)) != null ? helper : alias4),(options={"name":"pageProgressionDirectionIsRTL","hash":{},"fn":container.noop,"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":11,"column":0},"end":{"line":16,"column":34}}}),(typeof helper === alias5 ? helper.call(alias3,options) : helper));
  if (!lookupProperty(helpers,"pageProgressionDirectionIsRTL")) { stack1 = alias6.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n>\n<!-- img aria-hidden=\"true\" src=\"images/pagination1.svg\" -->\n<span class=\"icon  icon-arrow-left\" aria-hidden=\"true\"></span>\n</button>\n  \n<!-- Right page -->\n<button id=\"right-page-btn\"  accesskey=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"keyboard") : depth0)) != null ? lookupProperty(stack1,"accesskeys") : stack1)) != null ? lookupProperty(stack1,"PageNextAlt") : stack1), depth0))
    + "\" class=\"page-switch-overlay-icon btn-default page-nav-button nav-right\"\n\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"pageProgressionDirectionIsRTL") || (depth0 != null ? lookupProperty(depth0,"pageProgressionDirectionIsRTL") : depth0)) != null ? helper : alias4),(options={"name":"pageProgressionDirectionIsRTL","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":0},"end":{"line":31,"column":34}}}),(typeof helper === alias5 ? helper.call(alias3,options) : helper));
  if (!lookupProperty(helpers,"pageProgressionDirectionIsRTL")) { stack1 = alias6.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"pageProgressionDirectionIsRTL") || (depth0 != null ? lookupProperty(depth0,"pageProgressionDirectionIsRTL") : depth0)) != null ? helper : alias4),(options={"name":"pageProgressionDirectionIsRTL","hash":{},"fn":container.noop,"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":33,"column":0},"end":{"line":38,"column":34}}}),(typeof helper === alias5 ? helper.call(alias3,options) : helper));
  if (!lookupProperty(helpers,"pageProgressionDirectionIsRTL")) { stack1 = alias6.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n>\n<!-- img aria-hidden=\"true\" src=\"images/pagination1.svg\" -->\n<span class=\"icon  icon-arrow-left\" aria-hidden=\"true\"></span>\n</button>\n";
},"useData":true});