var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"container-fluid\">\n\n    <div class=\"navbar-button-group navbar-left\">\n        <button class=\"navbar-btn menu-button\" type=\"button\" id=\"btnToggleMainMenu\"\n                data-static-open-popup=\".app-main-menu\">\n            <span class=\"icon icon-menu\" aria-hidden=\"true\"></span>\n        </button>\n        <!-- <button id=\"aboutButt1\" tabindex=\"1\" type=\"button\" class=\"btn icon-logo\" data-toggle=\"modal\"\n                 data-target=\"#about-dialog\" title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"about") : stack1), depth0))
    + "\" aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"about") : stack1), depth0))
    + "\"></button>-->\n    </div>\n\n    <!--<div class=\"portal-logo\" role=\"img\" aria-label=\"Axis360 Logo\">\n    </div>-->\n\n    <div class=\"navbar-button-group navbar-right\" role=\"region\" aria-label=\"Tools\">\n\n        <button class=\"navbar-btn\" type=\"button\" id=\"btnToggleFontOptions\"\n                data-static-open-popup=\".app-display-options-menu\">\n            <span class=\"icon icon-display\" aria-hidden=\"true\"></span>\n        </button>\n\n        <!--\n                <button tabindex=\"1\" type=\"button\" class=\"btn icon-thumbnails\" title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"thumbnail_view") : stack1), depth0))
    + "\"\n                        aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"thumbnail_view") : stack1), depth0))
    + "\">\n                    <span class=\"glyphicon glyphicon-th\" aria-hidden=\"true\"></span>\n                </button>\n                <button tabindex=\"1\" type=\"button\" class=\"btn icon-list-view\" title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"list_view") : stack1), depth0))
    + "\"\n                        aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"list_view") : stack1), depth0))
    + "\">\n                    <span class=\"glyphicon glyphicon-align-justify\" aria-hidden=\"true\"></span>\n                </button>\n                <button id=\"addbutt\" tabindex=\"1\" type=\"button\" class=\"btn icon-add-epub\" title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_add_book") : stack1), depth0))
    + "\"\n                        aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_add_book") : stack1), depth0))
    + "\" data-toggle=\"modal\" data-target=\"#add-epub-dialog\">\n                    <span class=\"glyphicon glyphicon-plus\" aria-hidden=\"true\"></span>\n                </button>\n                <button id=\"settbutt1\" tabindex=\"1\" type=\"button\" class=\"btn icon-settings\"\n                        title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"settings") : stack1), depth0))
    + " ["
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"keyboard") : depth0)) != null ? lookupProperty(stack1,"ShowSettingsModal") : stack1), depth0))
    + "]\"\n                        aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"settings") : stack1), depth0))
    + " ["
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"keyboard") : depth0)) != null ? lookupProperty(stack1,"ShowSettingsModal") : stack1), depth0))
    + "]\"\n                        accesskey=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"keyboard") : depth0)) != null ? lookupProperty(stack1,"accesskeys") : stack1)) != null ? lookupProperty(stack1,"ShowSettingsModal") : stack1), depth0))
    + "\" data-toggle=\"modal\"\n                        data-target=\"#settings-dialog\">\n                    <span class=\"glyphicon glyphicon-cog\" aria-hidden=\"true\"></span>\n                </button>-->\n    </div>\n</div>";
},"useData":true});