import moduleConfig from './ModuleConfig';
import $ from 'jquery';
import StorageManager from './storage/StaticStorageManager';
import Settings from './storage/Settings';
import libraryManager from './EpubLibraryManager';
import MainMenu from './menus/navbar/MainMenu';
import Strings from './i18n/Strings';
import LibraryNavbar from './templates/library-navbar.hbs';
import LibraryBody from './templates/library-body.hbs';
import EmptyLibrary from './templates/empty-library.hbs';
import LibraryItem from './templates/library-item.hbs';
import Analytics from './analytics/Analytics';
import Keyboard from './Keyboard';
import Helpers from './EpubReaderHelpers';
import Managers from '@axisnow/data/Managers';
import AccessibilityManager from './accessibility/AcessibilityManager';
import AccessibilityJson from './accessibility/accessibility.json';

/* // BH-325 (Removing code related to details-*.html)
    var detailsDialogStr = DetailsDialog({strings: Strings});*/

var mainMenu;
var removeBookId = '';

var heightRule, noCoverRule;
//maxHeightRule

var findHeightRule = function() {
  var styleSheet = document.styleSheets[0];
  var ii = 0;
  var cssRule;
  do {
    if (styleSheet.cssRules) {
      cssRule = styleSheet.cssRules[ii];
    } else {
      cssRule = styleSheet.rules[ii];
    }
    if (cssRule && cssRule.selectorText) {
      if (cssRule.selectorText.toLowerCase() == '.library-item') {
        heightRule = cssRule;
      }
      // else if (cssRule.selectorText.toLowerCase()=='.library-item img') {
      //     maxHeightRule = cssRule;
      // }
      else if (
        cssRule.selectorText.toLowerCase() == 'body:not(.list-view) .library-item .no-cover'
      ) {
        noCoverRule = cssRule;
      }
    }
    ii++;
  } while (cssRule);
};

var setItemHeight = function() {
  if (!heightRule || !noCoverRule) return;

  var medWidth = 2,
    smWidth = 3,
    xsWidth = 4,
    rowHeight = 0,
    imgWidth = 0,
    scale = 1;

  var winWidth = window.innerWidth;

  if (winWidth >= 992) {
    imgWidth = winWidth * (medWidth / 12) - 30;
    rowHeight = 1.33 * imgWidth + 60;
  } else if (winWidth >= 768) {
    imgWidth = winWidth * (smWidth / 12) - 30;
    rowHeight = 1.33 * imgWidth + 60;
  } else {
    imgWidth = winWidth * (xsWidth / 12) - 30;
    rowHeight = 1.33 * imgWidth + 20;
  }
  heightRule.style.height = rowHeight + 'px';
  scale = imgWidth / 300;

  noCoverRule.style.width = imgWidth + 'px';
  noCoverRule.style.height = 1.33 * imgWidth + 'px';
  noCoverRule.style.fontSize = 40 * scale + 'px';
  //maxHeightRule.style.height = 1.33 * imgWidth + 'px';
  //maxHeightRule.style.width = imgWidth + 'px';
};

/* // BH-325 (Removing code related to details-*.html)
    var showDetailsDialog = function(details){
        var bodyStr = DetailsBody({
            data: details,
            strings: Strings
        });

        $('.details-dialog .modal-body').html(bodyStr);
        $('.details-dialog .delete').on('click', function(){
            $('.details-dialog').modal('hide');
            var success = function(){
                libraryManager.retrieveAvailableEpubs(loadLibraryItems);
                Dialogs.closeModal();
            }

            var promptMsg = Strings.i18n_are_you_sure + ' \'' + details.title + '\'';

            Dialogs.showModalPrompt(Strings.delete_dlg_title, promptMsg,
                                    Strings.i18n_delete, Strings.i18n_cancel,
                                    function(){
                                        Dialogs.showModalProgress(Strings.delete_progress_title, '');
                                        Dialogs.updateProgress(100, Messages.PROGRESS_DELETING, details.title, true);
                                        libraryManager.deleteEpubWithId(details.rootDir, success, showError)
                                    });
        });
    }*/

var showError = function(errorCode, data) {
  Dialogs.showError(errorCode, data);
};

/* // BH-325 (Removing code related to details-*.html)
    var loadDetails = function(e){
        var $this = $(this),
            url = $this.attr('data-package'),
            bookRoot = $this.attr('data-root'),
            rootDir = $this.attr('data-root-dir'),
            noCoverBg = $this.attr('data-no-cover');

        $('.details-dialog').remove();

        $('.details-dialog').off('hidden.bs.modal');
        $('.details-dialog').off('shown.bs.modal');

        $('#app-container').append(detailsDialogStr);

        $('#details-dialog').on('hidden.bs.modal', function () {
            Keyboard.scope('library');

            setTimeout(function(){ $this.focus(); }, 50);
        });
        $('#details-dialog').on('shown.bs.modal', function(){
            Keyboard.scope('details');
        });


        $('.details-dialog').modal();

        var retrieveDetails = function(packageUrl) {

            if (!packageUrl || packageUrl.indexOf(".opf") < 0) {
                console.warn("no package path (OPF within zipped EPUB archive?): " + packageUrl);
            }

            libraryManager.retrieveFullEpubDetails(packageUrl, bookRoot, rootDir, noCoverBg, showDetailsDialog, showError);
        };

        console.log("OPF package URL: " + url);
        if (url && url.indexOf(".opf") < 0) {

            var urlContainerXml = url + "META-INF/container.xml";
            $.get(urlContainerXml, function(data){

                if(typeof(data) === "string" ) {
                    var parser = new window.DOMParser;
                    data = parser.parseFromString(data, 'text/xml');
                }
                var $rootfile = $('rootfile', data);
                var rootFilePath = $rootfile.attr('full-path');
                console.log("OPF package path (root-file from container.xml): " + rootFilePath);

                var packageUrl = url + (Helpers.EndsWith(url, "/") ? "" : "/") + rootFilePath;

                console.log("OPF package URL (from container.xml): " + packageUrl);
                retrieveDetails(packageUrl);

            }).fail(function() {
                //console.warn(arguments);
                console.error("FAILED OPF package URL (from container.xml): " + urlContainerXml);
                retrieveDetails(url);
            });
        }
        else {
            retrieveDetails(url);
        }
    }*/

var loadLibraryItems = function(epubs) {
  $('#app-container .page-view.page-library').remove();
  $('#app-container').append(LibraryBody({}));
  if (!epubs.length) {
    $('#app-container .library-items').append(
      EmptyLibrary({ imagePathPrefix: moduleConfig.imagePathPrefix, strings: Strings }),
    );
    // setTimeout(function() {
    //     AccessibilityManager.init(AccessibilityJson, {}, function() {
    //         removeBookId = '';
    //         AccessibilityManager.setFocus(".page-library .empty-message-offline");
    //     })
    // }, 100)
    return;
  }

  var processEpub = function(epubs, count) {
    var epub = epubs[count];
    if (!epub) {
      // count >= epubs.length
      /* // BH-325 (Removing code related to details-*.html)
                $('.details').on('click', loadDetails);
                 */
      return;
    }

    var noCoverBackground =
      moduleConfig.imagePathPrefix + 'images/covers/cover' + ((count % 8) + 1) + '.jpg';
    if (epub.isSubLibraryLink) {
      noCoverBackground = moduleConfig.imagePathPrefix + 'images/covers/cover2.jpg';
    }

    var createLibraryItem = function() {
      // See --COMMENT-- below!
      // if (!epub.isSubLibraryLink && !epub.packagePath) {
      //     console.warn("no epub.packagePath (OPF within zipped EPUB archive?): " + epub.rootUrl);
      //     //console.log(epub);
      // }

      $('.library-items').append(
        LibraryItem({
          count: { n: count + 1, tabindex: count * 2 + 99 },
          epub: epub,
          strings: Strings,
          noCoverBackground: noCoverBackground,
        }),
      );

      processEpub(epubs, ++count);
    };

    if (!epub.isSubLibraryLink && !epub.packagePath) {
      createLibraryItem();

      // --COMMENT--
      // Code below works, but just here to demonstrate how the package OPF path can be resolved whilst populating the library view. Because the HTTP requests for each ebook introduce huge lag, instead we resolve the OPF path on-demand, when user chooses to see the EPUB details / metadata dialog popup (see loadDetails() function above, which itself emits an HTTP request to get the actual OPF file XML payload, via LibraryManager.retrieveFullEpubDetails())
      // $.get(epub.rootUrl + "/META-INF/container.xml", function(data){

      //     if(typeof(data) === "string" ) {
      //         var parser = new window.DOMParser;
      //         data = parser.parseFromString(data, 'text/xml');
      //     }
      //     var $rootfile = $('rootfile', data);
      //     epub.packagePath = $rootfile.attr('full-path');

      //     createLibraryItem();

      // }).fail(function() {
      //     //console.warn(arguments);
      //     createLibraryItem();
      // });
    } else {
      createLibraryItem();
    }
  };
  processEpub(epubs, 0);

  $('.remove-book').click(function(e) {
    var isbn = $(e.currentTarget).data('isbn');
    removeBookId = $(e.currentTarget).data('book-index');
    libraryManager
      .removeBookFromLibrary(isbn)
      .then(function() {
        handleLibraryChange();
      })
      .catch(function() {
        console.error('unable to remove book');
      });
  });

  $('.remove-all-books').on('click', function() {
    return Managers.RemoveAllDownloadedBooks()
      .then(function() {
        handleLibraryChange();
      })
      .catch(function() {
        console.error('Unable to remove all books');
      });
  });
 // setTimeout(function() {
  //     AccessibilityManager.init(AccessibilityJson, {}, function() {
  //         if(removeBookId !== '') {
  //             if($(".page-library .read:eq(" + (parseInt(removeBookId) - 1) + ")").length) {
  //                 AccessibilityManager.setFocus(".page-library .read:eq(" + (parseInt(removeBookId) - 1) + ")");
  //             } else {
  //                 AccessibilityManager.setFocus(".page-library .read:eq(0)");
  //             }
  //             removeBookId = '';
  //         } else {
  //             AccessibilityManager.setFocus(".page-library .read:eq(0)");
  //         }
  //     })
  // }, 100)
};

var saveNewBookAuthDataAsCurrentBook = function(url, callback) {
  if (callback) {
    var isbn = null;
    if (url) {
      var streamStr = '/content/stream/';
      var streamPtr = url.indexOf(streamStr);
      if (streamPtr >= 0) {
        streamPtr += streamStr.length;
        var endPtr = url.indexOf('/', streamPtr);
        if (endPtr == -1) {
          endPtr = url.indexOf('?', streamPtr);
        }
        if (endPtr == -1) {
          endPtr = url.length;
        }
        isbn = url.substring(streamPtr, endPtr);
      }
    }
    if (!isbn) {
      // we should still proceed even if we can't parse the isbn of the book we're opening
      console.error('EpubLibrary.saveNewBookAuthDataAsCurrentBook could not extract ISBN from: "' + url + '"');
      callback();
    } else {
      Managers.license.getAuthData(isbn).then(function(authData) {
        //FSS - copy license auth data from this book back to local storage - D-32207
        Managers.user.setCurrentAuthData(authData);
        callback();
      });
    }
  }
}

var readClick = function(e) {
  var urlParams = Helpers.getURLQueryParams();
  //var ebookURL = urlParams['epub'];
  var libraryURL = urlParams.epubs;
  var embedded = urlParams.embedded;

  var ebookURL = $(this).attr('data-book');
  //Add Book type attribute, so that the offline book will open based on book type from OCL 
  var type = $(this).attr('data-book-type');
  if (ebookURL) {
    // FSS re-saves the authData for the book we're currently opening from Library (OCL) to session storage.
    // This allows the authData for this book to persist for the session in the case user does a Remove All Books
    // which blows away all licenses (and authData) from IndexedDB. This auth data is necessary if doing Return To Book
    // so that we can re-execute a successfull license get (which needs the productTypeId that's stored in authData).
    saveNewBookAuthDataAsCurrentBook(ebookURL, function() {
      var eventPayload = { embedded: embedded, epub: ebookURL, epubs: libraryURL, offlineMode: true,formatResponse:['cbz','epub'] };
      if(type == 'cbz'){
        console.log('Loading CBZ Book..')
        $(window).triggerHandler('readcomics', eventPayload);
      } else {
        $(window).triggerHandler('readepub', eventPayload);
      }
    });
  } else {
    var libURL = $(this).attr('data-library');
    if (libURL) {
      saveNewBookAuthDataAsCurrentBook(ebookURL, function() { // FSS - see comment above for "ebookURL" block
        // TODO: this doesn't work, so we refresh the whole page, bypassing pushState (replaceState is used instead after reload)
        // libraryManager.resetLibraryData();
        // var eventPayload = libURL;
        // $(window).triggerHandler('loadlibrary', eventPayload);

        var URLPATH = window.location
          ? window.location.protocol +
            '//' +
            window.location.hostname +
            (window.location.port ? ':' + window.location.port : '') +
            window.location.pathname
          : 'index.html';

        var url = URLPATH + '?epubs=' + encodeURIComponent(libURL);

        window.location = url;
      });
    } else {
      var linkURL = $(this).attr('data-link');
      if (linkURL) {
        saveNewBookAuthDataAsCurrentBook(ebookURL, function() { // FSS - see comment above for "ebookURL" block
          window.open(linkURL, '_blank');
        });
      }
    }
  }
  return false;
};

var loginDialog = function() {
  $('app-container');
};

var unloadLibraryUI = function() {
  // needed only if access keys can potentially be used to open a book while a dialog is opened, because keyboard.scope() is not accounted for with HTML access keys :(
  /*// BH-325 (Removing code related to Dialogs.js)
        Dialogs.closeModal();
        Dialogs.reset();*/
  $('.modal-backdrop').remove();

  Keyboard.off('library');
  Keyboard.off('settings');

  /* // BH-325 (Removing code related to ReaderSettingsDialog.js / setting-*.html)
        $('#settings-dialog').off('hidden.bs.modal');
        $('#settings-dialog').off('shown.bs.modal');
        */

  /* // BH-325 (Removing code related to about-dialog.html)
        $('#about-dialog').off('hidden.bs.modal');
        $('#about-dialog').off('shown.bs.modal');*/

  /* // BH-325 (Removing code related to add-epub-dialog.html)
        $('#add-epub-dialog').off('hidden.bs.modal');
        $('#add-epub-dialog').off('shown.bs.modal');
        */

  /* // BH-325 (Removing code related to details-*.html)
        $('.details-dialog').off('hidden.bs.modal');
        $('.details-dialog').off('shown.bs.modal');*/

  $(window).off('resize');
  $(document.body).off('click');
  $(window).off('storageReady');
  $('#app-container').attr('style', '');
};

var promptForReplace = function(originalData, replaceCallback, keepBothCallback) {
  Settings.get('replaceByDefault', function(val) {
    if (val === 'true') {
      replaceCallback();
    } else {
      keepBothCallback();
    }
  });
};

var handleLibraryChange = function() {
  /* // BH-325 (Removing code related to Dialogs.js)
        Dialogs.closeModal();*/
  libraryManager.retrieveAvailableEpubs(loadLibraryItems);
};

var importZippedEpub = function(file) {
  /* // BH-325 (Removing code related to Dialogs.js)
        Dialogs.showModalProgress(Strings.import_dlg_title, Strings.import_dlg_message);
         */
  libraryManager.handleZippedEpub({
    file: file,
    overwrite: promptForReplace,
    success: handleLibraryChange,
    progress: function() {} /*Dialogs.updateProgress, BH-325*/,
    error: showError,
  });
};

/* // BH-325 (Removing code related to add-epub-dialog.html)
    var handleFileSelect = function(evt){
        $('#add-epub-dialog').modal('hide');

        var file = evt.target.files[0];
        importZippedEpub(file);
    }*/

/* // BH-325 (Removing code related to add-epub-dialog.html)
    var handleDirSelect = function(evt){
        var files = evt.target.files;
        $('#add-epub-dialog').modal('hide');
        Dialogs.showModalProgress(Strings.import_dlg_title, Strings.import_dlg_message);
        libraryManager.handleDirectoryImport({
            files: files,
            overwrite: promptForReplace,
            success: handleLibraryChange,
            progress: Dialogs.updateProgress,
            error: showError
        });
    }*/

/* // BH-325 (Removing code related to add-epub-dialog.html)
    var handleUrlSelect = function(){
        var url = $('#url-upload').val();
        $('#add-epub-dialog').modal('hide');
        Dialogs.showModalProgress(Strings.import_dlg_title, Strings.import_dlg_message);
        libraryManager.handleUrlImport({
            url: url,
            overwrite: promptForReplace,
            success: handleLibraryChange,
            progress: Dialogs.updateProgress,
            error: showError
        });
    }*/

var importEpub = function(ebook) {
  // TODO: also allow import of URL and directory select
  // See libraryManager.canHandleUrl() + handleUrlSelect()
  // See libraryManager.canHandleDirectory() + handleDirSelect()

  if (!window.Blob || !(ebook instanceof Blob)) return;
  if (!window.File || !(ebook instanceof File)) return;

  importZippedEpub(ebook);
};

var doMigration = function() {
  /* // BH-325 (Removing code related to Dialogs.js)
        Dialogs.showModalProgress(Strings.migrate_dlg_title, Strings.migrate_dlg_message);*/
  libraryManager.handleMigration({
    success: function() {
      Settings.put('needsMigration', false, $.noop);
      handleLibraryChange();
    },
    progress: function() {} /*Dialogs.updateProgress, BH-325*/,
    error: showError,
  });
};

var loadLibraryUI = function() {
  mainMenu = new MainMenu();
  mainMenu.toc.clear();
  mainMenu.getButton('download').hide();
  mainMenu.getButton('offlineLibrary').hide();
  mainMenu.getButton('goToPage').hide();
  mainMenu.getButton('closeBook').hide();

  /* // BH-325 (Removing code related to Dialogs.js)
        Dialogs.reset();*/

  Keyboard.scope('library');

  Analytics.trackView('/library');
  var $appContainer = $('#app-container');
  $appContainer.empty();

  /* // BH-325 (Removing code related to ReaderSettingsDialog.js / setting-*.html)
        SettingsDialog.initDialog();*/

  /* // BH-325 (Removing code related to add-epub-dialog.html)
        $appContainer.append(AddEpubDialog({
            canHandleUrl : libraryManager.canHandleUrl(),
            canHandleDirectory : libraryManager.canHandleDirectory(),
            strings: Strings
        }));*/

  //$appContainer.append(Axis360LoginDialog({
  //}));

  // Versioning.getVersioningInfo(function(version) {
  /* // BH-325 (Removing code related to about-dialog.html)
            $appContainer.append(AboutDialog({imagePathPrefix: moduleConfig.imagePathPrefix, strings: Strings, dateTimeString: version.dateTimeString, viewerJs: version.readiumJsViewer, readiumJs: version.readiumJs, sharedJs: version.readiumSharedJs, cfiJs: version.readiumCfiJs}));
            */
  // });

  /* // BH-325 (Removing code related to about-dialog.html)
        $('#about-dialog').on('hidden.bs.modal', function () {
            Keyboard.scope('library');

            setTimeout(function(){ $("#aboutButt1").focus(); }, 50);
        });
        $('#about-dialog').on('shown.bs.modal', function(){
            Keyboard.scope('about');
        });*/

  /* // BH-325 (Removing code related to add-epub-dialog.html)
        $('#add-epub-dialog').on('hidden.bs.modal', function () {
            Keyboard.scope('library');

            setTimeout(function(){ $("#addbutt").focus(); }, 50);
        });
        $('#add-epub-dialog').on('shown.bs.modal', function(){
            Keyboard.scope('add');

            $('#add-epub-dialog input').val('');

            setTimeout(function(){ $('#closeAddEpubCross')[0].focus(); }, 1000);
        });
        $('#url-upload').on('keyup', function(){
            var val = $(this).val();
            if (val && val.length){
                $('#add-epub-dialog .add-book').prop('disabled', false);
            }
            else{
                $('#add-epub-dialog .add-book').prop('disabled', true);
            }
        });
        $('.add-book').on('click', handleUrlSelect);
        */
  // BH-379 Updating selector to select application navbar which is 'nav#app-navbar'
  $('header#app-navbar').empty();
  //$('nav').attr("aria-label", Strings.i18n_toolbar);
  $('header#app-navbar').append(
    LibraryNavbar({ strings: Strings, /*dialogs: Dialogs, BH-325*/ keyboard: Keyboard }),
  );
  $('#btnToggleFontOptions,#btnToggleMainMenu').hide();
  $('.icon-list-view').on('click', function() {
    $(document.body).addClass('list-view');
    setTimeout(function() {
      $('.icon-thumbnails')[0].focus();
    }, 50);
  });
  $('.icon-thumbnails').on('click', function() {
    $(document.body).removeClass('list-view');
    setTimeout(function() {
      $('.icon-list-view')[0].focus();
    }, 50);
  });
  findHeightRule();
  setItemHeight();
  StorageManager.initStorage(function() {
    libraryManager.retrieveAvailableEpubs(loadLibraryItems);
  }, showError);

  /* // BH-325 (Removing code related to ReaderSettingsDialog.js / setting-*.html)
        Keyboard.on(Keyboard.ShowSettingsModal, 'library', function(){$('#settings-dialog').modal("show");});*/

  $(window).trigger('libraryUIReady');
  $(window).on('resize', setItemHeight);

  var setAppSize = function() {
    var appHeight = $(document.body).height() - $('#app-container')[0].offsetTop;
    $('#app-container').height(appHeight);
  };
  $(window).on('resize', setAppSize);
  $('#app-container').css('overflowY', 'auto');

  setAppSize();
  $(document.body).on('click', '.read', readClick);

  /* // BH-325 (Removing code related to add-epub-dialog.html)
        $('#epub-upload').on('change', handleFileSelect); */

  /* // BH-325 (Removing code related to add-epub-dialog.html)
        $('#dir-upload').on('change', handleDirSelect);*/

  /* // BH-325 (Removing code related to ReaderSettingsDialog.js / setting-*.html)
        $('#settings-dialog').on('hidden.bs.modal', function () {

            Keyboard.scope('library');

            setTimeout(function(){ $("#settbutt1").focus(); }, 50);

            $("#buttSave").removeAttr("accesskey");
            $("#buttClose").removeAttr("accesskey");
        });
        $('#settings-dialog').on('shown.bs.modal', function () {

            Keyboard.scope('settings');

            $("#buttSave").attr("accesskey", Keyboard.accesskeys.SettingsModalSave);
            $("#buttClose").attr("accesskey", Keyboard.accesskeys.SettingsModalClose);
        });*/

  Keyboard.on(Keyboard.HidePopups, 'library', function() {
    mainMenu.$el.hide();
  });

  //async in Chrome
  Settings.get('needsMigration', function(needsMigration) {
    if (needsMigration) {
      doMigration();
    }
  });
};

var applyKeyboardSettingsAndLoadUi = function(data) {
  if (data && data.epubs && typeof data.epubs == 'string') {
    // this is normally init'ed at page launch using the "epubs" URL GET query parameter,
    // but needs manually setting when using pushState() to refresh the page contents with a different library source
    moduleConfig.epubLibraryPath = data.epubs;
  }

  // override current scheme with user options
  Settings.get('reader', function(json) {
    Keyboard.applySettings(json);

    var epubLibraryPath = moduleConfig.epubLibraryPath
      ? StorageManager.getPathUrl(moduleConfig.epubLibraryPath)
      : StorageManager.getPathUrl('/epub_library.json');

    window.sessionStorage.setItem('epubLibraryPath', epubLibraryPath);

    loadLibraryUI();

    if (data && data.importEPUB) {
      // File/Blob
      importEpub(data.importEPUB);
    }
  });
};
window.setReplaceByDefault = function(replace) {
  Settings.put('replaceByDefault', String(replace));
};

var setTitle = function(branding) {
  document.title = branding;
  document.title = 'Offline Content Library - ' + branding;
};
export default {
  loadUI: applyKeyboardSettingsAndLoadUi,
  unloadUI: unloadLibraryUI,
  importEpub: importEpub,
  setTitle: setTitle,
  handleLibraryChange: handleLibraryChange,
};
