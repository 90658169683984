function Semaphore(max) {
  var counter = 0;
  var waiting = [];
  var take = function() {
    if (waiting.length > 0 && counter < max){
      counter++;
      let promise = waiting.shift();
      console.log(`${promise.name} proceeds`)
      promise.resolve();
    }
  }
  
  this.acquire = function(name) {
    if(counter < max) {
      counter++
      return new Promise(resolve => {
        console.log(`${name} proceeds`)
        resolve();
      });
    } else {
      return new Promise((resolve, err) => {
        console.log(`${name} wait listed`)
        waiting.push({'name': name, resolve: resolve, err: err});
      });
    }
  }
    
  this.release = function(name) {
   console.log(`${name} released`)
   counter--;
   take();
  }
  
  this.purge = function() {
    let unresolved = waiting.length;
  
    for (let i = 0; i < unresolved; i++) {
      waiting[i].err('Task has been purged.');
    }
  
    counter = 0;
    waiting = [];
    
    return unresolved;
  }
  this.getWaitList = function() {
    return waiting
  }
}

export default Semaphore