import utils from '@axisnow/util/utils';
import RemoteSearch from './RemoteSearch';
import OfflineSearch from './OfflineSearch';

var _search;
function SearchManager(isbn) {
  utils
    .isOnline()
    .then(function() {
      _search = new RemoteSearch(isbn);
    })
    .catch(function() {
      _search = new OfflineSearch(isbn);
    });
}
SearchManager.prototype.searchByWord = function(word) {
  return _search.byWord(word);
};
export default SearchManager;
