import Popup from './Popup';
import PopupMenuHeaderContentsTemplate from '../templates/popup/popup-menu-header-contents-with-button.hbs';

var PopupMenuWithHeaderButton = Popup.prototype.extends(function(
  popupNumber,
  type,
  headerData,
  options,
) {
  options = this.appendPopup(options);
  this.$header = this.getHeader()
    .addClass('colored')
    .append(PopupMenuHeaderContentsTemplate(headerData));
  this.$header.prependTo(this.$el);
  this.$el
    .addClass('popup-sticky popup-right popup-navbar popup-scrollable app-bookmark-menu')
    .addClass(this.popupCountClass);
  if (this.popupId) {
    this.$el.attr('id', this.popupId);
  }
  //this.$header.prependTo(this.$el);
});
export default PopupMenuWithHeaderButton;
