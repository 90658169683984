import $ from 'jquery';
import _ from 'underscore';
import PopupFactory from './popup/PopupFactory';
import TTSSettingsMenu from './templates/tts-settings-menu.hbs';
import Keyboard from './Keyboard';
import tts from './tts/tts';
import Promise from 'bluebird';
import AccessibilityJsonWrapper from './AccessibilityJsonWrapper';
import utils from '@axisnow/util/utils';

var ttsSettingsPopup;

function initTTSUI() {
  if (tts.isSupported()) {
    return tts
      .getVoices()
      .then(function(voices) {
        if (voices && voices.length > 0) {
          tts.loadSettings();

          var speedArray = [
            { name: 'Slower', value: -1 },
            { name: 'Normal', value: 0 },
            { name: 'Faster', value: 1 },
            { name: 'Fastest', value: 2 },
          ]; // hardcoded values for now
          var highlightingArray = [
            { name: 'Words', value: 0 },
            { name: 'Sentences', value: 1 },
            { name: 'None', value: 2 },
          ]; // hardcoded values for now
          var voicesJson = AccessibilityJsonWrapper.getJson().elemSeq.tts_options_popup.children;
          // We need to set up the voices dynamically inside the AccessibiliyJson becase we don't know at compile time what voices are available.
          // Hack ? Yes! YOU find another way to dynamically update the Accessibilitymanager json and tell me! Please.
          _.forEach(voices, function(v, idx) {
            v.id = idx;
            voicesJson['tts-voice-id-' + idx] = {
              selector: '#tts-voice-id-' + idx,
              attributes: {
                role: 'button',
                'aria-label': {
                  en: '<>',
                },
              },
              data: {
                states: {
                  statesList: {
                    '0': {
                      en: 'change voice to ' + v.name,
                    },
                    '1': {
                      en: 'voice ' + v.name + ' is selected.',
                    },
                  },
                },
              },
            };
          });
          if (!_.find(voices, { localService: true })) {
            // if we don't have any word-highlighting capable voices, remove the option
            highlightingArray = [{ name: 'Sentences', value: 1 }, { name: 'None', value: 2 }];
          }

          if (!ttsSettingsPopup) {
            ttsSettingsPopup = PopupFactory.create(PopupFactory.TYPE.NAVBAR_MENU, 'Audio Options', {
              id: 'ttsSettings',
              cssClass: 'size height unit-auto app-tts-settings-menu ',
            });
            var $ttsSettingsContainerLayoutContent = $(
              TTSSettingsMenu({
                voices: voices.map(voice => {
                  return {
                    id: voice.id,
                    name: voice.name,
                    localService: voice.localService,
                    voiceURI: voice.voiceURI,
                    lang: voice.lang
                  }
                }),
                speeds: speedArray,
                highlighting: highlightingArray,
              }),
            );
            ttsSettingsPopup.addContainerLayout($ttsSettingsContainerLayoutContent);
          }

          $('#btnTTSPlay').on('click', function() {
            $('#navbar-overflow-overlay').css('display', 'none');
            tts.play();
            $('#btnTTSPause').addClass('border-radius-updated'); //border-radius updated
            $('.navbar-overflow-menu').css('display', 'none'); 
            $('#navbar-overflow-overlay').css('display', 'none');
            utils.updateHighligtedIcon('btnTTSPlay') //update highlighted icon
          });

          $('#btnTTSPause').on('click', function() {
            tts.pause();
            $('#btnTTSPause').removeClass('border-radius-updated');//border-radius updated
            $('.navbar-overflow-menu').css('display', 'none'); 
            $('#navbar-overflow-overlay').css('display', 'none');
            utils.updateHighligtedIcon('btnTTSPause') //update highlighted icon
          });

          var setHeaderPlayState = function(playTruePauseFalse) {
            var playButton = $('#btnTTSPlay');
            var pauseButton = $('#btnTTSPause');
            togglePlayState(playButton, pauseButton, playTruePauseFalse);
          };
          var setHighlightPlayState = function(playTruePauseFalse) {
            var playButton = $('#btnTTSPlaySelection');
            var pauseButton = $('#btnTTSPauseSelection');
            togglePlayState(playButton, pauseButton, playTruePauseFalse);
          };

          var togglePlayState = function(playButton, pauseButton, playTruePauseFalse) {
            if (playTruePauseFalse) {
              var hasFocus = document.activeElement === pauseButton[0];
              
              playButton.removeClass('hidden');
              pauseButton.addClass('hidden');
              if (hasFocus) {
                playButton.focus();
              }
            } else {
              var hasFocus = document.activeElement === playButton[0];

              playButton.addClass('hidden');
              pauseButton.removeClass('hidden');
              if (hasFocus) {
                pauseButton.focus();
              }
            }
          }

          tts.onTTSTextAvailability(function(hasTTSText) {
            if (hasTTSText) {
              setHeaderPlayState(true);
              setHighlightPlayState(true);
              $('#btnToggleTTSSettings').removeClass('hidden');
              $('#btnToggleBookmark').removeClass('border-radius-updated');
            } else {
              $('#btnTTSPlay').addClass('hidden');
              $('#btnTTSPause').addClass('hidden');
              $('#btnTTSPlaySelection').addClass('hidden');
              $('#btnTTSPauseSelection').addClass('hidden');
              $('#btnToggleTTSSettings').addClass('hidden');
              $('#btnToggleBookmark').addClass('border-radius-updated');
            }
          });

          tts.onTTSStart(function(playingSelection) {
            if (tts.hasTTSText()) {
              if (playingSelection) {
                setHighlightPlayState(false);
                setHeaderPlayState(true);
              } else {
                setHeaderPlayState(false);
                setHighlightPlayState(true);
              }
            }
          });

          tts.onTTSPause(function(playingSelection) {
            if (tts.hasTTSText()) {
              setHighlightPlayState(true);
              setHeaderPlayState(true);
            }
          });

          tts.onTTSStop(function(playingSelection) {
            if (tts.hasTTSText()) {
              setHighlightPlayState(true);
              setHeaderPlayState(true);
            }
          });
        }
      })
      .catch(function() {
        // ignore TTS init failure
      });
  } else {
    return Promise.resolve();
  }
}

function initTTSSettingsUI() {
  /*********** TTS Settings ********************/
  if (tts.isSupported()) {
    return tts
      .getVoices()
      .then(function(voices) {
        if (voices && voices.length > 0) {
          var lastWordMode;
          var setupVoiceScrollableList = function(isChanged) {
            if (lastWordMode == undefined || lastWordMode != (tts.getHighlightMode() == 0) || isChanged) {
              lastWordMode = tts.getHighlightMode() == 0;
              var ttsVoicesOuter = $('.tts-voices-outer');
              if (ttsVoicesOuter) {
                // size menu according to number of voices, scroll current selection into visibility
                ttsVoicesOuter.css('height', '100%'); // reset (will be set dynamically after timeout)
                setTimeout(function() {
                  if (!tts.isIOSMode() && !tts.isAndroidMode()) {
                    // set up scroll size for voices, could not find a clean css-only way to make voices scrollable
                    var ttsVoicesBounds = ttsVoicesOuter[0].getBoundingClientRect();
                    ttsVoicesOuter.css(
                      'height',
                      Math.min(window.innerHeight - ttsVoicesBounds.y, ttsVoicesBounds.height) + 'px',
                    );
                  }
                  var $radioGroup = ttsSettingsPopup.$el.find('.voice-selector');
                  // now scroll selected voice into view
                  if (tts.getVoice()) {
                    var ipt = $radioGroup.find('input[value="' + tts.getVoice().name + '"]');
                    if (ipt && ipt.length > 0) {
                      var li = ipt.parents('li');
                      if (li && li.length > 0) {
                        li[0].scrollIntoView();
                      }
                    }
                  }
                }, 200);
              }
            }
          };
          if (voices.length > 1) {
            $('.tts-voice-list').removeClass('hidden');
          }
          $('#btnToggleTTSSettings').on('click', function() {
            //Remove all active classes
            $('.navbar-btn').removeClass('active');
            $('.select_wrap').removeClass('active');
            utils.updateHighligtedIcon('btnToggleTTSSettings');             
            if (ttsSettingsPopup.$el.is(':visible')) {
              ttsSettingsPopup.hide();
              $('#btnToggleTTSSettings span:first').attr('class', 'icon icon-icon-tts-settings');
            } else {
              // preset selections in controls
              lastWordMode = undefined;
              updateVoiceSelection();
              updateSpeedSelection();
              updateHighlightModes();
              updateVoicesBasedOnWordHighlighting(tts.getHighlightMode() == 0); // update once on init

              $('.popup:visible').hide();
              $('#navbar-overflow-overlay').css('display', 'none');
              $('.navbar-overflow-menu').css('display', 'none');
              $('#btnToggleTTSSettings span:first').attr('class', 'icon icon-active-tts-settings');
              ttsSettingsPopup.show();
              $(this).addClass('active');
            }
            setupVoiceScrollableList();
          });

          // Keyboard.on(Keyboard.TTSSettingsMenu, 'reader', function () {
          //     triggerMenuOpener('#btnToggleTTSSettings');
          // });

          // speeds
          var $ttsSettingsSpeedRadioGroup = ttsSettingsPopup.$el.find('.speed-selector');
          var updateSpeedSelection = function() {
            if (tts.getRate() >= -5 && tts.getRate() <= 5) {
              var ipt1 = $ttsSettingsSpeedRadioGroup.find('input[value="' + tts.getRate() + '"]');
              if (ipt1 && ipt1.length > 0) {
                ipt1[0].checked = true;
              }
            }
          };

          // highlight modes
          var $ttsSettingsHighlightingGroup = ttsSettingsPopup.$el.find('.highlighting-selector');
          var updateHighlightModes = function() {
            if (tts.getHighlightMode() >= 0 && tts.getHighlightMode() <= 2) {
              $ttsSettingsHighlightingGroup.find('.tts-highlight-mode').removeClass('active');
              var ipt2 = $ttsSettingsHighlightingGroup.find(
                '#tts-highlight-mode-' + tts.getHighlightMode(),
              );
              if (ipt2 && ipt2.length > 0) {
                ipt2.addClass('active');
              }
            }
          };

          // voices
          var $ttsSettingsVoiceRadioGroup = ttsSettingsPopup.$el.find('.voice-selector');
          var updateVoiceSelection = function() {
            if (tts.getVoice()) {
              var ipt3 = $ttsSettingsVoiceRadioGroup.find(
                'input[value="' + tts.getVoice().name + '"]',
              );
              if (ipt3 && ipt3.length > 0) {
                ipt3[0].checked = true;
              }
            }
          };

          var hideNonlocalVoicesAndUpdateSelection = function () {
            $('.tts-word-capable-false').addClass('hidden');
            if (!tts.getVoice().localService) {
              // if current voice can't handle word highlighting, find first that can
              tts.setVoiceByName(_.find(voices, { localService: true }).name);
              updateVoiceSelection();
            }
          };

          var updateVoicesBasedOnWordHighlighting = function(isWordMode, isChanged) {
            utils.isOnline().then(function() {
              if (isWordMode) {
                hideNonlocalVoicesAndUpdateSelection();
            } else {
              $('.tts-word-capable-false').removeClass('hidden');
            }
            }).catch(function() {
              hideNonlocalVoicesAndUpdateSelection();
            }).then(function () {
              setupVoiceScrollableList(isChanged); // update scrolling area for voices
            })            
          };

          $(window).on('online offline', function() {
            updateVoicesBasedOnWordHighlighting(tts.getHighlightMode() == 0, true);
          });

          // set click handlers
          var setSpeedTo = function(speed) {
            if (speed >= -5 && speed <= 5) {
              tts.setRate(speed);
              tts.changedSettings();
            }
          };
          $ttsSettingsSpeedRadioGroup.off('click').on('click', function(e) {
            setSpeedTo(e.target.value);
          });
          // ENTER key handler
          $ttsSettingsSpeedRadioGroup.keypress(function(e) {
            var key = e.which;
            if (key == 13) {
              setSpeedTo($(e.target).data('value'));
              // $ttsSettingsSpeedRadioGroup.find('label').each(function(index, element) {
              //   AccessibilityManager.toggleState(element, 0);
              // });
              // AccessibilityManager.toggleState(e.target, 1);
              updateSpeedSelection();
            }
          });

          for (var num = 0; num <= 2; num++) {
            var el = $ttsSettingsHighlightingGroup.find('#tts-highlight-mode-' + num);
            if (el) {
              el.off('click').on('click', function(e) {
                var hlNum = e.target.id || e.target.parentElement.id;
                hlNum =
                  hlNum &&
                  hlNum.indexOf('tts-highlight-mode-') == 0 &&
                  +hlNum.substring('tts-highlight-mode-'.length);
                if (hlNum >= 0 && hlNum <= 2) {
                  tts.setHighlightMode(hlNum);
                  updateHighlightModes();
                  updateVoicesBasedOnWordHighlighting(hlNum == 0);
                  tts.changedSettings();
                  // AccessibilityManager.updateTabOrder('tts_options_popup', function() {
                  //   AccessibilityManager.setFocus('#tts-highlight-mode-' + hlNum + ' a');
                  // });
                }
                return false;
              });
            }
          }
          $ttsSettingsVoiceRadioGroup.off('click').on('click', function(e) {
            tts.setVoiceByName(e.target.value);
            tts.changedSettings();
          });
        }
      })
      .catch(function() {
        // ignore TTS init failure
      });
  } else {
    return Promise.resolve();
  }
}

var initTTS = function(readium) {
  return tts
    .init(readium)
    .then(initTTSUI) // for now we don't chain this into getSettingsPromise promise train below, so we don't inadvertently lock up if TTS unavailable
    .then(initTTSSettingsUI)
    .catch(function(err) {
      console.log('TTS init error ', err);
    });
};

export default {
  init: initTTS,
};
