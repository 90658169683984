import Helpers from '../EpubReaderHelpers';

function PageUsage(isbn, uuid, profileId, profileType, isPrimary, eventEmitter) {
  Object.defineProperty(this, 'uuid', {
    value: uuid,
    writable: true,
    enumerable: true,
  });

  Object.defineProperty(this, 'isbn', {
    value: isbn,
    writable: true,
    enumerable: true,
  });

  Object.defineProperty(this, 'profileid', {
    value: profileId,
    writable: true,
    enumerable: true,
  });

  Object.defineProperty(this, 'profileType', {
    value: profileType,
    writable: true,
    enumerable: true,
  });

  Object.defineProperty(this, 'isPrimary', {
    value: isPrimary,
    writable: true,
    enumerable: true,
  });

  Object.defineProperty(this, 'uuid_isbn', {
    value: uuid +'|'+isbn,
    writable: true,
    enumerable: true,
  });

   Object.defineProperty(this, 'uuid_profile_isbn', {
    value: uuid + '|' + profileId + '|' + isbn ,
    writable: true,
    enumerable: true,
  });

  Object.defineProperty(this, '_eventEmitter', {
    value: eventEmitter,
    writable: false,
    enumerable: false,
  });

  var location = '';
  Object.defineProperty(this, 'location', {
    enumerable: true,
    get: function() {
      return location;
    },
    set: function(loc) {
      location = loc;
      eventEmitter.emit('new:updated', this);
    },
  });

  var locationArray = [];
  Object.defineProperty(this, 'locationArray', {
    enumerable: true,
    get: function() {
      return locationArray.join(',');
    },
    set: function(loc) {
      if (loc == '[]') {
        locationArray = [];
        return;
      }
      var locIndex = locationArray.indexOf(loc);
      if (locIndex == -1 && loc.trim() !== '') {
        locationArray.push(loc);
      }
    },
  });

  var wordsOnPage = -99;
  Object.defineProperty(this, 'wordsonpage', {
    enumerable: true,
    get: function() {
      return wordsOnPage;
    },
    set: function(wordCount) {
      wordsOnPage = wordCount;
      eventEmitter.emit('new:updated', this);
    },
  });

  var usercreatedate;
  Object.defineProperty(this, 'usercreatedate', {
    enumerable: true,
    get: function() {
      return usercreatedate;
    },
    set: function(date) {
      usercreatedate = date;
      eventEmitter.emit('new:updated', this);
    },
  });
}

PageUsage.usageName = 'PageUsage';

PageUsage.prototype.setCurrentPositionFromReadiumCfi = function(packageDocument, location) {
  var cfi = JSON.parse(location);
  this.location = Helpers.locObjToFullCFI(packageDocument, cfi);
  console.log('location-epub',this.location)
};

PageUsage.prototype.getLocationAsReadiumCfi = function(packageDocument) {
  return Helpers.locObjFromFullCFI(packageDocument, this.location, true);
};

PageUsage.prototype.update = function(pageUsage) {
  if (pageUsage.isbn == this.isbn && pageUsage.uuid == this.uuid) {
    if (!(pageUsage instanceof PageUsage)) {
      pageUsage = $.extend(
        true,
        new PageUsage(pageUsage.isbn, pageUsage.uuid, pageUsage.profileid, pageUsage.profileType, pageUsage.isPrimary, this._eventEmitter),
        pageUsage,
      );
    }
    this._eventEmitter.emit('new:updated', pageUsage);
  } else {
    throw new Errors.BadData("The ISBN and/or UUID values don't match.");
  }
  return pageUsage;
};

export default PageUsage;
