// As of 3/4/2021 it is used to time epub/audiobook loads(open), paging actions, and downloads

function startTiming(type, source) {
    try {
      type = type || 'generic';
      source = source || 'generic';
      var timerVarName = 'photonTimer_' + type;
      if (!window[timerVarName]) {
        window[timerVarName] = {
          type: type,
          source: source,
          start: Date.now()
        };
        console.log('A360-AnalyticsTimer: type: "' + window[timerVarName].type + '" (' + window[timerVarName].source + ') start at ' + window[timerVarName].start);
      }
    } catch (err) {
      // don't want to fail just because timer failed...
      console.error('A360-AnalyticsTimer: startTiming (' + type + ') error: ', err);
    }
  }
  
  // FSS uses this to time the initial book Open (Load) as well as individual page turns
  function endTiming(type, metadata) {
    try {
      metadata = metadata || {};
      type = type || 'generic';
  
      var timerVarName = 'photonTimer_' + type;
      var timerValue = null;
  
      // general purpose timers go here (as of 3/3/21 we have 'Paging' and 'Download')
      timerValue = window[timerVarName];
      if (timerValue) {
        window[timerVarName] = undefined; // clear out this timer
        if (timerValue.start) {
          // calculate the time difference from now from pageLoadStartTime
          var now = Date.now();
          var loadTime = (now - timerValue.start);
  
          if (loadTime) {
            var mediaType = metadata.type;
            console.log('A360-AnalyticsTimer: type: "' + timerValue.type + '" ' + mediaType + ' (' + timerValue.source + ') elapsed ' + loadTime + ' ms');  
          }
        }
      }
    } catch (err) {
      // don't want to fail just because timer failed...
      console.error('AnalyticsTimer: endTiming (' + type + ') error: ', err);
    }
  }
  
  export default {
    startTiming: startTiming,
    endTiming: endTiming
  };