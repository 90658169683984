var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"container-fluid\">\n\n    <!-- left-side buttons (only menu button for now) -->\n    <div class=\"hide-until-loaded navbar-button-group navbar-left\">\n        <button class=\"navbar-btn menu-button\" type=\"button\" id=\"btnToggleMainMenu\" aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1), depth0))
    + "\" accesskey=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"keyboard") : depth0)) != null ? lookupProperty(stack1,"accesskeys") : stack1)) != null ? lookupProperty(stack1,"Menu") : stack1), depth0))
    + "\">\n            <span class=\"icon icon-menu\"></span>\n            <span aria-hidden=\"true\" class=\"sr-only\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"menu") : stack1), depth0))
    + "</span>\n        </button>\n    </div>\n\n    <!--<div class=\"portal-logo\" role=\"img\" aria-label=\"Axis360 Logo\"></div>-->\n\n    <!-- right-side buttons -->\n    <div class=\"hide-until-loaded navbar-button-group navbar-right\" role=\"region\" aria-label=\"Tools\">\n        <button class=\"navbar-btn\" type=\"button\" id=\"btnToggleAudioSpeed\" title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"audio_speed") : stack1), depth0))
    + "\" accesskey=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"keyboard") : depth0)) != null ? lookupProperty(stack1,"accesskeys") : stack1)) != null ? lookupProperty(stack1,"ToggleAudioSpeedMenu") : stack1), depth0))
    + "\">\n            <span aria-hidden=\"true\" class=\"icon icon-icon-speed\"></span>\n            <span aria-hidden=\"true\" class=\"sr-only\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"audio_speed") : stack1), depth0))
    + "</span>\n        </button>\n        <button class=\"navbar-btn\" type=\"button\" id=\"btnToggleBookmark\" title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"bookmark") : stack1), depth0))
    + "\" accesskey=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"keyboard") : depth0)) != null ? lookupProperty(stack1,"accesskeys") : stack1)) != null ? lookupProperty(stack1,"Bookmark") : stack1), depth0))
    + "\">\n            <span aria-hidden=\"true\" class=\"icon icon-bookmark-outline\"></span>\n            <span aria-hidden=\"true\" class=\"sr-only\">Bookmarks</span>\n        </button>\n        <button class=\"navbar-btn\" type=\"button\" id=\"btnToggleSleepTimer\" title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"sleep_timer") : stack1), depth0))
    + "\" accesskey=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"keyboard") : depth0)) != null ? lookupProperty(stack1,"accesskeys") : stack1)) != null ? lookupProperty(stack1,"ToggleSleepTimerMenu") : stack1), depth0))
    + "\">\n            <span aria-hidden=\"true\" class=\"icon icon-icon-timer\"></span>\n            <span aria-hidden=\"true\" class=\"sr-only\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"sleep_timer") : stack1), depth0))
    + "</span>\n        </button>\n        <button class=\"navbar-btn\" type=\"button\" id=\"btnToggleHelp\" title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"help") : stack1), depth0))
    + "\" accesskey=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"keyboard") : depth0)) != null ? lookupProperty(stack1,"accesskeys") : stack1)) != null ? lookupProperty(stack1,"HelpMenu") : stack1), depth0))
    + "\">\n            <!--- WorkItem 84562 Two Icons are getting highlighted --->\n            <span aria-hidden=\"true\" class=\"icon icon-help\">\n                <!-- <span class=\"path1\"></span>\n                <span class=\"path2\"></span> -->\n            </span>\n            <span aria-hidden=\"true\" class=\"sr-only\">Help</span>\n        </button>\n    </div>\n\n    <button id=\"buttShowToolBar\" class=\"sr-only hidden\" title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_toolbar_show") : stack1), depth0))
    + "\" accesskey=\"v\">\n        <span aria-hidden=\"true\" class=\"sr-only\">Show Navigation</span>\n    </button>\n    <button id=\"buttHideToolBar\" class=\"pull-right\" title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_toolbar_hide") : stack1), depth0))
    + "\" accesskey=\"x\">\n        <span aria-hidden=\"true\" class=\"icon icon-arrow-left\"></span>\n        <span aria-hidden=\"true\" class=\"sr-only\">Hide Navigation</span>\n    </button>\n</div>\n\n";
},"useData":true});