var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"splash splash-busy see-through\">\n    <div class=\"close hidden\"><span class=\"icon icon-close\"></span></div>\n    <div class=\"splash-body\">\n        <div class=\"splash-content progress-control\">\n            <div class=\"splash-icon\" role=\"progressbar\" aria-valuemin=\"0\" aria-valuemax=\"100\" aria-valuenow=\"0\">\n                <svg width=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"w") || (depth0 != null ? lookupProperty(depth0,"w") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"w","hash":{},"data":data,"loc":{"start":{"line":6,"column":28},"end":{"line":6,"column":33}}}) : helper)))
    + "\" height=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"h") || (depth0 != null ? lookupProperty(depth0,"h") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"h","hash":{},"data":data,"loc":{"start":{"line":6,"column":43},"end":{"line":6,"column":48}}}) : helper)))
    + "\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\">\n                    <circle class=\"bar\" r=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"r") || (depth0 != null ? lookupProperty(depth0,"r") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"r","hash":{},"data":data,"loc":{"start":{"line":7,"column":43},"end":{"line":7,"column":48}}}) : helper)))
    + "\" cx=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"cx") || (depth0 != null ? lookupProperty(depth0,"cx") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cx","hash":{},"data":data,"loc":{"start":{"line":7,"column":54},"end":{"line":7,"column":60}}}) : helper)))
    + "\" cy=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"cy") || (depth0 != null ? lookupProperty(depth0,"cy") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cy","hash":{},"data":data,"loc":{"start":{"line":7,"column":66},"end":{"line":7,"column":72}}}) : helper)))
    + "\" fill=\"transparent\" stroke-dasharray=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"cir") || (depth0 != null ? lookupProperty(depth0,"cir") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cir","hash":{},"data":data,"loc":{"start":{"line":7,"column":111},"end":{"line":7,"column":118}}}) : helper)))
    + "\" stroke-dashoffset=\"0\"></circle>\n                </svg>\n                <div class=\"pct\">0%</div>\n            </div>\n            <!--<h3 class=\"text-uppercase\">Success!</h3>-->\n            <p>\n                <span class=\"spin-prgs-msg line1\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"msg") || (depth0 != null ? lookupProperty(depth0,"msg") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"msg","hash":{},"data":data,"loc":{"start":{"line":13,"column":50},"end":{"line":13,"column":57}}}) : helper)))
    + "</span><br/>\n                <span class=\"spin-prgs-msg line2\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"msg2") || (depth0 != null ? lookupProperty(depth0,"msg2") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"msg2","hash":{},"data":data,"loc":{"start":{"line":14,"column":50},"end":{"line":14,"column":58}}}) : helper)))
    + "</span>\n            </p>\n            <!--<div class=\"form-buttons\">\n                <button class=\"btn btn-default\">Cancel</button>\n            </div>-->\n        </div>\n    </div>\n</div>";
},"useData":true});