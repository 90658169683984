import Digest from './digest';
import Encryption from './encryption';
import 'webcrypto-shim';

var _encryption, _digest;

var Signature = function() {
  _digest = new Digest();
  _encryption = new Encryption();
};
Signature.prototype.getRsaEncryptedSha = function(digest) {
  return {
    name: 'rsa-ecrypted-sha',
    rsa: _encryption.getRSAOAEP(),
    sha: _digest.getSHA(digest),
  };
};
//Gets this crypto algorithm settings from the XML encryption Identifier
Signature.prototype.getAlgorithm = function(identifier) {
  var identifiers = {
    'http://www.w3.org/2000/09/xmldsig#rsa-sha1': this.getRsaEncryptedSha('SHA-256'),
    'http://www.w3.org/2001/04/xmldsig-more#rsa-sha256': this.getRsaEncryptedSha('SHA-256'),
  };
  return identifiers[identifier];
};

/*Signature.prototype.verify = function (algo, key, signature, data ) {
        signature = _encryption.utils.base64ToArr(signature);
        return new Promise(function(resolve, reject){
            if (algo.name == "rsa-ecrypted-sha"){
                _encryption.decrypt(algo.rsa, key, signature).then(function(decryptedHash){
                    decryptedHash = _encryption.utils.arrToBase64(new Uint8Array(decryptedHash));

                    return _digest.digest(algo.sha, data).then(function(calculatedHash){
                        if ((decryptedHash == calculatedHash)){
                            resolve();
                        }else{
                            reject(new Error("Signature is invalid"));
                        }
                        /!*resolve(isValid);
                        return isValid;*!/
                    });
                }).catch(function(err){
                    reject(err);
                })
            }else{
                //For now we don't support other signatures, so we reject the promise.
                reject(new Error("Algorithm not supported"));
            }
        });
    };*/

Signature.prototype.verify = function(algo, key, signature, data) {
  signature = _encryption.utils.base64ToArr(signature);
  if (algo.name == 'rsa-ecrypted-sha') {
    return _encryption
      .decrypt(algo.rsa, key, signature)
      .then(function(decryptedHash) {
        decryptedHash = _encryption.utils.arrToBase64(new Uint8Array(decryptedHash));
        return Promise.all([decryptedHash, _digest.digest(algo.sha, data)]);
      })
      .then(function(results) {
        if (results[0] != results[1]) {
          throw new Error('Signature is invalid');
        }
        return true;
      });
  } else {
    //For now we don't support other signatures, so we reject the promise.
    return Promise.reject(new Error('Algorithm not supported'));
  }
};
export default Signature;
