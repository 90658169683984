var libraryDataStoreDefUpdate = {
  name: 'LibraryDataStore',
  indexes: [
    {
      name: 'isbn',
      keyPath: 'isbn',
      optionalParameters: { unique: true },
    },
  ],
};

var bookDataStoreDefUpdate = {
  name: 'BookDataStore',
  indexes: [
    {
      name: 'isbn',
      keyPath: 'isbn',
      optionalParameters: { unique: false },
    },
  ],
};

var licenseStoreDefUpdate = {
  name: 'LicenseStore',
  indexes: [
    {
      name: 'isbn',
      keyPath: 'isbn',
      optionalParameters: { unique: false },
    },
  ],
};

var MigrationManager9To10 = function(db, transaction) {
  this.db = db;
  this.transaction = transaction;
}

MigrationManager9To10.prototype.updateObjectStore = function(storeDefUpdate) {
  var self = this;
  return new Promise((resolve, reject) => {
    var objectStore = self.transaction.objectStore(storeDefUpdate.name);
    storeDefUpdate.indexes.forEach(function(element) {
      objectStore.deleteIndex(element.name);
      objectStore.createIndex(element.name, element.keyPath, element.optionalParameters)
    });
    resolve();
  });
};

MigrationManager9To10.prototype.migrate = async function() {
  await this.updateObjectStore(bookDataStoreDefUpdate);
  await this.updateObjectStore(libraryDataStoreDefUpdate);
  await this.updateObjectStore(licenseStoreDefUpdate);
  console.debug('9 to 10 done');
};

export default MigrationManager9To10;