/**
 * Error Monitor is a singleton, that automatically setups the error monitor.
 * THis needs to be done as early as possible so errors aren't thrown before
 * the ErrorMonitor is setup.
 */
import $ from 'jquery';
import Bugsnag from 'bugsnag-js';
import Managers from '@axisnow/data/Managers';
import moduleConfig from './ModuleConfig';
import Promise from 'bluebird';
import Utils from '@axisnow/util/utils';
import PackageInfo from './PackageInfo';

var ErrorMonitor = function() {
  setupBugsnag(PackageInfo.version);
};

//Private Functions
var setupBugsnag = function(version) {
  var promise;

  /*if (moduleConfig.releaseStage === 'local'){
            promise = Utils.deferredToPromise($.get("/dev/.local-release-postfix"));
            promise = promise.then(function(data){
                if (!data || data.trim() == ""){
                    data = moduleConfig.releaseStage;
                }else{
                    data = moduleConfig.releaseStage + "-" + data;
                }
                return data;
            }).catch(function(){
                return moduleConfig.releaseStage;
            });
        }else{
            promise = Promise.resolve(moduleConfig.releaseStage);
        }

        return promise.then(function(releaseStage){
            Bugsnag.releaseStage = releaseStage;
            return version;
        });*/
  promise = Promise.resolve(moduleConfig.releaseStage)
    .then(function(releaseStage) {
      if (releaseStage === 'local') {
        return Utils.deferredToPromise($.get('/dev/.local-release-postfix'))
          .then(function(data) {
            if (!data || data.trim() == '') {
              data = moduleConfig.releaseStage;
            } else {
              data = moduleConfig.releaseStage + '-' + data;
            }
            return data;
          })
          .catch(function() {
            return releaseStage;
          });
      }
      return releaseStage;
    })
    .then(function(releaseStage) {
      // window.bugsnagClient = Bugsnag({
      //   apiKey: '21ff183a2dfdc23e33bd437ec4d4cd0c',
      //   appVersion: version,
      //   releaseStage: releaseStage,
      // });
      return version;
    })
    .catch(function() {
      // window.bugsnagClient = Bugsnag({
      //   apiKey: '21ff183a2dfdc23e33bd437ec4d4cd0c',
      //   appVersion: version,
      // });
    });
};

//Public API
ErrorMonitor.prototype.bugsnag = function(error, metadata) {
  var bugsnagObject = {};
  bugsnagObject.metaData = metadata != undefined ? metadata : {};
  if (metadata != undefined) {
    bugsnagObject.metaData = metadata;
  }
  return Managers.user
    .getCurrentUser(true)
    .then(function(user) {
      if (user) {
        bugsnagObject.user = user;
        $.extend(bugsnagObject.metaData, error.metaData);
        //bugsnagClient.notify(error, bugsnagObject);
      }
    })
    .catch(function(err) {
      //If there is a problem getting the user we should still log the error.
      console.error(err);
      //bugsnagClient.notify(error, bugsnagObject);
    });
};

export default new ErrorMonitor();
