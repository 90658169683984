import $ from 'jquery';
import PopupFactory from '../../popup/PopupFactory';
import Menu from '../../menus/Menu';
import AudioSpeedMenuTemplate from '../../templates/audio-speed-menu.hbs';
import Feature from '@axisnow/data/app-permission/Feature';
import Managers from '@axisnow/data/Managers';
import AccessibilityManager from '../../accessibility/AcessibilityManager';

// Audio Speed options
var audioSpeedPopup = PopupFactory.create(PopupFactory.TYPE.NAVBAR_MENU, 'Audio Speed', {
  id: 'audioSpeed',
  cssClass: 'size height unit-auto app-audio-speed-menu ',
});

// Adding attributes for WA
audioSpeedPopup.$el.attr('role', 'dialog');

// Assigned Audio Speed Html content to variable
var $audioSpeedContainerLayoutContent = $(AudioSpeedMenuTemplate({}));
audioSpeedPopup.clearContainerLayouts();
var $audioSpeedContainerLayout = audioSpeedPopup.addContainerLayout(
  $audioSpeedContainerLayoutContent,
);
var $slider = $audioSpeedContainerLayout.find('.audio-speed-slider');
var AudioSpeedMenu = function() {
  Menu.apply(this, arguments);
  this.popup(audioSpeedPopup);
};

AudioSpeedMenu.prototype = Object.create(Menu.prototype);

var featureManager = Managers.feature;
featureManager.feature(
  new Feature(
    'AudioBookSpeed',
    function() {
      $('#btnToggleAudioSpeed').show();
      console.log('AudioBook Speed feature enabled');
    },
    function() {
      $('#btnToggleAudioSpeed').hide();
      console.log('AudioBook Speed feature disabled');
    },
  ),
);

AudioSpeedMenu.prototype.show = function() {
  var self = this;
  var speed = self.findaway.getSpeed();
  $slider.prop('value', speed == '0.5' ? 0 : speed);
  $slider.attr('aria-valuenow', speed == '0.5' ? 0 : speed);
  $slider.attr('aria-valuetext', speed + ' time');

  $slider.on('change', function() {
    var speedString = $slider.prop('value') === $slider.prop('min') ? 0.5 : $slider.prop('value');
    speed = parseFloat(speedString);
    self.findaway.setSpeed(speed);
    $slider.attr('aria-valuetext', speed + ' time');
  });

  this._popup.show();
//    AccessibilityManager.setTabGroup("audio_popup")
  //    AccessibilityManager.updateTabOrder("audio_popup")
};

export default AudioSpeedMenu;
