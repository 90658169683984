import Modernizr from '@axisnow/modernizr';
//AXNG-45 Need to import so polyfills could be applied
import '@axisnow/util/Polyfills';
import AxisNowDataLoader from '@axisnow/data/AxisNowDataLoader/AxisNowDataLoader';
import { registerPlugins } from '@axisnow/plugins/initialize';
import { importReadiumSharedJSDataTypes } from '@axisnow/ng-r1-compat';

importReadiumSharedJSDataTypes();
window.ReadiumSDK = window.ReadiumSDK || {};

let initialized = false;

export default function(Readium) {
  if (initialized) return;

  //indexeddb is an async test, so we need to wait until it is finished before checking if is supported
  Modernizr.on('indexdbkeysupported', function() {
    var results = {
      crypto: Modernizr.crypto,
      indexeddb: Modernizr.indexeddb,
      indexdbkeysupported: Modernizr.indexdbkeysupported,
    };
    Modernizr._trigger('customready', results);
  });

  window.ReadiumSDK.DataLoader = new AxisNowDataLoader(Readium);

  registerPlugins();

  initialized = true;
}
