import $ from 'jquery';
import _ from 'underscore';
import selectionchange from './lib/selectionchange-polyfill';
function TextSelection(api) {
  var DEBUG = true;

  var _reader = api.reader;
  var emit = _.bind(this.emit, this);

  var _currentSelection;
  var _clearSelectionFn;

  this.getSelection = function() {
    return _currentSelection;
  };

  this.clearSelection = function() {
    if (_clearSelectionFn) _clearSelectionFn();
  };

  this.hasSelection = function() {
    return !!(
      _currentSelection &&
      _currentSelection.toString().trim().length &&
      !_currentSelection.isCollapsed
    );
  };

  var _eventListenersBound;

  var _isMobile = false;

  function log() {
    if (!DEBUG) return;
    console.log.apply(console, arguments);
  }

  var Events = (this.Events = {
    START: 'start',
    CHANGED: 'changed',
    END: 'end',
    CLEARED: 'cleared',
  });

  function isAndroid() {
    return /Android/i.test(navigator.userAgent);
  }

  function isMobileSafari() {
    return /iP(ad|od|hone)/i.test(navigator.userAgent);
  }

  function getStartEndPosFromRange(range) {
    var start = range.cloneRange();
    var end = range.cloneRange();
    start.collapse(true);
    var startPos = start.getBoundingClientRect();
    end.collapse(false);
    var endPos = end.getBoundingClientRect();

    return {
      start: startPos,
      end: endPos,
    };
  }

  function bind(doc) {
    // polyfill for firefox `selectionchange` event
    selectionchange.start(doc);

    // reset selection state (workaround for IE 11 selection issue)
    var selection = doc ? doc.getSelection() : null;
    var firstElement = doc ? doc.body.firstElementChild : null;
    if (selection && firstElement) {
      var range = doc.createRange();
      range.setStartBefore(firstElement);
      range.setEndBefore(firstElement);
      selection.addRange(range);
      selection.removeAllRanges();
    }

    doc.addEventListener('touchstart', function usesTouch() {
      _isMobile = true;
      doc.removeEventListener('touchstart', usesTouch);
    });

    if (isAndroid()) {
      // for android use a different method for text selection
      _eventListenersBound = AndroidChromeTextSelection(doc);
    } else if (isMobileSafari()) {
      // iOS Safari doesn't fire any touch events while changing the selection,
      // therefore we can't detect a selection end like normal
      _eventListenersBound = MobileSafariTextSelection(doc);
    } else {
      _eventListenersBound = GenericTextSelection(doc);
    }

    _.each(_eventListenersBound, function(listener, eventType) {
      doc.addEventListener(eventType, listener, true);
    });
  }

  function unbind(doc) {
    // polyfill for firefox `selectionchange` event
    selectionchange.stop(doc);

    var selection = doc ? doc.getSelection() : null;
    if (selection) {
      selection.removeAllRanges();
    }
    emit(Events.CLEARED);

    _.each(_eventListenersBound, function(listener, eventType) {
      doc.removeEventListener(eventType, listener);
    });
  }

  function TextSelectionEvent(eventType, clearSelectionFn) {
    var sel = _currentSelection;
    if (!sel) return null;

    var text = sel.toString();
    var range = sel.isCollapsed ? null : sel.getRangeAt(0);

    var result = {
      eventType: eventType,
      cfi: null,
      text: text,
      range: range,
      document: range ? range.startContainer.ownerDocument : null,
      coordinates: null,
      isMobile: _isMobile,
      clear: clearSelectionFn
        ? clearSelectionFn
        : function() {
            sel.removeAllRanges();
          },
    };

    if (range) {
      var clonedRange = range.cloneRange();

      Object.defineProperty(result, 'cfi', {
        get: function() {
          return _reader.getRangeCfiFromDomRange(clonedRange);
        },
      });

      Object.defineProperty(result, 'coordinates', {
        get: function() {
          var coords = getStartEndPosFromRange(clonedRange);
          return {
            start: {
              clientX: coords.start.left,
              clientY: coords.start.top,
              width: coords.start.width,
              height: coords.start.height,
            },
            end: {
              clientX: coords.end.right,
              clientY: coords.end.bottom,
              width: coords.end.width,
              height: coords.end.height,
            },
          };
        },
      });
    }

    return result;
  }

  function GenericTextSelection(doc) {
    var document = doc;

    var selectionCleared;
    var selectionEnded;

    function onSelectionCleared() {
      log('selection cleared');
      selectionCleared = true;
      _currentSelection = null;
      var selection = document ? document.getSelection() : null;
      if (selection) {
        selection.removeAllRanges();
      }
      emit(Events.CLEARED);
    }
    _clearSelectionFn = onSelectionCleared;

    function onSelectionChange() {
      log('selection change');
      selectionEnded = false;
      var initialEvent = false;
      if (!_currentSelection) {
        initialEvent = true;
      }
      var selection = document ? document.getSelection() : null;
      if (selection && selection.isCollapsed) {
        _currentSelection = null;
        if (!initialEvent && !selectionCleared) {
          selectionCleared = true;
          onSelectionCleared();
        }
        return;
      }
      _currentSelection = selection;
      log('Changing to selection with length ' + _currentSelection.toString().length);
      selectionCleared = false;
      if (initialEvent) {
        onSelectionStart();
        return;
      }
      emit(Events.CHANGED, TextSelectionEvent(Events.CHANGED));
    }

    function onSelectionStart() {
      log('selection start');
      emit(Events.START, TextSelectionEvent(Events.START));
    }

    function onPointerUp() {
      _.defer(function() {
        if (!_currentSelection && !selectionCleared) {
          onSelectionCleared();
        } else if (_currentSelection && !selectionEnded) {
          if (_currentSelection.toString().trim().length === 0) {
            log(' selection ended with no text selected, aborting', _currentSelection);
            onSelectionCleared();
          } else {
            log(' selection end', _currentSelection);
            selectionEnded = true;
            emit(Events.END, TextSelectionEvent(Events.END));
          }
        }
      });
    }

    return {
      selectionchange: onSelectionChange,
      dblclick: onSelectionChange,
      mouseup: onPointerUp,
      keyup: onPointerUp,
      touchend: onPointerUp,
    };
  }

  function MobileSafariTextSelection(doc) {
    var timeoutId;

    var _super = GenericTextSelection(doc);
    var _super_onSelectionChange = _super['selectionchange'];

    _super['selectionchange'] = function() {
      clearTimeout(timeoutId);
      _super_onSelectionChange();
      timeoutId = setTimeout(_super['touchend'], 300);
    };

    return _super;
  }

  function AndroidChromeTextSelection(doc) {
    var document = doc;

    var selectionAnchor;
    var selectionHandleHeight = 22;
    var initialSelection = true;

    window.ReadiumSDKExport.Helpers.polyfillCaretRangeFromPoint(document); //only polyfills once, no-op afterwards
    var vibrate = navigator.vibrate || navigator.webkitVibrate;
    if (vibrate) {
      vibrate = _.bind(vibrate, navigator);
    } else {
      vibrate = $.noop;
    }
    //document.styleSheets[0].insertRule("::selection {background: #a8d1ff}", 0);

    // helper function to test if a point is within a rectangle
    function pointRectangleIntersection(point, rect) {
      return (
        point.x > rect.left && point.x < rect.right && point.y > rect.top && point.y < rect.bottom
      );
    }

    function collapseRectToPoint(rect, offsetX, offsetY) {
      return {
        x: rect.left + offsetX,
        y: rect.bottom + offsetY,
      };
    }

    function getRectFromPoint(point, size) {
      var half = size / 2;
      return {
        left: point.x - half,
        right: point.x + half,
        top: point.y - half,
        bottom: point.y + half,
      };
    }

    function onSelectStart(event) {
      log('selectstart');
      event.preventDefault();
      return false;
    }

    function onSelectionChange(event) {
      log('selectionchange', 'touch');
      clearTimeout(selClearTimer);
      event.preventDefault();
      return false;
    }

    // "long click" should select a word.
    // set up listeners for long click identification
    var selStartTimer,
      selClearTimer,
      selEndTimer,
      timeout = 300;
    function onTouchStart(e) {
      log('touchstart', e);
      selStartTimer = setTimeout(function() {
        e = e.targetTouches[0];
        vibrate(30);
        log('createInitialSelection');
        var mouseX = e.clientX || e.pageX;
        var mouseY = e.clientY || e.pageY;
        var range = document.caretRangeFromPoint(mouseX, mouseY);
        var element = document.elementFromPoint(mouseX, mouseY);

        if (document.documentElement === element) return;

        var sel = document.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
        sel = document.getSelection();

        selectionAnchor = expandSelectionToWord(sel);
        if (selectionAnchor == '') {
          selectionAnchor = range;
        }
        startSelection(range);
        selEndTimer = setTimeout(function() {
          endSelection();
        }, timeout - 25);
      }, timeout);
      selClearTimer = setTimeout(function() {
        clearSelection();
      }, timeout - 50);
      e.preventDefault();
    }

    function onTouchEnd(e) {
      log('touchend', 'clearTimeout', e);
      clearTimeout(selStartTimer);
    }

    function onTouchMove(e) {
      log('touchmove', 'clearTimeout', e);
      //clearTimeout(selStartTimer);
      clearTimeout(selClearTimer);
    }

    function startSelection() {
      log('startSelection');
      clearTimeout(selStartTimer);
      document.addEventListener('touchstart', updateSelectionStart);
      document.addEventListener('touchend', updateSelectionEnd);
      document.addEventListener('touchmove', updateSelection);
      _currentSelection = document.getSelection();
      log('Starting selection with length ' + _currentSelection.toString().length);
      renderHandles(_currentSelection.getRangeAt(0));
      emit(Events.START, TextSelectionEvent(Events.START, clearSelection));
    }

    function clearSelection() {
      log('clearSelection');
      _currentSelection = document.getSelection();
      if (!!(_currentSelection &&
        _currentSelection.toString().trim().length &&
        !_currentSelection.isCollapsed)) {
        document.getSelection().removeAllRanges();
      }
      selectionAnchor = null;
      document.removeEventListener('touchstart', updateSelectionStart);
      document.removeEventListener('touchend', updateSelectionEnd);
      document.removeEventListener('touchmove', updateSelection);
      _currentSelection = null;
      initialSelection = true;
      clearRenderedHandles();
      clearTimeout(selEndTimer);
      emit(Events.CLEARED);
    }
    _clearSelectionFn = clearSelection;

    function endSelection() {
      log('endSelection');
      emit(Events.END, TextSelectionEvent(Events.END, clearSelection));
    }

    function getSelectionTouchState(touchPoint, range) {
      var rangePos = getStartEndPosFromRange(range);
      var startRect = getRectFromPoint(collapseRectToPoint(rangePos.start, 10, 10), 35);
      var endRect = getRectFromPoint(collapseRectToPoint(rangePos.end, 10, 10), 35);

      if (pointRectangleIntersection(touchPoint, endRect)) {
        return 'end';
      } else if (pointRectangleIntersection(touchPoint, startRect)) {
        return 'start';
      }
      return 'none';
    }

    function updateSelectionStart(e) {
      log('updateSelectionStart', e);

      var coords = e.changedTouches[0];
      var touchPoint = { x: coords.clientX, y: coords.clientY };
      var selTouchState = getSelectionTouchState(touchPoint, _currentSelection.getRangeAt(0));
      log('selTouchState', selTouchState);
      if (selTouchState !== 'none') {
        clearTimeout(selStartTimer);
        document.addEventListener('touchmove', updateSelection);
      }
    }

    function updateSelectionEnd(e) {
      log('updateSelectionEnd', e);

      var coords = e.changedTouches[0];
      var touchPoint = { x: coords.clientX, y: coords.clientY };
      var selTouchState = getSelectionTouchState(touchPoint, _currentSelection.getRangeAt(0));
      log('selTouchState', selTouchState);
      if (!initialSelection && selTouchState === 'none') {
        clearSelection();
      }
      initialSelection = false;
      clearTimeout(selStartTimer);
      document.removeEventListener('touchmove', updateSelection);
    }

    function updateSelection(e) {
      log('updateSelection', e);

      e.preventDefault();
      clearTimeout(selStartTimer);

      var coords = e.changedTouches[0];
      var touchPoint = { x: coords.clientX, y: coords.clientY };

      if (!initialSelection) {
        touchPoint.y = touchPoint.y - selectionHandleHeight;
      }

      var focus = document.caretRangeFromPoint(touchPoint.x, touchPoint.y);
      if (!focus) {
        log('no focus');
        return;
      }
      var range = new Range();
      var backwards = selectionAnchor.compareBoundaryPoints(Range.START_TO_START, focus) == 1;
      log('start', backwards ? 'focus' : 'selectionAnchor');
      log('end', backwards ? 'selectionAnchor' : 'focus');
      var startPos = backwards ? focus : selectionAnchor;
      var endPos = backwards ? selectionAnchor : focus;
      range.setStart(startPos.startContainer, startPos.startOffset);
      range.setEnd(endPos.startContainer, endPos.startOffset);
      var sel = document.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
      //expandSelectionToWord(sel, false, 1);
      _currentSelection = document.getSelection();
      log('Updating to selection with length ' + _currentSelection.toString().length);
      renderHandles(range);
      emit(Events.CHANGED, TextSelectionEvent(Events.CHANGED, clearSelection));
      clearTimeout(selEndTimer);
      selEndTimer = setTimeout(function() {
        endSelection();
      }, timeout - 25);
    }

    function expandSelectionToWord(sel) {
      log('expandSelectionToWord');

      sel.collapse(sel.anchorNode, sel.anchorOffset);
      sel.modify('move', 'forward', 'character');
      sel.modify('move', 'backward', 'word');
      sel.modify('extend', 'backward', 'character');
      sel.modify('extend', 'forward', 'word');
      var range = '';
      if (sel.rangeCount > 0) {
        range = sel.getRangeAt(0);
      }
      return range;
    }

    function renderHandles(range) {
      const htmlRect = $('html', range.startContainer.ownerDocument)[0].getBoundingClientRect();
      const iframeRect = document.defaultView.frameElement.getBoundingClientRect();
      const scale = iframeRect.height/htmlRect.height;

      var parentDoc = window.document;
      var parentFrameOffsets = document.defaultView.frameElement.getBoundingClientRect();

      clearRenderedHandles();
      var rangePos = getStartEndPosFromRange(range);

      var $leftDiv = $(parentDoc.createElement('div'));
      var $rightDiv = $(parentDoc.createElement('div'));
      $leftDiv.appendTo(parentDoc.documentElement);
      $rightDiv.appendTo(parentDoc.documentElement);
      $leftDiv.addClass('rd-selection-handle left');
      $rightDiv.addClass('rd-selection-handle right');

      var leftDivWidth = $leftDiv.width();
      var leftDivHeight = (selectionHandleHeight = $leftDiv.height());
      $leftDiv.css({
        position: 'absolute',
        left: rangePos.start.left * scale + parentFrameOffsets.left - leftDivWidth,
        top: rangePos.start.bottom * scale + parentFrameOffsets.top,
      });

      $rightDiv.css({
        position: 'absolute',
        left: rangePos.end.left * scale + parentFrameOffsets.left,
        top: rangePos.end.bottom * scale + parentFrameOffsets.top,
      });
    }

    function clearRenderedHandles() {
      $('.rd-selection-handle', window.document).remove();
    }

    return {
      selectstart: onSelectStart,
      selectionchange: onSelectionChange,
      touchstart: onTouchStart,
      touchend: onTouchEnd,
      touchmove: onTouchMove,
    };
  }

  _reader.on(
    ReadiumSDK.Events.CONTENT_DOCUMENT_LOADED,
    _.bind(function($iframe, spineItem) {
      bind($iframe[0].contentDocument);
    }, this),
  );

  _reader.on(
    ReadiumSDK.Events.CONTENT_DOCUMENT_UNLOADED,
    _.bind(function($iframe, spineItem) {
      unbind($iframe[0].contentDocument);
    }, this),
  );
}

export default TextSelection;
