import $ from 'jquery';
import PubSub from 'pubsub-js'; //for audio update

/**
 *
 * @param onStatusChanged
 * @param onPositionChanged
 * @param onAudioEnded
 * @param onAudioPlay
 * @param onAudioPause
 * @constructor
 */
var _checkVal = 1;
var self;
/**
 * Subscribe event when Bookmarks listed
 */
PubSub.subscribe('AUDIO-SPEED-KEY',function (msg,data) {
if (data) {
  _checkVal = data;
  self.play();


}
});
var AudioPlayer = function(
  onStatusChanged,
  onPositionChanged,
  onAudioEnded,
  onAudioPlay,
  onAudioPause,
) {
  var _iOS = navigator.userAgent.match(/(iPad|iPhone|iPod)/g) ? true : false;
  var _Android = navigator.userAgent.toLowerCase().indexOf('android') > -1;
  var _isMobile = _iOS || _Android;

  //var _isReadiumJS = typeof window.requirejs !== "undefined";

  var DEBUG = false;

  var _audioElement = new Audio();
  var _audioSource = document.createElement("source");

  _audioElement.appendChild(_audioSource);

  if (DEBUG) {
    _audioElement.addEventListener('load', function() {
      console.debug('0) load');
    });

    _audioElement.addEventListener('loadstart', function() {
      console.debug('1) loadstart');
    });

    _audioElement.addEventListener('durationchange', function() {
      console.debug('2) durationchange');
    });

    _audioElement.addEventListener('loadedmetadata', function() {
      console.debug('3) loadedmetadata');
    });

    _audioElement.addEventListener('loadeddata', function() {
      console.debug('4) loadeddata');
    });

    _audioElement.addEventListener('progress', function() {
      console.debug('5) progress');
    });

    _audioElement.addEventListener('canplay', function() {
      console.debug('6) canplay');
    });

    _audioElement.addEventListener('canplaythrough', function() {
      console.debug('7) canplaythrough');
    });

    _audioElement.addEventListener('play', function() {
      console.debug('8) play');
    });

    _audioElement.addEventListener('pause', function() {
      console.debug('9) pause');
    });

    _audioElement.addEventListener('ended', function() {
      console.debug('10) ended');
    });

    _audioElement.addEventListener('seeked', function() {
      console.debug('X) seeked');
    });

    _audioElement.addEventListener('timeupdate', function() {
      console.debug('Y) timeupdate');
    });

    _audioElement.addEventListener('seeking', function() {
      console.debug('Z) seeking');
    });
  }

  _audioElement.addEventListener('onstalled', function(e) { 
    console.debug('Audio stalled and invoking reload....');
    audioElement.load();
  }, false);

  self = this;

  //_audioElement.setAttribute("preload", "auto");

  var _currentEpubSrc = undefined;

  var _currentSmilSrc = undefined;

  var _currentEpubSrcType = undefined;

  this.currentSmilSrc = function() {
    return _currentSmilSrc;
  };

  var _rate = 1.0;
  this.setRate = function(rate) {
    console.log("AudioPlayer.setRate : ", rate);
    _rate = rate;
    if (typeof _rate !== 'number') {
      _rate = 1.0;
    }
    if (_rate < 0.5) {
      _rate = 0.5;
    }
    if (_rate > 4.0) {
      _rate = 4.0;
    }

    _audioElement.playbackRate = _rate;
  };
  self.setRate(_rate);
  this.getRate = function() {
    return _rate;
  };

  var _volume = 1.0;
  this.setVolume = function(volume) {
    _volume = volume;
    if (_volume < 0.0) {
      _volume = 0.0;
    }
    if (_volume > 1.0) {
      _volume = 1.0;
    }
    _audioElement.volume = _volume;
  };
  self.setVolume(_volume);
  this.getVolume = function() {
    return _volume;
  };

  this.play = function() {
    if (DEBUG) {
      console.error('this.play()');
    }
    console.log("AudioPlayer.play: ", _currentEpubSrc);
    if (!_currentEpubSrc) {
      return false;
    }

    startTimer();
    self.setVolume(_volume);
    self.setRate(_checkVal);
    _audioElement.play();
    return true;
  };

  this.pause = function() {
    if (DEBUG) {
      console.error('this.pause()');
    }

    stopTimer();

    _audioElement.pause();
  };

  _audioElement.addEventListener('play', onPlay, false);
  _audioElement.addEventListener('pause', onPause, false);
  _audioElement.addEventListener('ended', onEnded, false);

  function onPlay() {
    onStatusChanged({ isPlaying: true });
    onAudioPlay();
  }

  function onPause() {
    onAudioPause();
    onStatusChanged({ isPlaying: false });
  }

  function onEnded() {
    if (_audioElement.moSeeking) {
      if (DEBUG) {
        console.debug('onEnded() skipped (still seeking...)');
      }

      return;
    }

    stopTimer();

    onAudioEnded();
    onStatusChanged({ isPlaying: false });
  }

  var _intervalTimerSkips = 0;

  var _intervalTimer = undefined;
  function startTimer() {
    if (_intervalTimer) {
      return;
    }

    _intervalTimer = setInterval(function() {
      if (_audioElement.moSeeking) {
        if (DEBUG) {
          //console.debug("interval timer skipped (still seeking...)");
        }

        _intervalTimerSkips++;
        if (_intervalTimerSkips > 1000) {
          _intervalTimerSkips = 0;
          stopTimer();
        }
        return;
      }

      var currentTime = undefined;
      try {
        currentTime = _audioElement.currentTime;
      } catch (ex) {
        console.error(ex.message);
      }

      //                if (DEBUG)
      //                {
      //                    console.debug("currentTime: " + currentTime);
      //                }

      if (currentTime) {
        onPositionChanged(currentTime, 1);
      }
    }, 20);
  }

  function stopTimer() {
    if (_intervalTimer) {
      clearInterval(_intervalTimer);
    }
    _intervalTimer = undefined;
  }

  this.isPlaying = function() {
    return _intervalTimer !== undefined;
  };

  this.reset = function() {
    if (DEBUG) {
      console.error('this.reset()');
    }

    this.pause();

    _audioElement.moSeeking = undefined;

    _currentSmilSrc = undefined;
    _currentEpubSrc = undefined;
    _currentEpubSrcType = undefined;

    setTimeout(function() {
      _audioSource.setAttribute('src', '');
      _audioSource.setAttribute('type', '');
      //_audioElement.setAttribute('src', '');
      //_audioElement.setAttribute('type', '');
    }, 1);
  };

  _audioElement.addEventListener('loadstart', function() {
    _touchInited = true;
  });
  var _touchInited = false;
  this.touchInit = function() {
    if (!_iOS) {
      return false;
    }

    if (_touchInited) {
      return false;
    }

    _touchInited = true;

    _audioSource.setAttribute('src', 'touch/init/html5/audio.mp3');
    //_audioElement.setAttribute('src', 'touch/init/html5/audio.mp3');
    _audioElement.load();

    return true;
  };

  var _playId = 0;

  var _seekQueuing = 0;

  this.playFile = function(
    smilSrc,
    epubSrc,
    seekBegin, //element
    epubSrcType
  ) {
    _playId++;
    if (_playId > 99999) {
      _playId = 0;
    }

    var playId = _playId;

    if (_audioElement.moSeeking) {
      _seekQueuing++;
      if (_seekQueuing > MAX_SEEK_RETRIES) {
        _seekQueuing = 0;
        return;
      }

      //if (DEBUG) {
        console.log(
          'this.playFile(' + epubSrc + ')' + ' @' + seekBegin + ' (POSTPONE, SEEKING...)',
        );
      //}

      setTimeout(function() {
        self.playFile(smilSrc, epubSrc, seekBegin, epubSrcType);
      }, 20);

      return;
    }

    _audioElement.moSeeking = {};

    //if (DEBUG) {
      console.log('this.playFile(' + epubSrc + ')' + ' @' + seekBegin + ' #' + playId + ' type:' + epubSrcType);
    //}

    var audioNeedsNewSrc = !_currentEpubSrc || _currentEpubSrc !== epubSrc;

    if (!audioNeedsNewSrc) {
      //if (DEBUG) {
        console.log('this.playFile() SAME SRC');
      //}

      this.pause();

      _currentSmilSrc = smilSrc;
      _currentEpubSrc = epubSrc;
      _currentEpubSrcType = epubSrcType
      playSeekCurrentTime(seekBegin, playId, false);

      return;
    }

    if (DEBUG) {
      console.debug('this.playFile() NEW SRC');
      console.debug('_currentEpubSrc: ' + _currentEpubSrc);
      console.debug('epubSrc: ' + epubSrc);
      console.debug('epubSrcType: ' + epubSrcType);
    }

    this.reset();
    _audioElement.moSeeking = {};

    _currentSmilSrc = smilSrc;
    _currentEpubSrc = epubSrc;
    _currentEpubSrcType = epubSrcType;

    //element.parentNode.insertBefore(_audioElement, element); //element.parentNode.childNodes[0]);

    if (!_Android) {
      _audioElement.addEventListener('play', onPlayToForcePreload, false);
    }

    $(_audioElement).on(_readyEvent, { seekBegin: seekBegin, playId: playId }, onReadyToSeek);

    setTimeout(function() {
      _audioSource.setAttribute('src', _currentEpubSrc);
      _audioSource.setAttribute('type', (_currentEpubSrcType || _currentEpubSrcType !== undefined)? _currentEpubSrcType :  'audio/mpeg');

      //_audioElement.setAttribute('src', _currentEpubSrc);
      //_audioElement.setAttribute('type', _currentEpubSrcType);
      // _audioElement.src = _currentEpubSrc;
      // $(_audioElement).attr("src", _currentEpubSrc);

      // if (_Android)
      // {
      //     _audioElement.addEventListener('loadstart', onReadyToPlayToForcePreload, false);
      // }

      _audioElement.load();

      if (!_Android) {
        playToForcePreload();
      }
    }, 1);
  };

  // var onReadyToPlayToForcePreload = function ()
  // {
  //     _audioElement.removeEventListener('loadstart', onReadyToPlayToForcePreload, false);
  //
  //     if (DEBUG)
  //     {
  //         console.debug("onReadyToPlayToForcePreload");
  //     }
  //
  //     playToForcePreload();
  // };

  var playToForcePreload = function() {
    if (DEBUG) {
      console.debug('playToForcePreload');
    }

    //_audioElement.volume = 0;
    //_audioElement.play();
    var vol = _volume;
    _volume = 0;
    self.play();
    _volume = vol;
  };

  var onPlayToForcePreload = function() {
    _audioElement.removeEventListener('play', onPlayToForcePreload, false);

    if (DEBUG) {
      console.debug('onPlayToForcePreload');
    }
    _audioElement.pause(); // note: interval timer continues (immediately follows self.play())
  };

  var _readyEvent = _Android ? 'canplaythrough' : 'canplay';
  function onReadyToSeek_(event) {
    if (DEBUG) {
      console.debug('onReadyToSeek #' + event.data.playId);
    }
    playSeekCurrentTime(event.data.seekBegin, event.data.playId, true);
  }
  function onReadyToSeek(event) {
    $(_audioElement).off(_readyEvent, onReadyToSeek);

    if (!_Android) {
      onReadyToSeek_(event);
    } else {
      if (DEBUG) {
        console.debug('onReadyToSeek ANDROID ... waiting a bit ... #' + event.data.playId);
      }

      //self.play();
      playToForcePreload();

      setTimeout(function() {
        onReadyToSeek_(event);
      }, 1000);
    }
  }

  function playSeekCurrentTime(newCurrentTime, playId, isNewSrc) {
    if (DEBUG) {
      console.debug('playSeekCurrentTime() #' + playId);
    }

    if (newCurrentTime == 0) {
      newCurrentTime = 0.01;
    }

    if (Math.abs(newCurrentTime - _audioElement.currentTime) < 0.3) {
      if (DEBUG) {
        console.debug('playSeekCurrentTime() CONTINUE');
      }

      _audioElement.moSeeking = undefined;
      self.play();
      return;
    }

    var ev = isNewSrc ? _seekedEvent1 : _seekedEvent2;

    if (DEBUG) {
      console.debug('playSeekCurrentTime() NEED SEEK, EV: ' + ev);
    }

    self.pause();

    $(_audioElement).on(
      ev,
      { newCurrentTime: newCurrentTime, playId: playId, isNewSrc: isNewSrc },
      onSeeked,
    );

    try {
      _audioElement.currentTime = newCurrentTime;
    } catch (ex) {
      console.error(ex.message);

      setTimeout(function() {
        try {
          _audioElement.currentTime = newCurrentTime;
        } catch (ex) {
          console.error(ex.message);
        }
      }, 5);
    }
  }

  var MAX_SEEK_RETRIES = 10;
  var _seekedEvent1 = _iOS ? 'canplaythrough' : 'seeked'; //"progress"
  var _seekedEvent2 = _iOS ? 'timeupdate' : 'seeked';
  function onSeeked(event) {
    var ev = event.data.isNewSrc ? _seekedEvent1 : _seekedEvent2;

    var notRetry = event.data.seekRetries == undefined;

    if (notRetry || event.data.seekRetries == MAX_SEEK_RETRIES) {
      // first retry
      $(_audioElement).off(ev, onSeeked);
    }

    if (DEBUG) {
      console.debug('onSeeked() #' + event.data.playId + ' FIRST? ' + notRetry + ' EV: ' + ev);
    }

    var curTime = _audioElement.currentTime;
    var diff = Math.abs(event.data.newCurrentTime - curTime);

    if ((notRetry || event.data.seekRetries >= 0) && diff >= 1) {
      if (DEBUG) {
        console.debug(
          'onSeeked() time diff: ' +
            event.data.newCurrentTime +
            ' vs. ' +
            curTime +
            ' (' +
            diff +
            ')',
        );
      }

      if (notRetry) {
        event.data.seekRetries = MAX_SEEK_RETRIES;

        // if (DEBUG)
        // {
        //     console.debug("onSeeked() fail => first retry, EV: " + _seekedEvent2);
        // }

        event.data.isNewSrc = false;
        //$(_audioElement).on(_seekedEvent2, event.data, onSeeked);
      }

      //else
      {
        event.data.seekRetries--;

        //Photon: After 10 retries return to avoid infinite loop.
        if(event.data.seekRetries <= 0) return;
        
        if (DEBUG) {
          console.debug('onSeeked() FAIL => retry again (timeout)');
        }

        setTimeout(function() {
          onSeeked(event);
        }, _Android ? 1000 : 200);
      }

      setTimeout(function() {
        _audioElement.pause();
        try {
          _audioElement.currentTime = event.data.newCurrentTime;
        } catch (ex) {
          console.error(ex.message);

          setTimeout(function() {
            try {
              _audioElement.currentTime = event.data.newCurrentTime;
            } catch (ex) {
              console.error(ex.message);
            }
          }, 4);
        }
      }, 5);
    } else {
      if (DEBUG) {
        console.debug('onSeeked() STATE:');
        console.debug(notRetry);
        console.debug(event.data.seekRetries);
        console.debug(diff);
      }

      if (diff >= 1) {
        if (DEBUG) {
          console.debug('onSeeked() ABORT, TRY AGAIN FROM SCRATCH!');
        }

        var smilSrc = _currentSmilSrc;
        var epubSrc = _currentEpubSrc;
        var seekBegin = event.data.newCurrentTime;
        var epubSrcType = _currentEpubSrcType;

        self.reset();

        setTimeout(function() {
          self.playFile(smilSrc, epubSrc, seekBegin, epubSrcType);
        }, 10);

        return;
      }

      if (DEBUG) {
        console.debug('onSeeked() OKAY => play!');
      }

      event.data.seekRetries = undefined;

      self.play();

      _audioElement.moSeeking = undefined;
    }
  }
};

export default AudioPlayer;