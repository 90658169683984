var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li>\n<label id=\"label_"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":3,"column":17},"end":{"line":3,"column":25}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"i18n") || (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":27},"end":{"line":3,"column":35}}}) : helper)))
    + "<br/><span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":3,"column":46},"end":{"line":3,"column":54}}}) : helper)))
    + "</span></label>\n<input id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":4,"column":11},"end":{"line":4,"column":19}}}) : helper)))
    + "\" name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":4,"column":27},"end":{"line":4,"column":35}}}) : helper)))
    + "\" class=\"keyboardInput\" type=\"text\"  placeholder=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"shortcut") || (depth0 != null ? lookupProperty(depth0,"shortcut") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"shortcut","hash":{},"data":data,"loc":{"start":{"line":4,"column":85},"end":{"line":4,"column":97}}}) : helper)))
    + "\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"shortcut") || (depth0 != null ? lookupProperty(depth0,"shortcut") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"shortcut","hash":{},"data":data,"loc":{"start":{"line":4,"column":106},"end":{"line":4,"column":118}}}) : helper)))
    + "\" aria-labelledbyxxx=\"label_"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":4,"column":146},"end":{"line":4,"column":154}}}) : helper)))
    + "\" aria-label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"i18n") || (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":4,"column":168},"end":{"line":4,"column":176}}}) : helper)))
    + "\" title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"i18n") || (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":4,"column":185},"end":{"line":4,"column":193}}}) : helper)))
    + "\"></input>\n<button class=\"resetKey captureKeyboardShortcut\" role=\"button\" data-key=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":5,"column":73},"end":{"line":5,"column":81}}}) : helper)))
    + "\"  title=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_reset_key") : stack1), depth0))
    + " ("
    + alias4(((helper = (helper = lookupProperty(helpers,"def") || (depth0 != null ? lookupProperty(depth0,"def") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"def","hash":{},"data":data,"loc":{"start":{"line":5,"column":119},"end":{"line":5,"column":126}}}) : helper)))
    + ")\" aria-label=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_reset_key") : stack1), depth0))
    + " ("
    + alias4(((helper = (helper = lookupProperty(helpers,"def") || (depth0 != null ? lookupProperty(depth0,"def") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"def","hash":{},"data":data,"loc":{"start":{"line":5,"column":169},"end":{"line":5,"column":176}}}) : helper)))
    + ")\"><span aria-hidden=\"true\">&#8855;</span></button>\n<span id=\"duplicate_keyboard_shortcut\" aria-hidden=\"true\">"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_duplicate_keyboard_shortcut") : stack1), depth0))
    + "</span>\n<span id=\"invalid_keyboard_shortcut\" aria-hidden=\"true\">"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_invalid_keyboard_shortcut") : stack1), depth0))
    + "</span>\n</li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li id=\"resetAllKeys"
    + alias1(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"id","hash":{},"data":data,"loc":{"start":{"line":11,"column":20},"end":{"line":11,"column":26}}}) : helper)))
    + "\" class=\"resetAllKeys\">\n<button class=\"resetKey\" role=\"button\"  title=\""
    + alias1(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_reset_key_all") : stack1), depth0))
    + "\" aria-label=\""
    + alias1(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_reset_key_all") : stack1), depth0))
    + "\"><span aria-hidden=\"true\">"
    + alias1(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_reset_key_all") : stack1), depth0))
    + "  &#8855;</span></button>\n</li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(options={"name":"name","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":9,"column":9}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"name")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(options={"name":"name","hash":{},"fn":container.noop,"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":10,"column":0},"end":{"line":14,"column":9}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"name")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});