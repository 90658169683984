import $ from 'jquery';
import _ from 'underscore';
import PageListParser from './PagelistParser';

var PaginationHelpers = function(readium, packageDocument) {
  var _spine;
  var pageListParser = new PageListParser(packageDocument);
  var epubCFI = window.ReadiumSDKExport.EpubCfi;

  this.resolveHref = function(href) {
    if (!href) {
      return;
    }
    var navDocHref = pageListParser.getNavDocHref();
    return this.resolveContentUrl(href, navDocHref);
  };

  this.goToHref = function(href) {
    if (!href) {
      return;
    }
    var navDocHref = pageListParser.getNavDocHref();
    readium.reader.openContentUrl(href, navDocHref);
  };

  this.goToCfi = function(cfi) {
    if (!cfi) {
      return;
    }
    var cfiobj = _.isString(cfi) ? this.cfiStringToObj(cfi) : cfi;
    if (!cfiobj) {
      return;
    }
    //a dummy cfi can be passed here by preview cfi indexing
    // if so go to the end of the preview
    if (cfiobj.idref === '**dummy**' || cfiobj.contentCFI === '**dummy**') {
      readium.reader.openSpineIndexPage(-1, -1);
      return;
    }
    return readium.reader.openSpineItemElementCfi(cfiobj.idref, cfiobj.contentCFI);
  };

  // //currently not being used, may be needed later
  // this.gotoCfi = function(cfiEncoded) {
  //     var decoded = ATB.Helpers.b64_to_utf8(cfiEncoded);
  //     this.lastDecodedCFI = decoded;
  //     if (ATB.book) {
  //         ATB.book.gotoPosition({cfi: decoded});
  //     }
  // };
  //
  // this.b64_to_utf8 = function (str) {
  //     try {
  //         return new TextDecoderLite('utf-8').decode(window.b64toByteArray(str));
  //     } catch (e) {
  //         console.error(e);
  //         return null;
  //     }
  // };

  this.getCurrentSpineItems = function() {
    return readium.reader.getLoadedSpineItems();
  };

  this.isVisibleCFI = function(cfiobj) {
    var cfiObjLocal = cfiobj;
    if (_.isString(cfiObjLocal)) {
      cfiObjLocal = this.cfiStringToObj(cfiObjLocal);
    }
    if (!cfiObjLocal || !cfiObjLocal.contentCFI) {
      return false;
    }

    // 2017-12-21
    // KSakai: Adding an error check here due to exception errors in the readium library
    try {
      return readium.reader.isVisibleSpineItemElementCfi(cfiObjLocal.idref, cfiObjLocal.contentCFI);
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  this.cfiStringToObj = function(cfi) {
    if (!_.isString(cfi)) {
      console.error('[EpubViewerHandler::CFIStringToObj] null input');
      return null;
    } else if (cfi.match(/^epubcfi\(/)) {
      console.error(
        'Absolute CFI used: Conversion from absolute CFIs to partial CFIs is no longer supported.',
      );
      return { idref: null, contentCFI: null };
    }
    try {
      return window.ReadiumSDKExport.BookmarkData.fromString(cfi);
    } catch (e) {
      console.warn('Unable to get bookmark data from cfi!');
      return null;
    }
  };

  this.contentCfiComparator = function(cfiA, cfiB) {
    var comparisonResult = epubCFI.Interpreter.compareCFIs(
      'epubcfi(/99!' + cfiA + ')',
      'epubcfi(/99!' + cfiB + ')',
    );
    return comparisonResult[0]; // Discarding the second value because we are not comparing ranges here
  };

  this.parseContentCfi = function(cont) {
    if (!cont) {
      return;
    }
    var parseResult = cont
      .replace(/\[(.*?)\]/, '')
      .split(/[/,]/)
      .map(function(n) {
        if (!_.includes(n, ':')) {
          return parseInt(n);
        }
        return n;
      })
      .filter(function(n) {
        return !_.isNaN(n);
      });
    return parseResult;
  };

  this.formatForComparison = function(cont) {
    //fixing the end string format
    var length = cont.length;
    //check if last element is a string, representing the start of a text node, if not push the first possible value onto the array
    if (!_.isString(cont[length - 1])) {
      cont.push('1:0');
    }
    //collapse all duplicate elements
    var condensed = [cont[0]];
    var currentVal = cont[0];
    var i;
    for (i = 1; i < cont.length; i++) {
      if (currentVal === cont[i] && currentVal === 2) {
        continue;
      } else {
        condensed.push(cont[i]);
        currentVal = cont[i];
      }
    }
    return condensed;
  };

  this.formatLabelItems = function(labelItems) {
    var formattedLabelItems = [];

    labelItems.forEach(function(labelItem) {
      if (typeof labelItem.cfi === window.ReadiumSDKExport.BookmarkData) {
        formattedLabelItems.push(labelItem);
      } else {
        var newItem = {
          cfi: { idref: labelItem.cfi.idref, contentCFI: labelItem.cfi.contentCFI },
          label: { label: labelItem.label.label, index: labelItem.label.index },
        };

        formattedLabelItems.push(newItem);
      }
    });

    return formattedLabelItems;
  };

  this.resolveContentUrl = function(contentRefUrl, sourceFileHref, initiator) {
    var combinedPath = this.resolveContentRefHelper(contentRefUrl, sourceFileHref);

    var hashIndex = combinedPath.indexOf('#');
    var hrefPart;
    var elementId;
    if (hashIndex >= 0) {
      hrefPart = combinedPath.substr(0, hashIndex);
      elementId = combinedPath.substr(hashIndex + 1);
    } else {
      hrefPart = combinedPath;
      elementId = undefined;
    }

    if (!_spine) _spine = this.getSpine();

    var spineItem = _spine.getItemByHref(hrefPart);
    if (!spineItem) {
      console.warn('spineItem ' + hrefPart + ' not found');
      // sometimes that happens because spine item's URI gets encoded,
      // yet it's compared with raw strings by `getItemByHref()` -
      // so we try to search with decoded link as well
      var decodedHrefPart = decodeURIComponent(hrefPart);
      spineItem = _spine.getItemByHref(decodedHrefPart);
      if (!spineItem) {
        console.warn('decoded spineItem ' + decodedHrefPart + ' missing as well');
        return false;
      }
    }
    return { href: hrefPart, elementId: elementId, idref: spineItem.idref };
  };

  this.resolveContentRefHelper = function(contentRef, sourceFileHref) {
    if (!sourceFileHref) {
      return contentRef;
    }

    var sourceParts = sourceFileHref.split('/');
    sourceParts.pop(); //remove source file name

    var pathComponents = contentRef.split('/');

    while (sourceParts.length > 0 && pathComponents[0] === '..') {
      sourceParts.pop();
      pathComponents.splice(0, 1);
    }

    var combined = sourceParts.concat(pathComponents);

    return combined.join('/');
  };

  this.openSpineIndexPage = function(spineIndex, pageIndex, initiator) {
    if (!_spine) _spine = this.getSpine();

    var spineItem;
    if (spineIndex === -1) {
      spineItem = _spine.last();
    } else {
      spineItem = _spine.items[spineIndex];
    }
    if (!spineItem) {
      return false;
    }

    var pageRequest = new PageOpenRequest(spineItem, initiator);

    if (pageIndex === -1) {
      pageRequest.setLastPage();
    } else if (pageIndex) {
      pageRequest.setPageIndex(pageIndex);
    }

    openPage(pageRequest, 0);
    return true;
  };

  this.getSpine = function() {
    return _spine || readium.reader.spine();
  };
};

export default PaginationHelpers;
