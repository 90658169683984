import $ from 'jquery';
import _ from 'underscore';
import PopupFactory from '../../popup/PopupFactory';
import Menu from '../../menus/Menu';
import SleepTimer from '../../audiobook/SleepTimer';
import SleepTimerMenuTemplate from '../../templates/sleep-timer-menu.hbs';
import Feature from '@axisnow/data/app-permission/Feature';
import Managers from '@axisnow/data/Managers';
import AccessibilityManager from '../../accessibility/AcessibilityManager';

// Sleep Timer options
var sleepTimerPopup = PopupFactory.create(PopupFactory.TYPE.NAVBAR_MENU, 'Sleep Timer', {
  id: 'sleepTimer',
  cssClass: 'size height unit-auto app-sleep-timer-menu ',
});

// Adding attributes for WA
sleepTimerPopup.$el.attr('role', 'dialog');

// Assigned Sleep Timer Html content to variable
var $sleepTimerContainerLayoutContent = $(SleepTimerMenuTemplate({}));
sleepTimerPopup.clearContainerLayouts();
var $sleepTimerContainerLayout = sleepTimerPopup.addContainerLayout(
  $sleepTimerContainerLayoutContent,
);

var SleepTimerMenu = function() {
  Menu.apply(this, arguments);
  this.popup(sleepTimerPopup);
};

SleepTimerMenu.prototype = Object.create(Menu.prototype);

var featureManager = Managers.feature;
featureManager.feature(
  new Feature(
    'SleepTimer',
    function() {
      $('#btnToggleSleepTimer').show();
      console.log('AudioBook Sleep Timer enabled');
    },
    function() {
      $('#btnToggleSleepTimer').hide();
      console.log('AudioBook Sleep Timer disabled');
    },
  ),
);

SleepTimerMenu.prototype.show = function() {
  var self = this;
  if (!this._sleepTimer) {
    $sleepTimerContainerLayout.find('.sleep-time-slider').prop('value', 0);
    $sleepTimerContainerLayout.find('.sleep-time-slider').attr('aria-valuenow', 0);
    $sleepTimerContainerLayout.find('.sleep-time-slider').attr('aria-valuetext', 'off');
  }

  this._sleepTimer = new SleepTimer(
    self.findaway,
    $sleepTimerContainerLayout.find('.sleep-time-slider'),
    $sleepTimerContainerLayout.find('.time-stamp'),
  );
  $sleepTimerContainerLayout.find('.sleep-time-slider').on('input', function() {
    onChange();
  });

  $sleepTimerContainerLayout.find('.sleep-time-slider').on('change', function() {
    _.debounce(onChange(), 300);
  });

  var onChange = function() {
    var time = $sleepTimerContainerLayout.find('.sleep-time-slider').prop('value');
    self._sleepTimer.setTimer(parseInt(time));
    $sleepTimerContainerLayout
      .find('.sleep-time-slider')
      .attr('aria-valuenow', $sleepTimerContainerLayout.find('.sleep-time-slider').prop('value'));
    $sleepTimerContainerLayout
      .find('.sleep-time-slider')
      .attr(
        'aria-valuetext',
        slider_aria($sleepTimerContainerLayout.find('.sleep-time-slider').prop('value')) +
          self._sleepTimer.$display.html(),
      );
  };

  this._popup.show();

  // AccessibilityManager.setTabGroup("sleep_timer_popup")
  // AccessibilityManager.updateTabOrder("sleep_timer_popup")
};

SleepTimerMenu.prototype.getSleepTimer = function() {
  return this._sleepTimer;
};

function slider_aria(value) {
  switch (value) {
    case '0':
      return 'off';
      break;
    case '15':
      return '15 min';
      break;
    case '30':
      return '30 min';
      break;
    case '45':
      return '1 hour';
      break;
    case '60':
      return 'End';
      break;
  }
}

export default SleepTimerMenu;
