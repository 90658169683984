import $ from 'jquery';
import AnnotationsUsage from './AnnotationsUsage';
import EpubReaderHelpers from '../EpubReaderHelpers';

/*function EbookAnnotationsUsage(isbn, uuid, eventEmitter) {

        Object.defineProperties(this, {
            "spines": this.sections
        });
    }*/

var EbookAnnotationsUsage = function() {
  AnnotationsUsage.apply(this, arguments);
  //this.spines = this.sections;

  Object.defineProperties(this, {
    spines: {
      get: function() {
        return this.sections;
      },
      set: function(value) {
        if (value instanceof Array) {
          value.forEach(function(element, index) {
            element.sectionId = element.refid;
          });
        } /*else if (typeof value === "object" ){
                        sections = value;
                    }else{
                        throw new Error ("Unable to set sections. Invalid data.");
                    }*/
        this.sections = value;
      },
      enumerable: true,
    },
  });
};

EbookAnnotationsUsage.usageName = 'EbookAnnotationsUsage';

EbookAnnotationsUsage.prototype = Object.create(AnnotationsUsage.prototype);

EbookAnnotationsUsage.locationEquals = EpubReaderHelpers.cfiEquals;

EbookAnnotationsUsage.sortBy = $.extend({}, AnnotationsUsage.sortBy, {
  COLOR: function(annotationsArray, ascending) {
    annotationsArray = annotationsArray.sort(function(a, b) {
      var colorA = a.color.toLowerCase();
      var colorB = b.color.toLowerCase();

      if (ascending) {
        if (colorA < colorB) {
          return -1;
        } else if (colorA > colorB) {
          return 1;
        } else {
          return 0;
        }
      } else {
        if (colorA > colorB) {
          return -1;
        } else if (colorA < colorB) {
          return 1;
        } else {
          return 0;
        }
      }
    });
    return annotationsArray;
  },
});

//Public API
EbookAnnotationsUsage.prototype.sortBy = EbookAnnotationsUsage.sortBy;

EbookAnnotationsUsage.prototype.locationEquals = EbookAnnotationsUsage.locationEquals;

export default EbookAnnotationsUsage;
