var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"audioplayer\">\n   \n    <button tabindex=\"0\" id=\"btn-play-audio\" type=\"button\" class=\"navbar-btn\"  title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_audio_play") : stack1), depth0))
    + "\"  aria-labelledby=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_audio_play") : stack1), depth0))
    + "\" accesskey=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"keyboard") : depth0)) != null ? lookupProperty(stack1,"accesskeys") : stack1)) != null ? lookupProperty(stack1,"MediaOverlaysPlayPause") : stack1), depth0))
    + "\">\n        <span class=\"icon icon-play\"></span>\n        <span aria-hidden=\"true\" class=\"navbar-btn-label\">Play</span>\n    </button>\n\n    <button tabindex=\"0\" id=\"btn-pause-audio\" type=\"button\" class=\"navbar-btn\"  title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_audio_pause") : stack1), depth0))
    + "\"  aria-labelledby=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_audio_pause") : stack1), depth0))
    + "\">\n        <span class=\"icon icon-icon-pause\"></span>\n        <span aria-hidden=\"true\" class=\"navbar-btn-label\">Pause</span>\n    </button>\n\n    <button tabindex=\"0\" id=\"btn-collapse-audio\" type=\"button\" class=\"navbar-btn icon-top\" title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_audio_collapse") : stack1), depth0))
    + "\" aria-labelledby=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_audio_collapse") : stack1), depth0))
    + "\" accesskey=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"keyboard") : depth0)) != null ? lookupProperty(stack1,"accesskeys") : stack1)) != null ? lookupProperty(stack1,"MediaOverlaysAdvancedPanelShowHide") : stack1), depth0))
    + "\">\n        <span class=\"icon icon-icon-tts-settings\"></span>\n        <span aria-hidden=\"true\" class=\"navbar-btn-label\">Audio Options</span>\n    </button>\n\n    <button tabindex=\"0\" id=\"btn-expand-audio\" type=\"button\" class=\"navbar-btn icon-top\" title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_audio_expand") : stack1), depth0))
    + "\" aria-labelledby=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"i18n_audio_expand") : stack1), depth0))
    + "\" accesskey=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"keyboard") : depth0)) != null ? lookupProperty(stack1,"accesskeys") : stack1)) != null ? lookupProperty(stack1,"MediaOverlaysAdvancedPanelShowHide") : stack1), depth0))
    + "\">\n        <span class=\"icon icon-icon-tts-settings\"></span>\n        <span aria-hidden=\"true\" class=\"navbar-btn-label\">Audio Options</span>\n    </button>  \n\n    <button class=\"navbar-btn btnToggleNotesRA icon-top\" type=\"button\" id=\"btnToggleNotes\" title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"notes_highlights") : stack1), depth0))
    + "\"\n        accesskey=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"keyboard") : depth0)) != null ? lookupProperty(stack1,"accesskeys") : stack1)) != null ? lookupProperty(stack1,"NotesHighlightsMenu") : stack1), depth0))
    + "\">\n        <span class=\"icon icon-notes\"></span>\n        <span aria-hidden=\"true\" class=\"navbar-btn-label\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"notes_highlights") : stack1), depth0))
    + "</span>\n    </button>\n        <button class=\"navbar-btn hidden\" type=\"button\" id=\"btnToggleShare\">\n        <span aria-hidden=\"true\" class=\"icon icon-share\"></span>\n        <span aria-hidden=\"true\" class=\"navbar-btn-label\">Share</span>\n    </button>\n    <button class=\"navbar-btn btnToggleSearchRA icon-top\" type=\"button\" id=\"btnToggleSearch\" title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"search") : stack1), depth0))
    + "\"\n        accesskey=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"keyboard") : depth0)) != null ? lookupProperty(stack1,"accesskeys") : stack1)) != null ? lookupProperty(stack1,"Search") : stack1), depth0))
    + "\">\n        <span class=\"icon icon-search search-icon-pos\"></span>\n        <span aria-hidden=\"true\" class=\"navbar-btn-label\">Search</span>\n    </button>\n    <button class=\"navbar-btn btnToggleHelpRA\" type=\"button\" id=\"btnToggleHelp\" title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"strings") : depth0)) != null ? lookupProperty(stack1,"help") : stack1), depth0))
    + "\"\n        accesskey=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"keyboard") : depth0)) != null ? lookupProperty(stack1,"accesskeys") : stack1)) != null ? lookupProperty(stack1,"HelpMenu") : stack1), depth0))
    + "\">\n        <!--- WorkItem 82688 UIRefresh --->\n        <span class=\"icon icon-help help-icon-pos\">\n            <!-- <span class=\"path1\"></span>\n            <span class=\"path2\"></span> -->\n        </span>\n        <span aria-hidden=\"true\" class=\"navbar-btn-label\">Help</span>\n    </button> \n</div>\n";
},"useData":true});