import $ from 'jquery';
import _ from 'underscore';
import Strings from './i18n/Strings';
import URI from 'urijs';
import PopupFactory from './popup/PopupFactory';
import splashMessage from './SplashMessage';
import ComicNavbar from './templates/comic-navbar.hbs';
import ComicLanding from './templates/comic-landing.hbs';
import ReaderBookTitleHeader from './templates/reader-book-title-header.hbs';
import Keyboard from './Keyboard';
import UsageManager from './usage/UsageManager';
import ComicManager from './ComicManager';
import Managers from '@axisnow/data/Managers';
import utils from '@axisnow/util/utils';
import Errors from '@axisnow/util/Errors';
import errorMonitor from './ErrorMonitor';
import moment from 'moment-timezone';
import BookmarksMenu from './menus/navbar/BookmarksMenu';
import HelpMenu from './menus/navbar/HelpMenu';
import MainMenu from './menus/navbar/MainMenu';
import Helpers from './EpubReaderHelpers';
import ActivateCST from './CacheStatusTool';
import FocusTrap from 'focus-trap';
import EncryptionDocumentParser from '../packages/fetch/parsers/encryption_document_parser';
import EncryptionHandler from '../packages/fetch/encryption_handler';
import PublicationFetcher from '@axisnow/fetch/publication_fetcher';
import ResourceCache from '../packages/fetch/resource_cache';
import ContentTransformer from './ComicTransformer';
//import BookOptionsMenu from './menus/navbar/BookOptionsMenu';
import BookViewOptions from './templates/bookview-options.hbs';
import EngageManager from './EngageManager';
import PubSub from 'pubsub-js'; //for panel update
import AXISAnalyticshelper from './AXISAnalyticshelper';
import Hammer from 'hammerjs';

var _isReady;
var usageManager;
var comicManager;
var player;

//All Popup declarations, Confirmation moda and navbar and inline menu declarations
var popupModal = PopupFactory.create(PopupFactory.TYPE.MODAL);
var audioSpeedMenu; //Audio Speed
var bookmarksMenu; //Bookmarks
var sleepTimerMenu; //Sleep Timer
var helpMenu; //Help
var mainMenu; //MainMenu
var bookOptionsMenu; //BookMenu
var book;
var remoteInterval;
var localInterval;
var remotePosition;
var update;
var _deferredXmlDom = $.Deferred();

var $appContainer;
var $chapterTitle;
var $coverImage;
var $currentTime;
var $remainingTime;
var $wdgSlider;
var $stRemainingTime;
var $imageWidth;
var sleepTimer;
var env;
var user;
//iOS fix for initial playback
var firstTimeOptions;
// A flag for dragging the slider and stopping the timeChange event
var flagSliderPress = false;

var inactivityTimerId = undefined; // FSS timer id for inactivity

var isTitleWave = false; // FSS local flag to hide features for TitleWave
var returnDate; // FSS local variable for DueDate
var isCheckedOut = false; // FSS local variable for isCheckedOut
var isGuestUser = false; // FSS local variable for guest users
var bookTitle; // FSS local variable for Title
var isMyDestiny = false; // FSS local variable for MyDestiny modes
var audiobookId; // FSS local
var customerNumber; // FSS local
var isClassSet = false; //FSS Local Variable for Class Set Titles
var navbarOverflowMenuFocusTrap;
var navbarOverflowMenuFocusTrapActive = false;
var isTemporary = false;
var fssUserAudiobookLocationOverrideForD_28639 = 0;

var glass, glassContainer, prevBtn, nextBtn;
var currentPage = null;
var currentVisible = -1;
var srcImg;
var srcImgSource;
var comicBkBasePath;
var zoomLevel = 0;
var preLoadHL = null;
var preFetchHL1 = null;
var preFetchHL2 = null;
var bookTitle;
var comicUrl;
var isbn;
var tocData;
var menuItems = [];
var encryptionData;
var encryptionDom;
var comicMetaData = {
  type: "cbz",
  url: "",
  title: "",
  author: "",
  coverpage: "",
  pages: [],
  pageIndex: ""
};
var license;
var location = null;

var comicBkPage = {
  image: "",
  frames: []
};
var findPackageResponse;
var parsePackageResponse;
var publicationFetcher;
var resourceCache = new ResourceCache(window)
var _resourceFetcher;
var _encryptionHandler;
var contentTransformer;
var currentFormat;
var clickedMenuItem;

var readium;
var zoomLevels = [1.0, 1.1, 1.3, 1.5, 2.0];
var zoomPointer = 0;
var _comicManager;
var eventPayload;
var currentViewer = 'page';
var resizeIsTriggered = 0;
var engageManager;
var bookMarkList =[];  //bookmark list for update panel
var checkPage; //check page or not for update panel; 
var currentLocationIndex;  //current location index;
var formatResponseLength;
var initialLoad; //it used to check initial page location status in ADCB function
var goToPage;
var clickedBookmarkItem;
var enablePinchZoom;
var selectedFormatId;

/**
 * Subscribe event when Bookmarks listed
 */
PubSub.subscribe('BOOKMARK-MENU-LIST-KEY',function (msg,data) {
if($.isArray(data)){
  if(data.length > 0){
     bookMarkList = data; 
  }
}
})

//Book View options dialog
var bookviewOptionsPopup = PopupFactory.create(PopupFactory.TYPE.NAVBAR_MENU, 'Format', {
  id: 'bookviewoptions',
  cssClass: 'size height unit-auto app-bookview-options ',
});

//var isIOS = navigator.userAgent.match(/(iPad|iPhone|iPod)/g) ? true : false;

function bindToggleZoomIn() {
  var imgHeight = Math.floor($("#glass").height());
  var screenHeight = Math.floor($("#reading-area").height());
  var imgWidth = Math.floor($("#glass").width());
  var screenWidth = Math.floor($("#reading-area").width());
  if((imgHeight == screenHeight) || (imgWidth == screenWidth) || (imgWidth > screenWidth) || (imgHeight > screenHeight) || ((screenWidth-imgWidth) <= 57)){
    $('#btnToggleZoomIn').attr('disabled', true);
    return;
  }
  else{
    zoomPointer = zoomPointer + 1;
    if (zoomPointer >= (zoomLevels.length-1)) {
      zoomPointer = zoomLevels.length -1;
      return;
    }
    zoomLevel = parseFloat(zoomLevels[zoomPointer]);

    updateZoom();
  }
}

function bindToggleZoomOut() {
  zoomPointer = zoomPointer - 1;
  if (zoomPointer < 0) {
    zoomPointer = 0;
    return;
  }
  zoomLevel = parseFloat(zoomLevels[zoomPointer]);  
  $('#btnToggleZoomIn').attr('disabled', false);
  updateZoom();
}

function updateZoom() {
  var curArea;
  var currPage = window.sessionStorage.getItem('COMIC_CURRENT_PAGE');
  if(window.sessionStorage.getItem('getCurrentAreaIndex')){
    curArea = window.sessionStorage.getItem('getCurrentAreaIndex');
  } else{
    curArea = window.sessionStorage.getItem('COMIC_CURRENT_AREA');
  }
  var location = (currPage == null) ? '-1' : currPage;
  
  if (curArea && curArea != -1) {
    location = location + '|' + curArea;
  }
  $('#app-container').trigger('navigateArea', [location]);
}

/********Accessibility*****/
document.onkeyup = function(e) {
  if (e.altKey && (e.keyCode == 187))
  {
    e.preventDefault();
    bindToggleZoomIn();
  }
  if (e.altKey && (e.keyCode == 189))
  {
    e.preventDefault();
    bindToggleZoomOut();
  }
  if (e.altKey && (e.keyCode == 70))
  {
    e.preventDefault();
    $('#btnFullScreenView').trigger('click');
  }
  if (e.altKey && (e.keyCode == 69))
  {
    e.preventDefault();
    $('#btnExitFullScreenView').trigger('click');
  }
  if((e.key === "Escape") || (document.activeElement.id == "btnFullScreenView") || ($(e.target).attr('id') == "btnFullScreenView")) {
    $('.select_wrap').removeClass('active');
    $(".default_option").attr('aria-expanded', 'false');
  }

  if($(e.target).attr('id') == "formattxt"){
    var inputval, filter, ul, li, a, i, txtValue, span, div;
    //var inputval = $(e.target.value);
    filter = e.target.value.toUpperCase();
    ul = document.getElementById("selFormatList");
    li = ul.getElementsByTagName("li");
    for (i = 0; i < li.length; i++) {
        a = li[i].getElementsByTagName("span")[0];
        txtValue = a.textContent || a.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
            li[i].style.display = "";           
        } else {
            li[i].style.display = "none";         
        }
        $('.select_wrap').addClass('active');
        $(".default_option").attr('aria-expanded', 'true');
    }
    $('#formattxt').keyup(function(e) {
      if(e.which == 40){
        if($("#selFormatList li.focusedSel").length!=0) {
          var storeTarget	= $('#selFormatList').find("li.focusedSel").next();
          $("#selFormatList li.focusedSel").removeClass("focusedSel");
          storeTarget.focus().addClass("focusedSel");
        }
        else {
          $('#selFormatList').find("li:first").focus().addClass("focusedSel");
        }
        return ;
      }
      if(e.which == 38){
        if($("#selFormatList li.focusedSel").length!=0) {
          var storeTarget	= $('#selFormatList').find("li.focusedSel").prev();
          $("#selFormatList li.focusedSel").removeClass("focusedSel");
          storeTarget.focus().addClass("focusedSel");
        }
        else {
          $('#selFormatList').find("li:first").focus().addClass("focusedSel");
        }
        return ;
      }      
    });
  }

  /**** Flip pages on click left/up and right/down arrow ***/
  if ((e.keyCode == 37) || (e.keyCode == 38) || (e.keyCode == 33)) {
    e.preventDefault();
    var expandStatus = $('.default_option').attr('aria-expanded');
    if((expandStatus == undefined) || (expandStatus == "false")){
      $('#left-page-btn').trigger('click');  
    }     
  }
  if ((e.keyCode == 39) || (e.keyCode == 40) || (e.keyCode == 34) ) {
    e.preventDefault();
    var expandStatus = $('.default_option').attr('aria-expanded');
    if((expandStatus == undefined) || (expandStatus == "false")){
      $('#right-page-btn').trigger('click');
    }    
  }
}

function checkAndSetForFullScreen() {
  var fullscreenElement = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullscreenElement || document.msFullscreenElement;
	
	if (!fullscreenElement) {
		$("#btnFullScreenView").removeClass("hidden");
    $("#btnExitFullScreenView").addClass("hidden");
    $('.book-title-header').removeClass('book-title-header-white');
    $("#app-fullscreen").attr("style", "");
    //$('#btnExitFullScreenView span:first').attr('class', 'icon icon-full-screen full-screen-icon-pos'); //update icon
	} else {
		$("#btnFullScreenView").addClass("hidden");
    $("#btnExitFullScreenView").removeClass("hidden").addClass("active");
    $('.book-title-header').addClass('book-title-header-white');
    $("#app-fullscreen").attr("style", "background-color:#000000");
    //$('#btnFullScreenView span:first').attr('class', 'icon icon-active-full-screen'); //update icon
	}
  //update icon
  iconUpdate('', 'all');
}
/**
 * Icon Update
 * @param {*} btnName 
 * @param {*} status 
 */
function iconUpdate(btnName, status) {
  try {
    if ((btnName === "#btnToggleBookmark" && status === "hide")) {
      $('#btnToggleBookmark span:first').attr('class', 'icon icon-bookmark-outline');
    } else if((btnName === "#btnToggleBookmark" && status === "show")) {
      $('#btnToggleHelp span:first').attr('class', 'icon icon-help');
      $('#btnToggleBookmark span:first').attr('class', 'icon icon-active-bookmark-outline');
    } else if((btnName === "#btnToggleHelp" && status === "hide")) {
      $('#btnToggleHelp span:first').attr('class', 'icon icon-help');
    } else if ((btnName === "#btnToggleHelp" && status === "show")) {
      $('#btnToggleBookmark span:first').attr('class', 'icon icon-bookmark-outline');
      $('#btnToggleHelp span:first').attr('class', 'icon icon-active-help');
    } else {
      $('#btnToggleHelp span:first').attr('class', 'icon icon-help');
      $('#btnToggleBookmark span:first').attr('class', 'icon icon-bookmark-outline');
    }
  } catch (error) {
    console.error('IconIUpdate-catch',error.message);
  }
}
/**
 * Bookmarklist/Help menu open onclick event
 * @param {*} selector 
 * @param {*} hotkey 
 * @param {*} menu 
 */
function bindToggleMenu(selector, hotkey, menu) {
  $(selector).on('click', function () {
    //Remove all active classes
    $('.navbar-btn').removeClass('active');
    $('.select_wrap').removeClass('active');
    if (menu.isVisible()) {
      iconUpdate(selector, 'hide');
      menu.hide();
    } else {
      $('.popup:visible').hide();
      $('#navbar-overflow-overlay').css('display', 'none');
      $('.navbar-overflow-menu').css('display', 'none');
      iconUpdate(selector, 'show');
      menu.show();
      $(this).addClass('active');
    }
  });

  $(menu._popup.$header).on('touchmove', function (event) {
    if (event.target.type != 'button') {
      console.log('preventDefault ' + this);
      event.preventDefault();
    }
  });

  Keyboard.on(hotkey, 'reader', function () {
    $(selector).trigger('click');
  });
}

function readEncriptionData(callback) {
  return new Promise(function (resolve, reject) {
      var encryptionUrl = '/encryption.xml';
        
      _comicManager.getEncyptionDom(encryptionUrl, publicationFetcher, function (encryptionDom, error) {
        if (error) {
          reject('encryption dom error');
        } else {
          resolve(encryptionDom);
        }
      },
      function(){
        console.error("ComicReader readEncriptionData failure!!");
        _encryptionHandler = new EncryptionHandler(undefined);
        reject('encryption dom error')
      }
      );
  });
}

function readComicACBFile() {
  if(!_encryptionHandler){
    _encryptionHandler = new EncryptionHandler(encryptionData, license);
  }

  return _comicManager.fetchComicFromRemote(isbn, publicationFetcher, _encryptionHandler);
}

function fetchLicence() {
  return new Promise(function (resolve, reject) {
    var isbn = new URI(comicUrl).filename();
    var self = this;

    Managers.license.fetchLicense(isbn).then(function (licenseObj) {
      license = licenseObj.license;
      publicationFetcher = new PublicationFetcher(comicUrl, null, null, null, null, resourceCache);
      var encryptionParser = new EncryptionDocumentParser(publicationFetcher);

      readEncriptionData()
        .then(function (encDom) {
          encryptionDom = encDom;
          encryptionData = encryptionParser.parse(isbn, encryptionDom);
          contentTransformer = new ContentTransformer(encryptionData, license);
          resolve(true);
          }, function () {
            console.error('ComicReader.fetchLicence:', 'Dom encryptionerror');
       });
    }).catch(function(err) {
      errorMonitor.bugsnag(err);
      splashMessage.init({
        closeBtn: false,
        cssClass: 'splash-system',
      });
      var error = new Errors.UnexpectedError(
         err.name,
        'Unexpected Error! Please report error.',
        'Error',
      );
      $.extend(error, { urlParams: Helpers.getURLQueryParams(), origError: err });
      splashMessage.message(error);
      splashMessage.$el.show();
    });
  });
}

function decryptContent(fileName) {
  if(!_encryptionHandler){
    _encryptionHandler = new EncryptionHandler(encryptionData, license);
  }
  
  return new Promise(function (resolve, reject) {
    publicationFetcher.relativeToPackageFetchComicBookFileContents(fileName, "blob", _encryptionHandler, function (status, resourceUrl) {
      if(status == "success"){
        resolve(resourceUrl);
      } else {
        reject(null);
      }
    });
  });
}

var loadlibrary = function() {
  $('html').attr('data-theme', 'library');

  var urlParams = Helpers.getURLQueryParams();
  var ebookURL = urlParams.epub;
  var libraryURL;
  if (ebookURL) {
    libraryURL = new URI('/epub_library.json', ebookURL);
    libraryURL.protocol('offline:');
  } else {
    libraryURL = urlParams.epubs;
  }

  $(window).triggerHandler('loadlibrary', libraryURL.toString());
};

function setupMenus() {
  Keyboard.scope('reader');

  //audioSpeedMenu = new AudioSpeedMenu(null, usageManager, null, null, player);
  bookmarksMenu = new BookmarksMenu(null, usageManager, isbn, null, null, comicManager);
  //sleepTimerMenu = new SleepTimerMenu(null, usageManager, null, null, player);
  helpMenu = new HelpMenu(null, usageManager, null);
  mainMenu = new MainMenu(null, usageManager, isbn, null, null, comicManager);
  //bookOptionsMenu = new BookOptionsMenu(null, usageManager, isbn, null, isCheckedOut, returnDate, bookTitle, updateActivity);

  //bindToggleMenu('#btnToggleAudioSpeed', Keyboard.AudioSpeedMenu, audioSpeedMenu);
  bindToggleMenu('#btnToggleBookmark', Keyboard.Bookmark, bookmarksMenu);
  //bindToggleMenu('#btnToggleSleepTimer', Keyboard.SleepTimerMenu, sleepTimerMenu);
  bindToggleMenu('#btnToggleMainMenu', Keyboard.Menu, mainMenu);
  //bindToggleMenu('#btnToggleBookOptions', Keyboard.BookOptionsMenu, bookOptionsMenu);
  // FSS help menu opens in a new tab
  bindToggleMenu('#btnToggleHelp', Keyboard.HelpMenu, helpMenu);

  Keyboard.on(Keyboard.HelpMenu, 'reader', function () {
    $('#btnToggleHelp').trigger('click');
  });

  Keyboard.on(Keyboard.ZoomIn, 'reader', function() {
    $('#btnToggleZoomIn').trigger('click');
  });
  Keyboard.on(Keyboard.ZoomOut, 'reader', function() {
    $('#btnToggleZoomOut').trigger('click');
  });

  Keyboard.on(Keyboard.FullScreen, 'reader', function() {
    $('#btnFullScreenView').trigger('click');
  });
  Keyboard.on(Keyboard.ExitFullScreen, 'reader', function() {
    $('#btnExitFullScreenView').trigger('click');
  });

  $('#btnToggleZoomOut').hide();
  $('#btnToggleZoomIn').hide();

  if (eventPayload.hasOwnProperty('formatResponse')) {
    var isEpubFormat = $.inArray('epub', eventPayload.formatResponse)

    if (isEpubFormat != -1) {
      $('#btnToggleFormatSwitch').show();
    }
    else {
      $('#btnToggleFormatSwitch').hide();
    }
  }
  else {
    $('#btnToggleFormatSwitch').hide();
  }

  //**************************** OVERFLOW ***************************//
  // FSS - Added for 43146 - Overflow menu fix
  $('#btnToggleMore').on('click', function () {
    $('.navbar-btn').removeClass('active');
    $('#btnToggleBookmark').removeClass('currentActive');
    $('.select_wrap').removeClass('active');
    popupModal.hideAll();
    if (($('.navbar-overflow-menu').css('display') == 'block') && ($('#navbar-overflow-overlay').css('display') == 'block')) {
      $('#navbar-overflow-overlay').css('display', 'none');
    }
    else{
      $('#navbar-overflow-overlay').css('display', 'block');
      $('.navbar-overflow-menu').css('display', 'block');
    }
    if (navbarOverflowMenuFocusTrapActive) {
      // Deactivate here if menu button was pressed while menu is open
      navbarOverflowMenuFocusTrap.deactivate();
      return;
    }   
    if (!navbarOverflowMenuFocusTrap) {
      navbarOverflowMenuFocusTrap = initOverflowFocusTrap();
    }
    navbarOverflowMenuFocusTrap.activate();
    navbarOverflowMenuFocusTrapActive = true;
  });

  $('#navbar-overflow-overlay').on('click', function() {
    $('#navbar-overflow-overlay').css('display', 'none');
  });

  function initOverflowFocusTrap() {
    return FocusTrap($('.navbar-overflow-menu')[0], {
      onDeactivate: function (e) {
        $('#navbar-overflow-overlay').css('display', 'none');
        $('.navbar-overflow-menu').css('display', '');
        if ($('#btnToggleMore').is(':visible')) {
          $('#btnToggleMore').focus();
        } else {
          $('#btnToggleMainMenu').focus(); // Rightmost button on the toolbar
        }
        navbarOverflowMenuFocusTrapActive = false;
      },
      // Prevent click on menu button from calling deactivate before click handler 
      allowOutsideClick: (e) => {
        if (!e.target.classList.contains('icon-more') && !(e.target.id === 'btnToggleMore')) {
          navbarOverflowMenuFocusTrap.deactivate();
        }
        return true;
      }
    });
  }

  //*****************************************************************//

  //S-34977 hide features for Follett shared user
  Managers.user.getCurrentUser(true).then(function (user) {
    if (!user.isTemporary) {
      bindToggleMenu('#btnToggleBookmark', Keyboard.Bookmark, bookmarksMenu);
    } else {
      $('#btnToggleBookmark').hide();
    }
    if (window.sessionStorage.getItem('fssReferrerUrl')) {
      //remove fssReferrerUrl if exists
      window.sessionStorage.removeItem('fssReferrerUrl')
    }
  });

  //checkout/checkin button logic
  $(".checkout-book-options-group").removeClass("hidden");
  $(".checkin-book-options-group").addClass("hidden");


  Keyboard.on(Keyboard.HidePopups, 'reader', function () {
    popupModal.hideAll();
    $('.navbar-btn').removeClass('active');

    // closing overlay views
    //HelpPage.close();
  });
  Keyboard.on(Keyboard.PlayerNext, 'reader', function () {
    var sliderInput = document.getElementById('wdg-slider-input');
    var v = parseInt(sliderInput.value);
    player.seekTo(player.getPosition() + parseInt(sliderInput.step)).then(function () {
    });
  });
  Keyboard.on(Keyboard.PlayerPrev, 'reader', function () {
    var sliderInput = document.getElementById('wdg-slider-input');
    var v = parseInt(sliderInput.value);
    player.seekTo(player.getPosition() - parseInt(sliderInput.step));
  });
  var $slider = $('.audio-speed-slider');
  Keyboard.on(Keyboard.IncreaseAudioSpeed, 'reader', function () {
    $('#audioSpeed').show();
    setTimeout(function () {
      var value = parseInt($slider.val());
      $slider.prop('value', value + parseInt($slider.prop('step')));
      $slider.change();
    }, 300);
  });
  Keyboard.on(Keyboard.DecreaseAudioSpeed, 'reader', function () {
    $('#audioSpeed').show();
    setTimeout(function () {
      var value = parseInt($slider.val());
      $slider.prop('value', value - parseInt($slider.prop('step')));
      $slider.change();
    }, 300);
  });
  $slider.on('mouseup touchend', function (event) {
    $('#audioSpeed').show();
    setTimeout(function () {
      var value = parseInt($slider.val());
      $slider.change();
    }, 300);
  });

  $slider.on('keyup', function (event) {
    if (event.key == 'Escape') {
      $('#audioSpeed .close').trigger('click');
    }
  });
  var $sliderSleepTimer = $('.sleep-time-slider');
  Keyboard.on(Keyboard.IncreasePlayerSleepTimer, 'reader', function () {
    $('#sleepTimer').show();
    setTimeout(function () {
      var value = parseInt($sliderSleepTimer.val());
      $sliderSleepTimer.prop('value', value + parseInt($sliderSleepTimer.prop('step')));
      $sliderSleepTimer.change();
    }, 300);
  });
  Keyboard.on(Keyboard.DecreasePlayerSleepTimer, 'reader', function () {
    $('#sleepTimer').show();
    setTimeout(function () {
      var value = parseInt($sliderSleepTimer.val());
      $sliderSleepTimer.prop('value', value - parseInt($sliderSleepTimer.prop('step')));
      $sliderSleepTimer.change();
    }, 300);
  });
  $sliderSleepTimer.on('mouseup touchend', function (event) {
    $('#sleepTimer').show();
    setTimeout(function () {
      var value = parseInt($sliderSleepTimer.val());
      $sliderSleepTimer.change();
    }, 300);
  });
  Keyboard.on(Keyboard.PlayerPrevChapter, 'reader', function () {
    $('#btnPreviousChapter').click();
  });
  Keyboard.on(Keyboard.PlayerNextChapter, 'reader', function () {
    $('#btnNextChapter').click();
  });
  $sliderSleepTimer.on('keyup', function (event) {
    if (event.key == 'Escape') {
      $('#sleepTimer .close').trigger('click');
    }
  });
  $(document).on('REQUEST_BOOK_CLOSE', function () {
    closeWindow();
  });
}

function updateUI() {
  var track = player.currentTrack;
  var chapter_number = player.currentTitle.chapters[track].chapter_number;
  var chapterized = player.currentTitle.chapterized;
  $chapterTitle.empty();
  mainMenu.hide();
  bookmarksMenu.hide();
  audioSpeedMenu.hide();
  sleepTimerMenu.hide();
  var string = chapterized ? (chapter_number === 0 ? "Introduction" : "Chapter " + chapter_number) : ('Track ' + (track + 1));
  $chapterTitle.text(string);

  var duration = player.currentTitle.chapters[track].duration;
  $wdgSlider.prop('max', duration);
  $wdgSlider.attr('aria-valuemin', 0);
  $wdgSlider.attr('aria-valuemax', duration);
  var audioTitle = player.currentTitle.title;
  setBookTitle(audioTitle);
  if ($('#audioPositionPopupId').length === 0) {
    $('#btnPause').focus();
  }
}

function convertPackgeFullPathToComic(epubPath){
  var epubURI = new URI(epubPath);
  return epubPath.replace(epubURI.pathname(), '/content/v2/stream/cbz/' + epubURI.filename());
  //return ('https://qa-frontdoor.axisnow.com/content/v2/stream/cbz/' + epubURI.filename());
}

function loadUI(data) {
  console.log('hey i am comic load UI', data);

  $(document).bind('fullscreenchange webkitfullscreenchange mozfullscreenchange msfullscreenchange', function (e) {
    checkAndSetForFullScreen();
  });
  if ($(window).width() < 1024) {
    zoomLevel = 0;
  }
  AXISAnalyticshelper.startTiming('Paging', 'Content Load');
  //If the ISBN as 2 formats(cbz/epub), then show Older version
  utils.isOnline().then( function() {
    if(data.formatResponse.length > 1) {
      $('.epubOption').show();
      $('#epubOption').show();
      $('ul.select_ul').addClass('formatwithEbookOption').removeClass('formatwithoutEbookOption');
   } else {
      $('.epubOption').hide();
      $('#epubOption').hide();
      $('ul.select_ul').addClass('formatwithoutEbookOption').removeClass('formatwithEbookOption');
   }
   }).catch(err =>{
     console.log('browser is offline',err.message);
     $('.epubOption').hide();
     $('#epubOption').hide();
     $('ul.select_ul').addClass('formatwithoutEbookOption').removeClass('formatwithEbookOption');
   })
  if (data.hasOwnProperty("formatResponse")) {
     formatResponseLength = data.formatResponse.length;
  }
  comicUrl = convertPackgeFullPathToComic(data.epub);
  comicBkBasePath = comicUrl + '/';
  eventPayload = data;
  isbn = new URI(comicUrl).filename();
    

  $('.modal-backdrop').remove();

  $appContainer = $('#app-container');
  $appContainer.empty();
  $appContainer.append(
    ComicLanding({ strings: Strings, /* dialogs: Dialogs, BH-325 */ keyboard: Keyboard }),
  );
  $('header#app-navbar').empty();
  //$('nav').attr("aria-label", Strings.i18n_toolbar);
  $('header#app-navbar').append(
    ComicNavbar({ strings: Strings, /* dialogs: Dialogs, BH-325 */ keyboard: Keyboard }),
  );

  //Moving show navbar button at the top
  $('#app-navbar').before($('#buttShowToolBar'));
  $chapterTitle = $('#chapterTitle');
  $coverImage = $('.landing-icon');
  $currentTime = $('.pagination-current');
  $remainingTime = $('.pagination-remaining');
  $wdgSlider = $('#wdg-slider-input');
  $stRemainingTime = $('.st-time-remaining');

  var $navBar = $('#app-navbar');
 // Loop to prevent hiding with any menu is opened
  if ($('.popup-sticky').is(':visible')) {
    hideLoop();
    return;
  }
  //$navBar.empty();
  //$('nav').attr("aria-label", Strings.i18n_toolbar);

  //Moving show navbar button at the top
  $navBar.before($('#buttShowToolBar'));

  $appContainer.hide();
  initDisplayOptionsUI();
  initFormatViewUI();

  var id = data.audiobookid;
  audiobookId = data.audiobookid; // save audiobook ID for tracking
  bindEvents(id);

  usageManager = new UsageManager(Managers.user);
  engageManager = new EngageManager(Managers.user);
  
  Managers.user
    .getCurrentUserData()
    .then(function (user) {
      $('#btnToggleZoomOut').hide();
      $('#btnToggleZoomIn').hide();
      isTemporary = user.isTemporary;
      if (isTemporary) {
        $("#btnToggleBookmark").hide();
        $("#btnToggleNotes").hide();
        $("#icon-edit").hide();
        $("#icon-notes").hide();
        $("#highlight-options-toolbar").css("min-width", "18.5em");
        $('#btnToggleZoomOut').hide();
        $('#btnToggleZoomIn').hide();

        if (document.referrer && window.sessionStorage && document.referrer.indexOf('/dap/director') >= 0) {
          window.sessionStorage.setItem('fssReferrerUrl', document.referrer);
        }
      } else {
        if (window.sessionStorage.getItem('fssReferrerUrl')) {
          // remove fssReferrerUrl if exists and book is not temporary
          window.sessionStorage.removeItem('fssReferrerUrl')
        }
      }
      if (!isbn) {
        // we should still proceed even if we can't parse the isbn of the book we're opening
        console.error('Comic bok Library.saveNewBookAuthDataAsCurrentBook could not extract ISBN from: "' + url);
        
      } else {
        Managers.license.getAuthData(isbn).then(function (authData) {
          isClassSet = authData.classSet || false;
        });
      }
    })
    .then(function () {
      var pageUsage = usageManager.fetchCurrentComicPositionFromRemote(isbn);
      return pageUsage;
    })
    .catch(Errors.BrowserOffline, function () {
      return new Promise(function (resolve) {
        UsageManager.prototype.getPageUsage(isbn)
          .then(function (pageUsage) {
            resolve(pageUsage);
          });
      });
    })
    .then(function (pageUsage) {
      //getPackageFile();
      
      console.log('pageUsage1', pageUsage);
      if (pageUsage.hasOwnProperty('location')) {
        location = pageUsage.location;
      }
      _comicManager = new ComicManager(null, null, null);
      _encryptionHandler = '';
      fetchLicence().then(function (response) {
        loadComic();
      })
    });
}

function loadComic() {
  if (window['photonTimer_Load']) {
    AXISAnalyticshelper.endTiming('Load', { type: 'Comic' });
  } else {
    AXISAnalyticshelper.endTiming('Paging', { type: 'Comic' });
  }
  $('.hide-until-loaded').removeClass('hide-until-loaded');
  srcImg = document.getElementById("comicImg");
  srcImgSource = srcImg.src;
  /**
   * Bookmark Filter
   */

  readComicACBFile().then(function(xml){
    if(xml){

      loadComicBookObject($($.parseXML(xml)));

      if (location && !location.includes('epub')) {
        /**
         * Using Setter Method to set the initialLoad status
         */
         document.getElementById("statusDiv").innerText="";
         setTimeout(() => {document.getElementById("statusDiv").innerText="Loading please wait"},0)
         setInitialLoadCheck('initialLoad');
        $('#app-container').trigger('navigatePage', [location]);
        setPreLoadTag();
        tocData = menuItems;
        comicManager = new ComicManager(comicMetaData, srcImg, tocData)
        $appContainer.show();
        setupMenus();
       // initActivityWatcher(); 
      } else {
        decryptContent(comicMetaData.coverpage).then(function(imgUrl){
          srcImg.src = imgUrl;
          //savePlace();

          setPreLoadTag();
          tocData = menuItems;
          comicManager = new ComicManager(comicMetaData, srcImg, tocData)
          $appContainer.show();
          setupMenus();
          //initActivityWatcher(); // FSS maintains a more active activity update process
          //initSaveIntervals();
        });
      }
      
      checkAndSetForFullScreen();

    } else {
      throw Error("Comic ACBF decryption Failed!!!");
    }
  });
}

function getOfflineModeStatus() {
  utils.isOnline().then(function () { 
    //console.log("Browser Online");
  }).catch(Errors.BrowserOffline, function () {
    $('li.epubOption').hide();
    $('#epubOption').hide();
  });
}

function setBookTitle(title) {
  let launchUrl = window.location.href;
  if (window.sessionStorage && launchUrl.indexOf('audiobook=') >= 0) {
    window.sessionStorage.setItem('fssReturnToBookUrl', launchUrl);
  }

  //set variable title for html
  bookTitle = title;

  //set title of tab in browser
  //document.title = document.title + ' - ' + title; // we removed brand name  ticket #205732
    document.title = title;
  var $titleEl = $('.book-title-header');
  if ($titleEl.length) {
    $titleEl.text(title);
  } else {
    var html = ReaderBookTitleHeader({ title: title });
    $(html).insertBefore('#app-container');
  }
}

function loadComicBookObject(xmlDoc) {
  try {
    Array
    var objs = xmlDoc.find("coverpage");
    var book_title = xmlDoc.find("book-title");
    var author_first_name = xmlDoc.find("author[activity='Writer'] first-name");
    var author_last_name = xmlDoc.find("author[activity='Writer'] last-name");
    menuItems = [];
    comicMetaData.pages = [];

    var formatAuthorName = function(fNameList, lNameList) {
      var name;
      //Updated code for Mobile issue
      if(fNameList && fNameList.length > 0 && $(fNameList[0]).html().trim() != ""){
        name = $(fNameList[0]).html().trim();
      }

      if(lNameList && lNameList.length > 0 && $(lNameList[0]).html().trim() != ""){
        if(name){
          name = name + " " + $(lNameList[0]).html().trim();
        } else {
          name = $(lNameList[0]).html().trim();
        }
      }

      return name;
    };

    bookTitle = $(book_title[0]).html().trim();
    comicMetaData.url = comicUrl;
    comicMetaData.title = bookTitle;
    comicMetaData.author = formatAuthorName(author_first_name, author_last_name);

    setBookTitle(bookTitle);

    //Cover Page
    for (var i = 0; i < objs.length; i++) {
      //console.log(objs[i].getInnerHTML());
      comicMetaData.coverpage = $(objs[i]).find("image")[0].getAttribute("href"); //comicBkBasePath + 
     /**
      * Some of book image,page txt come along with ISBN so we are removed
      */
      var txtWithdot, imgTxt;
      if(comicMetaData.coverpage.includes(isbn + '\\')){
         console.log("CoverPAge", comicMetaData.coverpage);
         if((comicMetaData.coverpage.split('images')[1]) != undefined){
          var temp = comicMetaData.coverpage.split('images')[1];
          txtWithdot = temp.substr(1);
          imgTxt = txtWithdot.substr(0, txtWithdot.indexOf('.'));
          comicMetaData.coverpage = `${isbn}/images/${txtWithdot}`;
         }
         else{
          txtWithdot = comicMetaData.coverpage.split('\\')[1];
          imgTxt = txtWithdot.substr(0, txtWithdot.indexOf('.'));
          comicMetaData.coverpage = `${isbn}/${txtWithdot}`;
         }
        } else {
          txtWithdot = comicMetaData.coverpage.substr(comicMetaData.coverpage.lastIndexOf('/') + 1);
          imgTxt = txtWithdot.substr(0, txtWithdot.indexOf('.'));
       }
      //comicMetaData.title = imgTxt;
      menuItems.push({ title: '' + imgTxt, "image": pgImg, index: '-1', children: [] });
    }

    //Pages
    objs = xmlDoc.find("page");
    for (var i = 0; i < objs.length; i++) {
      //Page Image
      var pgImg = $(objs[i]).find("image")[0].getAttribute("href"); //comicBkBasePath +
      var checkIsbnInImgPath = $(objs[i]).find("image")[0].getAttribute("href"); // it used to check the ISBN number following the image path or not
       /**
      * Some of book image ,page txt come along with ISBN so we are removed
      */
     var txtWithdot,imgTxt;
      if(pgImg.includes(isbn + '\\')) {        
        pgImg = pgImg.split(isbn)[1];
        if((pgImg.split('images')[1]) != undefined){
          var temp = pgImg.split('images')[1];
          txtWithdot = temp.substr(1);
          imgTxt = txtWithdot.substr(0, txtWithdot.indexOf('.'));
        }
        else{
          txtWithdot = pgImg.substr(1);
          imgTxt = txtWithdot.substr(0, txtWithdot.indexOf('.'));
        }         
       } else {
         txtWithdot = pgImg.substr(pgImg.lastIndexOf('/') + 1);
         imgTxt = txtWithdot.substr(0, txtWithdot.indexOf('.'));
       }
       var menuObj = {
        title: '' + imgTxt,
        "image": pgImg,
        "frames": frames,
        "index": i,
        "children": [],
      }
      //frames in a page
      var frameNodeArray = $(objs[i]).find("frame");
      var frames = [];

      for (var j = 0; j < frameNodeArray.length; j++) {
        frames.push({
          "points": frameNodeArray[j].getAttribute("points")
        })

        var subObj = {
          title: 'Frame ' + (j + 1),
          index: i + '|' + j,
          track: i + '|' + j,
          children: []
        }
       // menuObj.children.push(subObj);
      }
      var imagePath;
      if(checkIsbnInImgPath.includes(isbn + '\\')){
        if(checkIsbnInImgPath.includes('\\images\\')){
         imagePath = `${isbn}/images/${txtWithdot}`;
        }
        else{
          imagePath = `${isbn}/${txtWithdot}`;
        }
      }
      else{
        imagePath = pgImg;
      }
      //Page object
      comicMetaData.pages.push({
        'title': imgTxt,
        "image": imagePath,
        "frames": frames,
      });
      menuItems.push(menuObj);
    }
     console.log('menuObj', menuObj);
  } catch (e) {
    console.error(e);
  }
}

function updatePage(currPage) {
  window.sessionStorage.setItem('COMIC_CURRENT_PAGE', currPage);
   /**
    * check page reached to last page or not, if reached disabled the next button
   */ 
     disabledNxtButton(parseInt(currPage));
}
function updateViewr(curViewer) {
  currentViewer = curViewer;
  if(currentViewer == 'panel'){
    checkPage= '';
    currentLocationIndex = '';
    bookMarkList =[];
  }  
  if(currentViewer == 'page'){
    $('#btnToggleZoomOut').hide();
    $('#btnToggleZoomIn').hide();
  }
  $('.bookviewerSelect, #viewerSelect').val(currentViewer);
  var formatSelectedoption = currentViewer == "page" ? "Comic Book (Page View)" : currentViewer == "panel" ? "Comic Book (Frame View)" : "Comic Book (Page View)";
  $(".navbar-btn-format-label").text(formatSelectedoption);
  $("#Format_selected").attr({
    "title": formatSelectedoption,
    "aria-labelledby": formatSelectedoption,
  });
  $('#Format_selected').find('input[id=formattxt]').val(formatSelectedoption).change();
  selectedFormatId = formatSelectedoption;
  $("#"+ currentViewer).focus();
}

function updateArea(curArea) {
  window.sessionStorage.setItem('COMIC_CURRENT_AREA', curArea)
}

function showBothViewer() {
  $('#pageOnly').hide();
  $('.bookviewerSelect, #viewerSelect').show();
}

function showPageViewerOnly() {
  $('#pageOnly').show();
  $('.bookviewerSelect').hide();
}

function updateReadingTime() {
  console.log('page start time', moment().utc().format('MM/DD/YYYY hh:mm:ss A Z'));
  window.sessionStorage.setItem('startTime', moment().utc().format('MM/DD/YYYY hh:mm:ss A Z'));
}

$(window).on('gotoPage', function (e, pageNumber) {
  goToPage = 'check';
  moveToPage(pageNumber-1);
});

function magnifyArea(frame, zoomLevel) {
  //console.log(area);
  srcImg.style.opacity = 0.01;
  if (!$('#btnToggleZoomOut').is(':visible')) {
    $('#btnToggleZoomOut').show();
    $('#btnToggleZoomIn').show();
  }
  /***
   * check if bookmark page then update the panel.
   */
  if(checkPage){
    updateViewr('page')
  }else {
    updateViewr('panel')
  }
  if (frame) {
    //alert("with Frame");
    magnify("myimage", zoomLevel, frame);
    updateArea(0);
  } else if (currentPage == null) {
    //alert("No Current Page");
    //console.log(comicMetaData.pages[0]);
    currentPage = comicMetaData.pages[0];
    decryptContent(currentPage.image).then(function(imgUrl){
      srcImg.src = imgUrl;
      srcImg.style.opacity = 1;
      updateArea(-1);
    });
  } else if (currentPage && currentPage.frames.length == 0) {
    //alert("No farmes in Current Page");
    var currIndex = comicMetaData.pages.indexOf(currentPage);
    currentPage = comicMetaData.pages[currIndex + 1];
    decryptContent(currentPage.image).then(function(imgUrl){
      srcImg.src = currentPage.image;
      srcImg.style.opacity = 1;
      updateArea(currIndex);
    });
  } else if (currentPage && (srcImg.getAttribute('disabled') != 'true')) {
    //alert("First Frame");
    //alert(currentVisible);
    currentVisible = currentVisible + 1;
    frame = currentPage.frames[currentVisible];
    magnify("myimage", 0, frame);
    srcImg.setAttribute('disabled', 'true');
    updateArea(currentVisible);
  }
  setPreLoadTag();
}

function ZoomIn() {
  //console.log("area");
  Zoom(1.1);
}

function ZoomOut() {
  Zoom(0.9);
}

function setPreLoadTag() {
  AXISAnalyticshelper.endTiming('Paging', { type: 'Comic' });
   utils.isOnline().then(function () {
     //console.log("BrowserOnline");    
  }).catch(Errors.BrowserOffline, function () {
    var head = document.getElementsByTagName('head')[0];
    var currIndex = comicMetaData.pages.indexOf(currentPage);
    if (currIndex <= comicMetaData.pages.length - 2) {
      if (!preLoadHL) {
        preLoadHL = document.createElement('link');
        preLoadHL.setAttribute("rel", "preload");
        preLoadHL.setAttribute("as", "image");
        preLoadHL.setAttribute("href", comicBkBasePath + comicMetaData.pages[currIndex + 1].image);

        head.appendChild(preLoadHL);
      } else {
        preLoadHL.setAttribute("href", comicBkBasePath + comicMetaData.pages[currIndex + 1].image);
      }
    }

    if (currIndex > 0) {
      if (!preFetchHL1) {
        preFetchHL1 = document.createElement('link');
        preFetchHL1.setAttribute("rel", "prefetch");
        preFetchHL1.setAttribute("as", "image");
        preFetchHL1.setAttribute("href", comicBkBasePath + comicMetaData.pages[currIndex - 1].image);

        head.appendChild(preFetchHL1);
      } else {
        preFetchHL1.setAttribute("href", comicBkBasePath + comicMetaData.pages[currIndex - 1].image);
      }
    }

    if (currIndex <= comicMetaData.pages.length - 3) {
      if (!preFetchHL2) {
        preFetchHL2 = document.createElement('link');

        preFetchHL2.setAttribute("rel", "prefetch");
        preFetchHL2.setAttribute("as", "image");
        preFetchHL2.setAttribute("href", comicBkBasePath + comicMetaData.pages[currIndex + 2].image);

        head.appendChild(preFetchHL2);
      } else {
        preFetchHL2.setAttribute("href", comicBkBasePath + comicMetaData.pages[currIndex + 2].image);
      }
    }
    var cIndex = (currIndex < 0) ? 0 : currIndex;
  });
}

function Zoom(amount) {
  // resize image
  var image = document.getElementById('comicImg');
  image.height = image.height * amount;

  // resize image map
  var map = document.getElementById('image-map');
  for (var i = 0; i < map.areas.length; i++) {
    var coords = ""
    var area = map.areas[i];
    var coords = area.coords.split(',');
    //console.log(coords.join(","));
    for (var j = 0; j < coords.length; j++) {

      coords[j] = coords[j] * amount;
    }
    //console.log(coords.join(","));
    area.coords = coords.join(",");
  }
  savePlace(getInitialLoadCheck());
}

function magnify(imgID, zoom, frame) {
  var w, h, bw;
  //console.log(frame.points);

  cleanMagnifierControls();


  /* Create magnifier glass: */
  glassContainer = document.createElement("DIV");
  glass = document.createElement("DIV");

  glass.setAttribute("id", "glass");
  glass.setAttribute("class", "img-magnifier-glass");
  /* Insert magnifier glass: */
  glassContainer.appendChild(glass)

  srcImg.parentElement.insertBefore(glassContainer, srcImg);

  var coords = frame.points.replaceAll(' ', ',').split(',');
  console.log(coords);
  var leastX = parseFloat(coords[0]);
  var leastY = parseFloat(coords[1]);
  var width = parseFloat(coords[0]);
  var height = parseFloat(coords[1]);

  //console.log("Width:" + width + " , Height:" + height);

  for (var i = 2; i < coords.length; i++) {
    var coordVal = parseFloat(coords[i]);
    //console.log("currVal->" + coordVal + ":::leastX->" + leastX + ":::leastY->" + leastX);
    if (i % 2 == 0) {
      if (width < coordVal) width = coordVal;
      if (leastX > coordVal) leastX = coordVal;
    } else {
      if (height < coordVal) height = coordVal;
      if (leastY > coordVal) leastY = coordVal;
    }
  }

  if(zoom <= 0){
    zoom = getMaxZoomLevel(width - leastX, height - leastY);
  }

  width = getAspectRatioWidth(width) * zoom;
  height = getAspectRatioHeight(height) * zoom;
  leastX = getAspectRatioWidth(leastX) * zoom;
  leastY = getAspectRatioHeight(leastY) * zoom;

  //console.log("Width:" + width + " , Height:" + height);
  //console.log("leastX:" + leastX + " , leastY:" + leastY);

  /* Set background properties for the magnifier glass: */
  glass.style.backgroundImage = "url('" + srcImg.src + "')";
  glass.style.backgroundRepeat = "no-repeat";
  glass.style.backgroundSize = (srcImg.width * zoom) + "px " + (srcImg.height * zoom) + "px";
  //glass.style.left = (leastX/zoom)  + "px"; 
  //glass.style.top = (leastY/zoom) + "px";
  glass.style.width = (width - leastX) + "px";
  glass.style.height = (height - leastY) + "px";
  glass.style.backgroundPosition = "-" + leastX + "px -" + leastY + "px";

  glass.style.zindex = 10;
}

function getMaxZoomLevel(width, height){
  var result = 1.0, w, h, i = 1;
  var ra = $("#reading-area");
  var raW = ra.width() * 80/100;
  var raH = ra.height() * 80/100;

  zoomLevels = [];
 // for(var i = 0; i <= zoomLevels.length; i++) {
   while(true){
    w = getAspectRatioWidth(width) * i;
    h = getAspectRatioHeight(height) * i;

    if(w <= raW && h <= raH){
      result = parseFloat(i.toFixed(1));      
      if(!zoomLevels.includes(result)) zoomLevels.push(result);
      zoomPointer = zoomLevels.indexOf(result);
      zoomLevel = result;
    }
    else {
      break;
    }
    i = i + 0.1;
  }
  return result;
}

function getAspectRatioWidth(w) {
  //console.log('srcImg.width', srcImg.width, srcImg.naturalWidth);
  return w * (srcImg.width / srcImg.naturalWidth)
}

function getAspectRatioHeight(h) {
  return h * (srcImg.height / srcImg.naturalHeight)
}

function showNextArea(ignore) {
  //console.log("showNextArea");
  var currIndex = comicMetaData.pages.indexOf(currentPage);
  //console.log("showNextArea: CurrIndex: ", currIndex);
  //console.log("showNextArea: currentPage: ", currentPage);

  if (typeof ignore == 'undefined') {
    trackPageActivity('panel', currIndex, currentVisible).then(function () {
      updateReadingTime();
    });
  }

  if (!currentPage) {
    currentPage = comicMetaData.pages[0];
    updatePage(0);
  }
  //console.log("showNextArea: currentPage: ", currentPage);
  if (currentVisible >= (currentPage.frames.length - 1) || currentPage.frames.length == 0) {
    if (currentPage) {
      var currIndex = comicMetaData.pages.indexOf(currentPage);
      if (currentPage && (currIndex + 1) > (comicMetaData.pages.length - 1)) {
        currentPage.frames.length >= 1 ? updateViewr('panel') : updateViewr('page');
        moveToPage(currIndex);
        return;
      }
      else {
        currentPage = comicMetaData.pages[(currIndex + 1)];
        currentVisible = -1;
        updateViewr('panel');
        showNextArea('ignore');
        return;
      }
    }
    savePlace(getInitialLoadCheck());
    //moveToNextPage();
  } else {
    var currIndex = comicMetaData.pages.indexOf(currentPage);
    //console.log('currIndex', currIndex);
    currentVisible = currentVisible + 1;
    //console.log('currentVisible', currentVisible);
    moveToPage(currIndex,function () {
      $('#btnToggleZoomIn').attr('disabled', true);     
      magnifyArea(currentPage.frames[currentVisible], 0);
      updatePage(currIndex);
      var viewportmeta = document.querySelector('meta[name="viewport"]');
      viewportmeta.content = 'width=device-width, initial-scale=1.0';
      window.sessionStorage.setItem('getCurrentAreaIndex', currentVisible)
      updateArea(currentVisible);
      savePlace(getInitialLoadCheck());
    });
  }

}

function showPreviousArea(ignore) {
  var currIndex = comicMetaData.pages.indexOf(currentPage);

  if (typeof ignore == 'undefined') {
    trackPageActivity('panel', currIndex, currentVisible).then(function () {
      updateReadingTime();
    });
  }

  if (!currentPage || currentVisible == 0 || (currentPage && currentPage.frames.length == 0)) {
    if (!currentPage) {
      //showPageViewerOnly();
      updateViewr('page');
      moveToPage(-1);
      return;
    }
    else if (currentPage) {
      var currIndex = comicMetaData.pages.indexOf(currentPage);
      if (currentPage && (currIndex - 1) < 0) {
        updateViewr('page');
        moveToPage(currIndex);
        return;
      }
      else {
        currentPage = comicMetaData.pages[(currIndex - 1)];
        currentVisible = currentPage.frames.length;
        showPreviousArea('ignore');
        return;
      }
    }
    savePlace(getInitialLoadCheck());
  } else {
    if (currentVisible == -1) {
      currentVisible = currentVisible = currentPage.frames.length;
    }
    currentVisible = currentVisible - 1;
    var currIndex = comicMetaData.pages.indexOf(currentPage);
    moveToPage(currIndex,function () {
      $('#btnToggleZoomIn').attr('disabled', true);
      magnifyArea(currentPage.frames[currentVisible], 0);
      updatePage(currIndex);
      var viewportmeta = document.querySelector('meta[name="viewport"]');
      viewportmeta.content = 'width=device-width, initial-scale=1.0';
      window.sessionStorage.setItem('getCurrentAreaIndex', currentVisible)
      updateArea(currentVisible);
      savePlace(getInitialLoadCheck());
    });
  }
}

function updateCurrentArea(currIndex) {
  //console.log('currIndex', currIndex, currentPage);
  currIndex = currIndex.toString();
  if (currIndex.indexOf('|') > -1) {
    if (!$('#btnToggleZoomOut').is(':visible')) {
      $('#btnToggleZoomOut').show();
      $('#btnToggleZoomIn').show();
    }
    var frame = currIndex.split('|');
    var pageIndx = frame[0];
    if (pageIndx == -1) {
      currentPage = null;
    }
    else if (pageIndx > -1) {
      currentPage = comicMetaData.pages[pageIndx];
    }
    //console.log('currentPage', currentPage);
    currentVisible = parseInt(frame[1]);
    if(isNaN(zoomLevel)){ 
      zoomLevel=0;
    }
    $('#btnToggleZoomIn').attr('disabled', false);
    magnifyArea(currentPage.frames[currentVisible], zoomLevel);
    updateArea(currentVisible);
    //console.log('window.currPage', pageIndx, window.sessionStorage.getItem('COMIC_CURRENT_PAGE'), window.sessionStorage.getItem('COMIC_CURRENT_AREA'))
  }
}

function moveToPage(currIndex, _callback) {
  //console.log('currIndex', currIndex, currentPage);
  //console.log('_callback', _callback);
  
  if (currIndex.toString().indexOf('|') > -1) {
    currIndex = currIndex.toString();
    
    var frame = currIndex.split('|');
    var pageIndx = frame[0];
    if (pageIndx == -1) {
      currentPage = null;
    }
    else if (pageIndx > -1) {
      currentPage = comicMetaData.pages[pageIndx];
    }
    currentVisible = parseInt(frame[1]);
    window.sessionStorage.setItem('getCurrentAreaIndex', currentVisible)
    srcImg.style.visibility = "visible";
    moveToPage(pageIndx,function () {
      $('#btnToggleZoomIn').attr('disabled', false);
      magnifyArea(currentPage.frames[currentVisible], zoomLevel);
    });
    updateArea(currentVisible);
    updatePage(pageIndx);
    //console.log('window.currPage', pageIndx, window.sessionStorage.getItem('COMIC_CURRENT_PAGE'), window.sessionStorage.getItem('COMIC_CURRENT_AREA'))
  } else {
    //$('#btnToggleZoomOut').hide();
    //$('#btnToggleZoomIn').hide();

    if (_callback && _callback != 'undefined') {
      if (currentViewer == 'panel') {
        currentFormat = 'panel';
      }
      else{
        currentFormat = '';
        srcImg.style.opacity = 0.3;
        srcImg.style.visibility = "hidden";             
      }  
    } else {
      if (currentViewer == 'panel') {
        currentFormat = 'panel';
      }
      else{
        currentFormat = '';
        srcImg.style.visibility = "visible";
      }    
    }

    if (currIndex == -1) {
      //alert("No Current Page");
      //console.log(comicMetaData.pages[0]);
      currentPage = null;
      decryptContent(comicMetaData.coverpage).then(function(imgUrl){
        srcImg.src = imgUrl;
        srcImg.style.opacity = 1;
        //showPageViewerOnly();
        srcImg.onload = function () {
          cleanMagnifierControls();
          if (_callback && _callback != 'undefined') {
            _callback();
          }
        }
        updatePage(-1);
        updateArea(-1);
      });
    } else if (currIndex > -1) {
       var tocSelectKey = window.sessionStorage.getItem('TOC-CLICKED-ITEM-KEY');
       var bookmarkSelectKey = window.sessionStorage.getItem('BOOKMARK-CLICKED-ITEM-KEY');
        if(tocSelectKey) {
            clickedMenuItem = JSON.parse(tocSelectKey);
            window.sessionStorage.removeItem('TOC-CLICKED-ITEM-KEY');
        } else if(bookmarkSelectKey) {
            clickedBookmarkItem = JSON.parse(bookmarkSelectKey);
            window.sessionStorage.removeItem('BOOKMARK-CLICKED-ITEM-KEY');  
        }
       var object; 
       if(goToPage) {
          object = findPageIndex(parseInt(currIndex) + 1 , 1);
          currentPage = object.currentPage;
          currIndex = object.currIndex;
          updatePage(object.currIndex);
       } else if(clickedMenuItem) {
          object = findPageIndex(parseInt(currIndex) + 2 , 2);
          currentPage = object.currentPage;
          currIndex = object.currIndex;
          updatePage(object.currIndex);
       } else if(clickedBookmarkItem) {
          object = findPageIndex(parseInt(currIndex) , 3);
          currentPage = object.currentPage;
          currIndex = object.currIndex;
          updatePage(object.currIndex);
          savePlace(getInitialLoadCheck());
       } else {
          currentPage = comicMetaData.pages[parseInt(currIndex)];
          updatePage(currIndex);
       }
       
      //console.log('currentindex1',currentPage1);
      console.log('currentindex',currentPage);
      decryptContent(currentPage.image).then(function(imgUrl){
        srcImg.src = imgUrl;
        if(clickedBookmarkItem) {
            if(clickedBookmarkItem.data.image.includes('Frame')) {
              currentFormat = "panel";
              clickedBookmarkItem = '';
              updateViewr('panel');                
            } else {
              currentFormat = "page";
              clickedBookmarkItem = '';
              updateViewr('page');  
            }  
      } 
      
        if (currentFormat == 'panel') {
          srcImg.style.opacity = 0;           
        }
        else{
          srcImg.style.opacity = 1;
        }   
        /*if(currentPage.frames.length){
        showBothViewer();
        }
        else{
          showPageViewerOnly();
        }*/

        /**
         * it used to convert panel view when click the user gotopage or TOC
         */

         if(goToPage && currentFormat == 'panel') {
            goToPage = '';
            changeCurrentVisible.set(-1);
            srcImg.style.visibility = "visible";
            showNextArea();
         } else if(clickedMenuItem && currentFormat == 'panel') {
            clickedMenuItem = '';
            changeCurrentVisible.set(-1);
            srcImg.style.visibility = "visible";
            showNextArea();
         } else if(currentFormat != 'panel') {
            goToPage = '';
            clickedMenuItem = '';
            savePlace(getInitialLoadCheck());
         }

        srcImg.onload = function () {
          cleanMagnifierControls();
          if (_callback && _callback != 'undefined') {
            delete srcImg.onload;
            srcImg.style.visibility = "visible";
            _callback();
          }
        }
        if (typeof _callback == 'undefined') {
          updatePage(currIndex);
        }
        updateArea(-1);
        setPreLoadTag();
      });
    }
  }
  /**
     * Update panel or page view based on user clicked the bookmark
     * we commented out now bcoz Frames not displayed in TOC, if FSS required we will use it
     */
  //  var findLocation;
  //  if(bookMarkList.length > 0){
  //   findLocation = bookMarkList.filter(data => data.location == currentLocationIndex)
  //     checkPage= findLocation.length > 0 ? findLocation[0].booktext.includes('Frame') : '';
  //     $spinner.spinner('stop');
  //     if(checkPage) {
  //       updateViewr('panel');
  //     } else {
  //       updateViewr('page');
  //     }
  //  } else {
      /**
    * Update panel or page view based on menu bar click
    * we commented out now bcoz Frames not displayed in TOC, if FSS required we will use it
    */
  //   var menuSelectKey;
  //  if(getMenuClickStatus()){
  //   setMenuClick(false);
  //    menuSelectKey = window.sessionStorage.getItem('MENU-CLICKED-ITEM-KEY');
  //    if(menuSelectKey){
  //      var tempMenuObj = JSON.parse(menuSelectKey);
  //      clickedMenuItem = tempMenuObj;
  //      if(tempMenuObj.data.children.length > 0){
  //      var filterValue = tempMenuObj.data.children.filter(data => data.index == tempMenuObj.index);
  //      if(filterValue[0].title.includes('Frame'))
  //      updateViewr('panel')
  //      }else {
  //      updateViewr('page')
  //      }
  //      savePlace(getInitialLoadCheck());
  //    }
  //  }
 //}

  updateReadingTime();
  if(!clickedMenuItem || !goToPage) {
    if (typeof _callback == 'undefined') {
      savePlace(getInitialLoadCheck());
    }
  }

}

function moveToNextPage() {
   /**
   * user already added the bookmark if user click the  next button focus color is removed
   */
  AXISAnalyticshelper.startTiming('Paging', 'Next Page');
  $('#btnToggleBookmark').removeClass('currentActive');
  if (currentViewer == 'panel') {
    showNextArea();
    return;
  }
  var currIndex = comicMetaData.pages.indexOf(currentPage);
  trackPageActivity('page', currIndex, '-1').then(function () {
    updateReadingTime();
  });
  updateArea(-1);
  $('#btnToggleZoomOut').hide();
  $('#btnToggleZoomIn').hide();
  
  if (currentPage == null) {
    //alert("No Current Page");
    //console.log(comicMetaData.pages[0]);
    currentPage = comicMetaData.pages[0];
    decryptContent(currentPage.image).then(function(imgUrl){
      srcImg.src = imgUrl;
      srcImg.style.opacity = 1;
      srcImg.style.visibility = "visible";
      srcImg.onload = function () {
        cleanMagnifierControls();
      }

      updatePage(0);
      savePlace(getInitialLoadCheck());
    });
    
    /*if(currentPage.frames.length){
      showBothViewer();
    }
    else{
      showPageViewerOnly();
    }*/

  } else if (currentPage && currentPage.frames.length == 0) {
    //alert("No farmes in Current Page");
    var currIndex = comicMetaData.pages.indexOf(currentPage);
    currentPage = comicMetaData.pages[currIndex + 1];
    //console.log("Next Page", currentPage);
    decryptContent(currentPage.image).then(function(imgUrl){
      srcImg.src = imgUrl;
      srcImg.style.visibility = "visible";
      srcImg.style.opacity = 1;
      showPageViewerOnly();
      srcImg.onload = function () {
        cleanMagnifierControls();
      }

      updatePage(currIndex);
      savePlace(getInitialLoadCheck());
      setPreLoadTag();
    });
    
  } else if (currentPage) {
    var currIndex = comicMetaData.pages.indexOf(currentPage);

    if (currIndex == (comicMetaData.pages.length - 1)) {
      //currentPage = null;
      //srcImg.src = comicMetaData.coverpage;
      updatePage(currIndex);
      savePlace(getInitialLoadCheck());
    } else {
      currentPage = comicMetaData.pages[currIndex + 1];
      //console.log("Next Page", currentPage);
      decryptContent(currentPage.image).then(function(imgUrl){
        srcImg.src = imgUrl;
        srcImg.setAttribute('disabled', 'false');
        srcImg.style.opacity = 1;
        srcImg.style.visibility = "visible";
        /*if(currentPage.frames.length){
          showBothViewer();
        }
        else{
          showPageViewerOnly();
        }*/
        srcImg.onload = function () {
          cleanMagnifierControls();
        }
        updatePage(currIndex + 1);
        setPreLoadTag();
        savePlace(getInitialLoadCheck());
      });
    }
  } else {
    //alert('escaping moveToNextPage');
  }
}

function moveToPrevPage() {
   /**
   * user already added the bookmark if user click the  next button focus color is removed
   */
  AXISAnalyticshelper.startTiming('Paging', 'Prev Page');
  $('#btnToggleBookmark').removeClass('currentActive');
  if (currentViewer == 'panel') {
    showPreviousArea();
    return;
  }
  var currIndex = comicMetaData.pages.indexOf(currentPage);
  trackPageActivity('page', currIndex, '-1').then(function () {
    updateReadingTime();
  });
  updateArea(-1);
  $('#btnToggleZoomOut').hide();
  $('#btnToggleZoomIn').hide();
  if (currentPage) {
    //alert("No farmes in Current Page");
    var currIndex = comicMetaData.pages.indexOf(currentPage);
    if (currIndex <= 0) {
      currentPage = null;
      decryptContent(comicMetaData.coverpage).then(function(imgUrl){
        srcImg.src = imgUrl;
        srcImg.style.visibility = "visible";
        srcImg.style.opacity = 1;
        //showPageViewerOnly();
        srcImg.onload = function () {
          cleanMagnifierControls();
        }
        updatePage(currentPage);
        savePlace(getInitialLoadCheck());
      });
      
    } else {
      currentPage = comicMetaData.pages[currIndex - 1];

      decryptContent(currentPage.image).then(function(imgUrl){
        srcImg.src = imgUrl;
        srcImg.style.opacity = 1;
        /*if(currentPage.frames.length){
          showBothViewer();
        }
        else{
          showPageViewerOnly();
        }*/
        srcImg.style.opacity = 1;
        srcImg.style.visibility = "visible";
        srcImg.setAttribute('disabled', 'false');
        srcImg.onload = function () {
          cleanMagnifierControls();
        }
        updatePage(currIndex - 1);
        savePlace(getInitialLoadCheck());
        setPreLoadTag();
      });
    }

  } else if (currentPage && currentPage.frames.length == 0) {
    var currIndex = comicMetaData.pages.indexOf(currentPage);
    currentPage = comicMetaData.pages[currIndex - 1];

    decryptContent(currentPage.image).then(function(imgUrl){
      srcImg.src = imgUrl;
      srcImg.style.visibility = "visible";
      srcImg.style.opacity = 1;
      srcImg.onload = function () {
        cleanMagnifierControls();
      }
      updatePage(currIndex - 1);
      savePlace(getInitialLoadCheck());
      setPreLoadTag();
    });
    
  }
}

function imageContainerClicked(e) {
  srcImg.setAttribute('disabled', 'false');
  var clickTarget = e.target;
  var clickTargetWidth = clickTarget.offsetWidth;
  var xCoordInClickTarget = e.clientX - clickTarget.getBoundingClientRect().left;
  //console.log(e.pageX);
  //console.log(window.innerWidth);
  if (e.pageX > (window.innerWidth - ((window.innerWidth * 10) / 100))) {
    moveToNextPage();
  } else if (e.pageX < window.innerWidth - ((window.innerWidth * 90) / 100)) {
    moveToPrevPage();
  }
  /*if (clickTargetWidth / 2 > xCoordInClickTarget) {
    
  } else {
    
  }*/
}

function cleanMagnifierControls() {
  srcImg.setAttribute('disabled', 'false');

  if (glass) {
    glass.remove();
    glass = undefined;
  }

  if (prevBtn) {
    prevBtn.remove();
    prevBtn = undefined;
  }

  if (nextBtn) {
    nextBtn.remove();
    nextBtn = undefined;
  }

  if (glassContainer) {
    glassContainer.remove();
    glassContainer = undefined;
  }
}

function unloadUI() {
  deactivateActivityWatcher(); // FSS maintains a more active activity update process
}



function hideAllPopupMenus() {
  popupModal.hideAll();
  iconUpdate('', 'all') //update all icon
  $('.navbar-btn').removeClass('active');
  resizeIsTriggered = 0;
  if (navbarOverflowMenuFocusTrap && navbarOverflowMenuFocusTrapActive) {
    navbarOverflowMenuFocusTrap.deactivate();
  }
}

function bindEvents(id) {
  var permissionManager = Managers.appPermission;
  var cstPermission = permissionManager.permission('CacheStatusTool');
  if (!cstPermission.disableByDefault) {
    $('.app-version').click(ActivateCST);
  }

  $('body').on('click', function (e) {
    if ($(e.target).parents('.popup, #app-navbar').length == 0) {
      if(($("#formattxt").val()) == ""){
        $("#formattxt").val(selectedFormatId);
      }      
      hideAllPopupMenus();
    }
    if ($(e.target).parents(".select_wrap").length == 0) {
      $('.select_wrap').removeClass('active');
    }
  });

  $('.navbar-default').addClass('comicAutoHide');

  $('.comicAutoHide').on('mouseover', function() {
     $('.comicAutoHide').css('opacity', '1');
     $('.app-footer').css('display','none'); 
     $('.comic-nav-left-buttons').css('opacity', '1');
     $('.comic-nav-right-buttons').css('opacity', '1');
  });

  $('.comic-landing').on('mouseover', function() { 
    if(!$('.popup').is(':visible')){
      $('.comicAutoHide').css('opacity','0');
      $('.app-footer').css('display','none'); 
      $('.comic-nav-left-buttons').css('opacity', '0');
      $('.comic-nav-right-buttons').css('opacity', '0');
    } 
  });

  $('.comic-nav-left-buttons').on('mouseover', function() { 
    setTimeout(function() {
      $('.comic-nav-left-buttons').css('opacity', '1');
    }, 0)      
  });

  $('.comic-nav-right-buttons').on('mouseover', function() { 
    setTimeout(function() {
      $('.comic-nav-right-buttons').css('opacity', '1');
    }, 0)      
  });

  $('.offline-lib-btn').on('click', function() {
    $('.popup').hide();
    loadlibrary();
    return false;
  });

  /*$('.btnCloseBook').on('click touchend', function () {
    var currIndex = comicMetaData.pages.indexOf(currentPage);

    trackPageActivity(currentViewer, currIndex, currentVisible).then(function () {
      closeWindow();
    });

    //Flush dynamically loaded resource from app state cache.
    resourceCache.flushCache();
  });*/

  // $('.btnCloseBook').on('click touchend', function () {
  //     //window.close();
  //     window.parent.close();
  // });

  $appContainer.find('.js-player-close').on('click', function (event) {
    event.preventDefault();
    clearSaveIntervals();
    player.trigger('unload');
  });

  // not used, toggle btnPause instead
  // $appContainer.find('#btnPlay').on('click', function (event) {
  //     event.preventDefault();
  //     player.play();
  //     $('.js-player .active').removeClass('active');
  //     $(this).addClass('active');
  // });

  /*$('#btnToggleHelp').on('click', function () {
    //Remove all active classes
    $(".navbar-btn").removeClass("active");
    $(".popup:visible").hide();

    utils.isOnline().then(function () {
      window.open('http://destinydiscoverhelp.fsc.follett.com/Content/Reader/_eBook%20Help.htm', '_blank');
    }).catch(function (error) {
      var popupModalHelp = PopupFactory.create(PopupFactory.TYPE.MODAL);
      popupModalHelp.setTitle('');
      popupModalHelp.setMessage('The help menu is not accessible without internet connectivity.');
      popupModalHelp.addButton("Close", function (e) {
        popupModalHelp.hide();
      }, true);
      $(".js-close-popup").click(function () {
        popupModalHelp.hide();
      });
      popupModalHelp.show();
    });
  });*/

  $appContainer.find('#btnPause').on('click', function (event) {
    event.preventDefault();
    $('#btnPause').removeClass('active');

    if ($('#btnPause span:first').hasClass('icon-icon-pause')) {
      togglePlayPause('play');
      player.pause();
      update = true;
      savePlace(getInitialLoadCheck()).then();
    } else {
      togglePlayPause('pause');
      //FSS 46867 - Fix for iOS - audiobooks not fully loading first track 
      if (Helpers.isMobileSafari() && firstTimeOptions) {
        player.play().then(
          player.changeTrack(parseInt(firstTimeOptions.track)).then(function () {
            player.seekTo(firstTimeOptions.position);
            firstTimeOptions = undefined;
          }));
      } else {
        player.play();
      }
      player.setSpeed(player.getSpeed());
    }
  });
  
  $appContainer.find('#left-page-btn').on('click', function (event) {
    event.preventDefault();
    if (enablePinchZoom) {
        var viewportmeta = document.querySelector('meta[name="viewport"]');
        viewportmeta.content = 'width=device-width, initial-scale=1.0, user-scalable=no';
      }   
    if(currentPage != null){
      document.getElementById("statusDiv").innerText="";
      setTimeout(() => {document.getElementById("statusDiv").innerText="Page Changed"},1000)
    }
    moveToPrevPage();
  });
  
  $appContainer.find('#right-page-btn').on('click', function (event) {
    event.preventDefault();
    if (enablePinchZoom) {
        var viewportmeta = document.querySelector('meta[name="viewport"]');
        viewportmeta.content = 'width=device-width, initial-scale=1.0, user-scalable=no';
      }   
    document.getElementById("statusDiv").innerText="";
    setTimeout(() => {document.getElementById("statusDiv").innerText="Page Changed"},1000)
    moveToNextPage();
  });

  $appContainer.on("navigatePage", function (event, curIndex) {
    AXISAnalyticshelper.startTiming('Paging', 'TOC');
    setTimeout(() => {$('.book-title-header').focus()},0);
    var regExp = /\(([^)]+)\)/g;
    var temp = regExp.exec(curIndex);
    console.log(currentViewer)
    currentLocationIndex = temp == null? curIndex: temp[1];
    moveToPage(temp == null? curIndex: temp[1]);
  });

  $appContainer.on("navigateArea", function (event, curIndex) {
    updateCurrentArea(curIndex);
  });

   /** FULL Screen / EXit Full Screen ***/
   $('#btnFullScreenView').on('click', function (e) {  
    $('.navbar-btn').removeClass('active');
    popupModal.hideAll();
    $("#btnFullScreenView").addClass("hidden");
    $("#btnExitFullScreenView").removeClass("hidden").addClass("active");
    var promise = setToFullScreen(true);

    if(promise && currentViewer == 'panel') {
      promise.then(() => {
        var frame = currentPage.frames[currentVisible];
        //When screen size changes zoom level needs to be adjusted
        magnify("myimage", 0, frame);
      });
    }
  });

  $('#btnExitFullScreenView, #helpList a').on('click', function (e) {    
    $('.navbar-btn').removeClass('active');
    $('.book-title-header').removeClass('book-title-header-white');
    popupModal.hideAll();
    $("#btnFullScreenView").removeClass("hidden").addClass("active"); 
    $("#btnExitFullScreenView").addClass("hidden");
    var promise = setToFullScreen(false);

    if(promise && currentViewer == 'panel') {
      promise.then(() => {
        var frame = currentPage.frames[currentVisible];
        //When screen size changes zoom level needs to be adjusted
        magnify("myimage", 0, frame);
      });
    }
  });

  $('#btnToggleZoomOut').on('click', function (e) {
    e.preventDefault();
    $('.select_wrap').removeClass('active');
    $('.navbar-btn').removeClass('active');
    popupModal.hideAll();
    $('#navbar-overflow-overlay').css('display', 'none');
    $(this).addClass('active');
    bindToggleZoomOut();
  });

  $('#btnToggleFormatSwitch').on('change', function (e) {
    e.preventDefault();
    if ($(this).val() == 'epub') {
      $(window).triggerHandler('readepub', eventPayload);
    }
  });

  $('#btnToggleZoomIn').on('click', function (e) {
    e.preventDefault();
    $('.select_wrap').removeClass('active');
    $('.navbar-btn').removeClass('active');
    popupModal.hideAll();
    $('#navbar-overflow-overlay').css('display', 'none');
    $(this).addClass('active');
    bindToggleZoomIn();
  });
  /**
   * Below event used for Header and mobile BookView for change panel ,page and epub view
   */

  $("[name='viewerSelect']").off("click").on("click", function() {
    bookmarksMenu.hide();
   // currentViewer = this.value;   
    if(this.id == "panel"){
      zoomLevel = 0;
      currentVisible = -1;
      bookviewOptionsPopup.hide();
      $('.navbar-overflow-menu').css('display', 'none'); 
      $('#navbar-overflow-overlay').css('display', 'none');
      updateViewr('panel');
      showNextArea();    
    }
    else if(this.id == 'epub'){
      displaySwitchToEbookViewPopup();
    }
    else {
      bookviewOptionsPopup.hide();
      $('.navbar-overflow-menu').css('display', 'none'); 
      $('#navbar-overflow-overlay').css('display', 'none');
      var currIndex = comicMetaData.pages.indexOf(currentPage);      
      updateViewr('page');
      moveToPage(currIndex);
      return;
    }
    savePlace(getInitialLoadCheck());
  });

$(".img-magnifier-container").on("click",function(e){
  if ($('.img-magnifier-container div').hasClass('img-magnifier-glass')) {
      moveToNextPage();
  }  
});

$("#comicImg").on("dblclick",function(e){
   if (!$('.img-magnifier-container div').hasClass('img-magnifier-glass')) {
    if (currentPage) {
      $('#btnToggleZoomIn').attr('disabled', true);
        magnifyArea(currentPage.frames[0], 0);
        savePlace(getInitialLoadCheck());    
    }
  }
})

var hammer = new Hammer.Manager(document.getElementById("comicImg"), {
  touchAction: 'auto',
  inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput,
  recognizers: [
  [Hammer.Swipe, {
  direction: Hammer.DIRECTION_HORIZONTAL
  }]
  ]
}); 

var swipeConfig = {
event: 'swipe',
pointers: 1,
threshold: 10,
direction: Hammer.DIRECTION_HORIZONTAL,
velocity: 0.3,
prevent_mouseevents: true,
};

var Swipe = new Hammer.Swipe(swipeConfig);

hammer.add(Swipe);

hammer.get('swipe').set({ direction: Hammer.DIRECTION_HORIZONTAL });

hammer.on('swipeleft', function(event) {
if (event.pointerType == 'touch') {
  window.swiped = true;
   moveToNextPage();   
  }
});

hammer.on('swiperight', function(event) {
if (event.pointerType == 'touch') {
  window.swiped = true;
   moveToPrevPage();
  }
});  

hammer.add(new Hammer.Pinch({event: 'pinch'})); // Add pinch gesture events.

hammer.on('pinchin', function(event) {
    enablePinchZoom = false;
}).on('pinchout', function(event) {
    enablePinchZoom = true;
}).on('pinchend', function(event) {
    console.log('hammer pinchend');
});

  $appContainer.find('#btnPreviousChapter').on('click', function (event) {
    event.preventDefault();
    showPreviousArea();
  });

  $appContainer.find('#btnNextChapter').on('click', function (event) {
    event.preventDefault();
    showNextArea();
  });

  // FSS fix for D-82639, have to add start and end events to tell audiobook play timer to NOT touch the slider when user is manipulating it
  $appContainer.find('#wdg-slider-input').on('mousedown touchstart', function (event) {
    fssUserAudiobookLocationOverrideForD_28639 = -1; // keep ignoring timer changes until slider released
  });

  $appContainer.find('#wdg-slider-input').on('input', function (event) {
    event.preventDefault();
    updatePositionAndFlag(true);
  });

  $appContainer.find('#wdg-slider-input').on('change', function (event) {
    event.preventDefault();
    updatePositionAndFlag(false);
    update = true;
    savePlace(getInitialLoadCheck);
    // FSS fix for D-82639, ignore next couple timer changes so that user's slide wins for sure
    fssUserAudiobookLocationOverrideForD_28639 = 2;
  });

  //not yet incorporated into ListenNow
  $appContainer.find('.js-player-skipToFirst').on('click', function (event) {
    event.preventDefault();
    player.changeTrack(0);
  });

  $appContainer.find('.js-player-skipToLast').on('click', function (event) {
    event.preventDefault();
    player.changeTrack(book.playlist.length - 1);
  });

  $appContainer.find('.js-volume').on('change', function (event) {
    event.preventDefault();
    var vol = $(this).val();
    player.setVolume(vol);
  });

  $(document).on('visibilitychange', () => {
    if ($('#btnPause span:first').hasClass('icon-icon-pause') && !player.isPlaying()) {
      togglePlayPause('play');
    }
  });

}

function clearSaveIntervals() {
  clearInterval(remoteInterval);
  clearInterval(localInterval);
}

var trackPageActivity = function (type, pagePosition, framePosition) { // FSS maintains a more active activity update process
  // this is also called by BookOptionsMenu after a successful checkout (DevOps 54488)
  return utils.isOnline().then(function () {
    //ga('send', 'event', 'GA Keep Alive', 'Comic');
    var curPage = pagePosition;
    var currentPage = (curPage != 'null' && curPage != -1) ? (curPage + 2) : 1;
    if (typeof type != 'undefined' && type == 'panel' && typeof framePosition != 'undefined' && framePosition != -1) {
      currentPage = parseInt(currentPage + '.' + (framePosition + 1));
    }

    var totalPages = menuItems.length;

    return engageManager.trackActivityToRemote(isbn, currentPage, totalPages, 'comic', 0).then(function (response) {
      // could not determine timeout so default to 15 minutes out
      //inactivityTimerId = window.setTimeout(triggerInactiveNotice, 900000);
    });
  }).catch(Errors.BrowserOffline, function () {
    //Unable to capture Activity, Browser Offline
    console.log("Browser is offline");
  });
};



// FSS adds a wrapper to updateActivity to prevent frequent calls if user clicks mouse or presses keys too frequently
//var updateActivityThrottled = _.throttle(updateActivity, 10000);
var updateActivityThrottled = _.throttle('', 10000);

var triggerInactiveNotice = function () { // FSS
  splashMessage.init({
    closeBtn: false,
    cssClass: "splash-system"
  });

  var message = {
    Type: "WARNING",
    Messages: ["This reading session has expired."]
  };
  $.extend(message, { 'urlParams': Helpers.getURLQueryParams() });

  splashMessage.message(message);
  splashMessage.$el.show();
  //deactivateActivityWatcher();
};

var initActivityWatcher = function () { // FSS maintains a more active activity update process
  document.documentElement.addEventListener('mousedown', updateActivityThrottled, true);
  document.documentElement.addEventListener('keyup', updateActivityThrottled, true);
};

var deactivateActivityWatcher = function () { // FSS maintains a more active activity update process
  $('#app-navbar').off('mousemove');
  navbarOverflowMenuFocusTrap = undefined;
  $(window).off('orientationchange');
  $(window).off('resize');
  window.clearTimeout(inactivityTimerId);
  document.documentElement.removeEventListener("mousedown", updateActivityThrottled);
  document.documentElement.removeEventListener("keyup", updateActivityThrottled);
  $('.book-title-header').remove();
  $('.offline-lib-btn').off('click');
  $(document.body).removeClass('hide-ui');
  // unbind listeners
  if (mainMenu) {
    mainMenu.unbindListeners();
  }
  // Reset to default value
  currentViewer = 'page';
  currentVisible = -1;
  currentPage = null;
  encryptionData = '';
   encryptionDom = '';
   menuItems = '';
   comicUrl = '';
   license = '';
   publicationFetcher = '';
   contentTransformer = '';
   comicUrl = '';
   isbn = '';
   $coverImage = '';
  $(document).unbind('fullscreenchange webkitfullscreenchange mozfullscreenchange msfullscreenchange');
  updateComicFooter();
};

function savePlace(callbackGetInitialLoadCheck) {
  /**
   * it used to restrict the initial call
   */
  if(!callbackGetInitialLoadCheck){
  var currPage,curArea,location = '';
  if(currentLocationIndex){
  currPage = currentLocationIndex;
  curArea = window.sessionStorage.getItem('COMIC_CURRENT_AREA');
  }else {
   currPage = window.sessionStorage.getItem('COMIC_CURRENT_PAGE');
   curArea = window.sessionStorage.getItem('COMIC_CURRENT_AREA');
  }
   currentLocationIndex = '';
   location = (currentPage == null) ? '-1' : currPage;
  if (location != -1 && curArea && curArea != -1) {
    location = location + '|' + curArea;
  }

  location = "cbzloc(" + location + ")";

  Promise.join(usageManager.getComicBookPageUsage(isbn))
    .spread(function (pgUsage) {
      pgUsage.location = location;
      var pageUsage = pgUsage;
      pageUsage.usercreatedate = new Date();
            // update local
      if(location !='cbzloc(null)'){
      return usageManager.saveComicBookPageUsage(pageUsage).then(function () {
        // Current position is only saved for books NOT in preview mode
        return utils
          .isOnline()
          .then(function () {
            return usageManager.saveCurrentComicPositionToRemote(isbn);
          })
          .catch(function (err) {
            return pageUsage;
          });
      })
    }
    })
    .catch(function (err) {
      console.error(err);
    });
  }else {
    setInitialLoadCheck('');
    currentLocationIndex = '';
  }
}

var displayError = function (error) {
  error.Messages = error.message;
  error.Type = 'Warning';
  var metaData = {
    'Findaway metadata': {
      id: player.currentTitle.id,
      title: player.currentTitle.title,
      account_id: player.currentTitle.account_id,
      publisher: player.currentTitle.publisher,
    },
  };
  errorMonitor.bugsnag(error, metaData);

  var urlParams = Helpers.getURLQueryParams();
  $.extend(error, { bookId: env.contentId, UUID: env.externalListener, urlParams: urlParams });

  var title = player ? player.currentTitle.title : ""; // FSS
  setBookTitle(title); // FSS
  splashMessage.init({
    closeBtn: false,
    cssClass: 'splash-system',
    urlParams: urlParams,
  });

  if (typeof error == 'string') {
    error = {
      Type: 'Error',
      Messages: ['An unexpected error has occurred.', error],
    };
  }

  error.Title = error.code ? error.code.replace('_', ' ') : Strings.err_dlg_title;

  splashMessage.message(error);
  splashMessage.$el.show();
};

var alertOffline = function (error) {
  error.code = 'Device Is Offline';
  error.message = 'Please return online for full audiobook functionality.';

  displayError(error);
};

var genericError = function (error) {
  error.code = 'Audiobook Error';
  error.message = 'Unable to access audiobook. Please try again later.';

  displayError(error);
};

var setTitle = function (branding) {
  document.title = branding;
};

var closeWindow = function() {
  // ga('send', 'event', 'Navbar', 'Click', 'Close Comicbook');
  var closeHelper = function() {
    if (window.location !== window.parent.location) {
        window.parent.postMessage({ type: "reader-close" }, "*");
    } else {
        // FSS adds this for D-27465
        if (window.opener) {
          // When Developer Tools is open and window is offline, window.close() must be called twice so this will not close.
            window.close();
        } else {
            $('.offline-lib-btn').click();
        }
    }
};
usageManager.closeComicBook(isbn).then(closeHelper, closeHelper); // close on success or fail
};

function updateCheckoutDueDate(returnDate){
  var $dueDateSelector = $("#due-date-text");
  if ($dueDateSelector.length) {
    $dueDateSelector.text("Due " + returnDate);
    $dueDateSelector.attr('aria-label', "Due " + returnDate);
  }
}

var hidePageLayout = function(callback) {
  var currentAspectRatio = $(window).width() / $(window).height();
  var $pageLayout = $('[aria-label="Page Layout"]').parents('.layout-options');
  const aspectRatio = 1 / 1.5;
  if (currentAspectRatio < aspectRatio) {
    callback();
    $pageLayout.addClass('hidden');
  } else {
    $pageLayout.removeClass('hidden');
  }
}

$(window).on('resize', function() {
  resizeIsTriggered = 1;
});

$(window).on('orientationchange', function() {
  $(':input:focus').blur();
});

function hideEPubOption() {
  $('.epubOption').hide();
  $('#epubOption').hide();
}

/** Keyboard Accessibility **/
function setFullScreenInActiveMode() {
  $("#btnFullScreenView").addClass("active");
  $("#btnExitFullScreenView").removeClass("active").addClass("hidden");
  currentVisible = -1;
  updateViewr('panel');
  showNextArea();
}

function setFullScreenActiveMode() {
  $("#btnFullScreenView").addClass("hidden");
  $("#btnExitFullScreenView").removeClass("hidden").addClass("active");
  currentVisible = -1;
  updateViewr('panel');
  showNextArea();
}

function formatListFocusedId(optionId){
  if (optionId == 'panel') {
    var promise = window.setToFullScreen(true);
    /* Browsers like Chrome, IE, Firefox returns to promise for set to fullscreen
    *  but Safari never support to promise to fullscreen like others.
    *  for better user experience this changes were made (like it wait till set to full screen so it can set to 80% of image)
    *  Coverting to promise is leads to issue in Safari(AB#101769) so it was not used
    *  AB#101769
    */
    if (promise) {
      promise.then(function () {
        setFullScreenActiveMode();
      }).catch(error => {
        setFullScreenInActiveMode();
      });  
    } else {
      setFullScreenActiveMode();
    }
  }
  else if (optionId == 'epub') {
    displaySwitchToEbookViewPopup();
  } else {
    var currIndex = comicMetaData.pages.indexOf(currentPage);
    updateViewr('page');
    moveToPage(currIndex);
    return;
  }
}

function defaultOptionExpand(){
  utils.isOnline().then(function () {
    if (formatResponseLength == 1) {
      hideEPubOption();
    }
  }).catch(Errors.BrowserOffline, function () {       
      hideEPubOption();
  });

  $('.navbar-btn').removeClass('active');
  popupModal.hideAll();
}
/******/
/* Format Drop down */
var initFormatViewUI = function() {
  $(".default_option").click(function () {
    defaultOptionExpand();
    $(this).parent().toggleClass("active");
     var expandStatus = $('.default_option').attr('aria-expanded');
      if((expandStatus == undefined) || (expandStatus == "false")) {
        $('.default_option').attr('aria-expanded', 'true');   
      }
      else{
        $('.default_option').attr('aria-expanded', 'false');
      }
  });

  /**** Only for Accessibility **
   * Purpose of SetTimeout is added to announce the combo box state(expand/collapse)
   * **/
  $(".default_option").keydown(function (e) {   
    if(e.key === "Enter"){
      defaultOptionExpand();
      $(this).parent().toggleClass("active");
      if($('.select_wrap').hasClass('active')){
        $('.default_option').attr('aria-expanded', 'true'); 
      }   
     // var selFormat = $('#Format_selected').text();
      var selFormat = $('#Format_selected').find('input[id=formattxt]').val();
      if(selFormat == "Comic Book (Page View)"){
        $("#page").focus();
        $('.select_ul').attr('aria-expanded', 'true');       
      }  
      else if(selFormat == "Comic Book (Frame View)"){
        $("#panel").focus();
        $('.select_ul').attr('aria-expanded', 'true');
      }       
     }

  });

  $(".select_ul li").keydown(function(e){
    if(e.keyCode === 38){ //uparrow
      var dataprevious = $(this).attr('data-previous');
      $('#'+dataprevious).focus();              
    }
    if(e.keyCode === 40){  //Downarrow
      var datanext = $(this).attr('data-next');
      $('#'+datanext).focus();
    }
    if(e.key === "Escape") {
      $('.select_wrap').removeClass('active');
      $('.default_option').attr('aria-expanded', 'false');
      $('.default_option').get(0).focus();
    }
    if(e.key === "Enter"){
      formatListFocusedId(document.activeElement.id);
      savePlace(getInitialLoadCheck());
      $('.select_wrap').removeClass('active');
      $('.default_option').attr('aria-expanded', 'false');
      $('.default_option').get(0).focus();
    }
    if(e.key == "Tab") {
      $('.select_wrap').removeClass('active');
    }    
  });  
  
  $(".select_ul li").click(function(){  
    var currentele = $(this).html();
    var selectedformattext = stripHtml(currentele);
    $("#formattxt").val(selectedformattext).change();
    selectedFormatId = selectedformattext;
    //$(".default_option li").html(currentele);
    //$("span.navbar-btn-format-label").html(currentele);    
    $(this).parents(".select_wrap").removeClass("active");
    //bookmarksMenu.hide();
    $('.default_option').attr('aria-expanded', 'false');
    $('.default_option').get(0).focus();
    formatListFocusedId(this.id);  
    savePlace(getInitialLoadCheck());    
  })
}
/****/

// Book View popup show/hide
var initDisplayOptionsUI = function() {
  $('#btnToggleBookView').on('click', function() {
    //Remove all active classes
    $('.navbar-btn').removeClass('active');
    var selectedFormat = $('.navbar-btn-format-label').text();
    if (bookviewOptionsPopup.$el.is(':visible')) {
      bookviewOptionsPopup.hide();
    } else {
      popupModal.hideAll();
      bookviewOptionsPopup.show();
      $(this).addClass('active');
      if(selectedFormat == "Comic Book (Page View)"){
        $('input[value="page"]').prop('checked', true);
      }  
      if(selectedFormat == "Comic Book (Frame View)"){
        $('input[value="panel"]').prop('checked', true);
      }      
    }
  });
  Keyboard.on('Format', 'reader', function() {
    triggerMenuOpener('#btnToggleBookView');
  });
}
// triggers handler of the menu openers
var triggerMenuOpener = function(opener) {
  //unhiding application navbar
  unhideUI();
  $(opener).trigger('click');
};

var updateComicFooter = function() {
  var $docBody = $(document.body);
  
  if ($docBody.hasClass('hide-footer-epub')) {
    $docBody.removeClass('hide-footer-epub');
  }

  if ($docBody.hasClass('hide-ui') && $docBody.hasClass('hide-pagination')) {
    $docBody.addClass('hide-footer-epub');
  }
  
  if (!$docBody.hasClass('hide-ui')) {
    $docBody.addClass('hide-btn-show-ui');
  } else {
    $docBody.removeClass('hide-btn-show-ui');
  }
};

var $bookviewOptionsContainerLayoutContent = $(BookViewOptions({ strings: Strings }));
var $bookviewOptionsContainerLayout = bookviewOptionsPopup.addContainerLayout(
  $bookviewOptionsContainerLayoutContent,
);

$(".js-close-popup").click(function () {
  $('#navbar-overflow-overlay').css('display', 'none');
  $('#btnToggleBookmark span:first').attr('class', 'icon icon-bookmark-outline'); //icon update
  $('#btnToggleHelp span:first').attr('class', 'icon icon-help'); //icon update
});

function displaySwitchToEbookViewPopup(){
  var popupModalFormatType = PopupFactory.create(PopupFactory.TYPE.MODAL, {id: "chooseFormatType"});
        popupModalFormatType.clear();
        popupModalFormatType.setMessage('You will lose your place in your book if you change to eBook View. Are you sure you want to change?');            
        popupModalFormatType.addButton("YES", function () {
          $('<button>').attr('tabindex', '-1');
          popupModalFormatType.hide();
          bookviewOptionsPopup.hide();
          $(window).triggerHandler('readepub', eventPayload);
        }, true);            
        popupModalFormatType.addButton("CANCEL", function () {
        $("#app-fullscreen").attr("style", "");
        var formatSelectedoption = currentViewer == "page" ? "Comic Book (Page View)" : currentViewer == "panel" ? "Comic Book (Frame View)" : "Comic Book (Page View)";
        $(".navbar-btn-format-label").text(formatSelectedoption);
        //$("#Format_selected").text(formatSelectedoption);
        $("#formattxt").val(formatSelectedoption).change();
        $("#Format_selected").attr({
          "title": formatSelectedoption,
          "aria-labelledby": formatSelectedoption,
        });
        popupModalFormatType.hide();  
        bookviewOptionsPopup.hide();             
        }, false);
        popupModalFormatType.show();
}
/**
 * Set Initial Load Check
 */
function setInitialLoadCheck(status) {
  initialLoad = status; 
}

/**
 * Get Initial Load Check
 */
function getInitialLoadCheck() {
    return initialLoad; 
}

/**
 * Get Menu Status Value
 * its commented out it may required for FSS
 */
// function getMenuClickStatus() {
//     return checkMenuClick; 
// }
/**
 * Set Menu Click Value
 * its commented out it may required for FSS
 */
// function setMenuClick(status) {
//   checkMenuClick = status; 
// }

/**
 *   Change the current visible it used for panel view
 */
var changeCurrentVisible = {
  
  set:function(currentState) {
      currentVisible = currentState;    
  },
  get:function() {
      return currentVisible;
  }
}

/**
 * it used to find Page index
 */
function findPageIndex(currIndex,type) {
   var pageLength = comicMetaData.pages.length - 1;
      
      if(type == 3 && currIndex <= pageLength) {
        return {currentPage:comicMetaData.pages[parseInt(currIndex)],currIndex:clickedBookmarkItem.index};
      } else if(pageLength <= currIndex) {
        return {currentPage: type == 2 || 3 ? comicMetaData.pages[parseInt(currIndex) - 2] : comicMetaData.pages[parseInt(currIndex) - 1],currIndex:type == 2 || 3 ? parseInt(currIndex) - 2 : parseInt(currIndex) - 1};
      } else if(type == 2) {
        return {currentPage: comicMetaData.pages[parseInt(currIndex)],currIndex:parseInt(currIndex)};
      } else {
        return {currentPage:comicMetaData.pages[parseInt(currIndex)],currIndex};
    }
}

/**
 * Disabled the Back Button
 * @param {*} currIndex 
 */
function disabledNxtButton(currIndex) {
  var pageLength = comicMetaData.pages.length - 1;

  if (currIndex == pageLength) {
      $('#right-page-btn').hide();
  } else {
      $('#right-page-btn').show();
  }

}

function stripHtml(html){
  // Create a new div element
  var temporalDivElement = document.createElement("div");
  // Set the HTML content with the providen
  temporalDivElement.innerHTML = html;
  return temporalDivElement.textContent || temporalDivElement.innerText || "";
}

export default {
  loadUI: loadUI,
  unloadUI: unloadUI,
  setTitle: setTitle,
};
