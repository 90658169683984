import AccessibilityJson from './accessibility/accessibility.json';

var json = AccessibilityJson;

var self = {};

self.getJson = function() {
  // return the actual instance of the JSON, allowing for external consumers to modify it!
  return json;
};

export default self;
