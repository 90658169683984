import $ from 'jquery';
import utils from '@axisnow/util/utils';
import Managers from '@axisnow/data/Managers';
import PopupFactory from '../../popup/PopupFactory';
import InlineAnnotationsMenu from '../../menus/inline/InlineAnnotationsMenu';
import InlineHighlightsMenu from '../../menus/inline/InlineHighlightsMenu';
import InlineNotesMenu from '../../menus/inline/InlineNotesMenu';
import InlineGoogleSearch from '../../menus/inline/InlineGoogleSearch';
import InlineWikiSearch from '../../menus/inline/InlineWikiSearch';
import InlineDictionarySearch from '../../menus/inline/InlineDictionarySearch';
import inlineWordReferenceMenuTemplate from '../../templates/inline-menu/in-place-word-reference.hbs';
import Feature from '@axisnow/data/app-permission/Feature';
import FeatureGroup from '@axisnow/data/app-permission/FeatureGroup';
import tts from '../../tts/tts';
import errorMonitor from './../../ErrorMonitor';

//Highlights
var inlineWordReferenceMenuPopup = PopupFactory.create(PopupFactory.TYPE.IN_PLACE, {
  id: 'appWordReference',
  cssClass: 'app-word-reference',
});

//SubMenus
var inlineWordReferenceMenu;
var inlineHighlightsMenu;
var inlineNotesMenu;
var inlineGoogleSearch;
var inlineWikiSearch;
var inlineDictionarySearch;

//Static Calls
inlineWordReferenceMenuPopup.$body.html(inlineWordReferenceMenuTemplate({}));

//Constructor
var InlineWordReferenceMenu = function() {
  var that = this;
  InlineAnnotationsMenu.apply(this, arguments);
  inlineHighlightsMenu = new InlineHighlightsMenu(this.readium, this.usageManager, this.isbn);
  inlineNotesMenu = new InlineNotesMenu(this.readium, this.usageManager, this.isbn);
  inlineGoogleSearch = new InlineGoogleSearch();
  inlineWikiSearch = new InlineWikiSearch();
  inlineDictionarySearch = new InlineDictionarySearch(this.readium, this.usageManager, this.isbn);

  this.popup(inlineWordReferenceMenuPopup);
  /*this.eventEmitter.on("annotation:updated", function(){
                alert("Updated annotation");
            })*/
  this.on('annotation:updated', function(annotation) {
    inlineHighlightsMenu.annotation = annotation;
    inlineNotesMenu.annotation = annotation;
  });

  this.getButton('highlight')
    .off('click')
    .on('click', function() {
      setTimeout(function() {
        inlineHighlightsMenu.show.call(inlineHighlightsMenu, undefined, true).then(function() {
          console.log("Inline highlights menu displayed");
        }).catch(function(error) {
          console.error(error);
          errorMonitor.bugsnag(error);
        });
      }, 150);
    });

  this.getButton('note')
    .off('click')
    .on('click', function() {
      inlineNotesMenu.show.call(inlineNotesMenu, null, true).then(function() {
        console.log("Inline notes menu displayed");
      }).catch(function(error) {
        console.error(error);
        errorMonitor.bugsnag(error);
      });
    });

  var ttsEvents = tts.isIOSMode() ? "click touchstart touchend" : "click";
  var ttsEventCheck = tts.isIOSMode() ? "touchend" : "click";

  this.getButton("tts-play").off(ttsEvents).on(ttsEvents, function(ev) {
    if (ev.type == ttsEventCheck) {
      // prevent multiple call in ios from click, touchstart, touchend (but still need to handle touch events to reliably catch button press in ios)
      tts.play(true);
    }
    ev.stopPropagation();
    ev.preventDefault();
    return false; // on false, jquery event handler stops propagation and default handling
  });

  this.getButton("tts-pause").off(ttsEvents).on(ttsEvents, function(ev) {
    if (ev.type == ttsEventCheck) {
      // prevent multiple call in ios from click, touchstart, touchend (but still need to handle touch events to reliably catch button press in ios)
      tts.pause(true);
    }
    ev.stopPropagation();
    ev.preventDefault();
    return false; // on false, jquery event handler stops propagation and default handling
  });

  this.getButton('google')
    .off('click')
    .on('click', function() {
      inlineGoogleSearch.show.call(inlineGoogleSearch).then(function() {
        console.log("Inline Google Search menu displayed");
      }).catch(function(error) {
        console.error(error);
        errorMonitor.bugsnag(error);
      });
    });

  this.getButton('wikipedia')
    .off('click')
    .on('click', function() {
      inlineWikiSearch.show.call(inlineWikiSearch).then(function() {
        console.log("Inline Wiki Search displayed");
      }).catch(function(error) {
        console.error(error);
        errorMonitor.bugsnag(error);
      });
    });

  this.getButton('dictionary')
    .off('click')
    .on('click', function() {
      inlineDictionarySearch.show.call(inlineDictionarySearch).then(function() {
        console.log("Inline Dictionary menu displayed");
      }).catch(function(error) {
        console.error(error);
        errorMonitor.bugsnag(error);
      });;
    });

  this.getButton('booksearch')
    .off('click')
    .on('click', function() {
      that.emit('booksearch', that._textSelectionEvent.text);
    });
  this.getButton('wikipedia').hide();
};

InlineWordReferenceMenu.prototype = Object.create(InlineAnnotationsMenu.prototype);

var featureManager = Managers.feature;
var inlineMenuFeaturesArray = [];
inlineMenuFeaturesArray.push(
  new Feature(
    'Notes-inlineMenu',
    function() {
      showHideInlineMenu(false, 'icon-add-note');
      console.log('Notes Inline menu feature enabled');
    },
    function() {
      showHideInlineMenu(true, 'icon-add-note');
      console.log('Notes Inline menu feature disabled');
    },
  ),
  new Feature(
    'Highlights-inlineMenu',
    function() {
      showHideInlineMenu(false, 'icon-edit');
      inlineWordReferenceMenuPopup.$body
        .find('.icon-edit')
        .closest('button')
        .show();
      console.log('Highlights Inline menu feature enabled');
    },
    function() {
      showHideInlineMenu(true, 'icon-edit');
      console.log('Highlights Inline menu feature disabled');
    },
  ),
  //Search inline menu feature
  new Feature(
    'SearchMenu-inline',
    function() {
      inlineWordReferenceMenuPopup.$body
        .find('.icon-inner-search,.icon-dictionary,.icon-wikipedia,.icon-google')
        .closest('button')
        .show();
      console.log('Search inline Menu feature enabled');
    },
    function() {
      inlineWordReferenceMenuPopup.$body
        .find('.icon-inner-search,.icon-dictionary,.icon-wikipedia,.icon-google')
        .closest('button')
        .hide();
      inlineWordReferenceMenuPopup.$body.find('.toolbar').css('min-width', '0px');
      console.log('Search inline Menu feature disabled');
    },
  ),
  new Feature(
    'DictionarySearch',
    function() {
      showHideInlineMenu(false, 'icon-dictionary');
      console.log('Dictionary search inline Menu feature enabled');
    },
    function() {
      showHideInlineMenu(true, 'icon-dictionary');
      console.log('Dictionary search inline Menu feature disabled');
    },
  ),
  new Feature(
    'GoogleSearch',
    function() {
      showHideInlineMenu(false, 'icon-google');
      console.log('Google search inline Menu feature enabled');
    },
    function() {
      showHideInlineMenu(true, 'icon-google');
      console.log('Google search inline Menu feature disabled');
    },
  ),
  new Feature(
    'WikipediaSearch',
    function() {
      showHideInlineMenu(false, 'icon-wikipedia');
      console.log('Wikipedia search inline Menu feature enabled');
    },
    function() {
      showHideInlineMenu(true, 'icon-wikipedia');
      console.log('Wikipedia search inline Menu feature disabled');
    },
  ),
  new Feature(
    'InlineSearch',
    function() {
      showHideInlineMenu(false, 'icon-inner-search');
      console.log('Search inline Menu feature enabled');
    },
    function() {
      showHideInlineMenu(true, 'icon-inner-search');
      console.log('Search inline Menu feature disabled');
    },
  ),
);
featureManager.feature(inlineMenuFeaturesArray);
featureManager.featureGroup(new FeatureGroup('InlineMenus', inlineMenuFeaturesArray));
function showHideInlineMenu(isHide, iconClass) {
  var inlineMenu = inlineWordReferenceMenuPopup.$body;
  if (isHide) {
    inlineMenu
      .find('.' + iconClass)
      .closest('button')
      .hide();
    inlineMenu.find('.toolbar').css('min-width', '0px');
    return;
  }
  inlineMenu
    .find('.' + iconClass)
    .closest('button')
    .show();
}
//Public API
InlineWordReferenceMenu.prototype.getButton = function(name) {
  var btnIconSelector = {
    highlight: '.icon-edit',
    note: '.icon-add-note',
    'tts-play': '.icon-icon-play',
    'tts-pause': '.icon-icon-pause',
    dictionary: '.icon-dictionary',
    google: '.icon-google',
    wikipedia: '.icon-wikipedia',
    booksearch: '.icon-inner-search ',
  };
  return inlineWordReferenceMenuPopup.$el.find(btnIconSelector[name]).parent();
};

InlineWordReferenceMenu.prototype.showHighlightMenu = function(triggerElement) {
  setTimeout(function() {
    inlineHighlightsMenu.show.call(inlineHighlightsMenu, triggerElement,false).then(function() {
      console.log("Inline Highlights menu displayed");
    }).catch(function(error) {
      console.error(error);
      errorMonitor.bugsnag(error);
    });
  }, 150);
};

InlineWordReferenceMenu.prototype.updateHighlightMenu = function() {
  inlineHighlightsMenu.update.call(inlineHighlightsMenu);
};

InlineWordReferenceMenu.prototype.updateNoteMenu = function() {
  inlineNotesMenu.update.call(inlineNotesMenu);
};

InlineWordReferenceMenu.prototype.closeHighlightMenu = function() {
  inlineHighlightsMenu.hide.call(inlineHighlightsMenu);
};

InlineWordReferenceMenu.prototype.showNoteMenu = function(triggerElement) {
  var self = this;
  inlineNotesMenu.show.call(inlineNotesMenu, triggerElement, false).then(function() {
      console.log("Inline notes menu displayed");
  }).catch(function(error) {
    console.error(error);
    errorMonitor.bugsnag(error);
  });
};

InlineWordReferenceMenu.prototype.show = function() {
  $("body").one("hideAllPopupMenus", function() {
    // .one, fire once and only once then handler removes itself (thanks DW!)
    tts.stopSelection();
  });
  //AX-856: Hide inline menu.  This should be dynamically determined and based on other feature settings.  So if all the inline menu items are hidden, then the inline should not be shown
  if (
    $('.app-word-reference').find('button[style*="none"]').length ==
    $('.app-word-reference').find('button').length
  ) {
    return false;
  }
  //ReadAlong: Disable TTS if MediaOverlay available
  if(this.readium.reader.isMediaOverlayAvailable()) {
     $('#btnTTSPlaySelection').addClass('hidden');
     $('#btnTTSPlaySelection').hide();
     $('#btnTTSPauseSelection').addClass('hidden');
  } else { 
     $('#btnTTSPlaySelection').show();
     $('#btnTTSPlaySelection').removeClass('hidden');
     $('#btnTTSPauseSelection').addClass('hidden');
  }
  var highlightIcons = inlineWordReferenceMenuPopup.$el
    .find('.icon-dictionary, .icon-wikipedia, .icon-google, .icon-inner-search')
    .parent('.btn');
  highlightIcons.removeClass('btn-greyedOut');
  highlightIcons.prop('disabled', true);
  inlineWordReferenceMenuPopup.show();
  utils
    .isOnline()
    .then(function() {
      // enable buttons
      highlightIcons.prop('disabled', false);
    })
    .catch(function() {
      highlightIcons.addClass('btn-greyedOut'); //css('color','#AAAFB4')
    });

  $('#app-container').removeClass('open');
  this.centerMenuToSelection();
};

InlineWordReferenceMenu.prototype.textSelectionEvent = function(textSelectionEvent) {
  this._textSelectionEvent = textSelectionEvent;
  InlineAnnotationsMenu.prototype.textSelectionEvent.call(this, textSelectionEvent);
  inlineHighlightsMenu.textSelectionEvent(textSelectionEvent);
  inlineNotesMenu.textSelectionEvent(textSelectionEvent);
  inlineGoogleSearch.textSelectionEvent(textSelectionEvent);
  inlineWikiSearch.textSelectionEvent(textSelectionEvent);
  inlineDictionarySearch.textSelectionEvent(textSelectionEvent);
};

InlineWordReferenceMenu.prototype.selectedHighlightType = function(selectedHighlightType) {
  InlineAnnotationsMenu.prototype.selectedHighlightType.call(this, selectedHighlightType);
  inlineHighlightsMenu.selectedHighlightType(selectedHighlightType);
  inlineNotesMenu.selectedHighlightType(selectedHighlightType);
};

/*InlineWordReferenceMenu.prototype.isCreatingAnnotation = function (isCreatingAnnotation) {
            InlineAnnotationsMenu.prototype.isCreatingAnnotation.call(this, isCreatingAnnotation);
            inlineHighlightsMenu.isCreatingAnnotation(isCreatingAnnotation);
            inlineNotesMenu.isCreatingAnnotation(isCreatingAnnotation);
        };*/

InlineWordReferenceMenu.prototype.annotation = function(annotation) {
  InlineAnnotationsMenu.prototype.annotation.call(this, annotation);
  inlineHighlightsMenu.annotation(annotation);
  inlineNotesMenu.annotation(annotation);
};

export default InlineWordReferenceMenu;
