import $ from 'jquery';
import ProgressWidget from './ProgressWidget';
import SpinnerTemplate from './templates/spinner-progress.hbs';

var r = 65,
    h = 200,
    w = 200,
    cx = 100,
    cy = 100,
    cir = 2 * Math.PI * r;
function registerProgressCtrl() {
    var wdg = this;

    var cssSpinner = {
        strokeDashoffset: cir,
        //transition: "stroke-dashoffset .1s linear",
        stroke: '#fff',
        strokeWidth: '.6em',
    };
    var cssPCT = {
        position: 'absolute',
        width: '100%',
        top: '39%',
        left: 0,
        bottom: 0,
        fontSize: '2.2em',
        textAlign: 'center',
    };

    var $circle;
    var $pct;

    return {
        init: function() {
            $pct = $(wdg.$el)
                .find('.pct')
                .css(cssPCT);
            $circle = $(wdg.$el).find('svg .bar');
            $circle.css(cssSpinner);
            $pct.parent('.splash-icon').css('position', 'relative');
        },
        start: function() {
            $circle.css({ strokeDashoffset: cir });
        },
        stop: function() {
            $circle.css({ strokeDashoffset: 0 });
        },
        setValue: function(val) {
            if (val < 0) {
                val = 0;
            }
            if (val > 100) {
                val = 100;
            }
            var pct = ((100 - val) / 100) * cir;

            $circle.css({ strokeDashoffset: pct });
            $pct.text(val + '%');
            $(wdg.$el)
                .find('.splash-icon')
                .attr('aria-valuenow', val);
        },
    };
}
export default ProgressWidget.create('body, #app-fullscreen', {
    template: SpinnerTemplate({
        r: r,
        cx: cx,
        cy: cy,
        cir: cir,
        w: w,
        h: h,
        msg: 'Downloading to your browser for offline access',
    }),
    detached: true,
    progress: {
        type: registerProgressCtrl,
    },
    style: {},
});
