import _ from 'underscore';
import URI from 'urijs';
import CryptoJS_SHA1 from 'crypto-js/sha1';

var EncryptionDocumentParser = function(publicationFetcher) {
  var _packageFetcher = publicationFetcher;
  var _xmlDom;

  // Parse the encryption.xml document into a javascript object
  this.parse = function(id, encryptionDom, callback) {
    if (!encryptionDom) {
      callback(undefined);
      return;
    }

    var txt = unescape(encodeURIComponent(id.trim()));
    var sha = CryptoJS_SHA1(txt);

    var byteArray = [];

    for (var i = 0; i < sha.sigBytes; i++) {
      byteArray.push((sha.words[i >>> 2] >>> (24 - (i % 4) * 8)) & 0xff);
    }

    var encryptionData = {
      uid: id,
      uidHash: byteArray,
      encryptions: undefined,
    };

    /*

            var encryptedData = $('EncryptedData', encryptionDom);
            encryptedData.each(function (index, encryptedData) {
                var encryptionAlgorithm = $('EncryptionMethod', encryptedData).first().attr('Algorithm');

                // For some reason, jQuery selector "" against XML DOM sometimes doesn't match properly
                var cipherReference = $('CipherReference', encryptedData);
                cipherReference.each(function (index, CipherReference) {

                    //var cipherReferenceURI = "/" + $(CipherReference).attr('URI');
                    var cipherReferenceURI = $(CipherReference).attr('URI');

                    console.log('Encryption/obfuscation algorithm ' + encryptionAlgorithm + ' specified for ' +
                        cipherReferenceURI);

                    if(!encryptionData.encryptions) {
                        encryptionData.encryptions = {};
                    }

                    encryptionData.encryptions[cipherReferenceURI] = {
                        algorithm: encryptionAlgorithm
                    };
                });

            });
*/
    var encryptedDataElems = encryptionDom.getElementsByTagNameNS('*', 'EncryptedData');
    //var $encryptedData = $(encryptionDom.getElementsByTagNameNS("*", "EncryptedData"));
    //$.each()
    Array.prototype.forEach.call(encryptedDataElems, function(element, index, array) {
      var encryptionAlgorithm = findXmlElemByLocalNameAnyNS(
        element,
        'EncryptionMethod',
      ).getAttribute('Algorithm');
      var cipherReferenceURI = findXmlElemByLocalNameAnyNS(element, 'CipherReference').getAttribute(
        'URI',
      );
      var retrievalMethod = findXmlElemByLocalNameAnyNS(element, 'RetrievalMethod');

      if (!encryptionData.encryptions) {
        encryptionData.encryptions = {};
      }
      var data = {
        algorithm: encryptionAlgorithm,
      };

      if (retrievalMethod !== undefined && retrievalMethod.length !== 0) {
        data.key = {
          uri: retrievalMethod.getAttribute('URI'),
          type: retrievalMethod.getAttribute('Type'),
        };
      }
      encryptionData.encryptions[cipherReferenceURI] = data;
    });

    return encryptionData;
  };

  //XML Helper functions
  function findXmlElemByLocalNameAnyNS(rootElement, localName, predicate) {
    var elements = rootElement.getElementsByTagNameNS('*', localName);
    if (predicate) {
      return _.find(elements, predicate);
    } else {
      return elements[0];
    }
  }

  function filterXmlElemsByLocalNameAnyNS(rootElement, localName, predicate) {
    var elements = rootElement.getElementsByTagNameNS('*', localName);
    return _.filter(elements, predicate);
  }

  function getElemText(rootElement, localName, predicate) {
    var foundElement = findXmlElemByLocalNameAnyNS(rootElement, localName, predicate);
    if (foundElement) {
      return foundElement.textContent;
    } else {
      return '';
    }
  }

  function getElemAttr(rootElement, localName, attrName, predicate) {
    var foundElement = findXmlElemByLocalNameAnyNS(rootElement, localName, predicate);
    if (foundElement) {
      return foundElement.getAttribute(attrName);
    } else {
      return '';
    }
  }
};

export default EncryptionDocumentParser;
