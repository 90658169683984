import $ from 'jquery';
import IndexDBDataManager from '@axisnow/storage/IndexedDBDataManager';
import License from './License';
import LicenseFetcher from './LicenseFetcher';
import KeyStore from '@axisnow/crypto/keystore';
import Errors from '@axisnow/util/Errors';

var _dataManager = new IndexDBDataManager('LicenseStore', manageConflict);

var keyStore;
var licenseFetcher;
var getCurrentUser;
var userManager;

var LicenseManager = function(userMgr) {
  userManager = userMgr;
  keyStore = new KeyStore();
  licenseFetcher = new LicenseFetcher(userManager);
  getCurrentUser = userManager.getCurrentUser;
};

// Private API

function makeLicenseStore(license, isbn, authData) {
  return getCurrentUser()
    .then(function(user) {
      var uuid_isbn = user.bookvaultId + '|' + isbn;
      return [_dataManager.get('uuid_isbn', uuid_isbn), user];
    })
    .spread(function(licenseObj, user) {
      $.extend(licenseObj, {
        uuid_isbn: user.bookvaultId + '|' + isbn,
        uuid: user.bookvaultId,
        isbn: isbn,
        id: user.id,
        license: license,
        authData: authData
      });
      return licenseObj;
    });
}

function getUnkeyedLicenseFromStorage(isbn) {
  return getCurrentUser().then(function(user) {
    var uuid_isbn = user.bookvaultId + '|' + isbn;
    return _dataManager.get('uuid_isbn', uuid_isbn);
  });
}
function getKeyedLicenseFromStorage(isbn) {
  var self = this;
  return getCurrentUser().then(function(user) {
    var uuid_isbn = user.bookvaultId + '|' + isbn;
    return _dataManager
      .get('uuid_isbn', uuid_isbn)
      .then(function(data) {
        return [keyStore.getKey('name', user.bookvaultId), data];
      })
      .spread(function(key, data) {
        if (typeof data.license !== 'undefined') {
          if (typeof data.license._key === 'undefined') {
            data.license = new License(data.license, key);
          }
          return data;
        }
        console.error('LicenseObj (' + uuid_isbn + ') does not have a license');
        throw new Errors.UnableToRetrieveLicense(
          'License Error',
          'Unable retrieve a license. Please try again after re-downloading book.',
        );
      });
  });
}

function getAllUnkeyedLicensesFromStorage(index, value) {
  return _dataManager
    .getAll(index, value)
    .then(function(data) {
      return data;
    })
    .catch(function(error) {
      console.error('getLicensesFromStorage error', error);
    });
}
function saveLicenseToStorage(licenseObject) {
  if (!licenseObject.license) {
    throw new Error('Error saving license: licenseObj.license is undefined');
  }
  var key = licenseObject.license._key;
  return _dataManager
    .put('uuid_isbn', licenseObject.uuid_isbn, JSON.parse(JSON.stringify(licenseObject)))
    .then(function(licenseObject) {
      licenseObject.license = new License(licenseObject.license, key);
      return licenseObject;
    })
    .catch(function(error) {
      console.error('saveLicenseToStorage error', error);
    });
}

function manageConflict(license1, license2) {
  var issued1 = license1.license.issued;
  var issued2 = license2.license.issued;

  if (issued1 !== issued2) {
    var date1 = new Date(issued1);
    var date2 = new Date(issued2);

    if (date1 > date2) {
      return license1;
    } else {
      return license2;
    }
  } else {
    var update1 = license1._meta.updateTime;
    var update2 = license2._meta.updateTime;

    date1 = new Date(update1);
    date2 = new Date(update2);

    if (date1 > date2) {
      return license1;
    } else {
      return license2;
    }
  }
}

function getAuthDataFromStorage(isbn) {
  var authData = userManager.getCurrentAuthData();
  if (authData.ISBN == isbn) {
    // if isbn matches the currently opened book use the fresh authData value
    return Promise.resolve(authData);
  } else {
    // else this must be for a license validation of some other book, look up authData saved for that one
    return getKeyedLicenseFromStorage(isbn)
    .then(function(licenseObj) {
      return (licenseObj && licenseObj.authData) || {};
    }).catch(function (ex) {
      return {};
    });
  }
}


//Public API

// Fetches a new license from remote
LicenseManager.prototype.fetchLicense = function(isbn) {
  var self = this;
  // FSS needs to persist the authData in order to get the productTypeId needed for FSS license retrieval
  var theAuthData;
  return getAuthDataFromStorage(isbn) // getAuthDataFromStorage will never fail, will just return empty object
  .then (function(authData) {
    theAuthData = authData;
    return licenseFetcher.getLicenseDocument(isbn, theAuthData.productTypeId);
  })
  .then(function(license) {
    return makeLicenseStore(license, isbn, theAuthData).then(function(licenseObject) { // FSS needs theAuthData persisted
      return self.saveLicense(licenseObject);
    });
  })
  .catch(function(error) {
    if (
      error instanceof Errors.LicenseExpired ||
      error instanceof Errors.UserHasNoRights ||
      error instanceof Errors.BookOwnershipNotFound
    ) {
      self.deleteLicense(isbn);
      throw error;
    } else if (
      error instanceof Errors.UnableToRetrieveLicense ||
      error instanceof Errors.BrowserOffline
    ) {
      return getKeyedLicenseFromStorage(isbn).catch(function(ex) {
        throw ex;
      });
    }
  });
};

// Gets license from the storageManager
LicenseManager.prototype.getUnkeyedLicense = function(isbn) {
  return getUnkeyedLicenseFromStorage(isbn);
};

LicenseManager.prototype.getAllUnkeyedLicensesWithIndex = function(index, value) {
  return getAllUnkeyedLicensesFromStorage(index, value);
};

LicenseManager.prototype.saveLicense = function(licenseObject) {
  return saveLicenseToStorage(licenseObject);
};

LicenseManager.prototype.deleteLicense = function(isbn) {
  return getUnkeyedLicenseFromStorage(isbn).then(function(licenseObj) {
    if (!licenseObj.uuid_isbn) {
      //license was previously deleted, no need to remove
      return licenseObj;
    }

    return _dataManager
      .remove('uuid_isbn', licenseObj.uuid_isbn, licenseObj)
      .catch(function(error) {
        console.error('deleteLicense error', error);
      });
  });
};

LicenseManager.prototype.validateLicense = function(isbn) {
  var self = this;
  return getKeyedLicenseFromStorage(isbn)
    .then(function(licenseObj) {
      return licenseObj.license.isValidAndNotExpired().then(function() {
        return true;
      });
    })
    .catch(function(error) {
      // if license is undefined, invalid, or expired, delete license
      return self.deleteLicense(isbn).then(function() {
        return false;
      });
    });
};

LicenseManager.prototype.updateLicense = function(isbn, productTypeId) {
  var self = this;
  return licenseFetcher
    .getLicenseDocument(isbn, productTypeId)
    .then(function() {
      return true;
    })
    .catch(function(error) {
      if (
        !(error instanceof Errors.UnableToRetrieveLicense || error instanceof Errors.BrowserOffline)
      ) {
        // if license is not valid, delete license
        return self.deleteLicense(isbn).then(function() {
          return false;
        });
      }
      return true;
    });
};

LicenseManager.prototype.getAuthData = function (isbn) {
  return getAuthDataFromStorage(isbn);
}

export default LicenseManager;
