import moment from 'moment-timezone';
/**
 * Shared Helpers
 */

/**
 *
 * @returns object (map between URL query parameter names and corresponding decoded / unescaped values)
 */
export function getURLQueryParams() {
  var params = {};

  var query = '?' + window.location.hash.split('?').pop();

  if (query && query.length) {
    query = query.substring(1);
    var keyParams = query.split('&');
    for (var x = 0; x < keyParams.length; x++) {
      var keyVal = keyParams[x].split('=');
      if (keyVal.length > 1) {
        params[keyVal[0]] = decodeURIComponent(keyVal[1]);
      }
    }
  }

  return params;
}

/**
 * Below method used to get TimezoneOffset
 * @returns 
 */
 export function getTimezone(){
  var timeZoneOffset = new Date().getTimezoneOffset();
  var timeZone = moment.tz.zone(moment.tz.guess()).abbr(timeZoneOffset);
  return timeZone;
 }
