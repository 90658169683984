var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div role=\"main\" class=\"page-view page-landing\">\n\n    <div class=\"container\">\n\n        <div class=\"landing-icon text-center\">\n            <!--<img src=\"coverImageSrc\" width=\"362\" height=\"573\">-->\n        </div>\n        <h3 class=\"text-center\" id=\"chapterTitle\">\n            chapterTitle\n        </h3>\n\n        <div class=\"container-form form-material player\">\n            <div class=\"player-button-group text-center\">\n                <button class=\"player-btn\" type=\"button\" id=\"btnPreviousChapter\" title=\"Previous Chapter\" aria-label=\"Previous Chapter\" accesskey=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"keyboard") : depth0)) != null ? lookupProperty(stack1,"accesskeys") : stack1)) != null ? lookupProperty(stack1,"PlayerPrevChapter") : stack1), depth0))
    + "\">\n                    <span aria-hidden=\"true\" class=\"icon icon-icon-prev-chapter\"></span>\n                    <span aria-hidden=\"true\" class=\"sr-only\">Previous Chapter</span>\n                </button>\n                <button class=\"player-btn\" type=\"button\" id=\"btnPrevious\" title=\"Previous 30 seconds\" aria-label=\"Previous 30 seconds\" accesskey=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"keyboard") : depth0)) != null ? lookupProperty(stack1,"accesskeys") : stack1)) != null ? lookupProperty(stack1,"Prev30SecondsPlayer") : stack1), depth0))
    + "\">\n                    <span aria-hidden=\"true\" class=\"icon icon-icon-prev\"></span>\n                    <span aria-hidden=\"true\" class=\"sr-only\">Previous</span>\n                </button>\n                <button class=\"player-btn play-pause\" type=\"button\" id=\"btnPause\" title=\"Play\" aria-label=\"Play\" accesskey=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"keyboard") : depth0)) != null ? lookupProperty(stack1,"accesskeys") : stack1)) != null ? lookupProperty(stack1,"PlayPausePlayer") : stack1), depth0))
    + "\">\n                    <span aria-hidden=\"true\" class=\"icon icon-audio-play\"></span>\n                    <span aria-hidden=\"true\" class=\"sr-only\">Play/Pause</span>\n                </button>\n                <!-- Use below code for Play Button or replace button ID and class in the span.-->\n                <!--<button class=\"player-btn\" type=\"button\" id=\"btnPlay\">-->\n                    <!--<span class=\"icon icon-icon-play\"></span>-->\n                <!--</button>-->\n                <button class=\"player-btn\" type=\"button\" id=\"btnNext\" title=\"Move Next 30 seconds\" aria-label=\"Move Next 30 seconds\" accesskey=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"keyboard") : depth0)) != null ? lookupProperty(stack1,"accesskeys") : stack1)) != null ? lookupProperty(stack1,"Next30SecondsPlayer") : stack1), depth0))
    + "\">\n                    <span aria-hidden=\"true\" class=\"icon icon-icon-next\"></span>\n                    <span aria-hidden=\"true\" class=\"sr-only\">Next</span>\n                </button>\n                <button class=\"player-btn\" type=\"button\" id=\"btnNextChapter\" title=\"Next Chapter\" aria-label=\"Next Chapter\" accesskey=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"keyboard") : depth0)) != null ? lookupProperty(stack1,"accesskeys") : stack1)) != null ? lookupProperty(stack1,"PlayerNextChapter") : stack1), depth0))
    + "\">\n                    <span aria-hidden=\"true\" class=\"icon icon-icon-next-chapter\"></span>\n                    <span aria-hidden=\"true\" class=\"sr-only\">Next Chapter</span>\n                </button>\n            </div>\n        </div>\n    </div>\n</div>\n\n<footer class=\"app-footer\">\n\n    <div class=\"container\">\n        <div class=\"wdg-slider-control\">\n            <div class=\"wdg-slider\">\n                <input id=\"wdg-slider-input\" type=\"range\" min=\"0\" step=\"1000\" value=\"0\">\n                <!--<div class=\"track\"></div>-->\n                <!--<div class=\"track lower-range\"></div>-->\n                <!--<div class=\"track higher-range\"></div>-->\n                <!--<div class=\"thumb\"></div>-->\n            </div>\n            <div class=\"pagination-current\"></div>\n            <div class=\"pagination-remaining\"></div>\n        </div>\n    </div>\n\n</footer>\n";
},"useData":true});