import $ from 'jquery';
import PopupFactory from '../../popup/PopupFactory';
import Helpers from '../../EpubReaderHelpers';
import InlineAnnotationsMenu from '../../menus/inline/InlineAnnotationsMenu';
import InlineNotes from '../../templates/inline-menu/in-place-note-editor.hbs';
import AccessibilityManager from '../../accessibility/AcessibilityManager';
import Promise from 'bluebird';
import moment from 'moment-timezone';

//Notes
var inlineNotes = PopupFactory.create(PopupFactory.TYPE.IN_PLACE, {
  id: 'inlineNotesMenu',
  cssClass: 'app-note-popup',
});
inlineNotes.$body.html(InlineNotes({}));
var oldHide = inlineNotes.hide;
inlineNotes.hide = function() {
  this.$el.trigger('hideInlineNote');
  oldHide.call(this);
};

var InlineNotesMenu = function() {
  InlineAnnotationsMenu.apply(this, arguments);
  this.popup(inlineNotes);
};

InlineNotesMenu.prototype = Object.create(InlineAnnotationsMenu.prototype);

InlineNotesMenu.prototype.update = function() {
  this.centerMenuToSelection();
};

InlineNotesMenu.prototype.show = Promise.method(function(triggerElement, newAnnotation) {
  console.log("First Call");
  this.hideAll();
  this.centerMenuToSelection();
  let originalColor;
  let color = this.selectedHighlightType() || 'yellow';
  let annotation = this.annotation;
  this.newAnnotation = newAnnotation;
  let self = this;
  this.bindEvents();
  if (!!annotation) {
    inlineNotes.$body.find('textarea').val(annotation.notetext);
    self.centerMenuToSelection();
    inlineNotes.show(triggerElement);
    inlineNotes.$el.data(self.textSelectionEvent());
    originalColor = annotation.color;
    inlineNotes.$body.find('.' + originalColor + ' input').click();
  } else {
    inlineNotes.$body.find('textarea').val('');
    self.centerMenuToSelection();
    inlineNotes.show();

    inlineNotes.$el.data(self.textSelectionEvent());
    inlineNotes.$body.find('.' + color + ' input').click();
    //inlineNotes.$body.find(".yellow input").click();
  }
  inlineNotes.$body.find('textarea').focus();

  // AccessibilityManager.panelCloseHandler(function() {
  //     AccessibilityManager.setTabGroup("add_note_popup")
  //     setTimeout(function() {
  //         AccessibilityManager.updateTabOrder("add_note_popup")
  //     }, 50)
  // })
  inlineNotes.$body.find("input[name='selected-note-color']:checked").attr('aria-checked', 'true');
  return;
});

InlineNotesMenu.prototype.bindEvents =function() {
let self = this;
  inlineNotes.$body
    .find("input[name='selected-note-color']")
    .off('click')
    .on(
      'click',
      $.proxy(function(e) {
        var textSelectionEvent = inlineNotes.$el.data();
        if (!textSelectionEvent) {
          return;
        }

        //clear the selection using a better method:
        textSelectionEvent.clear();

        let cfiObj = textSelectionEvent.cfi;
        let fullCFI = Helpers.locObjToFullCFI(this.readium.packageDocument, cfiObj);

        let highlight = this.readium.reader.plugins.highlights.getHighlight(fullCFI);

        let color = $(e.currentTarget).val();
        if (!highlight) {
          this.readium.reader.plugins.highlights.addHighlight(
            cfiObj.idref,
            cfiObj.contentCFI,
            fullCFI,
            color,
            null,
          );
        } else {
          this.readium.reader.plugins.highlights.updateAnnotation(fullCFI, color, null);
          $('#statusDiv').text('selected text is colored as ' + color);
        }

        inlineNotes.$body.find("input[name='selected-note-color']").each(function() {
          $(this).attr('aria-checked', 'false');
          $(this).attr('aria-label', $(this).val());
        });
        $(e.currentTarget).attr('aria-checked', 'true');
      }, this),
    );

  let cancel = function() {
    if (!inlineNotes.$el.is(':visible')) {
      $('body').off('hideAllPopupMenus', cancel);
      return;
    }
    console.log('canceled');
    if (!this.annotation) {
      let textSelectionEvent = inlineNotes.$el.data();
      if (!textSelectionEvent) {
        return;
      }

      let cfiObj = textSelectionEvent.cfi;
      let fullCFI = Helpers.locObjToFullCFI(this.readium.packageDocument, cfiObj);

      this.readium.reader.plugins.highlights.removeHighlight(fullCFI);
    } else {
      this.readium.reader.plugins.highlights.updateAnnotation(
        this.annotation.location,
        this.annotation.color,
        null,
      );
    }
    inlineNotes.$el.hide();
    $('body').off('hideAllPopupMenus', cancel);
    this.annotation = null;
    // setTimeout(function() {
    //     AccessibilityManager.panelCloseHandler(function() {
    //         AccessibilityManager.dispatchAction("focusContentElement")
    //     })
    // }, 50)
  };

  inlineNotes.$body
    .find('.btn-primary')
    .off('click')
    .one(
      'click',
      $.proxy(function(e) {
        var textSelectionEvent = inlineNotes.$el.data();
        if (!textSelectionEvent) {
          return;
        }
        let color = inlineNotes.$body.find("input[name='selected-note-color']:checked").val();
        if(this.newAnnotation){
          this.annotationUtils.saveNotes(
            color,
            inlineNotes.$body.find('textarea').val(),
            textSelectionEvent.cfi,
            textSelectionEvent.text,
            this.isbn
          ).then(function() {
            self.usageManager.fetchAnnotationsFromRemote(self.isbn).then(function(response){
              console.log('Fetched Annotations from Remote');
            });
          });
        }else{
          let notes = inlineNotes.$body.find('.form-control').val();
          this.annotation.type = 'Note';
          this.annotation.page = '';
          this.annotation.notetext = notes;
          this.annotation.createdate = this.annotationUtils.getCurrentDate();
          this.annotation.updateAnnotation = 'true';
          this.annotation.color = inlineNotes.$body
            .find("input[name='selected-note-color']:checked")
            .val();
          this.annotationUtils.updateAnnotation(textSelectionEvent.cfi.idref, this.annotation, this.isbn)
            .then(function() {
              console.log('Annotations updated online');
              self.usageManager.fetchAnnotationsFromRemote(self.isbn).then(function(response){
                console.log('Fetched Annotations from Remote');
              });
            })
            .catch(function(error) {
              console.log('Updating Annotations  offline :  error');
              throw error;
            });
        }
        inlineNotes.$el.hide();
        $('body').off('hideAllPopupMenus', cancel);
        // setTimeout(function() {
        //     AccessibilityManager.panelCloseHandler(function() {
        //         AccessibilityManager.dispatchAction("focusContentElement")
        //     })
        // }, 50)
      }, this),
    );

  inlineNotes.$body
    .find('.js-close-popup')
    .off('click')
    .one('click', $.proxy(cancel, this));
  inlineNotes.$el.one('hideInlineNote', $.proxy(cancel, this));
}

export default InlineNotesMenu;
