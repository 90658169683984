import $ from 'jquery';
import OverlayView from './OverlayView';
import ModuleConfig from './ModuleConfig';
import Helpers from './EpubReaderHelpers';
import AccessibilityManager from './accessibility/AcessibilityManager';

var urlParams = Helpers.getURLQueryParams();
   if (!urlParams.videobook) {
    var helpView,
      _initialized = false,
      helpRoot = ModuleConfig.contentDirectoryUrl + '/help',
      helpManifestUrl =
      helpRoot + (typeof urlParams.epub == 'undefined' ? '/AudioIndex.json' : '/index.json'),
      viewModel = { imageFolder: helpRoot + '/images' };
    }

var initView = function(view) {
  // Creating help view
  helpView = new OverlayView({
    id: 'ovwHelpPage',
    title: 'Help',
    viewClass: 'page-help',
    attr: { 'aria-label': 'Help Page' },
  });

  // Setting content in help view
  helpView.setView(view, viewModel);

  // Attaching event handlers for topic links withing help view
  helpView.$body.off('click.toc').on('click.toc', 'a.js-view-help', function(e) {
    e.preventDefault();
    fnShow(this.hash);
    return false;
  });

  _initialized = true;
};

var fnInit = function(cb) {
  if (_initialized || !$.isFunction(cb)) return;

      if (!urlParams.videobook) {
      //Getting manifest file from server
      $.get({ url: helpManifestUrl, dataType: 'json' })
        .done(function(data) {
          // manifest received
          if ($.isPlainObject(data) && data.pageUrl) {
            var toc = data.toc;
            var pageUrl = helpRoot + '/' + data.pageUrl;

            //getting page
            $.get({ url: pageUrl, dataType: 'text' })
              .done(function(data) {
                // page received

                // initializing page view
                initView(data);

                //calling back with toc
                cb(toc);
              })
              .fail(function(error) {
                // unable to get page
              });
          }
        })
        .fail(function(error) {
          // unable to get manifest
        });
     }
};

var fnShow = function(target) {
  if (helpView) {
    helpView.focusTo(target);
    // setTimeout(function() {
    //     AccessibilityManager.panelCloseHandler(function() {
    //         AccessibilityManager.setTabGroup("help_overview_popup")
    //         AccessibilityManager.updateTabOrder("help_overview_popup")
    //         setTimeout(function() {
    //             AccessibilityManager.setFocus(target)
    //         }, 500)
    //     })
    // }, 500)
  }
};

var fnClose = function() {
  if (helpView) {
    helpView.hide();
  }
};

export default {
  init: fnInit,
  show: fnShow,
  close: fnClose,
};
