/*
 Progress Widget Structure
 =========================
 <div class="progress-widget">
 <div class="progress-control">
 <!-- progress element -->
 </div>
 </div>


 */

import $ from 'jquery';

var tplWidget =
    '<div class="progress-widget" style="position:absolute;"><div class="progress-control" style="text-align:center;position:absolute;top:0;left:0;right:0;bottom:0;margin:auto;"></div></div>',
  tplSVG =
    '<svg id="{ID}" x="0px" y="0px" width="{W}" height="{H}" viewBox="{V}" style="{S}" xml:space="preserve"></svg>',
  wdgZindex = 2000,
  defaultParentSelector = 'body',
  dataName = 'progressWdg',
  dataAttr = 'data-progress-wdg',
  resizeTimer;

// Registered progress control types
var _registeredTypes = {};

// Default options
var defaultWdgOptions = {
  template: '',
  style: { background: 'rgba(255,255,255,1)', zIndex: wdgZindex },
  overlayAs: 'nested', // or "layered"
  delay: 250,
  // Detaching will prevent widget data to be written on owner.
  // widget data stores information about related progress widget
  detached: false,
  progress: {
    type: 'spinner',
  },
};

// Default Interface implementation for progress control
var defaultProgress = { init: $.noop, start: $.noop, stop: $.noop, setValue: $.noop };

// Widget Data Object
var widgetData = {
  overlay: '',
  id: '',
};

// Returns unique ID string
function uniqueId() {
  return Math.random(1)
    .toString()
    .replace(/\d*\./, '');
}

// Copies co-ordinates and size from source to target
function copyRect(source, target) {
  if ($(source).length == 0 || $(target).length == 0) return;

  //$(target).offset({top:,left:}) keeps adding offset, therefore using css
  $(target).css({ top: $(source).offset().top, left: $(source).offset().left });
  $(target)
    .width($(source).width())
    .height($(source).height());
}

// Create SVG, appends to parent and returns Snap SVG object
function createSVG(parent, options) {
  // Preparing template
  var tpl = tplSVG
    .replace('{ID}', options.id || '')
    .replace('{W}', options.w || '') // width
    .replace('{H}', options.h || '') // height
    .replace('{V}', options.v || '') // view box
    .replace('{S}', options.s || ''); // style

  // Creating and appending DOM
  $(parent).append(tpl);

  // Getting and return snap object
  return Snap('#' + options.id);
}

// Register progress control type to be used by widget
function register(progressType, builder) {
  if (_registeredTypes.hasOwnProperty(progressType)) {
    throw progressType + ' : progress type already registered';
  }

  _registeredTypes[progressType] = builder;
}

// compare object1 with object2 and return false if object2 doesn't have all
// of object1 properties
function compareObjects(object1, object2) {
  if (!$.isPlainObject(object1) || !$.isPlainObject(object2)) return false;
  Object.getOwnPropertyNames(object1).forEach(function(p) {
    if (typeof object1[p] != typeof object2[p]) return false;
  });
  return true;
}

// Widget Class
var Widget = function(owner, options) {
  // owner is required
  if (!owner) return;

  // do not create widget if already created for owner
  // or owner is not a dom element
  if ($(owner).data('progressWdg') || $(owner).length == 0) return;

  options = options || {};

  // creating options property
  Object.defineProperty(this, 'options', {
    value: $.extend({}, defaultWdgOptions, options),
    writable: false,
    enumerable: false,
    configurable: false,
  });

  var id = uniqueId(),
    wid = 'PRG' + id,
    $elTpl = options.template || tplWidget,
    data = Object.create(widgetData);

  // Preparing widget data
  data.id = wid;

  // creating widget element and assigning it as a property of widget object
  Object.defineProperty(this, '$el', {
    value: $($elTpl),
    writable: false,
    enumerable: false,
    configurable: false,
  });

  // select owner element and assigning it as a property of with widget object
  Object.defineProperty(this, '$owner', {
    value: $(owner),
    writable: false,
    enumerable: false,
    configurable: false,
  });

  // setting id and applying styles
  $(this.$el)
    .attr('id', wid)
    .css(this.options.style)
    .css({ position: 'absolute' })
    .hide();

  // appending widget to the parent and
  // parent is "body" element if overlayAs property is set to 'layered'
  if (this.options.overlayAs == 'layer') {
    data.overlay = this.options.overlayAs;
    $(defaultParentSelector).append(this.$el);
  }

  // parent is $owner if overlayAs property is set to 'nested'
  if (this.options.overlayAs == 'nested') {
    $(this.$owner).append(this.$el);
    this.$el.css({ width: '100%', height: '100%', top: 0, left: 0 });
  }

  if (!this.options.detached) {
    // setting data on $owner dom
    $(this.$owner).attr(dataAttr, data);
  }

  //
  // Creating progress control
  // creating custom progress control
  // if options.progress.type is a function
  // which must implement and return 'defaultProgress' object
  if (this.options.progress.type && $.isFunction(this.options.progress.type)) {
    this.progressCtrl = this.options.progress.type.apply(this);
  } else {
    // creating registered progress control
    Object.defineProperty(this, 'progressCtrl', {
      value: _registeredTypes[this.options.progress.type].apply(this),
      writable: false,
      enumerable: false,
      configurable: false,
    });
  }

  if (!compareObjects(defaultProgress, this.progressCtrl)) {
    throw new Error("Widget progress doesn't implement all required methods");
  }

  // calling progress control builder object to init dom
  this.progressCtrl.init(this.options.progress);

  this._delayTimeoutID = null;
  this._delayTime = this.options.delay == 0 ? 250 : this.options.delay;
  this._overlayAs = this.options.overlayAs;
  this._value = -1;
};

// Start method implementation
Widget.prototype.start = function(delay) {
  var self = this;

  function show() {
    // widget should only be started if visible
    if ($(self.$el).is(':visible')) return;

    // resizing widget size to cover owner and showing widget
    // if overlay mode is "layered"
    if (self._overlayAs == 'layered') {
      copyRect(self.$owner, self.$el);
    }

    self.$el.fadeIn();

    // Calling start method of progress control
    self.progressCtrl.start();
  }

  if (this._delayTime) {
    //MDN: Passing an invalid ID to clearTimeout() silently does nothing; no exception is thrown.
    clearTimeout(this._delayInterval);
    this._delayInterval = setTimeout(show, 250);
  } else {
    show();
  }
};

// Stop method implementation
Widget.prototype.stop = function() {
  clearTimeout(this._delayInterval);
  this.$el.fadeOut(
    function() {
      // resetting progress control
      this._value = 0;
      this.progressCtrl.stop();
      this.progressCtrl.setValue(0);
    }.bind(this),
  );
};

// Set value method
Widget.prototype.setValue = function(value) {
  if (!this.isVisible() || value <= this._value) return;
  this._value = value;
  this.progressCtrl.setValue(value);
};

Widget.prototype.isVisible = function() {
  return $(this.$el).is(':visible');
};

Widget.prototype.reset = function() {
  this._value = 0;
  this.progressCtrl.setValue(0);
};

// handling window's resize event to resize visible progress based on their owner
/*
     $(window).on("resize", function () {

     clearTimeout(resizeTimer);
     var cancel = true;
     resizeTimer = setTimeout(function () {
     cancel = false;

     $("[data-progress-wdg]").each(function () {
     var id = $(this).data("progressWdg");
     var wdg = $("#" + id);
     if ($(wdg).is(":visible") && !cancel) {
     copyRect(this, wdg);
     }
     });
     }, 250);
     });
     */

//
// BUILT-IN PROGRESS CONTROL TYPES
// ===============================

// Spinner Progress
register('spinner', function() {
  var wdg = this;

  return {
    init: function() {
      var id = 'SVG' + wdg.$el.attr('id');
      wdg.$el
        .find('.progress-control')
        .width(40)
        .height(40);
      var svg = createSVG(wdg.$el.find('.progress-control'), {
        id: id,
        w: 40,
        h: 40,
        v: '0 0 50 50',
      });
      svg
        .path(
          'M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z',
        )
        .attr('fill', 'rgb(101, 124, 2)');
    },
    start: function() {
      var id = 'SVG' + wdg.$el.attr('id');
      var path = Snap.select('#' + id + ' path');

      path.stop();
      function animate() {
        path.transform('r0,25,25');
        path.animate({ transform: 'r360,25,25' }, 600, animate);
      }
      animate();
    },
    stop: function() {
      var id = 'SVG' + wdg.$el.attr('id');
      Snap.select('#' + id + ' path').stop();
    },
    setValue: $.noop,
  };
});

export default {
  create: function(owner, options) {
    return new Widget(owner, options);
  },
  register: register,
};
